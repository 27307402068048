<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
	name: 'index',
	components: {sidebarInnerHardcoded}, 
	data () {
		return {
			menu : [
				{
					title 	: this.$t('leffit.bijenkorf.virgocode_importeren'),
					url 	: '/admin/bijenkorf/virgo/import',
					children : [],
				},
				{
					title 	: this.$t('leffit.bijenkorf.pricate_export'),
					url 	: '/admin/bijenkorf/pricat/export',
					children : [],
				},
                {
					title 	: this.$t('leffit.bijenkorf.webshop_export'),
					url 	: '/admin/bijenkorf/webshop/export',
					children : [],
				},
                {
					title 	: this.$t('leffit.bijenkorf.sales_report'),
					url 	: '/admin/bijenkorf/sales-report/import',
					children : [],
				},
			],
		}
	}
}
</script>

<style lang="css" scoped>
	#mainBody .mainPanel {
		border: 1px solid #f0f0f0;
		min-height: 800px;
	}
</style>