import stockIndex from "../../../../src/leffit/stocks/settings/warehouses/index";
import StocksIndex from "../../../../src/leffit/stocks/stock/index";
import WarehouseIndex from "../../../../src/leffit/stocks/warehouses/index";
import warehousesIndex from "../../../../src/leffit/stocks/settings/warehouses/table";
import warehousesCreate from "../../../../src/leffit/stocks/settings/warehouses/create";
import warehousesItem from "../../../../src/leffit/stocks/settings/warehouses/item";

import stocklocationsCreate from "../../../../src/leffit/stocks/settings/stock_locations/create";
import stocklocationsItem from "../../../../src/leffit/stocks/settings/stock_locations/item";

import stockLocationsIndex from "../../../../src/leffit/stocks/settings/stock_locations/index";
import WarehouseStockCreate from '../../../../src/leffit/stocks/warehouses/create';
import WarehouseStockItem from '../../../../src/leffit/orders/order/item';

import stocksSalesReportIndex from '../../../../src/leffit/stocks/sale_reports/index'

export default [
    {
        path: "stocks",
        component: StocksIndex,
        children: [
            {
                path: 'stocks',
                component: StocksIndex
            }
        ],
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "stock_start",
        component: WarehouseIndex,
        meta: {
            AuthRequired: true,
        },
        props: {
            ordertype_id: 300,
            create_url: '/admin/stocks/stock_start'
        }
    },
    {
        path: "stock_start/create",
        component: WarehouseStockCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 300
        }
    },
    {
        path: "stock_start/create/:id",
        component: WarehouseStockItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 300
        }
    },
    {
        path: "warehouse_sales_report",
        component: WarehouseIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 305,
            create_url: '/admin/stocks/warehouse_sales_report'
        }
    },
    {
        path: "warehouse_sales_report/create/:id",
        component: WarehouseStockItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 305
        }
    },
    {
        path: "warehouse_sales_report/create",
        component: WarehouseStockCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 305
        }
    },

    {
        path: "warehouse_sent",
        component: WarehouseIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 310,
            create_url: '/admin/stocks/warehouse_sent'
        }
    },
    {
        path: "warehouse_sent/create/:id",
        component: WarehouseStockItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 310
        }
    },
    {
        path: "warehouse_sent/create",
        component: WarehouseStockCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 310
        }
    },
    {
        path: "warehouse_received",
        component: WarehouseIndex,
        meta: {
            AuthRequired: true,
        },
        props: {
            ordertype_id: 320,
            create_url: '/admin/stocks/warehouse_received'
        }
    },
    {
        path: "warehouse_received/create/:id",
        component: WarehouseStockItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 320
        }
    },
    {
        path: "warehouse_received/create",
        component: WarehouseStockCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: 320
        }
    },
    {
        path: "settings",
        component: stockIndex,
        children: [
            {
                path: "warehouses",
                component: warehousesIndex,
            },
            {
                path: 'warehouses/create',
                component: warehousesCreate
            },
            {
                path: 'warehouses/:id',
                component: warehousesItem
            },
            {
                path: 'warehouses/:id/edit',
                component: warehousesItem
            },
            {
                path: "stock_locations",
                component: stockLocationsIndex,
            },
            {
                path: 'stock_locations/create',
                component: stocklocationsCreate
            },
            {
                path: 'stock_locations/:id',
                component: stocklocationsItem
            },
            {
                path: 'stock_locations/:id/edit',
                component: stocklocationsItem
            },
        ],
        meta: {
            AuthRequired: true
        },
    },

    {
        path: "sales-report/import",
        component: stocksSalesReportIndex,
        meta: {
            AuthRequired: true
        }
    },
];