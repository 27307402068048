<template>
    <div>
           <table class="table table-hover">
                <thead>
                    <tr>
                        <td colspan="3"></td>
                        <td colspan="3">
                            <div class="form-group">
                                <input type="email" placeholder="enter email" class="form-control" v-model="contact.email">
                            </div>
                        </td> 
                        <td colspan="3"><div class="form-group col-lg-1"><button @click="addEmail()" class="btn btn-sm btn-success" style="margin-top:-5px"><i class="fa fa-plus"></i></button></div></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contact,index) in emails" :key="index">
                        <td colspan="3">{{index+1}}</td>
                        <td colspan="3">
                            <input type="text" :class="index == edit ? 'form-control':'form-control dormant'" v-model="contact.email" />
                        </td>
                        <td colspan="3">
                            <div class="">
                                <button @click="edit=index" v-if="index != edit" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i></button>
                                <button @click="updateEmail()" v-else class="btn btn-success btn-sm"><i class="fas fa-check"></i></button>
                                <button @click="removeEmail(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
           </table>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return {
            contact:{email:''},
            emails:[],
            edit:-1
        }
    },
    methods:{
        addEmail(){
            if(this.contact.email == ''){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'Email fields is required',type:'danger'});
                return false;
            }
            
            this.emails.push(this.contact);
            
            this.$parent.item.emails=JSON.stringify(this.emails);
            this.$parent.updateItem();
        },
        updateEmail(){
            this.edit=-1;
            this.$parent.item.emails=JSON.stringify(this.emails);
            this.$parent.updateItem();
             
        },
        removeEmail(index){
            if(!confirm("Are you sure you want to delete this item?")){ 
                return false;
            }
            var emails=this.emails;
            emails.splice(index,1);
            this.emails=emails;
            this.$parent.item.emails=JSON.stringify(this.emails);
            this.$parent.updateItem();
        },
    },
    created(){
        if(this.item.emails != null){
            this.emails=JSON.parse(this.item.emails); 
        }
    }
}
</script>
<style scoped>
       .form-control.dormant{
        border: none;
        background: transparent;
    }
    .form-control.dormant:focus{
        outline: none;
        border: none;
    }
    .form-control.dormant:active{
        outline: none;
        border: none;
    }
</style>