<template>
	<div>
		<div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.website.pagetypes') }}</h3>
      	</div>
        <div class="row">
			<a href="#addPageType" data-toggle="modal" class="btn btn-success btn-sm">{{ $t('leffit.website.add_pagetypes') }}</a>
		</div>
      <page-type-table></page-type-table>

      <div class="modal" id="addPageType">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">{{ $t('leffit.website.add_pagetypes') }}</div>
                  <div class="modal-body">
                      <div class="form-group">
                          <label for="">Name</label>
                          <input v-model="item.name" type="text" class="form-control">
                      </div>

                      <button @click="create()" class="btn btn-success">{{ $t('leffit.website.create') }}</button>
                  </div>
              </div>
          </div>
      </div>
	</div>
</template>
<script>
import table from './table';
export default {
    components:{
        'page-type-table':table,
        
    },
    data(){
        return {
            item:{
                name:''
            }
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.$store.state);
            this.$store.dispatch('post',{endpoint:'/leffit/cms/pagetypes',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Pagetype created",text:'Pagetype was created successfully'});
                this.item={};
                window.location.href='/admin/website/pagetypes/'+data.data.data.id+'/edit';
			}).catch((error)=>{
				console.log(error);
			});
        }
    }
}
</script>