<template>
	<div> 
      	<!-- <warehouse-table></warehouse-table>  -->
    <router-view></router-view>
	</div>
</template>

<script>
// import table from './table'; 
export default {
  	name: 'productFilters',
	// components:{'warehouse-table':table},
  	data () {
    	return {
    		pagedata : [],
        attributes : [
          'ean',
          'name',
          'producttype',
          'productstatus',
          'stock',
          'created_at',
          'updated_at',
          'deleted_at',
        ],
        search : {},
    	}
  	},
    methods: {
      translate (input) {
        if(input){
          if(input.constructor === String){
            if(input.indexOf('leffit.products')!==-1){
              return this.$t(input);
            }else{
              return input;
            }
          }
        }
        
        return input;
      }
    },
  	mounted(){
	    this.$store.dispatch('post',{endpoint:'/leffit/products/search',details:{limit:10}})
        .then(data=>{
        	console.log(data);
			this.pagedata = data.data.data;
		});
	},
}
</script>

<style lang="css" scoped>
</style>