<template>
    <div>
     <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.orders.ordertype.create') }}</h3>
      	</div>
        <div class="row">
            <div class="form-group col-12 ">
                <label for="">Name</label>
                <input v-model="item.name" type="text" class="form-control" placeholder="Enter your Ordertype name"/>
            </div>
            <div class="form-group col-sm-12 ">
                 <label for="">Pickorder</label>
                 <input v-model="item.pickorder" type="text" class="form-control" placeholder="Enter your Pickorder name"/>
			</div>
             <div class="form-group col-12 ">
                <label for="filter_active" class="d-flex" style="width:60px">
                    <input :checked="item.active == 1 ? true:false" @click="item.active=item.active == 0 ? 1:0" style="height: 21px;" type="checkbox" class="form-control d-block" id="filter_active"  />
                    <div>Active</div>
                </label>
            </div>
        </div>
        <div class="row m-0 p-0">
            <button @click="create()" class="btn btn-success">SUBMIT</button>
        </div>
    </div>
</template>

<script>
export default {
    name:'createpermission',

    data(){
        return {
			item:{
                active:0,
                name:'',
                pickorder:''
                }
        }
    },
    methods:{
		create(){

            if(this.item.name.length == 0){
                this.$store.dispatch('makeNotification',{title:"Error occured",text:'OrderType name is required',type:'danger'});
                return false;
            }
			this.$store.dispatch('post',{endpoint:'/leffit/orders/order-types',details:this.item})
			.then(data=>{
                console.log('result',data);
                this.$store.dispatch('makeNotification',{title:"ITEM ADDED",text:this.item.name+' was added successfully',type:'success'});
                this.item.name='';
                this.item.pickorder='';
                this.item.active=0;

            }).catch((error)=>{
                console.log(error.request);
                if(error.request.status == 403){
                    this.$store.dispatch('makeNotification',{title:"Error occured",text:JSON.parse(error.request.response)['message'],type:'danger'});
                }
                this.sent=false;
            });
		},
    },
    mounted(){

    }
}
</script>
<style scoped>

</style>