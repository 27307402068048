import shipmentsIndex from '../../../../src/leffit/shipping/shipments/index.vue'
import shippingcostIndex from '../../../../src/leffit/shipping/shippingcosts/index.vue'
import shippingmethodIndex from '../../../../src/leffit/shipping/shippingmethods/index.vue'
import shippingregionsIndex from '../../../../src/leffit/shipping/shippingregions/index.vue'
import shippingstatusIndex from '../../../../src/leffit/shipping/shippingstatus/index.vue'

import shippingstatusCreate from '../../../../src/leffit/shipping/shippingstatus/create.vue'
import shippingstatusEdit from '../../../../src/leffit/shipping/shippingstatus/edit.vue'

import shippingregionsCreate from '../../../../src/leffit/shipping/shippingregions/create.vue'
import shippingregionsEdit from '../../../../src/leffit/shipping/shippingregions/edit.vue'

import shippingmethodsCreate from '../../../../src/leffit/shipping/shippingmethods/create.vue'
import shippingmethodsEdit from '../../../../src/leffit/shipping/shippingmethods/edit.vue'

import shippingcostsCreate from '../../../../src/leffit/shipping/shippingcosts/create.vue'
import shippingcostsEdit from '../../../../src/leffit/shipping/shippingcosts/edit.vue'

import shipmentsCreate from '../../../../src/leffit/shipping/shipments/create.vue'
import shipmentsEdit from '../../../../src/leffit/shipping/shipments/edit.vue'

export default [
    {
        path:"shipment",
        component: shipmentsIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"shipments/create",
        component: shipmentsCreate,
    },
    {
        path:"shipments/:id",
        component: shipmentsEdit,
    },
    {
        path:"shipments/:id/edit",
        component: shipmentsEdit,
    },
    {
        path:"shippingcosts",
        component: shippingcostIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"shippingcosts/create",
        component: shippingcostsCreate,
    },
    {
        path:"shippingcosts/:id/edit",
        component: shippingcostsEdit,
    },
    {
        path:"shippingcosts/:id/view",
        component: shippingcostsEdit,
    },
    {
        path:"shippingmethods",
        component: shippingmethodIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"shippingmethods/create",
        component: shippingmethodsCreate,
    },
    {
        path:"shippingmethods/:id/edit",
        component: shippingmethodsEdit,
    },
    {
        path:"shippingregions",
        component: shippingregionsIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"shippingregions/create",
        component: shippingregionsCreate,
    },
    {
        path:"shippingregions/:id/edit",
        component: shippingregionsEdit,
    },
    {
        path:"shippingregions/:id/view",
        component: shippingregionsEdit,
    },
    {
        path:"shippingstatus",
        component: shippingstatusIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"shippingstatus/create",
        component: shippingstatusCreate,
    },
    {
        path:"shippingstatus/:id/edit",
        component: shippingstatusEdit,
    },
]