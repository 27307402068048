<template>
  <div>
    <div class="head row p-0 m-0">
      <h4 v-show="isDisabled">
        <router-link
          :to="this.$route.fullPath + '/edit'"
          class="btn btn-secondary"
          ><i class="fa fa-edit"></i>
          {{ $t("lefficient.system.edit") }}</router-link
        >
      </h4>
      <h4 v-show="isDisabled">
        <a
          @click="generatePassword()"
          href="#generatePassword"
          class="btn btn-primary"
          ><i class="fa fa-edit"></i>
          {{ $t("leffit.crm.contact.generate_password") }}</a
        >
      </h4>
    </div>

    <div v-show="isDisabled" class="row">
      <div class="col-sm-12 col-md-6">
        <div class="imgCard card">
          <h4>{{ $t("leffit.crm.contact.picture") }}</h4>
          <div
            v-bind:style="{ backgroundImage: 'url(' + item.picture + ')' }"
          ></div>
        </div>
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.contact.financial_details") }}</h4>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.coc") }}</b>
              <h6>{{ item.coc }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.vat_number") }}</b>
              <h6>{{ item.vat_number }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.iban_name") }}</b>
              <h6>{{ item.iban_name }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.iban_number") }}</b>
              <h6>{{ item.iban_number }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.swift_code") }}</b>
              <h6>{{ item.swift_code }}</h6>
            </div>
          </div>
        </div>
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.contact.other_details") }}</h4>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.function") }}</b>
              <h6>{{ item.function }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.birthday") }}</b>
              <h6>{{ item.birthday }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.age") }}</b>
              <h6 class="mt-1">{{ item.age }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.gender") }}</b>
              <h6>{{ item.gender }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.language") }}</b>
              <h6>{{ item.language }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.contact.optin") }}</b>
              <h6>{{ item.optin }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.contact.basic_details") }}</h4>
          <div id="">
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.title") }}</b>
              <h6>{{ item.title }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.firstname") }}</b>
              <h6>{{ item.firstname }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.prelastname") }}</b>
              <h6>{{ item.prelastname }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.lastname") }}</b>
              <h6>{{ item.lastname }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.address") }}</b>
              <h6>{{ item.address }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.address2") }}</b>
              <h6>{{ item.address2 }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.zipcode") }}</b>
              <h6>{{ item.zipcode }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.city") }}</b>
              <h6>{{ item.city }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.country") }}</b>
              <h6>{{ item.country }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.phone") }}</b>
              <h6>{{ item.phone }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.mobile") }}</b>
              <h6>{{ item.mobile }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.fax") }}</b>
              <h6>{{ item.fax }}</h6>
            </div>
            <div class="bsc">
              <b>{{ $t("leffit.crm.contact.email") }}</b>
              <h6>{{ item.email }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.contact.remarks") }}</h4>
          <div id="">
            <div class="bsc" v-html="item.remarks"></div>
          </div>
        </div>
      </div>
      <div v-if="item.extra && item.extra.length > 0" class="col-12">
        <div v-if="item.extra != null" class="card">
          <div>
            <!-- <metaData :item="item" :displayType="'view'"></metaData> -->
          </div>
        </div>
      </div>
    </div>
    <form @submit="updateItem($event)">
      <div v-show="!isDisabled">
        <div style="background:white;" class="imgCard card">
          <h4 style="color:#4e5467 !important">UPLOAD IMAGE</h4>
          <div
            id="imgHolder"
            v-bind:style="{ backgroundImage: 'url(' + item.picture + ')' }"
          ></div>

          <div class="d-flex text-center pt-2">
            <input
              type="file"
              name=""
              id="file"
              class="d-none"
              @change="readFile()"
            />
            <button
              v-if="!this.profileImageSet"
              type="button"
              @click="trigger()"
              style="margin:auto;background:#fb9c7e;color:white;font-size: 12px;font-weight: 500;"
              class="btn "
            >
              Change Picture
            </button>
            <button
              v-if="this.profileImageSet"
              type="button"
              @click="cancelImage()"
              style="margin:auto;background:red;color:white;font-size: 12px;font-weight: 500;"
              class="btn "
            >
              Cancel Picture
            </button>
          </div>
        </div>
        <div class="card">
          <div class="form-row">
            <div class="form-group col-2">
              <label for="contact_title">{{
                $t("leffit.crm.contact.title")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_title"
                v-model="item.title"
              />
            </div>

            <div class="form-group col-3">
              <label for="contact_firstname">{{
                $t("leffit.crm.contact.firstname")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_firstname"
                v-model="item.firstname"
              />
            </div>

            <div class="form-group col-3">
              <label for="contact_prelastname">{{
                $t("leffit.crm.contact.prelastname")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_prelastname"
                v-model="item.prelastname"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_lastname">{{
                $t("leffit.crm.contact.lastname")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_lastname"
                v-model="item.lastname"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_address">{{
                $t("leffit.crm.contact.address")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_address"
                v-model="item.address"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_address2">{{
                $t("leffit.crm.contact.address2")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_address2"
                v-model="item.address2"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-4">
              <label for="contact_zipcode">{{
                $t("leffit.crm.contact.zipcode")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_zipcode"
                v-model="item.zipcode"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_city">{{
                $t("leffit.crm.contact.city")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_city"
                v-model="item.city"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_country">{{
                $t("leffit.crm.contact.country")
              }}</label>
              <select2
                :options="
                  country.map((c) => {
                    return { id: c.code, text: c.name };
                  })
                "
                v-model="item.country"
                style="position:relative"
                :disabled="isDisabled"
                class=" country"
                id="contact_country"
              ></select2>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-6">
              <label for="contact_phone">{{
                $t("leffit.crm.contact.phone")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_phone"
                v-model="item.phone"
              />
            </div>

            <div class="form-group col-6">
              <label for="contact_mobile">{{
                $t("leffit.crm.contact.mobile")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_mobile"
                v-model="item.mobile"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-6">
              <label for="contact_fax">{{
                $t("leffit.crm.contact.fax")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_fax"
                v-model="item.fax"
              />
            </div>

            <div class="form-group col-6">
              <label for="contact_email">{{
                $t("leffit.crm.contact.email")
              }}</label>
              <input
                type="email"
                :disabled="isDisabled"
                class="form-control"
                id="contact_email"
                v-model="item.email"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_picture">{{
                $t("leffit.crm.contact.picture")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_picture"
                v-model="item.picture"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-4">
              <label for="contact_function">{{
                $t("leffit.crm.contact.function")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_function"
                v-model="item.function"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_birthday">{{
                $t("leffit.crm.contact.birthday")
              }}</label>
              <input
                type="date"
                :disabled="isDisabled"
                class="form-control"
                id="contact_birthday"
                v-model="item.birthday"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_gender">{{
                $t("leffit.crm.contact.gender")
              }}</label>
              <select
                v-model="item.gender"
                style="position:relative"
                :disabled="isDisabled"
                class="form-control"
                id="contact_gender"
              >
                <option value="0">Male</option>
                <option value="1">Female</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-6">
              <label for="contact_coc">{{
                $t("leffit.crm.contact.coc")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_coc"
                v-model="item.coc"
              />
            </div>

            <div class="form-group col-6">
              <label for="contact_vat_number">{{
                $t("leffit.crm.contact.vat_number")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_vat_number"
                v-model="item.vat_number"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-4">
              <label for="contact_iban_name">{{
                $t("leffit.crm.contact.iban_name")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_iban_name"
                v-model="item.iban_name"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_iban_number">{{
                $t("leffit.crm.contact.iban_number")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_iban_number"
                v-model="item.iban_number"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_swift_code">{{
                $t("leffit.crm.contact.swift_code")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_swift_code"
                v-model="item.swift_code"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_language">{{
                $t("leffit.crm.contact.language")
              }}</label>
              <select2
                :options="
                  language.map((l) => {
                    return { id: l.code, text: l.name };
                  })
                "
                v-model="item.language"
                style="position:relative"
                :disabled="isDisabled"
                class="form-control language"
                id="contact_language"
              ></select2>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_remarks">{{
                $t("leffit.crm.contact.remarks")
              }}</label>
              <editor
                id="contact_remarks"
                v-model="item.remarks"
                :disabled="isDisabled"
                :init="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
										alignleft aligncenter alignright alignjustify | \
										bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help',
                }"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_status_id">{{
                $t("leffit.crm.contact.status_id")
              }}</label>
              <select
                :disabled="isDisabled"
                class="form-control"
                id="contact_status_id"
                v-model="item.status_id"
              >
                <option value="1">{{ $t("leffit.crm.contact.active") }}</option>
                <option value="0">{{
                  $t("leffit.crm.contact.inactive")
                }}</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_parent_id">{{
                $t("leffit.crm.contact.parent_id")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_parent_id"
                v-model="item.parent_id"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12 ">
              <label for="contact_optin" class="d-flex" style="width:60px">
                <input
                  :checked="item.optin == 1 ? true : false"
                  @click="item.optin = item.optin == 0 ? 1 : 0"
                  style="height: 21px;"
                  type="checkbox"
                  :disabled="isDisabled"
                  class="form-control d-block"
                  id="contact_optin"
                />
                <div>{{ $t("leffit.crm.contact.optin") }}</div>
              </label>
            </div>
          </div>

          <!-- <div v-if="item.extra">
						<metaData :item="item" :displayType="'edit'"></metaData>
					</div> -->
        </div>
        <div class="row p-0 m-0">
          <button class="btn btn-success">
            {{ $t("lefficient.leffit.system.update") }}
          </button>
        </div>
      </div>
    </form>

    <div v-if="setting_ready && group_tabs.length > 0" class="card mt-2">
      <tabsMeta
        :item="item"
        :tabs="group_tabs"
        :options="options"
        :package_name="'crm_contact_settings'"
      ></tabsMeta>
    </div>
    <div class="card mt-2">
      <ul class="nav nav-pills nav-fill">
        <li v-if="shouldDisplay('messages')" class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#contact">{{
            $t("leffit.crm.contact.message")
          }}</a>
        </li>
        <li v-if="shouldDisplay('order')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#orders">{{
            $t("leffit.orders.order.orders")
          }}</a>
        </li>
        <!-- <li v-if="shouldDisplay('admin')"  class="nav-item"><a class="nav-link" data-toggle="tab" href="#admin">{{ $t('leffit.crm.contact.admins') }}</a></li> -->
        <li v-if="shouldDisplay('organizations')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#organization">{{
            $t("leffit.crm.contact.orgnizations")
          }}</a>
        </li>
        <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#products">{{ $t('leffit.crm.contact.products') }}</a></li> -->
        <li v-if="shouldDisplay('remarks')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#remarks">{{
            $t("leffit.crm.contact.remark")
          }}</a>
        </li>
        <li v-if="shouldDisplay('subscriptions')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#subscription"
            >Subscription</a
          >
        </li>
        <li v-for="(group, index) in item.extra" :key="index" class="nav-item">
          <a class="nav-link" data-toggle="tab" :href="'#meta' + index">{{
            index
          }}</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-if="shouldDisplay('messages')"
          class="tab-pane fade show active"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <communication></communication>
        </div>
        <div
          v-if="shouldDisplay('admin')"
          class="tab-pane fade show"
          id="admin"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <admins :item="item"></admins>
        </div>
        <div
          v-if="shouldDisplay('order')"
          class="tab-pane fade show"
          id="orders"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <orders :item="item"></orders>
        </div>
        <div
          v-if="shouldDisplay('organizations')"
          class="tab-pane fade show"
          id="organization"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <organizations :item="item"></organizations>
        </div>
        <!-- <div
          class="tab-pane fade show"
          id="products"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <products :item="item"></products>
        </div> -->
        <div
          v-if="shouldDisplay('remarks')"
          class="tab-pane fade show"
          id="remarks"
          role="tabpanel"
          aria-labelledby="contact-tab"
        ></div>
        <div
          class="tab-pane fade show"
          id="subscription"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <subsription v-if="item.id" :id="item.id"></subsription>
        </div>
        <div
          v-for="(group, index) in item.extra"
          :key="index"
          class="tab-pane fade show"
          :id="'meta' + index"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div
            v-for="(field, index2) in group"
            :key="index2"
            class="form-group"
          >
            <label for="">{{ field.label }}</label>
            <div v-if="field.settings.group_type == 'single'">
              <input
                v-if="!isDisabled"
                v-model="field.value"
                type="text"
                class="form-control"
              />
              <div v-else>{{ field.value }}</div>
            </div>
            <div v-if="field.settings.group_type == 'multiple'">
              <table class="table table-responsive table-hover">
                <thead>
                  <th
                    v-for="(column, index3) in getTableColumns(
                      field.data,
                      field.settings.multi,
                      group
                    )"
                    :key="index3"
                  >
                    {{ column }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(row, index4) in field.data" :key="index4">
                    <td
                      v-for="(column, index3) in getTableColumns(
                        field.data,
                        field.settings.multi,
                        group
                      )"
                      :key="index3"
                    >
                      <div v-if="displayType == 'edit'">
                        <input
                          v-if="field.settings.multi"
                          type="text"
                          class="form-control"
                          v-model="JSON.parse(row.value)[column]"
                        />
                        <input
                          v-else
                          type="text"
                          class="form-control"
                          v-model="row.value"
                        />
                      </div>
                      <div v-else>
                        <p v-if="field.settings.multi">
                          {{ JSON.parse(row.value)[column] }}
                        </p>
                        <p v-else>{{ row.value }}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import communication from "./communication";
import admins from "./admins";
//import products from "./products";
import orders from "./orders";
import organizations from "./organizations";
import tabsMeta from "../../components/tabs_meta";
// import metaData from './meta-data';
import subsription from "./subscription";

export default {
  props: ["id", "edit"],
  components: {
    communication,
    admins,
    orders,
    organizations,
    tabsMeta,
    //products,
    subsription,
  },
  name: "item",
  data() {
    return {
      item: {
        title: "",
        firstname: "",
        prelastname: "",
        lastname: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        picture: "",
        function: "",
        birthday: "",
        gender: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "",
        remarks: "",
        status_id: "",
        parent_id: "",
        optin: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      language: language,
      country: country,
      profileImageSet: false,
      settings: [],
      group_tabs: [],
      options: [],
      setting_ready: false,
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    isDisabled: function() {
      return !(this.edit == "edit");
    },
  },
  created() {},
  methods: {
    getTableColumns(data, inverse = false, group = []) {
      var columns = [];

      if (!inverse) {
        for (var field in group) {
          columns.push(field.key);
        }
      } else {
        data.forEach((element) => {
          for (var field in JSON.parse(element.value)) {
            if (columns.find((d) => d == field) == undefined) {
              columns.push(field);
            }
          }
        });
      }
      return columns;
    },
    shouldDisplay(tab) {
      var check = false;
      if (this.settings != null) {
        var tb = this.settings.find((setting) => setting.field == tab);
        if (tb != undefined) {
          if (tb.show) {
            check = true;
          }
        }
      }
      return check;
    },
    generatePassword() {
      if (
        window.confirm("Are you sure you want to generate a new password ?")
      ) {
        this.$store
          .dispatch(
            "get",
            "/leffit/crm/contacts/generate-password/" + this.item.id
          )
          .then(() => {
            this.$store.dispatch("makeNotification", {
              title: "ITEM UPDATED",
              text: "Password was generated and sent successfully",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error.request);
          });
      }
    },
    updateItem(e) {
      e.preventDefault();
      console.log(this.item);
      this.$store
        .dispatch("put", {
          endpoint: "/leffit/crm/contacts/" + this.item.id,
          details: this.item,
        })
        .then(() => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.lastname + " was updated successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    trigger() {
      document.getElementById("file").click();
    },
    cancelImage() {
      document.getElementById("imgHolder").style.backgroundImage =
        "url('" + this.item.picture + "')";
      this.profileImageSet = false;
    },
    readFile() {
      var file = document.getElementById("file").files[0];

      let formats = ["image/png", "image/jpg", "image/jpeg", "image/PNG"];

      if (!formats.find((ft) => ft == file.type)) {
        alert("file format not supported");
        return false;
      }

      var fileReader = new FileReader();
      fileReader.onload = (result) => {
        document.getElementById("imgHolder").style.backgroundImage =
          "url('" + result.srcElement.result + "')";
        this.profileImageSet = true;
      };
      fileReader.readAsDataURL(file);
    },
  },
  mounted() {
    this.$store
      .dispatch("get", "/leffit/crm/contacts/" + this.id)
      .then((data) => {
        this.item = data.data.data;
        this.item.optin = this.item.optin == null ? 0 : this.item.optin;
        document.getElementById("contact_language").value = this.item.language;
      });

    this.$store
      .dispatch(
        "get",
        "/lefficient/leffit/packages/settings/crm_contact_settings"
      )
      .then((data) => {
        if (data.data != null) {
          if (data.data.setting != null) {
            var settings = JSON.parse(data.data.setting);
            this.settings = settings.tabs;
            if (settings.options) {
              this.options = JSON.parse(JSON.stringify(settings.options));
            }
            this.group_tabs = settings.group_tabs ? settings.group_tabs : [];
            this.setting_ready = true;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="css" scoped>
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #d3d3d330;
}
</style>
