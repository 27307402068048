<template>
  <div class="row m-0 p-2">
    <table class="table">
      <thead>
        <!-- store this with pricelevel purchase_price_latest -->
        <tr>
          <th>Label</th>
          <th colspan="2">Prijs</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
         <tr v-for="(level,index) in sales_pricelevels" :key="index">
           <td>{{$t('leffit.products.pricelevel.'+level.label)}}</td>
           <td><input type="number" class="form-control" v-model="level.price" ></td>
           <td></td>
         </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">Totaal:
          <td>{{ pricelevels_total }}</td>
        </tr>
      </tfoot>
    </table>
    <div v-if="!isCreate" class="row">
      <div class="col-12">
        <button @click="update()" class="btn btn-success">UPDATE</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pricelevels',
  props:['item', 'isCreate'],
  data () {
    return {
      sales_pricelevels: this.$parent.sales_pricelevels,
    }
  },
  computed : { 
    pricelevels_total(){
      var total = 0;
      this.sales_pricelevels.forEach(function(item){
        total += parseFloat(item.price);
      });
      return total;
    }
  },
  methods :{
    update(){
      if(this.isCreate) return false;

      this.$store.dispatch('post',{
        details:{prices:JSON.stringify(this.sales_pricelevels)},
        endpoint:'/leffit/products/productprices/update-price-level/'+this.item.id
      })
      .then((data)=>{
        console.log(data);
        this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:'Price level was updated successfully',type:'success'});
      })
      .catch((error)=>{
        console.log(error.request);
      });
    }
  },
  created(){},
}
</script>

<style lang="css" scoped>
tfoot td, tfoot th{
  border-top: 3px solid;

}
</style>