<template>
    <div class="row m-0" id="preview">  
        <div class="col-12" style="background:#00000047;height:50px">
            <button @click="$parent.preview_show=false" class="btn btn-danger">close</button>
        </div>
         <div id="templateHolder" :class="'templateHolder'">  
            <div class="col-sm-12 col-md-12 col-lg-12 holder mb-5">  
                <!--  EACH ROW -->
                <div 
                    v-for="(row,index) in sections"
                    :key="index"
                    :class="'row m-0 each-row '+processRowAttr(row.settings)[0]"
                    :style="' '+processRowAttr(row.settings)[1]"
                    >  
                    <div class="resizer" v-if="resizer.row == index" @mousemove="mouseMove($event)" @mouseout="mouseUp()" @mouseup="mouseUp()"></div>

                    <!-- EACH COLUMN -->
                    <div :class="section.classes.join(' ')+' column'" v-for="(section,index2) in row.sections" :key="index2">
                        <div  :id="'section'+section.unique_id"  :class="'temp'" >
                            
                            <span v-html="section.html"> </span> 
                        </div>
                    </div>   
                </div> 
            </div>
        </div>  
    </div> 
</template>

<script>  
    export default {
        name:'create_template', 
        props:['items'],
        data(){
            return {
                editMode:false,
                enlargeMode:false,
                editBackup:null,
                activeIndex:0,
                updatedImages:[],
                editTitle:false,
                uploadings:[],
                default_settings:{
                    margin:{top:'',right:'',left:'',bottom:''},
                    padding:{top:'',right:'',left:'',bottom:''},
                    background:{backgroundColor:'transparent','backgroundImage':'','position':'','size':''},
                    font:{color:'black',size:''},
                    container:{type:'boxed',column:3,align:'left'},
                },
                edit_setting_section:{settings:{}},
                margins_top:['','mt-1','mt-2','mt-3','mt-4','mt-5'],
                margins_bottom:['','mb-1','mb-2','mb-3','mb-4','mb-5'],
                margins_right:['','mr-1','mr-2','mr-3','mr-4','mr-5'],
                margins_left:['','ml-1','ml-2','ml-3','ml-4','ml-5'],
                paddings_top:['','pt-1','pt-2','pt-3','pt-4','pt-5'],
                paddings_bottom:['','pb-1','pb-2','pb-3','pb-4','pb-5'],
                paddings_right:['','pr-1','pr-2','pr-3','pr-4','pr-5'],
                paddings_left:['','pl-1','pl-2','pl-3','pl-4','pl-5'],
                backgroundImage:null,
                uploaded_setting_image:false,
                mouse_down:false,
                xAxis:0,resizer:{col:0,row:null,id:0},
                distance_drag:0,
                sections:[]
            }
        }, 
        mounted(){ 
            
        },
        methods:{ 
            
            bindValues(rows){ 
                rows=rows.map((row)=>{
                    // console.log(row.sections);
                    if(row.sections == undefined){
                        var temp=row;
                        row={sections:[],settings:{}};
                        row.sections=temp;
                        row.settings={...this.default_settings};
                        row.unique_id=Math.floor(Math.random()*9999999);
                    } 
                    row.sections=row.sections.map((section)=>{ 
                        var content=section.content;
                        section.fields.map((field)=>{
                            content=content.replace('++{{'+field.element+'}}',field.content);
                        }); 
                        section.settings={...this.default_settings,...section.settings};
                        section.classes=[];
                        section.html=this.appendPropsToElement(content,section.settings);
                        section.mode='initial';

                        //
                        if(section.settings.container.column){
                            section.classes.push('col-md-'+section.settings.container.column);
                        }

                        //alignment type
                        if(section.settings.container.align == 'right'){ 
                            section.classes.push("ml-auto");
                        }else if(section.settings.container.align == 'center'){ 
                            section.classes.push("m-auto");
                        }else{
                            section.classes.push("mr-auto");
                        }
                        return section;
                    });
                    return row;
                });
                // console.log(rows);
                this.sections=rows;
            },
            appendPropsToElement(element,settings){
                
                if(settings == undefined){
                    return element;
                }
                var domParser=new DOMParser();
                var html=domParser.parseFromString(element,"text/html");
                var body=html.body;
                var first=body.children[0]; 
                
                window.$(first).removeClass('bg-white');
                //attaching setting to the element
                    //margins
                    for(var p in settings.padding){window.$(first).addClass(settings.padding[p]);}
                   //paddings
                    for(var m in settings.margin){ window.$(first).addClass(settings.margin[m]);}      
                    //background
                    window.$(first).css('background-color',settings.background.backgroundColor);
                    window.$(first).css('background-image','url('+settings.background.backgroundImage+')');
                    window.$(first).css('background-size',settings.background.size);
                    window.$(first).css('background-position',settings.background.position);
                    window.$(first).css('color',settings.font.color);

                    if(settings.font.size != ''){
                        window.$(first).css('font-size',settings.font.size);
                    }
                    if(settings.container){
                        //container type 
                        if(settings.container.type == 'fluid'){ 
                            window.$(first).removeClass('container');
                        }else{
                            window.$(first).addClass('container');
                        }
                        

                    }

                    
 
                return window.$(body).html();
            },
            processRowAttr(settings){
                var classes="";
                var styles="";
                //margins
                for(var p in settings.padding){classes+=settings.padding[p]+" "}
                //paddings
                for(var m in settings.margin){ classes+=settings.margin[m]+" "}

                // container
                if(settings.container.type == 'boxed'){
                    classes+="container ";
                }



                if(settings.background.backgroundImage != ''){
                    styles+="background-image:url("+settings.background.backgroundImage+");"
                }
                if(settings.background.backgroundColor != ''){
                    styles+="background-color:"+settings.background.backgroundColor+";";
                }
                if(settings.background.position != ''){
                    styles+="background-position:"+settings.background.position+";";
                }
                if(settings.background.size != ''){
                    styles+="background-size:"+settings.background.size+";";
                }
                if(settings.font.size != ''){
                    styles+="font-size:"+settings.font.size+";";
                }
                if(settings.font.color != ''){
                    styles+="color:"+settings.font.color+";";
                }

                return [classes,styles];
            },
            repairData(data){ 
                if(data.sections != undefined){
                    return data;
                }
                if(data.length == 0){
                    return [];
                }
                if(data[0].id != undefined){
                    data=data.map((d)=> {return [d];});
                }
                // console.log(data);
                return data;
            }
        },
        created(){
            console.log(this.items);
            this.sections=this.items;
            this.bindValues(this.sections);
        }
    }
</script>



<style scoped>
    #preview{
        position: fixed;
        z-index: 999999999999999999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
    }
     /* @import "./section-builder-styles.css"; */
</style>