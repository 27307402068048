<template>
  <!-- notification  -->
  <div id="notification-container">
    <div  v-for="(notification) in notifications.info"  :key="'notification_'+notification.id" class="notification-item notification-info text-light bg-info" @click="NotificationRemove(notification.id,'info')">
        <div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div>
        <div class="icons">
          <span class="text-danger"><i class="fa fa-info" aria-hidden="true"></i> </span>
        </div>
        <div class="texts">
          <h4 v-if="notification.title">{{notification.title}}</h4>
          <p v-if="notification.message">{{notification.message}}</p>
          
        </div>
    </div>

    <div  v-for="(notification) in notifications.success"  :key="'notification_'+notification.id" class="notification-item notification-success text-light bg-success" @click="NotificationRemove(notification.id,'success')">
        <div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div>
        <div class="icons">
          <span class="text-success"><i class="fa fa-check" aria-hidden="true"></i> </span>
        </div>
        <div class="texts">
          <h4 v-if="notification.title">{{notification.title}}</h4>
          <p v-if="notification.message">{{notification.message}}</p>
          
        </div>
    </div>

    <div  v-for="(notification) in notifications.warning"  :key="'notification_'+notification.id" class="notification-item notification-warning text-light bg-warning" @click="NotificationRemove(notification.id,'warning')">
        <div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div>
        <div class="icons">
          <span class="text-danger"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> </span>
        </div>
        <div class="texts">
          <h4 v-if="notification.title">{{notification.title}}</h4>
          <p v-if="notification.message">{{notification.message}}</p>
          
        </div>
    </div>

    <div  v-for="(notification) in notifications.danger"  :key="'notification_'+notification.id" class="notification-item notification-danger text-light bg-danger" @click="NotificationRemove(notification.id,'danger')">
        <div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div>
        <div class="icons">
          <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> </span>
        </div>
        <div class="texts">
          <h4 v-if="notification.title">{{notification.title}}</h4>
          <p v-if="notification.message">{{notification.message}}</p>
          
        </div>
    </div>

    <div  v-for="(notification) in notifications.general"  :key="'notification_'+notification.id" class="notification-item notification-general text-light bg-general" @click="NotificationRemove(notification.id,'general')">
        <div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div>
        <div class="icons">
          <span class="text-general"><i class="fa fa-angle-left" aria-hidden="true"></i> </span>
        </div>
        <div class="texts">
          <h4 v-if="notification.title">{{notification.title}}</h4>
          <p v-if="notification.message">{{notification.message}}</p>
          
    </div>

    </div>
  </div>
  <!-- notification  -->
 </template>


<script>
  import { mapState } from "vuex"; 
  export default {
    name:'notifications',
    data () {
        return { 
        }
    },
    computed:{
      ...mapState({
          notifications:state=>state.leffit.notifications
      })
    },
    methods:{
        NotificationRemove(id,type){
            this.$store.dispatch('removeNotification',{id,type});
        }
    }
  }
</script>

<style scoped>
  #notification-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    top: 0;
    right: 0;
    padding-top: 5px;
    padding-right: 5px;
  }

  .notification-item {
    position: relative;
    pointer-events: auto;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999;
    -webkit-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    color: #fff;
    border-radius: 6px;
    padding: 10px 20px;
    background:#ddd;
  }

  .notification-item .close {
    position: absolute;
    top: 0px;
    right: 0;
    font-size: 15px;
  }

  .notification-item .icons span.pink{
    color: #F1958E;
  }

  .notification-item .icons span.green{
    color: #76B82A;
  }

  .notification-item .icons span.xx{
    color: #E42C1F;
    margin-left: -12px;
    z-index: -1;
  }
 .texts h4,p{
     color: white !important;
 }
  .notification-item .icons span.yellow{
    color: #FEC700;
  }
  .notification-item .icons{
    display: inline-block;
    width: 20px;
    float: left;
  }
  .notification-item .texts{
    display: inline-block;
    width: 280px;
    margin-left: 5px;
  }


  .notification-item .texts h4{
    font-size: 15px;
    color: #555;
    font-weight: 600;
  }
  .notification-item .texts p{
    line-height: 1;
    color: #555;
    margin-top: 5px;

  }

  .progress{
    height: 5px;
    background: lightgray;
    position: relative;
  }
  .progress span{
    position: absolute;
    top:0%;
    left: 0;
    height: 100%;
    /* width: 50%; */
    background: #4CAF50;
  }
  .prg{
    position: fixed;
    width: 200px !important; 
    bottom: 5%;
    height: -webkit-fit-content;
    left:0; 
    background: white;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    box-shadow: 0px 0px 10px darkgrey;
    padding-bottom: 10px;
    z-index: 99999999999999999;
  }
  .prg .icons{

  }
  .prg h4{
    font-size: 12px;
    margin-bottom: 5px;
    color: gray;
    text-align: center;
  }
  .prg h4 i{
    float: left;
    color: green;
    font-size: 13px;
  }
  .prg h4 span{
    color: green;
    float: right;
    font-size: 13px;
  }
  .black{
    color: #000;
  }
</style>