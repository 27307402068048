<template>
    <div>
        <div class="form-group col-lg-10 d-flex" style="justify-content:space-between">
            <v-select v-model="value" label="text" :options="options" name="" id="selectFilter" class="col-12" style="width:100%">
            </v-select>
            <button @click="addAdmin()" class="btn btn-sm btn-success mt-1" style="margin-top:-5px"><i class="fa fa-plus"></i></button>
        </div> 
        <div class="row p-2 m-0 mb-5">
            <table class="table table-responsive table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ $t('leffit.crm.address.name') }}</th>
                        <th>{{ $t('leffit.crm.address.address') }}</th>
                        <th>{{ $t('leffit.crm.address.address2') }}</th>
                        <th>{{ $t('leffit.crm.address.zipcode') }}</th>
                        <th>{{ $t('leffit.crm.address.city') }}</th>
                        <th>{{ $t('leffit.crm.address.country') }}</th>
                        <th>{{ $t('leffit.crm.address.phone') }}</th>
                        <th>{{ $t('leffit.crm.address.mobile') }}</th>
                        <th>{{ $t('leffit.crm.address.fax') }}</th>
                        <th>{{ $t('leffit.crm.address.email') }}</th> 
                        <th>{{ $t('leffit.crm.address.created_at') }}</th>
                        <th>{{ $t('leffit.crm.address.updated_at') }}</th>
                        <th>{{ $t('leffit.crm.address.deleted_at') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in item.addresses" :key="index">
                        <td>
                            <router-link :to="'/admin/crm/addresses/'+item.id" class="btn btn-open">
                                <i class="fa fa-search"></i>
                            </router-link>
                            <router-link :to="'/admin/crm/addresses/'+item.id+'/edit'" class="btn btn-warning">
                                <i class="fa fa-pencil"></i>
                            </router-link>
                            <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
                                <i class="fa fa-remove"></i>
                            </a>
                        </td>
                        <td>{{ item.name }} </td>
                            <td>{{ item.address }} </td>
                            <td>{{ item.address2 }} </td>
                            <td>{{ item.zipcode }} </td>
                            <td>{{ item.city }} </td>
                            <td>{{ item.country }} </td>
                            <td>{{ item.phone }} </td>
                            <td>{{ item.mobile }} </td>
                            <td>{{ item.fax }} </td>
                            <td>{{ item.email }} </td> 
                            <td>{{ item.created_at }} </td>
                            <td>{{ item.updated_at }} </td>
                            <td>{{ item.deleted_at }} </td>
                    </tr>
                </tbody>
            </table> 
        </div> 
    </div>
</template>

<script>
export default { 
    props:{item:{required:true}},
    data(){
        return {
            users:[],
            items:[],
            options:[],
            value:0,
        }
    },
    methods:{
        deleteItem(id){
            if(!confirm("Are you sure you want to delete this item?")){
                return false;
            }
            this.item.addresses=this.item.addresses.filter(ft => ft.id != id);
            this.$store.dispatch('get','/leffit/crm/remove-addresses/'+this.item.id+'/'+id)
            .then(data=>{
                // console.log('result',data.data.data);
                // this.users = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:'Item was updated successfully',type:'success'});
            });
        },
        addAdmin(){ 
            setTimeout(()=>{
                var item=this.items.find((ft)=>ft.id == this.value.id);
                if(item){
                    this.item.addresses.unshift(item);
                } 
                this.$store.dispatch('get','/leffit/crm/add-addresses/'+this.item.id+'/'+this.value.id)
                .then(data=>{
                    console.log('result',data);
                    // this.users = data.data.data;
                    this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
                });
            },1000);
            
        }
    },
    created() {
        this.$store.dispatch('get','/leffit/crm/addresses')
        .then(data=>{
            console.log('result',data.data.data);
            var items=data.data.data;
            this.items=items;
			this.options =items.map((item)=>{
                return {id:item.id,text: item.name};
            });

		});
    },
}
</script>