<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},
    computed:{
        menu : function(){
            return  [
                        {
                            title   : this.$t('lefficient.leffit.settings.currencies'),
                            url     : '/admin/settings/currencies',
                            children : [],
                        },
                        {
                            title   : this.$t('lefficient.leffit.settings.users'),
                            url     : '/admin/settings/users',
                            children : [],
                        },
                        {
                            title       : this.$t('lefficient.leffit.settings.roles'),
                            url         : '/admin/settings/roles',
                            children    :[] 
                        },
                        {
                            title       : this.$t('lefficient.leffit.settings.permissions'),
                            url         : '/admin/settings/permissions', 
                            children    :[]
                        },
                        {
                            title       : this.$t('lefficient.leffit.settings.generator'),
                            url         : '/admin/settings/generator', 
                            children    :[]
                        },
                        // {
                        //     title       : this.$t('lefficient.leffit.settings.routes'),
                        //     url         : '/admin/settings/routes', 
                        //     children    :[]
                        // },
                        {
                            // title       : this.$t('lefficient.leffit.settings.import_export'),
                            title       : 'Import/Export',
                            // url         : '/admin/settings/import-export', 
                            children    :[
                                {
                                    // title       : this.$t('lefficient.leffit.settings.import'),
                                    title:'Import',
                                    url         : '/admin/settings/import', 
                                    children    :[]
                                },
                                {
                                    // title       : this.$t('lefficient.leffit.settings.export'),
                                    title: 'Export',
                                    url         : '/admin/settings/export', 
                                    children    :[]
                                },
                                {
                                    title       : 'Bulk files',
                                    url         : '/admin/settings/bulk-files',
                                    children    :[] 
                                }, 
                            ]
                        },
                        {
                            title       : this.$t('leffit.orders.order.ordertypes'),
                            url         : '/admin/settings/ordertypes', 
                            children    :[]
                        },
                        {
                            title       : this.$t('lefficient.leffit.settings.packages'),
                            url         : '/admin/settings/packages', 
                            children    : [
                                // ...this.package_settings_menu,
                                {
                                    title:'Contact',
                                    url:'/admin/settings/packages/model/crm_contact_settings',
                                    children:[]
                                },
                                {
                                    title:'Organization',
                                    url:'/admin/settings/packages/model/crm_organization_settings',
                                    children:[]
                                },
                                {
                                    title:'Product Filters',
                                    url:'/admin/settings/packages/model/product_filter_settings',
                                    children:[]
                                },
                                {
                                    title:'Products',
                                    url:'/admin/settings/packages/model/product_settings',
                                    children:[]
                                },
                                {
                                    title:'Product Types',
                                    url:'/admin/settings/packages/model/product_type_settings',
                                    children:[]
                                },
                                {
                                    title:'Purchase Keurloon',
                                    url:'/admin/settings/packages/config/purchase_keurloon',
                                    children:[]
                                }
                            ],
                        },
            ];
        },  
    },
    data () {
        return  { 
            package_settings_menu : []
        }
    },
    mounted() {
        this.$store.dispatch('get', '/lefficient/leffit/packages').then(data => {
            let packages = data.data
            for(let i in packages){
                this.package_settings_menu.push({
                    title: packages[i],
                    url: '/admin/settings/packages/'+packages[i],
                    children: []
                })
            }
        })
    }
}
</script>

<style lang="css" scoped>
</style>