import newletterIndex from "../../../../src/leffit/newletters/index";
import create_template from "../../../../src/leffit/newletters/create_template";

export default [
    {
        path:'/',
        component:newletterIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'edit/:id',
        component:create_template,
        meta:{
            AuthRequired:true
        }
    }
]