<template>
  <div>
    <div class="row p-2 m-0 mb-5">
      <table class="table table-hover">
        <thead>
          <tr>
            <td></td>
            <td colspan="3">
              <div class="row">
                <div class="form-group col-lg-10">
                  <select2 v-model="selected" :options="options" name="" id="">
                  </select2>
                </div>
              </div>
            </td>
            <td>
              <div class="form-group col-lg-1">
                <button
                  @click="addAdmin()"
                  class="btn btn-sm btn-success"
                  style="margin-top:-5px"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("leffit.crm.address.name") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ft, index) in item.organizations" :key="index">
            <td>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                  @click="remove(ft.id)"
                  class="btn btn-sm pl-2 pr-2 btn-danger"
                >
                  <i class="fa fa-remove"></i>
                </button>
              </div>
            </td>
            <td>{{ ft.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: { item: { required: true } },
  data() {
    return {
      users: [],
      items: [],
      options: [],
      selected: 0,
      selected_list: [],
    };
  },
  methods: {
    remove(id) {
      if (!confirm("Are you sure you want to delete this item?")) {
        return false;
      }
      this.item.organizations = this.item.organizations.filter(
        (ft) => ft.id != id
      );
      this.$store
        .dispatch(
          "get",
          "/leffit/crm/detach-organization-to-contact/" +
            this.item.id +
            "/" +
            id
        )
        .then((data) => {
          // console.log('result',data.data.data);
          // this.users = data.data.data;
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: "Item was updated successfully",
            type: "success",
          });
        });
    },
    addAdmin() {
      setTimeout(() => {
        var item = this.items.find((ft) => ft.id == this.selected);
        if (item) {
          this.item.organizations.unshift(item);
        }
        this.$store
          .dispatch(
            "get",
            "/leffit/crm/attach-organization-to-contact/" +
              this.item.id +
              "/" +
              this.selected
          )
          .then(() => {
            // this.users = data.data.data;
            this.$store.dispatch("makeNotification", {
              title: "ITEM UPDATED",
              text: this.item.name + " was updated successfully",
              type: "success",
            });
          });
      }, 1000);
    },
  },
  created() {
    this.$store.dispatch("get", "/leffit/crm/organizations").then((data) => {
      var items = data.data.data;
      this.items = items;
      this.options = items.map((item) => {
        return { id: item.id, text: item.name };
      });
    });
  },
};
</script>
<style scoped></style>
