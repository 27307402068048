var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-0",attrs:{"id":"dashboard"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"modal",attrs:{"id":"exampleModal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title text-uppercase"},[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.new_module')))]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('form',{staticClass:"mb-5",attrs:{"action":""},on:{"submit":function($event){return _vm.createNewsletter($event)}}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"form-control",attrs:{"id":"title","type":"text","placeholder":_vm.$t('leffit.courses.modules.template_title')},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.Short_description')))]),_c('editor',{attrs:{"init":{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                    }},model:{value:(_vm.short_description),callback:function ($$v) {_vm.short_description=$$v},expression:"short_description"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.description')))]),_c('editor',{attrs:{"init":{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                    }},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('formerror')],1),_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"},on:{"click":function($event){return _vm.createNewsletter($event)}}},[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.create')))]),_c('button',{staticClass:"btn btn-secondary float-right",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.close')))])])])])]),_c('button',{staticClass:"btn btn-success float-right mb-3",attrs:{"type":"button","data-toggle":"modal","data-target":"#exampleModal"}},[_vm._v(" "+_vm._s(_vm.$t('leffit.courses.modules.new_module'))+" ")])]),_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"display",staticStyle:{"width":"100%"},attrs:{"id":"example"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.title')))]),_c('th',[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.Short_description')))]),_c('th',[_vm._v(_vm._s(_vm.$t('leffit.courses.modules.Action')))])])]),_c('tbody',_vm._l((_vm.newsletters),function(nlt,index){return _c('tr',{key:index},[_c('td',[_c('a',{attrs:{"href":'modules/edit/'+nlt.id}},[_vm._v(_vm._s(nlt.title))])]),_c('td',{domProps:{"innerHTML":_vm._s(nlt.short_description)}}),_c('td',[_c('div',[_c('a',{staticClass:"btn btn-sm btn-success",attrs:{"href":'modules/edit/'+nlt.id}},[_vm._v("OPEN")]),_vm._v("   "),_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.deleteN(nlt.id)}}},[_vm._v("DELETE")])])])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }