import ProcurementQuotationIndex from '../../../../src/leffit/orders/order/index';
import ProcurementQuotationItem from '../../../../src/leffit/orders/order/item';
import ProcurementQuotationCreate from '../../../../src/leffit/orders/order/create';

import ProcurementOrderIndex from '../../../../src/leffit/orders/order/index';
import ProcurementOrderItem from '../../../../src/leffit/orders/order/item';
import ProcurementOrderCreate from '../../../../src/leffit/orders/order/create';

import ProcurementOrderConfirmationIndex from '../../../../src/leffit/orders/order/index';
import ProcurementOrderConfirmationItem from '../../../../src/leffit/orders/order/item';
import ProcurementOrderConfirmationCreate from '../../../../src/leffit/orders/order/create';

import ProcurementReceiptIndex from '../../../../src/leffit/orders/order/index';
import ProcurementReceiptItem from '../../../../src/leffit/orders/order/item';
import ProcurementReceiptCreate from '../../../../src/leffit/orders/order/create';

import ProcurementInvoiceIndex from '../../../../src/leffit/orders/order/index';
import ProcurementInvoiceItem from '../../../../src/leffit/orders/order/item';
import ProcurementInvoiceCreate from '../../../../src/leffit/orders/order/create';

import ProcurementReturnIndex from '../../../../src/leffit/orders/order/index';
import ProcurementReturnItem from '../../../../src/leffit/orders/order/item';
import ProcurementReturnCreate from '../../../../src/leffit/orders/order/create';

import ProcurementCreditInvoiceIndex from '../../../../src/leffit/orders/order/index';
import ProcurementCreditInvoiceItem from '../../../../src/leffit/orders/order/item';
import ProcurementCreditInvoiceCreate from '../../../../src/leffit/orders/order/create';

// Order
// import Order from '../../../../src/leffit/orders/order/item';

var tmp = process.env.VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES.split(',');
var VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES = [];

tmp.forEach(function(item){
    item = item.split('|');
    VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES[item[0]] = parseInt(item[1]);
});

export default [
    // {
    //     path:"orders/:id",
    //     component: Order,
    //     meta:{
    //         AuthRequired:true
    //     }, 
    // },
    {
        path:"orders/procurement_quotation",
        component: ProcurementQuotationIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_quotation']
        }
    },
    {
        path:"orders/procurement_quotation/create/:id",
        component: ProcurementQuotationItem,
        meta:{
            AuthRequired:true
        }, 
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_quotation']
        }
    },
    {
        path:"orders/procurement_quotation/create",
        component: ProcurementQuotationCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_quotation']
        }
    },

    {
        path:"orders/procurement_order",
        component: ProcurementOrderIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order']
        }
    },
    {
        path:"orders/procurement_order/create/:id",
        component: ProcurementOrderItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order']
        }
    },
    {
        path:"orders/procurement_order/create",
        component: ProcurementOrderCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order']
        }
    },

    {
        path:"orders/procurement_order_confirmation",
        component: ProcurementOrderConfirmationIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order_confirmation']
        }
    },
    {
        path:"orders/procurement_order_confirmation/create/:id",
        component: ProcurementOrderConfirmationItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order_confirmation']
        }
    },
    {
        path:"orders/procurement_order_confirmation/create",
        component: ProcurementOrderConfirmationCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_order_confirmation']
        }
    },

    {
        path:"orders/procurement_receipt",
        component: ProcurementReceiptIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_receipt']
        }
    },
    {
        path:"orders/procurement_receipt/create/:id",
        component: ProcurementReceiptItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_receipt']
        }
    },
    {
        path:"orders/procurement_receipt/create",
        component: ProcurementReceiptCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_receipt']
        }
    },


    {
        path:"orders/procurement_invoice",
        component: ProcurementInvoiceIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_invoice']
        }
    },
    {
        path:"orders/procurement_invoice/create/:id",
        component: ProcurementInvoiceItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_invoice']
        }
    },
    {
        path:"orders/procurement_invoice/create",
        component: ProcurementInvoiceCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_invoice']
        }
    },

    {
        path:"orders/procurement_return",
        component: ProcurementReturnIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_return']
        }
    },
    {
        path:"orders/procurement_return/create/:id",
        component: ProcurementReturnItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_return']
        }
    },
    {
        path:"orders/procurement_return/create",
        component: ProcurementReturnCreate,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_return']
        }
    },

    {
        path:"orders/procurement_invoice_credit",
        component: ProcurementCreditInvoiceIndex,
        meta:{ 
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_invoice_credit']
        }
    },
    {
        path:"orders/procurement_invoice_credit/create/:id",
        component: ProcurementCreditInvoiceItem,
        meta:{
            AuthRequired:true
        },
        props:{
            ordertype_id : VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES['procurement_invoice_credit']
        }
    },
    {
        path:"orders/procurement_invoice_credit/create",
        component: ProcurementCreditInvoiceCreate,
        meta:{
            AuthRequired:true
        }
    },
]