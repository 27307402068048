<template>
    <div>
        <div class="row">
            <div class="col-10 upload-container"> 
                <button @click="exportVirgoData()" class="btn btn-success">
                    <span v-if="!loading">Export Virgo</span>
                    <span v-else>Exporting</span>
                </button>

                <div class="row">
                    <div class="col-12">
                        <div class="form-groups mt-5">
                            <label for="">Import Virgo(csv format)</label>
                            <input @change="file_selected()" id="filevirgo" type="file" class="form-control" accept=".csv,.xlsx">
                        </div>
                        <div v-show="success_text.length > 0" class="alert mt-2">
                            <p class="m-0 p-0 text-success">{{success_text}}</p>
                        </div>
                        <div v-show="error_text.length > 0" class="alert mt-2">
                            <p class="m-0 p-0 text-danger">{{error_text}}</p>
                        </div>
                        <button v-if="show_importbutton" @click="importVirgo()" class="btn btn-default">
                            <span v-if="!loading2">Import Virgo</span>
                            <span v-else>Importing....</span>
                        </button>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            loading:false,
            loading2:false,
            success_text:'',
            error_text:'',
            show_importbutton:false
        }
    },
    methods: { 
        file_selected(){
            this.show_importbutton = true;
        },
        async exportVirgoData(){
            if(this.loading) return false; 
            this.loading = true;
            this.success_text = '';
            this.error_text = '';
            
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export?model=virgo',
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if(response.status == 200 ){
                    console.log(response);
                    const type = response.headers['content-type']
                    const blob = new File([response.data],{type: 'application/octet-stream'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'virgo.csv';
                    link.click(); 
                    
                    this.$store.dispatch('displayNotification',{
                        type:'success',
                        id:Math.round(Math.random()*100),
                        message:'Exported successfully.',
                        interval:5000,
                        autoDismiss:true
                    })
                }
                else{ 
                    if(response.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                    }
                } 
            this.loading = false;
        },
        async importVirgo(){
            var files = document.getElementById('filevirgo').files;
            if(files.length == 0) return false;

            if(this.loading2) return false; 
            this.loading2 = true;
            
            var form_data = new FormData();
            form_data.append('file', files[0]);
            var response = await this.$store.dispatch('post', {
                details:form_data,
                endpoint:'/lefficient/leffit/import?model=product-virgo'
            })

            if(response.status == 200){
                this.success_text = 'Virgo file was uploaded successfully';
            }else{
                this.error_text = 'Unable to upload file.';
            }
            this.loading2 = false;
        }
    },
    create(){}
}
</script>
<style scoped>
    .btns{
        position: absolute;
        height: fit-content;
        width: fit-content;
        margin: auto;
        top:0;
        bottom:0;
        left:0;
        right:0;
    }
    .upload-container{ 
        height: 400px;
    }
</style>