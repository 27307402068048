<template>
  <div class="d-flex flex-column flex-lg-row" id="mainBody">
    <sidebarInnerHardcoded :menu="menu" />
      <div style="width: 100% !important" class="mainPanel">
        <div>
          <div class="head row">
            <h3 class="pl-3">{{ $t("leffit.emails.title") }}</h3>
          </div>
          <!----><!----><!----><!----><!----><!---->
          <div>
            <table class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t("leffit.emails.language") }}</th>
                  <th>{{ $t("leffit.emails.name") }}</th>
                  <th>{{ $t("leffit.emails.subject") }}</th>
                  <th>{{ $t("leffit.emails.created_at") }}</th>
                  <th>{{ $t("leffit.emails.updated_at") }}</th>
                  <th>{{ $t("leffit.emails.deleted_at") }}</th>
                </tr>
              </thead>
              <thead></thead>
              <tbody>
                <tr v-for="(emailtemp, index) in mailtemplate" :key="index">
                  <td>
                    <a
                      :href="'/admin/email/detail/' + emailtemp.id"
                      class="btn btn-open"
                      ><i class="fa fa-search"></i
                    ></a>
                    <a
                      :href="'/admin/email/edit/' + emailtemp.id"
                      class="btn btn-warning"
                      ><i class="fa fa-pencil"></i
                    ></a>
                    <a @click="deletetemp(emailtemp.id)" class="btn btn-warning"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </td>
                  <td>{{ emailtemp.language }}</td>
                  <td>{{ emailtemp.name }}</td>
                  <td>{{ emailtemp.subject }}</td>
                  <td>{{ emailtemp.created_at }}</td>
                  <td>{{ emailtemp.updated_at }}</td>
                  <td>{{ emailtemp.deleted_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import sidebarInnerHardcoded from "./../../layout/components/sidebar_inner_hardcoded";
import { mapState } from "vuex";
export default {
  name: "index",
  components: {
    sidebarInnerHardcoded,
  },
  computed: {
    ...mapState({
      mailtemplate: (state) => state.leffit.mailtemplate,
    }),
  },
  data() {
    return {
      menu: [
        {
          title: this.$t("leffit.email.templates"),
          url: "/admin/email/",
          children: [],
        },

        {
          title: this.$t("leffit.email.add"),
          url: "/admin/email/template",
          children: [],
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch("get", "/lefficient/leffit/mailtemplates")
      .then((data) => {
        this.$store.state.leffit.mailtemplate = data.data.data;
        this.$store.state.leffit.loader.show = false;
        console.log(this.$store.state.leffit.mailtemplate);
      })
      .catch((error) => {
        this.$store.state.leffit.formErrors.push("Server error!!");
        console.log(error.request);
        this.$store.state.leffit.loader.show = false;
      });
  },
  methods: {
    deletetemp(id) {
      this.$store
        .dispatch("delete", "/lefficient/leffit/mailtemplates/" + id)
        .then((data) => {
          this.$store.state.leffit.mailtemplate =
            this.$store.state.leffit.mailtemplate.filter((d) => d.id != id);
          this.$store.state.leffit.loader.show = false;
          console.log(this.$store.state.leffit.mailtemplate);
        })
        .catch((error) => {
          this.$store.state.leffit.formErrors.push("Server error!!");
          console.log(error);
          this.$store.state.leffit.loader.show = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
