<template>
	<div>
		<h2>Import Settings</h2>

        <div class="row m-0 p-0">
            <div class="accordion" id="accordion1" style="width:100%">
                <div v-for="(module,index) in modules" :key="index" class="card" style="border:none">
                    <div style="background: #8080804f;" class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <a style="color: black;" class="btn btn-link btn1" data-toggle="collapse" :href="'#module'+index" aria-expanded="true" :aria-controls="'#module'+index">
                            {{module.modulename}}
                            <button @click="save()" style="background:white" class="btn btn-mute float-right">Update</button>
                        </a>
                    </h2>
                    </div> 
                    <div :id="'module'+index" class="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                        <div class="accordion" id="accordion2">
                            <div v-for="(model,index2) in module.models" :key="index2" class="card" style="border-color:lightgray;border-radius: 0;">
                                <div class="card-header" id="headingOne">
                                <h2 class="mb-0 d-flex">
                                    <a class="btn btn-link btn1" data-toggle="collapse" :href="'#model'+index+'-'+index2" aria-expanded="true" :aria-controls="'#model'+index+'-'+index2">
                                        {{model.name}} 
                                    </a>

                                    <div class="float-right">
                                        <div class="form-group m-0 p-0">
                                            <label for="">UPLOAD CSV FILE</label>
                                            <input type="file" class="form-control" @change="readFile($event,model.export_name)">
                                        </div>
                                    </div>
                                </h2>
                                </div> 
                                <div :id="'model'+index+'-'+index2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion2">
                                    <!-- <div > -->
                                        <label v-for="(attr,index3) in model.attributes" :key="index3" class="card-body attr p-0 pl-4 pt-2 pb-2 pr-2" :for="attr.name+'attr'+index+''+index2+''+index3">
                                            <input v-model="attr.import" :id="attr.name+'attr'+index+''+index2+''+index3" type="checkbox">
                                            {{attr.name}}
                                        </label>
                                    <!-- </div> -->
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>
        </div>
	</div>
</template>

<script>
import axios from 'axios';
	export default {
		data(){
            return {
                modules:[
                    {
                        modulename:'product',
                        models:[
                            {
                                name:'product',
                                title:'Product',
                                export_name:'product',
                                attributes:[
                                    {
                                        'name' 		: 'name',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 	: 'description',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 	: 'producttype_id',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'ean',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'productstatus_id',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'price',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'weight',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'vat',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'stock',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'size',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'remarks',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                     
                                ]
                            },
                            {
                                name:'product_filter',
                                title:'Product Filter',
                                export_name:'productfilter',
                                attributes:[
                                    {
                                        'name' 		: 'name',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'description',
                                        'export'    :false,
                                        'import'    :false
                                    }, 
                                    {
                                        'name' 		: 'lang',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    
                                ]
                            },
                            {
                                name:'product_filter_meta',
                                title:'Product Filter Meta',
                                export_name:'productmetafilter',
                                attributes:[
                                    {
                                        'name' 		: 'group',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'key',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'value',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'lang',
                                        'export'    :false,
                                        'import'    :false
                                    }, 
                                ]
                            },
                            {
                                name:'product_filter_meta_label',
                                title:'Product Filter Meta Label',
                                export_name:'productmetafilterlabel',
                                attributes:[
                                    {
                                        'name' 		: 'group',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'key',
                                        'export'    :false,
                                        'import'    :false
                                    },
                                    {
                                        'name' 		: 'label',
                                        'export'    :false,
                                        'import'    :false
                                    }, 
                                ]
                            }, 
                        ], 
                    }, 
                    {
                        modulename:'contact',
                        models:[
                            {
                                name:'contact',
                                title:'Contact',
                                export_name:'contact',
                                attributes:[ 
                                    {
                                        'name':'title' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'firstname',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'prelastname',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'lastname' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'address' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'address2' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'zipcode' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'city' 	,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'country' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'phone' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'mobile' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'fax' 	,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'email' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'picture' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'function' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'birthday' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'gender' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'coc' 	,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'vat_number',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'iban_name',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'iban_number',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'swift_code',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'language' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'remarks' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'status_id',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'parent_id',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'optin' ,
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'created_at',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'updated_at',
                                        'export':false,
                                        'import':false
                                    },
                                    {
                                        'name':'deleted_at',
                                        'export':false,
                                        'import':false
                                    },
                                ]
                            }, 
                        ], 
                    },
                    {
                        modulename:'organization',
                        models:[
                            {
                                name:'organization',
                                title:'Organization',
                                export_name:'organization',
                                attributes:[ 
                                    {
                                        'name':'name',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'address',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'address2',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'zipcode',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'city',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'country',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'phone',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'mobile',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'fax',
                                        'export':false,
                                        'import':false
                                    },						
                                    {
                                        'name':'email',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'picture',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'coc',
                                        'export':false,
                                        'import':false
                                    },						
                                    {
                                        'name':'vat_number',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'iban_name',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'iban_number',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'swift_code',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'language',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'remarks',
                                        'export':false,
                                        'import':false
                                    },					
                                    {
                                        'name':'status_id',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'parent_id',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'organizationtype_id',
                                        'export':false,
                                        'import':false
                                    },		
                                    {
                                        'name':'created_at',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'updated_at',
                                        'export':false,
                                        'import':false
                                    },				
                                    {
                                        'name':'deleted_at',
                                        'export':false,
                                        'import':false
                                    },				
                                ]
                            }, 
                        ], 
                    },
                ]
            }
        },
        methods:{
            readFile(e,model){
                var file=e.srcElement.files[0];
                if(file != null ){
                    if(confirm("Do you want to upload this file?")){
                        this.importProduct(model,file);
                    }   
                }
            },
            importProduct(filterName,file) {
                //console.log(filterName);
                let formData = new FormData()
                formData.append('file', file);
                let header = {
                    'Content-Type': 'multipart/form-data'
                }
                axios.post(process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/import/?model='+filterName, formData, header)
                    .then(result => {
                        console.log(result); 
                        this.$store.dispatch('makeNotification',{title:'Upload successfull',text:'File was uploaded successfully',type:'success'});
                    })
                    .catch(error => {  
                        this.$store.dispatch('makeNotification',{title:'Upload error',text:'Upload error occured',type:'danger'});
                        
                    })
            },
            save(){ 
                this.$store.dispatch('put',{
                    details:{root:JSON.stringify(this.modules)},
                    endpoint:'/lefficient/leffit/packages/settings/export',
                })
                .then((data)=>{
                    console.log(data);
                    this.$store.dispatch('makeNotification',{title:'ITEM UPDATE',text:'Item was updated successfully',type:'success'});
                })
                .catch((error)=>{
                    console.log(error);
                });
            }
        },
        created(){
            this.$store.dispatch('get','/lefficient/leffit/packages/settings/export')
            .then((data)=>{
                var temp=JSON.parse(data.data.setting);
                this.modules=this.modules.map((md)=>{
                    var server_module=temp.find((e)=> e.modulename == md.modulename);

                    if(server_module) {
                        md.models=md.models.map((d)=>{
                            var server_model=server_module.models.find((e)=> e.name == d.name);
                            if(server_model){
                                server_model.attributes=d.attributes.map((atr)=>{ 
                                    var attr=server_model.attributes.find((sm)=> sm.name == atr.name); 
                                    if(attr){
                                        return attr
                                    }
                                    return atr;
                                })
                                return server_model;
                            }
                            return d;
                        });
                    }

                    return md;
                });
                console.log(this.modules);
            })
            .catch((error)=>{
                console.log(error);
            });
        }
	}
</script>

<style scoped>
	.btn1{
        width: 100%;
        text-align: left;
        border: none;
    }
    .attr:hover{
        cursor: pointer;
        background: #d8d8d87a !important;
    }
    .attr{
        width: 100%;
    }
</style>