<template>
  <div>
    <div class="row p-2 m-0 mb-5">
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("leffit.products.product_filter.group") }}</th>
            <th>{{ $t("leffit.products.product_filter.filter") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ft, index) in filters" :key="index">
            <td></td>
            <td>{{ ft.name }}</td>
            <td colspan="3">
              <div class="row">
                <div class="form-group col-lg-10">
                  <Select2
                    @select="selectFilter(ft)"
                    v-model="ft.value"
                    :options="
                      ft.subfilters.map((d) => {
                        return { id: d.id, text: d.name };
                      })
                    "
                    class="select2 col-12"
                    style="width: 100%"
                  >
                  </Select2>
                </div>
                <div class="lg-2">
                  <a
                    v-if="selected_filters[ft.id]"
                    @click="removeSelection(ft)"
                    href="#"
                    class="text-danger d-block mt-3"
                    ><i class="fas fa-remove"></i
                  ></a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item", "filter_id"],
  data() {
    return {
      filters: [],
      selected_filters: {},
    };
  },
  methods: {
    isAttached(id) {
      return this.item.productfilters.find((d) => d.id == id) ? true : false;
    },
    selectFilter(d) {
      var temp = this.selected_filters;
      temp[d.id] = d.value;
      this.selected_filters = [];
      this.selected_filters = temp;

      this.$store
        .dispatch(
          "get",
          "/leffit/products/add-product-to-filter/" +
            d.value +
            "/" +
            this.item.id
        )
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            type: "success",
            title: "",
            text: "Filter was added successfully",
          });
        });
    },
    removeSelection(ft) {
      this.$store
        .dispatch(
          "get",
          "/leffit/products/remove-product-from-filter/" +
            ft.value +
            "/" +
            this.item.id
        )
        .then((data) => {
          console.log("result", data.data);
          this.$store.dispatch("makeNotification", {
            type: "success",
            title: "",
            text: "Filter was added successfully",
          });
        });
      ft.value = 0;
      var temp = this.selected_filters;
      delete temp[ft.id];
      this.selected_filters = [];
      this.selected_filters = temp;
    },
  },
  mounted() {
    this.$store
      .dispatch("get", "/leffit/products/get-productfilters/" + this.filter_id)
      .then((data) => {
        this.filters = data.data.data;
        var item = null;
        this.filters = this.filters.map((d) => {
          d.value = "";
          d.subfilters = d.subfilters.map((sp) => {
            item = this.item.productfilters.find((s) => s.id == sp.id);
            if (item) {
              d.value = item.id;
              this.selected_filters[d.id] = item.id;
            }
            return sp;
          });
          return d;
        });
        setTimeout(() => {
          //window.$('.select2').select2();
        });
      });
  },
  created() {},
};
</script>
