<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			 <div class="textEditor form-group">
        
          <label for="">{{$t('leffit.emails.template_name')}}</label>
          <div>{{templatefield.name}}</div>
        </div>

        <div class="textEditor form-group">
          <label for="">{{$t('leffit.emails.subject')}}</label>
          <div>{{templatefield.subject}}</div>
        </div>
        <div class="textEditor form-group">
        
          <label for="">{{$t('leffit.emails.identifier')}}</label>
          <div>{{templatefield.identifier}}</div>
        </div>

    <div  class="textEditor">
      <label for="">{{$t('leffit.emails.description')}}</label>
     <div v-html="templatefield.content"> </div>
      </div>
 
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../../layout/components/sidebar_inner_hardcoded'; 
import { mapState } from "vuex";
export default {
  	name: 'index',
  	components: {
      sidebarInnerHardcoded
    },
  	data () {
    	return {
        menu : [
          {
            title   : this.$t('leffit.email.templates'),
            url   : '/admin/email/',
            children : [],
          },

          {
            title   : this.$t('leffit.email.add'),
            url   : '/admin/email/template',
            children : [],
          },
        ],
       templatefield:{name:'',subject:'',content:'',identifier:''},
       
    		
    	}
  	},created(){
       let id=this.$route.params.id; 
       this.$store.state.leffit.loader.show=true;
        this.$store.dispatch('get','/lefficient/leffit/mailtemplates/'+id)
            .then(data=>{
               
               if(data.data == ''){ this.$router.push('/');}

                    let template=data.data;
                    console.log(template.data);
                    this.templatefield=template.data;
                    if(template.data.name != null && template.data.name != ''){
                        this.templatefield=JSON.parse(template.content);
                        template.content='';
                        this.templatefield=template;
                    }
                    this.$store.state.leffit.loader.show=false;
                    
                
            })
            .catch(error=>{
                this.$store.state.leffit.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.leffit.loader.show=false;
            });
   }
   
}
</script>

<style lang="css" scoped>
</style>