<template>
    <div>
        	<div class="row p-2 m-0 mb-5">
                <table class="table table-hover">
                       <thead>
                        <tr>
                            <td></td>
                            <td colspan="3">
                                <div class="row">
                                    <div class="form-group col-lg-10">
                                        <v-select label="text" v-model="admin" :options="users_options" id="selectFilter" class="col-12 form-control" style="width:100%">
                                        </v-select>
                                    </div> 
                                </div>
                            </td> 
                            <td><div class="form-group col-lg-1"><button @click="addAdmin()" class="btn btn-sm btn-success" style="margin-top:-5px"><i class="fa fa-plus"></i></button></div></td>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ $t('leffit.crm.address.name') }}</th>
                        </tr>
                    </thead> 
                    <tbody>
                            <tr v-for="(ft,index) in item.admins" :key="index">
                                <td>
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button @click="remove(ft.id)"  class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-remove"></i></button>
                                    </div>
                                </td>
                                <td>{{ft.firstname+' '+ft.lastname}}</td>
                            </tr>
                    </tbody>
                </table>
            </div> 
    </div>
</template>

<script>
export default {
    name:'productFilters',
    props:['item'],
    data(){
        return {
            users:[],
            users_options:[],
            admin:0
        }
    },
    methods:{
        remove(id){
            if(!confirm("Are you sure you want to delete this item?")){
                return false;
            }
             this.item.admins=this.item.admins.filter(ft => ft.id != id);
              this.$store.dispatch('get','/leffit/crm/remove-organization-admin/'+this.item.id+'/'+id)
            .then(data=>{
                // console.log('result',data.data.data);
                // this.users = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:'Item was updated successfully',type:'success'});
            });
        },
        addAdmin(){  
            this.item.admins.unshift(this.users.find((ft)=>ft.id == this.admin.id));
            this.$store.dispatch('get','/leffit/crm/add-organization-admin/'+this.item.id+'/'+this.admin.id)
            .then(data=>{
                console.log('result',data.data.data);
                // this.users = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
        }
    },
    created() {
        this.$store.dispatch('get','/lefficient/leffit/users')
        .then(data=>{
            // console.log('result',data.data.data); 
            this.users=data.data.data;
			this.users_options = this.users.map((user)=>{
                    return {id:user.id,text: user.firstname+' '+user.lastname};
            }); 
		});
    },
}
</script>

<style scoped>
    .form-control{
        border:none !important;
    }
</style>