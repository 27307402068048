<template>
	<div class="row m-0 p-2">
		<table class="table">
      <thead style="border-bottom: 2px solid #000;" v-if="item.price_displays.last_purchase_cost || item.price_displays.last_purchase_price">
        <tr v-if="item.price_displays.last_purchase_price && purchase_price_latest_val">
          <th width="20%">{{ $t('leffit.products.pricelevel.purchase_price_latest') }}</th>
          <th width="40%">{{ purchase_price_latest }}</th>
          <th width="40%"></th>
        </tr>

        <tr v-if="item.price_displays.last_purchase_cost && purchase_cost_latest_val">
          <th width="20%">{{ $t('leffit.products.pricelevel.purchase_cost_latest') }}</th>
          <th width="40%">{{ purchase_cost_latest }}</th>
          <th width="40%"></th>
        </tr>


        <tr v-if="item.price_displays.last_purchase_cost && item.price_displays.last_purchase_price">
          <th width="20%">{{ $t('leffit.products.pricelevel.cost_price') }}</th>
          <th width="40%"></th>
          <th width="40%">{{ cost_price }}</th>
        </tr>
      </thead>
      <tbody>
         <tr v-for="(level,index) in sales_pricelevels" :key="index">
           <td>{{$t('leffit.products.pricelevel.'+level.label)}}</td>
           <td><input type="number" class="form-control" @change="re_calculate_price_from_factor(level)" @keyup="re_calculate_price_from_factor(level)" v-model="level.factor"></td>
           <td><input type="number" class="form-control" @change="re_calculate_factor(level)" @keyup="re_calculate_factor(level)" v-model="level.price"></td>
         </tr>
      </tbody>
      <!-- show product price in tfoot -->
      <tfoot>
        <tr>
          <th>{{ $t('leffit.products.product.price') }}</th>
          <td><input type="number" class="form-control" v-model="item_factor" @change="updatePrice()" @keyup="updatePrice()"  step="0.01"></td>
          <td><input type="number" class="form-control" v-model="item.price" @change="re_calculate_product_factor()" @keyup="re_calculate_product_factor()" step="0.05" /></td>
        </tr>
      </tfoot>


			
		</table>
	</div>
</template>

<script>
export default {
  name: 'pricelevels',
  props:['item'],
  data () {
    return {
    	items : [],
      purchase_price_latest_val : false,
      purchase_cost_latest_val : false,
      wholesale_factor : 2 ,
      wholesale_val : 0 ,
      store_factor : 2.5,

      sales_pricelevels: this.$parent.sales_pricelevels,
      purchase_pricelevels: this.$parent.pricelevels,
      cost_price_value:0,
      final_price: 0,
      item_factor: 0,
      last_price:0,
    }
  },
  computed : {
      purchase_price_latest : function(){
        return this.purchase_price_latest_val;
      },
      purchase_cost_latest : function(){
        return this.purchase_cost_latest_val;
      },
      cost_price : function(){
        console.log('COSTPRICE');
        var val=0;
        if(this.purchase_price_latest_val && this.purchase_cost_latest_val){
          if(this.item.price_displays.last_purchase_cost){
            val+=this.purchase_cost_latest;
          }
          if(this.item.price_displays.last_purchase_price){
            val+=this.purchase_price_latest;
          }
        }else{
          var total = 0;
          var final = 0;
          this.purchase_pricelevels.forEach(function(item){
            total += parseFloat(item.price);
            final = parseFloat(item.price);
          });
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.final_price = final;
          val = total;
        }
        return val;
        // return parseFloat(val).toFixed(2);
      },
  },
  methods :{
    re_calculate_factor(level){
		var price = parseFloat(this.cost_price);
		price = price <= 0 ? 1 : price;
		var wholesale_pricelevel = parseFloat(level.price);
		this.sales_pricelevels = this.sales_pricelevels.map((d)=>{
			if(d.label == level.label){
				d.factor = (wholesale_pricelevel / price);
			}
			return d;
		}) 
		this.re_calculate_product_price();
    },
	re_calculate_price_from_factor(level){
		var price = parseFloat(this.cost_price);
		price = price <= 0 ? 1 : price;
		var factor = parseFloat(level.factor);
		this.sales_pricelevels = this.sales_pricelevels.map((d)=>{
			if(d.label == level.label){
				d.price = (factor * price);
			}
			return d;
		}) 
    },
	re_calculate_product_price(){
		var wholesale = this.sales_pricelevels.find((d)=> d.label == 'wholesale');
		if(!wholesale) return false;

		var price = parseFloat(wholesale.price);
		var factor = parseFloat(this.item_factor);

		this.$parent.item.price = parseFloat(factor * price).toFixed(2); 
    },
	re_calculate_product_factor(){
		var wholesale = this.sales_pricelevels.find((d)=> d.label == 'wholesale');
		if(!wholesale) return false;

		var price = parseFloat(wholesale.price);
		var item_price = parseFloat(this.item.price);
		this.item_factor = parseFloat( (item_price / price) ).toFixed(2);

	},
    updatePrice(){
      this.item.price = parseFloat(this.item_factor*this.last_price).toFixed(2);
	  this.re_calculate_product_price();
    },
    updatePricelevels : function(index){
      var cost_price = this.cost_price;
      var last_price = cost_price;
      this.sales_pricelevels.forEach(function(level,index){
        level.factor = parseFloat(parseFloat(level.price)/parseFloat(last_price)).toFixed(2);
        last_price = level.price;
        console.log('level', level, cost_price,index);
      });
      this.last_price=last_price;
      this.item_factor = parseFloat(parseFloat(this.item.price) / parseFloat(last_price)).toFixed(2);
      console.log('FACTOR', this.item_factor, parseFloat(this.item.price) , parseFloat(last_price));
    },
  },
  mounted(){
    this.updatePricelevels();
  },
}
</script>

<style lang="css" scoped>
tfoot td, tfoot th{
  border-top: 3px solid;

}
</style>