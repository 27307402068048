<template>
      <div class="row p-2 m-0 mb-5">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <td></td>
                        <td colspan="3">
                            <div class="row">
                                <div class="form-group col-lg-10">
                                    <select2 v-model="selected" :options="contacts.map((d)=>{ return {text:d.firstname+' '+d.lastname,id:d.id}})"></select2>
                                </div> 
                            </div>
                        </td> 
                        <td><div class="form-group col-lg-1"><button @click="addContact()" class="btn btn-sm btn-success" style="margin-top:-5px"><i class="fa fa-plus"></i></button></div></td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Lastname</th>
                        <th>Firstname</th>
                        <th>Email</th>
                    </tr>
                </thead> 
                <tbody>
                        <tr v-for="(ft,index) in item.productcontacts" :key="index">
                            <td>
                                <button @click="removeContact(ft.id)"  class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-trash"></i></button>
                            </td>
                            <td v-html="ft.title"></td>
                            <td v-html="ft.lastname"></td>
                            <td v-html="ft.firstname"></td>
                            <td v-html="ft.email"></td>
                        </tr>
                </tbody>
            </table>
        </div> 
</template>
<script>
import { mapState } from 'vuex';
export default {
    props:['item'],
    data(){
        return {
            contacts:[],
            selected:''
        }
    },
    computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint,
            user:state=>state.leffit.user,
        }),
	}, 
    methods:{
        addContact(){ 
            var selected=this.selected;
            this.item.productcontacts.unshift(this.contacts.find((ft)=>ft.id == selected));

            this.$store.dispatch('get','/leffit/products/add-contact/'+this.item.id+'/'+selected)
            .then(data=>{
                // console.log('result',data.data.data);
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
        },
        removeContact(id){
            if(!window.confirm('Are you sure you want to remove this item')){
                return false;
            }
            
            this.item.productcontacts=this.item.productcontacts.filter(ft => ft.id != id);
            this.$store.dispatch('get','/leffit/products/remove-productcontact/'+this.item.id+'/'+id)
            .then(data=>{
                console.log('result',data);
                // this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
        },
    },
    created(){
        this.$store.dispatch('get',"/leffit/crm/contacts")
        .then((data)=>{
            this.contacts=data.data.data;
        })
        .catch((error)=>{
            console.log(error.request);
        });
    }
}
</script>