import Vue from 'vue'

// translations
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import Messages_en from './en/index.js'
import Messages_nl from './nl/index.js'

// usage:
// In functions: this.$t('signup.create_account'); or in components: {{ $t('profile.fields.kids') }}

if(!window.localStorage.getItem('Language')){
	window.localStorage.setItem('Language', 'en');
}
// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: window.localStorage.getItem('Language'), // set locale
	messages:{
		en : Messages_en,
		nl : Messages_nl,
	},
	silentTranslationWarn: true
})

// console.log('messages', Messages_en);

export default i18n;