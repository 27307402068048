export default [
        {
          'value' : '',
          'label'       : '-- SELECT FAKER VALUE --',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'safeEmail',
          'label'       : 'User: Emailaddress',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'userName',
          'label'       : 'User: Username',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'name',
          'label'       : 'User: Name',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'firstName',
          'label'       : 'User: First Name',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'lastName',
          'label'       : 'User: Last Name',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'birthday',
          'label'       : 'User: birthday',
          'fieldtype'   : 'datetime',
          'inputtype'   : 'date',
        },
        {
          'value'       : 'address',
          'label'       : 'User: Address',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'zipcode',
          'label'       : 'User: Zipcode',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'city',
          'label'       : 'User: City',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'country',
          'label'       : 'User: CountryCode',
          'fieldtype'   : 'string',
          'inputtype'   : 'select',
        },
        {
          'value'       : 'image_person',
          'label'       : 'User: Image',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'e164PhoneNumber',
          'label'       : 'User: Phonenumber',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'password',
          'label'       : 'User: Password',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'image',
          'label'       : 'Image',
          'fieldtype'   : 'text',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'string',
          'label'       : 'String',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'text',
          'label'       : 'Text',
          'fieldtype'   : 'text',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'datetime_random',
          'label'       : 'Random Datetime',
          'fieldtype'   : 'datetime',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'randomDigit',
          'label'       : 'Random Digit',
          'fieldtype'   : 'integer',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'randomDigitNotNull',
          'label'       : 'Random Digit not 0',
          'fieldtype'   : 'integer',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'randomFloat',
          'label'       : 'Random float',
          'fieldtype'   : 'double',
          'inputtype'   : 'text',
        },

        {
          'value'       : 'url',
          'label'       : 'Url',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },

        {
          'value'       : 'ip-address',
          'label'       : 'Ip-address ipv4',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        {
          'value'       : 'ip-addressV6',
          'label'       : 'Ip-address ipv6',
          'fieldtype'   : 'string',
          'inputtype'   : 'text',
        },
        
      ]