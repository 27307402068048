<template>
  <div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th v-for="(attribute, index) in attributes" :key="index">
            {{ $t("leffit.crm.contact." + attribute) }}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <a @click="searchTable()" class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th v-for="(attribute, index) in attributes" :key="index">
            <input
              v-if="search.search"
              type="text"
              v-model="search.search[attribute]"
              class="form-control"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in contacts.data" :key="index">
          <td>
            <router-link
              :to="'/admin/crm/contact/' + item.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              :to="'/admin/crm/contact/' + item.id + '/edit'"
              class="btn btn-warning"
            >
              <i class="fa fa-pencil"></i>
            </router-link>
            <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </a>
          </td>
          <td :key="index" v-for="(attribute, index) in attributes">
            {{ item[attribute] }}
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <pagination
        :limit="2"
        :data="contacts"
        @pagination-change-page="searchTable"
      ></pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactTable",
  data() {
    return {
      attributes: [
        "firstname",
        "lastname",
        "address",
        "zipcode",
        "city",
        "country",
        "phone",
        "mobile",
        "email",
        "status_id",
      ],
      contacts: {},
      search: { search: {} },
    };
  },
  methods: {
    searchTable(page = 1) {
      this.$store
        .dispatch("post", {
          details: this.search,
          endpoint: "/leffit/crm/contacts/search?page=" + page,
        })
        .then((data) => {
          console.log(data.data);
          this.contacts = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(id) {
      this.$parent.pagedata = this.pagedata.filter((dt) => dt.id != id);
      console.log(this.pagedata);
      this.$store
        .dispatch("delete", "/leffit/crm/contacts/" + id)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: "Contact was removed successfully",
            type: "success",
          });
        });
    },
  },
  mounted() {
    this.searchTable();
  },
};
</script>
