import axios from 'axios';

export default { 
    
    relogin(context){ 
        context.commit('logout');
    //   alert("Your session has expired"); 
        window.location='/';
    },
    checkAuth(context){
        return new Promise((resolve,reject)=>{
            axios.get(context.state.endpoint+'checkAuth?token='+context.state.token)
            .then((data)=>{
                resolve(data)
            })
            .catch((error)=>{
                // localStorage.removeItem('token');
                context.dispatch('processError',error);
                reject(error)
            })
        });
    },
    getUsers(context){
        return new Promise((resolve,reject)=>{
            axios.get(context.state.endpoint+'users?token='+context.state.token)
            .then((data)=>{
                resolve(data)
            })
            .catch((error)=>{
                context.dispatch('processError',error);
                reject(error)
            })
        });
    },

}