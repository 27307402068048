<template>
    <div class="wrapper ">
        <sidebar />
        <div class="main-panel">
            <navbar />
            <div class="content">
                <div class="nav2 d-none d-md-flex">
                    <div class="pagina">
                        <h5 v-if="testmode==1">{{ $t('system.testmode') }}</h5>
                        <div class="brdcmb">
                        <ul>
                            <li><a href="/admin">HOME</a></li>
                            <li v-for="(crumb, index) in breadcrumbs" :key="index" :class="{active: (crumb.url==$route.path)}"><router-link :to="crumb.url">{{ $t(crumb.title).toUpperCase() }}</router-link></li>
                            <!-- <li class="active"><a href="/">Dashboard 1</a></li> -->
                        </ul>
                    </div>
                    </div>
                    <!-- <div class="opt">
                        <button class="btn">{{ $t('system.edit') }}</button>
                        <button class="btn">{{ $t('system.save') }}</button>
                    </div> -->
                </div>
                <router-view :key="$route.path+'_'+selected_language" />
                {{ get_permissions() }}
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './components/sidebar';
import navbar from './components/navbar';
// import processSteps from './components/process_steps';
// import sidebarInner from './components/sidebar_inner';
// import dashboard from "./../pages/dashboard";
// import edit_user from "./../pages/edit_user";
    export default {
        name:'layout',
        components:{navbar,sidebar},
        created(){
        },
        data(){
            return {
                testmode : process.env.VUE_APP_TESTMODE,
            }
        },
        computed : {
            selected_language : function(){
                return this.$root.$i18n.locale;
            },
            breadcrumbs : function(){
                var crumbs = [];
                var path = '';
                this.$route.path.split('/').forEach(function(item){
                    if(item.length == 0){
                        return false;
                    }
                    if(item=='admin'){
                        path += '/'+item;
                        return false;
                    }
                    path += '/'+item;
                    if(item=='contacts' || item =='organizations' || item=='addresses'|| item=='messages' || item=='products' ||item=='producttypes' ||item=='productstatuses' || item=='sales' || item=='sales_quotation'
                    || item=='sales_order' || item=='sales_packageslip' || item=='sales_invoice' || item=='sales_return'|| item=='sales_invoice_credit'){
                        item='system.'+item;
                    }

                    crumbs.push({'title':item,'url':path});
                });
                return crumbs;
            }
        },
    }
</script>
<style>
    .fright{
        float: right;
    }
    .tright{
        text-align: right;
    }
</style>
<style scoped>
    .main-panel{
        width: calc(100% - 210px);
    }
    .sidebar-mini .main-panel{
        width: calc(100% - 80px);
    }
    .main-panel>.content{
        padding: 0px 0px !important;
        margin-top: 52px;
        min-height: calc(100vh - 52px);
        height: calc(100vh - 52px);
        overflow-x: hidden;
    }
    @media (max-width: 991px){
        .main-panel{
            width: calc(100%);
        }
    }

    .mainPanel{
        padding: 0px 20px;
    }

    .mainPanel .head {
        width: 100%;
    }
    .mainPanel .head h1{
        font-size: 25px;
        color:var(--color-1);
        text-align: left;
        font-weight: 400;
    }

    #mainBody{
        padding: 0x;
        margin: 0px;
        margin-top: 20px;
    }
    #mainBody .tools{
         /*border:1px solid red; */
    }
    #mainBody .mainPanel{
         /*border:1px solid black; */
    }

    .pagina{
        height: -webkit-fit-content;
        display: flex;
    }
    .pagina h5{
        margin: 0px;
        line-height: 1.7 !important;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
        padding: 13px 30px;
        background: var(--color-2);
    }

    .nav2{
        width: 100%;
        background:var(--color-1);
        display: flex;
        justify-content: space-between;
        line-height: unset;
    }

    .brdcmb{
        margin-left: 10px;
        width: 100%;
        padding: 13px 15px;
    }
    .brdcmb ul{
        margin-bottom: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
    }
    .brdcmb ul li{
        margin-right: 13px;
    }
    .brdcmb ul li a{
        text-decoration: none;
        font-size: 13px;
        color: white;
        position: relative;
    }
    .brdcmb ul li.active a{
        color: var(--color-2);
    }
    .opt{
        padding: 13px 10px;
    }
    .opt .btn{
        margin:0px;
        font-size: 10px;
        margin-right: 8px;
        border-radius: 30px;
        padding: 3px 10px;
        color: white;
        font-weight: 600;
        border:none !important;
        box-shadow: none;
    }
    .opt .btn:first-child{
        background: #17c0c8;
    }
    .opt .btn:last-child{
        background: var(--color-2);
    }

</style>

