<template>
  <div class="container" style="">
    <!-- width:calc(100vw - 485px) -->
    <div class="row card" style="min-height:83vh">
      <div class="col-12" v-if="!orgLoading && !filterLoading">
      <!-- <div class="col-12"> -->
        <div class="form-group mb-0">
          <label>Leverancier</label>
          <div class="d-flex align-items-center">
            <v-select
              class="w-100"
              :reduce="(org) => org.id"
              v-model="organisation"
              label="name"
              :options="organisations"
            ></v-select>
            <div>
              <button
                class="btn btn-success px-4"
                @click="getOrganizationProduct()"
              >
                <span v-if="!addLoading">{{
                  $t("leffit.products.filter.add")
                }}</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <label>Filter</label>
          <div class="d-flex align-items-center">
            <v-select
              class="w-100"
              :reduce="(dt) => dt.id"
              v-model="filter"
              label="name"
              :options="data"
            ></v-select>
            <div>
              <button class="btn btn-success px-4" @click="getFilterProducts()">
                 <span v-if="!gettingFilter">{{
                 $t("leffit.products.filter.add")
                }}</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <!-- v-if="products.length > 0" -->
          <label>Products</label>
          <div class="d-flex align-items-center">
            <v-select
              class="w-100"
              :reduce="(product) => product.product_id"
              v-model="fetchProduct"
              label="name"
              :options="fetchProducts"
            >
              <template slot="option" slot-scope="option">
                {{ option.casa_productcode }} - {{ option.name }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.casa_productcode }} - {{ option.name }}
              </template>
            </v-select>
            <div>
              <button class="btn btn-success px-4" @click="addFromProducts()">
                <span>{{ $t("leffit.products.filter.add") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <label>Seizoenscode</label>
          <div class="d-flex align-items-center">
            <v-select
              class="w-100"
              :reduce="(org) => org.casa_seizoenscode"
              v-model="casa_sd"
              label="casa_seizoenscode"
              :options="prods"
            ></v-select>
            <div>
              <button class="btn btn-success px-4" @click="seizoenscodeFilter()">
                <span>{{ $t("leffit.products.filter.add") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="py-5" style="text-align: center">LOADING...</div>
      <!-- <div class="col-sm-12" v-if="products.length > 0"> -->
      <div class="col-sm-12">
        <div class="table-responsive" style="min-height:27em">
          <table class="table">
            <thead>
              <tr class="border-top-1">
                <th>Product</th>
                <th v-if="products.length > 0">
                  <v-select
                    style="min-width:20em"
                    :reduce="(dt) => dt.id"
                    v-model="sort"
                    label="name"
                    :options="filters"
                    @input="sortSelectedProducts()"
                  ></v-select>
                  <!-- <input type="text" class="form-control"> -->
                </th>
                <th>
                  <button class="btn btn-primary" @click="downloadPdf()">
                    <span v-if="!exportLoader">Download</span>
                    <span v-else>DOWNLOADING...</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in products" :key="item.id">
                <td>{{ item.casa_productcode }} - {{ item.name }} - {{item.product_id}}</td>
                <td v-if="products.length > 0"></td>
                <td>
                  <button
                    @click="removeProductTable(index)"
                    class="btn btn-danger"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                  <!-- <button @click="addProduct(item.product_id)" class="btn btn-success">{{$t('leffit.products.filter.add')}}</button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background:#00000085;">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="removeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="col-sm-12" v-if="filtered.length > 0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr class="border-top-1">
                                                <th>Product</th>
                                                <th>
                                                    <button class="btn btn-success" @click="downloadPdf()">Download</button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in filtered" :key="item.id">
                                                <td>
                                                    {{item.product}}
                                                </td>
                                                <td>
                                                    <button @click="removeProductTable(index)" class="btn btn-danger"><i class="fa fa-trash-o"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      sort:'',
      casa_sd:'',
      organisations: [],
      organisation: "",
      products: [],
      product: "",
      filters: [],
      filter: "",
      addLoading: false,
      gettingFilter:false,
      filterLoading: false,
      orgLoading: false,
      data: [],
      filtered: [],
      fetchProducts: [],
      fetchProduct: "",
      exportLoader: false,
      prods:[],
      allProducts:[]
    };
  },
  methods: {
    sortSelectedProducts(){
      var fp = this.filters.filter(filter => filter.id == this.sort)
      this.products = fp[0].products.filter(prod => 
                  this.products.filter(prod2 => prod2.product_id == prod.product_id)
                )
    },
    seizoenscodeFilter(){
      this.products = this.allProducts.filter(item=>{
        return this.casa_sd == item.casa_seizoenscode
      })
      // console.log(this.products)
    },
    getAllProducts() {
      this.$store
        .dispatch("get", "/leffit/products/get-all-products")
        .then((resp) => {
          resp.data.forEach((item) => {
            item.product_id = item.id;
          });
          
          var produs = [...resp.data];
          this.allProducts = [...resp.data]

          this.fetchProducts = resp.data.sort(
            (a, b) =>
              (a.casa_productcode + " - " + a.name >
                b.casa_productcode + " - " + b.name &&
                1) ||
              -1
          );
          this.prods = produs.filter((item, index) =>
            produs.findIndex(obj => obj.casa_seizoenscode === item.casa_seizoenscode) === index
          )
          this.prods = this.prods.sort(
            (a, b) =>
              (a.casa_seizoenscode >
                b.casa_seizoenscode &&
                1) ||
              -1
          );
          // console.log(this.prods)
        });
    },
    addFromProducts() {
      if (this.fetchProduct.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product field is required",
          type: "danger",
        });
        return false;
      }
      let prods = [];
      var selected_filter = this.fetchProducts.find(
        (value) => value.id == this.fetchProduct
      );
      if (this.products.find((prd) => prd.id == selected_filter.id)) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product has been added already",
          type: "danger",
        });
        return false;
      }
      this.products.push(selected_filter);
      this.products = this.products.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      // console.log(this.products)
    },
    getOrganizationProduct() {
      if (this.organisation.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Supplier field is required",
          type: "danger",
        });
        return false;
      }
      this.addLoading = true;
      this.$store
        .dispatch(
          "get",
          "/leffit/crm/get-organization-product/" + this.organisation
        )
        .then((resp) => {
          // console.log(resp)
          this.addLoading = false;
          this.products = [...this.processProductData(resp)];
          // this.products.push(...resp.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrganisations() {
      this.orgLoading = true;
      this.$store
        .dispatch("get", "/leffit/crm/get-organizations-product")
        .then((resp) => {
          this.orgLoading = false;
          this.organisations = resp.data.data.sort(
            (a, b) => (a.name > b.name && 1) || -1
          );
        })
        .catch((error) => {
          this.orgLoading = false;
          console.log(error);
        });
    },
    getFilterProducts() {
      let prods = [];
      var selected_filter = this.filters.find(
        (value) => value.id == this.filter
      );
      this.gettingFilter = true;
      this.$store
        .dispatch(
          "post",
          {
            details:{"filter_id":this.filter},
            endpoint:"/leffit/products/get-product-by-filter"
          }
        )
        .then((resp) => {
          console.log(resp)
          this.products = [...this.processProductData(resp)];
        })
        .catch((error) => {
          console.log(error);
        });
        this.gettingFilter = false;
    },
    processProductData(response){
      response.data.forEach((item) => {
        item.product_id = item.id;
      });
      var temp = [...this.products];
      response.data.forEach((product) => {
        if (!this.products.find((p) => p.id == product.id)) {
          temp.push(product);
        }
      });
      this.products = [];
      return temp.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    getFilters() {
      this.filterLoading = true;
      let tempData = [];

      this.$store
        .dispatch("get", "/leffit/products/get-sorted-productfilters")
        .then((data) => {
          this.filterLoading = false;
          this.filters = data.data;
          this.filters.forEach((filter) => {
            if (filter.parent_id != 0) {
              var hold = {};
              hold.name = filter.name;
              hold.id = filter.id;
              hold.second = "";
              hold.third = "";
              var parent = this.filters.find(
                (parent) => parent.id == filter.parent_id
              );
              // hold.second = parent.name
              if (parent) {
                hold.name = parent.name + " - " + hold.name;
                if (parent.parent_id != 0) {
                  var parent_p = this.filters.find(
                    (parent_p) => parent_p.id == parent.parent_id
                  );
                  // hold.third = parent_p.name
                  hold.name = parent_p.name + " - " + hold.name;
                }
              }
              tempData.push(hold);
            }
          });
          this.data = tempData.sort((a, b) => (a.name > b.name && 1) || -1);
        });
    },
    removeProductTable(pindex) {
      this.products.splice(pindex, 1);
    },
    removeModal() {
      window.$("#exampleModal").removeClass("show");
    },

    async downloadPdf() {
      var product_id = this.products.map((pid) => pid.product_id);
      product_id = JSON.stringify(product_id);
      this.exportLoader = true;
      var response = await axios.post(
        process.env.VUE_APP_API_BASE_URL +
          "/lefficient/leffit/download-stickers-pdf",
        { product_id: product_id },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status == 200) {
        this.exportLoader = false;
        const type = response.headers["content-type"];
        const blob = new File([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var current = new Date();
        link.download =
          current.getFullYear() +
          "" +
          (current.getMonth() + 1) +
          "" +
          current.getDate() +
          "_ProductVoorraadStikkers" +
          ".pdf";
        link.click();

        this.removeModal();
        this.filtered = [];
        this.$store.dispatch("displayNotification", {
          type: "success",
          id: Math.round(Math.random() * 100),
          message: "Exported successfully.",
          interval: 5000,
          autoDismiss: true,
        });
      }
      this.exportLoader = false;
    },
    addProduct(pr_id) {
      if (pr_id) {
        this.product = pr_id;
      }
      let details = {};
      if (this.product.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product field is required",
          type: "danger",
        });
        return false;
      }
      this.alreay_exist = false;
      if (this.filtered.find((val) => val.product_id == this.product)) {
        this.alreay_exist = true;
      }
      if (this.alreay_exist) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product has been added already",
          type: "danger",
        });
        return false;
      }
      details.product_id = this.product;
      this.products.forEach((val) => {
        if (val.product_id == this.product) {
          details.product = val.casa_productcode + " - " + val.name;
        }
      });

      this.$store.dispatch("displayNotification", {
        type: "success",
        id: Math.round(Math.random() * 100),
        message: "Product added successfully.",
        interval: 5000,
        autoDismiss: true,
      });
      // details.price = this.row2.price
      this.filtered.push(details);
      // console.log(this.filtered)
    },
  },
  created() {
    this.getAllProducts();
    this.getOrganisations();
    this.getFilters();
  },
};
</script>

<style>
.vs__dropdown-toggle {
  height: 40px !important;
}
</style>

<style scoped>
.select2-container {
  width: 100% !important;
}
.v-select {
  width: 100%;
}
</style>
