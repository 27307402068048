<template>
	<div>
		<div class="head row p-0">
        	<h3 class="pl-3">{{ $t('leffit.roles.index_title') }}</h3>
			<div class="col-12 p-0 m-0">
				<router-link to="roles/create"  class="btn btn-success btn-sm"> Add new </router-link> 
			</div>
      	</div>
      	<table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.filters.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr :key="index" v-for="(item, index) in pagedata">
      				<td>
      					<router-link :to="'/admin/settings/role/'+item.id" class="btn btn-open">
      						<i class="fa fa-search"></i>
      					</router-link>
      					<router-link :to="'/admin/settings/role/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
						<a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes" v-html="item[attribute]"></td>
      			</tr>
      		</tbody>
      	</table>
	</div>
</template>

<script> 
export default {
  	name: 'index',
  	data () {
    	return {
    		pagedata : [],
            attributes : [
            'name',
            'description',
            ],
			search : {}, 
			item:{active:0,name:'',description:'',parent:'',language:'',pickorder:''}
    	}
	},   
	methods:{
		deleteItem(id){
			this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
			this.$store.dispatch('delete','/lefficient/leffit/Roles/'+id)
			.then(data=>{
				console.log(data);
				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Item was removed successfully',type:'success'});
				// this.pagedata = data.data.data;
			});
		}
	},
  	mounted(){
		window.$('.language').select2();
		window.$('.parent').select2();
	    this.$store.dispatch('get','/lefficient/leffit/Roles')
        .then(data=>{
        	console.log(data);
			this.pagedata = data.data;
		});
	},
}
</script>

<style lang="css" scoped>
</style>