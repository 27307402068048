<template>
  <div>
    <div class="row p-2 m-0 mb-5">
      <table class="table table-hover">
        <thead>
          <tr>
            <td></td>
            <td colspan="3">
              <div class="row">
                <div class="form-group col-lg-10">
                  <select
                    name=""
                    id="selectFilter"
                    class="form-control select2 col-12"
                    style="width:100%"
                  >
                    <option value="">{{
                      $t("leffit.products.product_filter.select")
                    }}</option>
                    <option
                      v-for="(ft, index) in filters"
                      :key="index"
                      :value="ft.id"
                    >
                      {{ merge_parent_names(ft) }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
            <td>
              <div class="form-group col-lg-1">
                <button
                  @click="addFilter()"
                  class="btn btn-sm btn-success"
                  style="margin-top:-5px"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("leffit.products.product_filter.name") }}</th>
            <th>{{ $t("leffit.products.product_filter.group") }}</th>
            <th>{{ $t("leffit.products.product_filter.description") }}</th>
            <th>{{ $t("leffit.products.product_filter.lang") }}</th>
            <th>{{ $t("leffit.products.product_filter.pickorder") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ft, index) in item.productfilters" :key="index">
            <td>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                  @click="reOrder('up', index)"
                  v-if="index > 0"
                  class="btn btn-sm pl-2 pr-2 btn-warning"
                >
                  <i class="fa fa-chevron-up"></i>
                </button>
                <button
                  @click="reOrder('down', index)"
                  v-if="index < item.productfilters.length - 1"
                  class="btn btn-sm pl-2 pr-2 btn-success"
                >
                  <i class="fa fa-chevron-down"></i>
                </button>
                <button
                  @click="remove(ft.id)"
                  class="btn btn-sm pl-2 pr-2 btn-danger"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
            <td v-html="ft.name"></td>
            <td v-html="merge_parent_names(ft)"></td>
            <td v-html="ft.description"></td>
            <td v-html="ft.lang"></td>
            <td v-html="ft.pickorder"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "productFilters",
  props: ["item", "product"],
  data() {
    return {
      filters: [],
    };
  },
  methods: {
    merge_parent_names(filter) {
      var temp = [];
      while (filter != null) {
        temp.unshift(filter.name);
        filter = filter.parent;
      }
      return temp.join(" - ");
    },
    reOrder(direction, index) {
      var data = this.item.productfilters;
      if (direction == "up") {
        var temp = data[index];
        data[index] = data[index - 1];
        data[index - 1] = temp;
      } else if (direction == "down") {
        temp = data[index];
        data[index] = data[index + 1];
        data[index + 1] = temp;
      }
      this.item.productfilters = JSON.parse(JSON.stringify(data));

      var payload = this.item.productfilters.map((d, index) => {
        return {
          index,
          id: d.id,
        };
      });
      console.log(payload);
      this.$store
        .dispatch("post", {
          details: payload,
          endpoint: "/leffit/products/reorderfilters/" + this.product,
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove(id) {
      if (!window.confirm("Are you sure you want to remove this item")) {
        return false;
      }

      this.item.productfilters = this.item.productfilters.filter(
        (ft) => ft.id != id
      );
      this.$store
        .dispatch(
          "get",
          "/leffit/products/remove-productfilter/" + this.item.id + "/" + id
        )
        .then((data) => {
          // console.log('result',data.data.data);
          // this.filters = data.data.data;
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.name + " was updated successfully",
            type: "success",
          });
        });
    },
    addFilter() {
      var selected = document.getElementById("selectFilter").value;
      this.item.productfilters.unshift(
        this.filters.find((ft) => ft.id == selected)
      );
      this.$store
        .dispatch(
          "get",
          "/leffit/products/add-productfilter/" + this.item.id + "/" + selected
        )
        .then((data) => {
          console.log("result", data.data.data);
          // this.filters = data.data.data;
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.name + " was updated successfully",
            type: "success",
          });
        });
    },
  },
  created() {
    this.$store
      .dispatch("get", "/leffit/products/productfilters")
      .then((data) => {
        console.log("result", data.data.data);
        this.filters = data.data.data;

        window.$("#selectFilter").select2();
      });
  },
};
</script>
