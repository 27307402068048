export default `
<section class="four bg-white  center">
<div class="row pt-4 mb-3">
    <div class="col-md-6">
        <img src="++{{section7Image1}}">
    </div>
    <div class="col-md-6 center">
        <h2 id="section7header" class="h2">++{{section7header}}</h2>
        <h6 id="section7Text1" class="p"> ++{{section7Text1}}</h6>
    </div>
    
</div>
</section>
`