// Casa
import casaRouter from './leffit/casa/index'
import casaIndex from "./../../src/leffit/casa/index";

// CRM
import crmRouter from './leffit/crm/index'
import crmIndex from "./../../src/leffit/crm/index";

// website
import websiteRouter from './leffit/website/index'
import websiteIndex from "./../../src/leffit/website/index";

// Products
import productsIndex from "./../../src/leffit/products/index";
import productsRouter from './leffit/products/index'

// Settings
import settingIndex from "./../../src/leffit/settings/index";
import settingsRouter from "./leffit/settings/index";

// Stock
import stockIndex from "./../../src/leffit/stocks/index";
import stockRouter from "./leffit/stocks/index";

// Orders
import salesIndex from "./../../src/leffit/orders/sales_index";
import salesRouter from "./leffit/sales/index";

// Reports
import reportsIndex from "./../../src/leffit/reports/index";
import reportsRouter from "./leffit/reports/index";


// Procurement
import procurementIndex from "./../../src/leffit/orders/procurement_index";
import procurementRouter from "./leffit/procurement/index";

//Newsletters
import newletterIndex from "../../src/leffit/newletters/index";
import newletterRouter from "./leffit/newsletter/index";
import create_template from "../../src/leffit/newletters/create_template";

//Email Template
import emailIndex from "../../src/leffit/email/index";
import emailRouter from "./leffit/email/index";
import template from "../../src/leffit/email/template/index";
import templateEdit from "../../src/leffit/email/template/edit";
import templateDetail from "../../src/leffit/email/template/single";

// Shipment
import shippingsIndex from "./../../src/leffit/shipping/index";
import shippingsRouter from './leffit/shippings/index'

//Courses
import coursesIndex from "../../src/leffit/courses/index";
import coursesRouter from "./leffit/courses/index";

//Import Export 
import import_export from "./leffit/import_export/index";
import importExportIndex from "../../src/leffit/import_export/index";

//media-manager
// import mediamanagerIndex from "./../../src/leffit/mediamanager/index";

//appointments routes
import appointmentsIndex from "./../../src/leffit/appointments/index";
import appointmentsRouter from "./leffit/appointments/index";

//bijenkorf
import BijenkorfRouter from './leffit/bijenkorf/index';
import BijenkorfIndex from '../../src/leffit/bijenkorf/index'

import EditUser from '../../src/pages/edit_user';

export default [
    {
        path: "edit-user/:id",
        component: EditUser,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "casa-jewelry",
        component: casaIndex,
        children: casaRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "newsletters",
        component: newletterIndex,
        children: newletterRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "shippings",
        component: shippingsIndex,
        children: shippingsRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "newsletter/edit/:id",
        component: create_template,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "website",
        component: websiteIndex,
        children: websiteRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "courses",
        component: coursesIndex,
        children: coursesRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "crm",
        component: crmIndex,
        children: crmRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "email",
        component: emailIndex,
        children: emailRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "email/edit/:id",
        component: templateEdit,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "email/detail/:id",
        component: templateDetail,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "email/template",
        component: template,

        meta: {
            AuthRequired: true
        },
    },
    {
        path: "procurement",
        component: procurementIndex,
        children: procurementRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "products",
        component: productsIndex,
        children: productsRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "appointments",
        component: appointmentsIndex,
        children: appointmentsRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "sales",
        component: salesIndex,
        children: salesRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "reports",
        component: reportsIndex,
        children: reportsRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "settings",
        component: settingIndex,
        children: settingsRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "stocks",
        component: stockIndex,
        children: stockRouter,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: 'bijenkorf',
        component: BijenkorfIndex,
        children: BijenkorfRouter,
        meta: {
            AuthRequired: true
        },
    }
];