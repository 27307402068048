import Homepage from '../../../../src/leffit/website/homepage/index'

import layoutsIndex from '../../../../src/leffit/website/layouts/index'

import pagestypesIndex from '../../../../src/leffit/website/pagestypes/index'
import pagestypesEdit from '../../../../src/leffit/website/pagestypes/edit'

import sectiontypesIndex from '../../../../src/leffit/website/sectiontypes/index'
import sectiontypesEdit from '../../../../src/leffit/website/sectiontypes/edit' 

import blocktypeIndex from '../../../../src/leffit/website/blocktypes/index'
import blocktypeEdit from '../../../../src/leffit/website/blocktypes/edit'

import fieldsIndex from '../../../../src/leffit/website/fields/index'
import fieldsEdit from '../../../../src/leffit/website/fields/edit'


import editWebsite from '../../../../src/leffit/website/edit-new';
import viewWebsite from '../../../../src/leffit/website/view';
import Preview from '../../../../src/leffit/website/preview'

export default [
    {
        path:'homepage',
        component:Homepage
    },
    {
        path:':id/edit',
        component:editWebsite
    },
    {
        path:':id/preview',
        component:Preview
    },
    {
        path:':id/view',
        component:viewWebsite
    },
    {
        path:'layouts',
        component:layoutsIndex
    },
    {
        path:'pagetypes',
        component:pagestypesIndex
    },
    {
        path:'pagetypes/:id/edit',
        component:pagestypesEdit
    },
    {
        path:'sectiontypes',
        component:sectiontypesIndex
    },
    {
        path:'sectiontypes/:id/edit',
        component:sectiontypesEdit
    } ,
    {
        path:'blocktypes',
        component:blocktypeIndex
    },
    {
        path:'blocktypes/:id/edit',
        component:blocktypeEdit
    },
    {
        path:'fields',
        component:fieldsIndex
    },
    {
        path:'fields/:id/edit',
        component:fieldsEdit
    }, 
];