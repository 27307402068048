<template>
    <div style="position:relative;height:100vh"> 
        <div class="row tools">
            <div class="title col-12 col-sm-6 col-md-5">
                <router-link to='/admin/website/blocktypes' style="padding:0px 20px;color:white;font-size:20px" class="btn"><i class="fa fa-arrow-left"></i> </router-link>
                <div class="title">
                    <input  id="edittitle" style="color:white;text-align:center;background: #404040;border: none;border-radius: 0;" type="text"  class="form-control" v-model="item.name" />
                    <!-- <span style="color:white;text-transform: capitalize;"  >{{this.item.name}}</span> -->
                </div>
            </div>
            <div class="tols col-12 col-sm-6 col-md-7"> 
                <div class="tool"><button @click="save();" style="padding:5px 20px;color:white" class="btn"><i class="fa fa-save"></i> SAVE</button></div>
            </div>
        </div>
        
        <div id="templateHolder" :class="'templateHolder'">  
            <section class="container" id="app">
                <section class="row">
                    <!-- <div class="col-sm-0 col-md-1 col-lg-2"></div>  -->
                    <div class="col-sm-12 col-md-12 col-lg-12 holder mb-5">
                        <div v-for="(section,index) in selected_fields" :key="index" :class="'temp'" >
                            <div class="sectionTools">
                                <button @click="moveUp(index)" v-if="index != 0" title="shift section upward" class="btn"><i class="fa fa-chevron-up"></i></button>
                                <button @click="moveDown(index)" v-if="index != selected_fields.length-1" title="shift section downward" class="btn"><i class="fa fa-chevron-down"></i></button>
                                <button @click="deleteSection(section.id)" class="btn"><i class="fa fa-trash"></i></button>
                            </div> 
                            <div class="sectionBody">
                                <p>{{section.name}}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <div class="defaultTemp">
                <form action="" class="form-inline">
                    <select class="form-control col-10 mr-1 select" name="" id="" v-model="field">
                        <option value="">Select a new field</option>
                        <option v-for="(fd,index) in fields" :key="index" :value="fd.id">{{fd.name}}</option>
                    </select> 
                    <button type="button" @click="selectSection()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                </form>
            </div> 
        </div>  
    </div>
</template>
<script>
export default {
    data(){
        return {
            item:{},
            selected_fields:[],
            fields:[],
            field:'',
            loading:false
        }
    }, 
    methods:{
        save(){

            if(this.loading){
                return false;
            }

            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:"You can't leave the name field empty."});
                return false;
            } 

            this.$store.dispatch('put',{endpoint:'/leffit/cms/blocktypes/'+this.item.id,details:this.item});
            
            var data=[];
            data=this.selected_fields.map((sect,index)=>{
                return {
                    id:sect.id,
                    order:index
                };
            });

            console.log(data);
            this.$store.dispatch('put',{endpoint:'/leffit/cms/blocktypes/attach-section/'+this.item.id,details:data})
			.then(data=>{ 
                console.log(data);
                this.$store.dispatch('makeNotification',{type:'success',title:"Blocktype updated",text:'Blocktype was saved successfully'});
			}).catch((error)=>{
				console.log(error.request);
			});
        },
        moveDown(index){
            var temp=this.selected_fields;
            var next=temp[index + 1];
            temp[index + 1]=temp[index];
            temp[index]=next;
            this.selected_fields=[];
            this.selected_fields=temp; 
        },
        moveUp(index){
            var temp=this.selected_fields;
            var prev=temp[index - 1];
            temp[index - 1]=temp[index];
            temp[index]=prev;
            this.selected_fields=[];
            this.selected_fields=temp; 
        },
        deleteSection(id){
            this.selected_fields=this.selected_fields.filter((sect)=> sect.id != id);
        },
        selectSection(){
             var field=this.fields.find((d)=> d.id == this.field);
            if(field ){
                this.selected_fields.push(field); 
            }
        }
    },
    created(){
        this.$store.dispatch('get','/leffit/cms/blocktypes/'+this.$route.params.id)
        .then(data=>{
            console.log(data); 
            this.item=data.data.data;
            this.selected_fields=this.item.fields;
        });
        
        this.$store.dispatch('get','/leffit/cms/fields')
        .then(data=>{
            console.log(data); 
            this.fields=data.data.data;
             var context=this;
            window.$('.select').select2().change(function(){
                context.field=window.$(this).val();
            });
		});
    }
}
</script>

<style scoped>
    .sectionBody{
            height: 100px;
    background: #8dabb9;
    }
    .sectionBody p{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
    .sect{
        width: 33%;
        height: 200px;
        display: inline-block;
        text-align: center;
        padding: 10px;
    }
    .hold{
        background: lightblue;
        height: 100%;
    }
    .hold p{
            overflow-wrap: break-word;
    }
    .hold:hover{
        cursor: pointer;
    }
</style>

<style scoped> 
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        }
        .templateHolder{ 
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            margin: auto;
            margin-top: 80px;
            width: 670px;
            border: 1px solid lightgray;
            padding: 10px;
            height: 90%;
            overflow-y: scroll;
        }
        .editBoard{
            position: absolute;
            top:0%;
            bottom: 5%;
            right: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            width: 500px; 
            padding: 10px;
            height: 94%;
            overflow-y: scroll;
            background: #f2f2f2;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        } 
         
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            position: absolute;
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative;  
        }
        .temp:hover{
            cursor: pointer; 
        }
        .temp:hover .sectionTools{
            display: block;
        }
        .temp.editting{
            border: 2px dashed red;
        }
        .temp:hover .sectionTools button{
            background: black;
        }
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        }
        .uploadImage:hover{
            transform: scale(0.95);
        }
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       
</style>
<style scoped>
    div img{
        width:100% !important;
    }
</style>