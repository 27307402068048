<template>
     <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round">
                <!-- <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i> -->
                <!-- <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i> -->
                <i class="fa fa-bars"></i>
            </button>
            </div>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-start">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <a class="navbar-brand" style="position:relative">
                        <input class="searchInput" :placeholder="$t('system.search')"/>
                        <i class="fa fa-search"></i>
                    </a>
                </div>
            </form> -->

            <a class="navbar-brand" style="margin:0px 0px">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t('lefficient.leffit.language') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" @click="setLanguage('nl')">{{ $t('lefficient.languages.en.nl') }}</a>
                        <a class="dropdown-item" @click="setLanguage('en')">{{ $t('lefficient.languages.en.en') }}</a>
                    </div>
                </div>
            </a>

            <!-- <ul class="navbar-nav ">

                <li class="nav-item ">
                    <a class="nav-link" href="" >
                        aA
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="" >
                        <i class="fa fa-sun"></i>
                        <i class="fa fa-moon"></i>
                    </a>
                </li>

            </ul> -->
        </div>
        <div class="collapse navbar-collapse justify-content-center">
            <img src="https://www.lefficient.nl/admin/images/logoLeffix2.png" height="35px" style="margin-top:5px; width: auto !important;">
            <!-- <ul class="navbar-nav">
                <li class="nav-item navrightb ">
                    <a class="nav-link" href="" >
                        <i class="fa fa-envelope"></i>
                    </a>
                </li>

            </ul> -->
        </div>
        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <!-- <li class="nav-item navrightb ">
                    <a class="nav-link" href="" >
                        <i class="fa fa-envelope"></i>
                    </a>
                </li>
                <li class="nav-item navrightb">
                    <a class="nav-link" href="" >
                        <i class="fa fa-comments"></i>
                    </a>
                </li>  -->
                <li class="nav-item navrightb">
                    <a class="nav-link" href="/admin/help" >
                        <i class="fa fa-question-circle"></i>
                    </a>
                </li>
            </ul>
        </div>
        </div>
    </nav>
</template>

<script>
export default {
    name:'navbar',
    methods: {
        setLanguage(lang) {
            this.$root.$i18n.locale = lang;
            window.localStorage.setItem('Language', lang);
        }
    }
}
</script>
<style scoped>
    .nav-mobile-menu li a{
        color: var(--color-1) !important;
    }
    .nav-mobile-menu li a i{
        /* color: var(--color-1) !important; */
    }
    .navrightb{
        border-right:1px solid lightgray;
    }
    .navrightb a{
        padding: 10px 10px !important;
    }
    .navrightb:last-child{
        border-right:none;
    }
    .navrightb:last-child a i{
        color: #a87e00 !important;
    }
    .justify-content-start .nav-item .nav-link i.fa{
        color: var(--color-1);
        font-size: 14px !important;
    }
    .justify-content-end .nav-item .nav-link i.fa{
        color: var(--color-1);
        font-size: 21px !important;
    }
    .nav-link i.fa.fa-comments{
        color:#17c0c8 !important;
    }
    .navbar-brand .dropdown .btn:hover{
        box-shadow: none;
    }
    .navbar-brand .dropdown{

    }
    .navbar-brand .dropdown .btn{
        border:none;
        background: transparent;
        color: var(--color-1);
        font-size: 12px;
        font-weight: 500;
        padding: 0px;
    }
    .navbar-brand .dropdown .btn:focus{
        outline:none;
    }
    .fa-search{
        color: hsla(0, 0%, 75%, 1);
        position: absolute;
        right: 7px;
        top: 29%;
    }
    .searchInput{
        border: 1px solid lightgray;
        border-radius: 5px;
        height: 28px;
        font-size: 15px;
        padding: 4px 9px;
        font-weight: 500;
    }
    .btn.btn-just-icon.btn-round:hover{
        border:none;
    }
    .btn.btn-just-icon.btn-round:hover{
        border:none;
        color: black;
    }
    .btn.btn-fab.btn-round, .btn.btn-just-icon.btn-round{
        border-radius: 0px;
        box-shadow: none;
        background: transparent;
    }
    .navbar{
        padding: 0px;
        /* border:1px solid lightgray; */
    }
    .navbar .container-fluid{
        padding-left: 0px;
    }

    @media (max-width: 991px){

        .navbar{
            height: 40px !important;
            background-color:var(--color-1) !important;
        }
        .navbar .navbar-toggler .navbar-toggler-icon.icon-bar{
            background-color: white !important;
        }
    }

</style>
