<template>
  <div>
    <div class="row">
      <div id="" :class="{'on-drag': isDragging}"
        @dragleave="dragOverHandler($event, false)"
        @dragover="dragOverHandler($event, true)"
        @drop.prevent="dropHandler($event)"
        class="col-10 upload-container">
        <input v-if="!loading" @change="upload()" type="file" class="d-none" id="file" multiple />
        <label class="w-100 h-100" for="file">
          <div v-if="!isDragging" class="label text-center">
            <i class="fas fa-plus"></i>
            <h5 v-if="loading">UPLOADING</h5>
            <h5 v-else>UPLOAD SALES REPORT</h5>
          </div>
          <div v-else class="label text-center"> 
            <h5 >DRAG AND DROP HERE</h5>
          </div>
        </label>
      </div>
    </div>
    <ul>
      <li class="text-danger" v-for="(filename, index) in failedFiles" :key="index">
        {{filename}} report already exist
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      isDragging:false,
      failedFiles : []
    };
  },
  methods: {
    dragOverHandler(e, status){
      e.preventDefault();
      this.isDragging = status;
    },
    dropHandler(e){
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.upload(files);
    },
    async upload(files) {
      if (this.loading) return false;
      if(files == null) files = document.getElementById("file").files;

      var formData = new FormData();
      Array.from(files).map((file)=>{ 
        formData.append("file"+Math.random()*files.length, file);
      });


      this.loading = true;

      this.$store
        .dispatch("post", {
          endpoint: "/sales-report/import",
          details: formData,
        })
        .then(({ data }) => {
          console.log(data); 
           this.loading = false;
          if(data.status == 'success'){
            this.$store.dispatch("displayNotification", {
              type: "success",
              id: Math.random() * 100,
              message: "Sales Report was uploaded successfully.",
              interval: 500,
            });
            this.$router.push("/admin/stocks/warehouse_sales_report");
          }
          else{
            this.failedFiles = data.data;
            this.$store.dispatch("displayNotification", {
              type: "danger",
              id: Math.random() * 100,
              message: "Error occured while processing file",
              interval: 500,
            });
          }
         
        });
    },
  },
  create() {},
};
</script>
<style scoped>
.label {
  position: absolute;
  height: fit-content;
  width: fit-content;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upload-container {
  border: 1px solid lightgray;
  height: 400px;
}
.on-drag{
  border: 2px solid dashed;
  background: lightgray;
}
</style>