import lessionIndex from "../../../../src/leffit/courses/lesson/index";
import lessionEdit from "../../../../src/leffit/courses/lesson/edit";

import moduleIndex from "../../../../src/leffit/courses/modules/index";
import moduleEdit from "../../../../src/leffit/courses/modules/edit";

import programIndex from "../../../../src/leffit/courses/programs/index";
import programEdit from "../../../../src/leffit/courses/programs/edit";
 
import fileIndex from "../../../../src/leffit/courses/files/index.vue";

import sectionIndex from "../../../../src/leffit/courses/sections/index";
import sectionEdit from "../../../../src/leffit/courses/sections/edit";
import sectionPreview from "../../../../src/leffit/courses/sections/preview";


export default [
    {
        path:'files',
        component:fileIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'lessons',
        component:lessionIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'lessons/edit/:id',
        component:lessionEdit,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'modules',
        component:moduleIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'modules/edit/:id',
        component:moduleEdit,
        meta:{
            AuthRequired:true
        }
    },
     {
        path:'programs',
        component:programIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'programs/edit/:id',
        component:programEdit,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'sections',
        component:sectionIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'sections/:id',
        component:sectionEdit,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:'sections/:id/preview',
        component:sectionPreview,
        meta:{
            AuthRequired:true
        }
    }
]