export default `
<section class="four bg-white  ">
<div class="row pt-4 mb-3">
    <div class="col-md-6">
        <h2 id="section9header" class="h2">++{{section9header}}</h2>
        <h6 id="section9Text1" class="p">++{{section9Text1}}</h6>
    </div>
    <div class="col-md-6 text-center">
        <img src="++{{section9Image}}">
    </div>
</div>
</section>
`