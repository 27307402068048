<template>
  <div class="d-flex flex-column flex-lg-row" id="mainBody">
    <sidebarInnerHardcoded :menu="menu" />
    <div style="width: 100% !important" class="mainPanel">
      <router-view />
    </div>
  </div>
</template>

<script>
import sidebarInnerHardcoded from "./../../layout/components/sidebar_inner_hardcoded";
export default {
  name: "index",
  components: { sidebarInnerHardcoded },
  data() {
    return {
      menu: [
        {
          title: this.$t("leffit.reports.turnover_rate"),
          url: "/admin/reports/turnover_rate",
          children: [],
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.mainPanel {
  width: 100% !important;
}
</style>
