<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.stock.warehouses.create') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.products.stock.warehouses.name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                </div>
 
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script> 
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',
            }, 
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.$store.state);
            this.$store.dispatch('post',{endpoint:'/leffit/products/stocklocations',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Stocklocation created",text:'Warehouse was created automatically'});
                this.item={};
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){ 
    }
    
}
</script>
