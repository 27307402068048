<template>
  <div class="d-flex flex-column flex-lg-row" id="mainBody">
    <sidebarInnerHardcoded :menu="menu" />
    <div style="width: 100% !important" class="mainPanel">
      <router-view :key="'sales_' + $route.path" />
    </div>
  </div>
</template>

<script>
import sidebarInnerHardcoded from "./../../layout/components/sidebar_inner_hardcoded";
export default {
  name: "index",
  components: { sidebarInnerHardcoded },

  computed: {
    menu: function () {
      return this.menu_data;
    },
  },

  data() {
    return {
      menu_data: [],
    };
  },
  mounted() {
    this.$store.dispatch("get", "/leffit/orders/menu/sales").then((data) => {
      let menu_items = data.data.data;
      var thas = this;
      menu_items.forEach(function (element) {
        thas.menu_data.push({
          title: thas.$t(element["name"]),
          url:
            "/admin/sales/orders/" +
            element["name"].replace("leffit.orders.ordertype.", ""),
          children: [],
        });
      });
    });
  },
};
</script>

<style lang="css" scoped>
</style>