export default `	
    <section class="six bg-white text-center" >
    <div class="">
        <h2 id="section2Header" class="h2 pt-3">++{{section2Header}}</h2>
        <div class="row mt-3">
            <div class="col-sm-4 col-lg-4" >
                <img src="++{{section2Image1}}">
                <h6 id="section2Text1" class="p">++{{section2Text1}}</h6>
            </div>
            <div class="col-sm-4 col-lg-4">
                <img src="++{{section2Image2}}">
                <h6 id="section2Text2" class="p">++{{section2Text2}}</h6>
            </div>
            <div class="col-sm-4 col-lg-4">
                <img src="++{{section2Image3}}">
                <h6 id="section2Text3" class="p">++{{section2Text3}}</h6>
            </div>
        </div>
    </div> 
    </section>
`;