<template>
  <div>
    <div class="head row">
      <h3 class="pl-3">{{ $t("leffit.crm.contacts") }}</h3>
      <div class="col-12 p-0 m-0">
        <button
          data-target="#addnew"
          data-toggle="modal"
          class="btn btn-success btn-sm"
        >
          {{ $t("leffit.crm.add_new_contact") }}
        </button>
      </div>
      <div v-if="import_error.length > 0" class="col-12">
        <div class="alert alert-danger">
          <p v-html="import_error.substr(0, 700) + '...'"></p>
        </div>
      </div>
    </div>
    <contact-table></contact-table>

    <div class="modal" tabindex="-1" role="dialog" id="addnew">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("leffit.crm.add_new_contact") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-2">
                <label for="contact_title">{{
                  $t("leffit.crm.contact.title")
                }}</label>
                <select v-model="item.title" id="" class="form-control">
                  <option value="Ms">{{ $t("lefficient.system.ms") }}</option>
                  <option value="Mr">{{ $t("lefficient.system.mr") }}</option>
                </select>
              </div>

              <div class="form-group col-3">
                <label for="contact_firstname">{{
                  $t("leffit.crm.contact.firstname")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_firstname"
                  v-model="item.firstname"
                />
              </div>

              <div class="form-group col-3">
                <label for="contact_prelastname">{{
                  $t("leffit.crm.contact.prelastname")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_prelastname"
                  v-model="item.prelastname"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_lastname">{{
                  $t("leffit.crm.contact.lastname")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_lastname"
                  v-model="item.lastname"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_address">{{
                  $t("leffit.crm.contact.address")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_address"
                  v-model="item.address"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_address2">{{
                  $t("leffit.crm.contact.address2")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_address2"
                  v-model="item.address2"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-4">
                <label for="contact_zipcode">{{
                  $t("leffit.crm.contact.zipcode")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_zipcode"
                  v-model="item.zipcode"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_city">{{
                  $t("leffit.crm.contact.city")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_city"
                  v-model="item.city"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_country">{{
                  $t("leffit.crm.contact.country")
                }}</label>
                <select2
                  :options="
                    country.map((c) => {
                      return { id: c.code, text: c.name };
                    })
                  "
                  v-model="item.country"
                  style="position:relative;border:0"
                  class="form-control country p-0"
                  id="contact_country"
                ></select2>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <label for="contact_phone">{{
                  $t("leffit.crm.contact.phone")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_phone"
                  v-model="item.phone"
                />
              </div>

              <div class="form-group col-6">
                <label for="contact_mobile">{{
                  $t("leffit.crm.contact.mobile")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_mobile"
                  v-model="item.mobile"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <label for="contact_fax">{{
                  $t("leffit.crm.contact.fax")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_fax"
                  v-model="item.fax"
                />
              </div>

              <div class="form-group col-6">
                <label for="contact_email">{{
                  $t("leffit.crm.contact.email")
                }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="contact_email"
                  v-model="item.email"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_picture">{{
                  $t("leffit.crm.contact.picture")
                }}</label>
                <input type="file" class="form-control" id="contact_picture" />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-4">
                <label for="contact_function">{{
                  $t("leffit.crm.contact.function")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_function"
                  v-model="item.function"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_birthday">{{
                  $t("leffit.crm.contact.birthday")
                }}</label>
                <input
                  type="date"
                  class="form-control"
                  id="contact_birthday"
                  v-model="item.birthday"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_gender">{{
                  $t("leffit.crm.contact.gender")
                }}</label>
                <select
                  v-model="item.gender"
                  style="position:relative"
                  class="form-control"
                  id="contact_gender"
                >
                  <option value="0">{{
                    $t("leffit.crm.contact.gender_woman")
                  }}</option>
                  <option value="1">{{
                    $t("leffit.crm.contact.gender_male")
                  }}</option>
                  <option value="-1">{{
                    $t("leffit.crm.contact.gender_unknown")
                  }}</option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <label for="contact_coc">{{
                  $t("leffit.crm.contact.coc")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_coc"
                  v-model="item.coc"
                />
              </div>

              <div class="form-group col-6">
                <label for="contact_vat_number">{{
                  $t("leffit.crm.contact.vat_number")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_vat_number"
                  v-model="item.vat_number"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-4">
                <label for="contact_iban_name">{{
                  $t("leffit.crm.contact.iban_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_iban_name"
                  v-model="item.iban_name"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_iban_number">{{
                  $t("leffit.crm.contact.iban_number")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_iban_number"
                  v-model="item.iban_number"
                />
              </div>

              <div class="form-group col-4">
                <label for="contact_swift_code">{{
                  $t("leffit.crm.contact.swift_code")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="contact_swift_code"
                  v-model="item.swift_code"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_language">{{
                  $t("leffit.crm.contact.language")
                }}</label>
                <select2
                  :options="
                    language.map((l) => {
                      return { id: l.code, text: l.name };
                    })
                  "
                  v-model="item.language"
                  style="position:relative;border:0"
                  class="form-control language p-0"
                  id="contact_language"
                ></select2>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_remarks">{{
                  $t("leffit.crm.contact.remarks")
                }}</label>
                <textarea
                  class="form-control WYSIWYG"
                  id="organization_remarks"
                  v-model="item.remarks"
                ></textarea>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="contact_status_id">{{
                  $t("leffit.crm.contact.status_id")
                }}</label>

                <select
                  class="form-control"
                  id="contact_status_id"
                  v-model="item.status_id"
                >
                  <option value="1" selected>{{
                    $t("leffit.crm.contact.active")
                  }}</option>
                  <option value="0">{{
                    $t("leffit.crm.contact.inactive")
                  }}</option>
                </select>
              </div>
            </div>

            <div class="form-row" v-if="pagedata.data">
              <div class="form-group col-12">
                <label for="contact_parent_id">{{
                  $t("leffit.crm.contact.parent_id")
                }}</label>
                <select2
                  v-model="item.parent_id"
                  :options="
                    pagedata.data.map((l) => {
                      return {
                        id: l.id,
                        text: l.title + ' ' + l.firstname + ' ' + l.lastname,
                      };
                    })
                  "
                  style="position:relative;width:100%;border:0;padding:0"
                  class="form-control parent"
                  id="contact_parent_id"
                ></select2>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 ">
                <label for="contact_optin" class="d-flex" style="width:60px">
                  <input
                    :checked="item.optin == 1 ? true : false"
                    @click="item.optin = item.optin == 0 ? 1 : 0"
                    style="height: 21px;"
                    type="checkbox"
                    class="form-control d-block"
                    id="contact_optin"
                  />
                  <div>{{ $t("leffit.crm.contact.optin") }}</div>
                </label>
              </div>
            </div>
            <div v-if="item.extra">
              <fieldset
                v-for="(group, index) in item.extra.fields"
                :key="index"
                class="group mt-2"
              >
                <label for="">{{
                  group.group.toUpperCase() + " -> " + group.label
                }}</label>
                <input
                  v-model="group.value"
                  type="text"
                  class="form-control"
                  :placeholder="group.label"
                />
              </fieldset>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="closemodal"
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              {{ $t("system.close") }}
            </button>
            <button @click="create()" type="button" class="btn btn-primary">
              {{ $t("system.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import contactTable from "./table";
import axios from "axios";
export default {
  name: "index",
  data() {
    return {
      pagedata: {},
      item: {
        title: "",
        firstname: "",
        prelastname: "",
        lastname: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "NL",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        picture: "",
        function: "",
        birthday: "",
        gender: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "NL",
        remarks: "",
        status_id: 1,
        parent_id: "",
        optin: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      search: { },
      language: language,
      country: country,
      import_error: "",
    };
  },
  components: { "contact-table": contactTable },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
  },
  methods: {
    trigger() {
      document.getElementById("file").click();
    },
    exportproduct(tablename) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/export/?model=" +
            tablename,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          const type = result.headers["content-type"];
          const blob = new File([result.data], {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "contact.xlsx";
          link.click();
          var token = JSON.parse(localStorage.getItem("token"));
          // window.location.href = process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+'&colname='+this.tablefield+'&token='+token;
        })
        .catch((error) => {
          console.log(error.request);
          if (error.request.status == 500) {
            this.$store.dispatch("makeNotification", {
              title: "Server error",
              text: "Unable to export server error occured!",
              type: "danger",
            });
          }
        });
    },
    importProduct(filterName) {
      let formData = new FormData();
      formData.append("file", document.getElementById("file").files[0]);
      formData.append("model", filterName);
      let header = {
        "Content-Type": "multipart/form-data",
      };
      this.import_error = "";
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/import/?model=" +
            filterName,
          formData,
          header
        )
        .then((result) => {
          console.log(result);
          this.$store.dispatch("makeNotification", {
            title: "Successfull",
            text: "Your importation was successfull",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.processImportError(error);
        });
    },
    processImportError(error) {
      if (error.request.status == 422) {
        var response = JSON.parse(error.request.response);
        var errors = response.errors;
        var row = "";
        var error_text = "";
        for (var err in errors) {
          var title = err.split(".");
          if (title.length > 1) {
            row = "Row " + title[0];
          }
          error_text = errors[err][0].replace(title[0] + ".", "");
          this.import_error += row + " " + error_text + "<br />";
        }
        // console.log(row,error_text);
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Your importation was not successfull",
          type: "danger",
        });
      }
    },
    create() {
      var formData = new FormData();
      console.log(this.item.extra);
      for (var field in this.item) {
        if (field != "extra") {
          formData.append(field, this.item[field]);
        }
      }
      formData.append("extra", JSON.stringify(this.item.extra.fields));
      var profile_picture = document.getElementById("contact_picture").files;
      if (profile_picture.length > 0) {
        formData.append("contact_picture", profile_picture[0]);
      }

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/contacts",
          details: formData,
        })
        .then((data) => {
          // console.log('result',JSON.parse(data));
          var err_count = JSON.parse(data.request.response);
          console.log(data.request.status);
          console.log(err_count);
          console.log(Object.entries(err_count).length);
          if (data.request.status >= 200 && data.request.status <= 210) {
            this.$store.dispatch("makeNotification", {
              title: "ITEM ADDED",
              text: this.item.firstname + " was added successfully",
              type: "success",
            });
            this.pagedata.unshift(data.data.data);
            document.getElementById("closemodal").click();
          } else {
            Object.entries(err_count).forEach(([key, value]) => {
              this.$store.dispatch("makeNotification", {
                title: "Error",
                text: value[0],
                type: "danger",
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.$store
      .dispatch(
        "get",
        "/lefficient/leffit/packages/settings/crm_contact_settings"
      )
      .then((data) => {
        var temp = this.item;
        temp.extra = data.data;
        this.item = null;
        this.item = temp;
        console.log(this.item.extra);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.select2-container {
  height: 100%;
}
</style>

<style lang="css" scoped></style>
