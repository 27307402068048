<template>
    <div v-if="loaded">
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.stock.warehouses.name') }}</h3>
      	</div>
        <div class="row">
          <a href="/admin/stocks/settings/warehouses/create" class="btn btn-success btn-sm">{{ $t('leffit.products.stock.add_warehouses') }}</a>
        </div>
        <table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th v-for="(attribute,index) in attributes" :key="index">{{ $t('leffit.crm.contact.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a @click="searchTable()" class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th v-for="(attribute,index) in attributes" :key="index"><input type="text" v-model="search.search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr v-for="(item, index) in warehouses.data" :key="index">
      				<td>
      					<router-link :to="'/admin/stocks/settings/warehouses/'+item.id" class="btn btn-open">
      						<i class="fa fa-search"></i>
      					</router-link>
      					<router-link :to="'/admin/stocks/settings/warehouses/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
                <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes">{{ item[attribute] }}</td>
      			</tr>
      		</tbody>
      	</table>
        <pagination
          :limit="2"
          :data="warehouses"
          @pagination-change-page="searchTable"
      ></pagination>
    </div>
</template>
<script>

export default {
    name:'product-table', 
    data(){
        return {
            attributes : [
              'name', 
              'address',
              'address2',
              'zipcode',
              'city',
              'country',
              'phone',
              'mobile',
              'fax',
              'email',
              'picture', 
              'language',
              'remarks', 
              'created_at',
              'updated_at',
              'deleted_at',
          ],
          search : {search:{}},
          warehouses:[],
          loaded:false
        }
    },
    methods:{
        searchTable(page=1){
          var temp=[];
          for(var s in this.search.search){
              temp.push({
                'attribute':s,
                'value':this.search.search[s]
              });
          }
          this.search.search=temp;
          console.log(this.search);
          this.$store.dispatch('post',{details:this.search,endpoint:'/leffit/products/warehouses/search?page='+page})
          .then((data)=>{
            this.loaded = true
            console.log(data);
            this.warehouses=data.data;
          })
          .catch((error)=>{
            console.log(error)
          })
        },
        translate (input) {
          if(input){
            if(input.constructor === String){
              if(input.indexOf('leffit.products')!==-1){
                return this.$t(input);
              }else{
                return input;
              }
            }
          }
          
          return input;
        },
        deleteItem(id){
          if(!confirm('Are you sure you want to delete?')){
            return false;
          }
          this.warehouses=this.warehouses.filter((dt)=> dt.id != id);
          console.log(this.warehouses);
          this.$store.dispatch('delete','/leffit/products/warehouses/'+id)
          .then(data=>{
            console.log(data);
            this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Item was removed successfully',type:'success'});
          });
        }
    }, 
    created(){ 
      this.searchTable()
      // this.$store.dispatch('post',{endpoint:'/leffit/products/warehouses/search?page=1',details:{search:[{attribute:'limit', value:10}]}})
      // .then(data=>{ 
      //   console.log(data);
      //   this.warehouses = data.data;
      // }).catch((error)=>{
      //   window.console.log(error);
      // })
    }
}
</script>