<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.shipping.cost_create') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.cost_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.cost_amount') }}</label>
                        <input type="number" class="form-control" id="title" v-model="item.amount">
                    </div> 
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.method') }}</label>
                        <Select2 v-model="item.shipping_method_id" :options="methods.map((d)=>{ return {id:d.id,text:d.name};})"></Select2>
                    </div> 
                </div>
 
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script> 
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',amount:0,shipping_method_id:0
            }, 
            methods:[]
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            } 
            this.$store.dispatch('post',{endpoint:'/leffit/shipping/shippingcosts',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Cost created",text:'Cost was created automatically'});
                this.item={};
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    created(){ 
        this.$store.dispatch('get','/leffit/shipping/shippingmethods')
        .then(data=>{
        	console.log(data);
                this.methods = data.data.data;
        });
    }
    
}
</script>
