<template>
	<div>
		<div class="head row p-0 m-0">
            <div v-show="isDisabled" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('leffit.products.product_status') }}</h3></div>
            <div v-show="!isDisabled" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('lefficient.system.edit_field', [$t('leffit.products.product_status')]) }}</h3></div>
        	<h4 v-show="isDisabled" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> {{ $t('lefficient.system.edit') }}</router-link></h4>
      	</div>
		<div v-show="isDisabled" class="row">
				<div class="col-sm-12 col-md-12"> 
					<div class="card basicDetailsCard">
						<h4>BASIC DETAILS</h4>
						<div id="">
							<div class="bsc bsc2">
								<b>Name:</b>
								<h6>{{item.name}}</h6>
							</div> 
						</div>
					</div> 
				</div> 
			</div>
		<form @submit="updateItem($event)"> 
			<div v-show="!isDisabled"> 
				<div class="card"> 
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="filter_title">{{ $t('leffit.product.type.name') }}</label>
                            <input type="text" :disabled="isDisabled" class="form-control" id="filter_title" v-model="item.name">
                        </div> 
                    </div>
  
					<div class="row p-0 m-0">
						<button class="btn btn-success">{{ $t('lefficient.system.save') }}</button>
					</div>
				</div>
			</div>	  
		</form>
	</div>
	
	
</template>

<script>
import { mapState } from 'vuex';
import language from '../../../../data/language';

export default {
	props:['id', 'edit'], 
  	name: 'productFilter',
  	data () {
	    return {
	    	item : {
                'name' 		: '', 
			},
			language:language,
            profileImageSet:false,
            filters:[],
            types:[]
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
  		isDisabled : function(){
  			return !(this.edit == 'edit')
  		}
	},  
	methods:{
		updateItem(e){
			e.preventDefault();
			this.$store.dispatch('put',{endpoint:'/leffit/products/productstatuses/'+this.item.id,details:this.item})
			.then(data=>{console.log('result',data.data); this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});}).catch((error)=>{console.log(error.request);});
		},
		 
	},
  	mounted(){ 
	    this.$store.dispatch('get','/leffit/products/productstatuses/'+this.id)
        .then(data=>{
        	console.log('result',data.data.data);
			this.item = data.data.data;  
		}); 
	}
}
</script>

<style lang="css" scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>