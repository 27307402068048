import axios from 'axios';

export default{

    processError(context,error){ 
        
        let messages=[]; 

        switch(error.request.status){
            case 400:
                    //bad request
                    messages=[]
                break;
            case 401:
                    //unauthorized 
                    messages=[{title:"Unauthorised",text:'You are not authorised'}];
                    context.state.lockscreen.show=true;
                break;
            case 402:
                    // forbidden
                    var response = JSON.parse(error.request.response);
                    messages=[{title:"ERROR",text:response.msg}]
                break; 
            case 403:
                    // forbidden
                    messages=[]
                break; 
            case 404:
                    // not found
                    messages=[{title:"Route error",text:'Route not found'}]
                break; 
            case 422:
                    response = JSON.parse(error.request.response);
                    console.log('fdfdf');
                    // validation
                    window.console.log(response);
                    for(var err in response.errors){
                        messages.push({title:response.messages,text:response.errors[err][0]});
                    }
                    for(var err2 in response){
                        messages.push({title:response.messages,text:response[err2][0]});
                    }
                break; 
            case 500:
                    // internal error
                    response =error.request;
                    messages=[{title:"Server error",text:'Opps! server error occured'}]
                    window.console.log(response);
                break;

            default:
                    // error
                    messages=[{title:"Unknown error",text:'Opps! error occured'}]
                break;
        }

        messages.forEach((msg)=>{
            var data={
                id:Math.round(Math.random() * 999),
                title:msg.title,
                message:msg.text,
                autoDismiss:true,
                type:'danger',
                interval:2000
            };
            context.dispatch('displayNotification',data); 
        })
 
    },
    makeNotification(context,data,time=2000){
        var notif={
            id:Math.round(Math.random() * 999),
            title:data.title,
            message:data.text,
            autoDismiss:true,
            type:data.type,
            interval:time
        };
        context.dispatch('displayNotification',notif); 
    },
    removeNotification(context,data){
        setTimeout(() => {
            context.state.notifications[data.type]=context.state.notifications[data.type].filter((ntf)=> ntf.id != data.id);
        }, data.interval ? data.interval:0);
    },
    displayNotification(context,data){
        context.state.notifications[data.type].push(data);  
        if(data.autoDismiss){
            context.dispatch('removeNotification',{id:data.id,type:data.type,interval:data.interval});
        }
    },
    get(context, endpoint){
        return new Promise((resolve,reject)=>{
            // console.log('GET request with token:', context.state.access_token);
            axios.get(context.state.endpoint+endpoint) 
            // axios.get(context.state.endpoint+endpoint,{'Authorization': 'Bearer '+context.state.access_token})
            .then((data)=>{
                resolve(data)
            })
            .catch((error)=>{
                console.log('get request');
                console.log('error.request', error.request); 
                console.log('error', error); 
                context.dispatch('processError',error);
                reject(error)
            })
        });
    },
    
    getImage(context, endpoint){
        return new Promise((resolve,reject)=>{
            axios.get(context.state.endpoint+endpoint,{responseType: 'blob'})  
            .then((data)=>{
                resolve(data)
            })
            .catch((error)=>{ 
                console.log('error.request', error.request); 
                reject(error)
            })
        });
    },
    post(context , payload){ 
        if(payload.loader){
            context.state.loader.show=payload.loader.show;
            context.state.loader.text=payload.loader.text;
            context.state.loader.type=payload.loader.type ? payload.loader.type:'page';
        }
        return new Promise((resolve,reject)=>{ 

            axios.post(context.state.endpoint+payload.endpoint, payload.details)
            .then((data)=>{  
                console.log(data) ;
                if(payload.notification){
                    context.dispatch('makeNotification',payload.notification);
                }
                resolve(data)
                context.commit('closePageLoader');
            })
            .catch((error)=>{ 
                
                console.log(error.request);
                context.commit('closePageLoader');
                context.dispatch('processError',error);
                if(!payload.endpoint=='/lefficient.leffit/translations'){
                    context.dispatch('processError',error); 
                }
                // reject(error);
            })
        });
    },
    post_progress(context , payload){ 
        if(payload.loader){
            context.state.loader.show=payload.loader.show;
            context.state.loader.text=payload.loader.text;
            context.state.loader.type=payload.loader.type ? payload.loader.type:'page';
        }
        return new Promise((resolve,reject)=>{ 

            axios.post(context.state.endpoint+payload.endpoint, payload.details,{
                onUploadProgress: function(progressEvent) {
                  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                  context.state.upload_progress=percentCompleted;
                }
              })
            .then((data)=>{ 
                
                if(payload.notification){
                    context.dispatch('makeNotification',payload.notification);
                }
                resolve(data)
                context.commit('closePageLoader');
            })
            .catch((error)=>{ 
                console.log(error.request);
                context.commit('closePageLoader');
                if(!payload.endpoint=='/lefficient.leffit/translations'){
                    context.dispatch('processError',error); 
                }
                reject(error);
            })
        });
    },
    put(context , data){
        console.log(data);
        if(data.loader){
            context.state.loader.show=data.loader.show;
            context.state.loader.text=data.loader.text;
            context.state.loader.type=data.loader.type ? data.loader.type:'page';
        }
        return new Promise((resolve,reject)=>{
            axios.put(context.state.endpoint+data.endpoint , data.details)
            .then((result)=>{
                if(data.notification){
                    context.dispatch('makeNotification', data.notification);
                }
                resolve(result);
                context.commit('closePageLoader');
            })
            .catch((error)=>{
                context.commit('closePageLoader');
                context.dispatch('processError',error);
                reject(error)
            })
        });
    },
    delete(context , endpoint){  
        return new Promise((resolve,reject)=>{
            axios.delete(context.state.endpoint+endpoint)
            .then((data)=>{
                resolve(data);
                context.commit('closePageLoader');
            })
            .catch((error)=>{
                context.commit('closePageLoader');
                context.dispatch('processError',error);
                reject(error)
            })
        });
    },
    login(context,data){ 
        if(data.loader){
            context.state.loader.show=data.loader.show;
            context.state.loader.text=data.loader.text;
            context.state.loader.type=data.loader.type ? data.loader.type:'page';
        }
        context.commit('resetHeaders');
        var payload=data;
        console.log(payload);
        context.dispatch('post',{endpoint:'/login',details:data})
        .then(data=>{
            window.console.log(data);
            context.commit('setSanctumToken', data); 
            
            //getting users details
            context.dispatch('getSanctumUser')
            .then(()=>{
                if(!payload.loggedIn){
                    context.dispatch('makeNotification',{title:"AUTHENTICATION VALIDATED",text:'Login was successfull',type:'success'});
                    window.location.href=context.state.redirectAfterLogin;
                }
                else{
                    context.state.lockscreen.show=false;
                }
            });
            
        })
        .catch(error=>{
            window.console.log(error);
            if(error.request == 401){
                context.dispatch('makeNotification',{title:"AUTHENTICATION ERROR",text:'Invalid login details',type:'danger'});
            }else{
                context.dispatch('makeNotification',{title:"SERVER ERROR",text:'Opps an error occured!',type:'danger'});
            }
        }); 
    },
    refreshToken(context){
        console.log(context.state.refresh_token);
        context.dispatch('get','/refresh-token?refresh_token='+context.state.refresh_token)
        .then(data=>{
            console.log(data);
            context.commit('setSanctumToken', data);
            // context.commit('setTokens',{access_token:data.data.access_token,refresh_token:data.data.refresh_token,expires_in:data.data.expires_in});
        })
        .catch((error)=>{
            console.log(error);
        })
    },
    revokeToken(context, data) {
        context.dispatch('post',{endpoint:'/sanctum/revoke',details:data})
        .then(() => {
            localStorage.setItem('token', 'null');
            localStorage.setItem('token_expiry', 'null');
            this.$router.push({name: 'login'});
        });
    },
    getSanctumUser(context){
        console.log('getSanctumUser');
        return new Promise((resolve,reject)=>{
            context.dispatch('get','/lefficient/leffit/get-user').then((data)=>{ 
                console.log('getuser', data);
                context.commit('setUser',data.data.data);
                resolve();
            }).catch((error)=>{
                console.log('get user');
                console.log('error', error); 
                console.log('error request ', error.request); 
                context.dispatch('processError',error);
                reject(error)
            })
        })
    },
    getUser(context){
        console.log('getSanctumUser');
        return new Promise((resolve,reject)=>{
            context.dispatch('get','/lefficient/leffit/get-user').then((data)=>{ 
                console.log('getuser', data);
                context.commit('setUser',data.data.data);
                resolve();
            }).catch((error)=>{
                console.log('get user');
                console.log('error', error); 
                console.log('error request ', error.request); 
                context.dispatch('processError',error);
                reject(error)
            })
        })
    },
    logout ( context) {
        context.commit('clearUserData');
    },
    // getTranslations(context){
        // return new Promise((resolve,reject)=>{
            // context.dispatch('get','/lefficient/leffit/translations/nl').then((response)=>{
                // context.commit('setUser',response.data.data);
                // resolve();
            // }).catch((error)=>{
                // console.log('error', error.request); 
                // context.dispatch('processError',error);
                // reject(error)
            // })
        // })
    // },

}