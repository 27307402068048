import Vue from 'vue';
import Router from 'vue-router';

// Public pages:
import login from './../src/pages/public_pages/login'
import signup from './../src/pages/public_pages/signup'
import forgotPassword from './../src/pages/public_pages/forgot-password'
import resetPassword from './../src/pages/public_pages/reset-password'
import Schedule from './../src/leffit/appointments/schedule'


// Admin
import index from "./../src/layout/index";
import adminRouter from './admin/index' 

Vue.use(Router);

const router=new Router({
    mode:'history',
    routes:[
        {
            path:"/",
            component:index,
            meta:{
                AuthRequired:true
            }, 
        },
        {
            path:"/signup",
            component:signup,
            name:'signup'
        },
        {
            path:"/verify-account/:token",
            component:resetPassword
        },
        {
            path:"/login",
            component:login,
            name:'login'
        }, 
        {
            path:"/reset-password/:token",
            component:resetPassword,
            name:'reset-password'
        },
        {
            path:"/admin",
            component:index,
            children : adminRouter,
            meta:{
                AuthRequired:true
            }, 
        },
        {
            path:"/forgot-password",
            component:forgotPassword
        }, 
        {
            path:"/reset-password/:token",
            component:resetPassword
        },
        {
            path:"/edit-user/:display_name",
            component:index,
            meta:{
                AuthRequired:true
            }, 
        },
        {
            path:"/appointments/schedule",
            component:Schedule,
            meta:{
                AuthRequired:true
            }, 
        },
        // {
        //     path:"/profile/:id",
        //     component:profile,
        //     meta:{
        //         AuthRequired:true
        //     },
        //     props: (route) => ({
        //         id : parseInt(route.params.id),
        //         edit : false,
        //     }),
        // },
    ]
});


export default router;

router.beforeEach((to,from,next)=>{
    const loggedIn = localStorage.getItem('user');
    let token=localStorage.getItem('token');  
    // if(to.matched.some(record => record.meta.AuthRequired) && (!loggedIn || !token) ) {
    //     next('/login')
    //     return
    // }
    next(); 
})