<template>
    <div>
        <!-- Treeview -->
        <!-- <pac-tree-view :model="data" @delete-node="onDel">
            <span class="icon vtl-item" slot="addTreeNodeIcon">📂</span>
            <span class="icon vtl-item" slot="addLeafNodeIcon">＋</span>
            <span class="icon vtl-item" slot="editNodeIcon">📃</span>
            <span class="icon vtl-item" slot="delNodeIcon">✂️</span>
        </pac-tree-view> -->

        <!-- <button class="btn btn-success btn-sm" @click="getNewTree">Save changes</button> -->
    </div>
</template>

<script>
// import PacTreeView from '../../../../../helpers/components/treeview/PacTreeView'
import {Tree} from '../../../../../helpers/components/treeview/Tree'
import _ from 'lodash';

export default {
    name: 'index',
    components: {
        // PacTreeView
    },
    data() {
        return {
            id: 0,
            newTree: {},
            data: new Tree([]),
        }
    },

    mounted() {
        this.$store.dispatch('get', '/lefficient/leffit/packages/settings/' + this.$route.params.id)
            .then(data => {
                let dt= []
                dt[0] = this.transformObject(JSON.parse(data.data.setting), "Config " + this.$route.params.id);
                this.data = new Tree(dt)
            })
    },

    methods: {
        onDel (node) {
            node.remove()
        },
        transformValue: function(valueToTransform, keyForValue){
            this.id++;
            return {
                name: valueToTransform,
                key: keyForValue,
                id: this.id,
                isLeaf: true
            }
        },
    
        generateChildrenFromCollection: function(collection){
            return _.map(collection, (value, keyOrIndex)=>{
                if (this.isObject(value)) {
                    return this.transformObject(value, keyOrIndex);
                }
                if (this.isArray(value)) {
                    return this.transformArray(value, keyOrIndex);
                }
                if (this.isValue(value)) {
                    if(this.isJsonString(value) && isNaN(value)) {
                        return this.transformObject(JSON.parse(value), keyOrIndex);
                    }else {
                        return this.transformValue(value, keyOrIndex);
                    }
                }
            }) ;   
        },
    
        // Transformer for the Array type
        transformArray: function(arrayToTransform, keyForArray){
            this.id++;
            return {
                name: keyForArray,
                id: this.id,
                children: this.generateChildrenFromCollection(arrayToTransform)
            }
        },
        
        // Transformer for the Object type
        transformObject: function(objectToTransform, keyForObject){
            this.id++;
            return {
                name: keyForObject,
                id: this.id,
                children: this.generateChildrenFromCollection(objectToTransform)
            }
        },
        
        // Helper Methods for value type detection
        isObject: function(value){
            return _.isPlainObject(value);
        },
        
        isArray: function(value){
            return _.isArray(value);
        },
        
        isValue: function(value){
            return !this.isObject(value) && !this.isArray(value);
        },

        isJsonString: function(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        getNewTree () {
            function _dfs (oldNode, isRoot) {
                let newNode = {}            

                if (oldNode.children && oldNode.children.length > 0) {
                    let children = {}

                    for (let i = 0, len = oldNode.children.length; i < len; i++) {
                        if(oldNode.children[i].key != undefined) {
                            children[oldNode.children[i].key] = oldNode.children[i].name
                        } else {
                            let keyName = isRoot ? 'root' : oldNode.children[i].name

                            children[keyName] = _dfs(oldNode.children[i])
                        }
                    }

                    newNode = children
                }
                else {
                    newNode[oldNode.key] = oldNode.name
                }

                return newNode
            }

            this.newTree = _dfs(this.data, true)

            this.$store.dispatch('put', 
            {
                endpoint: '/lefficient/leffit/packages/settings/' + this.$route.params.id,
                details: this.newTree
            }).then(data => {
                console.log(data);
                this.$store.dispatch('makeNotification',{title:"Update Config",text:'Config updated sucessfully',type:'success'});
            }).catch(error => {
                console.log(error);
                this.$store.dispatch('makeNotification',{title:"Update Config",text:'Config updated error',type:'danger'});
            })
        }
    }
}
</script>
