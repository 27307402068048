<template>
    <div class="row">
        <div class="col-12">
            <a href="vouchers/create" class="btn btn-sm btn-success">{{ $t('leffit.products.voucher.create') }}</a>
        </div>
        <div class="col-12">
            <voucher-table ></voucher-table>
        </div>
    </div>
</template>

<script>
import table from './table';
export default {
    components:{
        'voucher-table':table
    },
    data(){
        return {

        }
    },
    created(){

    }
}
</script>
<style scoped>

</style>
