<template>
    <div class="col-12 pt-2">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a :class="tabsController.english ? 'nav-link active':'nav-link'"  @click="toggleTab('english')" href="#english" data-toggle="tab" role="tab" aria-controls="english" aria-selected="true">{{ $t('lefficient.languages.english') }}</a>
            </li>
            <li class="nav-item">
                <a :class="tabsController.dutch ? 'nav-link active':'nav-link'" @click="toggleTab('dutch')" href="#dutch" data-toggle="tab" role="tab" aria-controls="dutch" aria-selected="false">{{ $t('lefficient.languages.dutch') }}</a>
            </li> 
        </ul>
        <div class="tab-content" id="myTabContent">
            <div :class="tabsController.english ? 'tab-pane fade show active':'tab-pane fade show'" id="english" role="tabpanel" aria-labelledby="english-tab">
                <div class="form-group">
                    <label for="">{{ $t('lefficient.system.body') }}</label>
                    <editor
                        v-model="description.en"
                        :init="{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                        }"
                    />
                </div>
                <div class="form-group">
                    <button @click="updateDescription()" class="btn btn-success btn-s">{{ $t('lefficient.system.update') }}</button>
                </div>
            </div> 
            <div :class="tabsController.dutch ? 'tab-pane fade show active':'tab-pane fade show'" id="dutch" role="tabpanel" aria-labelledby="dutch-tab">
                <div class="form-group">
                    <label for="">{{ $t('lefficient.system.body') }}</label>
                    <editor
                        v-model="description.de"
                        :init="{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                        }"
                    />
                </div>
                <div class="form-group">
                    <button @click="updateDescription()" class="btn btn-success btn-s">{{ $t('lefficient.system.update') }}</button>
                </div>

            </div> 
        </div>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return{
            tabsController:{
				english:true,
                dutch:false,
            },
            description: {
                en: null,
                de: null
            }
        }
    },
    methods:{
        toggleTab(field){
			this.tabsController={
				english:false,
                dutch:false,
			}
			this.tabsController[field]=true; 
		},
        updateDescription(){
            this.$parent.item.description = this.description.de;
            this.$parent.item.description_en = this.description.en;
            this.$parent.updateItem();
        }
    },
    created() {
        this.description.en = this.item.description_en
        this.description.de = this.item.description
    }

}
</script>
<style scoped>

</style>