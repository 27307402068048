<template>
	<div>
		<div class="head row p-0 m-0">
            <div v-show="isDisabled()" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('leffit.orders.ordertype.View') }}</h3></div>
            <div v-show="!isDisabled()" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('leffit.orders.ordertype.Edit') }}</h3></div>
        	<h4 v-show="isDisabled()" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> EDIT</router-link></h4>
			<div v-show="isDisabled()" class="col-12 p-0 m-0">
				<!-- <button @click="set()" data-target="#addnew" data-toggle="modal" class="btn btn-success btn-sm">Add New Filter</button> -->
			</div>
      	</div>
		<div v-show="isDisabled()" class="row">
				<div class="col-sm-12 col-md-12">
					<div class="card basicDetailsCard">
						<h4>BASIC DETAILS</h4>
						<div id="">
							<div class="bsc bsc2">
								<b>Name:</b>
								<h6>{{item.name}}</h6>
							</div>
							<div class="bsc bsc2">
								<b>pickorder:</b>
								<h6 v-html="item.pickorder"></h6>
							</div>
							<div class="bsc bsc2">
								<b>Active:</b>
								<h6 v-html="item.active"></h6>
							</div>

						</div>
					</div> 
				</div>
			</div>

		<form>
			<div v-show="!isDisabled()">
				 <div class="row ">
					<div class="accordion col-12" id="accordionExample">
						<div class="card" v-for="(setting,index) in data" :key="index">
							<div class="card-header" :id="'headingOne'">
								<h2 class="mb-0 d-flex" style="justify-content:space-between">
									<button v-if="setting.type != 'bool'" style="width: 100%;text-align:left;border:none;display: flex;justify-content: space-between;" class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapseOne'+index" aria-expanded="true" aria-controls="collapseOne">
										<span>{{setting.title}}</span>
										<span v-if="setting.type == 'list'"  style="font-size: 13px;color: black">---- click to open ----</span>
									</button>
									<label v-else :for="setting.title+'label'+index" class="d-flex col-12 text-left">
										<input v-model="setting.value" style="width:30px;height:18px;margin-top:0px" :id="setting.title+'label'+index"  type="checkbox" class="form-control">
										{{setting.title}}
									</label>
									<select style="width:50%" v-if="setting.type == 'list-select'" name="" id="" v-model="setting.value" class="form-control">
										<option value="">click to select</option>
										<option v-for="(opt,index2) in setting.data" :key="index2" :value="opt.value">{{opt.title}}</option>
									</select>
								</h2>
							</div> 
							<div v-if="setting.type == 'list'" :id="'collapseOne'+index" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
								<div class="card-body">
									<label v-for="(dt,index3) in setting.data" :key="index3" :for="dt.title+'-'+index+'-'+index3" class="col-12 pt-2 pb-2">
										<input :id="dt.title+'-'+index+'-'+index3" type="checkbox" v-model="dt.value">
										<span class="pb-2 pl-2">{{dt.title}}</span>
									</label>
								</div>
							</div>
						</div> 
						<div class="card col-12">
							<button type="button" @click="updateItem()" class="btn btn-success">UPDATE</button>
						</div>
					</div> 
				</div> 
			</div>
		</form>
	</div>


</template>

<script>
import { mapState } from 'vuex';
export default {
	props:['id', 'edit'],
	name: 'productFilter',
  	data () {
	    return { 
			item:{active:0,name:'',pickorder:''},
			data:[
				{title:'index',type:'list',data:[{title:'organization_name',value:false},{title:'contact_name',value:false},  {title:'firstname',value:false},{title:'lastname',value:false},{title:'companyname',value:false},{title:'address',value:false},{title:'zipcode',value:false},{title:'city',value:false},{title:'country',value:false},{title:'email',value:false},{title:'phone',value:false},{title:'orderstatus',value:false},  {title:'order_date',value:false},]},
				{title:'relationdetails',type:'bool',value:false},
				{title:'shippingdetails',type:'bool',value:false},
				{title:'files',type:'bool',value:false},
				{title:'purchase_costs',type:'bool',value:false},
				{title:'organizations',type:'list-select',value:'',data:[{title:'All',value:'all'},{title:'Creditors',value:'creditors'},{title:'Debtors',value:'debtors'},]},
				{title:'products',type:'list-select',data:[{title:'All',value:'all'},{title:'Supplier',value:'supplier'}, ]},
				{title:'orderdetails',type:'list',data:[{title:'show','value':false},{title:'currency','value':false},{title:'orderstatus','value':false},{title:'order_date','value':false},{title:'created_by_user','value':false},{title:'updated_by_user','value':false},{title:'prices','value':false},]},
				{title:'orderrow',type:'list',data:[{title:'amount',value:false},{title:'product_id',value:false},{title:'pricePer',value:false},{title:'price',value:false},{title:'vat',value:false},{title:'completed',value:false},{title:'remarks',value:false},{title:'discount',value:false},{title:'discountPercentage',value:false},{title:'purchase_cost',value:false},{title:'purchase_price_currency_id',value:false},{title:'purchase_price',value:false},{title:'supplier_product_identifier',value:false},{title:'delivery_time',value:false},]},
				{title:'orderrow_extra',type:'list',data:[{title:'amount',value:false},{title:'product_id',value:false},{title:'pricePer',value:false},{title:'price',value:false},{title:'vat',value:false},{title:'completed',value:false},{title:'expectedArrival',value:false},{title:'remarks',value:false},{title:'discount',value:false},{title:'discountPercentage',value:false},{title:'purchase_cost',value:false},{title:'purchase_price',value:false},{title:'supplier_product_identifier',value:false},{title:'delivery_time',value:false},]},
				{title:'buttons',type:'list',data:[{title:'productlist',value:false},{title:'productlist_no_price',value:false},{title:'picklist',value:false},{title:'packing_slip',value:false},{title:'sales_invoice',value:false},{title:'return_slip',value:false},{title:'credit_invoice',value:false}]}
			],
			settings:{}
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
	},
	methods:{ 
		isDisabled(){
  			return !(this.$route.meta.edit)
  		},
		updateItem(){
			console.log(this.data);
			
			this.settings.relationdetails=this.data.find((d)=>d.title == 'relationdetails').value;
			this.settings.shippingdetails=this.data.find((d)=>d.title == 'shippingdetails').value;
			this.settings.organizations=this.data.find((d)=>d.title == 'organizations').value;
			this.settings.products=this.data.find((d)=>d.title == 'products').value;

			this.settings.files=this.data.find((d)=>d.title == 'files').value;
			this.settings.purchase_costs=this.data.find((d)=>d.title == 'purchase_costs').value;
			this.settings.orderdetails=this.getSelecteds('orderdetails');
			this.settings.orderrow=this.getSelecteds('orderrow');
			this.settings.index=this.getSelecteds('index');
			this.settings.orderrow_extra=this.getSelecteds('orderrow_extra');
			this.settings.buttons=this.getSelecteds('buttons');
			this.item.settings=JSON.stringify(this.settings);

			console.log(this.settings);
			this.$store.dispatch('put',{endpoint:'/leffit/orders/order-types/'+this.item.id,details:this.item})
			.then(data=>{console.log('result',data.data); this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});}).catch((error)=>{console.log(error.request);});
		},
		getSelecteds(field){
			var temp=this.data.find((d)=> d.title == field);
			temp=temp.data.filter((d)=> d.value == true);
			temp=temp.map((d)=> d.title);
			return temp;
		},
		set(){
			window.$('.language2').select2();
			window.$('.parent2').select2();

		},
	},
  	mounted(){
	    this.$store.dispatch('get','/leffit/orders/order-types/'+this.$route.params.id)
        .then(data=>{
        	console.log('result',data.data);
			this.item = data.data.data;
			var temp=JSON.parse(data.data.data.settings);
			this.settings=temp;
			console.log(temp);

			//Making a view from the settings data
			this.data=this.data.map((d)=>{
				if(d.type == 'bool'){
					d.value=temp[d.title];
				}else if(d.type == 'list'){
					d.data=d.data.map((v)=>{
						if(temp[d.title].find((f)=> f == v.title)){
							v.value=true;
						}
						return v;
					});
				}else if(d.type == 'list-select'){
					d.value=temp[d.title];
				}
				return d;
			});

		}); 
	}, 
}
</script>

<style  scoped>
 .itemPermission{
        background-color: #d3d3d370;
        padding: 5px 8px;
        display: inline-block;
        width: auto;
        border-radius: 20px;
        margin-right:10px;
    }
    .itemPermission b{
        padding: 0px 10px;
        font-weight: 300;
        text-transform: capitalize;
    }
    .itemPermission a{
        color: red;
    }
    .itemPermission a i{
        font-weight: lighter;
        font-size: 14px;
    }
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>