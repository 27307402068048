export default {
  leffit: {
    bijenkorf: {
      pricate_export: "Pricat",
      webshop_export: "Webshop export",
      sales_report: "Sales report",
      virgocode_importeren: "Virgocodes",
    },
    shipping: {
      world: "World Shipping",
      us: "US Shipping",
      shippingstatuses: "Shippingstatuses",
      shippingstatus: "Shippingstatus",
      shippings: "Shippings",
      shippingregions: "Shippingregions",
      shippingregion: "Shippingregion",
      shippingmethods: "Shippingmethods",
      shippingmethod: "Shippingmethod",
      shippingcost: "Shippingcosts",
      shipping_status: { name: "Name", created_at: "Created at" },
      shipping_regions: { name: "Name", created_at: "Created at" },
      shipping_methods: {
        name: "Name",
        created_at: "Created at",
        active: "Active",
      },
      shipping_cost: {
        name: "Name",
        created_at: "Created at",
        amount: "Amount",
      },
      shipments: {
        status: "Status",
        method: "Method",
        extra: "Extra",
        created_at: "Created at",
      },
      shipment: "Shipment",
      permission: {
        update_shipping_status: "Update shipping_statuses",
        update_shipping_region: "Update shipping_regions",
        update_shipping_method: "Update shipping_methods",
        update_shipping_cost: "Update shipping_costs",
        update_shipment: "Update shipments",
        read_shipping_status: "Read shipping_statuses",
        read_shipping_region: "Read shipping_regions",
        read_shipping_method: "Read shipping_methods",
        read_shipping_cost: "Read shipping_costs",
        read_shipment: "Read shipments",
        list_shipping_status: "List shipping_statuses",
        list_shipping_region: "List shipping_regions",
        list_shipping_method: "List shipping_methods",
        list_shipping_cost: "List shipping_costs",
        list_shipment: "List shipments",
        delete_shipping_status: "Delete shipping_statuses",
        delete_shipping_region: "Delete shipping_regions",
        delete_shipping_method: "Delete shipping_methods",
        delete_shipping_cost: "Delete shipping_costs",
        delete_shipment: "Delete shipments",
        create_shipping_status: "Create shipping_statuses",
        create_shipping_region: "Create shipping_regions",
        create_shipping_method: "Create shipping_methods",
        create_shipping_cost: "Create shipping_costs",
        create_shipment: "Create shipments",
      },
      nl: "NL Shipping",
      eu: "EU Shipping",
      default: "DEFAULT Shipping",
    },
    products: {
      stikkers: "Stock stickers",
      web: {
        seo: {
          url: "Url",
          title: "Title",
          keywords: "Keywords",
          description: "Description",
        },
        description: { title: "Title" },
      },
      vouchers: "Vouchers",
      voucher: {
        value: "Value",
        validity: "Validity",
        start: "Start at",
        expire: "Expire at",
        redeemed: "Redeemed at",
        create: "Create",
        create_page: {
          start: "Start at",
          expire: "Expire at",
          validity: "Validity",
          value_type: "Value type (price)",
          value: "value (Ex.120)",
          create_button: "Create",
        },
      },
      warehouses: "Warehouses",
      warehouse: {
        zipcode: "Zipcode",
        status_id: "Status",
        remarks: "Remarks",
        picture: "Picture",
        phone: "Phone",
        organization_id: "Organization",
        name: "Name",
        mobile: "Mobile",
        language: "Language",
        fax: "Fax",
        email: "Email",
        country: "Country",
        city: "City",
        address2: "Address2",
        address: "Address",
      },
      type: { name: "Name", add: "Add Producttype" },
      stocks: "Stocks",
      stock: {
        warehouse_id: "Warehouse",
        stocks: "Stocks",
        stock_location_id: "Stock location",
        sku: "Sku",
        product_id: "Product",
        amount: "Amount",
        warehouse: "Warehouse",
        stock_location: "Stock location",
        stock_type: "Stock type",
        stock_locations: "Stock locations",
        add_warehouses: "Add Warehouses",
        warehouses: {
          name: "Warehouses",
        },
      },
      stock_locations: "Stock locations",
      stock_location: { name: "Stock location name" },
      statuses: { inactive: "Inactive", active: "Active" },
      settings: "Settings",
      producttypes: "Product Types",
      productstatuses: "Product Statuses",
      products: "Products",
      productmeta_labels: "Product Meta Labels",
      productimages: "Product Images",
      productfilters: "Product Filters",
      product: {
        length: "Length",
        width: "Width",
        height: "Height",
        silver_weight: "silver weight",
        weight: "Weight",
        vat: "Vat",
        updated_at: "Updated at",
        stock: "Stock",
        size: "Product size",
        remarks: "Remarks",
        related: {
          versions: "Versions",
          sizes: "Sizes",
          related_products: "Related",
        },
        producttype_id: "Producttype",
        producttype: "Producttype",
        productstatus_id: "Productstatus",
        productstatus: "Productstatus",
        product_type: "Product type",
        product_statuses: { inactive: "Inactive", active: "Active" },
        product_status: "Product status",
        product_add: "Add Product",
        prices: "Prices",
        price: "Price",
        plannable: "Plannable",
        other_details: "Other details",
        name: "Name",
        images: "Productimages",
        filters: "Productfilters",
        ean: "Barcode",
        description: "Description",
        deleted_at: "Deleted at",
        created_at: "Created at",
        create: "Create product",
        basic_details: "Product details",
        add_product: "Add product",
        select_product: "Select a product",
        product_detail: "Product details",
        casa_productcode: "Productcode",
        casa_seizoenscode: "Seasoncode",
        virgocode: "Virgocode",
        upload_image: "Images",
        ring_size: "Ring size",
        packaging: "Packaging",
        casa_filters: "Casa Filters",
      },
      product_types: {
        subscription: "Subscription",
        stripescard: "Stripescard",
        product: "Product",
        digital: "Digital Product",
        add_new: "Add new",
        add_new_type: "Add new type",
        name: "Name",
        close: "Close",
        save_changes: "Save changes",
      },
      product_statuses: {
        inactive: "Inactive",
        active: "Active",
        add_new: "Add new",
        add_new_status: "add new status",
        name: "Name",
        close: "Close",
        save_changes: "Save changes",
      },
      product_image: {
        title: "Title",
        filename: "Filename",
        caption: "Caption",
        alt: "short description",
        add: "Add",
      },
      product_filter: {
        view_filters: "View Filter",
        select: "Select",
        name_en: "English Name",
        lang: "Lang",
        filters: "Filters",
        edit_filters: "Edit Filter",
        description: "Description",
        description_en: "English Description",
        active: "Active",
        add_buton: "Add New",
        add_new_filter: "Add new filter",
        add_new_filter2: "Add new filter",
        name: "Name",
        pickorder: "Pickorder",
        parent: "Parent",
        language: "Language",
        close: "Close",
        save_changes: "Save changes",
        cancel: "Cancel",
        english_name: "English Name",
        english_description: "English Description",
        children_filter: {
          add: "Add",
        },
      },
      pricelevel: {
        wholesale_factor: "Wholesale price factor",
        wholesale: "Wholesale price",
        store_factor: "Store price factor",
        store: "Store price",
        purchase_price_latest: "Last purchaseprice",
        purchase_cost_latest: "Last purchasecosts",
        cost_price: "Cost price",
        purchase_exchange_rate: "Exchange rate",
        purchase_price: "Purchase price",
      },
      permission: {
        update_warehouse: "Update warehouses",
        update_stocklocation: "Update stocklocations",
        update_stock: "Update stocks",
        update_producttype: "Update producttypes",
        update_producttranslation: "Update producttranslations",
        update_productstatus: "Update productstatuses",
        update_productprice: "Update productprices",
        update_productmetalabel: "Update productmetalabels",
        update_productmeta: "Update productmetas",
        update_productimage: "Update productimages",
        update_productfiltermetalabel: "Update productfiltermetalabels",
        update_productfiltermeta: "Update productfiltermetas",
        update_productfilter: "Update productfilters",
        update_product: "Update products",
        read_warehouse: "Read warehouses",
        read_stocklocation: "Read stocklocations",
        read_stock: "Read stocks",
        read_producttype: "Read producttypes",
        read_producttranslation: "Read producttranslations",
        read_productstatus: "Read productstatuses",
        read_productprice: "Read productprices",
        read_productmetalabel: "Read productmetalabels",
        read_productmeta: "Read productmetas",
        read_productimage: "Read productimages",
        read_productfiltermetalabel: "Read productfiltermetalabels",
        read_productfiltermeta: "Read productfiltermetas",
        read_productfilter: "Read productfilters",
        read_product: "Read products",
        list_warehouse: "List warehouses",
        list_stocklocation: "List stocklocations",
        list_stock: "List stocks",
        list_producttype: "List producttypes",
        list_producttranslation: "List producttranslations",
        list_productstatus: "List productstatuses",
        list_productprice: "List productprices",
        list_productmetalabel: "List productmetalabels",
        list_productmeta: "List productmetas",
        list_productimage: "List productimages",
        list_productfiltermetalabel: "List productfiltermetalabels",
        list_productfiltermeta: "List productfiltermetas",
        list_productfilter: "List productfilters",
        list_product: "List products",
        delete_warehouse: "Delete warehouses",
        delete_stocklocation: "Delete stocklocations",
        delete_stock: "Delete stocks",
        delete_producttype: "Delete producttypes",
        delete_producttranslation: "Delete producttranslations",
        delete_productstatus: "Delete productstatuses",
        delete_productprice: "Delete productprices",
        delete_productmetalabel: "Delete productmetalabels",
        delete_productmeta: "Delete productmetas",
        delete_productimage: "Delete productimages",
        delete_productfiltermetalabel: "Delete productfiltermetalabels",
        delete_productfiltermeta: "Delete productfiltermetas",
        delete_productfilter: "Delete productfilters",
        delete_product: "Delete products",
        create_warehouse: "Create warehouses",
        create_stocklocation: "Create stocklocations",
        create_stock: "Create stocks",
        create_producttype: "Create producttypes",
        create_producttranslation: "Create producttranslations",
        create_productstatus: "Create productstatuses",
        create_productprice: "Create productprices",
        create_productmetalabel: "Create productmetalabels",
        create_productmeta: "Create productmetas",
        create_productimage: "Create productimages",
        create_productfiltermetalabel: "Create productfiltermetalabels",
        create_productfiltermeta: "Create productfiltermetas",
        create_productfilter: "Create productfilters",
        create_product: "Create products",
      },
      meta_label: {
        lang: "Lang",
        label: "Label",
        key: "Key",
        add_new: "Add New",
        add_new_filter: "Add new filter",
        close: "Close",
        save_changes: "Save Changes",
      },
      filters: {
        updated_at: "Updated at",
        pickorder: "Pickorder",
        name: "Name",
        language: "Language",
        description: "Description",
        deleted_at: "Deleted at",
        created_at: "Created at",
        add: "Add Filter",
        active: "Active",
        name_en: "Name_en",
        description_en: "Description_en",
      },
      filter: { parent: "Parent", to_add: "Add New", add: "Add" },
    },
    reports: {
      report: "Reports",
      turnover_rate: "Turnover Rate",
    },
    orders: {
      suppliers: "Suppliers",
      sales: "Sales",

      procurement: "Procurement",
      permission: {
        update_return: ["Update returns", "Update returns"],
        update_receipt: "Update receipts",
        update_quotation: ["Update quotations", "Update quotations"],
        update_packageslip: "Update packageslips",
        update_order: ["Update orders", "Update orders"],
        update_invoice: ["Update invoices", "Update invoices"],
        read_return: ["Read returns", "Read returns"],
        read_receipt: "Read receipts",
        read_quotation: ["Read quotations", "Read quotations"],
        read_packageslip: "Read packageslips",
        read_order: ["Read orders", "Read orders"],
        read_invoice: ["Read invoices", "Read invoices"],
        list_return: ["List returns", "List returns"],
        list_receipt: "List receipts",
        list_quotation: ["List quotations", "List quotations"],
        list_packageslip: "List packageslips",
        list_order: ["List orders", "List orders"],
        list_invoice: ["List invoices", "List invoices"],
        delete_return: ["Delete returns", "Delete returns"],
        delete_receipt: "Delete receipts",
        delete_quotation: ["Delete quotations", "Delete quotations"],
        delete_packageslip: "Delete packageslips",
        delete_order: ["Delete orders", "Delete orders"],
        delete_invoice: ["Delete invoices", "Delete invoices"],
        create_return: ["Create returns", "Create returns"],
        create_receipt: "Create receipts",
        create_quotation: ["Create quotations", "Create quotations"],
        create_packageslip: "Create packageslips",
        create_order: ["Create orders", "Create orders"],
        create_invoice: ["Create invoices", "Create invoices"],
      },
      ordertype: {
        sales_return: "Sales return",
        sales_quotation: "Sales quotation",
        sales_packageslip: "Sales packageslip",
        sales_order: "Sales order",
        sales_invoice_credit: "Sales credit invoice",
        sales_invoice: "Sales invoice",
        sales_backorder: "Sales backorder",
        procurement_return: "Procurement return",
        procurement_receipt: "Procurement receipt",
        procurement_quotation: "Procurement quotation",
        procurement_order_confirmation: "Procurement order confirmation",
        procurement_order: "Procurement order",
        procurement_invoice_credit: "Procurement Credit Invoice",
        procurement_invoice: "Procurement invoice",
        procurement_backorder: "Procurement backorder",
        ordertype: "OrderTypes",
        create: "create OrderType",
        Edit: "Edit OrderType",
        View: "View OrderType",
      },
      orderstatus: {
        sent: "Sent",
        ready_to_sent: "Ready to sent",
        ready_to_collect: "Ready to collect",
        on_hold: "On hold",
        delivered: "Delivered",
        created: "Created",
        confirmed: "Confirmed",
        collecting: "Collecting",
        canceled: "Canceled",
      },
      orderrow: {
        vat: "Vat",
        supplier_product_identifier: "Productcode Supplier",
        remarks: "Remarks",
        purchase_price_currency_id: "Currency",
        purchase_price: "Purchase price",
        purchase_currency: "Currency",
        purchase_cost: "Purchase cost",
        product_id: "Product",
        product: "Product",
        pricePer: "Price per",
        price: "Price",
        expectedArrival: "Expected Arrival",
        discountPercentage: "Discount (%)",
        discount: "Discount",
        description: "Description",
        delivery_time: "Delivery time",
        completed: "Completed",
        backorder: "Backorder",
        amount: "Amount",
      },
      order: {
        zipcode: "Zipcode",
        vat: "Vat",
        use_shipping_address: "Use shipping address",
        updated_by_user: "Updated by user",
        update: "Update order",
        total: "Total",
        subtotal: "Subtotal",
        shipping_details: "Shipping Details",
        ship_zipcode: "Zipcode",
        ship_lastname: "Lastname",
        ship_firstname: "Firstname",
        ship_country: "Country",
        ship_companyname: "Companyname",
        ship_city: "City",
        ship_address2: "Address2",
        ship_address: "Address",
        select_organization: "Select organization",
        select_contact: "Select contact",
        sales: "Sales",
        remarks: "Remarks",
        relationdetails: "Relationdetails",
        read: "Open order",
        procurements: "Procurements",
        phone: "Phone",
        organization_name: "Organization",
        organization: "Organization",
        ordertype: "Ordertype",
        orderstatus: "Orderstatus",
        orders: "Orders",
        order_item: "Order",
        order_date: "Order date",
        lastname: "Lastname",
        language: "Language",
        invoice_details: "Invoice Details",
        firstname: "Firstname",
        email: "Email",
        details: "Orderdetails",
        delete: "Delete order",
        created_by_user: "Created by user",
        create: "Create order",
        country: "Country",
        contact_name: "Contact",
        contact: "Contact",
        companyname: "Companyname",
        city: "City",
        buttons: {
          sales_invoice: "Invoice",
          return_slip: "Return slip",
          productlist_no_price: "Productlist (no prices)",
          productlist: "Productlist",
          product_labels: "Productlabels",
          picklist: "Picklist",
          packing_slip: "Packing slip",
          package_labels: "Packagelabels",
          orderpicking: "Orderpicking",
          invoice: "Invoice",
          credit_invoice: "Credit invoice",
        },
        address2: "Address2",
        address: "Address",
      },
      customers: "Customers",
      add_new_organization: "Add new organization",
      add_new_contact: "Add new contact",
    },
    newsletter: {
      leffit: {
        newsletter: {
          suppliers: "Suppliers",
          customers: "Customers",
          add_new_organization: "Add new organization",
          add_new_contact: "Add new contact",
        },
      },
    },
    emails: {
      title: "Title",
      language: "Language",
      name: "Name",
      subject: "Subject",
      created_at: "Created_at",
      updated_at: "Updated_at",
      deleted_at: "Deleted_at",
      template_name: "Template name",
      identifier: "Identifier",
      description: "Description",
      submit: "Submit",
    },
    crm: {
      suppliers: "Suppliers",
      settings: "Settings",
      permission: {
        update_organizationtype: "Update organizationtypes",
        update_organization: "Update organizations",
        update_contact: "Update contacts",
        update_addresstype: "Update addresstypes",
        update_address: "Update addresses",
        read_organizationtype: "Read organizationtypes",
        read_organization: "Read organizations",
        read_contact: "Read contacts",
        read_addresstype: "Read addresstypes",
        read_address: "Read addresses",
        list_organizationtype: "List organizationtypes",
        list_organization: "List organizations",
        list_contact: "List contacts",
        list_addresstype: "List addresstypes",
        list_address: "List addresses",
        delete_organizationtype: "Delete organizationtypes",
        delete_organization: "Delete organizations",
        delete_contact: "Delete contacts",
        delete_addresstype: "Delete addresstypes",
        delete_address: "Delete addresses",
        create_organizationtype: "Create organizationtypes",
        create_organization: "Create organizations",
        create_contact: "Create contacts",
        create_addresstype: "Create addresstypes",
        create_address: "Create addresses",
      },
      organizationtypes: "Organizationtypes",
      organizationtype: {
        wholesale: ["Wholesale", "Wholesale"],
        supplier: ["Supplier", "Supplier"],
        retail: ["Retail", "Retail"],
        customer_supplier: ["customer_supplier", "Customer and Supplier"],
        customer: ["Customer", "Customer"],
        consumer: ["Consumer", "Consumer"],
      },
      organizations: "Organizations",
      organization: {
        add_new: "Create New Organization",
        zipcode: "Zipcode",
        vat_number: "Vat number",
        updated_at: "Updated at",
        swift_code: "Swift code",
        status_id: "Status",
        remarks: "Remarks",
        picture: "Picture",
        phone: "Phone",
        parent_id: "Parent",
        organizationtype_id: "Organizationtype",
        name: "Name",
        mobile: "Mobile",
        language: "Language",
        inactive: "Inactive",
        iban_number: "IBAN number",
        iban_name: "IBAN name",
        fax: "Fax",
        email: "Email",
        deleted_at: "Deleted at",
        created_at: "Created at",
        country: "Country",
        coc: "Chambre of Commerce",
        city: "City",
        address2: "Address 2",
        address: "Address",
        active: "Active",
        creditor_number: "Creditor number",
        creditor_test: "CREDITOR TEST",
        debtor_number: "Debtor number",
        profile_image: "Profile image",
        basic_details: "Basic details ",
        switf_code: "Swift code",
        other_details: "Other details",
        contact: "Contact",
        admins: "Admins",
        orders: "Orders",
        products: "Products",
        edit: "Edit",
        generate_creditor_number: "Generate creditor's Number",
        generate_debitor_number: "Generate Debitor's Number",
        generating: "Generating",
        currency: "Currency",
      },
      customers: "Customers",
      crm: "CRM",
      contacts: "Contacts",
      contact: {
        zipcode: "Zipcode",
        vat_number: "Vat number",
        updated_at: "Updated at",
        title: "Title",
        swift_code: "Swift code",
        status_id: "Status",
        remarks: "Remarks",
        prelastname: "Prelastname",
        picture: "Picture",
        phone: "Phone",
        password: "Password",
        parent_id: "Parent",
        parent: "Parent contact",
        other_details: "Other details",
        optin: "Optin",
        mobile: "Mobile",
        lastname: "Lastname",
        language: "Language",
        inactive: "Inactive",
        iban_number: "IBAN number",
        iban_name: "IBAN name",
        generate_password: "Generate new password",
        gender_woman: "Woman",
        gender_unknown: "Unknown",
        gender_male: "Male",
        gender: "Gender",
        function: "Function",
        firstname: "Firstname",
        financial_details: "Financial Details",
        fax: "Fax",
        email: "Email",
        deleted_at: "Deleted at",
        created_at: "Created at",
        country: "Country",
        coc: "Chambre of Commerce",
        city: "City",
        birthday: "Birthday",
        basic_details: "Basic details",
        address2: "Address 2",
        address: "Address",
        active: "Active",
        name: "Name",
        message: "Message",
        create: "Create",
        filter_by_status: "Filter by Status",
        user: "User",
        orgnization: "Orgnization",
        status: "Status",
        new_order: "Create new order",
        admins: "Admins",
        display_name: "Display Name",
        orgnizations: "Orgnizations",
        product: "Products",
        remark: "Remark",
      },
      addresstypes: "Addresstypes",
      addresstype: {
        visit: ["Visiting Address", "Visittingaddress"],
        shipping: ["Shipping Address", "Shippingaddress"],
        post: ["Mail Address", "Postaddress"],
        invoice: ["Billing Address", "Invoiceaddress"],
      },
      addresses: "Addresses",
      messages: "Message",
      messagess: {
        title: "Title",
        orgnization: "Orgnization",
        user: "User",
        status: "Status",
      },
      address: {
        zipcode: "Zipcode",
        updated_at: "Updated at",
        phone: "Phone",
        name: "Name",
        mobile: "Mobile",
        fax: "Fax",
        email: "Email",
        deleted_at: "Deleted at",
        created_at: "Created at",
        country: "Country",
        code: "Code",
        city: "City",
        address2: "Address 2",
        address: "Address",
        display_name: "Display Name",
      },
      add_new_organization: "Add new organization",
      add_new_contact: "Add new contact",
    },
    cms: {
      section: {
        slider_with_title_subtitle: "Slider with title and subtitle",
        slider_with_title_content_button:
          "Slider with title, content and button",
        slider_with_title_content: "Slider with title and content",
        slider_with_title_button: "Slider with title and button",
        slider_with_title: "Slider with title",
        slider: "Slider",
        newsletter_subscribe_title_subtitle_image:
          "Newsletter subscribe with title, subtitle and image",
        model_grid: "Grid with objects",
        header: "Header",
        head: "Head section",
        footer: "Footer",
      },
      permission: {
        update_sectiontype: "Update sectiontypes",
        update_section: "Update sections",
        update_pagetype: "Update pagetypes",
        update_page: "Update pages",
        update_layout: "Update layouts",
        update_fieldtype: "Update fieldtypes",
        update_field: "Update fields",
        update_blocktype: "Update blocktypes",
        read_sectiontype: "Read sectiontypes",
        read_section: "Read sections",
        read_pagetype: "Read pagetypes",
        read_page: "Read pages",
        read_layout: "Read layouts",
        read_fieldtype: "Read fieldtypes",
        read_field: "Read fields",
        read_blocktype: "Read blocktypes",
        list_sectiontype: "List sectiontypes",
        list_section: "List sections",
        list_pagetype: "List pagetypes",
        list_page: "List pages",
        list_layout: "List layouts",
        list_fieldtype: "List fieldtypes",
        list_field: "List fields",
        list_blocktype: "List blocktypes",
        delete_sectiontype: "Delete sectiontypes",
        delete_section: "Delete sections",
        delete_pagetype: "Delete pagetypes",
        delete_page: "Delete pages",
        delete_layout: "Delete layouts",
        delete_fieldtype: "Delete fieldtypes",
        delete_field: "Delete fields",
        delete_blocktype: "Delete blocktypes",
        create_sectiontype: "Create sectiontypes",
        create_section: "Create sections",
        create_pagetype: "Create pagetypes",
        create_page: "Create pages",
        create_layout: "Create layouts",
        create_fieldtype: "Create fieldtypes",
        create_field: "Create fields",
        create_blocktype: "Create blocktypes",
      },
      pagetypetype: { homepage: "Homepage" },
      field: {
        url: "url",
        title: "title",
        target: "target",
        subtitle: "subtitle",
        slug: "slug",
        seo_title: "Pagetitle",
        seo_keywords: "seo_keywords",
        seo_description: "SEO description",
        page_id: "Page",
        model_id: "Model",
        image: "image",
        content: "content",
        button: "button",
        align: "alignment",
        address: "address",
      },
      blocktypes: "Blocktypes",
      blocktype: {
        title_image: "Title and image",
        title_and_button: "title and button",
        title: "Title",
        table: "Table",
        subtitle: "Subtitle",
        slider_with_title_subtitle: "Slider",
        slider_with_title_content_button: "Slider",
        slider_with_title_content: "Slider",
        slider_with_title_button: "Slider",
        slider_with_title: "Slider",
        slider: "Slider",
        seo: "Seo",
        newsletter_action: "Newsletter action",
        name: "Name",
        menu: "Menu",
        image_content: "title columns image and content",
        image_button: "Image and button",
        image: "Image",
        google_map: "Google Map",
        content_with_title_and_image: "Content with title and image",
        content_with_title_and_button: "Content with title and button",
        content_with_title: "Content with title",
        content: "Content",
        contact_form: "Contact form",
        button: "Button",
      },
    },
    websitehome: {
      general_setting: "General setting",
      landing_page: "landing page",
      in_slug: "in slug",
      name: "Name",
      slug: "Slug",
      title: "Title",
      description: "Description",
      keyword: "Keyword",
      update: "Update",
    },
    casa: {
      pricat: { item: "Pricat", import: "Sales reports", export: "Pricat" },
      casa_menu: "Casa Jewelry",
    },
  },
  lefficient: {
    system: {
      body: "Body",
      add_photo: "Add Photo",
      filename: "Filename",
      title: "Title",
      img_alt: "Description",
      warehouse: "Warehouse",
      stockloc: "Stocklocation",
      stocktype: "Stocktype",
      amount: "Amount",
      action: "Action",
      ms: "Mrs",
      mr: "Mr",
      yes: "Yes",
      update: "Update",
      testmode: "TESTMODE",
      settings: "Settings",
      sent: "Sent",
      search: "Search",
      save: "Save",
      results_no: "No Results found...",
      redirecting: "Redirecting",
      read: "Open",
      password_requesting: "Requesting new password",
      password_request: "Request new password",
      orders: "Orders",
      no: "No",
      loading: "Loading",
      language: "Language",
      edit_field: "Edit {0}",
      edit: "Edit",
      delete: "Delete",
      dashboard: "Dashboard",
      create_single: "Create {0}",
      create: "Create",
      copy_to: "Copy to",
      continue: "Continue",
      confirm: "Confirm",
      close: "Close",
      cancel: "Cancel",
      contacts: "Contact",
      organizations: "organizations",
      addresses: "addresses",
      messages: "messages",
      products: "products",
      producttypes: "producttypes ",
      productstatuses: "productstatuses",
      sales: "sales",
      sales_quotation: "sales_quotation",
      sales_order: "sales_order",
      sales_packageslip: "sales_packageslip",
      sales_invoice: "sales_invoice",
      sales_return: "sales_return",
      sales_invoice_credit: "sales_invoice_credit",
    },
    login: {
      signup_long: "Create a new account",
      signup: "Signup",
      passwords_min_int:
        "The password must contain at least 1 numeric value (0-9)",
      passwords_min_capital:
        "The password must contain at least 1 capital (A-Z)",
      passwords_min_8: "The password must contain 8 characters",
      passwords_do_not_match: "Passwords do not match",
      password_reset_verify: "Request is being verified",
      password_reset_succes: "Your password was reset, you can now login",
      password_request: "Requesting a new password",
      password_forgot: "Forgot your password?",
      password_create: "Please create a password and confirm your password",
      password_change: "Change password",
      logout: "Logout",
      login_success_message: "Login successful",
      login: "Login",
    },
    leffit: {
      users: "Users",
      user: {
        zipcode: "Zipcode",
        website: "Website",
        vat_number: "Vat number",
        userstatus: "Status",
        updated_at: "Updated at",
        title: "Title",
        swift_code: "Swift code",
        status_id: "Status",
        settings: "Settings",
        remarks: "Remarks",
        profile_image: "Picture",
        prelastname: "Prelastname",
        phone: "Phone",
        password: "Password",
        parent_id: "Parent",
        optin: "Optin",
        mobile: "Mobile",
        member_since: "Member since",
        member_end: "Member end",
        lastname: "Lastname",
        language: "Language",
        iban_number: "IBAN number",
        iban_name: "IBAN name",
        gender: "Gender",
        function: "Function",
        firstname: "Firstname",
        fax: "Fax",
        email_verified_at: "Email verified at",
        email: "Email",
        display_name: "Displayname",
        deleted_at: "Deleted at",
        date_of_birth: "Date of birth",
        created_at: "Created at",
        country: "Country",
        coc: "Chambre of Commerce",
        city: "City",
        birthday: "Birthday",
        address2: "Address 2",
        address: "Address",
        about_me: "About me",
        //Edit User
        "edit-user": "Edit User",
        update: "Update",
      },
      settings: {
        users: "Users",
        roles: "Roles",
        permissions: "Permissions",
        generator: "Generator",
      },
      permission: {
        update_user: "Update users",
        update_translation: "Update translations",
        update_setting: "Update settings",
        update_role: "Update roles",
        update_import: "Update imports",
        update_generator: "Update generators",
        update_export: "Update exports",
        update_currency: "Update currencies",
        update_coresetting: "Update coresettings",
        read_user: "Read users",
        read_translation: "Read translations",
        read_setting: "Read settings",
        read_role: "Read roles",
        read_import: "Read imports",
        read_generator: "Read generators",
        read_export: "Read exports",
        read_currency: "Read currencies",
        read_coresetting: "Read coresettings",
        list_user: "List users",
        list_translation: "List translations",
        list_setting: "List settings",
        list_role: "List roles",
        list_import: "List imports",
        list_generator: "List generators",
        list_export: "List exports",
        list_currency: "List currencies",
        list_coresetting: "List coresettings",
        delete_user: "Delete users",
        delete_translation: "Delete translations",
        delete_setting: "Delete settings",
        delete_role: "Delete roles",
        delete_import: "Delete imports",
        delete_generator: "Delete generators",
        delete_export: "Delete exports",
        delete_currency: "Delete currencies",
        delete_coresetting: "Delete coresettings",
        create_user: "Create users",
        create_translation: "Create translations",
        create_setting: "Create settings",
        create_role: "Create roles",
        create_import: "Create imports",
        create_generator: "Create generators",
        create_export: "Create exports",
        create_currency: "Create currencies",
        create_coresetting: "Create coresettings",
      },
      language: "Language",
    },
    languages: {
      dutch: "Dutch",
      english: "English",
      nl: { nl: "Dutch", en: "English" },
      en: {
        zu: "Zulu",
        zh: "Chinese",
        za: "Zhuang; Chuang",
        yo: "Yoruba",
        yi: "Yiddish",
        xh: "Xhosa",
        wo: "Wolof",
        wa: "Walloon",
        vo: "Volap\u00fck",
        vi: "Vietnamese",
        ve: "Venda",
        uz: "Uzbek",
        ur: "Urdu",
        uk: "Ukrainian",
        ug: "Uighur; Uyghur",
        ty: "Tahitian",
        tw: "Twi",
        tt: "Tatar",
        ts: "Tsonga",
        tr: "Turkish",
        to: "Tonga (Tonga Islands)",
        tn: "Tswana",
        tl: "Tagalog",
        tk: "Turkmen",
        ti: "Tigrinya",
        th: "Thai",
        tg: "Tajik",
        te: "Telugu",
        ta: "Tamil",
        sw: "Swahili",
        sv: "Swedish",
        su: "Sundanese",
        st: "Sotho, Southern",
        ss: "Swati",
        sr: "Serbian",
        sq: "Albanian",
        so: "Somali",
        sn: "Shona",
        sm: "Samoan",
        sl: "Slovenian",
        sk: "Slovak",
        si: "Sinhala; Sinhalese",
        sg: "Sango",
        se: "Northern Sami",
        sd: "Sindhi",
        sc: "Sardinian",
        sa: "Sanskrit",
        rw: "Kinyarwanda",
        ru: "Russian",
        ro: "Romanian; Moldavian; Moldovan",
        rn: "Rundi",
        rm: "Romansh",
        qu: "Quechua",
        pt: "Portuguese",
        ps: "Pushto; Pashto",
        pl: "Polish",
        pi: "Pali",
        pa: "Panjabi; Punjabi",
        os: "Ossetian; Ossetic",
        or: "Oriya",
        om: "Oromo",
        oj: "Ojibwa",
        oc: "Occitan (post 1500)",
        ny: "Chichewa; Chewa; Nyanja",
        nv: "Navajo; Navaho",
        nr: "Ndebele, South; South Ndebele",
        no: "Norwegian",
        nn: "Norwegian Nynorsk; Nynorsk, Norwegian",
        nl: "Dutch",
        ng: "Ndonga",
        ne: "Nepali",
        nd: "Ndebele, North; North Ndebele",
        nb: "Bokm\u00e5l, Norwegian; Norwegian Bokm\u00e5l",
        na: "Nauru",
        my: "Burmese",
        mt: "Maltese",
        ms: "Malay",
        mr: "Marathi",
        mn: "Mongolian",
        ml: "Malayalam",
        mk: "Macedonian",
        mi: "Maori",
        mh: "Marshallese",
        mg: "Malagasy",
        lv: "Latvian",
        lu: "Luba-Katanga",
        lt: "Lithuanian",
        lo: "Lao",
        ln: "Lingala",
        li: "Limburgan; Limburger; Limburgish",
        lg: "Ganda",
        lb: "Luxembourgish; Letzeburgesch",
        la: "Latin",
        ky: "Kirghiz; Kyrgyz",
        kw: "Cornish",
        kv: "Komi",
        ku: "Kurdish",
        ks: "Kashmiri",
        kr: "Kanuri",
        ko: "Korean",
        kn: "Kannada",
        km: "Central Khmer",
        kl: "Kalaallisut; Greenlandic",
        kk: "Kazakh",
        kj: "Kuanyama; Kwanyama",
        ki: "Kikuyu; Gikuyu",
        kg: "Kongo",
        ka: "Georgian",
        jv: "Javanese",
        ja: "Japanese",
        iu: "Inuktitut",
        it: "Italian",
        is: "Icelandic",
        io: "Ido",
        ik: "Inupiaq",
        ii: "Sichuan Yi; Nuosu",
        ig: "Igbo",
        ie: "Interlingue; Occidental",
        id: "Indonesian",
        ia: "Interlingua (International Auxiliary Language Association)",
        hz: "Herero",
        hy: "Armenian",
        hu: "Hungarian",
        ht: "Haitian; Haitian Creole",
        hr: "Croatian",
        ho: "Hiri Motu",
        hi: "Hindi",
        he: "Hebrew",
        ha: "Hausa",
        gv: "Manx",
        gu: "Gujarati",
        gn: "Guarani",
        gl: "Galician",
        gd: "Gaelic; Scottish Gaelic",
        ga: "Irish",
        fy: "Western Frisian",
        fr: "French",
        fo: "Faroese",
        fj: "Fijian",
        fi: "Finnish",
        ff: "Fulah",
        fa: "Persian",
        eu: "Basque",
        et: "Estonian",
        es: "Spanish; Castilian",
        eo: "Esperanto",
        en: "English",
        el: "Greek, Modern (1453-)",
        ee: "Ewe",
        dz: "Dzongkha",
        dv: "Divehi; Dhivehi; Maldivian",
        de: "German",
        da: "Danish",
        cy: "Welsh",
        cv: "Chuvash",
        cu:
          "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
        cs: "Czech",
        cr: "Cree",
        co: "Corsican",
        ch: "Chamorro",
        ce: "Chechen",
        ca: "Catalan; Valencian",
        bs: "Bosnian",
        br: "Breton",
        bo: "Tibetan",
        bn: "Bengali",
        bm: "Bambara",
        bi: "Bislama",
        bh: "Bihari languages",
        bg: "Bulgarian",
        be: "Belarusian",
        ba: "Bashkir",
        az: "Azerbaijani",
        ay: "Aymara",
        av: "Avaric",
        as: "Assamese",
        ar: "Arabic",
        an: "Aragonese",
        am: "Amharic",
        ak: "Akan",
        af: "Afrikaans",
        ae: "Avestan",
        ab: "Abkhazian",
        aa: "Afar",
      },
    },

    error: { field_empty: "Please fill {field} field" },
    countries: {
      ZW: "Zimbabwe",
      ZR: "Zaire",
      ZM: "Zambia",
      ZA: "South Africa",
      YU: "Yugoslavia",
      YT: "Mayotte",
      YE: "Yemen",
      XK: "Kosovo",
      WS: "Samoa",
      WF: "Wallis and Futuna Islands",
      VU: "Vanuatu",
      VN: "Vietnam",
      VI: "Virgin Islands (USA)",
      VG: "Virgin Islands (British)",
      VE: "Venezuela",
      VC: "Saint Vincent & Grenadines",
      VA: "Holy See (Vatican City State)",
      UZ: "Uzbekistan",
      UY: "Uruguay",
      US: "United States",
      UG: "Uganda",
      UA: "Ukraine",
      TZ: "Tanzania",
      TW: "Taiwan",
      TV: "Tuvalu",
      TT: "Trinidad and Tobago",
      TR: "Turkey",
      TP: "East Timor",
      TO: "Tonga",
      TN: "Tunisia",
      TM: "Turkmenistan",
      TK: "Tokelau",
      TJ: "Tadjikistan",
      TH: "Thailand",
      TG: "Togo",
      TF: "French Southern Territories",
      TD: "Chad",
      TC: "Turks and Caicos Islands",
      SZ: "Swaziland",
      SY: "Syria",
      SV: "El Salvador",
      ST: "Saint Tome (Sao Tome) and Principe",
      SR: "Suriname",
      SO: "Somalia",
      SN: "Senegal",
      SM: "San Marino",
      SL: "Sierra Leone",
      SK: "Slovakia",
      SJ: "Svalbard and Jan Mayen Islands",
      SI: "Slovenia",
      SH: "Saint Helena",
      SG: "Singapore",
      SE: "Zweden",
      SD: "Sudan",
      SC: "Seychelles",
      SB: "Solomon Islands",
      SA: "Saudi Arabia",
      RW: "Rwanda",
      RU: "Russia",
      RS: "Servi\u00eb",
      RO: "Romania",
      RE: "Reunion (French)",
      QA: "Qatar",
      PY: "Paraguay",
      PW: "Palau",
      PT: "Portugal",
      PR: "Puerto Rico",
      PN: "Pitcairn Island",
      PM: "Saint Pierre and Miquelon",
      PL: "Polen",
      PK: "Pakistan",
      PH: "Philippines",
      PG: "Papua New Guinea",
      PF: "Polynesia (French)",
      PE: "Peru",
      PA: "Panama",
      OM: "Oman",
      NZ: "Nieuw Zeeland",
      NU: "Niue",
      NR: "Nauru",
      NP: "Nepal",
      NO: "Norway",
      NL: "Nederland",
      NI: "Nicaragua",
      NG: "Nigeria",
      NF: "Norfolk Island",
      NE: "Niger",
      NC: "New Caledonia (French)",
      NA: "Namibia",
      MZ: "Mozambique",
      MY: "Malaysia",
      MX: "Mexico",
      MW: "Malawi",
      MV: "Maldives",
      MU: "Mauritius",
      MT: "Malta",
      MS: "Montserrat",
      MR: "Mauritania",
      MQ: "Martinique (French)",
      MP: "Northern Mariana Islands",
      MO: "Macau",
      MN: "Mongolia",
      MM: "Myanmar",
      ML: "Mali",
      MK: "Macedonia",
      MH: "Marshall Islands",
      MG: "Madagascar",
      ME: "Montenegro",
      MD: "Moldova",
      MC: "Monaco",
      MA: "Morocco",
      LY: "Libya",
      LV: "Latvia",
      LU: "Luxemburg",
      LT: "Lithuania",
      LS: "Lesotho",
      LR: "Liberia",
      LK: "Sri Lanka",
      LI: "Liechtenstein",
      LC: "Saint Lucia",
      LB: "Lebanon",
      LA: "Laos",
      KZ: "Kazakhstan",
      KY: "Cayman Islands",
      KW: "Kuwait",
      KR: "South Korea",
      KP: "North Korea",
      KN: "Saint Kitts & Nevis Anguilla",
      KM: "Comoros",
      KI: "Kiribati",
      KH: "Cambodia, Kingdom of",
      KG: "Kyrgyz Republic (Kyrgyzstan)",
      KE: "Kenya",
      JP: "Japan",
      JO: "Jordan",
      JM: "Jamaica",
      IT: "Itali\u00eb",
      IS: "Iceland",
      IR: "Iran",
      IQ: "Iraq",
      IN: "India",
      IM: "Isle of Man",
      IL: "Israel",
      IE: "Ierland",
      ID: "Indonesia",
      HU: "Hongarij\u00eb",
      HT: "Haiti",
      HR: "Croatia",
      HN: "Honduras",
      HM: "Heard and McDonald Islands",
      HK: "Hong-kong",
      GY: "Guyana",
      GW: "Guinea Bissau",
      GU: "Guam (USA)",
      GT: "Guatemala",
      GS: "S. Georgia & S. Sandwich Isls.",
      GR: "Griekenland",
      GQ: "Equatorial Guinea",
      GP: "Guadeloupe (French)",
      GN: "Guinea",
      GM: "Gambia",
      GL: "Greenland",
      GI: "Gibraltar",
      GH: "Ghana",
      GG: "Guernsey",
      GF: "French Guyana",
      GE: "Georgia",
      GD: "Grenada",
      GB: "Verenigd Koninkrijk",
      GA: "Gabon",
      FR: "Frankrijk",
      FO: "Faroe Islands",
      FM: "Micronesia",
      FK: "Falkland Islands",
      FJ: "Fiji",
      FI: "Finland",
      ET: "Ethiopia",
      ES: "Spanje",
      ER: "Eritrea",
      EH: "Western Sahara",
      EG: "Egypt",
      EE: "Estonia",
      EC: "Ecuador",
      DZ: "Algeria",
      DO: "Dominican Republic",
      DM: "Dominica",
      DK: "Denmark",
      DJ: "Djibouti",
      DE: "Duitsland",
      CZ: "Tjechi\u00eb",
      CY: "Cyprus",
      CX: "Christmas Island",
      CW: "Cura\u00e7ao",
      CV: "Cape Verde",
      CU: "Cuba",
      CS: "Former Czechoslovakia",
      CR: "Costa Rica",
      CO: "Colombia",
      CN: "China",
      CM: "Cameroon",
      CL: "Chile",
      CK: "Cook Islands",
      CI: "Ivory Coast (Cote D'Ivoire)",
      CH: "Zwitserland",
      CG: "Congo",
      CF: "Central African Republic",
      CD: "Congo, The Democratic Republic of the",
      CC: "Cocos (Keeling) Islands",
      CA: "Canada",
      BZ: "Belize",
      BY: "Belarus",
      BW: "Botswana",
      BV: "Bouvet Island",
      BT: "Bhutan",
      BS: "Bahamas",
      BR: "Brazil",
      BQ: "Bonaire",
      BO: "Bolivia",
      BN: "Brunei Darussalam",
      BM: "Bermuda",
      BJ: "Benin",
      BI: "Burundi",
      BH: "Bahrain",
      BG: "Bulgaria",
      BF: "Burkina Faso",
      BE: "Belgi\u00eb",
      BD: "Bangladesh",
      BB: "Barbados",
      BA: "Bosnia-Herzegovina",
      AZ: "Azerbaidjan",
      AW: "Aruba",
      AU: "Australi\u00eb",
      AT: "Oostenrijk",
      AS: "American Samoa",
      AR: "Argentina",
      AQ: "Antarctica",
      AO: "Angola",
      AN: "Netherlands Antilles",
      AM: "Armenia",
      ALL: "Alle landen",
      AL: "Albania",
      AI: "Anguilla",
      AG: "Antigua and Barbuda",
      AF: "Afghanistan, Islamic State of",
      AE: "United Arab Emirates",
      AD: "Andorra",
    },
  },
  version: "2020_06_02_13_43_31",
  lang: "en",
  contacts: "Contacts",
};
