export default `
<section class="four bg-white  ">
<div class="row pt-4 mb-3">
    <h2 id="section25header1" class="h2 four_o container">++{{section25header1}}</h2>
    <div class="col-sm-6">
        <h6 id="section25Text1" class="p">++{{section25Text1}} </h6>
    </div>
    <div class="col-sm-6">
        <img src="++{{section25Image1}}">
    </div>

    <div class="col-sm-6 mt-2">
        <h2 id="section25header2"  class="h2">++{{section25header2}}</h2>
        <h6 id="section25Text2"  class="p">++{{section25Text2}}</h6>
        <a  id="section25Text3" href="++{{section25Link1}}" class="btn btn-lg pl-4 pr-4 pt-2 pb-2 mb-4 mt-2 button">++{{section25Text3}}</a>
    </div>
    <div class="col-sm-6 mt-2">
        <img src="++{{section25Image2}}">
    </div>
</div>
</section>

`