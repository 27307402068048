import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import leffit from './../helpers/store/index';

import auth from './modules/auth'

Vue.use(Vuex);
 
var main={
    state:{
        // endpoint:"http://api.test.leffit.nl/api/v1",
        endpoint:"http://127.0.0.1:8000/api/v1",
        user: {},
        token:'',  
        alertMessage:{msg:'',show:false},
        requestStatus:{show:false,perc:0},
        lockscreen:{show:false},
        response:{
            errors:[],
            success:{
                status:false,
                message:''
            },
        },
        notifications:{
            info:[],
            success:[],
            warning:[],
            danger:[]
        },
        
        
    },
    getters,
    mutations,
    actions
};

const store=new Vuex.Store({
    modules:{
        leffit:leffit,
        main:main,
        auth
    },
    
});

export default store;