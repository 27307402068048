<template>
    <div class="row m-0 p-2">
        
        <div class="col-10"> 
            <!-- 
                IS IT POSSIBLE TO MAKE THIS DYNAMIC? 
                THE PRODUCT LIST FOR EACH TYPE IS THE SAME
            -->
 
            <Select2 v-model="value" :settings="{ settingOption: value, settingOption: value }" :options="options" />
        </div>
        <div class="col-2">
            <!-- MAYBE ADD THE TYPE AS PROPERTY TO THIS METHOD? -->
            <button @click="addProduct()" class="btn btn-success btn-block"><i class="fa fa-plus"></i></button>
        </div>
        

        <div class="col-12">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ $t('leffit.products.product_types.course') }}</th> 
                    </tr>
                </thead> 
                <tbody>
                    <!-- 
                        MAYBE MAKE THIS ALSO DEPENDENT ON THE TYPE
                     -->
                    <tr v-for="(ft,index) in item" :key="index"> 
                        <td> 
                            <div class="btn-group" role="group" aria-label="Basic example">
                            <button @click="reOrder('up',index)" v-if="index > 0" class="btn btn-sm pl-2 pr-2 btn-warning"><i class="fa fa-chevron-up"></i></button>
                            <button @click="reOrder('down',index)" v-if="index < item.length -1 " class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-chevron-down"></i></button>
                            <button @click="remove(ft.id)" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-remove"></i></button>
                        </div>
                        </td>
                        <td>{{ft.title}}</td> 
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Select2 from 'v-select2-component';
import { mapState } from 'vuex';
export default {
    name:'product',
    props:['item','product'],
    components:{Select2},
	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }), 
	},
    data(){
        return {
            products:[], 
            options:[],  
            value:0,
            type:'course'
        }
    }, 
    created() {
        this.$store.dispatch('get','/leffit/courses/programs')
        .then(data=>{ 
            this.products = data.data.data;

            this.options = data.data.data.map((d)=>{
                return {
                    id:d.id,
                    text:d.title
                }
            }); 
            window.$(".select2-container").css('width','100%');
        });
       
    },
    methods:{  
        reOrder(direction,index){
            var data=this.item;
            if(direction == 'up'){
                var temp=data[index];
                data[index]=data[index-1];
                data[index-1]=temp;
            }else if(direction == 'down'){
                temp=data[index];
                data[index]=data[index+1];
                data[index+1]=temp;
            }
            this.item=JSON.parse(JSON.stringify(data)); 

            var payload=this.item.map((d,index)=>{
                return {
                    index,
                    id:d.id
                };
            }); 

            this.$store.dispatch('post',{
                details:payload,
                endpoint:'/leffit/products/reorderproduct/'+this.product+'/'+this.type
            })
            .then((data)=>{
                console.log(data);
            })
            .catch((error)=>{
                console.log(error);
            });

        },
        addProduct(){  
            if(this.item == undefined){
                this.item=[];
            }
            this.item.unshift(this.products.find((ft)=>ft.id == this.value));
            this.$store.dispatch('get','/leffit/products/add-productproduct/'+this.product+'/'+this.value+"/"+this.type)
            .then(data=>{
                // console.log('result',data);
                // this.filters = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:data.data.name+' was updated successfully',type:'success'});
            });
        },
        remove(id){
            this.item=this.item.filter((ft)=>ft.id != id);

            this.$store.dispatch('get','/leffit/products/remove-productproduct/'+this.product+'/'+id)
            .then(data=>{
                console.log('result',data);
                // this.filters = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
        } 
    }
}
</script>
<style scoped>
    .select2-container{
        width: 100%;
    }
</style>