<template>
    <div class="row p-3">
        <div class="form-group col-12 col-md-12">
            <label for="">Name</label>
            <input type="text" class="form-control" v-model="subscription.name" >
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Amount</label>
            <input type="number" class="form-control" v-model="subscription.amount" >
        </div> 
        <div class="form-group col-12 col-md-6">
            <label for="">Occurence</label>
            <input type="number" class="form-control" v-model="subscription.occurence" >
        </div>   
        <div class="form-group col-12 col-md-6">
            <label for="">Duration</label>
            <input type="number" class="form-control" v-model="subscription.duration">
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Period</label>
            <select class="form-control" v-model="subscription.period">
                <option value="days">Days</option>
                <option value="month">Months</option>
                <option value="year">Years</option>
            </select>
        </div> 
        <div class="form-group col-12">
            <label for="">Description</label>
            <Editor v-model="subscription.description"></Editor>
        </div>
        <div class="form-group col-12 ">
            <button @click="addNewPlan()" class="btn btn-sm btn-success" style="margin-top:-5px">
                Create
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return {
            subscription:{
                amount:0,name:'',description:'',
                occurence:0,
                duration:0,
                period:''
            }
        }
    },
    methods:{
        addNewPlan(){ 
            if(this.subscription.occurence == '' || this.subscription.amount == '' || this.subscription.name == ''  || this.subscription.duration == '' || this.subscription.period == '' ){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'All fields are required',type:'danger'});
                return false;
            } 

            var temp={};
            if(this.item.producttype_data != null){
                temp=JSON.parse(this.item.producttype_data); 
            }
            temp.subscription=this.subscription;
            
            this.$parent.item.producttype_data=JSON.stringify(temp);
            this.$parent.updateItem(); 
        }
    },
    created(){
        if(this.item.producttype_data != null){
            var temp=JSON.parse(this.item.producttype_data);
            this.subscription=temp.subscription ? temp.subscription:this.subscription;
        }
    }
}
</script>
<style scoped>

</style>