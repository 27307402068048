export default [
        {
          "id"    : 0,
          "label" : "id",
          "type"  : "increments",
        },
        {
          "id"    : 1,
          "label" : "String",
          "type"  : "string",
        },
        {
          "id"    : 2,
          "label" : "Date",
          "type"  : "date",
        },
        {
          "id"    : 3,
          "label" : "Date Time",
          "type"  : "dateTime",
        },
        {
          "id"    : 4,
          "label" : "Text",
          "type"  : "text",
        },
        {
          "id"    : 5,
          "label" : "Long text",
          "type"  : "longText",
        },
        {
          "id"    : 6,
          "label" : "Integer",
          "type"  : "integer",
        },
        {
          "id"    : 7,
          "label" : "Double",
          "type"  : "double",
          "round" : 3,
        },

        {
          "id"    : 8,
          "label" : "Decimal",
          "type"  : "decimal",
          "round" : 3,
        },

        {
          "id"    : 9,
          "label" : "Double",
          "type"  : "double",
          "round" : 3,
        },

        {
          "id"    : 10,
          "label" : "Time",
          "type"  : "time",
        },
      ]