<template>
	 	<table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
                    <th>{{ $t('lefficient.system.organizations') }}</th>
                    <th>{{ $t('lefficient.system.amount') }}</th>
                    <!-- <th>{{ $t('lefficient.system.action') }}</th> -->
      			</tr>
      		</thead> 
      		<tbody>
      			<tr :key="index" v-for="(item, index) in pagedata">
      				<td> 
      				</td>
                    <td>{{item.order.companyname}}</td>
                    <td>{{item.amount}}</td>
                    <!-- <td></td> -->
      			</tr>
      		</tbody>
      	</table>
</template>

<script>
export default { 
  	data () {
    	return {
        pagedata : [],  
    	}
  	},
    methods: { 
    },
  	mounted(){
	    this.$store.dispatch('get','/leffit/orders/orderrows/product/'+this.$route.params.id)
        .then(data=>{
        	console.log(data);
          this.pagedata = data.data;
        });
	},
}
</script>

<style lang="css" scoped>
</style>