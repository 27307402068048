<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.shipping.cost_create') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.cost_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                    <div class="form-group col-12">
                        <label for="methods">{{ $t('leffit.shipping.shippingmethods') }}</label>
                        <select style="position:relative;width:100%" class="form-control methods" id="methods" v-model="item.shipping_method_id">
                            <option :key="index" v-for="(ct,index) in methods" :value="ct.id" >{{ct.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-12">
                        <label for="statusses">{{ $t('leffit.shipping.shippingstatusses') }}</label>
                        <select style="position:relative;width:100%" class="form-control statusses" id="statusses" v-model="item.shipping_status_id">
                            <option :key="index" v-for="(ct,index) in statusses" :value="ct.id" >{{ct.name}}</option>
                        </select>
                    </div> 
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.extra') }}</label>
                        <textarea class="form-control" rows="5" v-model="item.extra"></textarea>
                    </div> 
                </div>
 
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script> 
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',shipping_method_id:0,shipping_status_id:0,extra:''
            }, 
            methods:[],
            statusses:[]
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.item);
            this.$store.dispatch('post',{endpoint:'/leffit/shipping/shipments',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Cost created",text:'Cost was created automatically'});
                this.item={};
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){ 
        var context=this;
        this.$store.dispatch('get','/leffit/shipping/shippingmethods')
        .then(data=>{
        	console.log(data);
            this.methods = data.data.data;
            window.$('.methods').select2().change(function(){
                console.log(window.$(this).val());
                context.item.shipping_method_id=window.$(this).val();
            }); 

        });
        this.$store.dispatch('get','/leffit/shipping/shippingstatusses')
        .then(data=>{
        	console.log(data);
            this.statusses = data.data.data;
            window.$('.statusses').select2().change(function(){
                context.item.shipping_status_id=window.$(this).val();
            }); 
		});
    }
    
}
</script>
