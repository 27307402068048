<template>
	<div>
		<button class="btn btn-success" @click.prevent="showCreateForm()" data-toggle="modal" 
			    data-target="#createTableModal">Create Appointment</button>
		<v-calendar 
			:rows="1" 
			class="custom_calendar" 
			style="max-width: 100%;" 
			:first-day-of-week="2" 
			ref="calendar" 
			is-expanded  
			:attributes='attributes'>
			<template slot='day-content' slot-scope="props">
				<div class="day-cell" v-if="props.day.inMonth">
					{{props.day.day}}
					<div v-for="dayEvent in props.attributesMap" :key="dayEvent">
						<span class="event-badge badge badge-pill" :style="'background-color: ' + dayEvent.customData.color" @click.prevent="changeSchedule(dayEvent)" data-toggle="modal" 
			    data-target="#createTableModal">
							{{dayEvent.customData.name}}
						</span>
					</div>
				</div>
			</template>
		</v-calendar>

		<div 
		    class="modal fade" 
		    id="createTableModal" 
		    tabindex="-1" 
		    role="dialog" 
		    aria-labelledby="createTableModal"
		    aria-hidden="true">
		    <div class="modal-dialog" role="document">
		        <div class="modal-content">
		          <div class="modal-header">
		              <h4 class="modal-title font-weight-bold">Update Schedule</h4>
		              <button type="button" style="color:#fff" class="close" data-dismiss="modal" aria-label="Close">
		                  <span aria-hidden="true">&times;</span>
		              </button>
		          </div>
		          <div class="modal-body">
		          	<div class="form-row">
          				<div class="form-group col-12">
          	                <label for="type">{{ $t('leffit.products.product.product_type') }}</label>
          	                <select v-model="appointment.plannable_id" style="position:relative" class="form-control select1" id="type">
          	                    <option :key="index" v-for="(plannable,index) in plannables" :value="plannable.id">{{ plannable.model }}</option>
          	                </select>
          	            </div> 
          			</div>

          			<div class="form-row">
          				<div class="form-group col-12">
          					<label for="start">Start</label>
          					<datetime type="datetime" v-model="appointment.start" input-class="form-control"></datetime>
          			</div>

          			<div class="form-row">
          				<div class="form-group col-12">
          					<label for="end">End</label>
          					<datetime type="datetime" v-model="appointment.end" input-class="form-control"></datetime>
          				</div>
          			</div>

          			<div class="form-row">
          				<div class="form-group col-12">
          					<label for="description">Description</label>
          					<input type="text" v-model="appointment.description" class="form-control" id="description">
          				</div>
          			</div>

          			<div class="form-row">
          				<button class="btn btn-success" @click.prevent="action == 'create' ? create() : update()">{{ action == 'create' ? 'Create' : 'Update' }}</button>
          				<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          			</div>
		            
		          </div>
		        </div>
		    </div>
		</div>
	</div>
</div>
</template>

<script>

export default {
	components:{},
  	name: 'schedule',
  	data () {
	    return {
	    	date: {
	    		yesterday: new Date().setDate(new Date().getDate()-1),
	    		today: new Date,
	    		tomorrow: new Date().setDate(new Date().getDate()+1)
	    	},
	    	action: 'create',
	    	appointment: {},
	    	appointments: [],
	    	attributes: [],
	    	plannables: []
	    }
  	},
  	computed : {
        
	},
	created(){
		console.log(this.date)
	},  
	methods: {
		fetchAppointments: function(){
			this.$store.dispatch('get','/leffit/plannables/appointments')
	        .then(data=>{
	        	console.log('appointments',data);
				this.appointments = data.data.data;
				this.attributes = [];
				for(let appointment of this.appointments) {
					this.attributes.push({
		                key: appointment.id,
		                dates: new Date(appointment.start),
		                customData: {
		                	appointment: appointment,
		                	name: appointment.description,
		                	color: 'red'
		                }
		            });
				}

			});

	        this.$store.dispatch('get','/leffit/plannables/plannables')
	        .then(data=>{
	        	console.log('plannables',data);
				this.plannables = data.data.data;
				var context=this;
				window.$('.select1').select2().change(function(){
					context.appointment.plannable_id=window.$('.select1').val();
					window.console.log(window.$('.select1').val()); 
				});
			}); 
		},
		showCreateForm: function(){
			this.action = 'create';
			this.appointment = {
				plannable_id: '',
				start: new Date().toISOString(),
				end: new Date().toISOString(),
				description: ''
			};
		},
		changeSchedule: function(dayEvent){
			this.action = 'update';
			this.appointment = dayEvent.customData.appointment;
			this.appointment.start = new Date(this.appointment.start).toISOString();
			this.appointment.end = new Date(this.appointment.end).toISOString();
			window.$('.select1').val(this.appointment.plannable_id).trigger('change');
			console.log(dayEvent.customData.appointment)
		},
		create: function() {
			let notification = {title:"Appointment", text:'Created successfully', type:'success'};
			this.$store.dispatch('post',{endpoint:'/leffit/plannables/appointments',details:this.appointment, notification: notification})
	        .then(data=>{
	        	this.fetchAppointments();
	        	this.closeModal();
	        	console.log('plannables',data);
				// this.plannables = data.data.data;
				// var context=this;
				// window.$('.select1').select2().change(function(){
				// 	context.appointment.plannable_id=window.$('.select1').val();
				// 	window.console.log(window.$('.select1').val()); 
				// });
			});
		},
		update: function() {
			let notification = {title:"Appointment", text:'Updated successfully', type:'success'};
			this.$store.dispatch('put',{endpoint:'/leffit/plannables/appointments/'+this.appointment.id,details:this.appointment, notification: notification})
			.then(data=>{
				this.fetchAppointments();
				this.closeModal();
			}).catch((error)=>{
				console.log(error.request);
			});
		},
		closeModal: function(){
		    $('.modal').modal('hide');
		    $('.modal-backdrop').remove();
		},
	},
	mounted(){
		this.fetchAppointments();
	}
}
</script>

<style lang="css" scoped>
	.day-cell {
		border: 1px solid #ccc;
		align-self: stretch;
		height: 120px;
		padding: 5px;
		overflow: scroll;
	}

	.day-cell::-webkit-scrollbar {
	  display: none;
	}
</style>