<template>
    <div v-if="loaded">
        <table class="table table-responsive table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Type</th>
                    <th>{{ $t('leffit.products.voucher.value') }}</th>
                    <th>{{ $t('leffit.products.voucher.validity') }}</th>
                    <th>{{ $t('leffit.products.voucher.start') }}</th>
                    <th>{{ $t('leffit.products.voucher.expire') }}</th>
                    <th>{{ $t('leffit.products.voucher.redeemed') }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in vouchers.data" :key="index">
                    <td>
                        <div class="btn-group">
                            <button @click="removeVoucher(item.code)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                            <a :href="'vouchers/'+item.id" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i></a>
                        </div>
                    </td>
                    <td>{{index+1}}</td>
                    <td>{{item.code}}</td>
                    <td>{{ JSON.parse(item.metadata).value_type }}</td>
                    <td>{{ JSON.parse(item.metadata).value }}</td>
                    <td>{{ JSON.parse(item.metadata).validity }}</td>
                    <td>{{item.starts_at}}</td>
                    <td>{{item.expires_at}}</td>
                    <td>{{item.redeemed_at}}</td>
                </tr>
            </tbody>
        </table>
        <pagination
            :limit="2"
            :data="vouchers"
            @pagination-change-page="getVouchers"
        ></pagination>
    </div>
</template>

<script>
export default {
    data(){
        return {
            vouchers:[],
            loaded:false,
        }
    },
    methods:{
        removeVoucher(id){
            if(!confirm("Are you sure you want to delete this item?")){
                return false;
            }
            this.$store.dispatch('get','/leffit/payments/vouchers/delete/'+id)
            .then(data=>{
                console.log(data);
            }).catch((error)=>{
                window.console.log(error);
            });
            this.vouchers=this.vouchers.filter((d)=> d.code != id);
        },
        getVouchers(page = 1){
            this.$store.dispatch('get','/leffit/payments/vouchers?page='+page)
            .then(data=>{
                this.loaded = true
                this.vouchers = data.data;
            }).catch((error)=>{
                window.console.log(error);
            })
        }
    },
    created(){
        this.getVouchers();
    }
}
</script>
<style scoped>

</style>
