<template>
    <div style="position:relative;height:100vh"> 
         	<div class="col-sm-12 col-md-6">
				<div class="card basicDetailsCard">
					<h4>{{ $t('leffit.crm.contact.basic_details') }}</h4>
					<div id="">
						<div class="bsc">
							<b>{{ $t('leffit.crm.contact.name') }}</b>
							<h6>{{item.name}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('leffit.crm.contact.pagetype') }}</b>
							<h6>{{item.pagetype}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('leffit.crm.contact.locked') }}</b>
							<h6>
                                <span v-if="item.locked">YES</span>
                                <span v-if="!item.locked">NO</span>
                            </h6>
						</div>
						<div class="bsc">
							<b>{{ $t('leffit.crm.contact.locked') }}</b>
							<h6>
                                <span v-if="item.visible">YES</span>
                                <span v-if="!item.visible">NO</span>
                            </h6>
						</div> 
					</div>
				</div>
			</div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            item:{
                name:'',
            },
            fieldtypes:[],
            pagetypes:[]
        }
    }, 
    methods:{ 
    },
    mounted(){
        this.$store.dispatch('get','/leffit/cms/pages/'+this.$route.params.id)
        .then((data)=>{
            console.log('page');
            this.item=data.data.data;
        })
        .catch((error)=>{
            console.log(error.request);
        });
            
            
        this.$store.dispatch('get','/leffit/cms/pagetypes')
		.then((data)=>{
			// console.log(data);
            this.pagetypes=data.data.data; 
            this.item.pagetype=this.pagetypes.find((d)=> d.id == this.item.pagetype_id).name;
            console.log(this.item);
		})
		.catch((error)=>{
			console.log(error.request);
		});
    },
}
</script>

<style lang="css" scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>