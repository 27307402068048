<template>
    <div>
        <div class="row card" style="min-height:83vh">
            <div class="col-12" v-if="!orgLoading">
            <!-- <div class="col-12"> -->
                <div class="form-group mb-0">
                    <label>Leverancier</label>
                    <div class="d-flex align-items-center">
                        <v-select
                        class="w-100"
                        :reduce="(org) => org.id"
                        v-model="organisation"
                        label="name"
                        :options="organisations"
                        ></v-select>
                        <div>
                            <button
                                class="btn btn-success px-4"
                                @click="getOrganizationOrders()"
                            >
                                <span v-if="!addLoading">{{
                                $t("leffit.products.filter.add")
                                }}</span>
                                <span v-else>Loading...</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-0">
                    <label>Sales Order</label>
                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control" v-model="order_id" placeholder="Order id"> 
                        <div>
                            <button class="btn btn-success px-4" @click="getOrder()">
                                <span v-if="!orderLoading">{{ $t("leffit.products.filter.add") }}</span>
                                <span v-else>Loading...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="py-5" style="text-align: center">LOADING...</div>
            <!-- <div class="col-sm-12" v-if="products.length > 0"> -->
            <div class="col-sm-12">
                <div class="table-responsive" style="min-height:27em">
                    <table class="table">
                        <thead>
                            <tr class="border-top-1">
                                <th>Product</th>
                                <th>
                                    <button class="btn btn-primary" @click="exportBolData()">
                                        <span v-if="!loading">Download</span>
                                        <span v-else>DOWNLOADING...</span>
                                    </button>
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#exportImage">
                                        <span>Export afbeeldingen</span>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in uniqueProduct" :key="item.id">
                                <td>{{ item.casa_productcode }} - {{ item.name }} - {{item.product_id}}</td>
                                <td v-if="uniqueProduct.length > 0"></td>
                                <!-- <td>
                                    <button
                                        @click="removeProductTable(index)"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal fade" id="exportImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background:#00000085;">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="removeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="d-flex align-items-center">
                                <input type="text" class="form-control"> 
                                <div>
                                    <button class="btn btn-success px-4" @click="exportImage()">
                                        <span v-if="!orderLoading">Export</span>
                                        <span v-else>Loading...</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3"  v-if="uniqueProduct.length > 0">
                                <div class="col-sm-6">
                                    <p v-pre>{{product.id}} </p>
                                    <p v-pre>{{product.casa_productcode}} </p>
                                    <p v-pre>{{product.casa_seizoenscode}} </p>
                                    <p v-pre>{{product.virgocode}} </p>
                                    <p v-pre>{{product.name}} </p>
                                    <p v-pre>{{product.description}} </p>
                                    <p v-pre>{{product.description_en}} </p>
                                    <p v-pre>{{product.ean}} </p>
                                    <p v-pre>{{product.price_from}} </p>
                                    <p v-pre>{{product.price}} </p>
                                    <p v-pre>{{product.weight}} </p>
                                    <p v-pre>{{product.silver_weight}} </p>
                                    <p v-pre>{{product.ringmaat}} </p>
                                    <p v-pre>{{product.vat}} </p>
                                    <p v-pre>{{product.size}} </p>
                                    <p v-pre>{{product.diameter}} </p>
                                    <p v-pre>{{product.thickness}} </p>
                                    <p v-pre>{{product.length}} </p>
                                    <p v-pre>{{product.height}} </p>
                                    <p v-pre>{{product.width}} </p>
                                    <p v-pre>{{product.created_at}} </p>
                                    <p v-pre>{{product.updated_at}} </p>
                                    <p v-pre>{{product.deleted_at}} </p>
                                    <p v-pre>{{image.name}} </p>
                                    <p v-pre>{{image.file_name}} </p>
                                    <p v-pre>{{image.title}} // custom prop </p>
                                    <p v-pre>{{image.alt}} // custom prop </p>
                                    <p v-pre>{{image.pickorder}} </p>
                                </div>
                                <div class="col-sm-6">
                                    <p>{{uniqueProduct[0].id}} </p>
                                    <p>{{uniqueProduct[0].casa_productcode}} </p>
                                    <p>{{uniqueProduct[0].casa_seizoenscode}} </p>
                                    <p>{{uniqueProduct[0].virgocode}} </p>
                                    <p>{{uniqueProduct[0].name}} </p>
                                    <p>{{uniqueProduct[0].description}} </p>
                                    <p>{{uniqueProduct[0].description_en}} </p>
                                    <p>{{uniqueProduct[0].ean}} </p>
                                    <p>{{uniqueProduct[0].price_from}} </p>
                                    <p>{{uniqueProduct[0].price}} </p>
                                    <p>{{uniqueProduct[0].weight}} </p>
                                    <p>{{uniqueProduct[0].silver_weight}} </p>
                                    <p>{{uniqueProduct[0].ringmaat}} </p>
                                    <p>{{uniqueProduct[0].vat}} </p>
                                    <p>{{uniqueProduct[0].size}} </p>
                                    <p>{{uniqueProduct[0].diameter}} </p>
                                    <p>{{uniqueProduct[0].thickness}} </p>
                                    <p>{{uniqueProduct[0].length}} </p>
                                    <p>{{uniqueProduct[0].height}} </p>
                                    <p>{{uniqueProduct[0].width}} </p>
                                    <p>{{uniqueProduct[0].created_at}} </p>
                                    <p>{{uniqueProduct[0].updated_at}} </p>
                                    <p>{{uniqueProduct[0].deleted_at}} </p>
                                    <p>{{uniqueProduct[0].productimages.length > 0 ? uniqueProduct[0].productimages[0].name :''}} </p>
                                    <p>{{uniqueProduct[0].productimages.length > 0 ? uniqueProduct[0].productimages[0].file_name :''}} </p>
                                    <p>{{uniqueProduct[0].productimages.length > 0 ? uniqueProduct[0].productimages[0].title :''}} </p>
                                    <p>{{uniqueProduct[0].productimages.length > 0 ? uniqueProduct[0].productimages[0].alt :''}} </p>
                                    <p>{{uniqueProduct[0].productimages.length > 0 ? uniqueProduct[0].productimages[0].pickorder :''}} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-10 upload-container">
                <div class="btns">
                    <button @click="exportBolData()" class="btn btn-success">
                        <span v-if="!loading">Export Bol.com</span>
                        <span v-else>Exporting</span>
                    </button>

                    <button @click="exportBijenkorfData()" class="btn btn-success">
                        <span v-if="!loading2">Export Bijenkorf</span>
                        <span v-else>Exporting</span>
                    </button>
                </div>
            </div> 
        </div> -->
    </div>
</template>
<script>
import axios from 'axios'
import { json } from 'body-parser';
export default {
    data() {
        return {
            loading:false,
            loading2:false,
            orderLoading:false,
            addLoading: false,
            orgLoading: false,
            exportLoader: false,
            imageLoader:false,

            order:[],
            order_id:'',
            organisations: [],
            organisation: "",
            products:[],
            productsId:[],
            product: "",

            sort:'',
            casa_sd:'',
            data: [],
        }
    },
    computed:{
        uniqueProduct: function(){
            return this.products.filter((product, index)=>
                this.products.findIndex(item => item.id == product.id) === index
            )
        },
    },
    methods: {
        async exportImage(){
            var uid = this.uniqueProduct.map(product=>product.id)
            uid = JSON.stringify(uid)
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export-image?products='+uid,
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
            if(response.status == 200 ){
                console.log(response);
                const type = response.headers['content-type']
                const blob = new File([response.data],{type: 'application/octet-stream'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                var current = new Date();
                link.download = current.getFullYear()+current.getMonth()+1+current.getDate()+current.getTime()+'_product_image.zip';
                link.click(); 
                
                this.$store.dispatch('displayNotification',{
                    type:'success',
                    id:Math.round(Math.random()*100),
                    message:'Exported successfully.',
                    interval:5000,
                    autoDismiss:true
                })
            }
            else{ 
                if(response.status == 500){
                    this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                }
            }
        },
        removeModal() {
            window.$("#exportImage").removeClass("show");
        },
        removeProductTable(pindex) {
            this.products.splice(pindex, 1);
        },
        getOrganizationOrders() {
            if (this.organisation.length < 1) {
                this.$store.dispatch("makeNotification", {
                    title: "Error",
                    text: "Supplier field is required",
                    type: "danger",
                });
                return false;
            }
            this.addLoading = true;
            this.$store
            .dispatch(
                "get",
                "/leffit/orders/get-organisation-orders/" + this.organisation
            )
            .then((resp) => {
                this.addLoading = false;
                console.log(resp)
                var temp = []
                resp.data.data.forEach(orderrow=>{
                    orderrow.orderrows.forEach(row=>{
                        this.products.push(row.product)
                    })
                })
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getOrganisations() {
            this.orgLoading = true;
            this.$store
                .dispatch("get", "/leffit/crm/get-organizations-product")
                .then((resp) => {
                    this.orgLoading = false;
                    console.log(resp)
                    this.organisations = resp.data.data.sort(
                        (a, b) => (a.name > b.name && 1) || -1
                    );
                })
                .catch((error) => {
                    this.orgLoading = false;
                    console.log(error);
                });
        },
        getOrder(){
            this.orderLoading = true
            this.$store
                .dispatch("get", "/leffit/orders/orders/"+this.order_id)
                .then((resp) => {
                    this.orderLoading = false
                    console.log(resp.data)
                    if(resp.data.data.ordertype_id != 120){
                        this.$store.dispatch("makeNotification", {
                            title: "Error",
                            text: "Geen verkoop order",
                            type: "danger",
                        });
                        return false;
                    }
                    resp.data.data.orderrows.forEach(row=>{
                        this.products.push(row.product)
                    })
                    
                })
        },
        async exportBolData(){
            if(this.loading) return false; 
            this.loading = true;
            
            var uid = this.uniqueProduct.map(product=>product.id)
            uid = JSON.stringify(uid)
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export?model=webshop-bol&type=bol&products='+uid,
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
            if(response.status == 200 ){
                console.log(response);
                const type = response.headers['content-type']
                const blob = new File([response.data],{type: 'application/octet-stream'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                var current = new Date();
                link.download = current.getFullYear()+current.getMonth()+1+current.getDate()+'_bolcom.csv';
                link.click(); 
                
                this.$store.dispatch('displayNotification',{
                    type:'success',
                    id:Math.round(Math.random()*100),
                    message:'Exported successfully.',
                    interval:5000,
                    autoDismiss:true
                })
            }
            else{ 
                if(response.status == 500){
                    this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                }
            } 
            this.loading = false;
        },
        async exportBijenkorfData(){
            if(this.loading2) return false; 
            this.loading2 = true;
            
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export?model=webshop-bol&type=bijenkorf',
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if(response.status == 200 ){
                    console.log(response);
                    const type = response.headers['content-type']
                    const blob = new File([response.data],{type: 'application/octet-stream'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    // var current = new Date;
                    link.download = 'bijenkorf.csv';
                    link.click(); 
                    
                    this.$store.dispatch('displayNotification',{
                        type:'success',
                        id:Math.round(Math.random()*100),
                        message:'Exported successfully.',
                        interval:5000,
                        autoDismiss:true
                    })
                }
                else{ 
                    if(response.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                    }
                } 
            this.loading2 = false;
        },
    },
    created(){
        this.getOrganisations();
    }
}
</script>

<style>
.vs__dropdown-toggle {
  height: 40px !important;
}
</style>
<style scoped>
    .btns{
        position: absolute;
        height: fit-content;
        width: fit-content;
        margin: auto;
        top:0;
        bottom:0;
        left:0;
        right:0;
    }
    .upload-container{ 
        height: 400px;
    }
    .select2-container {
        width: 100% !important;
    }
    .v-select {
        width: 100%;
    }
</style>