<template>
  <div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("leffit.crm.address.name") }}</th>
          <th>{{ $t("leffit.crm.address.address") }}</th>
          <th>{{ $t("leffit.crm.address.address2") }}</th>
          <th>{{ $t("leffit.crm.address.zipcode") }}</th>
          <th>{{ $t("leffit.crm.address.city") }}</th>
          <th>{{ $t("leffit.crm.address.country") }}</th>
          <th>{{ $t("leffit.crm.address.phone") }}</th>
          <th>{{ $t("leffit.crm.address.mobile") }}</th>
          <th>{{ $t("leffit.crm.address.fax") }}</th>
          <th>{{ $t("leffit.crm.address.email") }}</th>
          <th>{{ $t("leffit.crm.address.created_at") }}</th>
          <th>{{ $t("leffit.crm.address.updated_at") }}</th>
          <th>{{ $t("leffit.crm.address.deleted_at") }}</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <a @click="searchItem()" class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th v-for="(attribute, index) in attributes" :key="index">
            <input
              type="text"
              v-model="search.search[attribute.attribute]"
              class="form-control"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in addresses.data" :key="index">
          <td>
            <router-link
              :to="'/admin/crm/addresses/' + item.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              :to="'/admin/crm/addresses/' + item.id + '/edit'"
              class="btn btn-warning"
            >
              <i class="fa fa-pencil"></i>
            </router-link>
            <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </a>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address2 }}</td>
          <td>{{ item.zipcode }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.country }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.fax }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.updated_at }}</td>
          <td>{{ item.deleted_at }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <pagination
        :limit="2"
        :data="addresses"
        @pagination-change-page="searchItem"
      ></pagination>
    </div>
  </div>

</template>

<script>
export default {
  name: "addresstable",
  // props: ["pagedata"],
  data() {
    return {
      attributes: [
        { attribute: "name", value: "" },
        { attribute: "address", value: "" },
        { attribute: "address2", value: "" },
        { attribute: "zipcode", value: "" },
        { attribute: "city", value: "" },
        { attribute: "country", value: "" },
        { attribute: "phone", value: "" },
        { attribute: "mobile", value: "" },
        { attribute: "fax", value: "" },
        { attribute: "email", value: "" },
        { attribute: "created_at", value: "" },
        { attribute: "updated_at", value: "" },
        { attribute: "deleted_at", value: "" },
      ],
      search: { search: {} },
      addresses: {},
    };
  },
  methods: {
    searchItem(page = 1) {
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/addresses/search?page="+page,
          details: this.search,
        })
        .then((data) => {
          this.addresses = data.data;
          console.log("Search addresses: ", this.addresses)
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    deleteItem(id) {
      this.pagedata = this.pagedata.filter((dt) => dt.id != id);
      console.log(this.pagedata);
      this.$store
        .dispatch("delete", "/leffit/crm/addresses/" + id)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: this.item.name + " was removed successfully",
            type: "success",
          });
        });
    },
  },
  created() {
    this.searchItem();
  },
};
</script>