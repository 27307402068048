<template>
    <div class="row">
        <div class="col-12 text-center">
            <label for="">Shipping Cost</label>
            <Select2 @change="$parent.updateItem()" class="form-control" v-model="settings.shipping"  :options="options" />
        </div>
    </div>
</template>
<script>
    export default {
        props:['settings'],
        data(){
            return {
                value:0,
                options:[]
            }
        },
        created(){
            this.$store.dispatch('get','/leffit/shipping/shippingcosts')
            .then(data=>{ 
                // console.log(data); 
                this.options = data.data.data.map((d)=>{
                    return {
                        id:d.id,
                        text:d.name
                    }
                });  
            });
        }
    }
</script>
<style scoped>

</style>