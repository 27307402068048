<template>
  <table class="table table-responsive table-hover">
    <thead>
      <tr>
        <th></th>
        <th>Producten</th>
        <th>{{ $t("lefficient.system.amount") }}</th>
        <th>{{ $t("lefficient.system.warehouse") }}</th>
        <th>{{ $t("leffit.products.stock.voorraadtype") }}</th>
        <th>Verwachte binnenkomst</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in filteredList">
        <td></td>
        <td>
          <span v-if="item.product">{{ item.product.name }}</span>
        </td>
        <td>{{ item.amount }}</td>
        <td>
          <span v-if="item.warehouse">{{ item.warehouse.name }}</span>
        </td>
        <td>
          <span v-if="item.stocktype">{{ $t(item.stocktype.name) }}</span>
        </td>
        <td>
          <span v-if="item.datetime">{{ item.datetime }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    stocks: null,
  },
  data() {
    return {
      pagedata: [],
    };
  },
  mounted() {
    if (this.stocks == null) {
      this.$store
        .dispatch(
          "get",
          "/leffit/products/product-stocks/" + this.$route.params.id
        )
        .then((data) => {
          this.pagedata = data.data.data;
        });
    } else {
      this.pagedata = this.stocks;
    }
  },

  computed: {
    filteredList() {
      return this.pagedata.filter((data) => {
        return data.amount != 0;
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
