<template>
  <div>
    <processSteps
      class="mb-3"
      :steps="item.process_steps"
      v-if="item.process_steps"
      :next="nextStep"
    ></processSteps>
    <div class="head row p-0 m-0">
      <div class="col-12 p-0 m-0">
        <h3 class="pl-3">{{ $t(ordertype.name) }}</h3>
      </div>
    </div>
    <div
      v-if="item.id && (item.ordertype_id == 130 || item.ordertype_id == 310)"
      class="row"
    >
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-print"></i> {{ $t("leffit.products.product.ean") }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.barcode_1/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.ean") }} 1
          </a>
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.barcode_2/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.ean") }} 2
          </a>
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.barcode_3/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.ean") }} 3
          </a>
        </div>
      </div>
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-print"></i> {{ $t("leffit.products.product.label") }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.product_packing_1/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.label") }} Beiden
          </a>
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.product_packing_2/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.label") }} Inkoopprijs
          </a>
          <a
            :href="
              url +
                '/lefficient/leffit/pdf/products.product_packing_3/' +
                item.id +
                '/nl?type=order'
            "
            target="_blank"
            class="dropdown-item"
            type="button"
          >
            {{ $t("leffit.products.product.label") }} Verkoopprijs
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="buttonbar" v-if="ordertype.settings.buttons.length > 0">
          <div class="row">
            <div class="col-md-12">
              <i class="fa fa-download mr-3"></i>
              <a
                class="btn btn-primary"
                v-for="(btn, index) in ordertype.settings.buttons"
                :key="'btn1' + index"
                :href="
                  endpoint +
                    '/lefficient/leffit/pdf/orders.' +
                    btn +
                    '/' +
                    $route.params.id +
                    '/nl'
                "
                target="_blank"
                :id="index"
              >
                {{ $t("leffit.orders.order.buttons." + btn) }}
              </a>
            </div>
            <div class="col-md-12 mb-3">
              <i class="fa fa-search mr-3"></i>
              <a
                class="btn btn-primary text-white"
                v-for="(btn, index) in ordertype.settings.buttons"
                :key="'btn2' + index"
                @click.prevent="
                  downloadPackageslipData(
                    endpoint +
                      '/lefficient/leffit/pdf/orders.' +
                      btn +
                      '/' +
                      $route.params.id +
                      '/nl'
                  )
                "
              >
                {{ $t("leffit.orders.order.buttons." + btn) }}
              </a>
            </div>
          </div>
        </div>
        <div class="accordion p-0" id="accordionExample">
          <div class="card">
            <!-- RELATION DETAILS -->
            <div v-if="ordertype.settings.relationdetails">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ $t("leffit.orders.order.relationdetails") }}
                  </a>
                </h2>
              </div>
              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <!-- FOR VIEWING SHIPPING -->
                      <div
                        v-if="!editOrder"
                        class="card basicDetailsCard"
                        style="height: 100%"
                      >
                        <div
                          class="row mb-2"
                          style="
                            justify-content: space-between;
                            padding: 0px 11px;
                          "
                        >
                          <h4 class="m-0">
                            {{ $t("leffit.orders.order.relationdetails") }}
                          </h4>
                          <button
                            v-if="!item.completed"
                            @click="toggleEditOrder()"
                            style="padding: 4px !important; height: 24px"
                            class="btn btn-sm m-0"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </div>
                        <div id="">
                          <div v-if="item.from_id != null" class="bsc bsc2">
                            <b> Verzendfiliaal </b>
                            <h6>{{ item.relation_warehouse.name }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.companyname") }} </b>
                            <h6>{{ item.companyname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.firstname") }} </b>
                            <h6>{{ item.firstname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.lastname") }} </b>
                            <h6>{{ item.lastname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.address") }} </b>
                            <h6>{{ item.address }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.address2") }} </b>
                            <h6>{{ item.address2 }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.zipcode") }} </b>
                            <h6>{{ item.zipcode }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.city") }} </b>
                            <h6>{{ item.city }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.country") }} </b>
                            <h6>{{ item.country }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.email") }} </b>
                            <h6>{{ item.email }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.phone") }} </b>
                            <h6>{{ item.phone }}</h6>
                          </div>
                        </div>
                      </div>

                      <!-- FOR EDITTING ORDER -->
                      <div
                        v-if="editOrder"
                        class="card basicDetailsCard"
                        style="height: 100%"
                      >
                        <div
                          class="row mb-2"
                          style="
                            justify-content: space-between;
                            padding: 0px 11px;
                          "
                        >
                          <h4 class="m-0">Edit Order</h4>
                          <button
                            v-if="!item.completed"
                            @click="toggleEditOrder()"
                            style="padding: 4px !important; height: 24px"
                            class="btn btn-sm btn-success m-0"
                          >
                            <i class="fa fa-check"></i>
                          </button>
                        </div>
                        <div id="">
                          <div class="row">
                            <div
                              v-if="item.from_id != null"
                              class="form-group col-12"
                            >
                              <label for="filter_parent">Verzendfiliaal</label>
                              <Select2
                                v-model="item.from_id"
                                :options="
                                  warehouses.map((d) => {
                                    return { id: d.id, text: d.name };
                                  })
                                "
                                style="position: relative; width: 100%"
                                class="organization organization1"
                                id="warehouse1"
                              ></Select2>
                            </div>
                            <div class="form-group col-6">
                              <label for="filter_parent">{{
                                $t("leffit.orders.order.select_organization")
                              }}</label>
                              <Select2
                                v-model="item.contact_id"
                                :options="
                                  organizations.map((d) => {
                                    return { id: d.id, text: d.name };
                                  })
                                "
                                @change="populateOrganization($event)"
                                style="position: relative; width: 100%"
                                class="form-control contact contact1"
                              ></Select2>
                            </div>
                            <div class="form-group col-6">
                              <label for="filter_parent">{{
                                $t("leffit.orders.order.select_contact")
                              }}</label>
                              <Select2
                                v-model="item.contact_id"
                                :options="
                                  contacts.map((d) => {
                                    return {
                                      id: d.id,
                                      text: d.firstname + ' ' + d.lastname,
                                    };
                                  })
                                "
                                @change="populateContact($event)"
                                style="position: relative; width: 100%"
                                class="form-control contact contact1"
                              ></Select2>
                            </div>

                            <div class="form-group col-6">
                              <label for="order_firstname">{{
                                $t("leffit.orders.order.firstname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_firstname"
                                v-model="item.firstname"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_lastname">{{
                                $t("leffit.orders.order.lastname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_lastname"
                                v-model="item.lastname"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_companyname">{{
                                $t("leffit.orders.order.companyname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_companyname"
                                v-model="item.companyname"
                              />
                            </div>
                            <div class="form-group col-6">
                              <label for="order_address">{{
                                $t("leffit.orders.order.address")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_address"
                                v-model="item.address"
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-6">
                              <label for="order_address2">{{
                                $t("leffit.orders.order.address2")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_address2"
                                v-model="item.address2"
                              />
                            </div>
                            <div class="form-group col-6">
                              <label for="order_zipcode">{{
                                $t("leffit.orders.order.zipcode")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_zipcode"
                                v-model="item.zipcode"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_city">{{
                                $t("leffit.orders.order.city")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_city"
                                v-model="item.city"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_country">{{
                                $t("leffit.orders.order.country")
                              }}</label>
                              <Select2
                                v-model="item.country"
                                :options="
                                  country.map((d) => {
                                    return { id: d.code, text: d.name };
                                  })
                                "
                                style="position: relative; width: 100%"
                                class="form-control country country2"
                              ></Select2>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-12">
                              <label for="order_email">{{
                                $t("leffit.orders.order.email")
                              }}</label>
                              <input
                                type="email"
                                class="form-control"
                                id="order_email"
                                v-model="item.email"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SHIPPING DETAILS -->
            <div v-if="ordertype.settings.shippingdetails">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapseOne1"
                    aria-expanded="true"
                    aria-controls="collapseOne1"
                  >
                    {{ $t("leffit.orders.order.shipping_details") }}
                  </a>
                </h2>
              </div>
              <div
                id="collapseOne1"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <!-- FOR VIEWING SHIPPING -->
                      <div
                        v-if="!editShipping"
                        class="card basicDetailsCard"
                        style="height: 100%"
                      >
                        <div
                          class="row mb-2"
                          style="
                            justify-content: space-between;
                            padding: 0px 11px;
                          "
                        >
                          <h4 class="m-0">
                            {{ $t("leffit.orders.order.shipping_details") }}
                          </h4>
                          <button
                            v-if="!item.completed"
                            @click="toggleEditShip()"
                            style="padding: 4px !important; height: 24px"
                            class="btn btn-sm m-0"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </div>
                        <div id="">
                          <div v-if="item.from_id != null" class="bsc bsc2">
                            <b> Ontvangstfiliaal </b>
                            <h6>{{ item.shipping_warehouse.name }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_companyname") }}
                            </b>
                            <h6>{{ item.ship_companyname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_firstname") }}
                            </b>
                            <h6>{{ item.ship_firstname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_lastname") }}
                            </b>
                            <h6>{{ item.ship_lastname }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_address") }}
                            </b>
                            <h6>{{ item.ship_address }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_address2") }}
                            </b>
                            <h6>{{ item.ship_address2 }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_zipcode") }}
                            </b>
                            <h6>{{ item.ship_zipcode }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b> {{ $t("leffit.orders.order.ship_city") }} </b>
                            <h6>{{ item.ship_city }}</h6>
                          </div>
                          <div class="bsc bsc2">
                            <b>
                              {{ $t("leffit.orders.order.ship_country") }}
                            </b>
                            <h6>{{ item.ship_country }}</h6>
                          </div>
                        </div>
                      </div>

                      <!-- FOR EDITTING SHIPPING -->
                      <div
                        v-if="editShipping"
                        class="card basicDetailsCard"
                        style="height: 100%"
                      >
                        <div
                          class="row mb-2"
                          style="
                            justify-content: space-between;
                            padding: 0px 11px;
                          "
                        >
                          <h4 class="m-0">Edit Shipping</h4>
                          <button
                            v-if="!item.completed"
                            @click="toggleEditShip()"
                            style="padding: 4px !important; height: 24px"
                            class="btn btn-sm btn-success m-0"
                          >
                            <i class="fa fa-check"></i>
                          </button>
                        </div>
                        <div id="">
                          <div class="row">
                            <div
                              v-if="item.to_id != null"
                              class="form-group col-12"
                            >
                              <label for="filter_parent"
                                >Ontvangstfiliaal
                              </label>
                              <Select2
                                v-model="item.to_id"
                                :options="
                                  warehouses.map((d) => {
                                    return { id: d.id, text: d.name };
                                  })
                                "
                                style="position: relative; width: 100%"
                                class="organization organization1"
                                id="warehouse1"
                              ></Select2>
                            </div>
                            <div class="form-group col-6">
                              <label for="filter_parent">{{
                                $t("leffit.orders.order.select_organization")
                              }}</label>
                              <Select2
                                v-model="item.organization_id"
                                :options="
                                  organizations.map((d) => {
                                    return { id: d.id, text: d.name };
                                  })
                                "
                                @change="populateShipOrganization($event)"
                                style="position: relative; width: 100%"
                                class="form-control organization organization2"
                              ></Select2>
                            </div>
                            <div class="form-group col-6">
                              <label for="filter_parent">{{
                                $t("leffit.orders.order.select_contact")
                              }}</label>
                              <Select2
                                v-model="item.contact_id"
                                :options="
                                  contacts.map((d) => {
                                    return {
                                      id: d.id,
                                      text: d.firstname + ' ' + d.lastname,
                                    };
                                  })
                                "
                                @change="populateShipContact($event)"
                                style="position: relative; width: 100%"
                                class="form-control contact contact2"
                              ></Select2>
                            </div>
                            <div class="form-group col-6">
                              <label for="order_ship_firstname">{{
                                $t("leffit.orders.order.ship.firstname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_firstname"
                                v-model="item.ship_firstname"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_ship_lastname">{{
                                $t("leffit.orders.order.ship.lastname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_lastname"
                                v-model="item.ship_lastname"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_ship_companyname">{{
                                $t("leffit.orders.order.ship.companyname")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_companyname"
                                v-model="item.ship_companyname"
                              />
                            </div>
                            <div class="form-group col-6">
                              <label for="order_ship_address">{{
                                $t("leffit.orders.order.ship.address")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_address"
                                v-model="item.ship_address"
                              />
                            </div>
                            <div class="form-group col-6">
                              <label for="order_ship_address2">{{
                                $t("leffit.orders.order.ship.address2")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_address2"
                                v-model="item.ship_address2"
                              />
                            </div>
                            <div class="form-group col-6">
                              <label for="order_ship_zipcode">{{
                                $t("leffit.orders.order.ship.zipcode")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_zipcode"
                                v-model="item.ship_zipcode"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_ship_city">{{
                                $t("leffit.orders.order.ship.city")
                              }}</label>
                              <input
                                type="text"
                                class="form-control"
                                id="order_ship_city"
                                v-model="item.ship_city"
                              />
                            </div>

                            <div class="form-group col-6">
                              <label for="order_ship_country">{{
                                $t("leffit.orders.order.ship.country")
                              }}</label>
                              <Select2
                                v-model="item.ship_country"
                                :options="
                                  country.map((d) => {
                                    return { id: d.code, text: d.name };
                                  })
                                "
                                style="position: relative; width: 100%"
                                class="form-control country country2"
                              ></Select2>
                            </div>
                          </div>

                          <div class="form-group col-12">
                            <label
                              for="order_use_shipping_address"
                              class="d-flex"
                            >
                              <input
                                :checked="
                                  item.use_shipping_address == 1 ? true : false
                                "
                                @click="
                                  item.use_shipping_address =
                                    item.use_shipping_address == 0 ? 1 : 0
                                "
                                style="height: 21px; width: 20px"
                                type="checkbox"
                                class="form-control d-block"
                                id="order_use_shipping_address"
                              />
                              <div>
                                {{
                                  $t("leffit.orders.order.use_shipping_address")
                                }}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ORDER DETAILS -->
            <div
              v-if="
                ordertype.settings != null &&
                  ordertype.settings.orderdetails.find((d) => d == 'show')
              "
            >
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("leffit.orders.order.details") }}
                  </a>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="card basicDetailsCard">
                    <div
                      class="row mb-2"
                      style="justify-content: flex-end; padding: 0px 11px"
                      v-if="!editOrderDetails"
                    >
                      <button
                        v-if="!item.completed"
                        @click="toggleEditOrderDetails()"
                        style="padding: 4px !important; height: 24px"
                        class="btn btn-sm m-0"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                    <table class="table" v-if="!editOrderDetails">
                      <tr>
                        <th>{{ $t("leffit.orders.order.currency") }}</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.orderstatus") }}</th>
                        <td>
                          {{ $t(item.orderstatus) }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.order_date") }}</th>
                        <td>
                          {{ item.order_date }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.created_by_user") }}</th>
                        <td v-if="item.created_by_user">
                          {{ item.created_by_user.display_name }}
                        </td>
                        <td v-else></td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.updated_by_user") }}</th>
                        <td v-if="item.updated_by_user">
                          {{ item.updated_by_user.display_name }}
                        </td>
                        <td v-else></td>
                      </tr>
                    </table>

                    <div
                      class="row mb-2"
                      style="justify-content: flex-end; padding: 0px 11px"
                      v-if="editOrderDetails"
                    >
                      <button
                        @click="toggleEditOrderDetails()"
                        style="padding: 4px !important; height: 24px"
                        class="btn btn-sm btn-success m-0"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                    </div>
                    <table class="table" v-if="editOrderDetails">
                      <tr>
                        <th>{{ $t("leffit.orders.order.currency") }}</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.orderstatus") }}</th>
                        <td>
                          <select
                            class="form-control"
                            v-model="item.orderstatus_id"
                          >
                            <option
                              :key="index"
                              v-for="(option, index) in orderstatuses"
                              :value="option.id"
                            >
                              {{ $t(option.name) }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.order_date") }}</th>
                        <td>
                          <input
                            type="date"
                            v-model="item.order_date"
                            class="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.created_by_user") }}</th>
                        <td v-if="item.created_by_user">
                          {{ item.created_by_user.display_name }}
                        </td>
                        <td v-else></td>
                      </tr>
                      <tr>
                        <th>{{ $t("leffit.orders.order.updated_by_user") }}</th>
                        <td v-if="item.updated_by_user">
                          {{ item.updated_by_user.display_name }}
                        </td>
                        <td v-else></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- FILES DETAILS -->
            <div v-if="ordertype.settings.files">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapseFiles"
                    aria-expanded="true"
                    aria-controls="collapseFiles"
                  >
                    {{ $t("leffit.orders.order.files") }}
                  </a>
                </h2>
              </div>
              <div
                id="collapseFiles"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <div class="card basicDetailsCard" style="height: 100%">
                        <div class="form-group">
                          <input type="file" id="file" class="form-control" />
                        </div>
                        <div class="">
                          <button @click="uploadFile()" class="btn btn-success">
                            UPLOAD
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-5">
                      <table>
                        <tr
                          v-for="(image, index) in item.images"
                          :key="index"
                          style="border-bottom: 30px solid white"
                        >
                          <td style="width: fit-content; vertical-align: top">
                            <a
                              @click="removeFiles(image.id)"
                              href="#"
                              class="btn btn-danger mr-4"
                              ><i class="fa fa-remove"></i
                            ></a>
                          </td>
                          <td style="width: fit-content" class="mb-3">
                            <img
                              style="height: 100px; width: auto !important"
                              :src="image"
                              alt=""
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- REMARK DETAILS -->
            <div class="card-header" id="headingRemarks">
              <div
                class="row mb-2"
                style="justify-content: space-between; padding: 0px 11px"
              >
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    {{ $t("leffit.orders.order.remarks") }}
                  </a>
                </h2>
              </div>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card basicDetailsCard">
                  <div v-if="!editRemark" id="">
                    <div
                      class="row mb-2"
                      style="justify-content: flex-end; padding: 0px 11px"
                    >
                      <button
                        v-if="!item.completed"
                        @click="toggleEditRemark()"
                        style="padding: 4px !important; height: 24px"
                        class="btn btn-sm m-0"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                    <div class="bsc">
                      <h6 v-html="item.remarks"></h6>
                    </div>
                  </div>
                  <div v-if="editRemark" class="id">
                    <div
                      class="row mb-2"
                      style="justify-content: flex-end; padding: 0px 11px"
                    >
                      <button
                        @click="toggleEditRemark()"
                        style="padding: 4px !important; height: 24px"
                        class="btn btn-sm btn-success m-0"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                    </div>
                    <div class="col-12">
                      <label for="order_remarks">{{
                        $t("leffit.orders.order.remarks")
                      }}</label>
                      <textarea
                        class="form-control WYSIWYGn"
                        id="order_remarks"
                        v-model="item.remarks"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- STOCK MUTATIONS DETAILS -->
            <div class="card-header" id="headingStock">
              <div
                class="row mb-2"
                style="justify-content: space-between; padding: 0px 11px"
              >
                <h2 class="mb-0">
                  <a
                    style="font-size: 16px"
                    data-toggle="collapse"
                    href="#collapsemutation"
                    aria-expanded="true"
                    aria-controls="collapsemutation"
                  >
                    {{ $t("leffit.orders.order.mutation") }}
                  </a>
                </h2>
              </div>
              <div
                id="collapsemutation"
                class="collapse"
                aria-labelledby="collapsemutation"
                data-parent="#collapsemutation"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 mt-5">
                      <table class="table table-responsive table-hover">
                        <thead>
                          <tr>
                            <td>Amount</td>
                            <td>Product</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(mutation, index) in item.mutations"
                            :key="index"
                            style="border-bottom: 30px solid white"
                          >
                            <td style="width: fit-content; vertical-align: top">
                              {{ mutation.mutation_amount }}
                            </td>
                            <td style="width: fit-content" class="mb-3">
                              <span v-if="mutation.product">{{
                                mutation.product.name
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ORDER ROW -->
      <div class="col-12 table-responsive">
        <div class="card">
          <table class="table table-hover">
            <thead>
              <tr>
                <th v-if="!item.completed">
                  <button
                    v-if="
                      !item.completed &&
                        (item.ordertype_id == 20 || item.ordertype_id == 40) &&
                        update_costs
                    "
                    @click="refreshCalculation()"
                    class="btn btn-primary"
                  >
                    <i
                      :class="{ loading: refreshing }"
                      class="fa fa-refresh"
                    ></i>
                  </button>
                </th>
                <th v-if="item.ordertype_id == 120">Nalevering</th>
                <th
                  v-for="(attr, index) in ordertype.settings.orderrow.length > 0
                    ? ordertype.settings.orderrow
                    : []"
                  :key="index"
                  :class="'order_' + attr"
                >
                  {{ $t("leffit.orders.orderrow." + attr) }}
                </th>
                <th v-if="item.ordertype_id == 310">Voorraad</th>
              </tr>
            </thead>
            <thead class="w-100">
              <tr>
                <th v-if="!item.completed">
                  <a
                    v-if="!item.completed"
                    @click="createRow()"
                    class="btn btn-primary"
                    ><i class="fa fa-plus"></i
                  ></a>
                </th>
                <th v-if="item.ordertype_id == 120">
                  <div class="form-group d-flex">
                    <input
                      v-model="row.backorder"
                      type="checkbox"
                      class="form-control m-0 mr-2"
                      id="backorder"
                    />
                  </div>
                </th>
                <th
                  v-for="(attr, index) in ordertype.settings.orderrow.length > 0
                    ? ordertype.settings.orderrow
                    : []"
                  :key="index"
                >
                  <input
                    v-if="attr == 'amount'"
                    @change="updatePrice()"
                    @keyup="updatePrice()"
                    type="number"
                    :placeholder="$t('leffit.orders.orderrow.amount')"
                    class="form-control"
                    id="order_row_amount"
                    v-model="row.amount"
                  />

                  <Select2
                    @change="updateProductDetails()"
                    v-model="row.product_id"
                    style="position: relative; width: 100%"
                    class="product"
                    v-if="
                      attr == 'product_id' &&
                        !products_loading &&
                        products.length > 0
                    "
                    :options="
                      products.map((d) => {
                        return { id: d.id, text: d.name };
                      })
                    "
                  ></Select2>
                  <span v-if="attr == 'product_id' && products_loading"
                    >Laden...</span
                  >
                  <a
                    v-if="
                      attr == 'product_id && !item.completed' &&
                        !products_loading &&
                        products.length == 0
                    "
                    :href="'/admin/crm/organization/' + item.organization_id"
                    >Add product to organization</a
                  >

                  <input
                    v-if="attr == 'pricePer'"
                    @change="updatePrice()"
                    @keyup="updatePrice()"
                    type="number"
                    class="form-control"
                    :placeholder="$t('leffit.orders.orderrow.pricePer')"
                    id="order_row_price"
                    v-model="row.pricePer"
                  />

                  <input
                    v-if="attr == 'discount'"
                    @change="updatePrice()"
                    @keyup="updatePrice()"
                    type="number"
                    class="form-control"
                    :placeholder="$t('leffit.orders.orderrow.discount')"
                    id="order_row_discount"
                    v-model="row.discount"
                  />

                  <input
                    v-if="attr == 'discountPercentage'"
                    @change="updatePrice()"
                    @keyup="updatePrice()"
                    type="number"
                    class="form-control"
                    :placeholder="
                      $t('leffit.orders.orderrow.discountPercentage')
                    "
                    id="order_row_discountPercentage"
                    v-model="row.discount_percentage"
                  />

                  <input
                    v-if="attr == 'price'"
                    type="number"
                    class="form-control"
                    id="order_row_price"
                    step="0.01"
                    v-model="row.price"
                    disabled
                  />

                  <input
                    v-if="attr == 'vat'"
                    type="text"
                    class="form-control"
                    :placeholder="$t('leffit.orders.orderrow.vat')"
                    id="order_row_vat"
                    v-model="row.vat"
                    disabled
                  />

                  <input
                    v-if="attr == 'purchase_price'"
                    type="number"
                    class="form-control"
                    id="order_row_purchase_price"
                    v-model="row.purchase_price"
                  />

                  <input
                    v-if="attr == 'expectedArrival'"
                    type="date"
                    class="form-control"
                    id="order_row_expected_arrival"
                    v-model="row.expectedArrival"
                  />

                  <select
                    v-if="attr == 'purchase_price_currency_id'"
                    style="position: relative; width: 100%"
                    class="form-control purchase_price_currency_id"
                    id="order_purchase_price_currency_id"
                  >
                    <option value="">
                      {{
                        $t("leffit.orders.orderrow.purchase_price_currency_id")
                      }}
                    </option>
                    <option
                      :key="index"
                      v-for="(pr, index) in purchase_price_currencies"
                      :value="pr.id"
                      v-html="pr.sign"
                    ></option>
                  </select>
                </th>
                <th v-if="item.ordertype_id == 310"></th>
              </tr>
            </thead>
            <tbody class="w-100">
              <tr
                v-for="(data, index) in this.rows"
                :key="index"
                :class="getOrderrowClass(data)"
                :data-row-id="data.id"
              >
                <td v-if="!item.completed">
                  <div class="btn-group">
                    <button
                      :disabled="rows.find((dt) => dt.editting == true) != null"
                      v-if="
                        !data.editting &&
                          data['product_id'] > 0 &&
                          !item.completed
                      "
                      @click="deleteRow(data.id)"
                      class="btn p-2 btn-danger btn-sm"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      :disabled="rows.find((dt) => dt.editting == true) != null"
                      v-if="!data.editting && !item.completed"
                      @click="editRow(data.id)"
                      class="btn p-1 btn-primary btn-sm"
                    >
                      <i class="fa fa-edit"></i>
                    </button>

                    <button
                      v-if="data.editting && !item.completed"
                      @click="cancelRowEdit()"
                      class="btn p-2 btn-warning btn-sm"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                    <button
                      v-if="data.editting && !item.completed"
                      @click="updateRow(data)"
                      class="btn p-1 btn-success btn-sm"
                    >
                      <i class="fa fa-check"></i>
                    </button>
                  </div>
                  <div>
                    <button
                      v-if="
                        index > 0 && data['product_id'] > 0 && !item.completed
                      "
                      @click="moveUp(index)"
                      class="btn p-1 btn-warning btn-sm"
                    >
                      <i class="fa fa-chevron-up"></i>
                    </button>
                    <button
                      v-if="
                        index < rows.length - 1 &&
                          data['product_id'] > 0 &&
                          !item.completed
                      "
                      @click="moveDown(index)"
                      class="btn p-1 btn-warning btn-sm"
                    >
                      <i class="fa fa-chevron-down"></i>
                    </button>
                  </div>
                </td>
                <td v-if="item.ordertype_id == 120">
                  <div class="form-group d-flex">
                    <input
                      style="width: 14px"
                      v-model="data['backorder']"
                      type="checkbox"
                      class="form-control m-0 mr-2"
                      :id="'backorder' + index"
                    />
                  </div>
                </td>
                <td
                  v-for="(attr, ind) in ordertype.settings.orderrow.length > 0
                    ? ordertype.settings.orderrow
                    : []"
                  :key="ind"
                  :class="{ 'text-right': attr == 'price' }"
                >
                  <input
                    v-if="attr == 'amount' && data['product_id'] > 0"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <span
                    v-else-if="attr == 'amount' && data['product_id'] == 0"
                  ></span>

                  <input
                    v-else-if="attr == 'product_id' && data[attr] > 0"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="text"
                    class="form-control"
                    :placeholder="$t('leffit.orders.orderrow.description')"
                    id="order_row_description"
                    v-model="data.product.name"
                  />
                  <span v-else-if="attr == 'product_id' && data[attr] == 0">{{
                    $t("leffit.products.pricelevel." + data["description"])
                  }}</span>

                  <input
                    v-else-if="attr == 'pricePer'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <input
                    v-else-if="attr == 'purchase_price'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <input
                    v-else-if="attr == 'discount'"
                    @keyup="updatePercByPrice(data)"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <input
                    v-else-if="attr == 'discountPercentage'"
                    @keyup="updatePriceByPerc(data)"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data.discount_percentage"
                  />

                  <span v-else-if="attr == 'vat'">{{ data.vat }}</span>
                  <span v-else-if="attr == 'remarks'">
                    <i
                      style="cursor: pointer"
                      class="fas fa-info-circle"
                      :class="[
                        data.remarks != null ? 'fa-info-circle' : 'fa-plus',
                      ]"
                      @click="orderrowRemarks(data, index)"
                    >
                    </i>
                  </span>
                  <span v-else-if="attr == 'supplier_product_identifier'">{{
                    data.supplier_product_identifier
                  }}</span>
                  <span v-else-if="attr == 'delivery_time'">{{
                    data.delivery_time
                  }}</span>
                  <span v-else-if="attr == 'price'">
                    <span v-if="data.description == 'purchase_exchange_rate'">
                      {{ data.price }}
                    </span>
                    <div
                      class="form-row"
                      v-else-if="data.description == 'purchase_price'"
                    >
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            v-html="item.organization.currency.sign"
                          ></div>
                        </div>
                        <input
                          @keyup="updatePurchasePrice(data)"
                          :disabled="!data.editting"
                          type="number"
                          class="form-control"
                          v-model="data.price"
                        />
                      </div>
                    </div>
                    <span v-else>
                      {{ price(data.price) }}
                    </span>
                  </span>

                  <div class="input-group" v-else-if="attr == 'purchase_cost'">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-calculator"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      id="order_row_purchase_cost"
                      v-model="data.purchase_cost"
                      :disabled="!data.editting"
                    />
                  </div>

                  <input
                    v-else-if="
                      attr == 'expectedArrival' && data['product_id'] > 0
                    "
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="date"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <div v-else-if="attr == 'purchase_price_currency_id'">€</div>

                  <div v-else></div>
                </td>
                <td v-if="item.ordertype_id == 310">{{ data.stocklevel }}</td>
              </tr>
              <tr v-if="ordertype.settings.orderdetails.prices">
                <td :colspan="ordertype.settings.orderrow.length - 1"></td>
                <td>{{ $t("leffit.orders.order.subtotal") }}</td>
                <td v-html="price(subtotal)" class="text-right">&euro;</td>
              </tr>
              <tr
                :key="k"
                v-for="(v, k) in ordertype.settings.orderdetails.prices
                  ? vat
                  : []"
              >
                <td :colspan="ordertype.settings.orderrow.length - 1"></td>
                <td>
                  {{ $t("leffit.orders.orderrow.vat") }}
                  <span v-html="k"></span>%
                </td>
                <td v-html="price(vatTotal)" class="text-right"></td>
              </tr>
              <tr v-if="ordertype.settings.orderdetails.prices">
                <td :colspan="ordertype.settings.orderrow.length - 1"></td>
                <td>{{ $t("leffit.orders.order.total") }}</td>
                <td v-html="price(total)" class="text-right"></td>
              </tr>
            </tbody>
            <tfoot v-if="ordertype.settings.orderdetails.purchase_costs">
              <tr v-if="ordertype.settings.orderdetails.prices">
                <td v-if="!item.completed"></td>
                <td :colspan="ordertype.settings.orderrow.length">
                  Inkoopkosten
                </td>
              </tr>
              <tr>
                <th v-if="!item.completed">
                  <a
                    v-if="!item.completed"
                    @click="createCostRow()"
                    class="btn btn-primary"
                    ><i class="fa fa-plus"></i
                  ></a>
                </th>
                <th
                  v-for="(attr, index) in ordertype.settings.orderrow.length > 0
                    ? ordertype.settings.orderrow
                    : []"
                  :key="'odr' + index"
                >
                  <input
                    v-if="attr == 'amount'"
                    @change="updatePrice()"
                    @keyup="updatePrice()"
                    type="number"
                    :placeholder="$t('leffit.orders.orderrow.amount')"
                    class="form-control"
                    id="order_row_amount"
                    v-model="row.amount"
                  />

                  <input
                    v-if="attr == 'product_id'"
                    class="form-control"
                    id="cost_name"
                    type="number"
                  />
                  <input
                    v-if="attr == 'purchase_price'"
                    type="number"
                    class="form-control"
                    id="order_row_purchase_price"
                    v-model="row.purchase_price"
                  />

                  <select
                    v-if="attr == 'purchase_price_currency_id'"
                    style="position: relative; width: 100%"
                    class="form-control purchase_price_currency_id"
                    id="order_purchase_price_currency_id"
                  >
                    <option value="">
                      {{
                        $t("leffit.orders.orderrow.purchase_price_currency_id")
                      }}
                    </option>
                    <option
                      :key="'ppc' + index"
                      v-for="(pr, index) in purchase_price_currencies"
                      :value="pr.id"
                      v-html="pr.sign"
                    ></option>
                  </select>

                  <select
                    v-else-if="attr == 'purchase_cost'"
                    class="form-control"
                    id="procurement_purchase_cost"
                  >
                    <option value="amount">
                      {{ $t("leffit.orders.orderrow.amount") }}
                    </option>
                    <option value="price">
                      {{ $t("leffit.orders.orderrow.price") }}
                    </option>
                    <option value="weight">
                      {{ $t("leffit.products.product.weight") }}
                    </option>
                  </select>
                </th>
              </tr>
              <tr v-if="ordertype.settings.orderdetails.prices">
                <td v-if="!item.completed"></td>
                <td :colspan="ordertype.settings.orderrow.length - 2"></td>
                <td>{{ $t("leffit.orders.order.subtotal") }}</td>
                <td v-html="price(subtotal)" class="text-right">&euro;</td>
              </tr>
            </tfoot>
            <tfoot
              v-if="
                item.ordercosts &&
                  !hide_total_section_for.find((d) => item.ordertype_id == d)
              "
            >
              <tr
                v-for="(data, index) in item.ordercosts"
                :key="'ordercost_' + index"
              >
                <td v-if="!item.completed">
                  <div class="btn-group">
                    <button
                      :disabled="
                        item.ordercosts.find((dt) => dt.editting == true) !=
                          null
                      "
                      v-if="!data.editting && !item.completed"
                      @click="editOrderCostRow(data.id)"
                      class="btn p-1 btn-primary btn-sm"
                    >
                      <i class="fa fa-edit"></i>
                    </button>

                    <button
                      v-if="data.editting && !item.completed"
                      @click="cancelOrderCostRowEdit()"
                      class="btn p-2 btn-warning btn-sm"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                    <button
                      v-if="data.editting && !item.completed"
                      @click="updateOrderCostRow(data)"
                      class="btn p-1 btn-success btn-sm"
                    >
                      <i class="fa fa-check"></i>
                    </button>
                  </div>
                </td>
                <td
                  v-for="(attr, index) in ordertype.settings.orderrow.length > 0
                    ? ordertype.settings.orderrow
                    : []"
                  :key="index"
                  :class="{ 'text-right': attr == 'price' }"
                >
                  <span v-if="attr == 'product_id' && data[attr] == 0">
                    {{
                      $t("leffit.products.pricelevel." + data["description"])
                    }}
                  </span>

                  <input
                    v-else-if="attr == 'pricePer'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <input
                    v-else-if="attr == 'vat'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <input
                    v-else-if="attr == 'price'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <div class="input-group" v-else-if="attr == 'purchase_cost'">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-calculator"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      id="order_row_purchase_cost"
                      v-model="data.purchase_cost"
                      :disabled="!data.editting"
                    />
                  </div>

                  <input
                    v-else-if="attr == 'price'"
                    @keyup="updateOverall()"
                    :disabled="!data.editting"
                    type="number"
                    class="form-control"
                    v-model="data[attr]"
                  />

                  <div v-else-if="attr == 'purchase_price_currency_id'">€</div>
                  <div v-else></div>
                </td>
              </tr>

              <tr v-if="ordertype.settings.orderdetails.prices">
                <td v-if="!item.completed"></td>
                <td :colspan="ordertype.settings.orderrow.length - 2"></td>
                <td>{{ $t("leffit.orders.order.subtotal") }}</td>
                <td v-html="price(subtotal)" class="text-right">&euro;</td>
              </tr>
              <tr
                :key="k"
                v-for="(v, k) in ordertype.settings.orderdetails.prices
                  ? vat
                  : []"
              >
                <td v-if="!item.completed"></td>
                <td :colspan="ordertype.settings.orderrow.length - 2"></td>
                <td>
                  {{ $t("leffit.orders.orderrow.vat") }}
                  <span v-html="k"></span>%
                </td>
                <td v-html="price(vatTotal)" class="text-right"></td>
              </tr>

              <tr v-if="ordertype.settings.orderdetails.prices">
                <td v-if="!item.completed"></td>
                <td :colspan="ordertype.settings.orderrow.length - 2"></td>
                <td>{{ $t("leffit.orders.order.total") }}</td>
                <td v-html="price(total)" class="text-right"></td>
              </tr>
            </tfoot>
          </table>

          <!-- ORDERROW REMARKS MODAL -->

          <div
            class="modal fade"
            id="updateOrderrowRemarksModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="updateOrderrowRemarksModal"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div
                class="modal-content"
                style="min-width: 800px; min-height: 400px"
              >
                <div class="modal-header">
                  <h4 class="modal-title font-weight-bold">
                    {{ $t("leffit.orders.order.remarks") }}
                  </h4>
                  <button
                    type="button"
                    style="color: #fff"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <editor
                    v-model="edit_remark.content"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help',
                    }"
                  />

                  <div class="form-group">
                    <button
                      @click="updateOrderrowRemarks"
                      class="btn btn-success"
                    >
                      <span>UPDATE</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row"> 
                        <div class="col-12"><h5>Shipment Row</h5></div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Method</th> 
                                    <th>Cost</th> 
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="form-group">
                                            <input type="text" :disabled="true" v-model="shipment_row.amount">
                                        </div>
                                    </td>
                                    <td style="width:240px">
                                        <div class="form-group">
                                            <Select2 @change="sortCost()" class="form-control" v-model="shipment_row.shipping_method_id" :options="methods.map((d)=>{ return {id:d.id,text:d.name};})"></Select2>
                                        </div>
                                    </td>
                                    <td style="width:240px">
                                        <div class="form-group">
                                            <Select2 class="form-control" v-model="shipment_row.shipping_cost_id" :options="costs.map((d)=>{ return {id:d.id,text:d.name};})"></Select2>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input type="text" :disabled="true" v-model="shipment_row.cost">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <div class="col-12">
                                        <button @click="saveShipmentRow()" class="btn btn-success">UPDATE</button>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import country from "../../../data/country";
import processSteps from "../../../layout/components/process_steps";
import axios from "axios";

export default {
  name: "orderView",
  components: { processSteps },
  props: ["ordertype_id"],
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      update_costs: true,
      item: {
        firstname: "",
        companyname: "",
        lastname: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "",
        email: "",
        remarks: "",
        use_shipping_address: 0,
        ship_companyname: "",
        ship_firstname: "",
        ship_lastname: "",
        ship_address: "",
        ship_address2: "",
        ship_zipcode: "",
        ship_city: "",
        ship_country: "",
      },
      row: {
        order_id: "",
        product_id: 0,
        description: "",
        amount: 1,
        pricePer: 0,
        discount: 0,
        discount_percentage: 0,
        price: "",
        vat: "",
        completed: "",
        expectedArrival: "",
        payment_id: "",
        remarks: "",
        purchase_cost: 0,
        method: 0,
        backorder: false,
      },
      backRow: {},
      editOrder: false,
      editShipping: false,
      editRemark: false,
      editOrderDetails: false,
      editOrderRow: false,
      profileImageSet: false,
      filters: [],
      filters2: [],
      types: [],
      pagedata: [],
      search: {},
      rows: [],
      hide_total_section_for: [25, 30, 50],
      show_currency_section: [20, 25, 30, 40, 50, 60],
      country: country,
      organizations: [],
      contacts: [],
      products_loading: true,
      products: [],
      orderstatuses: [],
      price_excl_vat: process.env.VUE_APP_LEFFIT_PRODUCTS_PRICE_EXCL_VAT,
      vat: 0,
      vatTotal: 0,
      total: 0,
      subtotal: 0,
      ordertype: {
        settings: {
          relationdetails: false,
          shippingdetails: false,
          orderdetails: [],
          buttons: [],
          orderrow: [],
          orderrow_extra: [],
        },
      },
      ordertype_name: "",
      order_url: "",
      purchase_price_currencies: [],
      attributes: {},
      methods: [],
      costs: [],
      shipment_row: {},
      warehouses: [],
      refreshing: false,
      edit_remark: {
        content: "",
        id: null,
        index: null,
      },
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    nextStep: function() {
      if (
        this.item.hasOwnProperty("id") &&
        this.ordertype.settings.hasOwnProperty("next_type")
      ) {
        if (this.ordertype.hasOwnProperty("next_ordertype")) {
          var url = "";
          // if(this.ordertype.settings.next_type == 25) url = '/admin/sales/orders/sales_order/create/'+this;
          if (this.ordertype.next_ordertype) {
            return {
              name: this.$t(this.ordertype.next_ordertype.name),
              // url     :  url,
              endpoint:
                "/leffit/orders/orders/succeed/" +
                this.item.id +
                "/next/" +
                this.ordertype.settings.next_type,
            };
          }
        }
      }

      return {
        name: "",
        endpoint: "",
      };
    },
  },
  created() {
    // var tmp_ordertypes = [];
    // var tmp_ordertypes_sales = process.env.VUE_APP_LEFFIT_SALES_ORDERTYPES.split(',');
    // var tmp_ordertypes_procurement = process.env.VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES.split(',');

    // tmp_ordertypes_sales.forEach(function(item){
    //     item = item.split('|');
    //     tmp_ordertypes[parseInt(item[1])] = [];
    //     tmp_ordertypes[parseInt(item[1])]['name'] = item[0];
    //     tmp_ordertypes[parseInt(item[1])]['type'] = 'sales';
    // });
    // tmp_ordertypes_procurement.forEach(function(item){
    //     item = item.split('|');
    //     tmp_ordertypes[parseInt(item[1])] = [];
    //     tmp_ordertypes[parseInt(item[1])] = item[0];
    //     tmp_ordertypes[parseInt(item[1])]['type'] = 'procurement';
    // });

    this.$store.dispatch("get", "/leffit/products/warehouses").then((data) => {
      console.log(data);
      this.warehouses = data.data.data;
    });
  },
  methods: {
    downloadPackageslipData(url) {
      axios
        .get(url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          const type = result.headers["content-type"];
          const blob = new File([result.data], {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "orders.pdf";
          link.click();
        })
        .catch((error) => {
          console.log(error.request);
          if (error.request.status == 500) {
            this.$store.dispatch("makeNotification", {
              title: "Server error",
              text: "Unable to export server error occured!",
              type: "danger",
            });
          }
        });
    },
    orderrowRemarks(data, index) {
      this.edit_remark.content = data.remarks == null ? "" : data.remarks;
      this.edit_remark.id = data.id;
      this.edit_remark.index = index;
      window.$("#updateOrderrowRemarksModal").modal("show");
    },
    updateOrderrowRemarks() {
      this.$store
        .dispatch("put", {
          endpoint:
            "/leffit/orders/edit/orderrow/" + this.edit_remark.id + "/remarks",
          details: { remarks: this.edit_remark.content },
        })
        .then((data) => {
          this.rows[this.edit_remark.index] = data.data.data;
          this.$store.dispatch("makeNotification", {
            title: "Orderrow updated",
            text: "orderrow was updated successfully",
            type: "success",
          });
          window.$("#updateOrderrowRemarksModal").modal("hide");
        })
        .catch((error) => {
          error;
          window.$("#updateOrderrowRemarksModal").modal("hide");
        });
    },
    async refreshCalculation() {
      // NEW METHOD
      this.refreshing = true;
      let num_products_above_8 = 0;
      let products_total_weight = 0;
      let total_order_value = 0;

      let order = await this.$store.dispatch(
        "get",
        "/leffit/orders/orders/" + this.$route.params.id
      );

      let orderrows = order.data.data.orderrows;

      orderrows.forEach((orderrow) => {
        if (orderrow.product.silver_weight > 8) {
          num_products_above_8 += orderrow.amount;
        }
        products_total_weight =
          products_total_weight + orderrow.amount * orderrow.product.weight;
        total_order_value = total_order_value + orderrow.price;
      });

      // determine cost calculation factors
      let purchase_waarborgkosten_total = this.item.ordercosts.find(
        (item) => item.description == "purchase_waarborgkosten_total"
      );
      let purchase_shippingcosts_total = this.item.ordercosts.find(
        (item) => item.description == "purchase_shippingcosts_total"
      );
      let waarborgkosten_per_piece =
        purchase_waarborgkosten_total.price / num_products_above_8;
      let shippingcosts_per_gram =
        purchase_shippingcosts_total.price / products_total_weight;

      let purchase_import_costs_total = this.item.ordercosts.find(
        (d) => d.description == "purchase_import_costs_total"
      );

      let costRows = [];

      this.rows = this.rows.map((row) => {
        let parentOrderrow =
          row.product_id != 0
            ? null
            : orderrows.find((item) => item.id == row.parent_orderrow_id);

        // setting "purchase_waarborgkosten"
        //if (row.product) {
        if (row.description == "purchase_waarborgkosten") {
          if (parentOrderrow.product.silver_weight > 8) {
            row.amount = parentOrderrow.amount;
            row.pricePer = waarborgkosten_per_piece;
            row.price = parentOrderrow.amount * waarborgkosten_per_piece;
          }
        }
        //}

        if (row.description == "purchase_shipping_costs") {
          row.amount = 1;
          row.pricePer = shippingcosts_per_gram;
          row.price =
            parentOrderrow.amount *
            parentOrderrow.product.weight *
            shippingcosts_per_gram;
          //let orderrowshippingcosts = purchase_shipping_costs.price;
        }

        if (row.description == "purchase_import_costs") {
          row.amount = 1;
          row.pricePer = 0;
          row.price =
            total_order_value == 0
              ? 0
              : (parentOrderrow.price / total_order_value) *
                purchase_import_costs_total.price;
        }

        if (row.product_id == 0) {
          let data = {};
          data["amount"] = row.amount;
          data["pricePer"] = row.pricePer;
          data["price"] = row.price;
          let r = {};
          r[row.id] = data;

          costRows.push(r);
        }

        return row;
      });

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/orders/edit/orderrow/cost",
          details: costRows,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "Cost Calculation",
            text: "Cost calculation done",
            type: "success",
          });
        });

      /* mainOrderrows.forEach((orderrow) => {
        // set purchase_waarborgkosten
        if (orderrow.product.silver_weight > 8) {
          let purchase_waarborgkosten = orderrows.find(
            (r) =>
              r.parent_orderrow_id == orderrow.id &&
              r.description == "purchase_waarborgkosten"
          );
          purchase_waarborgkosten.amount = orderrow.amount;
          purchase_waarborgkosten.pricePer = waarborgkosten_per_piece;
          purchase_waarborgkosten.price =
            orderrow.amount * waarborgkosten_per_piece;
        }

        // set purchase_shipping_costs
        let purchase_shipping_costs = orderrows.find(
          (r) =>
            r.parent_orderrow_id == orderrow.id &&
            r.description == "purchase_shippingcosts"
        );

        purchase_shipping_costs.amount = 1;
        purchase_shipping_costs.pricePer = shippingcosts_per_gram;
        purchase_shipping_costs.price =
          orderrow.amount * orderrow.product.weight * shippingcosts_per_gram;
        let orderrowshippingcosts = purchase_shipping_costs.price;

        // set purchase_importcosts
        let purchase_importcosts = orderrows.find(
          (r) =>
            r.parent_orderrow_id == orderrow.id &&
            r.description == "purchase_importcosts"
        );

        purchase_importcosts.amount = 1;
        purchase_importcosts.pricePer = 0;
        purchase_importcosts.price =
          (orderrow.price / total_import_costs_value) *
          purchase_import_costs_total;
      });
 */
      // END NEW METHOD
      /* this.refreshing = true;
      var NumberOfIndividualProducts = 0;
      var purchase_keurloon_total = 0;
      var TotalWeightOfProducts = 0;
      var TotalValueOfProducts = 0;

      // Fetch Orderrows
      // var result_ordr = await this.$store.dispatch('get','/leffit/orders/orderrows');
      // if(result_ordr.status != 200) return false;

      // Fetch Purchase Keurloon
      var result_pk = await this.$store.dispatch(
        "get",
        "/lefficient/leffit/PurchaseKeurloon"
      );
      if (result_pk.status != 200) return false;

      // var orderrows = result_ordr.data.data;
      //orderrows = [...this.rows];
      var pks = result_pk.data;

      //  Suming NumberOfIndividualProducts
      orderrows.forEach((row) => {
        if (row.product) {
          if (parseFloat(row.product.silver_weight) > 8) {
            NumberOfIndividualProducts += row.amount;
            TotalWeightOfProducts +=
              parseFloat(row.amount) * parseFloat(row.product.weight);
          }
        }
        TotalValueOfProducts += row.price;
      });

      var pkp = pks.find(
        (pk) =>
          pk.min_amount <= NumberOfIndividualProducts &&
          pk.max_amount >= NumberOfIndividualProducts
      );
      if (!pkp) {
        var largest = 0;
        pks.forEach((element) => {
          if (element.min_amount > largest.min_amount) largest = element;
        });
        pkp = largest;
      }
      if (!pkp) return false;
      purchase_keurloon_total = pkp.price_per;

      // setting "purchase_keurloon_total"
      this.item.ordercosts = this.item.ordercosts.map((cost) => {
        if (cost.description == "purchase_keurloon_total")
          cost.price = purchase_keurloon_total;
        return cost;
      });

      // Set purchaseKeurloonCostPerPiece
      var purchaseKeurloonCostPerPiece =
        purchase_keurloon_total / NumberOfIndividualProducts;
      // Set purchaseBehandelingskostenPerPiece
      var purchase_behandelingskosten = this.item.ordercosts.find(
        (d) => d.description == "purchase_behandelingskosten_total"
      );
      var purchase_behandelingskosten_total = 0;
      if (purchase_behandelingskosten)
        purchase_behandelingskosten_total = purchase_behandelingskosten.price;
      var purchaseBehandelingskostenPerPiece =
        purchase_behandelingskosten_total / NumberOfIndividualProducts;
      // Set purchaseVrachtkostenPerPiece
      var purchase_vrachtkosten = this.item.ordercosts.find(
        (d) => d.description == "purchase_vrachtkosten_total"
      );
      var purchase_vrachtkosten_total = 0;
      if (purchase_vrachtkosten)
        purchase_vrachtkosten_total = purchase_vrachtkosten.price;
      var purchaseVrachtkostenPerPiece =
        purchase_vrachtkosten_total / NumberOfIndividualProducts;

      var purchaseShippingCostsPerPiece =
        TotalWeightOfProducts / NumberOfIndividualProducts;
      var purchaseImportCostsPerPiece =
        TotalValueOfProducts / NumberOfIndividualProducts;

      this.rows = this.rows.map((cost) => {
        if (cost.orderrow_product) {
          if (parseInt(cost.orderrow_product.silver_weight) > 8) {
            // setting "purchase_keurloon"
            if (cost.description == "purchase_keurloon")
              cost.price = cost.orderrow_amount * purchaseKeurloonCostPerPiece;
            // setting "purchase_behandelingskosten"
            if (cost.description == "purchase_behandelingskosten")
              cost.price =
                cost.orderrow_amount * purchaseBehandelingskostenPerPiece;
            // setting "purchase_vrachtkosten"
            if (cost.description == "purchase_vrachtkosten")
              cost.price = cost.orderrow_amount * purchaseVrachtkostenPerPiece;
            console.log(cost.price);
          }
        }
        // setting "purchase_shippingcosts"
        if (cost.description == "purchase_shippingcosts")
          cost.price = cost.orderrow_amount * purchaseShippingCostsPerPiece;
        // setting "purchase_import_costs"
        if (cost.description == "purchase_import_costs")
          cost.price = cost.orderrow_amount * purchaseImportCostsPerPiece;

        return cost;
      });

      console.log(this.rows);
*/
      this.refreshing = false;
    },
    getOrderrowClass(data) {
      if (data.product_id == 0) {
        this.update_costs = true;
        return (
          "hidden orderrowchild_" +
          data.parent_orderrow_id +
          " " +
          data.description
        );
      } else {
        return "orderrow_" + data.id;
      }
    },
    removeFiles(id, index) {
      // this.$store.dispatch('delete','/leffit/orders/orders/'+this.item.id)
      // .then((data)=>{
      //     console.log(data);
      // })
      // .catch((error)=>{
      //     console.log(error);
      // });
      // this.item.images=this.item.images.filter((d,indx)=> indx != index);
    },
    uploadFile() {
      var file = document.getElementById("file");
      if (file.files.length == 0) {
        return false;
      }
      var form = new FormData();
      form.append("image", file.files[0]);
      this.$store
        .dispatch("post", {
          details: form,
          endpoint: "/leffit/orders/add-order-image/" + this.item.id,
        })
        .then((data) => {
          console.log(data);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortCost() {
      var data = this.methods.find(
        (d) => d.id == this.shipment_row.shipping_method_id
      );
      if (data) {
        this.costs = data.costs;
      }
    },
    saveShipmentRow() {
      var ajax_type = "post";
      var endpoint = "/leffit/shipping/shipments";

      if (this.shipment_row.id) {
        ajax_type = "put";
        endpoint = "/leffit/shipping/shipments/" + this.shipment_row.id;
      }

      this.shipment_row.shipping_status_id = 0;
      this.$store
        .dispatch(ajax_type, {
          endpoint: endpoint,
          details: this.shipment_row,
        })
        .then((data) => {
          console.log(data);

          var cost = this.costs.find(
            (d) => d.id == this.shipment_row.shipping_cost_id
          );

          // this.row=this.shipment_row;
          this.row.shipment_id = data.data.data.id;
          this.row.product_id = 0;
          this.row.description = cost
            ? cost.name + " " + cost.amount
            : "Description";
          this.row.amount = 1;
          this.row.pricePer = cost ? cost.amount : 0;
          this.row.price = cost ? cost.amount : 0;
          this.row.vat = 0;
          this.shipment_row.cost = this.row.price;

          if (!this.shipment_row.id) {
            this.createRow();
          } else {
            this.row.product_id = this.item.shipmentrow.product_id;
            this.row.id = this.item.shipmentrow.id;
            this.updateRow(this.row);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    colspanOrderrow(attr) {
      if (attr == "discount") {
        return 2;
      }
    },
    calc_vat() {
      var vat = [];
      var price_excl_vat = this.price_excl_vat;
      this.rows.forEach(function(item) {
        item.vat = parseInt(item.vat);
        var value;
        if (price_excl_vat) {
          value = (item.vat / 100) * item.price;
        } else {
          value = (item.vat / (100 + item.vat)) * item.price;
        }

        if (vat.hasOwnProperty(item.vat)) {
          vat[item.vat] += value;
        } else {
          vat[item.vat] = value;
        }
      });

      this.vat = vat;
    },
    calc_vatTotal() {
      var vatTotal = 0;
      if (this.vat) {
        this.vat.forEach(function(v, k) {
          if (v > 0) {
            vatTotal += v;
          }
        });
      }
      this.vatTotal = vatTotal;
    },
    calc_total() {
      this.total = this.subtotal + this.vatTotal;
    },
    calc_subtotal() {
      var price_excl_vat = this.price_excl_vat;
      var subtotal = 0;
      this.rows.forEach(function(item) {
        subtotal += parseFloat(item.price);
      });
      if (!price_excl_vat) {
        return subtotal - this.vatTotal;
      }
      this.subtotal = subtotal;
    },
    rowPrice(data) {
      data.discount = data.discount != null ? data.discount : 0;
      return data.amount * data.pricePer - data.discount;
    },
    moveUp(index) {},
    moveDown(index) {},
    price(number) {
      if (isNaN(number)) return 0;
      const formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });

      number = parseFloat(number);
      // return number;
      return formatter.format(number);
    },
    populateContact(id) {
      var data = this.contacts.find((d) => d.id == id);
      this.item.firstname = data.firstname;
      this.item.lastname = data.lastname;
      this.item.address = data.address;
      this.item.address2 = data.address2;
      this.item.email = data.email;
      this.item.city = data.city;
      this.item.zipcode = data.zipcode;

      window
        .$(".country")
        .val(data.country)
        .select2();
    },
    populateShipContact(id) {
      var data = this.contacts.find((d) => d.id == id);
      this.item.ship_firstname = data.firstname;
      this.item.ship_lastname = data.lastname;
      this.item.ship_address = data.address;
      this.item.ship_address2 = data.address2;
      this.item.ship_email = data.email;
      this.item.ship_city = data.city;
      this.item.ship_zipcode = data.zipcode;

      window
        .$(".country2")
        .val(data.country)
        .select2();
    },
    populateOrganization(id) {
      var data = this.organizations.find((d) => d.id == id);
      this.item.companyname = data.name;
      this.item.address = data.address;
      this.item.address2 = data.address2;
      this.item.email = data.email;
      this.item.city = data.city;

      // window.$('.country').val(data.country).select2();
    },
    populateShipOrganization(id) {
      var data = this.organizations.find((d) => d.id == id);
      this.item.ship_companyname = data.name;
      this.item.ship_address = data.address;
      this.item.ship_address2 = data.address2;
      this.item.ship_email = data.email;
      this.item.ship_city = data.city;

      window
        .$(".country2")
        .val(data.country)
        .select2();
    },
    toggleEditOrder() {
      this.editOrder = !this.editOrder;
      if (!this.editOrder) {
        this.updateItem();
      }
    },
    toggleEditShip() {
      this.editShipping = !this.editShipping;
      if (!this.editShipping) {
        this.updateItem();
      }
    },
    toggleEditRemark() {
      this.editRemark = !this.editRemark;
      if (this.editRemark) {
        setTimeout(() => {
          window.tinymce.init({
            selector: "textarea", // change this value according to your HTML
            plugin: "a_tinymce_plugin",
            a_plugin_option: true,
            a_configuration_option: 400,
          });
        }, 0);
      } else {
        this.item.remarks = window.tinyMCE.get("order_remarks").getContent();
        this.updateItem();
        window.tinyMCE.get("order_remarks").destroy();
      }
    },
    toggleEditOrderDetails() {
      this.editOrderDetails = !this.editOrderDetails;
      if (!this.editOrderDetails) {
        this.getOrderStatus();
        this.updateItem();
      }
    },
    updateItem() {
      this.$store
        .dispatch("put", {
          endpoint: "/leffit/orders/orders/" + this.item.id,
          details: this.item,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "Order updated",
            text: "order was updated successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    createRow() {
      this.row.order_id = this.item.id;
      var payload = { ...this.row };
      payload.price = parseInt(this.row.amount) * this.row.staticPricePer;
      payload.pricePer = this.row.staticPricePer;
      payload.backorder = this.row.backorder ? 1 : 0;

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/orders/orderrows",
          details: payload,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "Orderitem Created",
            text: "orderitem was added successfully",
            type: "success",
          });
          let storedRow = null;
          if (this.row.shipment_id == 0) {
            storedRow = data.data.data;
            this.rows.unshift(storedRow);
          }

          window.$("#closemodal").click();
          this.getOrder();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    deleteRow(id) {
      this.$store
        .dispatch("delete", "/leffit/orders/orderrows/" + id)
        .then((data) => {
          this.getOrder();
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: "Order was removed successfully",
            type: "success",
          });
        });
    },
    editRow(id) {
      this.rows = this.rows.map((dt) => {
        if (dt.id == id) {
          this.backRow = JSON.stringify(dt);
          dt.editting = true;
        }
        return dt;
      });
    },
    editOrderCostRow(id) {
      this.item.ordercosts = this.item.ordercosts.map((dt) => {
        if (dt.id == id) {
          this.backRow = JSON.stringify(dt);
          dt.editting = true;
        }
        return dt;
      });
    },
    getOrderStatus() {
      var id = this.item.orderstatus_id;
      var tmp = this.item.orderstatus;
      this.orderstatuses.map((dt) => {
        if (dt.id == id) {
          tmp = dt.name;
        }
      });
      this.item.orderstatus = tmp;
    },
    updateRow(row) {
      this.$store
        .dispatch("put", {
          endpoint: "/leffit/orders/orderrows/" + row.id,
          details: row,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "Row Updated",
            text: "Order row was updated successfully",
            type: "success",
          });
          this.rows = this.rows.map((dt) => {
            if (dt.id == row.id) {
              var dat = data.data.data;
              dat.discount_percentage = (dat.discount / dat.pricePer) * 100;
              return dat;
            } else {
              return dt;
            }
          });

          window.$("#closemodal2").click();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    updateOrderCostRow(row) {
      this.$store
        .dispatch("put", {
          endpoint: "/leffit/orders/orderrows/" + row.id,
          details: row,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "Row Updated",
            text: "Order row was updated successfully",
            type: "success",
          });
          this.item.ordercosts = this.item.ordercosts.map((dt) => {
            if (dt.id == row.id) {
              var dat = data.data.data;
              dat.discount_percentage = (dat.discount / dat.pricePer) * 100;
              return dat;
            } else {
              return dt;
            }
          });

          window.$("#closemodal2").click();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    updatePrice() {
      console.log(this.row.discount_percentage);
      this.row.discount_percentage =
        this.row.discount_percentage == "" || this.row.discount_percentage < 0
          ? 0
          : this.row.discount_percentage;
      this.row.discount =
        this.row.discount == "" || this.row.discount < 0
          ? 0
          : this.row.discount;

      var item = this.products.find((dt) => dt.id == this.row.product_id);
      item.pricePer = parseFloat(item.price);
      if (this.row.discount != null) {
        this.row.pricePer =
          parseFloat(item.pricePer) - parseFloat(this.row.discount);
      }

      if (this.row.discount_percentage != null) {
        var perc_price = parseFloat(
          (parseFloat(this.row.discount_percentage) / 100) *
            parseFloat(item.pricePer)
        ).toFixed(2);
        this.row.pricePer = parseFloat(
          parseFloat(this.row.pricePer) - perc_price
        );
      }

      this.row.price = this.row.amount * this.row.pricePer;
      this.updateOverall();
    },
    updatePercByPrice(data) {
      data.discount_percentage =
        (data.discount / (data.amount * data.pricePer)) * 100;
      this.rows = this.rows.map((dt) => {
        if (dt.id == data.id) {
          return data;
        }
        return dt;
      });
      this.updateOverall();
    },
    updatePriceByPerc(data) {
      data.discount =
        data.amount * data.pricePer * (data.discount_percentage / 100);
      this.rows = this.rows.map((dt) => {
        if (dt.id == data.id) {
          return data;
        }
        return dt;
      });
      this.updateOverall();
    },
    updatePurchasePrice(data) {
      let rate_row = this.rows.find(
        (o) =>
          o.description == "purchase_exchange_rate" &&
          o.parent_orderrow_id == data.parent_orderrow_id
      );
      let rate = rate_row?.price;
      this.rows = this.rows.map((dt) => {
        if (dt.id == data.parent_orderrow_id) {
          dt.price = dt.amount * data.price * rate;
        }
        return dt;
      });
      this.updateOverall();
    },
    cancelRowEdit() {
      this.backRow = JSON.parse(this.backRow);
      this.rows = this.rows.map((dt) => {
        if (dt.id == this.backRow.id) {
          this.backRow.editting = false;
          return this.backRow;
        }
        return dt;
      });
      this.updateOverall();
    },
    cancelOrderCostRowEdit() {
      this.backRow = JSON.parse(this.backRow);
      this.item.ordercosts = this.item.ordercosts.map((dt) => {
        if (dt.id == this.backRow.id) {
          this.backRow.editting = false;
          return this.backRow;
        }
        return dt;
      });
      this.updateOverall();
    },
    updateOverall() {
      this.calc_vat();
      this.calc_vatTotal();
      this.calc_subtotal();
      this.calc_total();
    },
    getProducts() {
      return this.products;
    },
    calculatePrice() {
      var A = 12;
      var B = 1.1;
      var C = 1.2;

      var weight = 12;
      var costs = this.row.purchase_price * A + this.row.purchase_price * B;
      if (weight >= 8) {
        costs = costs + (weight - 8 * C);
      }

      console.log("bereken", this.row.purchase_price, costs);
      this.row.purchase_cost = costs;

      //variabele kosten (A x inkoopprijs in euro) + kosten Invoerrechten (B x inkoopprijs in euro) + waarborgkosten (C x (gewicht groter of gelijk aan 8 gram)).
    },
    updateProductDetails() {
      var id = this.row.product_id;
      var item = this.products.find((dt) => dt.id == id);
      if (item != null) {
        this.row.description = item.name;
        this.row.pricePer = item.price;
        this.row.staticPricePer = item.price;

        if (document.getElementById("order_row_amount")) {
          this.row.price =
            item.price * document.getElementById("order_row_amount").value;
        } else {
          this.row.price = 0;
        }
        this.row.vat = item.vat;
      }
    },
    getOrder() {
      this.$store
        .dispatch("get", "/leffit/orders/orders/" + this.$route.params.id)
        .then((data) => {
          this.item = data.data.data;

          this.rows = this.item.orderrows.map((dt) => {
            dt.discount_percentage = (dt.discount / dt.pricePer) * 100;
            dt.editting = false;
            return dt;
          });

          var rows = [];
          this.rows.forEach(function(row) {
            var productrows = [];
            if (row.expectedArrival) {
              row.expectedArrival = row.expectedArrival.split(" ")[0];
            }
            productrows.push(row);

            if (row.childrows) {
              // console.log(row);
              row.childrows.forEach(function(childrow) {
                if (childrow.expectedArrival) {
                  childrow.expectedArrival = childrow.expectedArrival.split(
                    " "
                  )[0];
                }

                productrows.push(childrow);
              });
            }
            rows = productrows.concat(rows);
          });

          this.rows = rows;

          this.updateOverall();
        });
    },
  },
  mounted() {
    window.tinymce.init({
      selector: "textarea", // change this value according to your HTML
      plugin: "a_tinymce_plugin",
      a_plugin_option: true,
      a_configuration_option: 400,
    });

    this.$store.dispatch("get", "/leffit/crm/organizations").then((data) => {
      this.organizations = data.data.data;
    });

    this.$store
      .dispatch("get", "/lefficient/leffit/currencies/active")
      .then((data) => {
        this.purchase_price_currencies = data.data.data;
        window.$(".order_purchase_price_currency_id").select2();
      });

    this.$store.dispatch("get", "/leffit/crm/contacts").then((data) => {
      this.contacts = data.data.data;
    });

    this.$store
      .dispatch("get", "/leffit/orders/order-statuses")
      .then((data) => {
        this.orderstatuses = data.data.data;
      });

    this.$store
      .dispatch("get", "/leffit/orders/orders/" + this.$route.params.id)
      .then((data) => {
        this.item = data.data.data;

        this.rows = this.item.orderrows.map((dt) => {
          dt.discount_percentage =
            dt.discount_percentage == 0
              ? (dt.discount / dt.pricePer) * 100
              : dt.discount_percentage;
          dt.editting = false;
          return dt;
        });

        var rows = [];
        this.rows.forEach(function(row) {
          var productrows = [];
          if (row.expectedArrival) {
            //console.log('checking', row.expectedArrival.split(' '), 'endchecking')
            row.expectedArrival = row.expectedArrival.split(" ")[0];
          }
          productrows.push(row);
          if (row.childrows) {
            row.childrows.forEach(function(childrow) {
              childrow.orderrow_amount = row.amount;
              childrow.orderrow_product = row.product;
              productrows.push(childrow);
            });
          }
          rows = productrows.concat(rows);
        });

        this.rows = rows;

        this.updateOverall();

        this.$store
          .dispatch(
            "get",
            "/leffit/orders/order-types/" + this.item.ordertype_id
          )
          .then((data) => {
            this.ordertype = data.data.data;
            let ordertypeSettings = JSON.parse(this.ordertype.settings);
            this.ordertype.settings = JSON.parse(this.ordertype.settings);

            if (ordertypeSettings.hasOwnProperty("orderrow")) {
              let ordertypeSettingOrderrow = ordertypeSettings.orderrow.filter(
                (item) => item != "backorder"
              );
              this.ordertype.settings.orderrow = ordertypeSettingOrderrow;
            }

            if (this.ordertype.is_procurement) {
              this.$store
                .dispatch(
                  "get",
                  "/leffit/products/products_supplier/" +
                    this.item.organization_id
                )
                .then((data) => {
                  this.products = data.data.data ? data.data.data : data.data;
                  this.products_loading = false;
                  var context = this;
                });
            } else {
              this.$store
                .dispatch("post", {
                  endpoint: "/leffit/products/search",
                  details: { select: true },
                })
                .then((data) => {
                  this.products = data.data.data;
                  this.products_loading = false;
                });
            }
          });
      });

    this.$store
      .dispatch("get", "/leffit/shipping/shippingmethods")
      .then((data) => {
        this.methods = data.data.data;
        if (this.item.shipmentrow != null) {
          if (this.item.shipmentrow.shipment != null) {
            var select_method = this.methods.find(
              (d) => d.id == this.item.shipmentrow.shipment.shipping_method_id
            );
            if (select_method) {
              this.costs = select_method.costs;
            }
            if (this.item.shipmentrow != null) {
              this.shipment_row.id = this.item.shipmentrow.shipment.id;
              this.shipment_row.shipping_method_id = this.item.shipmentrow.shipment.shipping_method_id;
              this.shipment_row.shipping_cost_id = this.item.shipmentrow.shipment.shipping_cost_id;
              this.shipment_row.cost = this.item.shipmentrow.price;
              this.shipment_row.amount = this.item.shipmentrow.amount;

              // console.log(this.shipment_row);
            }
          }
        }
        // console.log(this.methods);
      });
  },
};
</script>

<style lang="css" scoped>
.loading {
  animation: rolling 1s infinite;
}
@keyframes rolling {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .order_amount{
    width: 70px;
}

.order_product_id{
    width: 250px;
}

.order_vat{
    width: 60px;
}

.order_purchase_price_currency_id{
    width: 70px;
}

.order_expectedArrival{
    width: 190px;
}
.order_purchase_cost{
    width: 150px;
}

.order_purchase_price{
    width: 100px;
}

.order_price{
    width: 150px;
} */

tfoot {
  background: #ddd;
}
.subTable tbody {
  border: 1px solid lightgray !important;
}
.subTable td {
  padding: 7px 20px !important;
  font-size: 12px;
}
.subTable tr td:first-child {
  padding-left: 5px !important;
  text-align: left !important;
}
.subTable {
  /*width: 150px;*/
  margin-left: auto;
}
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.hidden {
  /*display:none;*/
}
</style>
