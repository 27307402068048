<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},

  	data () {
    	return {
    		menu : [
    			// {
    			// 	title 	: 'Create',
    			// 	url 	: '/admin/appointments/create',
    			// 	children : [],
    			// },
          {
            title   : 'Appointments',
            url   : '/admin/appointments/appointment',
            children : [],
          },
    			{
    				title 	: 'Schedules',
    				url 	: '/admin/appointments/schedules',
    				children : [],
    			},
    		],
    	}
  	}
}
</script>

<style lang="css" scoped>
</style>