<template>
	<div>
		<div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.stock.stock_locations') }}</h3>
      	</div>
		<div class="row">
			<a href="/admin/stocks/settings/stock_locations/create" class="btn btn-success btn-sm">{{ $t('leffit.products.stock.add_warehouses') }}</a>
		</div>
		<location-table></location-table>
	</div>
</template>

<script>
import table from './table';
export default {
  	name: 'productFilters',
	components:{'location-table':table},
  	data () {
    	return {
    		pagedata : [],
			attributes : [
			'name', 
			'created_at'
			],
			search : {},
    	}
  	},
    methods: {
      translate (input) {
        if(input){
          if(input.constructor === String){
            if(input.indexOf('leffit.products')!==-1){
              return this.$t(input);
            }else{
              return input;
            }
          }
        }
        
        return input;
	  },
	   deleteItem(id){
          if(!confirm('Are you sure you want to delete?')){
            return false;
          }
          this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
          console.log(this.pagedata);
          this.$store.dispatch('delete','/leffit/products/stocklocations/'+id)
          .then(data=>{
            console.log(data);
            this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Item was removed successfully',type:'success'});
          });
        }
    },
  	mounted(){
	    this.$store.dispatch('post',{endpoint:'/leffit/products/stocklocations/search',details:{search:{limit:10}}})
        .then(data=>{
        	console.log(data);
			this.pagedata = data.data.data;
		});
	},
}
</script>

<style lang="css" scoped>
</style>