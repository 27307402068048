export default  [
    {"id":"1","code":"en","name":"English"},
    {"id":"2","code":"aa","name":"Afar"},
    {"id":"3","code":"ab","name":"Abkhazian"},
    {"id":"4","code":"af","name":"Afrikaans"},
    {"id":"5","code":"am","name":"Amharic"},
    {"id":"6","code":"ar","name":"Arabic"},
    {"id":"7","code":"as","name":"Assamese"},
    {"id":"8","code":"ay","name":"Aymara"},
    {"id":"9","code":"az","name":"Azerbaijani"},
    {"id":"10","code":"ba","name":"Bashkir"},
    {"id":"11","code":"be","name":"Byelorussian"},
    {"id":"12","code":"bg","name":"Bulgarian"},
    {"id":"13","code":"bh","name":"Bihari"},
    {"id":"14","code":"bi","name":"Bislama"},
    {"id":"15","code":"bn","name":"Bengali Bangla"},
    {"id":"16","code":"bo","name":"Tibetan"},
    {"id":"17","code":"br","name":"Breton"},
    {"id":"18","code":"ca","name":"Catalan"},
    {"id":"19","code":"co","name":"Corsican"},
    {"id":"20","code":"cs","name":"Czech"},
    {"id":"21","code":"cy","name":"Welsh"},
    {"id":"22","code":"da","name":"Danish"},
    {"id":"23","code":"de","name":"German"},
    {"id":"24","code":"dz","name":"Bhutani"},
    {"id":"25","code":"el","name":"Greek"},
    {"id":"26","code":"eo","name":"Esperanto"},
    {"id":"27","code":"es","name":"Spanish"},
    {"id":"28","code":"et","name":"Estonian"},
    {"id":"29","code":"eu","name":"Basque"},
    {"id":"30","code":"fa","name":"Persian"},
    {"id":"31","code":"fi","name":"Finnish"},
    {"id":"32","code":"fj","name":"Fiji"},
    {"id":"33","code":"fo","name":"Faeroese"},
    {"id":"34","code":"fr","name":"French"},
    {"id":"35","code":"fy","name":"Frisian"},
    {"id":"36","code":"ga","name":"Irish"},
    {"id":"37","code":"gd","name":"Scots Gaelic"},
    {"id":"38","code":"gl","name":"Galician"},
    {"id":"39","code":"gn","name":"Guarani"},
    {"id":"40","code":"gu","name":"Gujarati"},
    {"id":"41","code":"ha","name":"Hausa"},
    {"id":"42","code":"hi","name":"Hindi"},
    {"id":"43","code":"hr","name":"Croatian"},
    {"id":"44","code":"hu","name":"Hungarian"},
    {"id":"45","code":"hy","name":"Armenian"},
    {"id":"46","code":"ia","name":"Interlingua"},
    {"id":"47","code":"ie","name":"Interlingue"},
    {"id":"48","code":"ik","name":"Inupiak"},
    {"id":"49","code":"in","name":"Indonesian"},
    {"id":"50","code":"is","name":"Icelandic"},
    {"id":"51","code":"it","name":"Italian"},
    {"id":"52","code":"iw","name":"Hebrew"},
    {"id":"53","code":"ja","name":"Japanese"},
    {"id":"54","code":"ji","name":"Yiddish"},
    {"id":"55","code":"jw","name":"Javanese"},
    {"id":"56","code":"ka","name":"Georgian"},
    {"id":"57","code":"kk","name":"Kazakh"},
    {"id":"58","code":"kl","name":"Greenlandic"},
    {"id":"59","code":"km","name":"Cambodian"},
    {"id":"60","code":"kn","name":"Kannada"},
    {"id":"61","code":"ko","name":"Korean"},
    {"id":"62","code":"ks","name":"Kashmiri"},
    {"id":"63","code":"ku","name":"Kurdish"},
    {"id":"64","code":"ky","name":"Kirghiz"},
    {"id":"65","code":"la","name":"Latin"},
    {"id":"66","code":"ln","name":"Lingala"},
    {"id":"67","code":"lo","name":"Laothian"},
    {"id":"68","code":"lt","name":"Lithuanian"},
    {"id":"69","code":"lv","name":"Latvian Lettish"},
    {"id":"70","code":"mg","name":"Malagasy"},
    {"id":"71","code":"mi","name":"Maori"},
    {"id":"72","code":"mk","name":"Macedonian"},
    {"id":"73","code":"ml","name":"Malayalam"},
    {"id":"74","code":"mn","name":"Mongolian"},
    {"id":"75","code":"mo","name":"Moldavian"},
    {"id":"76","code":"mr","name":"Marathi"},
    {"id":"77","code":"ms","name":"Malay"},
    {"id":"78","code":"mt","name":"Maltese"},
    {"id":"79","code":"my","name":"Burmese"},
    {"id":"80","code":"na","name":"Nauru"},
    {"id":"81","code":"ne","name":"Nepali"},
    {"id":"82","code":"nl","name":"Dutch"},
    {"id":"83","code":"no","name":"Norwegian"},
    {"id":"84","code":"oc","name":"Occitan"},
    {"id":"85","code":"om","name":"(Afan) Oromoor Oriya"},
    {"id":"86","code":"pa","name":"Punjabi"},
    {"id":"87","code":"pl","name":"Polish"},
    {"id":"88","code":"ps","name":"Pashto Pushto"},
    {"id":"89","code":"pt","name":"Portuguese"},
    {"id":"90","code":"qu","name":"Quechua"},
    {"id":"91","code":"rm","name":"Rhaeto-Romance"},
    {"id":"92","code":"rn","name":"Kirundi"},
    {"id":"93","code":"ro","name":"Romanian"},
    {"id":"94","code":"ru","name":"Russian"},
    {"id":"95","code":"rw","name":"Kinyarwanda"},
    {"id":"96","code":"sa","name":"Sanskrit"},
    {"id":"97","code":"sd","name":"Sindhi"},
    {"id":"98","code":"sg","name":"Sangro"},
    {"id":"99","code":"sh","name":"Serbo-Croatian"},
    {"id":"100","code":"si","name":"Singhalese"},
    {"id":"101","code":"sk","name":"Slovak"},
    {"id":"102","code":"sl","name":"Slovenian"},
    {"id":"103","code":"sm","name":"Samoan"},
    {"id":"104","code":"sn","name":"Shona"},
    {"id":"105","code":"so","name":"Somali"},
    {"id":"106","code":"sq","name":"Albanian"},
    {"id":"107","code":"sr","name":"Serbian"},
    {"id":"108","code":"ss","name":"Siswati"},
    {"id":"109","code":"st","name":"Sesotho"},
    {"id":"110","code":"su","name":"Sundanese"},
    {"id":"111","code":"sv","name":"Swedish"},
    {"id":"112","code":"sw","name":"Swahili"},
    {"id":"113","code":"ta","name":"Tamil"},
    {"id":"114","code":"te","name":"Tegulu"},
    {"id":"115","code":"tg","name":"Tajik"},
    {"id":"116","code":"th","name":"Thai"},
    {"id":"117","code":"ti","name":"Tigrinya"},
    {"id":"118","code":"tk","name":"Turkmen"},
    {"id":"119","code":"tl","name":"Tagalog"},
    {"id":"120","code":"tn","name":"Setswana"},
    {"id":"121","code":"to","name":"Tonga"},
    {"id":"122","code":"tr","name":"Turkish"},
    {"id":"123","code":"ts","name":"Tsonga"},
    {"id":"124","code":"tt","name":"Tatar"},
    {"id":"125","code":"tw","name":"Twi"},
    {"id":"126","code":"uk","name":"Ukrainian"},
    {"id":"127","code":"ur","name":"Urdu"},
    {"id":"128","code":"uz","name":"Uzbek"},
    {"id":"129","code":"vi","name":"Vietnamese"},
    {"id":"130","code":"vo","name":"Volapuk"},
    {"id":"131","code":"wo","name":"Wolof"},
    {"id":"132","code":"xh","name":"Xhosa"},
    {"id":"133","code":"yo","name":"Yoruba"},
    {"id":"134","code":"zh","name":"Chinese"},
    {"id":"135","code":"zu","name":"Zulu"},
    ]