<template>
    <div>
        <sidebarInner v-if="this.$route.params.module_id"/>
        <div class="head row">
            <h3 class="pl-3">{{ $t('lefficient.leffit.user.edit-user') }}</h3>
        </div>

        <div id="contain" class="row p-2 m-0">
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.display_name') }}</label>
                <input type="text" class="form-control" disabled='true' :value="user.display_name"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.email') }}</label>
                <input type="text" class="form-control" disabled='true' :value="user.email"/>
            </div>
             <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.firstname') }}</label>
                <input type="text" class="form-control" v-model="user.firstname"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.lastname') }}</label>
                <input type="text" class="form-control" v-model="user.lastname"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.phone') }}</label>
                <input type="text" class="form-control" v-model="user.phone"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.mobile') }}</label>
                <input type="text" class="form-control" v-model="user.mobile"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.country') }}</label>
                <input type="text" class="form-control" v-model="user.country"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.city') }}</label>
                <input type="text" class="form-control" v-model="user.city"/>
            </div>
             <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.date_of_birth') }}</label>
                <input type="date" class="form-control" v-model="user.date_of_birth"/>
            </div>
             <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.gender') }}</label>
                <select v-model="user.gender" name="" id="" class="form-control">
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                </select>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.address') }}</label>
                <input type="text" class="form-control" v-model="user.address"/>
            </div>
             <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.address2') }}</label>
                <input type="text" class="form-control" v-model="user.address2"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.zipcode') }}</label>
                <input type="text" class="form-control" v-model="user.zipcode"/>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="" class="d-block text-left">{{ $t('lefficient.leffit.user.website') }}</label>
                <input type="text" class="form-control" v-model="user.website"/>
            </div>

            <div class="form-group col-12">
                <button @click="updateProfile()" class="btn btn-success">{{ $t('lefficient.leffit.user.update') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import sidebarInner from './../layout/components/sidebar_inner';
import { mapState } from 'vuex';
export default {
    name:'edit_user',
    components:{
        sidebarInner,
    },
    computed:{
        ...mapState({
            user:state=>state.leffit.user,
            endpoint:state=>state.leffit.endpoint
        })
    },
    methods:{
     updateProfile(){
         this.$store.dispatch('put',
            {endpoint:'/lefficient/leffit/users/'+this.user.id,
             details:this.user,
             loader:{text:'Updating profile',show:true},
             notification:{title:'Profile Update',text:'Your profile was updated.',type:'success'}})
         .then(data=>{
             console.log(data.data.data);
            this.$store.commit('setUser',data.data.data);
         }) ;
     }
    }
}
</script>
<style scoped>
     #contain{
         border:1px solid lightgray;
     }
</style>
