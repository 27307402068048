<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},

  	data () {
    	return {
    		menu : [
    			{
    				title 	: this.$t('leffit.casa.pricat.import'),
    				url 	: '/admin/casa-jewelry/pricat/import',
    				children : [],
    			},
    			{
              title   : this.$t('leffit.casa.pricat.export'),
              url     : '/admin/casa-jewelry/pricat/export',
              children : [],
          },
          {
              title   : this.$t('leffit.casa.bijenkorf_stickers'),
              url     : 'http://leffit-api.test/api/v1/lefficient/leffit/pdf/products.bijenkorf/1/nl',
              children : [],
          },
    		],
    	}
  	}
}
</script>

<style lang="css" scoped>
</style>