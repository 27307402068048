<template>
    <div>
        <h3>All currencies</h3>

        <div class=""> 
            <table class="table-responsive table">
                <thead>
                    <tr>
                        <th>Sign</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Factor</th>
                        <th>Active</th>
                    </tr>
                    <tr>
                        <th>
                            <div class="form-group">
                                <input @keyup="search()" v-model="query[0].value" type="text" class="form-control" placeholder="Sign">
                            </div>
                        </th>
                        <th>
                            <div class="form-group">
                                <input @keyup="search()" v-model="query[1].value" type="text" class="form-control" placeholder="Code">
                            </div>
                        </th>
                        <th>
                            <div class="form-group">
                                <input @keyup="search()" v-model="query[2].value" type="text" class="form-control" placeholder="Name">
                            </div>
                        </th>
                        <th>
                            <div class="form-group">
                                <input @keyup="search()" v-model="query[3].value" type="text" class="form-control" placeholder="Factor">
                            </div>
                        </th>
                        <th>
                            <div class="form-group">
                                <select @change="search()" v-model="query[4].value"  class="form-control">
                                    <option value="1">Active</option>
                                    <option value="0">Not active</option>
                                </select>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(currency,index) in list" :key="index">
                        <td v-html="currency.sign"></td> 
                        <td> {{currency.code}} </td>
                        <td> {{currency.name}} </td>
                        <td> 
                            <span v-if="currency.edit">
                                <div class="row">
                                    <div class="form-group p-0 col-8">
                                        <input v-model="currency.factor" type="number" class="form-control" />
                                    </div>
                                    <div class="form-group p-0 col-4" style="padding-right: 10px !important;">
                                        <button @click="updateCurrency(currency)" class="btn btn-success m-0 d-block" ><i class="fas fa-check"></i></button>
                                    </div>
                                </div>
                            </span>
                            <span v-else>
                                <a @click="editCurrency(currency)" href="javascript:void(0)" ><i class="fas fa-edit" style="color:grey"></i></a>
                                {{currency.factor}}
                            </span>
                        </td>
                        <td> 
                                <button 
                                    type="button" 
                                    class="btn btn-sm btn-secondary btn-toggle" 
                                    :class="currency.active ? 'active' : ''"
                                    data-toggle="button" aria-pressed="true" autocomplete="off"
                                    @click="toggleCurrencyStatus(currency)"
                                >
                                    <div class="handle"></div>
                                </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            list:[],
            query:[
                {attribute:'sign',value:''},
                {attribute:'code',value:''},
                {attribute:'name',value:''},
                {attribute:'factor',value:''},
                {attribute:'active',value:''},
            ]
        }
    },
    methods:{
        editCurrency(currency){ 
            currency.edit = !currency.edit; 
        },
        updateCurrency(currency){ 
            this.$store.dispatch('put', {
                endpoint:'/lefficient/leffit/currencies/'+currency.id,
                details:currency
            });
            currency.edit = false;
        },
        search(){ 
            this.$store.dispatch('post', {
                endpoint:'/lefficient/leffit/currencies/search',
                details:{search: this.query}
            })
            .then((data)=>{ 
                this.list = data.data.data.map((d)=>{
                    d.edit = false;
                    return d;
                });
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        toggleCurrencyStatus(currency) {
            let param = {...currency};
            param.active = (param.active == 1) ? 0 : 1;
            this.updateCurrency(param)
        }
    },
    created(){
        this.search();
    }
}
</script>

<style scoped>


 .btn-toggle > .handle {
	 position: absolute;
	 top: 0.1875rem;
	 left: 0.1875rem;
	 width: 1.125rem;
	 height: 1.125rem;
	 border-radius: 1.125rem;
	 background: #fff;
	 transition: left .25s;
}
 .btn-toggle.active {
	 transition: background-color .25s;
}
 .btn-toggle.active > .handle {
	 left: 3.525rem;
	 transition: left .25s;
}
 .btn-toggle.active:before {
	 opacity: .5;
}
 .btn-toggle.active:after {
	 opacity: 1;
}
 .btn-toggle.btn-sm:before, .btn-toggle.btn-sm:after {
	 line-height: -0.5rem;
	 color: #fff;
	 letter-spacing: .75px;
	 width: 2.25rem;
}
 .btn-toggle.btn-sm:before {
	 text-align: right;
	 left: 1.65rem;
}
 .btn-toggle.btn-sm:after {
	 text-align: left;
	 opacity: 0;
	 left: 0.4125rem;
}
 .btn-toggle.btn-sm.active:before {
	 opacity: 0;
}
 .btn-toggle.btn-sm.active:after {
	 opacity: 1;
}
 .btn-toggle:before, .btn-toggle:after {
	 color: #6b7381;
}
 .btn-toggle.active {
	 background-color: #29b5a8;
}
 .btn-toggle.btn-sm {
	 margin: 0 .5rem;
	 padding: 0;
	 position: relative;
	 border: none;
	 height: 1.5rem;
	 width: 4.8rem;
	 border-radius: 1.5rem;
}
 .btn-toggle.btn-sm:focus, .btn-toggle.btn-sm:focus.active, .btn-toggle.btn-sm.focus, .btn-toggle.btn-sm.focus.active {
	 outline: none;
}
 .btn-toggle.btn-sm:before, .btn-toggle.btn-sm:after {
	 line-height: 1.5rem;
	 width: .5rem;
	 text-align: center;
	 font-weight: 600;
	 font-size: .55rem;
	 text-transform: uppercase;
	 letter-spacing: 2px;
	 position: absolute;
	 bottom: 0;
	 transition: opacity .25s;
}
 .btn-toggle.btn-sm:before {
	 content: 'Inactive';
	 left: -0.5rem;
}
 .btn-toggle.btn-sm:after {
	 content: 'Active';
	 right: -0.5rem;
	 opacity: .5;
}
 .btn-toggle.btn-sm > .handle {
	 position: absolute;
	 top: 0.1875rem;
	 left: 0.1875rem;
	 width: 1.125rem;
	 height: 1.125rem;
	 border-radius: 1.125rem;
	 background: #fff;
	 transition: left .25s;
}
 .btn-toggle.btn-sm.active {
	 transition: background-color .25s;
}
 .btn-toggle.btn-sm.active > .handle {
	 left: 3.525rem;
	 transition: left .25s;
}
 .btn-toggle.btn-sm.active:before {
	 opacity: .5;
}
 .btn-toggle.btn-sm.active:after {
	 opacity: 1;
}
 .btn-toggle.btn-sm.btn-sm:before, .btn-toggle.btn-sm.btn-sm:after {
	 line-height: -0.5rem;
	 color: #fff;
	 letter-spacing: .75px;
	 width: 2.25rem;
}
 .btn-toggle.btn-sm.btn-sm:before {
	 text-align: right;
	 left: 1.65rem;
}
 .btn-toggle.btn-sm.btn-sm:after {
	 text-align: left;
	 opacity: 0;
	 left: 0.4125rem;
}
 .btn-toggle.btn-sm.btn-sm.active:before {
	 opacity: 0;
}
 .btn-toggle.btn-sm.btn-sm.active:after {
	 opacity: 1;
}
 .btn-toggle.btn-secondary {
	 color: #6b7381;
	 background: #bdc1c8;
}
 .btn-toggle.btn-secondary:before, .btn-toggle.btn-secondary:after {
	 color: #6b7381;
}
 .btn-toggle.btn-secondary.active {
	 background-color: #2c3e50;
}
 
</style>
