<template>
    <div>
        <div class="row">
            <div class="form-group col-12 ">
                <label for="">Name</label>
                <input v-model="item.name" type="text" class="form-control" placeholder="Enter your permission name"/>
            </div> 
            <div class="form-group col-12">
                <label for="">Description</label>
                <tinymce-editor v-model="item.description" class="form-control" :init="{height: 200, force_br_newlines:true, menubar:false}" plugins="lists emoticons" toolbar="undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | emoticons" menubar=""></tinymce-editor>
            </div> 
        </div>
        <div class="row m-0 p-0">
            <button @click="create()" class="btn btn-success">SUBMIT</button>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
    name:'createpermission',
    components:{
      'tinymce-editor': Editor 
    },
    data(){
        return {
            item:{name:'',description:''}
        }
    },
    methods:{ 
		create(){  
            if(this.sent){
                return false;
            }
            this.sent=true;

            if(this.item.name.length == 0){
                this.$store.dispatch('makeNotification',{title:"Error occured",text:'Role name is required',type:'danger'});
                return false;
            }
			this.$store.dispatch('post',{endpoint:'/lefficient/leffit/Permissions',details:this.item})
			.then(data=>{
                console.log('result',data); 
                this.$store.dispatch('makeNotification',{title:"ITEM ADDED",text:this.item.name+' was added successfully',type:'success'});
                this.item.name='';
                this.item.description='';
                this.sent=false;
            }).catch((error)=>{
                console.log(error.request);
                if(error.request.status == 403){
                    this.$store.dispatch('makeNotification',{title:"Error occured",text:JSON.parse(error.request.response)['message'],type:'danger'});
                }
                this.sent=false;
            });
		},
    },
    mounted(){ 
        
    }
}
</script>
<style scoped>

</style>