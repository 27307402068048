<template>
    <div>
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#generalCollapse" aria-expanded="true" aria-controls="generalCollapse">
                     {{$t('leffit.websitehome.general_setting')}}
                    </button>
                </h2>
                </div>

                <div id="generalCollapse" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <div class="form-group form-check">
                                    <input v-model="item.landingpage" type="checkbox" class="form-check-input" id="landingCheck">
                                    <label class="form-check-label p-0" for="landingCheck">{{$t('leffit.websitehome.landing_page')}}</label>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="form-group form-check">
                                    <input v-model="item.in_slug" type="checkbox" class="form-check-input" id="slugCheck">
                                    <label class="form-check-label p-0" for="slugCheck">{{$t('leffit.websitehome.in_slug')}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <ul class="nav nav-tabs col-12">
                                    <li class="nav-item">
                                        <a data-toggle="tab" class="nav-link active" role="tab" href="#Nederlands">Nederlands</a>
                                    </li>
                                    <li class="nav-item">
                                        <a data-toggle="tab" class="nav-link" role="tab" href="#Engels">Engels</a>
                                    </li> 
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="Nederlands">
                                        <div class="row">
                                            <div class="form-group col-12 col-md-6">
                                                <label>{{$t('leffit.websitehome.name')}}</label>
                                                <input v-model="meta.nl.name" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-6">
                                                <label>{{$t('leffit.websitehome.slug')}}</label>
                                                <input v-model="meta.nl.slug" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>{{$t('leffit.websitehome.title')}}</label>
                                                <input v-model="meta.nl.title" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>{{$t('leffit.websitehome.description')}}</label>
                                                <textarea v-model="meta.nl.description" class="form-control" id="" cols="30" rows="5"></textarea>
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>{{$t('leffit.websitehome.keyword')}}</label>
                                                <textarea  v-model="meta.nl.keywords" class="form-control" id="" cols="30" rows="5"></textarea>
                                            </div>
                                            <div class="col-12">
                                                <button @click="updateGeneral()" class="btn btn-success">{{$t('leffit.websitehome.update')}}</button>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="tab-pane" id="Engels">
                                        <div class="row">
                                            <div class="form-group col-12 col-md-6">
                                                <label>Name</label>
                                                <input v-model="meta.en.name" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-6">
                                                <label>Slug</label>
                                                <input v-model="meta.en.slug" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>Title</label>
                                                <input v-model="meta.en.title" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>Description</label>
                                                <textarea v-model="meta.en.description" class="form-control" id="" cols="30" rows="5"></textarea>
                                            </div>
                                            <div class="form-group col-12 col-md-12">
                                                <label>Keyword</label>
                                                <textarea  v-model="meta.en.keywords" class="form-control" id="" cols="30" rows="5"></textarea>
                                            </div>
                                            <div class="col-12">
                                                <button @click="updateGeneral()" class="btn btn-success">UPDATE</button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#pageDetails" aria-expanded="true" aria-controls="pageDetails">
                        Page details
                        </button>
                    </h2>
                </div>

                <div id="pageDetails" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <ul class="nav nav-tabs col-12">
                                    <li class="nav-item">
                                        <a data-toggle="tab" class="nav-link active" role="tab" href="#Nederlands2">Nederlands</a>
                                    </li>
                                    <li class="nav-item">
                                        <a data-toggle="tab" class="nav-link" role="tab" href="#Engels2">Engels</a>
                                    </li> 
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="Nederlands2">
                                        <div v-for="(pagesection, index) in item.pagetype.sectiontypes" :key="'sectiontype'+index" class="accordion" :id="'accordionDetail'+index">
                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#pageDetailsSection' + index" aria-expanded="true" :aria-controls="'pageDetailsSection' + index">
                                                            {{ pagesection.name }}
                                                        </button>
                                                    </h2>
                                                </div>

                                                <div :id="'pageDetailsSection' + index" class="collapse show" aria-labelledby="headingOne" :data-parent="'accordionDetail'+index">
                                                    <div class="card-body">
                                                        <div v-for="(field, index) in pagesection.fields" :key="'sectiontype' + index" class="row">
                                                            <div class="col-2">
                                                                {{ field.title }}
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <div v-for="(f, i) in field.fields" :key="'field' + i" class="mb-2 col-12">
                                                                        <custom-input
                                                                            :type="f.fieldtype.id"
                                                                            :lang="'nl'"
                                                                            :column-id="field.column"
                                                                            :field="f.id"
                                                                            :value="(copy_image && copy_image['nl'] && copy_image['nl'][pagesection.id] && copy_image['nl'][pagesection.id][field.column] && copy_image['nl'][pagesection.id][field.column][f.id]) ? copy_image['nl'][pagesection.id][field.column][f.id] : (f.detail_value_nl ? f.detail_value_nl.value : null)"
                                                                            c-class="form-control"
                                                                            :section="pagesection.id"
                                                                            :label="f.name"
                                                                            :options="productfilters.map(pf => ({id: pf.id, text: pf.name}))"
                                                                            @input="getInputData"
                                                                        ></custom-input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="tab-pane" id="Engels2">
                                        <div v-for="(pagesection, index) in item.pagetype.sectiontypes" :key="'sectiontype'+index" class="accordion" :id="'accordionDetail'+index">
                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h2 class="mb-0">
                                                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#pageDetailsSection' + index" aria-expanded="true" :aria-controls="'pageDetailsSection' + index">
                                                            {{ pagesection.name }}
                                                        </button>
                                                    </h2>
                                                </div>

                                                <div :id="'pageDetailsSection' + index" class="collapse show" aria-labelledby="headingOne" :data-parent="'accordionDetail'+index">
                                                    <div class="card-body">
                                                        <div v-for="(field, index) in pagesection.fields" :key="'sectiontype' + index" class="row">
                                                            <div class="col-2">
                                                                {{ field.title }}
                                                            </div>
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <div v-for="(f, i) in field.fields" :key="'field' + i" class="mb-2 col-12">
                                                                        <custom-input
                                                                            :type="f.fieldtype.id"
                                                                            :lang="'en'"
                                                                            :column-id="field.column"
                                                                            :field="f.id"
                                                                            :value="(copy_image && copy_image['en'] && copy_image['en'][pagesection.id] && copy_image['en'][pagesection.id][field.column] && copy_image['en'][pagesection.id][field.column][f.id]) ? copy_image['en'][pagesection.id][field.column][f.id] : (f.detail_value_en ? f.detail_value_en.value : null)"
                                                                            c-class="form-control"
                                                                            :section="pagesection.id"
                                                                            :label="f.name"
                                                                            :options="productfilters.map(pf => ({id: pf.id, text: pf.name}))"
                                                                            @input="getInputData"
                                                                        ></custom-input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <button @click="updateDetails()" class="btn btn-success">UPDATE</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomInput from '../../leffit/components/custom_inputs.vue'
import axios from "axios"

export default {
    components: {'custom-input': CustomInput},
    data() {
        return {
            datatest: null,
            item:{
                landingpage:true,
                in_slug:true,
                pagetype: {},
            }, 
            meta:{
                nl:{
                    name:'',
                    slug:'',
                    title:'',
                    description:'',
                    keywords:''
                },
                en:{
                    name:'',
                    slug:'',
                    title:'',
                    description:'',
                    keywords:''
                }
            },
            productfilters: [],
            page_details: {},
            updade_details: false,
            copy_image:{}
            //fieldIdArr: []
        }
    },
    methods: {
        getInputData(value, sectionId, fieldId, lang, column, url) {
            this.setInputData(value, sectionId, fieldId, lang, column)
            
            this.setCopyImage(sectionId, fieldId, lang, column, url)
        }, 
        setCopyImage(sectionId, fieldId, lang, column, url){

            if(url == null) return;

            console.log('url', url)

            let oldPageDetails = this.copy_image;

            if(oldPageDetails[lang] && oldPageDetails[lang][sectionId] && oldPageDetails[lang][sectionId][column] && oldPageDetails[lang][sectionId][column][fieldId]) {
                //this.copy_image[lang][sectionId][column][fieldId] = url;
                this.$set(this.copy_image[lang][sectionId][column], fieldId, url)
            } else if(oldPageDetails[lang] && oldPageDetails[lang][sectionId] && oldPageDetails[lang][sectionId][column]){
                this.$set(this.copy_image[lang][sectionId][column], fieldId, url)
                //this.copy_image[lang][sectionId][column][fieldId] = url;
            } else if(oldPageDetails[lang] && oldPageDetails[lang][sectionId]){
                let fieldIdArr = {}
                fieldIdArr[fieldId] = url
                this.$set(this.copy_image[lang][sectionId], column, fieldIdArr)
                //this.copy_image[lang][sectionId][column] = fieldIdArr
            } else if(oldPageDetails[lang]){
                let fieldIdArr = {}
                fieldIdArr[fieldId] = url
                let columnArr = {}
                columnArr[column] = fieldIdArr
                this.$set(this.copy_image[lang], sectionId, columnArr)
                //this.copy_image[lang][sectionId] = columnArr
            } else {
                let fieldIdArr = {}
                fieldIdArr[fieldId] = url
                let columnArr = {}
                columnArr[column] = fieldIdArr
                let sectionDataArr = {}
                sectionDataArr[sectionId] = columnArr
                this.$set(this.copy_image, lang, sectionDataArr)
                //this.copy_image[lang] = sectionDataArr
            }
            console.log(this.copy_image)
        },

        setInputData(value, sectionId, fieldId, lang, column){
             let oldPageDetails = this.page_details;

            if(oldPageDetails[lang] && oldPageDetails[lang][sectionId] && oldPageDetails[lang][sectionId][column] && oldPageDetails[lang][sectionId][column][fieldId]) {
                this.page_details[lang][sectionId][column][fieldId] = value;
            } else if(oldPageDetails[lang] && oldPageDetails[lang][sectionId] && oldPageDetails[lang][sectionId][column]){
                this.page_details[lang][sectionId][column][fieldId] = value;
            } else if(oldPageDetails[lang] && oldPageDetails[lang][sectionId]){
                let fieldIdArr = {}
                fieldIdArr[fieldId] = value
                this.page_details[lang][sectionId][column] = fieldIdArr

            } else if(oldPageDetails[lang]){
                let fieldIdArr = {}
                fieldIdArr[fieldId] = value
                let columnArr = {}
                columnArr[column] = fieldIdArr
                this.page_details[lang][sectionId] = columnArr
            } else {
                let fieldIdArr = {}
                fieldIdArr[fieldId] = value
                let columnArr = {}
                columnArr[column] = fieldIdArr
                let sectionDataArr = {}
                sectionDataArr[sectionId] = columnArr
                this.page_details[lang] = sectionDataArr
            }
        },

        updateDetails() {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let formData = new FormData();
            
            for (const lang_key in this.page_details) {
                if (this.page_details.hasOwnProperty(lang_key)) {
                    let sections = this.page_details[lang_key];

                    for (const section_key in sections) {                    
                        if (sections.hasOwnProperty(section_key)) {
                            let columns = sections[section_key]

                            for (const column_index in columns) { 
                                if (columns.hasOwnProperty(column_index)) {
                                    let fields = columns[column_index];
                                    for (const field_key in fields) {
                                        if (fields.hasOwnProperty(field_key)) {
                                            let field_value = fields[field_key];

                                            formData.append('page_id', this.item.id);
                                            formData.append('pagetype_id', this.item.pagetype_id);
                                            formData.append('language['+lang_key+']', lang_key);
                                            formData.append('sectiontype_id['+lang_key+']['+section_key+']', section_key);
                                            formData.append('column_index['+lang_key+']['+section_key+']['+column_index+']', column_index);
                                            formData.append('field_id['+lang_key+']['+section_key+']['+column_index+']['+field_key+']', field_key);
                                            formData.append('value['+lang_key+']['+section_key+']['+column_index+']['+field_key+']', field_value);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            axios.post(process.env.VUE_APP_API_BASE_URL+'/leffit/cms/page/save-page-details', formData, config)
                .then((data)=>{
                    data;
                    this.$store.dispatch('makeNotification',{title:"Page details",text:'Page details updated successfully',type:'success'});
                })

        },

        async updateGeneral(){
            var payload = {
                landingpage:this.item.landingpage,
                in_slug:this.item.in_slug,
                name:this.meta.nl.name,
                name_en:this.meta.en.name,
                slug:this.meta.nl.slug,
                slug_en:this.meta.en.slug,
                meta:{
                    nl:{
                        title:this.meta.nl.title,
                        description:this.meta.nl.description,
                        keywords:this.meta.nl.keywords,
                    },
                    en:{
                        title:this.meta.en.title,
                        description:this.meta.en.description,
                        keywords:this.meta.en.keywords,
                    },
                }
            };

            this.$store.dispatch('put', {
                endpoint:'/leffit/cms/pages/'+this.item.id,
                details:payload
            })

        }
    },
    created() {
        this.$store.dispatch('get','/leffit/cms/pages/'+this.$route.params.id)
            .then((data)=>{
                this.item=data.data.data; 

                this.meta.nl.name = this.item.name;
                this.meta.en.name = this.item.name_en;
                this.meta.nl.slug = this.item.slug;
                this.meta.en.slug = this.item.slug_en;

                if(this.item.meta != null){
                    var meta_temp = JSON.parse(this.item.meta);

                    this.meta.nl.title = meta_temp.nl.title
                    this.meta.nl.description = meta_temp.nl.description
                    this.meta.nl.keywords = meta_temp.nl.keywords
                    
                    this.meta.en.title = meta_temp.en.title
                    this.meta.en.description = meta_temp.en.description
                    this.meta.en.keywords = meta_temp.en.keywords
                }
                

            })
            .catch((error)=>{
                error;
            });

        this.$store.dispatch('get','/leffit/products/productfilters/all/')
            .then((data)=>{
                this.productfilters=data.data.data; 
            })
            .catch((error)=>{
                error;
            });
    },
}
</script>