import reports from "./../../../../src/leffit/reports/index";
import turnoverRate from "./../../../../src/leffit/reports/turnoverRate";


export default [
    {
        path: "reports",
        component: reports,
        meta: {
            AuthRequired: true,
        },
    },
    {
        path: "turnover_rate",
        component: turnoverRate,
        meta: {
            AuthRequired: true,
        },
    },
]

