<template>
  <div class="row p-4">
    <div class="col-6 form-group">
      <label for="">{{ $t("leffit.products.product.weight") }}</label>
      <input type="number" class="form-control" v-model="item.width" />
    </div>
    <div class="col-6 form-group">
      <label for="">{{ $t("leffit.products.product.height") }}</label>
      <input type="number" class="form-control" v-model="item.height" />
    </div>
    <div class="col-6 form-group">
      <label for="">{{ $t("leffit.products.product.thickness") }}</label>
      <input type="number" class="form-control" v-model="item.thickness" />
    </div>
    <div class="col-6 form-group">
      <label for="">{{ $t("leffit.products.product.diameter") }}</label>
      <input type="number" class="form-control" v-model="item.diameter" />
    </div>
    <div class="col-6 form-group">
      <label for="">{{ $t("leffit.products.product.length") }}</label>
      <input type="number" class="form-control" v-model="item.length" />
    </div>

    <div class="col-12"><hr /></div>

    <div class="col-12 form-group">
      <label for="">{{ $t("leffit.products.product.packaging") }}</label>
      <div class="row">
        <div class="col-3">
          <label for="">{{ $t("leffit.products.product.width") }}</label>
          <input
            type="number"
            class="form-control"
            placeholder="width"
            v-model="item.package_width"
          />
        </div>
        <div class="col-3">
          <label for="">{{ $t("leffit.products.product.height") }}</label>
          <input
            type="number"
            class="form-control"
            placeholder="height"
            v-model="item.package_height"
          />
        </div>
        <div class="col-3">
          <label for="">{{ $t("leffit.products.product.length") }}</label>
          <input
            type="number"
            class="form-control"
            placeholder="length"
            v-model="item.package_length"
          />
        </div>
        <div class="col-3">
          <label for="">{{ $t("leffit.products.product.weight") }}</label>
          <input
            type="number"
            class="form-control"
            placeholder="weight"
            v-model="item.package_weight"
          />
        </div>
      </div>
    </div>

    <div class="col-12">
      <button @click="$parent.updateItem($event)" class="btn btn-success">
        Opslaan
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
};
</script>