<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},
    computed:{
        menu : function(){
            return  [
                {
                    title   : this.$t('lefficient.leffit.import.import'),
                    url     : '/admin/import-export/import',
                    children : [],
                },
                {
                    title       : this.$t('lefficient.leffit.export.import'),
                    url         : '/admin/import-export/export',
                    children    :[] 
                },
                 
            ];
        },  
    },
    data () {
        return  {  
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="css" scoped>
</style>