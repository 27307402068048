<template>
	<div>
		<div class="head row p-0 m-0">
            <div v-show="isDisabled()" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('leffit.roles.view_title') }}</h3></div>
            <div v-show="!isDisabled()" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('leffit.role.edit_title') }}</h3></div>
        	<h4 v-show="isDisabled()" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> EDIT</router-link></h4>
			<div v-show="isDisabled()" class="col-12 p-0 m-0">
				<!-- <button @click="set()" data-target="#addnew" data-toggle="modal" class="btn btn-success btn-sm">Add New Filter</button> -->
			</div>
      	</div>
		<div v-show="isDisabled()" class="row">
				<div class="col-sm-12 col-md-12"> 
					<div class="card basicDetailsCard">
						<h4>BASIC DETAILS</h4>
						<div id="">
							<div class="bsc bsc2">
								<b>Name:</b>
								<h6>{{item.name}}</h6>
							</div> 
							<div class="bsc bsc2">
								<b>Description:</b>
								<h6 v-html="item.description"></h6>
							</div>
							 
						</div>
					</div> 
					<!-- <div class="card">
						<h4>CHILDREN FILTER</h4>
						<table class="table table-responsive table-hover">
								<thead>
									<tr>
										<th></th>
										<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.filters.'+attribute) }}</th>
									</tr>
								</thead>
							<thead>
								<tr>
								<th><a class="btn btn-primary"><i class="fa fa-search"></i></a></th>
								<th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search[attribute]" class="form-control"></th>
								</tr>
							</thead>
								<tbody>
									<tr :key="index" v-for="(item, index) in pagedata">
										<td>
											<router-link :to="'/admin/products/productfilter/'+item.id" class="btn btn-open">
												<i class="fa fa-search"></i>
											</router-link>
											<router-link :to="'/admin/products/productfilter/'+item.id+'/edit'" class="btn btn-warning">
												<i class="fa fa-pencil"></i>
											</router-link>
											<a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
												<i class="fa fa-trash"></i>
											</a>
										</td>
										<td :key="index" v-for="(attribute,index) in attributes">{{ item[attribute] }}</td>
									</tr>
								</tbody>
						</table>

						<div class="modal" tabindex="-1" role="dialog" id="addnew">
							<div class="modal-dialog modal-lg" role="document">
								<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title">Add New Filter</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<div class="row">
										<div class="form-group col-sm-12 ">
											<label for="">Name</label>
											<input v-model="item2.name" type="text" class="form-control" />
										</div>
										<div class="form-group col-6">
											<label for="filter_parent">{{ $t('leffit.crm.filter.parent') }}</label> 
											<select style="position:relative;width:100%" class="form-control parent2" id="filter_parent2">
												<option :key="index" v-for="(pr,index) in this.filters2" :value="pr.id">{{pr.name}}</option>
											</select>
										</div>
										<div class="form-group col-sm-12 col-md-6">
											<label for="">Language</label>
											<select style="position:relative;width:100%" class="form-control language2" id="filter_language2">
												<option :key="index" v-for="(lg,index) in language" :value="lg.code">{{lg.name}}</option>
											</select>
										</div>
										<div class="form-group col-sm-6">
											<label for="">Description</label>
											<input v-model="item2.description" type="text" class="form-control" />
										</div>
										<div class="form-group col-sm-6 ">
											<label for="">Pickorder</label>
											<input v-model="item2.pickorder" type="text" class="form-control" />
										</div>
										<div class="form-group col-12 ">
											<label for="filter_active" class="d-flex" style="width:60px">
												<input :checked="item2.active == 1 ? true:false" @click="item2.active=item2.active == 0 ? 1:0" style="height: 21px;" type="checkbox" class="form-control d-block" id="filter_active"  />
												<div>{{ $t('leffit.products.product_filter.active') }}</div>
											</label>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button id="closemodal" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Close</button>
									<button @click="create()" type="button" class="btn btn-primary">Save changes</button>
								</div>
								</div>
							</div>
						</div>
					</div> -->
				</div> 
			</div>

		<form @submit="updateItem($event)"> 
			<div v-show="!isDisabled()"> 
				<div class="card"> 
					<div class="row">
						<div class="form-group col-12 ">
							<label for="">Name</label>
							<input v-model="item.name" type="text" class="form-control" placeholder="Enter your role name"/>
						</div> 
						<div class="form-group col-12">
							<label for="">Description</label>
							<tinymce-editor v-model="item.description" class="form-control" :init="{height: 200, force_br_newlines:true, menubar:false}" plugins="lists emoticons" toolbar="undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | emoticons" menubar=""></tinymce-editor>
						</div> 
					</div>
					<div class="col-12">
						<label for="">Attach permissions</label>
						<select @change="attachPermission()" name="" id="permissions" class="form-control">
							<option value=""></option>
							<option :key="index" v-for="(v,index) in pagedata.filter(ft => attached.find(d=>d.id == ft.id) == null)" :value="v.id">{{v.name}}</option>
						</select>
					</div>
					<div class="col-12 pt-2 " style="text-align:left">
						<div v-for="(v,index) in attached" :key="index" class="itemPermission mt-2">
							<b>{{v.name}}</b>
							<a @click="detach(v)" href="#"><i class="fa fa-remove"></i></a>
						</div>
					</div>
					<div class="row p-0 m-0 mt-3">
						<button @submit="updateItem($event)" class="btn btn-success">UPDATE</button>
					</div>
				</div>
			</div>	  
		</form>
	</div>
	
	
</template>

<script>
import { mapState } from 'vuex'; 
import Editor from '@tinymce/tinymce-vue';
export default {
	props:['id', 'edit'], 
	name: 'productFilter',
	components:{
      'tinymce-editor': Editor 
    },
  	data () {
	    return {
	    	item : {
                'name' 		: '',  
				'description' 		: '', 
			},  
			filters:[],
			filters2:[],
			types:[],
			pagedata : [],
            attributes : [
            'name',
            'description', 
			],
			search : {},
            attached:[],
			item2:{active:0,name:'',description:'',parent:'',language:'',pickorder:''}
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }), 
	},  
	methods:{
		
		isDisabled(){
  			return !(this.$route.meta.edit)
  		},
		updateItem(e){  
			e.preventDefault(); 
			this.$store.dispatch('put',{endpoint:'/lefficient/leffit/Roles/'+this.item.id,details:this.item})
			.then(data=>{console.log('result',data.data); this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});}).catch((error)=>{console.log(error.request);});
		},
		set(){
			window.$('.language2').select2();
			window.$('.parent2').select2();

		},
		deleteItem(id){
			this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
			this.$store.dispatch('delete','/leffit/products/productfilters/'+id)
			.then(data=>{
				console.log(data);
				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:this.item.name+' was removed successfully',type:'success'});
				// this.pagedata = data.data.data;
			});
		},
	},
  	mounted(){  
	    this.$store.dispatch('get','/lefficient/leffit/Roles/'+this.$route.params.id)
        .then(data=>{ 
        	console.log('result',data);
			this.item = data.data;
			this.attached=this.item.permissions;
		});  

		this.$store.dispatch('get','/lefficient/leffit/Permissions')
        .then(data=>{
			this.pagedata = data.data;
		});
	}
}
</script>

<style lang="css" scoped>
 .itemPermission{
        background-color: #d3d3d370;
        padding: 5px 8px;
        display: inline-block;
        width: auto;
        border-radius: 20px;
        margin-right:10px;
    }
    .itemPermission b{
        padding: 0px 10px;
        font-weight: 300;
        text-transform: capitalize;
    }
    .itemPermission a{
        color: red;
    }
    .itemPermission a i{
        font-weight: lighter;
        font-size: 14px;
    }
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>