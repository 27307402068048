<template>
    <div>
        <div class="row">
            <div class="col-12 d-flex" style="justify-content: space-between;">
                <h4>Relateds</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex"> 
                <div class="form-group col-5">
                    <label for="">Label</label>
                    <input type="text" class="form-control" v-model="item.label">
                </div>
                <div class="form-group col-5">
                    <label for="">Value</label>
                    <input type="text" class="form-control" v-model="item.value">
                </div>
                <div class="col-2">
                    <button @click="addnewrelated()" class="btn btn-success btn-sm" style="margin-top:40px">Add new</button>
                </div>
            </div>
        </div>
        <div class="row pt-2" style="background:#d3d3d326"> 
            <div v-for="(type , index) in relateds" :key="index" class="col-12 d-flex"> 
                <div class="form-group m-0 col-5">
                    <label for="">label</label>
                    <input type="text" class="form-control" v-model="type.label">
                </div>
                <div class="form-group m-0 col-4">
                    <label for="">value</label>
                    <input type="text" class="form-control" v-model="type.value">
                </div>
                <div class="col-2 d-flex">
                    <button @click="updateRelated()" class="btn btn-primary btn-sm" style="margin-top:40px">update</button>
                    <button @click="removeRelated(type)" class="btn btn-danger btn-sm" style="margin-top:40px"><i class="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        relateds:{required:true}
    },
    data(){
        return {
            item:{label:'',value:''}
        }
    },
    methods:{
        addnewrelated(){
            this.item.id=Math.round(Math.random() * 2000);
            this.$parent.relateds.unshift(this.item);
            this.$parent.settings.push({
                "title":this.item.label,
                "field":this.item.value,
                "show":true,
                "id":this.item.id
            });
            this.item={label:'',value:''};
            this.$parent.updateItem();
        },
        updateRelated(){
            this.$parent.updateItem();
        },
        removeRelated(item){
            if(!confirm("Are you sure you want to delete this item?")){
                return false;
            }
            this.$parent.relateds=this.$parent.relateds.filter((d)=> d.label != item.label && d.value != item.value);
            this.$parent.settings=this.$parent.settings.filter((d)=> d.id != item.id );
            this.$parent.updateItem();
        }
    },
    created(){

    }
}
</script>