<template>
<div>
   	<img v-bind:src="url" style="height: 80px; width: 90px;" :alt="$t('lefficient.system.img_alt')"/>
    </div>
</template>

<script>
export default {
    name:'productimage',
    props:['url'],
    data()
    {
        return{
            // url:'', 
        }
    },
     mounted(){ 
        //  this.$store.dispatch('get','/leffit/products/get_productimages/'+this.product+'/'+this.imgID+'/'+this.lang)
        // .then(data=>{
        //     this.url=data.data;
        //     console.log(this.url);

		// });
    }
}
</script>

<style scoped>
    div img{
        width:auto !important;
    }
</style>