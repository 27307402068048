<template>
	 	<table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.shipping.shipping_cost.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a @click="searchTable()" class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search.search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr :key="index" v-for="(item, index) in pagedata">
      				<td>
                <router-link :to="'/admin/shippings/shippingcosts/'+item.id+'/view'" class="btn btn-success">
      						<i class="fa fa-eye"></i>
      					</router-link>
      					<router-link :to="'/admin/shippings/shippingcosts/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
                <a @click="deleteItem(item.id)" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes">{{ translate(item[attribute]) }}</td>
      			</tr>
      		</tbody>
      	</table>
</template>

<script>
export default {
  	data () {
    	return {
        pagedata : [],
        attributes : [
          'name',
          'amount',
          'created_at',
        ],
        search : {search:{}},
    	}
  	},
    methods: {
      translate (input) {
        if(input){
          if(input.constructor === String){
            if(input.indexOf('leffit.products')!==-1){
              return this.$t(input);
            }else{
              return input;
            }
          }
        }
        return input;
      },
      searchTable(){
          console.log(this.search);
          this.$store.dispatch('post',{details:this.search,endpoint:'/leffit/shipping/shippingcosts/search'})
          .then((data)=>{
              console.log(data);
              this.pagedata=data.data.data;
          })
          .catch((error)=>{
              console.log(error)
          })
      },
      deleteItem(id){
        if(!confirm('Are you sure you want to delete?')){
          return false;
        }
        this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
        this.$store.dispatch('delete','/leffit/shipping/shippingcosts/'+id)
        .then(data=>{
          console.log(data);
          this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Item was removed successfully',type:'success'});
        });
      }
    },
  	mounted(){
	    this.$store.dispatch('post',{endpoint:'/leffit/shipping/shippingcosts/search',details:{search:{limit:10}}})
        .then(data=>{
        	console.log(data);
			    this.pagedata = data.data.data;
		});
	},
}
</script>

<style lang="css" scoped>
</style>