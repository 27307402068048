export default `
<section class="third bg-white  center pt-4">
<h2 id="section4Header1" class="h2 mb-2">
    ++{{section4Header1}}
</h2>
<h6 id="section4Text1" class="p mb-3">
    ++{{section4Text1}}
</h6>	
	
</section>
`