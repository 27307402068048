<template>
     <div id="lockscreen" v-if="$store.state.leffit.lockscreen.show">
       <!-- <div id="lockscreen" > -->
          <div class="content">
              <div v-if="$store.state.leffit.lockscreen.loading" class="lockScreenLoading">
                  <div class="loader">
                    <div class="content">
                        <div class="roller"></div>
                        <!-- <b>{{this.loader.text}}</b> -->
                    </div>
                </div>
              </div>
              <div v-else>
                <div class="header">
                    <img  src="https://www.lefficient.nl/admin/images/logoLeffix2.png" style="width:250px" class="fas fa-lock lockscreenlock mb-2" /><br>
                    <b style="color:#69150b">Sessie verlopen...</b>
                </div>
                <div class="body">
                    <form action="" @submit.prevent="unlockScreen()">
                    <div class="form-group">
                        <input v-model="password" type="password" class="form-control wachtwoord-input mb-3" name="" id="" placeholder="Voer je wachtwoord in...">
                        <!-- <input style="float:left" @click="logout()" type="button" class="btn" :value="$t('header.signout')"> -->
                        <input type="submit" class="btn" :value="$t('login.login')">
                    </div>
                    </form>
                    <!-- <b class="text-danger" v-if="error.show">
                        {{error.message}}
                    </b> -->
                </div>
              </div>
          </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                lockscreenTime: 100 * 3000,
                error:{
                    show:false
                },
                password:''
            }
        },
        methods:{
            unlockScreen(){
                console.log(this.$store.state);
                var email=this.$store.state.leffit.user.email;
                this.$store.dispatch('login', {
                    email:email,
                    password:this.password,
                    loggedIn:true
                });
                localStorage.setItem('lockscreen', 'false');
            }
        },
        created(){
            if(!this.$route.meta.AuthRequired){
                this.$store.state.leffit.lockscreen.show = false;
            }
        }
    }
</script>

<style >
#lockscreen{
    position: fixed;
    background: #fffffff5;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99999;
  }
  #lockscreen .content{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    color: white;
    text-align: center;
  }
  #lockscreen .header{}
  #lockscreen .header img{
    display: block;
    width: 70px;
    margin: auto;
  }
  #lockscreen .body{}
  #lockscreen .body .wachtwoord-input{
    border:1px solid white;
    font-size: 13px;
    padding: 0px 40px;
    text-align: center;
    margin-top: 11px;
    border-radius: 3px;
    background-color: #9e9e9e6e;
  }
  .lockscreenlock{
    color: #555;
    font-size: 60px;
  }
    .main-panel{
        width: calc(100% - 210px);
    }
    .sidebar-mini .main-panel{
        width: calc(100% - 80px);
    }
    .main-panel>.content{
        padding: 0px 0px !important;
        margin-top: 52px;
    }
    @media (max-width: 991px){
        .main-panel{
            width: calc(100%);
        }
    }

    .mainPanel{
        padding: 0px 20px;
    }

    .mainPanel .head {
        width: 100%;
    }
    .mainPanel .head h1{
        font-size: 25px;
        color:var(--color-1);
        text-align: left;
        font-weight: 400;
    }

    #mainBody{
        padding: 0x;
        margin: 0px;
        margin-top: 20px;
    }
    #mainBody .tools{
         /*border:1px solid red; */
    }
    #mainBody .mainPanel{
         /*border:1px solid black; */
    }

    .pagina{
        height: -webkit-fit-content;
        display: flex;
    }
    .pagina h5{
        margin: 0px;
        line-height: 1.7 !important;
        font-size: 15px;
        font-weight: 400;
        margin-right: 10px;
        white-space: nowrap;
        padding: 13px 30px;
        background: var(--color-2);
    }

    .nav2{
        width: 100%;
        background:var(--color-1);
        display: flex;
        justify-content: space-between;
        line-height: unset;
    }

    .brdcmb{
        width: 100%;
        padding: 13px 10px;
    }
    .brdcmb ul{
        margin-bottom: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
    }
    .brdcmb ul li{
        margin-right: 13px;
    }
    .brdcmb ul li a{
        text-decoration: none;
        font-size: 13px;
        color: white;
        position: relative;
    }
    .brdcmb ul li.active a{
        color: var(--color-2);
    }
    .opt{
        padding: 13px 10px;
    }
    .opt .btn{
        margin:0px;
        font-size: 10px;
        margin-right: 8px;
        border-radius: 30px;
        padding: 3px 10px;
        color: white;
        font-weight: 600;
        border:none !important;
        box-shadow: none;
    }
    .opt .btn:first-child{
        background: #17c0c8;
    }
    .opt .btn:last-child{
        background: var(--color-2);
    }
  .loader{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    background: #0000009c;
    z-index: 99999999999;
    }
    .loader.content{
        position:absolute;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        margin:auto;
        width: 300px;
        height: 200px;
    }
    .content b{
        display: block;
        margin-top: 10px;
        color: white;
    }
    .roller{
        margin: auto;
        width:60px;
        height: 60px;
        border:4px solid transparent;
        border-top:4px solid white;
        border-radius: 50%;
        animation: roll .5s linear infinite;
    }
    .roller2{
        margin: auto;
        width:35px;
        height:35px;
        border:4px solid transparent;
        border-top:4px solid white;
        border-radius: 50%;
        animation: roll .5s linear infinite;
    }

    @keyframes roll {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
    }
</style>
