export default `
<section class="four bg-white  ">
<div class="row pt-4 mb-3">
    <div class="col-sm-12  ">
        <h4 id="section26header" class="pt-3 four_o carry">++{{section26header}}</h4>
        <!-- <img src="full_bg.png"> -->			
    </div>

    <div class="col-sm-6 mt-2">
        <h6 id="section26Text1" class="p"> ++{{section26Text1}}</h6>
    </div>
    <div class="col-sm-6 mt-2">
        <img src="++{{section26Image1}}">
    </div>
</div>
</section>

`