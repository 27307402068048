<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3" v-show="!isDisabled">{{ $t('leffit.shipping.cost_edit') }}</h3>
            <h3 class="pl-3" v-show="isDisabled">{{ $t('leffit.shipping.cost_view') }}</h3>
      	</div>
        <div class="">
           <div >
				<div class="card" style="border:none">

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.method_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name" :disabled="(isDisabled ? true:false)" >
                    </div>
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.cost_amount') }}</label>
                        <input type="number" class="form-control" id="title" v-model="item.amount" :disabled="(isDisabled ? true:false)">
                    </div>
                     <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.method') }}</label>
                        <Select2 v-model="item.shipping_method_id" :options="methods.map((d)=>{ return {id:d.id,text:d.name};})"></Select2>
                    </div> 
                    <div class="form-group col-12" v-if="isRegion">
                        <label for="title">{{ $t('leffit.shipping.region_name') }} </label>
                        <router-link :to="'/admin/shippings/shippingregions/'+region.id+'/view'" >
      						 : {{region.name}}
      					</router-link>
                   </div>

                    <div class="form-group col-12" v-if="!isRegion">
                        <label for="title">{{ $t('leffit.shipping.region_name') }} </label>
                        Region  Not available
                   </div>

                </div>

                <button @click="create()" class="btn btn-success" :disabled="(isDisabled ? true:false)">{{ $t('lefficient.leffit.system.update') }}</button>

				</div>
			</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',amount:0,shipping_method_id:0
            },
            region : {
                name:'',
            },
            methods:[],
          isDisabled: false,
          isRegion: false,
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.$store.state);
            this.$store.dispatch('put',{endpoint:'/leffit/shipping/shippingcosts/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data);
                this.$store.dispatch('makeNotification',{type:'success',title:"Status updated",text:'Status was updated automatically'});

			}).catch((error)=>{
				console.log(error);
			});
        }
    },

    mounted(){
         var isview=window.location.pathname.indexOf("view");
         if(isview > 1){
                this.isDisabled=true;  }

         this.$store.dispatch('get','/leffit/shipping/shippingcosts/'+this.$route.params.id)
			.then(data=>{
                this.item=data.data.ShippingCost;
                this.region=data.data.ShippingRegion;
               if( data.data.ShippingRegion == null ){
                     this.isRegion=false;
               }else
               {
                    this.isRegion=true;
                    this.region=data.data.ShippingRegion;
               }
			}).catch((error)=>{
				console.log(error);
			});
    },
    created(){
        this.$store.dispatch('get','/leffit/shipping/shippingmethods')
        .then(data=>{
        	console.log(data);
                this.methods = data.data.data;
        });
    }

}
</script>
