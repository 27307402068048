<template>
    <div style="background:white">
        <div class="row m-0 p-0">
            <div class="col-12">
                <h2>CREATE NEW USER</h2>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label for="user_title">{{ $t('leffit.users.user.display_name') }}</label>
                    <input type="text" class="form-control" id="user_title" v-model="item.display_name">
                </div>
                <div class="form-group col-6">
                <label for="user_initials">{{ $t('leffit.users.user.initials') }}</label>
                <input type="text" class="form-control" id="user_initials" v-model="item.initials">
                </div>
            
                <div class="form-group col-6">
                <label for="user_firstname">{{ $t('leffit.users.user.firstname') }}</label>
                <input type="text" class="form-control" id="user_firstname" v-model="item.firstname">
                </div>
            
                <div class="form-group col-6">
                <label for="user_lastname">{{ $t('leffit.users.user.lastname') }}</label>
                <input type="text" class="form-control" id="user_lastname" v-model="item.lastname">
                </div> 
                <div class="form-group col-6">
                <label for="user_address">{{ $t('leffit.users.user.address') }}</label>
                <input type="text" class="form-control" id="user_address" v-model="item.address">
                </div> 
                <div class="form-group col-6">
                <label for="user_address2">{{ $t('leffit.users.user.address2') }}</label>
                <input type="text" class="form-control" id="user_address2" v-model="item.address2">
                </div>
            </div> 
            <div class="form-row">
                <div class="form-group col-6">
                <label for="user_zipcode">{{ $t('leffit.users.user.zipcode') }}</label>
                <input type="text" class="form-control" id="user_zipcode" v-model="item.zipcode">
                </div>
            
                <div class="form-group col-6">
                <label for="user_city">{{ $t('leffit.users.user.city') }}</label>
                <input type="text" class="form-control" id="user_city" v-model="item.city">
                </div>
            
                <div class="form-group col-6">
                <label for="user_country">{{ $t('leffit.users.user.country') }}</label>
                <!-- <select style="position:relative;width:100%" class="form-control country" id="user_country">
                    <option selected :key="index" v-for="(ct,index) in country" :value="ct.code">{{ct.name}}</option>
                </select> -->
                <select2 class="form-control" style="position:relative;width:100%" :options="country.map((c)=>{ return {id:c.code,text:c.name} })" v-model="item.country"></select2>
                </div>  
                <div class="form-group col-6">
                <label for="user_phone">{{ $t('leffit.users.user.phone') }}</label>
                <input type="text" class="form-control" id="user_phone" v-model="item.phone">
                </div>
            
                <div class="form-group col-6">
                <label for="user_mobile">{{ $t('leffit.users.user.mobile') }}</label>
                <input type="text" class="form-control" id="user_mobile" v-model="item.mobile">
                </div> 
                <div class="form-group col-6">
                <label for="user_website">{{ $t('leffit.users.user.website') }}</label>
                <input type="text" class="form-control" id="user_website" v-model="item.website">
                </div>
            
                <div class="form-group col-6">
                <label for="user_email">{{ $t('leffit.users.user.email') }}</label>
                <input type="email" class="form-control" id="user_email" v-model="item.email">
                </div> 
            
                <div class="form-group col-6">
                <label for="user_birthday">{{ $t('leffit.users.user.birthday') }}</label>
                <input type="date" class="form-control" id="user_birthday" v-model="item.date_of_birth">
                </div>
            
                <div class="form-group col-6">
                <label for="user_gender">{{ $t('leffit.users.user.gender') }}</label>
                <select v-model="item.gender" style="position:relative" class="form-control" id="user_gender">
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                </select>
                </div> 
            
                <div class="form-group col-6">
                <label for="user_password">{{ $t('leffit.users.user.password') }}</label>
                <input type="password" class="form-control" id="user_password" v-model="item.password">
                </div>
            </div> 
            <div class="form-row">
                <div class="form-group col-12">
                <label for="user_about_me">{{ $t('leffit.users.user.about_me') }}</label>
                <textarea  class="form-control WYSIWYG" id="organization_about_me" v-model="item.about_me"></textarea>
                </div>
            </div>
            <div class="col-12">
                <button @click="create()" class="btn btn-success">SUBMIT</button>
            </div>
        </div>
    </div>
</template>

<script>
import language from '../../../data/language';
import country from '../../../data/country';
export default {
    name:'createuser',
    data(){
        return {
            language:language, 
			country:country, 
            item:{
				'initials' : '',
				'display_name' : '',
				'firstname' : '',
				'lastname' : '',
				'address' : '',
				'address2' : '',
				'zipcode' : '',
				'city' : '',
				'country' : 'NL',
				'phone' : '',
				'mobile' : '',
				'website' : '', 
				'date_of_birth' : '',
				'about_me' :  '',
				'email' : '', 
				'password' : '',
				'gender' : '',
            },
			sent:false
        }
    },
    methods:{
        create(){
			if(this.sent){ return false;} 
			this.sent=true;
			this.$store.dispatch('post',{endpoint:'/lefficient/leffit/users',details:this.item})
			.then(data=>{
				console.log('result',data.data); 
				this.$store.dispatch('makeNotification',{title:"ITEM ADDED",text:'User was added successfully',type:'success'});
				this.pagedata.unshift(data.data.data);
				document.getElementById('closemodal').click();
				this.sent=false;
				
			}).catch((error)=>{
				console.log(error.request);
				});
		},
    },
    mounted(){
        window.$('.language').select2();
        window.$('.parent').select2(); 
        window.tinymce.init({
        selector: 'textarea#organization_about_me',
        height: 200,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | ' +
        ' bold italic backcolor | alignleft aligncenter ' +
        ' alignright alignjustify | bullist numlist outdent indent |' +
        ' removeformat | help',
        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            '//www.tiny.cloud/css/codepen.min.css'
        ]
        });



    }
}
</script>
<style scoped>

</style>