<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.shipping.method_edit') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.method_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.active') }}</label>
                        <select v-model="item.active">
                            <option value="1">Enable</option>
                            <option value="0">Disable</option>
                        </select>
                    </div> 

                    <div class="card col-12">
                        <div class="card-header"><h4>Add Cost</h4></div>
                        <div class="card-body">
                            <div class="form-group col-12">
                                <label for="title">{{ $t('leffit.shipping.cost_name') }}</label>
                                <input type="text" class="form-control" id="title" v-model="cost.name">
                            </div> 
                            <div class="form-group col-12">
                                <label for="title">{{ $t('leffit.shipping.cost_amount') }}</label>
                                <input type="number" class="form-control" id="title" v-model="cost.amount">
                            </div> 

                            <button @click="attachCost()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
                        </div>
                    </div>


                </div>
 
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.leffit.system.update') }}</button>
 
				</div>

                <div class="accordion p-0 pt-3" id="accordionExample">
                    <h4>SHIPPING COSTS</h4>
                    <div class="card" v-for="(cost,index) in item.costs" :key="index">
                        <div >
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0" style="display: flex;justify-content: space-between;">
                                    <a style="font-size:16px" >{{cost.name}} </a>
                                    <a style="font-size:16px;" >Price: {{cost.amount}} </a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        </div>
    </div>
</template>

<script> 
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',
            }, 
            cost : {
                name:'',amount:0,shipping_method_id:0
            }, 
            selectedcost:0,
            selectedcosts:[],
        }
    },
    methods:{
        attachCost(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            } 
            this.cost.shipping_method_id=this.item.id;
            this.$store.dispatch('post',{endpoint:'/leffit/shipping/shippingcosts',details:this.cost})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Cost created",text:'Cost was created automatically'});
			}).catch((error)=>{
				console.log(error);
            });
            this.item.costs.push(this.cost);
            this.cost={name:'',amount:0,shipping_method_id:0};
        },
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.$store.state);
            this.$store.dispatch('put',{endpoint:'/leffit/shipping/shippingmethods/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Status updated",text:'Status was updated automatically'});
                
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){ 
         this.$store.dispatch('get','/leffit/shipping/shippingmethods/'+this.$route.params.id)
			.then(data=>{
                console.log('result',data); 
                this.item=data.data.data;
			}).catch((error)=>{
				console.log(error);
			});
    }
    
}
</script>
