export default {
  leffit: {
    bijenkorf: {
      pricate_export: "Pricat",
      webshop_export: "Webshop export",
      sales_report: "Sales report",
      virgocode_importeren: "Virgocodes",
    },
    stocks: {
      available: "Beschikbaar",
      ordered: "Besteld",
      reserved: "Gereserveerd",
      backorder: "Backorder",
    },
    shipping: {
      world: "Verzendkosten Wereld",
      us: "Verzendkosten Amerika",
      shippingstatuses: "Verzendstatussen",
      shippingstatus: "Status zending",
      shippings: "Leveringen",
      shippingregions: "Verzendregios",
      shippingregion: "Verzendregio",
      shippingmethods: "Verzendmethoden",
      shippingmethod: "Verzendmethode",
      shippingcost: "Verzendkosten",
      shipping_status: { name: "Naam", created_at: "Aangemaakt op" },
      shipping_regions: { name: "Naam", created_at: "Aangemaakt op" },
      shipping_methods: {
        name: "Naam",
        created_at: "Aangemaakt op",
        active: "actief",
      },
      shipping_cost: {
        name: "Naam",
        created_at: "Aangemaakt op",
        amount: "Aantal",
      },
      shipments: {
        status: "Status",
        method: "Method",
        extra: "Extra",
        created_at: "Aangemaakt op",
      },
      shipment: "Levering",
      permission: {
        update_shipping_status:
          "Geeft de gebruiker de mogelijkheid om shipping_statuses te bewerken",
        update_shipping_region:
          "Geeft de gebruiker de mogelijkheid om shipping_regions te bewerken",
        update_shipping_method:
          "Geeft de gebruiker de mogelijkheid om shipping_methods te bewerken",
        update_shipping_cost:
          "Geeft de gebruiker de mogelijkheid om shipping_costs te bewerken",
        update_shipment:
          "Geeft de gebruiker de mogelijkheid om shipments te bewerken",
        read_shipping_status:
          "Geeft de gebruiker de mogelijkheid om shipping_statuses te bekijken",
        read_shipping_region:
          "Geeft de gebruiker de mogelijkheid om shipping_regions te bekijken",
        read_shipping_method:
          "Geeft de gebruiker de mogelijkheid om shipping_methods te bekijken",
        read_shipping_cost:
          "Geeft de gebruiker de mogelijkheid om shipping_costs te bekijken",
        read_shipment:
          "Geeft de gebruiker de mogelijkheid om shipments te bekijken",
        list_shipping_status:
          "Toestaan om alle shipping_statuses weer te geven",
        list_shipping_region: "Toestaan om alle shipping_regions weer te geven",
        list_shipping_method: "Toestaan om alle shipping_methods weer te geven",
        list_shipping_cost: "Toestaan om alle shipping_costs weer te geven",
        list_shipment: "Toestaan om alle shipments weer te geven",
        delete_shipping_status:
          "Geeft de gebruiker de mogelijkheid om shipping_statuses te verwijderen",
        delete_shipping_region:
          "Geeft de gebruiker de mogelijkheid om shipping_regions te verwijderen",
        delete_shipping_method:
          "Geeft de gebruiker de mogelijkheid om shipping_methods te verwijderen",
        delete_shipping_cost:
          "Geeft de gebruiker de mogelijkheid om shipping_costs te verwijderen",
        delete_shipment:
          "Geeft de gebruiker de mogelijkheid om shipments te verwijderen",
        create_shipping_status:
          "Geeft de gebruiker de mogelijkheid om nieuwe shipping_statuses aan te maken",
        create_shipping_region:
          "Geeft de gebruiker de mogelijkheid om nieuwe shipping_regions aan te maken",
        create_shipping_method:
          "Geeft de gebruiker de mogelijkheid om nieuwe shipping_methods aan te maken",
        create_shipping_cost:
          "Geeft de gebruiker de mogelijkheid om nieuwe shipping_costs aan te maken",
        create_shipment:
          "Geeft de gebruiker de mogelijkheid om nieuwe shipments aan te maken",
      },
      nl: "Verzendkosten Nederland",
      eu: "Verzendkosten EU",
      default: "Standaardverzending",
    },
    products: {
      stikkers: "Voorraad stikkers",
      web: {
        seo: {
          url: "Url",
          title: "Titel",
          keywords: "Keywords",
          description: "Omschrijving",
        },
        description: { title: "Titel" },
      },
      vouchers: "Kortingscodes",
      voucher: {
        value: "Waarde",
        validity: "Geldigheid",
        start: "Start op",
        expire: "Verloopt op",
        redeemed: "Verzilverd op",
        create: "nieuw",
        create_page: {
          start: "Begint op",
          expire: "Eindigt op",
          validity: "Geldigheid",
          value_type: "Type waarde (prijs)",
          value: "Waarde (Vb. 120)",
          create_button: "aanmaken",
        },
      },
      warehouses: "Magazijnen",
      warehouse: {
        zipcode: "Postcode",
        status_id: "Status",
        remarks: "Opmerkingen",
        picture: "Afbeelding",
        phone: "Telefoonnummer",
        organization_id: "Organisatie",
        name: "Naam",
        mobile: "Mobiel",
        language: "Taal",
        fax: "Fax",
        email: "Email",
        country: "Land",
        city: "Plaats",
        address2: "Adresregel 2",
        address: "Adres",
      },
      type: { name: "Naam", add: "Producttype toevoegen" },
      stocks: "Voorraden",
      stock: {
        warehouse_id: "Magazijn",
        stocks: "Voorraden",
        stock_location_id: "Voorraadlocatie",
        sku: "Sku",
        product_id: "Product",
        amount: "Aantal",
        voorraadtype: "Voorraadtype",
        warehouse: "Magazijnen",
        stock_location: "Voorraad locatie",
        stock_locations: "Voorraad locaties",
        stock_type: "Voorraad type",
        add_warehouses: "Voeg magazijn toe",
        warehouses: {
          name: "magazijnen",
        },
      },
      stock_locations: "Voorraad locaties",
      stock_location: { name: "Voorraadlocatienaam" },
      statuses: { inactive: "Inactief", active: "Actief" },
      settings: "Instellingen",
      producttypes: "Product Typen",
      productstatuses: "Product Statussen",
      products: "Producten",
      productmeta_labels: "Product Meta Labels",
      productimages: "Product Afbeeldingen",
      productfilters: "Product Filters",
      product: {
        length: "Lengte",
        width: "Breedte",
        height: "Hoogte",
        silver_weight: "Zilvergewicht",
        weight: "Gewicht",
        vat: "BTW",
        seo: "SEO",
        updated_at: "Bijgewerkt op",
        stock: "Voorraad",
        size: "Product afmeting",
        remarks: "Opmerkingen",
        related: {
          versions: "Versies",
          sizes: "Maten",
          related_products: "Gerelateerd",
        },
        producttype_id: "Producttype",
        producttype: "Producttype",
        productstatus_id: "Productstatus",
        productstatus: "Productstatus",
        product_type: "Product type",
        product_statuses: { inactive: "Inactief", active: "Actief" },
        product_status: "Product status",
        product_add: "Product toevoegen",
        prices: "Prijzen",
        price: "Prijs",
        price_from: "Van Prijs",
        plannable: "Planning",
        other_details: "Overige details",
        name: "Naam",
        images: "Productafbeeldingen",
        filters: "Productfilters",
        ean: "Barcode",
        description: "Omschrijving",
        deleted_at: "Verwijderd op",
        created_at: "Aangemaakt op",
        create: "Product aanmaken",
        basic_details: "Product details",
        add_product: "Product toevoegen",
        select_product: "selecteer een product",
        product_detail: "productgegevens",
        casa_productcode: "Productcode",
        casa_seizoenscode: "Seizoenscode",
        virgocode: "Virgocode",
        label: "Productlabel",
        thickness: "Dikte",
        diameter: "Diameter",
        purchase_pricelevels: "Inkoopprijzen",
        sales_pricelevels: "Verkoopprijzen",
        versions: "Uitvoeringen",
        sizes: "Maten",
        sizing: "Afmetingen",
        relateds: "Gerelateerd",
        orderconfirmation: "Bestelbevestiging",
        warehouses: "Magazijnen",
        currency: "Valuta",
        product_filters: "Productfilters",
        supplier: "Leverancier",
        supplier_code: "Productcode Leverancier",
        bolcom: "Bol.com",
        bijenkorf: "Bijenkorf",
        upload_image: "Upload EAN Afbeeldingen",
        packaging: "Verpakking",
        casa_filters: "Casa Filters",
        ring_size: "Ringmaat",
      },
      product_types: {
        subscription: "Abonnement",
        stripescard: "Strippenkaart",
        product: "Product",
        digital: "Digitaal Product",
        combined_product: "Gecombineerd Product",
        appointment: "Afspraak",
        "0": "Product Types",
        add_new: "Voeg nieuwe toe",
        add_new_type: "Voeg nieuw type toe",
        name: "Naam",
        close: "Sluiten",
        save_changes: "Opslaan",
      },
      product_type: "Product Type",
      product_statuses: {
        inactive: "Inactief",
        active: "Actief",
        "0": "Product Statuses",
        add_new: "voeg nieuwe toe",
        add_new_status: "voeg nieuwe status toe",
        name: "Naam",
        close: "Sluiten",
        save_changes: "Opslaan",
      },
      product_status: "Product Status",
      product_meta_labels: "Product Meta Labels",
      product_meta_label: "Product Meta Label",
      product_image: {
        title: "Title",
        filename: "Filename",
        caption: "Beschrijving",
        alt: "Korte omschrijving",
        add: "Toevoegen",
      },
      product_filters: "Product Filters",
      product_filter: {
        view_filters: "Bekijk Filter",
        select: "Selecteer",
        lang: "Taal",
        filters: "Filters",
        edit_filters: "Bewerk Filter",
        description: "Omschrijving",
        active: "actief",
        "0": "Product Filter",
        add_buton: "voeg nieuwe toe",
        add_new_filter: "Voeg nieuwe filter toe",
        add_new_filter2: "Nieuw productfilter",
        name: "Naam",
        pickorder: "Volgorde",
        parent: "Bovenliggend",
        language: "Taal",
        close: "Sluiten",
        save_changes: "Opslaan",
        group: "Groep",
        filter: "Filter",
        cancel: "Sluiten",
        english_name: "engelse naam",
        english_description: "engelse omschrijving",
        parents: "Bovenliggenden",
        children: "Onderliggende filters",
        websites: "Website",
        products: "Producten",
        product: "Product",
        metas: "Metas",
        children_filter: {
          add: "Toevoegen",
        },
      },
      filter: {
        view_filters: "Bekijk Filter",
        select: "Selecteer",
        lang: "Taal",
        filters: "Filters",
        edit_filters: "Bewerk Filter",
        description: "Omschrijving",
        active: "actief",
        "0": "Product Filter",
        add_buton: "voeg nieuwe toe",
        add_new_filter: "Voeg nieuwe filter toe",
        add_new_filter2: "Nieuw productfilter",
        name: "Naam",
        pickorder: "Volgorde",
        parent: "Bovenliggend",
        parents: "Bovenliggenden",
        children: "Onderliggende filters",
        websites: "Website",
        products: "Producten",
        metas: "Metas",
        language: "Taal",
        close: "Sluiten",
        save_changes: "Opslaan",
        group: "Groep",
        filter: "Filter",
        cancel: "Sluiten",
        english_name: "engelse naam",
        english_description: "engelse omschrijving",
        to_add: "Toevoegen",
        add: "Toevoegen",
      },
      pricelevel: {
        wholesale_factor: "Groothandel factor",
        wholesale: "Groothandel prijs",
        store_factor: "Winkelprijs factor",
        store: "Winkelprijs",
        purchase_price_latest: "Laatste inkoopprijs",
        purchase_cost_latest: "Laatste inkoopkosten",
        cost_price: "Kostprijs",
        purchase_valuta: "Inkoopvaluta",
        purchase_valuta_price: "Prijs in Inkoopvaluta",
        purchase_exchange_rate: "Wisselkoers",
        purchase_price: "Inkoopprijs",
        purchase_keurloon: "Keurloon",
        purchase_behandelingskosten: "Behandelingskosten",
        purchase_vrachtkosten: "Vrachtkosten",
        purchase_import_costs: "Importeerkosten",
        purchase_other: "Overige kosten",
        purchase_shippingcosts: "Verzendkosten",

        purchase_valuta_total: "Inkoopvaluta Totaal",
        purchase_valuta_price_total: "Prijs in Inkoopvaluta Totaal",
        purchase_exchange_rate_total: "Wisselkoers Totaal",
        purchase_price_total: "Inkoopprijs Totaal",
        purchase_keurloon_total: "Keurloon Totaal",
        purchase_behandelingskosten_total: "Behandelingskosten Totaal",
        purchase_vrachtkosten_total: "Vrachtkosten Totaal",
        purchase_import_costs_total: "Importeerkosten Totaal",
        purchase_other_total: "Overige kosten Totaal",
        purchase_shippingcosts_total: "Verzendkosten Totaal",
      },
      permission: {
        update_warehouse:
          "Geeft de gebruiker de mogelijkheid om warehouses te bewerken",
        update_stocklocation:
          "Geeft de gebruiker de mogelijkheid om stocklocations te bewerken",
        update_stock:
          "Geeft de gebruiker de mogelijkheid om stocks te bewerken",
        update_program:
          "Geeft de gebruiker de mogelijkheid om programs te bewerken",
        update_producttype:
          "Geeft de gebruiker de mogelijkheid om producttypes te bewerken",
        update_producttranslation:
          "Geeft de gebruiker de mogelijkheid om producttranslations te bewerken",
        update_productstatus:
          "Geeft de gebruiker de mogelijkheid om productstatuses te bewerken",
        update_productprice:
          "Geeft de gebruiker de mogelijkheid om productprices te bewerken",
        update_productmetalabel:
          "Geeft de gebruiker de mogelijkheid om productmetalabels te bewerken",
        update_productmeta:
          "Geeft de gebruiker de mogelijkheid om productmetas te bewerken",
        update_productimage:
          "Geeft de gebruiker de mogelijkheid om productimages te bewerken",
        update_productfiltermetalabel:
          "Geeft de gebruiker de mogelijkheid om productfiltermetalabels te bewerken",
        update_productfiltermeta:
          "Geeft de gebruiker de mogelijkheid om productfiltermetas te bewerken",
        update_productfilter:
          "Geeft de gebruiker de mogelijkheid om productfilters te bewerken",
        update_product:
          "Geeft de gebruiker de mogelijkheid om products te bewerken",
        update_plannable:
          "Geeft de gebruiker de mogelijkheid om plannables te bewerken",
        update_module:
          "Geeft de gebruiker de mogelijkheid om modules te bewerken",
        update_lesson:
          "Geeft de gebruiker de mogelijkheid om lessons te bewerken",
        update_course:
          "Geeft de gebruiker de mogelijkheid om courses te bewerken",
        update_appointment:
          "Geeft de gebruiker de mogelijkheid om appointments te bewerken",
        read_warehouse:
          "Geeft de gebruiker de mogelijkheid om warehouses te bekijken",
        read_stocklocation:
          "Geeft de gebruiker de mogelijkheid om stocklocations te bekijken",
        read_stock: "Geeft de gebruiker de mogelijkheid om stocks te bekijken",
        read_program:
          "Geeft de gebruiker de mogelijkheid om programs te bekijken",
        read_producttype:
          "Geeft de gebruiker de mogelijkheid om producttypes te bekijken",
        read_producttranslation:
          "Geeft de gebruiker de mogelijkheid om producttranslations te bekijken",
        read_productstatus:
          "Geeft de gebruiker de mogelijkheid om productstatuses te bekijken",
        read_productprice:
          "Geeft de gebruiker de mogelijkheid om productprices te bekijken",
        read_productmetalabel:
          "Geeft de gebruiker de mogelijkheid om productmetalabels te bekijken",
        read_productmeta:
          "Geeft de gebruiker de mogelijkheid om productmetas te bekijken",
        read_productimage:
          "Geeft de gebruiker de mogelijkheid om productimages te bekijken",
        read_productfiltermetalabel:
          "Geeft de gebruiker de mogelijkheid om productfiltermetalabels te bekijken",
        read_productfiltermeta:
          "Geeft de gebruiker de mogelijkheid om productfiltermetas te bekijken",
        read_productfilter:
          "Geeft de gebruiker de mogelijkheid om productfilters te bekijken",
        read_product:
          "Geeft de gebruiker de mogelijkheid om products te bekijken",
        read_plannable:
          "Geeft de gebruiker de mogelijkheid om plannables te bekijken",
        read_module:
          "Geeft de gebruiker de mogelijkheid om modules te bekijken",
        read_lesson:
          "Geeft de gebruiker de mogelijkheid om lessons te bekijken",
        read_course:
          "Geeft de gebruiker de mogelijkheid om courses te bekijken",
        read_appointment:
          "Geeft de gebruiker de mogelijkheid om appointments te bekijken",
        list_warehouse: "Toestaan om alle warehouses weer te geven",
        list_stocklocation: "Toestaan om alle stocklocations weer te geven",
        list_stock: "Toestaan om alle stocks weer te geven",
        list_program: "Toestaan om alle programs weer te geven",
        list_producttype: "Toestaan om alle producttypes weer te geven",
        list_producttranslation:
          "Toestaan om alle producttranslations weer te geven",
        list_productstatus: "Toestaan om alle productstatuses weer te geven",
        list_productprice: "Toestaan om alle productprices weer te geven",
        list_productmetalabel:
          "Toestaan om alle productmetalabels weer te geven",
        list_productmeta: "Toestaan om alle productmetas weer te geven",
        list_productimage: "Toestaan om alle productimages weer te geven",
        list_productfiltermetalabel:
          "Toestaan om alle productfiltermetalabels weer te geven",
        list_productfiltermeta:
          "Toestaan om alle productfiltermetas weer te geven",
        list_productfilter: "Toestaan om alle productfilters weer te geven",
        list_product: "Toestaan om alle products weer te geven",
        list_plannable: "Toestaan om alle plannables weer te geven",
        list_module: "Toestaan om alle modules weer te geven",
        list_lesson: "Toestaan om alle lessons weer te geven",
        list_course: "Toestaan om alle courses weer te geven",
        list_appointment: "Toestaan om alle appointments weer te geven",
        delete_warehouse:
          "Geeft de gebruiker de mogelijkheid om warehouses te verwijderen",
        delete_stocklocation:
          "Geeft de gebruiker de mogelijkheid om stocklocations te verwijderen",
        delete_stock:
          "Geeft de gebruiker de mogelijkheid om stocks te verwijderen",
        delete_program:
          "Geeft de gebruiker de mogelijkheid om programs te verwijderen",
        delete_producttype:
          "Geeft de gebruiker de mogelijkheid om producttypes te verwijderen",
        delete_producttranslation:
          "Geeft de gebruiker de mogelijkheid om producttranslations te verwijderen",
        delete_productstatus:
          "Geeft de gebruiker de mogelijkheid om productstatuses te verwijderen",
        delete_productprice:
          "Geeft de gebruiker de mogelijkheid om productprices te verwijderen",
        delete_productmetalabel:
          "Geeft de gebruiker de mogelijkheid om productmetalabels te verwijderen",
        delete_productmeta:
          "Geeft de gebruiker de mogelijkheid om productmetas te verwijderen",
        delete_productimage:
          "Geeft de gebruiker de mogelijkheid om productimages te verwijderen",
        delete_productfiltermetalabel:
          "Geeft de gebruiker de mogelijkheid om productfiltermetalabels te verwijderen",
        delete_productfiltermeta:
          "Geeft de gebruiker de mogelijkheid om productfiltermetas te verwijderen",
        delete_productfilter:
          "Geeft de gebruiker de mogelijkheid om productfilters te verwijderen",
        delete_product:
          "Geeft de gebruiker de mogelijkheid om products te verwijderen",
        delete_plannable:
          "Geeft de gebruiker de mogelijkheid om plannables te verwijderen",
        delete_module:
          "Geeft de gebruiker de mogelijkheid om modules te verwijderen",
        delete_lesson:
          "Geeft de gebruiker de mogelijkheid om lessons te verwijderen",
        delete_course:
          "Geeft de gebruiker de mogelijkheid om courses te verwijderen",
        delete_appointment:
          "Geeft de gebruiker de mogelijkheid om appointments te verwijderen",
        create_warehouse:
          "Geeft de gebruiker de mogelijkheid om nieuwe warehouses aan te maken",
        create_stocklocation:
          "Geeft de gebruiker de mogelijkheid om nieuwe stocklocations aan te maken",
        create_stock:
          "Geeft de gebruiker de mogelijkheid om nieuwe stocks aan te maken",
        create_program:
          "Geeft de gebruiker de mogelijkheid om nieuwe programs aan te maken",
        create_producttype:
          "Geeft de gebruiker de mogelijkheid om nieuwe producttypes aan te maken",
        create_producttranslation:
          "Geeft de gebruiker de mogelijkheid om nieuwe producttranslations aan te maken",
        create_productstatus:
          "Geeft de gebruiker de mogelijkheid om nieuwe productstatuses aan te maken",
        create_productprice:
          "Geeft de gebruiker de mogelijkheid om nieuwe productprices aan te maken",
        create_productmetalabel:
          "Geeft de gebruiker de mogelijkheid om nieuwe productmetalabels aan te maken",
        create_productmeta:
          "Geeft de gebruiker de mogelijkheid om nieuwe productmetas aan te maken",
        create_productimage:
          "Geeft de gebruiker de mogelijkheid om nieuwe productimages aan te maken",
        create_productfiltermetalabel:
          "Geeft de gebruiker de mogelijkheid om nieuwe productfiltermetalabels aan te maken",
        create_productfiltermeta:
          "Geeft de gebruiker de mogelijkheid om nieuwe productfiltermetas aan te maken",
        create_productfilter:
          "Geeft de gebruiker de mogelijkheid om nieuwe productfilters aan te maken",
        create_product:
          "Geeft de gebruiker de mogelijkheid om nieuwe products aan te maken",
        create_plannable:
          "Geeft de gebruiker de mogelijkheid om nieuwe plannables aan te maken",
        create_module:
          "Geeft de gebruiker de mogelijkheid om nieuwe modules aan te maken",
        create_lesson:
          "Geeft de gebruiker de mogelijkheid om nieuwe lessons aan te maken",
        create_course:
          "Geeft de gebruiker de mogelijkheid om nieuwe courses aan te maken",
        create_appointment:
          "Geeft de gebruiker de mogelijkheid om nieuwe appointments aan te maken",
      },
      meta_label: {
        lang: "Taal",
        label: "Label",
        key: "Key",
        add_new: "voeg nieuwe toe",
        add_new_filter: "Voeg nieuw label toe",
        close: "Sluiten",
        save_changes: "Opslaan",
      },
      filters: {
        updated_at: "Bijgewerkt op",
        pickorder: "Volgorde",
        name: "Naam",
        language: "Taal",
        description: "Omschrijving",
        deleted_at: "Verwijderd op",
        created_at: "Aangemaakt op",
        add: "Filter Aanmaken",
        active: "Actief",
        name_en: "Naam_nl",
        description_en: "omschrijving_nl",
      },
    },
    payments: {
      subscription: {
        suspended: "Geschorst",
        pending: "In behandeling",
        completed: "Afgerond",
        canceled: "Geannuleerd",
        active: "Actief",
      },
      status: {
        refund: {
          refunded: "Terugbetaald",
          queued: "Terugbetaling in wachtrij",
          processing: "Terugbetaling in behandeling",
          pending: "Terugbetaling in afwachting",
          failed: "Terugbetaling mislukt",
        },
        pending: "Pending",
        paid: "Paid",
        open: "Open",
        failed: "Failed",
        expired: "Expired",
        canceled: "Canceled",
        authorized: "Authorized",
      },
      provider: { mollie: "Mollie", invoice: "Factuur" },
      payment_method: {
        mollie: {
          sepa: "Mollie - Automatische incasso",
          paypal: "Mollie - Paypal",
          ideal: "Mollie - iDeal",
          creditcard: "Mollie - Creditcard",
        },
        invoice: "Factuur",
      },
    },
    reports: {
      report: "Rapportages",
      turnover_rate: " Omloopsnelheid",
    },
    orders: {
      bijenkorf: "Bijenkorf",
      suppliers: "Leveranciers",
      sales: "Verkoop",

      procurement: "Inkoop",
      permission: {
        update_return: [
          "Geeft de gebruiker de mogelijkheid om returns te bewerken",
          "Geeft de gebruiker de mogelijkheid om returns te bewerken",
        ],
        update_receipt:
          "Geeft de gebruiker de mogelijkheid om receipts te bewerken",
        update_quotation: [
          "Geeft de gebruiker de mogelijkheid om quotations te bewerken",
          "Geeft de gebruiker de mogelijkheid om quotations te bewerken",
        ],
        update_packageslip:
          "Geeft de gebruiker de mogelijkheid om packageslips te bewerken",
        update_order: [
          "Geeft de gebruiker de mogelijkheid om orders te bewerken",
          "Geeft de gebruiker de mogelijkheid om orders te bewerken",
        ],
        update_invoice: [
          "Geeft de gebruiker de mogelijkheid om invoices te bewerken",
          "Geeft de gebruiker de mogelijkheid om invoices te bewerken",
        ],
        read_return: [
          "Geeft de gebruiker de mogelijkheid om returns te bekijken",
          "Geeft de gebruiker de mogelijkheid om returns te bekijken",
        ],
        read_receipt:
          "Geeft de gebruiker de mogelijkheid om receipts te bekijken",
        read_quotation: [
          "Geeft de gebruiker de mogelijkheid om quotations te bekijken",
          "Geeft de gebruiker de mogelijkheid om quotations te bekijken",
        ],
        read_packageslip:
          "Geeft de gebruiker de mogelijkheid om packageslips te bekijken",
        read_order: [
          "Geeft de gebruiker de mogelijkheid om orders te bekijken",
          "Geeft de gebruiker de mogelijkheid om orders te bekijken",
        ],
        read_invoice: [
          "Geeft de gebruiker de mogelijkheid om invoices te bekijken",
          "Geeft de gebruiker de mogelijkheid om invoices te bekijken",
        ],
        list_return: [
          "Toestaan om alle returns weer te geven",
          "Toestaan om alle returns weer te geven",
        ],
        list_receipt: "Toestaan om alle receipts weer te geven",
        list_quotation: [
          "Toestaan om alle quotations weer te geven",
          "Toestaan om alle quotations weer te geven",
        ],
        list_packageslip: "Toestaan om alle packageslips weer te geven",
        list_order: [
          "Toestaan om alle orders weer te geven",
          "Toestaan om alle orders weer te geven",
        ],
        list_invoice: [
          "Toestaan om alle invoices weer te geven",
          "Toestaan om alle invoices weer te geven",
        ],
        delete_return: [
          "Geeft de gebruiker de mogelijkheid om returns te verwijderen",
          "Geeft de gebruiker de mogelijkheid om returns te verwijderen",
        ],
        delete_receipt:
          "Geeft de gebruiker de mogelijkheid om receipts te verwijderen",
        delete_quotation: [
          "Geeft de gebruiker de mogelijkheid om quotations te verwijderen",
          "Geeft de gebruiker de mogelijkheid om quotations te verwijderen",
        ],
        delete_packageslip:
          "Geeft de gebruiker de mogelijkheid om packageslips te verwijderen",
        delete_order: [
          "Geeft de gebruiker de mogelijkheid om orders te verwijderen",
          "Geeft de gebruiker de mogelijkheid om orders te verwijderen",
        ],
        delete_invoice: [
          "Geeft de gebruiker de mogelijkheid om invoices te verwijderen",
          "Geeft de gebruiker de mogelijkheid om invoices te verwijderen",
        ],
        create_return: [
          "Geeft de gebruiker de mogelijkheid om nieuwe returns aan te maken",
          "Geeft de gebruiker de mogelijkheid om nieuwe returns aan te maken",
        ],
        create_receipt:
          "Geeft de gebruiker de mogelijkheid om nieuwe receipts aan te maken",
        create_quotation: [
          "Geeft de gebruiker de mogelijkheid om nieuwe quotations aan te maken",
          "Geeft de gebruiker de mogelijkheid om nieuwe quotations aan te maken",
        ],
        create_packageslip:
          "Geeft de gebruiker de mogelijkheid om nieuwe packageslips aan te maken",
        create_order: [
          "Geeft de gebruiker de mogelijkheid om nieuwe orders aan te maken",
          "Geeft de gebruiker de mogelijkheid om nieuwe orders aan te maken",
        ],
        create_invoice: [
          "Geeft de gebruiker de mogelijkheid om nieuwe invoices aan te maken",
          "Geeft de gebruiker de mogelijkheid om nieuwe invoices aan te maken",
        ],
      },
      ordertype: {
        sales_return: "Retourbon",
        sales_return_received: "Retourontvangst",
        sales_quotation: "Verkoopofferte",
        sales_packageslip: "Pakbon",
        sales_order: "Verkooporder",
        sales_invoice_credit: "Creditfactuur",
        sales_invoice: "Factuur",
        sales_backorder: "Verkoop backorder",
        procurement_return: "Inkoop retour",
        procurement_receipt: "Inkoopbon",
        procurement_quotation: "Inkoopofferte",
        procurement_order_confirmation: "Inkooporderbevestiging",
        procurement_order: "Inkooporder",
        procurement_invoice_credit: "Inkoop Creditfactuur",
        procurement_invoice: "Inkoopfactuur",
        procurement_backorder: "Inkoop backorder",
        warehouse_sales_report: "Salesreport",
        warehouse_sent: "Verstuur interfiliaal",
        warehouse_received: "Ontvangen interfiliaal",
        stock_start: "Beginvoorraad",
      },
      orderstatus: {
        sent: "Verstuurd",
        ready_to_sent: "Klaar voor verzending",
        ready_to_collect: "Verzamel",
        on_hold: "In de wacht",
        delivered: "Afgeleverd",
        created: "Aangemaakt",
        confirmed: "Bevestigd",
        collecting: "Verzamelen",
        canceled: "Geannuleerd",
      },
      orderrow: {
        vat: "BTW",
        supplier_product_identifier: "Productcode Leverancier",
        remarks: "Opmerkingen",
        purchase_price_currency_id: "Valuta",
        purchase_price: "Inkoopprijs",
        purchase_currency: "Valuta",
        purchase_cost: "Inkoopkosten",
        product_id: "Product",
        product: "Product",
        pricePer: "Prijs per",
        price: "Prijs",
        expectedArrival: "Verwachte binnenkomst",
        discountPercentage: "Korting (%)",
        discount: "Korting",
        description: "Omschrijving",
        delivery_time: "Levertijd",
        completed: "Afgehandeld",
        backorder: "Nalevering",
        amount: "Aantal",
      },
      order: {
        zipcode: "Postcode",
        vat: "BTW",
        use_shipping_address: "Gebruik afleveradres",
        updated_by_user: "Bijgewerkt door gebruiker",
        update: "Order bewerken",
        total: "Totaal",
        subtotal: "Subtotaal",
        shipping_details: "Verzendgegevens",
        ship_zipcode: "Postcode",
        ship_lastname: "Achternaam",
        ship_firstname: "Voornaam",
        ship_country: "Land",
        ship_companyname: "Bedrijfsnaam",
        ship_city: "Plaats",
        ship_address2: "Adresregel 2",
        ship_address: "Adres",
        select_organization: "Selecteer organisatie",
        select_contact: "Selecteer contact",
        sales: "Verkopen",
        remarks: "Opmerkingen",
        relationdetails: "Relatiegegevens",
        read: "Order openen",
        procurements: "Inkopen",
        phone: "Telefoonnummer",
        organization_name: "Organisatie",
        organization: "Organisatie",
        ordertypes: "Ordertypen",
        ordertype: "Ordertype",
        orderstatus: "Orderstatus",
        orders: "BESTELLINGEN",
        order_item: "Order",
        order_date: "Orderdatum",
        lastname: "Achternaam",
        language: "Taal",
        invoice_details: "Factuurgegevens",
        firstname: "Voornaam",
        email: "Emailadres",
        details: "Ordergegevens",
        delete: "Order verwijderen",
        created_by_user: "Aangemaakt door gebruiker",
        create: "Order aanmaken",
        country: "Land",
        contact_name: "Contact",
        contact: "Contact",
        companyname: "Bedrijfsnaam",
        city: "Plaats",
        buttons: {
          sales_invoice: "Factuur",
          return_slip: "Retourbon",
          productlist_no_price: "Productlijst (zonder prijzen)",
          productlist: "Productlijst",
          product_labels: "Productlabels",
          product_packagelabels: "Productetiketten",
          picklist: "Verzamellijst",
          packing_slip: "Pakbon",
          package_labels: "Verzendetiketten",
          orderpicking: "Orderverzamelen",
          invoice: "Factuur",
          credit_invoice: "Creditfactuur",
        },
        address2: "Adresregel 2",
        address: "Adres",
        mutation: "Mutatie",
        files: "Bestanden",
      },
      customers: "Klanten",
      add_new_organization: "Organisatie toevoegen",
      add_new_contact: "Contact toevoegen",
    },
    newsletter: {
      leffit: {
        newsletter: {
          suppliers: "Leveranciers",
          customers: "Klanten",
          add_new_organization: "Organisatie toevoegen",
          add_new_contact: "Contact toevoegen",
        },
      },
    },
    email: {
      templates: "Templates",
      add: "Toevoegen",
      validate_email: "Validatie emailadres",
      signup_invitation: "Uitnodiging account aanmaken",
      password_reset: "Wachtwoord resetten",
      order_confirmation: "Orderbevestiging",
      account_overview: "Overzicht accountgegevens",
    },
    emails: {
      title: "Titel",
      language: "Taal",
      name: "naam",
      subject: "onderwerp",
      created_at: "aangemaakt_op",
      updated_at: "geupdate_op",
      deleted_at: "verwijderd_op",
      template_name: "template naam",
      identifier: "identificatiecode",
      description: "omschrijving",
      submit: "opslaan",
    },
    crm: {
      import: "importeren",
      export: "exporteren",
      suppliers: "Leveranciers",
      settings: "Instellingen",
      permission: {
        update_organizationtype:
          "Geeft de gebruiker de mogelijkheid om organizationtypes te bewerken",
        update_organization:
          "Geeft de gebruiker de mogelijkheid om organizations te bewerken",
        update_contact:
          "Geeft de gebruiker de mogelijkheid om contacts te bewerken",
        update_addresstype:
          "Geeft de gebruiker de mogelijkheid om addresstypes te bewerken",
        update_address:
          "Geeft de gebruiker de mogelijkheid om addresses te bewerken",
        read_organizationtype:
          "Geeft de gebruiker de mogelijkheid om organizationtypes te bekijken",
        read_organization:
          "Geeft de gebruiker de mogelijkheid om organizations te bekijken",
        read_contact:
          "Geeft de gebruiker de mogelijkheid om contacts te bekijken",
        read_addresstype:
          "Geeft de gebruiker de mogelijkheid om addresstypes te bekijken",
        read_address:
          "Geeft de gebruiker de mogelijkheid om addresses te bekijken",
        list_organizationtype:
          "Toestaan om alle organizationtypes weer te geven",
        list_organization: "Toestaan om alle organizations weer te geven",
        list_contact: "Toestaan om alle contacts weer te geven",
        list_addresstype: "Toestaan om alle addresstypes weer te geven",
        list_address: "Toestaan om alle addresses weer te geven",
        delete_organizationtype:
          "Geeft de gebruiker de mogelijkheid om organizationtypes te verwijderen",
        delete_organization:
          "Geeft de gebruiker de mogelijkheid om organizations te verwijderen",
        delete_contact:
          "Geeft de gebruiker de mogelijkheid om contacts te verwijderen",
        delete_addresstype:
          "Geeft de gebruiker de mogelijkheid om addresstypes te verwijderen",
        delete_address:
          "Geeft de gebruiker de mogelijkheid om addresses te verwijderen",
        create_organizationtype:
          "Geeft de gebruiker de mogelijkheid om nieuwe organizationtypes aan te maken",
        create_organization:
          "Geeft de gebruiker de mogelijkheid om nieuwe organizations aan te maken",
        create_contact:
          "Geeft de gebruiker de mogelijkheid om nieuwe contacts aan te maken",
        create_addresstype:
          "Geeft de gebruiker de mogelijkheid om nieuwe addresstypes aan te maken",
        create_address:
          "Geeft de gebruiker de mogelijkheid om nieuwe addresses aan te maken",
      },
      organizationtypes: "Organisatievorm",
      organizationtype: {
        wholesale: ["Groothandel", "Groothandel"],
        supplier: ["Leverancier", "Leverancier"],
        retail: ["Detailhandel", "Detailhandel"],
        customer_supplier: ["Klant en Leverancier", "Klant en Leverancier"],
        customer: ["Klant", "Klant"],
        consumer: ["Consument", "Consument"],
      },
      organizations: "Organisaties",
      organization: {
        add_new: "Maak nieuwe organisatie",
        zipcode: "Postcode",
        vat_number: "BTW nummer",
        updated_at: "Bijgewerkt op",
        swift_code: "Swift code",
        status_id: "Status",
        remarks: "Opmerkingen",
        picture: "Afbeelding",
        phone: "Telefoonnummer",
        parent_id: "Bovenliggend item",
        organizationtype_id: "Organisatievorm",
        name: "Naam",
        mobile: "Mobiel nummer",
        language: "Taal",
        inactive: "Inactief",
        iban_number: "IBAN rekeningnummer",
        iban_name: "IBAN naam",
        fax: "Faxnummer",
        email: "Emailadres",
        deleted_at: "Verwijderd op",
        created_at: "Aangemaakt op",
        country: "Land",
        coc: "Kamer van Koophandel",
        city: "Plaats",
        address2: "Adresregel 2",
        address: "Adres",
        active: "Actief",
        creditor_number: "crediteur nummer",
        creditor_test: "CREDITEUR TEST",
        debtor_number: "debiteur nummer",
        profile_image: "Profielfoto",
        basic_details: "Algemene gegevens ",
        switf_code: "Swift code",
        other_details: "Overige gegevens",
        contact: "CONTACTEN",
        admins: "BEHEERDERS",
        orders: "BESTELLINGEN",
        products: "PRODUCTEN",
        edit: "Bewerken",
        generate_creditor_number: "Genereer Crediteurnummer",
        generate_debitor_number: "Genereer Debiteurnummer",
        generating: "Genereren",
        currency: "Valuta",
      },
      customers: "Klanten",
      crm: "CRM",
      contacts: "Contacten",
      contact: {
        zipcode: "Postcode",
        vat_number: "BTW nummer",
        updated_at: "Bijgewerkt op",
        title: "Aanhef",
        swift_code: "Swift code",
        status_id: "Status",
        remarks: "Opmerkingen",
        prelastname: "Tussenvoegsel",
        picture: "Afbeelding",
        phone: "Telefoonnummer",
        password: "Wachtwoord",
        parent_id: "Bovenliggend item",
        parent: "Bovenliggend contact",
        other_details: "Overige gegevens",
        optin: "Opt in",
        mobile: "Mobiel nummer",
        lastname: "Achternaam",
        language: "Taal",
        inactive: "Inactief",
        iban_number: "IBAN rekeningnummer",
        iban_name: "IBAN naam",
        generate_password: "Genereer nieuw wachtwoord",
        gender_woman: "Vrouw",
        gender_unknown: "Onbekend",
        gender_male: "Man",
        gender: "Geslacht",
        function: "Functie",
        firstname: "Voornaam",
        financial_details: "Financi\u00eble gegevens",
        fax: "Faxnummer",
        email: "Emailadres",
        deleted_at: "Verwijderd op",
        created_at: "Aangemaakt op",
        country: "Land",
        coc: "Kamer van Koophandel",
        city: "Plaats",
        birthday: "Verjaardag",
        age: "Leeftijd",
        basic_details: "Basis gegevens",
        address2: "Adresregel 2",
        address: "Adres",
        active: "Actief",
        name: "Naam",
        message: "BERICHTEN",
        create: "MAKEN",
        filter_by_status: "Filteren op Status",
        user: "Gebruiker",
        orgnization: "Organisatie",
        status: "Statan",
        new_order: "MAAK NIEUWE BESTELLING AAN",
        admins: "BEHEERDERS",
        display_name: "Weergavenaam",
        orgnizations: "ORGANISATIES",
        products: "PRODUCTEN",
        remark: "OPMERKINGEN",
      },
      addresstypes: "Adrestype",
      addresstype: {
        visit: ["Bezoekadres", "Bezoekadres"],
        shipping: ["Verzendadres", "Verzendadres"],
        post: ["Postadres", "Postadres"],
        invoice: ["Factuuradres", "Factuuradres"],
      },
      addresses: "Adressen",
      messages: "Berichten",
      messagess: {
        title: "Titel",
        orgnization: "Organisatie",
        user: "Gebruiker",
        status: "Status",
      },
      address: {
        zipcode: "Postcode",
        updated_at: "Bijgewerkt op",
        phone: "Telefoonnnummer",
        name: "Naam",
        mobile: "Mobiel",
        fax: "Faxnummer",
        email: "Emailadres",
        deleted_at: "Verwijderd op",
        created_at: "Aangemaakt op",
        country: "Land",
        code: "Code",
        city: "Plaats",
        address2: "Adresregel 2",
        address: "Adres",
        display_name: "Display Name",
      },
      add_new_organization: "Organisatie toevoegen",
      add_new_contact: "Contact toevoegen",
    },
    cms: {
      section: {
        slider_with_title_subtitle: "Slider met titel en subtitel",
        slider_with_title_content_button: "Slider met titel, content en knop",
        slider_with_title_content: "Slider met titel en content",
        slider_with_title_button: "Slider met titel en knop",
        slider_with_title: "Slider met titel",
        slider: "Slider",
        newsletter_subscribe_title_subtitle_image:
          "Nieuwsbrief inschrijving met titel, subtitel en afbeelding",
        model_grid: "Grid met objecten",
        header: "Header",
        head: "Head onderdeel",
        footer: "Footer",
      },
      permission: {
        update_sectiontype:
          "Geeft de gebruiker de mogelijkheid om sectiontypes te bewerken",
        update_section:
          "Geeft de gebruiker de mogelijkheid om sections te bewerken",
        update_pagetype:
          "Geeft de gebruiker de mogelijkheid om pagetypes te bewerken",
        update_page: "Geeft de gebruiker de mogelijkheid om pages te bewerken",
        update_layout:
          "Geeft de gebruiker de mogelijkheid om layouts te bewerken",
        update_fieldtype:
          "Geeft de gebruiker de mogelijkheid om fieldtypes te bewerken",
        update_field:
          "Geeft de gebruiker de mogelijkheid om fields te bewerken",
        update_blocktype:
          "Geeft de gebruiker de mogelijkheid om blocktypes te bewerken",
        read_sectiontype:
          "Geeft de gebruiker de mogelijkheid om sectiontypes te bekijken",
        read_section:
          "Geeft de gebruiker de mogelijkheid om sections te bekijken",
        read_pagetype:
          "Geeft de gebruiker de mogelijkheid om pagetypes te bekijken",
        read_page: "Geeft de gebruiker de mogelijkheid om pages te bekijken",
        read_layout:
          "Geeft de gebruiker de mogelijkheid om layouts te bekijken",
        read_fieldtype:
          "Geeft de gebruiker de mogelijkheid om fieldtypes te bekijken",
        read_field: "Geeft de gebruiker de mogelijkheid om fields te bekijken",
        read_blocktype:
          "Geeft de gebruiker de mogelijkheid om blocktypes te bekijken",
        list_sectiontype: "Toestaan om alle sectiontypes weer te geven",
        list_section: "Toestaan om alle sections weer te geven",
        list_pagetype: "Toestaan om alle pagetypes weer te geven",
        list_page: "Toestaan om alle pages weer te geven",
        list_layout: "Toestaan om alle layouts weer te geven",
        list_fieldtype: "Toestaan om alle fieldtypes weer te geven",
        list_field: "Toestaan om alle fields weer te geven",
        list_blocktype: "Toestaan om alle blocktypes weer te geven",
        delete_sectiontype:
          "Geeft de gebruiker de mogelijkheid om sectiontypes te verwijderen",
        delete_section:
          "Geeft de gebruiker de mogelijkheid om sections te verwijderen",
        delete_pagetype:
          "Geeft de gebruiker de mogelijkheid om pagetypes te verwijderen",
        delete_page:
          "Geeft de gebruiker de mogelijkheid om pages te verwijderen",
        delete_layout:
          "Geeft de gebruiker de mogelijkheid om layouts te verwijderen",
        delete_fieldtype:
          "Geeft de gebruiker de mogelijkheid om fieldtypes te verwijderen",
        delete_field:
          "Geeft de gebruiker de mogelijkheid om fields te verwijderen",
        delete_blocktype:
          "Geeft de gebruiker de mogelijkheid om blocktypes te verwijderen",
        create_sectiontype:
          "Geeft de gebruiker de mogelijkheid om nieuwe sectiontypes aan te maken",
        create_section:
          "Geeft de gebruiker de mogelijkheid om nieuwe sections aan te maken",
        create_pagetype:
          "Geeft de gebruiker de mogelijkheid om nieuwe pagetypes aan te maken",
        create_page:
          "Geeft de gebruiker de mogelijkheid om nieuwe pages aan te maken",
        create_layout:
          "Geeft de gebruiker de mogelijkheid om nieuwe layouts aan te maken",
        create_fieldtype:
          "Geeft de gebruiker de mogelijkheid om nieuwe fieldtypes aan te maken",
        create_field:
          "Geeft de gebruiker de mogelijkheid om nieuwe fields aan te maken",
        create_blocktype:
          "Geeft de gebruiker de mogelijkheid om nieuwe blocktypes aan te maken",
      },
      pagetypetype: { homepage: "Homepage" },
      field: {
        url: "url",
        title: "title",
        target: "target",
        subtitle: "subtitle",
        slug: "slug",
        seo_title: "Paginatitel",
        seo_keywords: "seo_keywords",
        seo_description: "SEO descrtiption",
        page_id: "Pagina",
        model_id: "Model",
        image: "image",
        content: "content",
        button: "button",
        align: "centreer",
        address: "address",
      },
      blocktypes: "Blocktypen",
      blocktype: {
        title_image: "Titel en afbeelding",
        title_and_button: "titel en knop",
        title: "Titel",
        table: "Tabel",
        subtitle: "Subtitel",
        slider_with_title_subtitle: "Slider",
        slider_with_title_content_button: "Slider",
        slider_with_title_content: "Slider",
        slider_with_title_button: "Slider",
        slider_with_title: "Slider",
        slider: "Slider",
        seo: "Seo",
        newsletter_action: "Nieuwsbrief actie",
        name: "Naam",
        menu: "Menu",
        image_content: "titel kolommen afbeelding en content",
        image_button: "Afbeelding en knop",
        image: "Afbeelding",
        google_map: "Google Map",
        content_with_title_and_image: "Content met titel en afbeelding",
        content_with_title_and_button: "Titel met content en knop",
        content_with_title: "Content met titel",
        content: "Content",
        contact_form: "Contactformulier",
        button: "Knop",
      },
    },
    websitehome: {
      general_setting: "algemene instellingen",
      landing_page: "landingspagina",
      in_slug: "seo vriendelijke url",
      name: "naam",
      slug: "seo url",
      title: "titel",
      description: "omschrijving",
      keyword: "trefwoorden",
      update: "opslaan",
    },
  },
  lefficient: {
    system: {
      body: "lichaam",
      add_photo: "Toevoegen",
      filename: "Bestandsnaam",
      title: "Title",
      img_alt: "Omschrijving",
      warehouse: "Magazijn",
      stockloc: "Voorraadlocatie",
      stocktype: "Type",
      amount: "Aantal",
      action: "Actie",
      ms: "Mw",
      mr: "Dhr",
      yes: "Ja",
      update: "Bijwerken",
      testmode: "TESTVERSIE",
      settings: "Instellingen",
      sent: "Verstuur",
      search: "Zoeken",
      save: "Opslaan",
      results_no: "Geen resultaten gevonden...",
      redirecting: "Doorverwijzen",
      read: "Open",
      password_requesting: "Nieuw wachtwoord aan het aanvragen",
      password_request: "Vraag nieuw wachtwoord aan",
      orders: "Orders",
      no: "Nee",
      loading: "Laden",
      language: "Taal",
      edit_field: "{0} bewerken",
      edit: "Bewerken",
      delete: "Verwijder",
      dashboard: "Dashboard",
      create_single: "{0} aanmaken",
      create: "Aanmaken",
      copy_to: "Kopieer naar",
      continue: "Doorgaan",
      confirm: "Bevestig",
      close: "Sluiten",
      cancel: "Annuleer",
      contacts: "Contacten",
      organizations: "Organisaties",
      addresses: "Adressen",
      messages: "Berichten",
      products: "Producten",
      producttypes: "Producttypen ",
      productstatuses: "Productstatus",
      sales: "Verkoop",
      sales_quotation: "Verkoopofferte",
      sales_order: "Verkooporder",
      sales_packageslip: "Pakbon",
      sales_invoice: "Verkoopfactuur",
      sales_return: "Verkoop retour",
      sales_invoice_credit: "Verkoop creditfactuur",
    },
    login: {
      signup_long: "Maak een nieuwe account",
      signup: "Registreer",
      passwords_min_int:
        "Het wachtwoord moet minimaal 1 numeriek karakter (0-9) bevatten",
      passwords_min_capital:
        "Het wachtwoord moet minimaal 1 hoofdletter (A-Z) bevatten",
      passwords_min_8: "Het wachtwoord moet minimaal 8 karakters bevatten",
      passwords_do_not_match: "Wachtwoorden komen niet overeen",
      password_reset_verify: "Verzoek wordt geverifieerd",
      password_reset_succes:
        "Uw wachtwoord is gereset, u kunt nu opnieuw inloggen",
      password_request: "Nieuw wachtwoord wordt aangevraagd",
      password_forgot: "Wachtwoord vergeten?",
      password_create: "Maak en bevstig een nieuw wachtwoord",
      password_change: "Wijzig wachtwoord",
      logout: "Uitloggen",
      login_success_message: "Login succesvol",
      login: "Login",
    },
    leffit: {
      users: "Gebruikers",
      user: {
        zipcode: "Postcode",
        website: "Website",
        vat_number: "BTW nummer",
        userstatus: "Status",
        updated_at: "Bijgewerkt op",
        title: "Aanhef",
        swift_code: "Swift code",
        status_id: "Status",
        settings: "Instellingen",
        remarks: "Opmerkingen",
        profile_image: "Afbeelding",
        prelastname: "Tussenvoegsel",
        phone: "Telefoonnummer",
        password: "Wachtwoord",
        parent_id: "Bovenliggend item",
        optin: "Opt in",
        mobile: "Mobiel nummer",
        member_since: "Lid sinds",
        member_end: "Lid tot",
        lastname: "Achternaam",
        language: "Taal",
        iban_number: "IBAN rekeningnummer",
        iban_name: "IBAN naam",
        gender: "Geslacht",
        function: "Functie",
        firstname: "Voornaam",
        fax: "Faxnummer",
        email_verified_at: "Emailadres gevalideerd op",
        email: "Emailadres",
        display_name: "Weergavenaam",
        deleted_at: "Verwijderd op",
        date_of_birth: "Geboortedatum",
        created_at: "Aangemaakt op",
        country: "Land",
        coc: "Kamer van Koophandel",
        city: "Plaats",
        birthday: "Verjaardag",
        address2: "Adresregel 2",
        address: "Adres",
        about_me: "Over mij",
        //Edit User
        "edit-user": "Bewerk gebruiker",
        update: "bijwerken",
      },
      settings: {
        users: "Gebruikers",
        roles: "Rollen",
        permissions: "Rechten",
        generator: "Generator",
        currencies: "Valuta's",
        packages: "Module's",
      },
      permission: {
        update_user: "Geeft de gebruiker de mogelijkheid om users te bewerken",
        update_translation:
          "Geeft de gebruiker de mogelijkheid om translations te bewerken",
        update_setting:
          "Geeft de gebruiker de mogelijkheid om settings te bewerken",
        update_role: "Geeft de gebruiker de mogelijkheid om roles te bewerken",
        update_mailtemplate:
          "Geeft de gebruiker de mogelijkheid om mailtemplates te bewerken",
        update_import:
          "Geeft de gebruiker de mogelijkheid om imports te bewerken",
        update_generator:
          "Geeft de gebruiker de mogelijkheid om generators te bewerken",
        update_export:
          "Geeft de gebruiker de mogelijkheid om exports te bewerken",
        update_currency:
          "Geeft de gebruiker de mogelijkheid om currencies te bewerken",
        update_coresetting:
          "Geeft de gebruiker de mogelijkheid om coresettings te bewerken",
        read_user: "Geeft de gebruiker de mogelijkheid om users te bekijken",
        read_translation:
          "Geeft de gebruiker de mogelijkheid om translations te bekijken",
        read_setting:
          "Geeft de gebruiker de mogelijkheid om settings te bekijken",
        read_role: "Geeft de gebruiker de mogelijkheid om roles te bekijken",
        read_mailtemplate:
          "Geeft de gebruiker de mogelijkheid om mailtemplates te bekijken",
        read_import:
          "Geeft de gebruiker de mogelijkheid om imports te bekijken",
        read_generator:
          "Geeft de gebruiker de mogelijkheid om generators te bekijken",
        read_export:
          "Geeft de gebruiker de mogelijkheid om exports te bekijken",
        read_currency:
          "Geeft de gebruiker de mogelijkheid om currencies te bekijken",
        read_coresetting:
          "Geeft de gebruiker de mogelijkheid om coresettings te bekijken",
        list_user: "Toestaan om alle users weer te geven",
        list_translation: "Toestaan om alle translations weer te geven",
        list_setting: "Toestaan om alle settings weer te geven",
        list_role: "Toestaan om alle roles weer te geven",
        list_mailtemplate: "Toestaan om alle mailtemplates weer te geven",
        list_import: "Toestaan om alle imports weer te geven",
        list_generator: "Toestaan om alle generators weer te geven",
        list_export: "Toestaan om alle exports weer te geven",
        list_currency: "Toestaan om alle currencies weer te geven",
        list_coresetting: "Toestaan om alle coresettings weer te geven",
        delete_user:
          "Geeft de gebruiker de mogelijkheid om users te verwijderen",
        delete_translation:
          "Geeft de gebruiker de mogelijkheid om translations te verwijderen",
        delete_setting:
          "Geeft de gebruiker de mogelijkheid om settings te verwijderen",
        delete_role:
          "Geeft de gebruiker de mogelijkheid om roles te verwijderen",
        delete_mailtemplate:
          "Geeft de gebruiker de mogelijkheid om mailtemplates te verwijderen",
        delete_import:
          "Geeft de gebruiker de mogelijkheid om imports te verwijderen",
        delete_generator:
          "Geeft de gebruiker de mogelijkheid om generators te verwijderen",
        delete_export:
          "Geeft de gebruiker de mogelijkheid om exports te verwijderen",
        delete_currency:
          "Geeft de gebruiker de mogelijkheid om currencies te verwijderen",
        delete_coresetting:
          "Geeft de gebruiker de mogelijkheid om coresettings te verwijderen",
        create_user:
          "Geeft de gebruiker de mogelijkheid om nieuwe users aan te maken",
        create_translation:
          "Geeft de gebruiker de mogelijkheid om nieuwe translations aan te maken",
        create_setting:
          "Geeft de gebruiker de mogelijkheid om nieuwe settings aan te maken",
        create_role:
          "Geeft de gebruiker de mogelijkheid om nieuwe roles aan te maken",
        create_mailtemplate:
          "Geeft de gebruiker de mogelijkheid om nieuwe mailtemplates aan te maken",
        create_import:
          "Geeft de gebruiker de mogelijkheid om nieuwe imports aan te maken",
        create_generator:
          "Geeft de gebruiker de mogelijkheid om nieuwe generators aan te maken",
        create_export:
          "Geeft de gebruiker de mogelijkheid om nieuwe exports aan te maken",
        create_currency:
          "Geeft de gebruiker de mogelijkheid om nieuwe currencies aan te maken",
        create_coresetting:
          "Geeft de gebruiker de mogelijkheid om nieuwe coresettings aan te maken",
      },
      language: "Taal",
    },
    languages: {
      dutch: "Nederlands",
      english: "Engels",
      nl: {
        zu: "Zoeloe",
        zh: "Chinese talen",
        za: "Zhuang, Tsjoeang",
        yo: "Yoruba",
        yi: "Jiddisch",
        xh: "Xhosa",
        wo: "Wolof",
        wa: "Waals",
        vo: "Volap\u00fck, Volapuk",
        vi: "Vietnamees",
        ve: "Venda",
        uz: "Oezbeeks",
        ur: "Urdu",
        uk: "Oekra\u00efens",
        ug: "Oeigoers",
        ty: "Tahitiaans",
        tw: "Twi",
        tt: "Tataars",
        ts: "Tsonga",
        tr: "Turks",
        to: "Tongaans, Tonga",
        tn: "Tswana",
        tl: "Tagalog",
        tk: "Turkmeens",
        ti: "Tigrinya",
        th: "Thai",
        tg: "Tadzjieks",
        te: "Telugu, Teloegoe",
        ta: "Tamil",
        sw: "Swahili",
        sv: "Zweeds",
        su: "Soendanees",
        st: "Zuid-Sotho",
        ss: "Swazi",
        sr: "Servisch",
        sq: "Albanees",
        so: "Somalisch",
        sn: "Shona",
        sm: "Samoaans",
        sl: "Sloveens",
        sk: "Slowaaks",
        si: "Singalees",
        sg: "Sango",
        se: "Noord-Samisch\u00a0(Noord-Laps)",
        sd: "Sindhi",
        sc: "Sardijns",
        sa: "Sanskriet",
        rw: "Kinyarwanda",
        ru: "Russisch",
        ro: "Roemeens",
        rn: "Kirundi",
        rm: "Reto-Romaans",
        qu: "Quechua",
        pt: "Portugees",
        ps: "Pasjtoe",
        pl: "Pools",
        pi: "Pali",
        pa: "Punjabi",
        os: "Ossetisch",
        or: "Odia\u00a0(Oriya)",
        om: "Afaan Oromo",
        oj: "Ojibweg",
        oc: "Occitaans",
        ny: "Nyanja",
        nv: "Navajo",
        nr: "Zuid-Ndebele",
        no: "Noors",
        nn: "Nynorsk\u00a0(Nieuw-Noors)",
        nl: "Nederlands",
        ng: "Ndonga",
        ne: "Nepalees",
        nd: "Noord-Ndebele",
        nb: "Bokm\u00e5l-Noors",
        na: "Nauruaans",
        my: "Birmaans, Birmees",
        mt: "Maltees",
        ms: "Maleis",
        mr: "Marathi",
        mn: "Mongools",
        ml: "Malayalam",
        mk: "Macedonisch",
        mi: "Maori",
        mh: "Marshallees",
        mg: "Plateaumalagasi",
        lv: "Lets",
        lu: "Luba-Katanga",
        lt: "Litouws",
        lo: "Laotiaans",
        ln: "Lingala",
        li: "Limburgs",
        lg: "Luganda",
        lb: "Luxemburgs",
        la: "Latijn",
        ky: "Kirgizisch",
        kw: "Cornisch",
        kv: "Zurjeens\u00a0(Komi)",
        ku: "Koerdisch",
        ks: "Kasjmiri",
        kr: "Kanuri",
        ko: "Koreaans",
        kn: "Kannada\u00a0(Kanarees, Kanara)",
        km: "Khmer, Cambodjaans",
        kl: "Groenlands",
        kk: "Kazachs",
        kj: "Kwanyama",
        ki: "Gikuyu",
        kg: "Kongo",
        ka: "Georgisch",
        jv: "Javaans",
        ja: "Japans",
        iu: "Inuktitut",
        it: "Italiaans",
        is: "IJslands",
        io: "Ido",
        ik: "Inupiak",
        ii: "Yi",
        ig: "Igbo",
        ie: "Interlingue",
        id: "Indonesisch",
        ia: "Interlingua",
        hz: "Herero",
        hy: "Armeens",
        hu: "Hongaars",
        ht: "Ha\u00eftiaans Creools",
        hr: "Kroatisch",
        ho: "Hiri Motu",
        hi: "Hindi",
        he: "Hebreeuws",
        ha: "Hausa",
        gv: "Manx-Gaelisch",
        gu: "Gujarati",
        gn: "Guaran\u00ed",
        gl: "Galicisch",
        gd: "Schots-Gaelisch",
        ga: "Iers",
        fy: "Westerlauwers Fries",
        fr: "Frans",
        fo: "Faer\u00f6ers",
        fj: "Fijisch",
        fi: "Fins",
        ff: "Fula",
        fa: "Perzisch\u00a0(Farsi)",
        eu: "Baskisch",
        et: "Estisch",
        es: "Spaans",
        eo: "Esperanto",
        en: "Engels",
        el: "Grieks",
        ee: "Ewe",
        dz: "Dzongkha",
        dv: "Divehi",
        de: "Duits",
        da: "Deens",
        cy: "Welsh",
        cv: "Tsjoevasjisch",
        cu: "Kerkslavisch",
        cs: "Tsjechisch",
        cr: "Cree",
        co: "Corsicaans",
        ch: "Chamorro",
        ce: "Tsjetsjeens",
        ca: "Catalaans",
        bs: "Bosnisch",
        br: "Bretons",
        bo: "Tibetaans",
        bn: "Bengaals",
        bm: "Bambara",
        bi: "Bislama",
        bh: "Bihari",
        bg: "Bulgaars",
        be: "Wit-Russisch\u00a0(Belarussisch)",
        ba: "Basjkiers",
        az: "Azerbeidzjaans",
        ay: "Aymara",
        av: "Avaars",
        as: "Assamees",
        ar: "Arabisch",
        an: "Aragonees",
        am: "Amhaars",
        ak: "Akan",
        af: "Afrikaans",
        ae: "Avestisch",
        ab: "Abchazisch",
        aa: "Afar",
      },
    },
    error: { field_empty: "{field} niet ingevuld" },
    countries: {
      ZW: "Zimbabwe",
      ZM: "Zambia",
      ZA: "Zuid-Afrika",
      YT: "Mayotte",
      YE: "Jemen",
      WS: "Samoa",
      WF: "Wallis en Futuna",
      VU: "Vanuatu",
      VN: "Vietnam",
      VI: "Amerikaanse Maagdeneilanden",
      VG: "Britse Maagdeneilanden",
      VE: "Venezuela",
      VC: "Saint Vincent en de Grenadines",
      VA: "Vaticaanstad",
      UZ: "Oezbekistan",
      UY: "Uruguay",
      US: "Verenigde Staten",
      UM: "Kleine Pacifische eilanden van de V.S.",
      UG: "Oeganda",
      UA: "Oekra&iuml;ne",
      TZ: "Tanzania",
      TW: "Taiwan",
      TV: "Tuvalu",
      TT: "Trinidad en Tobago",
      TR: "Turkije",
      TO: "Tonga",
      TN: "Tunesi&euml;",
      TM: "Turkmenistan",
      TL: "Oost-Timor",
      TK: "Tokelau",
      TJ: "Tadzjikistan",
      TH: "Thailand",
      TG: "Togo",
      TF: "Franse Zuidelijke en Antarctische Gebieden",
      TD: "Tsjaad",
      TC: "Turks- en Caicoseilanden",
      SZ: "Swaziland",
      SY: "Syri&euml;",
      SX: "Sint Maarten",
      SV: "El Salvador",
      ST: "Sao Tom&eacute; en Principe",
      SS: "Zuid-Soedan",
      SR: "Suriname",
      SO: "Somali&euml;",
      SN: "Senegal",
      SM: "San Marino",
      SL: "Sierra Leone",
      SK: "Slowakije",
      SJ: "Spitsbergen en Jan Mayen",
      SI: "Sloveni&euml;",
      SH: "Sint-Helena, Ascension en Tristan da Cunha",
      SG: "Singapore",
      SE: "Zweden",
      SD: "Soedan",
      SC: "Seychellen",
      SB: "Salomonseilanden",
      SA: "Saoedi-Arabi&euml;",
      RW: "Rwanda",
      RU: "Rusland",
      RS: "Servi&euml;",
      RO: "Roemeni&euml;",
      RE: "R&eacute;union",
      QA: "Qatar",
      PY: "Paraguay",
      PW: "Palau",
      PT: "Portugal",
      PS: "Palestina",
      PR: "Puerto Rico",
      PN: "Pitcairneilanden",
      PM: "Saint-Pierre en Miquelon",
      PL: "Polen",
      PK: "Pakistan",
      PH: "Filipijnen",
      PG: "Papoea-Nieuw-Guinea",
      PF: "Frans-Polynesi&euml;",
      PE: "Peru",
      PA: "Panama",
      OM: "Oman",
      NZ: "Nieuw-Zeeland",
      NU: "Niue",
      NR: "Nauru",
      NP: "Nepal",
      NO: "Noorwegen",
      NL: "Nederland",
      NI: "Nicaragua",
      NG: "Nigeria",
      NF: "Norfolk",
      NE: "Niger",
      NC: "Nieuw-Caledoni&euml;",
      NA: "Namibi&euml;",
      MZ: "Mozambique",
      MY: "Maleisi&euml;",
      MX: "Mexico",
      MW: "Malawi",
      MV: "Maldiven",
      MU: "Mauritius",
      MT: "Malta",
      MS: "Montserrat",
      MR: "Mauritani&euml;",
      MQ: "Martinique",
      MP: "Noordelijke Marianen",
      MO: "Macau",
      MN: "Mongoli&euml;",
      MM: "Myanmar",
      ML: "Mali",
      MK: "Macedoni&euml;",
      MH: "Marshalleilanden",
      MG: "Madagaskar",
      MF: "Sint-Maarten",
      ME: "Montenegro",
      MD: "Moldavi&euml;",
      MC: "Monaco",
      MA: "Marokko",
      LY: "Libi&euml;",
      LV: "Letland",
      LU: "Luxemburg",
      LT: "Litouwen",
      LS: "Lesotho",
      LR: "Liberia",
      LK: "Sri Lanka",
      LI: "Liechtenstein",
      LC: "Saint Lucia",
      LB: "Libanon",
      LA: "Laos",
      KZ: "Kazachstan",
      KY: "Kaaimaneilanden",
      KW: "Koeweit",
      KR: "Zuid-Korea",
      KP: "Noord-Korea",
      KN: "Saint Kitts en Nevis",
      KM: "Comoren",
      KI: "Kiribati",
      KH: "Cambodja",
      KG: "Kirgizi&euml;",
      KE: "Kenia",
      JP: "Japan",
      JO: "Jordani&euml;",
      JM: "Jamaica",
      JE: "Jersey",
      IT: "Itali&euml;",
      IS: "IJsland",
      IR: "Iran",
      IQ: "Irak",
      IO: "Brits Indische Oceaanterritorium",
      IN: "India",
      IM: "Man",
      IL: "Isra&euml;l",
      IE: "Ierland",
      ID: "Indonesi&euml;",
      HU: "Hongarije",
      HT: "Ha&iuml;ti",
      HR: "Kroati&euml;",
      HN: "Honduras",
      HM: "Heard en McDonaldeilanden",
      HK: "Hongkong",
      GY: "Guyana",
      GW: "Guinee-Bissau",
      GU: "Guam",
      GT: "Guatemala",
      GS: "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
      GR: "Griekenland",
      GQ: "Equatoriaal-Guinea",
      GP: "Guadeloupe",
      GN: "Guinee",
      GM: "Gambia",
      GL: "Groenland",
      GI: "Gibraltar",
      GH: "Ghana",
      GG: "Guernsey",
      GF: "Frans-Guyana",
      GE: "Georgi&euml;",
      GD: "Grenada",
      GB: "Verenigd Koninkrijk",
      GA: "Gabon",
      FR: "Frankrijk",
      FO: "Faer&ouml;er",
      FM: "Micronesia",
      FK: "Falklandeilanden",
      FJ: "Fiji",
      FI: "Finland",
      ET: "Ethiopi&euml;",
      ES: "Spanje",
      ER: "Eritrea",
      EH: "Westelijke Sahara",
      EG: "Egypte",
      EE: "Estland",
      EC: "Ecuador",
      DZ: "Algerije",
      DO: "Dominicaanse Republiek",
      DM: "Dominica",
      DK: "Denemarken",
      DJ: "Djibouti",
      DE: "Duitsland",
      CZ: "Tsjechi&euml;",
      CY: "Cyprus",
      CX: "Christmaseiland",
      CW: "Cura&ccedil;ao",
      CV: "Kaapverdi&euml;",
      CU: "Cuba",
      CR: "Costa Rica",
      CO: "Colombia",
      CN: "China",
      CM: "Kameroen",
      CL: "Chili",
      CK: "Cookeilanden",
      CI: "Ivoorkust",
      CH: "Zwitserland",
      CG: "Congo-Brazzaville",
      CF: "Centraal-Afrikaanse Republiek",
      CD: "Congo-Kinshasa",
      CC: "Cocoseilanden",
      CA: "Canada",
      BZ: "Belize",
      BY: "Wit-Rusland",
      BW: "Botswana",
      BV: "Bouveteiland",
      BT: "Bhutan",
      BS: "Bahama's",
      BR: "Brazili&euml;",
      BQ: "Bonaire, Sint Eustatius en Saba",
      BO: "Bolivia",
      BN: "Brunei",
      BM: "Bermuda",
      BL: "Saint-Barth&eacute;lemy",
      BJ: "Benin",
      BI: "Burundi",
      BH: "Bahrein",
      BG: "Bulgarije",
      BF: "Burkina Faso",
      BE: "Belgi&euml;",
      BD: "Bangladesh",
      BB: "Barbados",
      BA: "Bosni&euml; en Herzegovina",
      AZ: "Azerbeidzjan",
      AX: "&Aring;land",
      AW: "Aruba",
      AU: "Australi&euml;",
      AT: "Oostenrijk",
      AS: "Amerikaans-Samoa",
      AR: "Argentini&euml;",
      AQ: "Antarctica",
      AO: "Angola",
      AM: "Armeni&euml;",
      AL: "Albani&euml;",
      AI: "Anguilla",
      AG: "Antigua en Barbuda",
      AF: "Afghanistan",
      AE: "Verenigde Arabische Emiraten",
      AD: "Andorra",
    },
  },
  version: "2020_08_30_12_26_47",
  lang: "nl",
};
