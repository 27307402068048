import api_translations from './api_translations/index'

var temp_shipping_data={
    'shipping' :{
        'shippings':'Shippings',
        'shipment':'shipment',
        'add_shipments':'Add Shipment',
        'shipment_create':'Create Shipment',
        'shipment_edit':'Edit Shipment',
        'shipment_name':'Shipment name',
        'shipment_amount':'Shipment amount',
        'extra':'Extra',

        'shippingcost':'Shipping Cost',
        'add_shippingcosts':'Add Cost',
        'cost_create':'Create Cost',
        'cost_edit':'Edit Cost',
        'cost_view': 'View Cost',
        'cost_name':'Cost name',
        'cost_amount':'Cost amount',

        'shippingmethods':'Shipping Methods',
        'add_shippingmethods':'Add Method',
        'method_create':'Create Method',
        'method_edit':'Edit Method',
        'method_name':'Method name',
        'active':'Active',

        'shippingregions':'Shipping Regions',
        'add_shippingregions':'Add Region',
        'region_create':'Create Region',
        'region_edit':'Edit Region',
        'region_view': 'View Region',
        'region_name':'Region name',
        'region_country' : 'Region country',
        'region_cost' : 'Region Cost',

        'shippingstatus':'shipping Status',
        'shippingstatusses': 'Shipping Statusses',
        'add_shippingstatus':'Add Status',
        'status_create':'Create Status',
        'status_edit':'Edit Status',
        'status_name':'Status name',

        "shipments" :  {
            'method'   : "Method",
            'status': 'Status',
            'extra': 'Extra',
            'created_at': 'Created at',
        },

        "shipping_cost" :  {
           'name': 'Name',
           'amount' : 'Amount',
           'created_at': 'Created at',
           'region': 'Region'
        },

        'shipping_methods' : {
           'name' : 'Name',
           'active' :'Active',
           'created_at': 'Created at'
        },

        'shipping_regions' : {
            'name' : 'Name',
            'created_at': 'Created at'
        },

        'shipping_status' : {
            'name' : 'Name',
            'created_at': 'Created at'
        },
    },
    website:{
        'website':'Website',
        'homepage_nl':'Homepage.nl',
        'homepage':'Homepage',
        'settings':'Settings',
        'layouts':'Layouts',

        'pagetypes':'Pagetypes',
        'add_pagetypes':'Add Pagetype',

        'sectiontypes':'Sectiontypes',
        'add_sectiontype':'Add Sectiontype',

        'blocktypes':'Blocktypes',
        'add_blocktypes':'Add Blocktypes',

        'fields':'Fields',
        'add_fields':'Add field',

        'create':'Create',
        'update':'Update'
    }
};

export  default{
    system      :  api_translations.lefficient.system,
    error       :  api_translations.lefficient.error,
    login       :  api_translations.lefficient.login,
    user        :  api_translations.lefficient.user,
    leffit      :  {...api_translations.leffit,...temp_shipping_data},
    lefficient  :  api_translations.lefficient,
}