<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.shipping.status_edit') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.status_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                </div>
 
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.leffit.system.update') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script> 
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',
            }, 
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.$store.state);
            this.$store.dispatch('put',{endpoint:'/leffit/shipping/shippingstatusses/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Status updated",text:'Status was updated automatically'});
                
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){ 
         this.$store.dispatch('get','/leffit/shipping/shippingstatusses/'+this.$route.params.id)
			.then(data=>{
                console.log('result',data); 
                this.item=data.data.data;
			}).catch((error)=>{
				console.log(error);
			});
    }
    
}
</script>
