import appointment from '../../../../src/leffit/appointments/appointment'
import schedule from '../../../../src/leffit/appointments/schedule'
import createAppointment from '../../../../src/leffit/appointments/create'

export default [
    {
        path:"appointment",
        component: appointment,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"schedules",
        component: schedule,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"create",
        component: createAppointment,
        meta:{
            AuthRequired:true
        },
    }
]