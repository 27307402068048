<template>
    <div> 
        <div v-for="(error,index) in errors" :key="index">
            <b>{{error}}</b>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name:'formerror',
    computed: {
        ...mapState({
            errors:state=>state.formErrors
        })
    },
}
</script>
<style scoped>
    b{
        color: red;
        font-size: 13px;
    }
</style>