<template>
    <div>
        <div v-if="this.loader.show && this.loader.type == 'page'" class="loader">
            <div class="content">
                <div class="roller"></div>
                <b>{{this.loader.text}}</b>
            </div>
        </div> 
        <div v-if="this.loader.show && this.loader.type == 'small'" class="roller2"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name:'loader',
    computed:{
    ...mapState({
      loader:state=>state.leffit.loader
    }),
  },
}
</script>

<style scoped> 
    .loader{
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    background: #0000009c;
    z-index: 99999999999;
    }
    .content{
        position:absolute;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        margin:auto;
        width: 300px;
        height: 200px;
    }
    .content b{
        display: block;
        margin-top: 10px;
        color: white;
    }
    .roller{ 
        margin: auto;
        width:60px;
        height: 60px;
        border:4px solid transparent;
        border-top:4px solid white;
        border-radius: 50%;
        animation: roll .5s linear infinite;
    }
    .roller2{ 
        margin: auto;
        width:35px;
        height:35px;
        border:4px solid transparent;
        border-top:4px solid white;
        border-radius: 50%;
        animation: roll .5s linear infinite;
    }

    @keyframes roll {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
    }
</style>