<template>
  <div
    style="min-width: 225px !important; max-width: 225px !important"
    class="tools"
  >
    <div class="collaps" style="overflow: auto">
      <div v-for="(page, index) in menu" :key="index">
        <router-link
          v-if="page.children.length == 0"
          class="menu"
          :class="{ active: isActive([], page.url) }"
          :to="page.url"
          :key="index"
        >
          <div class="ina">
            <div class="title">{{ page.title }}</div>
          </div>
        </router-link>
        <a
          v-else-if="page.children.length > 0"
          class="menu"
          :class="{
            active:
              isActive(page.children, '') ||
              $route.name == 'product_filter_item_page',
          }"
          data-toggle="collapse"
          :href="'#menuCollapse_' + index"
          style="padding-bottom: 0px"
        >
          <div class="ina">
            <div class="title">{{ page.title }}</div>
            <i
              class="fa fa-chevron-down colp-icon"
              v-if="page.children.length > 0"
            ></i>
          </div>
        </a>
        <div
          :class="
            page.children.find((d) => d.url == $route.path) ||
            $route.name == 'product_filter_item_page'
              ? 'collapse show'
              : 'collapse'
          "
          :id="'menuCollapse_' + index"
        >
          <div>
            <router-link
              v-for="(childpage, cindex) in page.children"
              :key="cindex"
              :to="childpage.url"
              :class="{
                submenu: true,
                active: isActive([], childpage.url),
              }"
            >
              <div class="ina">
                <div class="title">{{ childpage.title }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
export default {
  name: "sidebarInnerHardcoded",
  props: {
    menu: Array,
  },
  methods: {
    isActive(children, url) {
      let index_of_s = url.lastIndexOf("s");

      if (index_of_s == url.length - 1 && children.length > 0) {
        url = url.substr(0, index_of_s);
      }
      if (this.$route.path.search(url) >= 0 && url.length > 0) {
        //because procurement_invoice can be found in procurement_invoice_credit, both links are active, therefore to avoid that check if after the url, the next character is not _
        if (this.$route.path.split(url)[1][0] != "_") {
          return true;
        }
      }
      if (children.find((d) => d.url == this.$route.path)) {
        return true;
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.collaps {
  margin-top: 0px;
}
.collaps a.menu.active {
  background: var(--color-2);
}
.collaps a.menu:hover {
  background: var(--color-2);
}
.collaps a.menu {
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 10px 0px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 5px;
  color: var(--color-2) !important;
}
.menu[aria-expanded="true"] {
  background: var(--color-2);
}
.menu[aria-expanded="true"] .collapse {
  background: var(--color-1);
}
.collaps a.menu .collapse {
}
.collapse {
  background: var(--color-1);
  padding: 0px;
  border-radius: 0px;
  margin-top: -5px;
}
.collaps .collapse a.submenu {
  padding: 5px 0px 0px 15px;
  display: block;
  font-size: 14px;
  text-decoration: none;
}
.collaps .collapse a.submenu:hover {
  background: #fb9c7e !important;
}
.collaps .collapse a.submenu.active {
  background: #fb9c7e !important;
}
.collaps .collapse a.submenu.active .title {
  color: #4e5467 !important;
}
.collaps .collapse a.submenu:hover .title {
  color: #4e5467 !important;
}
.collaps .collapse a.submenu .title {
  color: #b9b9b9;
}
.collaps .ina {
  position: relative;
  padding: 0px 10px;
}

.collaps .colp-icon {
  color: gray;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 18px;
  margin: auto;
  right: 10px;
  font-size: 12px;
  display: none;
}
.collaps.active .colp-icon {
  display: block;
  color: var(--color-1);
}
.collaps .title {
  color: var(--color-1);
  font-weight: 500;
  margin: 0;
  min-height: unset;
}
.collaps.active .title {
  color: #ededed;
}
.collaps .menuIcons {
}
.collaps .menuIcons a {
  color: lightgray;
  margin-right: 6px;
}
.collaps.active .menuIcons a {
  color: #e8e8e87d;
}
.collaps:hover .menuIcons a {
  color: #e8e8e87d;
}
.collaps .menuIcons a:hover {
  color: gray;
}
.collaps .menuIcons a i.fa {
}

.icons {
}
.icons a {
  text-decoration: none;
  margin-right: 7px;
  padding: 0px 4px;
  border-radius: 13px;
}
.icons a i {
}

.icons a:first-child {
  border: 2px solid #fb9c7e70;
}
.icons a:first-child i {
  color: #fb9c7e70;
}
.icons a:nth-child(2) {
  border: 2px solid #4f546754;
}
.icons a:nth-child(2) i {
  color: #4f546754;
}
.icons a:nth-child(3) {
  border: 2px solid #d7e3f0;
}
.icons a:nth-child(3) i {
  color: #d7e3f0;
}

.icons a:nth-child(4) {
  border: 2px solid #17c0c873;
}
.icons a:nth-child(4) i {
  color: #17c0c873;
}
.top {
  display: flex;
  padding: 5px 5px 10px;
  justify-content: space-between;
  white-space: nowrap;
}
.switch {
  background: var(--color-1);
  padding: 10px 19px;
  border-radius: 10px;
  position: relative;
  margin-right: 5px;
}
.switch span {
  position: absolute;
  height: 79%;
  background: white;
  width: 17px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 1s linear;
}
.switch.on span {
  right: 2px;
}
.switch.off span {
  left: 2px;
}
</style>
