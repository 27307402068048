<template>
	<div class="row" id="mainBody">
		<!-- <pages_inner :menu="menu" /> -->
		<div class="col-lg-2 col-md-3 tools">
        	<div class="collaps">
				<innerpages :data="$store.state.leffit.pages"></innerpages> 
                <a
                    class="menu"
                    :class="{ active :  menu[1].url == $route.path}" 
                    data-toggle="collapse"
                    :href="'#menuCollapse_1'"  
                    >
                    <div class="ina">
                        <div class="title">{{  menu[1].title }}</div>
                        <i class="fa fa-chevron-down colp-icon" v-if=" menu[1].children.length>0"></i>
                    </div>  
                </a>
                <div class="collapse " :id="'menuCollapse_1'">
                    <div>
                         <a  v-for="(childpage, cindex) in menu[1].children"
                            :key="cindex"
                            :href="childpage.url"
                            class="submenu">
                            <div class="ina">
                                <div class="title">{{ childpage.title }}</div>
                            </div>
                        </a>
                    </div>
                </div>
        	</div>
		</div>
		<div class="modal" id="pageModal">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4>ADD A PAGE</h4>
					</div>
					<div class="modal-body">
						<form action="">
							<div class="form-group">
								<label for="">NAME</label>
								<input v-model="$store.state.leffit.page.name" type="text" class="form-control">
							</div>

							<div class="form-group">
								<label style="width:100%" for="">PAGE TYPES</label>
								<select style="width:100%" class='form-control select' id="">
									<option value=""></option>
									<option v-for="(pt,index) in pagetypes" :key="index" :value="pt.id">{{pt.name}}</option>
								</select>
							</div>

							<button type="button" @click="submit()" class="btn">SUBMIT</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-10 col-md-9 mainPanel">
			<router-view />
		</div>
	</div>
</template> 
<script>
import innerpages from './../../layout/components/pages_inner'; 
export default {
  	name: 'index',
  	components: {innerpages},

  	data () {
    	return {
    		menu : [
				{ 
					title 	: this.$t('leffit.website.homepage'),
					url 	: '/admin/website/homepage',
                    name    : 'home',
					children : [],
				},
				{
					title 	: this.$t('leffit.website.settings'),
					url 	: '/admin/website/settings',
                    name    : 'website',
					children : [
						{
							title 	: this.$t('leffit.website.layouts'),
							url 	: '/admin/website/layouts', 
						}, 
						{
							title 	: this.$t('leffit.website.pagetypes'),
							url 	: '/admin/website/pagetypes', 
						}, 
						{
							title 	: this.$t('leffit.website.sectiontypes'),
							url 	: '/admin/website/sectiontypes', 
						}, 
						{
							title 	: this.$t('leffit.website.blocktypes'),
							url 	: '/admin/website/blocktypes', 
						}, 
						{
							title 	: this.$t('leffit.website.fields'),
							url 	: '/admin/website/fields', 
						}, 
					],
				}, 
			],
			pages:[],
			pagetypes:[], 
		}
	},
	methods:{ 
		submit(){
			// console.log(this.$store.state.leffit.page);
			var page=this.$store.state.leffit.page; 
			
			 
				this.$store.dispatch('post',{endpoint:'/leffit/cms/pages',details:page})
				.then((data)=>{
					console.log(data); 
					this.toggleNode(page.id);
					window.$('#pageModal').modal('hide');
					this.$store.state.leffit.pages=data.data.data;
				})
				.catch((error)=>{
					console.log(error);
				}); 
		},
		toggleNode(id){
			var opened_pages=this.$store.state.leffit.opened_pages;
			if(opened_pages.find(d=> d == id)){
				var temp=opened_pages.filter(d=>d != id); 
				this.$store.state.leffit.opened_pages=temp; 
			}else{
				this.$store.state.leffit.opened_pages.push(id); 
			} 
			console.log(this.$store.state.leffit.opened_pages);
		},
	},
	created(){  
        if(this.$store.state.auth.user.id != 1){
            this.menu = this.menu.filter((d)=> d.name != 'website');
        }
		this.opened_pages=this.$store.state.leffit.opened_pages; 

		this.$store.dispatch('get','/leffit/cms/get-pages-tree')
		.then((data)=>{
			// console.log(data);
			this.pages=data.data;
            console.log(this.pages)
			this.$store.state.leffit.pages=this.pages;
		})
		.catch((error)=>{
			console.log(error.request);
		});
		
		this.$store.dispatch('get','/leffit/cms/pagetypes')
		.then((data)=>{
			// console.log(data);
			this.pagetypes=data.data.data;

			var context=this;
			window.$('.select').select2().change(function(){
				context.$store.state.leffit.page.pagetype_id=window.$(this).val();
			});
		})
		.catch((error)=>{
			console.log(error.request);
		});

        
	}
}
</script>
<style scoped>
	.children{
		height:0;
		overflow:hidden;
		transition: all .3s linear;
	}
	.children.open{
		height:auto; 
		max-height: 50%;
		transition: all .3s linear;
	}
	.page-tools{
		padding-left: 9px;
		line-height: 1;
		padding-bottom: 5px;
	}
	.page-tools a{
		color: #d5d3d3e6;
		font-size: 10px;
		margin: 0px 2px;
	}
	.page-tools a i{}
</style>
<style scoped>
    .collaps{
        margin-top: 0px;
    }
    .collaps a.menu.active{
        background: var(--color-2); 
    }
    .collaps a.menu:hover{
        background: var(--color-2);
    }
    .collaps a.menu{
        text-decoration: none;
        display: block;
        text-align: left;
        padding: 10px 0px;
        transition: all .3s ease-in-out;
        margin-bottom: 5px;
        color: var(--color-2) !important; 
    }
    .menu[aria-expanded='true'] {
        background: var(--color-2);
    }
    .menu[aria-expanded='true'] .collapse{
        background: var(--color-1);
    }
    .collaps a.menu .collapse{ 
    }
 .collapse {
        background: var(--color-1);
        padding: 0px;
        border-radius: 0px;
    }
    .collaps .collapse  a.submenu{ 
        padding: 5px 0px 5px 15px;
        display: block;
        font-size: 14px;
    }
     .collaps .collapse  a.submenu .title{ 
         color: #b9b9b9;
    }
    .collaps .ina{
        position: relative;
        padding: 0px 10px;
		line-height: 1;
    }

    .collaps .colp-icon{
        color: gray;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 18px;
        margin: auto;
        right: 10px;
        font-size: 12px;
        display: none;
    }
    .collaps.active .colp-icon{
        display: block;
        color: var(--color-1);
    }
    .collaps .title{
        color: var(--color-1);
        font-weight:500;
        margin: 0;
        min-height: unset;
		font-size: 14px;
    }
    .collaps .active .title{
        color: #ededed !important;
    } 
    .collaps .menuIcons{}
    .collaps .menuIcons a{
        color: lightgray;
        margin-right: 6px;
    }
    .collaps.active .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps:hover .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps .menuIcons a:hover{
        color: gray;
    }
    .collaps .menuIcons a i.fa{}



    .icons{}
    .icons a{
        text-decoration: none;
        margin-right: 7px;
        padding: 0px 4px;
        border-radius: 13px;
    }
    .icons a i{  }

    .icons a:first-child{
        border:2px solid #fb9c7e70;
    }
    .icons a:first-child i{
        color: #fb9c7e70;
    }
    .icons a:nth-child(2){
        border:2px solid #4f546754;
    }
    .icons a:nth-child(2) i{
           color: #4f546754;
    }
     .icons a:nth-child(3){
        border:2px solid #d7e3f0;
    }
    .icons a:nth-child(3) i{
           color: #d7e3f0;
    }

    .icons a:nth-child(4){
        border:2px solid #17c0c873;
    }
    .icons a:nth-child(4) i{
           color: #17c0c873;
    }
    .top{
        display: flex;
        padding:5px 5px 10px;
        justify-content: space-between;
        white-space: nowrap;
    }
    .switch{
        background: var(--color-1);
        padding:10px 19px;
        border-radius: 10px;
        position: relative;
        margin-right: 5px;
    }
    .switch span{
       position: absolute;
        height: 79%;
        background: white;
        width: 17px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        margin: auto; 
        transition: all 1s linear;
    }
    .switch.on span{
        right: 2px;
    }
    .switch.off span{
        left: 2px;
    }

</style>
