<template>
    <div style="position:relative;height:100vh;width:100%">
        <sectionBuilder
            :get_url="'/leffit/courses/lessons/'"
            :media_url="'/leffit/courses/add-lesson-media/'"
        ></sectionBuilder> 
    </div>
</template>

<script> 
    import { mapState } from "vuex"
    import sectionBuilder from "../sectionBuilder/create_template" 
    export default {
        name:'create_template',
        components:{
            sectionBuilder, 
        },
        computed:{
            ...mapState({
                newsletter:state=>state.leffit.template,
                sections:state=>state.leffit.sections,
                activeSection:state=>state.leffit.activeSection,
                changesMade:state=>state.leffit.changesMade,
            })
        },
        data(){
            return { 
            }
        },
        mounted(){
            console.log(this.newsletter);
        },
        methods:{
           
        },
        created(){ }
    }
</script>

<style scoped>
        
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        }
        .templateHolder{ 
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            margin: auto;
            margin-top: 80px;
            width: 670px;
            border: 1px solid lightgray;
            padding: 10px;
            height: 90%;
            overflow-y: scroll;
        }
        .editBoard{
            position: absolute;
            top:0%;
            bottom: 5%;
            right: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            width: 500px; 
            padding: 10px;
            height: 94%;
            overflow-y: scroll;
            background: #f2f2f2;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        }
        .defaultTemp .btn{
            border:1px solid lightgray;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: all .2s linear;
            color: gray;
        }
        .defaultTemp:hover .btn{
            transform: scale(1.3);
            background: white;
        }
        .defaultTemp:hover {
            transform: scale(0.9);
            background: #d3d3d31a;
        }
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            position: absolute;
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative; 
            margin-bottom: 10px;
        }
        .temp:hover{
            cursor: pointer; 
        }
        .temp:hover .sectionTools{
            display: block;
        }
        .temp.editting{
            border: 2px dashed red;
        }
        .temp:hover .sectionTools button{
            background: black;
        }
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        }
        .uploadImage:hover{
            transform: scale(0.95);
        }
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       
</style>
<style>
    div img{
        width:100% !important;
    }
</style>