<template>
	<div>
		<div class="head row p-0 m-0">
            <div class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t(ordertype.name) }}</h3></div>
      	</div>
		<div class="row mt-3">
            
                <div class="col-12 col-md-6"  v-if="ordertype.settings.relationdetails">
                    <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <a style="font-size:16px" data-toggle="collapse" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    {{ $t('leffit.orders.order.relationdetails') }}
                                </a>
                            </h2>
                        </div>

                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <div class="row">
                                    <div v-if="ordertype_id == 310" class="form-group col-12">
                                        <label for="filter_parent">Verzendfiliaal</label> 
                                        <Select2 v-model="item.from_id" :options="warehouses.map((d)=>{ return {id:d.id,text:d.name}})" style="position:relative;width:100%" class="organization organization1" id="warehouse1"></Select2>
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="filter_parent">{{ $t('leffit.orders.order.select_organization') }}</label> 
                                        <Select2 @change="populateOrganization()" v-model="item.organization_id" :options="organizations.map((d)=>{ return {id:d.id,text:d.name}})" style="position:relative;width:100%" class="organization organization1" id="order_organization"></Select2>
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="filter_parent">{{ $t('leffit.orders.order.select_contact') }}</label> 
                                        <Select2 @change="populateContact()" v-model="item.contact_id" :options="contacts.map((d)=>{ return {id:d.id,text:d.firstname+' '+d.lastname}})" style="position:relative;width:100%" class="contact contact1"></Select2>
                                    </div> 
                                    
                                    <div class="form-group col-6">
                                    <label for="order_firstname">{{ $t('leffit.orders.order.firstname') }}</label>
                                    <input type="text" class="form-control" id="order_firstname" v-model="item.firstname">
                                    </div> 
                                
                                    <div class="form-group col-6">
                                    <label for="order_lastname">{{ $t('leffit.orders.order.lastname') }}</label>
                                    <input type="text" class="form-control" id="order_lastname" v-model="item.lastname">
                                    </div>
                                    
                                        <div class="form-group col-6">
                                    <label for="order_companyname">{{ $t('leffit.orders.order.companyname') }}</label>
                                    <input type="text" class="form-control" id="order_companyname" v-model="item.companyname">
                                    </div> 
                                    <div class="form-group col-6">
                                    <label for="order_address">{{ $t('leffit.orders.order.address') }}</label>
                                    <input type="text" class="form-control" id="order_address" v-model="item.address">
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-6">
                                    <label for="order_address2">{{ $t('leffit.orders.order.address2') }}</label>
                                    <input type="text" class="form-control" id="order_address2" v-model="item.address2">
                                    </div> 
                                    <div class="form-group col-6">
                                    <label for="order_zipcode">{{ $t('leffit.orders.order.zipcode') }}</label>
                                    <input type="text" class="form-control" id="order_zipcode" v-model="item.zipcode">
                                    </div>
                                
                                    <div class="form-group col-6">
                                    <label for="order_city">{{ $t('leffit.orders.order.city') }}</label>
                                    <input type="text" class="form-control" id="order_city" v-model="item.city">
                                    </div>
                                
                                    <div class="form-group col-6">
                                        <label for="order_city">{{ $t('leffit.orders.order.country') }}</label>
                                    <Select2 v-model="item.country" :options="country.map((d)=>{ return {id:d.code,text:d.name}; })" style="position:relative;width:100%" class="country country1"></Select2>
                                    </div> 
                                </div>

                                <div class="form-row"> 
                                    <div class="form-group col-6">
                                        <label for="order_phone">{{ $t('leffit.orders.order.phone') }}</label>
                                        <input type="phone" class="form-control" id="order_phone" v-model="item.phone">
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="order_email">{{ $t('leffit.orders.order.email') }}</label>
                                        <input type="email" class="form-control" id="order_email" v-model="item.email">
                                    </div>
                                </div>

                            </div>
                        </div>
                </div>
                <div class="col-12 col-md-6" v-if="ordertype.settings.shippingdetails">
                    <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <a style="font-size:16px" data-toggle="collapse" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    {{ $t('leffit.orders.order.shipping_details') }}
                                </a>
                            </h2>
                        </div>

                        <div id="collapseThree" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <div class="row">
                                    <div v-if="ordertype_id == 310" class="form-group col-12">
                                        <label for="filter_parent">Ontvangstfiliaal</label> 
                                        <Select2 v-model="item.to_id" :options="warehouses.map((d)=>{ return {id:d.id,text:d.name}})" style="position:relative;width:100%" class="organization organization1" id="warehouse2"></Select2>
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="filter_parent">{{ $t('leffit.orders.order.select_organization') }}</label> 
                                        <Select2  @change="populateShipContact()" v-model="item.organization_id" :options="organizations.map((d)=>{ return {id:d.id,text:d.name} })" style="position:relative;width:100%" class="form-control organization organization1 p-0 border-0"></Select2>
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="filter_parent">{{ $t('leffit.orders.order.select_contact') }}</label>  
                                        <Select2 @change="populateShipOrganization()" v-model="item.contact_id" :options="contacts.map((d)=>{ return {id:d.id,text:d.firstname+' '+d.lastname} })" style="position:relative;width:100%" class="form-control contact contact2 p-0 border-0"  ></Select2>
                                    </div>  
                                    <div class="form-group col-6">
                                    <label for="order_ship_firstname">{{ $t('leffit.orders.order.ship_firstname') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_firstname" v-model="item.ship_firstname">
                                    </div> 
                                
                                    <div class="form-group col-6">
                                    <label for="order_ship_lastname">{{ $t('leffit.orders.order.ship_lastname') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_lastname" v-model="item.ship_lastname">
                                    </div>
                                    
                                    <div class="form-group col-6">
                                    <label for="order_ship_companyname">{{ $t('leffit.orders.order.ship_companyname') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_companyname" v-model="item.ship_companyname">
                                    </div> 
                                    <div class="form-group col-6">
                                    <label for="order_ship_address">{{ $t('leffit.orders.order.ship_address') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_address" v-model="item.ship_address">
                                    </div> 
                                    <div class="form-group col-6">
                                    <label for="order_ship_address2">{{ $t('leffit.orders.order.ship_address2') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_address2" v-model="item.ship_address2">
                                    </div> 
                                    <div class="form-group col-6">
                                    <label for="order_ship_zipcode">{{ $t('leffit.orders.order.ship_zipcode') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_zipcode" v-model="item.ship_zipcode">
                                    </div>
                                
                                    <div class="form-group col-6">
                                    <label for="order_ship_city">{{ $t('leffit.orders.order.ship_city') }}</label>
                                    <input type="text" class="form-control shipping_field" id="order_ship_city" v-model="item.ship_city">
                                    </div>
                                
                                    <div class="form-group col-6">
                                    <label for="order_ship_country">{{ $t('leffit.orders.order.ship_country') }}</label>
                                    <Select2 v-model="item.ship_country" :options="country.map((d)=>{ return {id:d.code,text:d.name}; })" style="position:relative;width:100%" class="form-control country country1 p-0 border-0"></Select2>
                                    </div> 
                                </div>

                                <div class="form-group col-12 ">
                                    <label for="order_use_shipping_address" class="d-flex">
                                        <input style="height: 21px;width:20px" type="checkbox" class="form-control d-block" id="order_use_shipping_address" v-model="item.use_shipping_address" />
                                        <div>{{ $t('leffit.orders.order.use_shipping_address') }}</div>
                                    </label>
                                </div>

                            </div> 
                        </div>
                </div> 
                <div class="accordion col-12" id="accordionExample">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <a style="font-size:16px" data-toggle="collapse" href="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                {{ $t('leffit.orders.order.remarks') }}
                            </a>
                        </h2>
                        </div>

                        <div id="collapseFour" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                    <div class="form-row">
                                    <div class="form-group col-12">
                                    <label for="order_remarks">{{ $t('leffit.orders.order.remarks') }}</label>
                                        <textarea  class="form-control WYSIWYG" id="order_remarks" v-model="item.remarks"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> 
                
                <div class="col-12">
                    <button @click="create()" class="btn btn-success">{{ $t('system.continue') }}</button>
                </div>
        </div>
	</div>
	
</template>

<script>
    import country from '../../../data/country';
    import { mapState } from 'vuex'; 
    export default { 
        name: 'orderCreate',
        props : {
            ordertype_id : Number,
        },
        data () {
            return { 
                profileImageSet:false,
                filters:[],
                filters2:[],
                types:[],
                pagedata : [], 
                search : {},
                item2:{active:0,name:'',description:'',parent:'',language:'',pickorder:''},
                country:country,
                organizations:[],
                contacts:[], 
                item : { 
                    'ordertype_id'          : this.ordertype_id,
                    'organization_id'       : '',
                    'firstname' 	        : '',
                    'companyname' 	        : '',
                    'lastname' 		        : '',
                    'address' 		        : '',
                    'address2' 		        : '',
                    'zipcode' 		        : '',
                    'city' 			        : '',
                    'country' 		        : 'NL', 
                    'phone' 		        : '', 
                    'email'                 : '', 
                    'remarks' 		        : '',
                    'use_shipping_address'  : 0,
                    'ship_companyname'      : '',
                    'ship_firstname'        : '',
                    'ship_lastname'         : '',
                    'ship_address'          : '',
                    'ship_address2'         : '',
                    'ship_zipcode'          : '',
                    'ship_city'             : '',
                    'ship_country'          : 'NL',
                },
                ordertype : {   settings : {
                                    relationdetails:false,
                                    shippingdetails:false,
                                    orderdetails:{},
                                    buttons:[],
                                    orderrow:[],
                                    orderrow_extra:[],
                                }
                },
                ordertype_name : '',
                order_url : '',
                warehouses:[]
            }
        },
        computed : {
            ...mapState({
                user:state=>state.leffit.user,
                endpoint:state=>state.leffit.endpoint
            }), 
        },
        created(){ 
            // tinymce.init({
            // 	selector: 'textarea',  // change this value according to your HTML
            // 	plugin: 'a_tinymce_plugin',
            // 	a_plugin_option: true,
            // 	a_configuration_option: 400
            // });  
            this.$store.dispatch('get','/leffit/products/warehouses')
            .then(data=>{ 
                console.log(data);
                this.warehouses = data.data.data;
            });
        },  
        methods:{
            populateContact(){
                var data=this.contacts.find((d)=> d.id == this.item.contact_id);

                this.item.firstname=data.firstname;
                this.item.lastname=data.lastname;
                this.item.address=data.address;
                this.item.address2=data.address2;
                this.item.phone=data.phone;
                this.item.email=data.email;
                this.item.city=data.city;
                this.item.zipcode=data.zipcode;

                // window.$('.country').val(data.country).select2();
            },
            populateShipContact(){
                var data=this.contacts.find((d)=> d.id == this.item.contact_id);

                this.item.ship_firstname=data.firstname;
                this.item.ship_lastname=data.lastname;
                this.item.ship_address=data.address;
                this.item.ship_address2=data.address2;
                this.item.ship_phone=data.phone;
                this.item.ship_email=data.email;
                this.item.ship_city=data.city;
                this.item.ship_zipcode=data.zipcode;

                // window.$('.country2').val(data.country).select2();
            },
            populateOrganization(){
                var data=this.organizations.find((d)=> d.id == this.item.organization_id);

                this.item.companyname=data.name;
                this.item.address=data.address;
                this.item.address2=data.address2;
                this.item.phone=data.phone;
                this.item.email=data.email;
                this.item.city=data.city;

                // window.$('.country').val(data.country).select2();
            },
            populateShipOrganization(){
                var data=this.organizations.find((d)=> d.id == this.item.organization_id);

                this.item.ship_companyname=data.name;
                this.item.ship_address=data.address;
                this.item.ship_address2=data.address2;
                this.item.ship_phone=data.phone;
                this.item.ship_email=data.email;
                this.item.ship_city=data.city;

                // window.$('.country2').val(data.country).select2();
            },
            create(){ 
                if(this.item.companyname.trim() == ''){
                    this.$store.dispatch('makeNotification',
                    {title:"Order error",text:'Please enter companyname',type:'danger'});
                    return false;
                }
                this.item.remarks=window.tinyMCE.get('order_remarks').getContent(); 
                this.item.orderstatus_id=2;
                var ordertypeName = this.ordertype_name;
                
                var details = this.item;
                details['created_by_user_id']  = this.user.id;
                details['updated_by_user_id']  = this.user.id;
                
                this.$store.dispatch('post',{endpoint:'/leffit/orders/orders',details:this.item})
                .then(data=>{
                	console.log('result',data.data); 
                	this.$store.dispatch('makeNotification',{title:ordertypeName+" Created",text:ordertypeName+' was added successfully',type:'success'});
                	window.location.href="create/"+data.data.data.id;
                    
                	}).catch((error)=>{
                		console.log(error.request);
                    });
            },
            getItem(){
                return this.item;
            },
            use_shipping_address(){
                this.item.use_shipping_address = 1;
            }
        },
        mounted(){
            var tmp_ordertypes = [];
            var tmp_ordertypes_sales = process.env.VUE_APP_LEFFIT_SALES_ORDERTYPES.split(',');
            var tmp_ordertypes_procurement = process.env.VUE_APP_LEFFIT_PROCUREMENT_ORDERTYPES.split(',');
            
            tmp_ordertypes_sales.forEach(function(item){
                item = item.split('|');
                tmp_ordertypes[parseInt(item[1])] = [];
                tmp_ordertypes[parseInt(item[1])]['name'] = item[0];
                tmp_ordertypes[parseInt(item[1])]['module'] = 'sales';
            });
            tmp_ordertypes_procurement.forEach(function(item){
                item = item.split('|');
                tmp_ordertypes[parseInt(item[1])] = [];
                tmp_ordertypes[parseInt(item[1])]['name'] = item[0];
                tmp_ordertypes[parseInt(item[1])]['module'] = 'procurement';
            });
            
            this.order_url = '/admin/'+tmp_ordertypes[this.ordertype_id]['module']+'/orders/' + tmp_ordertypes[this.ordertype_id]['name'];
            this.ordertype_name = tmp_ordertypes[this.ordertype_id]['name'];

            var thas = this;
            // window.$('.country').select2();
            // window.$('.country2').select2();

            window.$('.shipping_field').change(function(){
                var item = thas.getItem();
                if(item.use_shipping_address==0){
                    thas.use_shipping_address();
                    thas.$store.dispatch('makeNotification',{title:"Shipping address",text:'Shipping address will be used',type:'success'});
                }
            });

            this.$store.dispatch('get','/leffit/orders/order-types/'+this.ordertype_id)
            .then(data=>{ 
                this.ordertype = data.data.data;
                this.ordertype.settings = JSON.parse(this.ordertype.settings);
                console.log('ordertype:',this.ordertype);


                this.$store.dispatch('get','/leffit/crm/organizations/type/'+this.ordertype.settings.organizations)
                .then(data=>{
                    console.log(data);
                    this.organizations = data.data.data;
                    var context=this;  
                });
            });

        window.tinymce.init({
            selector: 'textarea',  // change this value according to your HTML
            plugin: 'a_tinymce_plugin',
            a_plugin_option: true,
            a_configuration_option: 400
        });  
        

        
        
        this.$store.dispatch('get','/leffit/crm/contacts')
        .then(data=>{ 
            this.contacts = data.data.data.filter((d)=> d.firstname && d.lastname);
            var context=this;  
        });
    },
    }
</script>

<style lang="css" scoped>
.collapse .card-body{
    background-color: white !important;
}
.subTable tbody{
    border: 1px solid lightgray !important;
}
.subTable td{
    padding: 7px 20px !important;
    font-size: 12px;
}
.subTable tr td:first-child{
    padding-left:5px !important;
    text-align:left !important;
}
.subTable{
    width: 200px;
    margin-left: auto;
}
#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>