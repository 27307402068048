<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.product.create') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

				<div class="form-row">
					<div class="form-group col-6">
						<label for="name">{{ $t('leffit.products.product.name') }}</label>
						<input type="text" class="form-control" id="name" v-model="item.name">
					</div> 
					<div class="form-group col-6">
						<label for="ean">{{ $t('leffit.products.product.ean') }}</label>
						<input type="text" class="form-control" id="ean" v-model="item.ean">
					</div>
					<div class="form-group col-6">
						<label>{{ $t('leffit.products.product.casa_productcode') }}:</label>
						<h6><input type="text" v-model="item.casa_productcode" class="form-control"></h6>
					</div>
					<div class="form-group col-6">
						<label>{{ $t('leffit.products.product.casa_seizoenscode') }}:</label>
						<h6><input type="text" v-model="item.casa_seizoenscode" class="form-control"></h6>
					</div>
					<div class="form-group col-6">
						<label>{{ $t('leffit.products.product.virgocode') }}:</label>
						<h6><input type="text" v-model="item.virgocode" class="form-control" ></h6>
					</div>
				</div>

				<div class="form-row">
					<div class="form-group col-6">
						<label for="type">{{ $t('leffit.products.product.product_type') }}</label>
						<select v-model="item.producttype_id" style="position:relative" class="form-control " id="type">
							<option :key="index" v-for="(ct,index) in producttypes" :value="ct.id">{{ $t(ct.name) }}</option>
						</select>
					</div>
				
					<div class="form-group col-6">
						<label for="status">{{ $t('leffit.products.product.product_status') }}</label>
						<select v-model="item.productstatus_id" style="position:relative" class="form-control select" id="status">
							<option :key="index" v-for="(st,index) in productstatuses" :value="st.id">{{ $t(st.name) }}</option>
						</select>
					</div> 
				</div>



				<div class="form-row">
					<div class="form-group col-6">
						<label for="filter">{{ $t('leffit.products.product.product_filters') }}</label>
						<Select2 @change="productFilterChanged()" v-model="productfilter_id" :options="product_filters.map((d)=>{ return {text:d.name, id:d.id} })" />
					</div> 
 
 
					<div class="form-group col-6">
						<label for="type">{{ $t('leffit.products.product.size') }}</label>
						<input @keyup="sizeChanged()" type="text" class="form-control" id="size" v-model="item.size">
					</div> 
				
				</div>

				<!-- <div class="form-row"> 
					<div class="form-group col-3">
						Productcode
					</div> 
					<div class="form-group col-3">
						<input type="text" class="form-control" id="price" v-model="productcode.casa_productcode">
					</div>
				</div> -->
 
				<div class="form-row">
					<div class="form-group col-12">
						<label for="name">{{ $t('leffit.products.product.casa_seizoenscode') }}</label>
						<input type="text" class="form-control" id="name" v-model="item.seizoenscode">
					</div>
				</div>
 
				<div class="form-row">
					 
					<div class="form-group col-4">
						<label for="type">{{ $t('leffit.products.product.supplier') }}</label>
						<v-select v-model="item.supplier" :options="suppliers.map((d)=>{ return {label:d.name, id:d.id} })" />
					</div>
 

					<div class="form-group col-4">
						<label for="type">{{ $t('leffit.products.product.supplier_code') }}</label>
						<input type="text" class="form-control" id="size" v-model="item.supplier_code">
					</div>
 
					<div class="form-group col-4">
						<label for="type">{{ $t('leffit.products.product.currency') }}</label>
						<v-select v-model="item.currency" :options="currencies.map((d)=>{ return {label:d.name, id:d.id} })" />
					</div>
				</div>

				<div class="form-row">
					<div class="form-group col-6">
						<label for="price">{{ $t('leffit.products.product.price') }}</label>
						<input type="text" class="form-control" id="price" v-model="item.price">
					</div>

					<div class="form-group col-6">
						<label for="price">{{ $t('leffit.products.product.vat') }}</label>
						<input type="text" class="form-control" id="vat" v-model="item.vat">
					</div>
				
				</div>


				
				<div class="form-row">
				
					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.weight') }}</label>
						<input type="number" class="form-control" id="weight" v-model="item.weight">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.silver_weight') }}</label>
						<input type="number" class="form-control" id="silver_weight" v-model="item.silver_weight">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.width') }}</label>
						<input type="number" class="form-control" id="width" v-model="item.width">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.height') }}</label>
						<input type="number" class="form-control" id="height" v-model="item.height">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.length') }}</label>
						<input type="number" class="form-control" id="length" v-model="item.length">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.thickness') }}</label>
						<input type="number" class="form-control" id="thickness" v-model="item.thickness">
					</div>

					<div class="form-group col-6">
						<label for="weight">{{ $t('leffit.products.product.diameter') }}</label>
						<input type="number" class="form-control" id="diameter" v-model="item.diameter">
					</div> 

					<div class="form-group col-4">
						<label for="type">{{ $t('leffit.products.product.product_filters') }}</label>
						<v-select multiple v-model="item.filters" :options="product_filters.map((d)=>{ return {label:d.name, id:d.id} })" />
					</div>

					<div class="form-group col-12">
						<label for="remarks">{{ $t('leffit.products.product.remarks') }}</label>
						<editor
							v-model="item.remarks"
							:init="{
								height: 300,
								menubar: false,
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar:
									'undo redo | formatselect | bold italic backcolor | \
									alignleft aligncenter alignright alignjustify | \
									bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
							}"
						/>
					</div> 
				</div>

				<div class="form-row">
					<div class="form-group col-12">
						<label for="description">{{ $t('leffit.products.product.description') }}</label>
						<editor
							v-model="item.description"
							:init="{
								height: 300,
								menubar: false,
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar:
									'undo redo | formatselect | bold italic backcolor | \
									alignleft aligncenter alignright alignjustify | \
									bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
							}"
						/>
					</div>
				</div>

				<div v-if="setting_ready">
					<nav class="nav">
						<a data-toggle="tab" role="tab" aria-controls="purchase" aria-selected="true" class="nav-link active" href="#purchase">{{ $t('leffit.products.product.purchase_pricelevels') }}</a>
						<a data-toggle="tab" role="tab" aria-controls="sales" aria-selected="false" class="nav-link" href="#sales">{{ $t('leffit.products.product.sales_pricelevels') }}</a>
					</nav>
					<div class="tab-content" id="myTabContent">
						<div class="tab-pane fade show active" id="purchase" role="tabpanel" aria-labelledby="home-tab">
							<purchasePriceLevels isCreate="true"></purchasePriceLevels>
						</div>
						<div class="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="profile-tab">
							<salesPriceLevels isCreate="true"></salesPriceLevels>
						</div>
					</div>
					<!-- 
						TODO 
						ADD section to set productprices directly on creating the product
						(use content of LEFFIT.PRODUCTS.PRODUCT.PURCHASE_PRICELEVELS tab)
					-->
				</div>

                <button @click="create()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script>
import purchasePriceLevels from './layouts/pricelevels_purchase'
import salesPriceLevels from './layouts/pricelevel_sales'
export default {
    name:'create',
	components:{
		purchasePriceLevels,salesPriceLevels
	},
    data(){
        return {
            item : {
                'name' 				: '', 
				'description' 		: '',
				'producttype_id' 	: '1',
				'ean' 				: '',
				'productstatus_id' 	: '1',
				'price' 		: '',
				'weight' 		: '',
				'vat' 			: '21',
				'stock' 		: '',
				'size' 		: 0,
				'seizoenscode' : 0,
				'remarks' 		: '', 
				'supplier':'',
				'currency':0,
				'supplier_code':0,
				filters:[],
				pricelevels:[],
				sales_pricelevels:[]
            },
            productcode : {
            	part_a : '',
            	part_b : '',
            	part_c : '',
            },
            producttypes:[],
            productstatuses:[],
			product_filters:[],
			suppliers:[],
			currencies:[],
			productfilter_id:0,
			pricelevels:[],
			sales_pricelevels:[],
			price_settings:{},
			settings:{},
			setting_ready:false,
        }
    },
    methods:{
		sizeChanged(){ 
			var temp = this.productcode;
			temp.part_c = this.item.size;
			this.productcode = {};
			this.productcode = temp;
		},
		productFilterChanged(){
			var filter = this.product_filters.find((d)=> d.id == this.productfilter_id);
			if(!filter) return false;
			if(!filter.extra) return false;
			var extra = filter.extra;
			if(!extra.Productcode) return false;
			if(!extra.Productcode.productcode) return false;
			var productcode = extra.Productcode.productcode;
			if(productcode.value.length <= 0) return false;
			var value = productcode.value.split('.');

			this.productcode.part_a = value[0];
			this.productcode.part_b = parseInt(value[1]) + 1;
			this.productcode.part_c = this.item.size;

		},
		sortPriceLevels(){
			this.pricelevels = this.price_settings.pricelevels.map((d)=>{
				d.price = 0;
				d.key = 'leffit.products.pricelevel.'+d.key;
				var pricelevel = this.item.pricelevels.find((p)=> p.key == d.key);
				if(pricelevel != undefined) { d.price = pricelevel.price; d.alreadyset = true; d.id = pricelevel.id; }
				return d;
			});
			this.sales_pricelevels = this.price_settings.sales_pricelevels.map((d)=>{
				d.price = 0;
				d.key = 'leffit.products.pricelevel.'+d.key;
				var pricelevel = this.item.pricelevels.find((p)=> p.key == d.key);
				if(pricelevel != undefined) { d.price = pricelevel.price; d.alreadyset = true; d.id = pricelevel.id; }
				return d;
			});
		},
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            console.log(this.item);
			
			this.item.pricelevels = this.pricelevels;
			this.item.sales_pricelevels = this.sales_pricelevels;

            this.$store.dispatch('post',{endpoint:'/leffit/products/products',details:this.item})
			.then(data=>{
				// console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Product created",text:'Product was created successfully'});
                window.location.href="/admin/products/products";
			}).catch((error)=>{
				console.log(error);
			});
        },
		
    },
    mounted(){
        this.$store.dispatch('get','/leffit/products/producttypes')
        .then(data=>{
        	// console.log('result producttypes',data);
			this.producttypes = data.data.data;
			var context=this;
			window.$('.select1').select2().change(function(){
				context.item.producttype_id=window.$('.select1').val();
				window.console.log(window.$('.select1').val()); 
			});
		}); 

		this.$store.dispatch('get','/leffit/products/productstatuses')
        .then(data=>{
        	// console.log('result productstatuses',data);
			this.productstatuses = data.data.data;
			var context=this;
			window.$('.select').select2().change(function(){
				context.item.productstatus_id=window.$('.select').val();
				// window.console.log(window.$('.select1').val());
			});
		}); 
    },
	created(){
		this.$store.dispatch('get','/leffit/products/get-highest-ean')
        .then(data=>{
			var prd=data.data;
			if(prd.ean) {
				this.item.ean = parseInt(prd.ean)+1;
			}
		});

		this.$store.dispatch('get','/leffit/products/get-product-filter-children/84')
        .then(data=>{
			this.product_filters = data.data;
		});

		this.$store.dispatch('get','/leffit/crm/organizations')
        .then(data=>{ 
			this.suppliers = data.data.data;
		});
		
		this.$store.dispatch('get','/lefficient/leffit/currencies')
        .then(data=>{
			this.currencies = data.data.data;
			console.log(this.currencies);
			var default_currency = this.currencies.find((d)=> d.code == 'EUR');
			console.log(default_currency);
			setTimeout(()=>{
				this.item.currency = {label:default_currency.name, id:default_currency.id};
			},500)
		});

		this.$store.dispatch('get', '/lefficient/leffit/packages/settings/product_settings')
        .then(data => {
            // console.log(data);
            if(data.data != null){
                if(data.data.setting != null){
					var settings=JSON.parse(data.data.setting);  
					settings.tabs.forEach(element => { 
						this.settings[element.field]=element.show;
					});
					console.log(settings); 

					this.price_settings.pricelevels=settings.pricelevels ? JSON.parse(JSON.stringify(settings.pricelevels)):[];
					this.price_settings.sales_pricelevels=settings.pricelevels ? JSON.parse(JSON.stringify(settings.sales_pricelevels)):[];
					this.setting_ready = true;
					this.sortPriceLevels();

                }
			}
        })
        .catch((error)=>{
            console.log(error);
        });
	}
}
</script>
<style >
	.select2-container{
		border: 1px solid lightgray !important;
		border-radius: 4px;
		padding: 4px 0px;
		height: unset !important;
	}
	.nav-link{
		color: grey;
	}
	.nav-link.active{
		color: #fb9c7f;
	}
</style>