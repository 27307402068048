export default `
<section class="seven bg-white  pt-4 pb-4">
<h3 id='section28header1' id="section28header1" class="h2">++{{section28header1}}</h3>
<h6 id="section28header2" class="p font-weight-bold">++{{section28header2}}</h6>

<div class="card">
    <div class="row card-body text-center">
        <div class="col-xm-0 col-sm-1"></div>
        <div class="col-xm-2 col-sm-2"><img src="++{{section28Image1}}"></div>
        <div class="col-xm-2 col-sm-2"><img src="++{{section28Image2}}"></div>
        <div class="col-xm-2 col-sm-2"><img src="++{{section28Image3}}"></div>
        <div class="col-xm-2 col-sm-2"><img src="++{{section28Image4}}"></div>
        <div class="col-xm-2 col-sm-2"><img src="++{{section28Image5}}"></div>
        <div class="col-xm-0 col-sm-1"></div>	
    </div>
    <div class="">
        <a id="section28Text1" class="btn btn-lg mb-4 mt-3 text-center">++{{section28Text1}} </a>
    </div>
</div>

<div class="card text-center mt-5 ">
        <div class="card-body">
            <h6 id='section28header3' class="p">++{{section28header3}}</h6>
            <h6 id='section28header4' class="p font-weight-bold mb-0 ">++{{section28header4}}</h6>
            <h6 id='section28header5' class="p">++{{section28header5}}</h6>

            <h6 id='section28header6' class="p font-weight-bold mb-0">++{{section28header6}}</h6>
            <h6 id='section28header7' class="p">++{{section28header7}}</h6>
             
            <h6 id='section28header8' class="p font-weight-bold mb-0">++{{section28header8}}</h6>
            <h6 id='section28header9' class="p">++{{section28header9}}</h6>

            <h6 id="section28header10" class="p font-weight-bold mb-0">++{{section28header10}}</h6>
            <h6 id='section28header11' class="p">++{{section28header11}}</h6>
        </div>
    </div>
</section>                                                      
`