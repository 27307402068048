import SalesOrderIndex from '../../../../src/leffit/orders/order/index';
import SalesOrderItem from '../../../../src/leffit/orders/order/item';
import SalesOrderCreate from '../../../../src/leffit/orders/order/create';

import SalesQuotationIndex from '../../../../src/leffit/orders/order/index';
import SalesQuotationItem from '../../../../src/leffit/orders/order/item';
import SalesQuotationCreate from '../../../../src/leffit/orders/order/create';

import SalesPackageslipIndex from '../../../../src/leffit/orders/order/index';
import SalesPackageslipItem from '../../../../src/leffit/orders/order/item';
import SalesPackageslipCreate from '../../../../src/leffit/orders/order/create';

import SalesInvoiceIndex from '../../../../src/leffit/orders/order/index';
import SalesInvoiceItem from '../../../../src/leffit/orders/order/item';
import SalesInvoiceCreate from '../../../../src/leffit/orders/order/create';

import SalesReturnIndex from '../../../../src/leffit/orders/order/index';
import SalesReturnItem from '../../../../src/leffit/orders/order/item';
import SalesReturnCreate from '../../../../src/leffit/orders/order/create';

import SalesCreditInvoiceIndex from '../../../../src/leffit/orders/order/index';
import SalesCreditInvoiceItem from '../../../../src/leffit/orders/order/item';
import SalesCreditInvoiceCreate from '../../../../src/leffit/orders/order/create';

var tmp = process.env.VUE_APP_LEFFIT_SALES_ORDERTYPES.split(',');
var VUE_APP_LEFFIT_SALES_ORDERTYPES = [];

tmp.forEach(function (item) {
    item = item.split('|');
    VUE_APP_LEFFIT_SALES_ORDERTYPES[item[0]] = parseInt(item[1]);
});

export default [
    {
        path: "orders/sales_order",
        component: SalesOrderIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_order']
        }
    },
    {
        path: "orders/sales_order/create/:id",
        component: SalesOrderItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_order']
        }
    },
    {
        path: "orders/sales_order/create",
        component: SalesOrderCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_order']
        }
    },

    {
        path: "orders/sales_quotation",
        component: SalesQuotationIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_quotation']
        }
    },
    {
        path: "orders/sales_quotation/create/:id",
        component: SalesQuotationItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_quotation']
        }
    },
    {
        path: "orders/sales_quotation/create",
        component: SalesQuotationCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_quotation']
        }
    },
    {
        path: "orders/sales_packageslip",
        component: SalesPackageslipIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_packageslip']
        }
    },
    {
        path: "orders/sales_return_received",
        component: SalesPackageslipIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_return_received']
        }
    },
    {
        path: "orders/sales_return_received/create/:id",
        component: SalesReturnItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_return_received']
        }
    },
    {
        path: "orders/sales_packageslip/create/:id",
        component: SalesPackageslipItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_packageslip']
        }
    },
    {
        path: "orders/sales_packageslip/create",
        component: SalesPackageslipCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_packageslip']
        }
    },
    {
        path: "orders/sales_invoice",
        component: SalesInvoiceIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice']
        }
    },
    {
        path: "orders/sales_invoice/create/:id",
        component: SalesInvoiceItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice']
        }
    },
    {
        path: "orders/sales_invoice/create",
        component: SalesInvoiceCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice']
        }
    },

    {
        path: "orders/sales_return",
        component: SalesReturnIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_return']
        }
    },
    {
        path: "orders/sales_return/create/:id",
        component: SalesReturnItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_return']
        }
    },
    {
        path: "orders/sales_return/create",
        component: SalesReturnCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_return']
        }
    },

    {
        path: "orders/sales_invoice_credit",
        component: SalesCreditInvoiceIndex,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice_credit']
        }
    },
    {
        path: "orders/sales_invoice_credit/create/:id",
        component: SalesCreditInvoiceItem,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice_credit']
        }
    },
    {
        path: "orders/sales_invoice_credit/create",
        component: SalesCreditInvoiceCreate,
        meta: {
            AuthRequired: true
        },
        props: {
            ordertype_id: VUE_APP_LEFFIT_SALES_ORDERTYPES['sales_invoice_credit']
        }
    },
]