<template>
    <div class="row">
        <div class="col-12">
            <button data-target="#plans" data-toggle="modal" class="btn btn-sm-btn-success">ADD NEW PLAN</button>
        </div>
        <div class="col-12">
            <table class="table table-hover">
                    <thead>
                        <tr>
                            <th colspan="3">ID</th> 
                            <th colspan="3">Plan name</th> 
                            <th colspan="3"></th>
                            <th colspan="3"></th>
                        </tr>
                    </thead> 
                    <tbody> 
                        <tr v-for="(plan,index) in plans" :key="index" >
                            <td colspan="3">
                                {{index+1}}
                            </td>
                            <td colspan="3">{{plan.name}}</td>
                            <td colspan="3">
                                <a @click="activePlan=plan" href="#viewTerms" data-toggle="modal">view terms</a>
                            </td> 
                            <td colspan="3">
                                <a @click="removePlans(index)" class="btn btn-sm btn-danger" href="#"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>

        <div id="plans" class="modal">
            <div class="modal-dialog ">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">  
                            <div class="form-group col-12 col-md-12">
                                <label for="">Name</label>
                                <input type="text" class="form-control" v-model="plan.name">
                            </div>
                            <div class="form-group col-12 ">
                                <button @click="addNewPlan()" class="btn btn-sm btn-success" style="margin-top:-5px">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="viewTerms">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="col-12">
                            <button data-target="#addTerms" data-toggle="modal" class="btn btn-sm-btn-success">ADD NEW TERM</button>
                        </div>
                        <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <td colspan="3">Terms</td>
                                        <td colspan="3">Amount</td>
                                        <td colspan="3">Name</td>
                                        <td colspan="3">Description</td>
                                        <td colspan="3">After</td>
                                        <td colspan="3">Paymentprovider</td>
                                        <td colspan="3">Paymentmethod</td>
                                        <td colspan="3"></td>
                                    </tr>
                                </thead> 
                                <tbody> 
                                    <tr v-for="(term,index) in activePlan.terms" :key="index" class="text-center">
                                        <td colspan="3">
                                            {{index+1}}
                                        </td>
                                        <td colspan="3">{{term.amount}}</td>
                                        <td colspan="3">{{term.name}}</td>
                                        <td colspan="3">
                                            <a href="#" @click="editTerms(index)" >view description</a>
                                        </td>
                                        <td colspan="3">{{term.duration +' '+term.period}}</td>
                                        <td colspan="3">{{$t(getProvider(term.provider))}}</td>
                                        <td colspan="3">{{$t(getMethod(term.method))}}</td> 
                                        <td>
                                            <div>
                                                <button @click="editTerms(index)" class="btn btn-sm btn-primary"><i class="fas fa-edit"></i></button> 
                                                <button @click="removeTerms(index)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>    
                    </div>
                </div>
            </div>
        </div>
        <div id="addTerms" class="modal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label for="">Amount</label>
                                <input type="number" class="form-control" v-model="term.amount" >
                            </div> 
                            <div class="form-group col-12 col-md-6">
                                <label for="">Name</label>
                                <input type="text" class="form-control" v-model="term.name">
                            </div>
                            <div class="form-group col-12">
                                <label for="">Description</label>
                                <Editor v-model="term.description"></Editor>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="">Duration</label>
                                <input type="number" class="form-control" v-model="term.duration">
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="">Period</label>
                                <select class="form-control" v-model="term.period">
                                    <option value="days">Days</option>
                                    <option value="month">Months</option>
                                    <option value="year">Years</option>
                                </select>
                            </div> 
                            <div class="form-group col-12 col-md-6">
                                <label for="">Provider</label>
                                <Select2 class="form-control" v-model="term.provider"  :options="providers" />
                            </div> 
                            <div class="form-group col-12 col-md-6">
                                <label for="">Method</label>
                                <Select2 class="form-control" v-model="term.method"  :options="methods" />
                            </div> 
                            <div class="form-group col-12 ">
                                <button @click="addNewTerm()" class="btn btn-sm btn-success" style="margin-top:-5px">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="viewDescription" class="modal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div v-if="edit > -1" class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label for="">Amount</label>
                                <input type="number" class="form-control" v-model="activePlan.terms[edit].amount" >
                            </div> 
                            <div class="form-group col-12 col-md-6">
                                <label for="">Name</label>
                                <input type="text" class="form-control" v-model="activePlan.terms[edit].name">
                            </div>
                            <div class="form-group col-12">
                                <label for="">Description</label>
                                <Editor v-model="activePlan.terms[edit].description"></Editor>
                            </div>
                             <div class="form-group col-12 col-md-6">
                                <label for="">Duration</label>
                                <input type="number" class="form-control" v-model="activePlan.terms[edit].duration">
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="">Period</label>
                                <select class="form-control" v-model="activePlan.terms[edit].period">
                                    <option value="days">Days</option>
                                    <option value="month">Months</option>
                                    <option value="year">Years</option>
                                </select>
                            </div>  
                            <div class="form-group col-12 col-md-6">
                                <label for="">Provider</label>
                                <Select2 class="form-control" v-model="activePlan.terms[edit].provider"  :options="providers" />
                            </div> 
                           <div class="form-group col-12 col-md-6">
                                <label for="">Method</label>
                                <Select2 class="form-control" v-model="activePlan.terms[edit].method"  :options="methods" />
                            </div> 
                            <div class="form-group col-12 ">
                                <button @click="updateTerm()" class="btn btn-sm btn-success" style="margin-top:-5px">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['item'],
    data(){
        return {
            term:{
                amount:0,name:'',description:'',duration:0,period:0,provider:'',method:''
            },
            plan:{name:'',terms:[]},
            plans:[],
            terms:[],
            edit:-1, 
            providers:[],
            methods:[],
            activePlan:{}
        }
    },
    methods: {
        addNewPlan(){
            if(this.plan.name == ''){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'All fields are required',type:'danger'});
                return false;
            }
            this.plan.id=Math.floor(Math.random() * 99999);

            console.log(this.plan);
            
            this.plans.push(this.plan);
            this.$parent.plans=this.plans;
            this.$parent.updateItem();
            this.plan={name:'',terms:[]};
            window.$("#plans").modal('hide'); 
        },
        getProvider(id){
            var result=this.providers.find((d)=> d.id == parseInt(id)); 
            return result ? result.text :'Not found'
        },
        getMethod(id){
            var result=this.methods.find((d)=> d.id == parseInt(id));
            return result ? result.text :'Not found'
        },
        editTerms(index){
            this.edit=index;
            window.$("#viewDescription").modal('show'); 
        },
        updateTerm(){
            this.edit=-1;
              
            this.updateCurrentPlan();

            window.$("#viewDescription").modal('hide'); 
            setTimeout(()=>{
                window.location.reload();
            },500);
        },
        updateCurrentPlan(){
            this.plans=this.plans.map((plan)=>{
                if(plan.name == this.activePlan.name){
                    return JSON.parse(JSON.stringify(this.activePlan));
                }
                return plan;
            }); 
            this.$parent.plans=this.plans;
            this.$parent.updateItem();
        },
        removeTerms(index){
            if(!confirm("Are you sure you want to delete this item?")){ 
                return false;
            }
            var terms=this.activePlan.terms;
            terms.splice(index,1);
            this.activePlan.terms=terms;
            this.updateCurrentPlan();
        },
        removePlans(index){
            if(!confirm("Are you sure you want to delete this item?")){ 
                return false;
            }
            var plans=this.plans;
            plans.splice(index,1);
            this.plans=this.plans;
            this.updateCurrentPlan();
        },
        addNewTerm(){ 
            if(this.term.name == '' || this.term.description == '' || this.term.provider == '' || this.term.method == ''){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'All fields are required',type:'danger'});
                return false;
            } 

            this.plans=this.plans.map((plan)=>{
                if(plan.name == this.activePlan.name){
                    plan.terms.push(this.term);
                }
                return plan;
            });
            this.$parent.plans=this.plans;
            this.$parent.updateItem();
            this.term={amount:0,name:'',description:'',after:'',provider:'',method:''};
        }
    },
    created(){
        if(this.item.paymentplans != null){
            this.plans=JSON.parse(this.item.paymentplans);
            this.$parent.plans=this.plans;
        }

        this.$store.dispatch('get','/leffit/payments/providers')
        .then(data=>{
        	var thas = this;
			this.providers = data.data.data.map((d)=>{
                return {
                    id:d.id,
                    text: thas.$t(d.name)
                }
            });
        });
        
        this.$store.dispatch('get','/leffit/payments/methods')
        .then(data=>{
        	// console.log(data.data);
            var thas = this;// 
			this.methods = data.data.data.map((d)=>{
                return {
                    id:d.id,
                    text: thas.$t(d.name)
                }
            });
		});
    }
}
</script>
<style scoped>
    .form-control.dormant{
        border: none;
        background: transparent;
    }
    .form-control.dormant:focus{
        outline: none;
        border: none;
    }
    .form-control.dormant:active{
        outline: none;
        border: none;
    }
</style>