<template>
    <div>
        <div class="accordion col-12" id="accordionExample">
            <div class="card" v-for="(setting,index) in settings" :key="index">
                <div class="card-header" :id="'headingOne'">
                    <h2 class="mb-0 d-flex" style="justify-content:space-between"> 
                        <label class="col-12 col-sm-6 d-flex text-left" style="width:50%">
                            <input v-if="setting.show != undefined" v-model="setting.show" style="width:30px;height:18px;margin-top:0px" :id="setting.title+'label'+index"  type="checkbox" class="form-control">
                            {{setting.title}}
                        </label>
                        <select style="width:50%" v-if="setting.options_api" name="" id="" v-model="setting.value" class="form-control d-inline">
                            <option value="">click to select </option> 
                            <option v-for="(option,index2) in list_data[setting.field]" :key="index2" :value="option.value">{{option.title}}</option>
                        </select>
                    </h2>
                </div>  
            </div> 
            <div class="card col-12">
                <button type="button" @click="updateItem()" class="btn btn-success">UPDATE</button>
            </div>
        </div> 

        <div class="card pb-0">
            <div class="row">
                <div class="col-12 d-flex" style="justify-content: space-between;">
                    <h4>Meta Tabs</h4>
                    <button data-target="#tabModal" data-toggle="modal" class="btn btn-success btn-sm" style="line-height: 1 !important;padding: 0px 10px;height: 33px;">Create Tab</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ul class="nav nav-pills nav-fill">
                        <li v-for="(tab,index) in group_tabs" :key="index" class="nav-item">
                            <a style="justify-content:space-between" @click="currentTab_id=tab.id" data-toggle="tab" class="nav-link d-flex" :href="'#nav-home'+index" role="tab" :aria-controls="'nav-home'+index" aria-selected="true">
                                <span style=" padding: 10px;">{{tab.name}}</span>
                                <a  @click="deleteTab(tab.id)" href="javascript:void(0)"><i style="font-size: 16px;color: red;" class="fas fa-trash"></i></a>
                            </a>
                        </li> 
                    </ul>
                </div>
                <div class="col-12 mt-3">
                    <div class="tab-content" id="nav-tabContent">
                        <div  v-for="(tab,index) in group_tabs" :key="index" class="tab-pane fade " :id="'nav-home'+index" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="row mt-0 pb-3 pt-2" style="background: #d3d3d347;">
                                <div class="col-12">
                                    <button data-target="#groupModal" data-toggle="modal" class="btn btn-primary btn-sm float-right">Add group</button>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3">
                                    <ul class="nav nav-pills flex-column">
                                        <li v-for="(group,index2) in tab.groups" :key="index2" class="nav-item">
                                            <a style="display: flex;" @click="group_key.group=group.group;group_key.group_type=group.type" data-toggle="tab" class="nav-link" :href="'#nav-group-home'+index2+index">
                                                <a style="display:inline-block;color: red;" @click="deleteGroup($event,tab.id,group)" href="#"><i style="font-size: 15px;padding: 4px 0px;" class="fas fa-trash"></i></a>
                                                <span class="ml-2">{{group.group}}</span>
                                            </a>
                                        </li> 
                                    </ul>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="tab-content" id="nav-tabContent">
                                        <div v-for="(group,index_g) in tab.groups" :key="index_g" class="tab-pane fade" :id="'nav-group-home'+index_g+index">
                                            <div class="col-12">
                                                <button data-target="#keygroupModal" data-toggle="modal" class="btn-sm btn btn-block">Create field</button>
                                            </div>
                                            <div class="col-12"> 
                                                <table class="table table-responsive table-hover">
                                                    <thead> 
                                                        <tr>
                                                            <th></th>
                                                            <th>Label</th>
                                                            <th>Key</th>
                                                            <th>Input type</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(key,index3) in group.keys" :key="index3">
                                                            <th>
                                                                <button @click="removeKey(key.key,group.group)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                                                <button v-if="!key.edit" @click="edit(key.key,group.group)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                                                                <button v-else @click="updateMeta(key.key,group.group)" class="btn btn-success btn-sm"><i class="fa fa-check"></i></button>
                                                                <button v-if="index3 > 0" @click="re_order(index3,group.group,'up')" class="btn btn-success btn-sm"><i class="fa fa-chevron-up"></i></button>
                                                                <button v-if="index3 < group.keys.length-1" @click="re_order(index3,group.group,'down')" class="btn btn-danger btn-sm"><i class="fa fa-chevron-down"></i></button>
                                                            </th>
                                                            <td>
                                                                <span v-if="!key.edit">{{key.label}}</span>
                                                                <input v-else type="text" class="form-control" v-model="key.label" />   
                                                            </td>
                                                            <td>
                                                                <span v-if="!key.edit">{{key.key}}</span>
                                                                <input v-else type="text" class="form-control" v-model="key.key" />   
                                                            </td>
                                                            <td>
                                                                <span v-if="!key.edit">{{key.type}}</span>
                                                                <select v-else class="form-control" v-model="key.type">
                                                                    <option value="text">Text</option>
                                                                    <option value="tinymce">Tinymce</option>
                                                                    <option value="select">Select</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div v-if="key.type == 'select'">
                                                                    <a @click="viewOption(key)" data-toggle="modal" href="#optionModal" id="">view options</a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="relateds != null" class="card col-12">
            <related :relateds="relateds"></related>
        </div>

        <div class="card col-12">
            <pricelevels :relateds="relateds"></pricelevels>
        </div>

         <div class="card col-12">
            <purchasepricelevels :relateds="relateds"></purchasepricelevels>
        </div>

        <!-- <div v-if="price_display != null" class="card col-12">
            <price :prices="price_display"></price>
        </div> -->
 
        <div class="modal" id="groupModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">CREATE NEW GROUP</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-sm-12 ">
                                <label for="">Group</label>
                                <input v-model="group.group" type="text" class="form-control" />
                            </div>
                            <div class="form-group col-sm-12">
                                <label for="">Input type</label>
                                <select v-model="group.type" class="form-control">
                                    <option value="single">Single</option> 
                                    <option value="multiple">Multiple</option>
                                </select>
                            </div> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="createGroup()" type="button" class="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="tabModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">CREATE NEW TAB</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-sm-12 ">
                                <label for="">Tab name</label>
                                <input v-model="tab.name" type="text" class="form-control" />
                            </div> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="createTab()" type="button" class="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="keygroupModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-head">
                        <h4 class="modal-title">{{group_key.group.group}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row"> 
						    <div class="form-group col-sm-12 ">
                                <label for="">Key</label>
                                <input v-model="group_key.key" type="text" class="form-control" />
                            </div>  
                            <div class="form-group col-sm-12">
                                <label for="">Label</label>
                                <input v-model="group_key.label" type="text" class="form-control" />
                            </div> 
                            <div class="form-group col-sm-12">
                                <label for="">Input type</label>
                                <select v-model="group_key.type" class="form-control">
                                    <option value="text">Text</option>
                                    <option value="tinymce">Tinymce</option>
                                    <option value="select">Select</option> 
                                </select>
                            </div> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="createField()" type="button" class="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="optionModal">
            <div class="modal-dialog">
                <div class="modal-content"> 
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Option Label</label>
                            <input type="text" class="form-control" v-model="option.label">
                        </div>
                        <div class="form-group">
                            <label for="">Option value</label>
                            <input type="text" class="form-control" v-model="option.value">
                        </div>
                        <button @click="createOption()" class="btn">Create Option</button>

                        <div class="row">
                            <table class="table table-responsive table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Label</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(option,index) in options.filter((d)=> d.field_id == option.field_id)" :key="index">
                                        <td>
                                            <button @click="removeOption(option.id)" class="btn"><i class="fas fa-trash"></i></button>
                                            <button @click="editOption(option)" class="btn"><i class="fas fa-edit"></i></button>
                                        </td>
                                        <td>{{option.label}}</td>
                                        <td>{{option.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
import related from './related';
import pricelevels from './pricelevels';
import purchasepricelevels from './purchase_pricelevels';

export default {
    name: 'index',
    components: { related  , pricelevels , purchasepricelevels},
    data() {
        return {  
            settings:[],
            groups:[],
            group_tabs:[],
            tab:{name:'',groups:[]},
            group:{group:'',keys:[],type:''},
            group_key:{group:'',key:'',label:"",type:''},
            relateds:null,
            list_data:{},
            currentgroup:null,
            currentTab_id:0,
            options:[],
            option:{field_id:0,id:0,label:'',value:''},
            message_status:[],
            pricelevels:[],
            sales_pricelevels:[],
            status:'',
            price_display:null
        }
    },  
    methods: {
        createStatus(){
            var item={text:this.status,id:Math.round(Math.random()*999999)}; 
            this.message_status.push(item);
            this.updateItem();
            this.status='';
        },
        removeStatus(id){ 
            if(!confirm("Are you sure you want to remove this item")){
                return false;
            }
            this.message_status=this.message_status.filter((d)=>d.id != id);
            this.updateItem();
        },
        viewOption(field){
            this.option.field_id=field.id;
        },
        createOption(){ 
            var option=JSON.parse(JSON.stringify(this.option));
            if(this.options.find((d)=> d.id == this.option.id)){
                this.options=this.options.map((d)=>{
                    if(d.id == this.option.id){
                        return option;
                    }
                    return d;
                });
            }else{
                option.id=Math.floor(Math.random() * 999999);
                this.options.push(option);
            } 
            this.option.label='';
            this.option.value='';
            this.option.id=0;

            this.updateItem();
        },
        editOption(option){
            this.option=option;
        },
        removeOption(id){
            this.options=this.options.filter((d)=> d.id != id);
            this.updateItem();
        },
        deleteGroup(e,tb,grp){
            e.stopPropagation();
            if(!confirm("Are you sure you want to delete this group")){
                return false;
            }

            this.group_tabs=this.group_tabs.map((tab)=>{
                if(tab.id == tb){
                    tab.groups=tab.groups.filter((group)=> group.id != grp.id); 
                    console.log(tab);
                }
                return tab;
            });

            this.updateItem();

            this.$store.dispatch('get','/lefficient/leffit/remove-meta-label/'+grp.group+'/'+this.$route.params.id)
            .then((data)=>{
                console.log(data); 
                this.$store.dispatch('makeNotification',{title:"Group updated",text:'Group was removed successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error.request);
            });

        },
        edit(key,grp){   
            this.group_tabs=this.group_tabs.map((tb)=>{
                tb.groups=tb.groups.map((group)=>{
                    if(group.group == grp){
                        group.keys=group.keys.map((d)=>{
                            if(d.key == key){
                                d.edit=d.edit ? !d.edit:true;
                            }
                            return d;
                        });
                    }
                    return group;
                });
                return tb;
            });
        },
        updateMeta(key,grp){  
            var payload={};
            this.group_tabs=this.group_tabs.map((tb)=>{
                tb.groups=tb.groups.map((group)=>{
                    if(group.group == grp){
                        group.keys=group.keys.map((d)=>{
                            if(d.key == key){
                                payload=d;
                                d.edit=false;
                            }
                            return d;
                        });
                    }
                    return group;
                });
                return tb;
            });

            this.$store.dispatch('post',{
                endpoint:'/lefficient/leffit/update-meta-labels/'+grp+'/'+key+'/'+this.$route.params.id,
                details:payload
            })
            .then((data)=>{
                console.log(data);
                this.$store.dispatch('makeNotification',{title:"Item updated",text:'Field was updated successfully',type:'success'});
            })
            .catch((error)=>{
                // console.log(error.request);
            });

        },
        re_order(key_index,group,direction){
            var payload=[];
            var data=[];
            this.group_tabs=this.group_tabs.map((tb)=>{
                if(tb.id == this.currentTab_id){
                    data=tb.groups.map((d)=>{
                        if(d.group == group){
                            var tmp=d.keys;
                            if(direction == 'up'){
                                var temp=tmp[key_index];
                                tmp[key_index]=tmp[key_index-1];
                                tmp[key_index-1]=temp;
                            }else if(direction == 'down'){
                                temp=tmp[key_index];
                                tmp[key_index]=tmp[key_index+1];
                                tmp[key_index+1]=temp;
                            }
                            d.keys=tmp;
                            payload=tmp.map((mp)=>{ return mp.id}); 

                        }
                        return d;
                    });
                    tb.groups=data;
                }
                return tb;
            });  
            this.groups=data;  
            this.order(payload);
        },
        order(payload){ 
            this.$store.dispatch('post',{
                endpoint:'/lefficient/leffit/order-meta-label',
                details:{data:payload},
            })
            .then((data)=>{
                console.log(data); 
                this.$store.dispatch('makeNotification',{title:"Group updated",text:'Field ordered successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error.request);
            });
        },
        removeKey(key,grp){
            if(!confirm("This field will be removed")){
                return false;
            }
            this.$store.dispatch('get','/lefficient/leffit/remove-meta-labels/'+grp+'/'+key+'/'+this.$route.params.id)
            .then((data)=>{
                console.log(data);
                this.$store.dispatch('makeNotification',{title:"Item removed",text:'Field was removed successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error.request);
            });

            this.group_tabs=this.group_tabs.map((tb)=>{
                tb.groups=tb.groups.map((group)=>{
                    if(group.group == grp){
                        group.keys=group.keys.filter((d)=>d.key != key);
                        console.log(group.keys);
                    }
                    return group;
                });
                return tb;
            });
            console.log(this.groups);
        },
        selectGroup(group){
            this.group_key.group=group.group;
        },
        addKeyToGroup(){
            this.groups=this.groups.map((group)=>{
                if(group.group == this.group_key.group.group){
                    group.keys.unshift(
                        {
                            key:this.group_key.key,
                            label:this.group_key.label,
                            type:this.group_key.type,
                        }
                    );
                }
                return group;
            });
            this.updateItem();
        },
        updateItem(){
            var payload=this.settings; 
            var data={tabs:payload,group_tabs:this.group_tabs,options:this.options,pricelevels:this.pricelevels,sales_pricelevels:this.sales_pricelevels};
            if(this.relateds != null){
                data.related_types=this.relateds;
                data.price_display=this.price_display;
            }
            console.log(data);
            this.$store.dispatch('put', {
                details:data,
                endpoint:'/lefficient/leffit/packages/settings/model/'+this.$route.params.id
            })
            .then(data => {
               console.log(data);
               this.$store.dispatch('makeNotification',{title:"MODEL UPDATE",text:'Model was updated successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error);
            });

            window.$("#groupModal").modal('hide');
            window.$("#tabModal").modal('hide');
        },
        fetchAndSaveOptions(url,field){
            this.$store.dispatch('get', url)
            .then(data => {
               var temp=this.list_data;
               this.list_data={};
               var result=data.data.data.map((d)=> { return {value:d.id,title:d.name} });
               
               temp[field]=result;
               this.list_data=temp;
               console.log(this.list_data);
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        createTab(){
            if(this.tab.name.length == 0 ){
                return false;
            }
            var temp=this.tab; 
            temp=JSON.parse(JSON.stringify(temp));
            temp.id=Math.floor(Math.random() * 999999);
            
            this.group_tabs.push(temp);  
            this.updateItem();
        },
        deleteTab(id){  
            if(!confirm('Are you sure you want to delete the tab?')){
                return false;
            }

            console.log(id);


            var gt = this.group_tabs.filter((d)=> d.id != id);  
            this.group_tabs =[];
            this.group_tabs=gt;
            this.updateItem();
            console.log(this.group_tabs);
        },
        createGroup(){
            if(this.group.group.length == 0 || this.group.type.length == 0){
                return false;
            }
            var temp=this.group;
            delete temp.keys; 
             
            temp=JSON.parse(JSON.stringify(temp));
            temp.id=Math.floor(Math.random() * 999999);
            this.group_tabs=this.group_tabs.map((tb)=>{
                if(tb.id == this.currentTab_id){
                    tb.groups = tb.groups ? [...tb.groups,temp]:[temp];
                }
                return tb;
            }); 
 
            this.updateItem();
        },
        createField(){ 
            this.group_key.model=this.$route.params.id;
            this.$store.dispatch('post',{
                endpoint:'/lefficient/leffit/create-meta-label',
                details:this.group_key,
            })
            .then((data)=>{ 
                data.data.type=this.group_key.type;
                var temp=JSON.parse(JSON.stringify(data.data));
                this.group_tabs=this.group_tabs.map((tb)=>{
                    if(tb.id == this.currentTab_id){
                        tb.groups=tb.groups.map((d)=>{
                            if(d.group == this.group_key.group){
                                if(!d.keys){
                                    d.keys=[];
                                }
                                d.keys.push(temp);
                            }
                            return d;
                        });
                    }
                    return tb;
                });
                
                this.$store.dispatch('makeNotification',{title:"Group updated",text:'Field was added successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error.request);
            }); 
            window.$("#keygroupModal").modal('hide');
        },
        init(){
            console.log(this.settings);
            this.settings.forEach(setting => {
                console.log(setting);
                if(setting.options_api != undefined){
                    this.fetchAndSaveOptions(setting.options_api,setting.field);
                }
            });
        }
    }, 
    mounted() { 
        this.$store.dispatch('get', '/lefficient/leffit/packages/settings/' + this.$route.params.id)
        .then(data => {
            console.log(data);
            if(data.data != null){
                if(data.data.setting != null){
                    var settings=JSON.parse(data.data.setting);
                    this.settings=settings.tabs; 
                    this.options=settings.options ? settings.options : [];
                    var group_tabs=settings.group_tabs ? settings.group_tabs: [];
                    this.relateds=settings.related_types; 
                    this.price_display=settings.price_display;
                    this.pricelevels = settings.pricelevels ? settings.pricelevels : [];
                    this.sales_pricelevels = settings.sales_pricelevels ? settings.sales_pricelevels : [];
                    
                    console.log(settings);

                    // this.message_status=settings.message_status ? settings.message_status:[]
                    this.group_tabs=group_tabs.map((tb)=>{
                        tb.groups=tb.groups.map((group)=>{
                            var fields=data.data.fields.filter((d)=> d.group == group.group);
                            fields=fields.map((fd)=>{
                                var settg=fd.settings != null ? JSON.parse(fd.settings):{};
                                fd.type=settg.type;
                                return fd;
                            });
                            group.keys=fields;
                            return group
                        });
                        return tb;
                    }); 
                    this.init();
                }
            } 
        })
        .catch((error)=>{
            console.log(error);
        })
    },
    created(){
        
    }
}
</script>
<style  scoped>
    .ms{
        list-style: none;
        background: #d3d3d37d;
        display: inline-block;
        padding: 2px 10px 2px 15px;
        border-radius: 18px;
        font-size: 14px;
        border: 1px solid lightgray;
        margin: 0px 5px;
    }
    .ms a{
        color:red;
        margin-left: 10px;
    }
    .flex-column a.nav-link{
        border-radius: 0px !important;
        box-shadow: none !important;
    }
    .flex-column a.nav-link.active{
        background: #4e5467;
    }
    .itemPermission{
        background-color: #d3d3d370;
        padding: 5px 8px;
        display: inline-block;
        width: auto;
        border-radius: 20px;
        margin-right:10px;
    }
    .itemPermission b{
        padding: 0px 10px;
        font-weight: 300;
        text-transform: capitalize;
    }
    .itemPermission a{
        color: red;
    }
    .itemPermission a i{
        font-weight: lighter;
        font-size: 14px;
    }
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>