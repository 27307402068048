<template>
	<div>
		<div class="head row p-0 m-0">
        	<h4>{{ $t('leffit.casa.pricat.export') }}</h4>
      	</div>

		<div class="row p-0 m-0">
			<a class="btn btn-success" href="" target="_blank">Dowload</a>
		</div>
	</div>
	
	
</template>

<script>
import { mapState } from 'vuex';

export default {
  	name: 'pricat_import',

  	data () {
	    return {
	    	content : '',
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
	},
	created(){ 
	},
}
</script>

<style scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>