<template>
    <div style="position:relative;height:100vh">  
        <font-picker class="d-none" :api-key="'AIzaSyBSqhPLj1EhSlhfNHWQKJjzOFmGXqSC0Cs'" :options="{}" ></font-picker>
        <div id="editor"> 
            <div class="edit-container">
                <div class="row m-0 p-0"> 
                    <div class="col-12">
                        <div class="cover col-12">
                            <div v-for="(section,index) in sections" :key="index" class="section temp"> 
                                <div @mousemove="mouseMove($event)"  @mouseup="mouseUp()"  class="sectionBody row"> 
                                    <div  :style="buildColumnStyle(col)" v-for="(col,indexr) in section" :key="indexr+'r'" :class="'part col-'+col.width+' '+buildColumnClass(col)" style="positive:relative">
                                        <div @click="blockClick(col,index,indexr)" class="column" v-html="bindValuesToContent(col)"></div>

                                        <!-- CHILD ROWS -->
                                        <div @click="blockClick(row,index,indexr,indexc)"  v-for="(row,indexc) in col.rows" :key="indexc+'c'" :class="' col-12 column row-column'"  >
                                            <div v-html="bindValuesToContent(row)"></div>
                                            <div class="sectionTools tool-bottom inner-tool">
                                                <button @click="removeInnerRow($event,index,indexr,indexc)"  class="btn"><i class="fa fa-remove"></i></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                 
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
 
    </div>
</template>
<script>
import FontPicker from 'font-picker-vue';
export default { 
    components:{'font-picker':FontPicker},
    data(){
        return {
            item:{
                name:'',fieldtype_id:''
            },
            data:{
                id:1,
                title:'',
                description:'',
                content:'',
                blueprint:'',
                styles:'',
                mode:'initial',
                fields:[]
            },
            block:{fields:[]},
            fieldtypes:[],
            sections:[],
            blocks:[],
            fields:[],
            position:'',
            max_with:12,
            mouse_down:false,
            xAxis:0,resizer:{col:0,row:null,id:0},
            distance_drag:0,
            edit_setting_section:{settings:{}},
            default_settings:{
                margin:{top:'',right:'',left:'',bottom:''},
                padding:{top:'',right:'',left:'',bottom:''},
                background:{backgroundColor:'transparent','backgroundImage':'','position':'','size':''},
                font:{color:'black',size:'',align:'left',family:''},
                container:{type:'boxed',column:3,align:''},
            },
            margins_top:['','mt-1','mt-2','mt-3','mt-4','mt-5'],
            margins_bottom:['','mb-1','mb-2','mb-3','mb-4','mb-5'],
            margins_right:['','mr-1','mr-2','mr-3','mr-4','mr-5'],
            margins_left:['','ml-1','ml-2','ml-3','ml-4','ml-5'],
            paddings_top:['','pt-1','pt-2','pt-3','pt-4','pt-5'],
            paddings_bottom:['','pb-1','pb-2','pb-3','pb-4','pb-5'],
            paddings_right:['','pr-1','pr-2','pr-3','pr-4','pr-5'],
            paddings_left:['','pl-1','pl-2','pl-3','pl-4','pl-5'],
            backgroundImage:null,
            uploaded_setting_image:false,
            fontFamily:'',
            blocktypes:['Title','Subtitle','Image','Bigtext','Button','Video','Slider'],
            options:{
                'text-align':[{text:'left',value:'left'},{text:'center',value:'center'},{text:'right',value:'right'}],
                'margin-align':[{text:'left',value:'mr-auto'},{text:'center',value:'m-auto'},{text:'right',value:'ml-auto'}],
            }
        }
    }, 
    methods:{  
        blockClick(block,row,col,inner_row=null){ 
            var settings=block.settings;
            if(typeof settings == 'string'){
                settings=JSON.parse(settings);
            }
            
            settings.width=block.width;
            this.block=settings;
            this.block.row=row;
            this.block.col=col;
            if(this.inner_row){
                this.block.inner_row=inner_row;
            }
            window.$("#editBlock").modal('show');
        },
        removeInnerRow(e,row,col,inner_row){
            e.stopPropagation(); 
            if(!confirm("Are you sure you want to remove this")){
                return false;
            }
            
            var temp=this.sections;
            temp[row][col].rows.splice(inner_row,1);
            this.sections=temp;
        },
        updateBlock(blk){
            var temp=this.sections;
            var row=this.block.row;
            var col=this.block.col;
            var inner_row=this.block.inner_row;
            if(inner_row){
                temp[row][col].rows[inner_row].settings.content=this.bindValuesToContent(blk);
            }else{
                temp[row][col].settings.content=this.bindValuesToContent(blk);
            }
            
            this.sections=temp;
            console.log(blk,this.sections);
        },
        saveSetting(){
            var temp=this.sections;
            var col=this.edit_setting_section.col;
            var row=this.edit_setting_section.row;

            delete this.edit_setting_section.col;
            delete this.edit_setting_section.row;

            temp[row][col]=this.edit_setting_section; 
            this.sections=[];
            this.sections=temp;   
            this.closeEditSection(); 
        },
        changeFont(d,el){ 
            this.block.column.font.family=d.family;
            this.updateField(el,d.family);
        },
        updateField(el,value){
            this.block.fields=this.block.fields.map((d)=>{
                if(d.element == el){
                    d.content=value;
                }
                return d;
            }); 
        },
        closeEditSection(){
            window.$("#edittingSection").modal('hide');
        },
        editblock(col,row){
            var temp=this.sections[row][col];
            if(typeof temp.settings == 'string'){
                temp.settings= JSON.parse(temp.settings);
                temp.settings.column={...this.default_settings,...temp.settings.column};
            }
            if(temp.settings.length == 0){
                temp.settings.column=this.default_settings;
            }
            temp.col=col;
            temp.row=row;
            this.edit_setting_section=temp;
            window.$("#edittingSection").modal('show');
        },
        pickColor(){
            document.getElementById('colorPicker').click();
        },
        removeblock(col,row){
            if(!confirm("Are you sure?")){
                return false;
            } 
            var temp=this.sections; 
            temp[row].splice(col,1);
            this.sections=[];
            this.sections=temp; 
        },
        mouseDown(e,col,row,id){ 
            this.mouse_down=true;
            this.xAxis=e.clientX;
            this.resizer.col=col;
            this.resizer.row=row;
            this.resizer.id=id;
        },
        mouseUp(){
            this.mouse_down=false;
            this.resizer.col=0;
            this.resizer.row=null;
            this.resizer.id=0; 
        },
        mouseMove(e){
            var col=this.resizer.col;   
            var row=this.resizer.row;
            var id=this.resizer.id;
            
            if(this.mouse_down){ 
                if(this.distance_drag >= 10){
                    if(this.xAxis < e.clientX){ 
                        this.xAxis=e.clientX;
                        this.resetWidth('+',this.resizer.row,this.resizer.col);
                    }else if(this.xAxis > e.clientX){
                        this.xAxis=e.clientX;
                        this.resetWidth('-',this.resizer.row,this.resizer.col);
                    }
                    this.distance_drag=0;
                    // console.log('initialised drag');
                }
                this.distance_drag=this.distance_drag+1; 
            }
        },
        resetWidth(action,row,col){
            var temp=this.sections; 
            var total_width=0;
            temp[row].forEach(element => {total_width+=element.width;});
            
            if(action == '+' && total_width >= 12){
                this.$store.dispatch('makeNotification',{type:'danger',title:"UI error",text:'there is no more space to add another column.Reduce the width of some columns in the row'});
                return false;
            }

            temp[row][col].width += action == '+' ? 1 : -1; 
            this.sections=[];
            this.sections=temp; 
        },
        save(){
            if(this.loading){
                return false;
            }

            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:"You can't leave the name field empty."});
                return false;
            } 

            this.$store.dispatch('put',{endpoint:'/leffit/cms/sectiontypes/'+this.item.id,details:this.item});
            var rows=[];
            var settings=this.item.setting != null ? this.item.setting:{layout:[]} ;
            var temp_section=[];

            settings.layout=this.sections.map((section)=>{
                temp_section=section.map((d,indx)=>{ 
                    if(d.rows != undefined){
                        rows=d.rows.map((r,indx2)=>{ return {pickorder:indx2,settings:JSON.stringify(r.settings)}; });
                    }
                    return {pickorder:indx,width:d.width,rows,settings:JSON.stringify(d.settings)};
                });
                return temp_section;
            });

            console.log(settings);
            settings.type='course';

            this.$store.dispatch('put',{endpoint:'/leffit/cms/sectiontypes/attach-section/'+this.item.id,details:settings})
            .then(data=>{ 
                console.log(data);
                this.$store.dispatch('makeNotification',{type:'success',title:"Sectiontype updated",text:'Sectiontype was saved successfully'});
            }).catch((error)=>{
                console.log(error.request);
            });
        },
        moveDown(index){
            var temp=this.sections;
            var next=temp[index + 1];
            temp[index + 1]=temp[index];
            temp[index]=next;
            this.sections=[];
            this.sections=temp; 
        },
        moveUp(index){
            var temp=this.sections;
            var prev=temp[index - 1];
            temp[index - 1]=temp[index];
            temp[index]=prev;
            this.sections=[];
            this.sections=temp; 
        },
        deleteSection(indx){
            if(!confirm("Are you sure you want to remove this section?")){
                return false;
            }
            this.sections=this.sections.filter((sect,index)=> index != indx);
        },
        selectSection(data){
             
            data=this.buildFieldContent(data);
            data.column=this.default_settings;
            var block={
                rows:[],
                width:data.width,
                settings:data
            };
            

            this.position=this.position.toString();
            block.width=this.max_with
            if(this.position == 'top'){// adding new block to the top of the page
                this.sections.unshift([block]);
            }else if(this.position == 'bottom'){//adding new block to the bottom of the page
                this.sections.push([block]);
            }else if(this.position.split(',').length > 1){// adding new row into a column
                this.position=this.position.split(',');
                this.sections=this.sections.map((d,index)=>{
                    if(index == this.position[0]){
                        var total_width=0; 
                        if(d[this.position[1]].rows == undefined){
                            d[this.position[1]].rows=[];
                        }
                        d[this.position[1]].rows.push(block);   
                        
                    }
                    return d;
                });
            }else{//adding new block(column) into an existing row
                this.sections=this.sections.map((d,index)=>{
                    if(index == this.position){
                        var total_width=0;
                        d.forEach(element => {total_width+=element.width;});
                        if(total_width < this.max_with){
                            block.width=this.max_with - total_width;
                            d.push(block);   
                        }else{
                            this.$store.dispatch('makeNotification',{type:'danger',title:"UI error",text:'there is no more space to add another column.Reduce the width of some columns in the row'});
                        }
                    }
                    return d;
                });
            }
            this.position='';
            document.getElementById('close').click();
            console.log(this.sections);
        },
        triggerAddModal(position){
            this.position=position;
        }, 
        buildColumnClass(col){
            var classes="";
            var settings=col.settings ? col.settings.column : null;

            if(settings == null){
                return '';
            }

            for(var p in settings.padding){ classes+=settings.padding[p]+' '; }
            for(var m in settings.margin){ classes+=settings.margin[m]+' '; }

            var align={left:'mr-auto',right:'ml-auto',center:'m-auto'};
            if(settings.container.align){
                classes+=align[settings.container.align];
            } 
            if(settings.container.type == 'fluid'){
                classes+=' container-fluid';
            }
            return classes;
        },
        buildColumnStyle(col){
            var styles="";
            var settings=col.settings ? col.settings.column : null;
            if(settings == null){
                return '';
            } 
            styles+="background-color:"+settings.background.backgroundColor+";";
            styles+="background-image:"+settings.background.backgroundImage+";";
            styles+="background-position:"+settings.background.position+";";
            styles+="background-size:"+settings.background.size+";";
            
            return styles;
        },
        getSectionType(){
            this.$store.dispatch('get','/leffit/cms/sectiontypes/'+this.$route.params.id)
            .then(data=>{ 
                this.item = data.data.data; 

                var settings=JSON.parse(this.item.settings);
                if(settings.layout != undefined){
                    var layout=settings.layout;
                    
                    layout=layout.map((row)=>{
                        row=row.map((col)=>{ 
                            var column=col;
                            column=JSON.stringify(column);
                            column=JSON.parse(column);
                            column.width=col.width;
                            column.settings=JSON.parse(column.settings);
                            
                            column.rows=column.rows.map((rw)=>{
                                rw.settings=JSON.parse(rw.settings);
                                return rw;
                            });
                            // console.log(this.buildColumnStyle(column));
                            // console.log(this.buildColumnClass(column));

                            return column;
                        });
                        return row;
                    });
                    console.log(layout);
                    layout=layout.filter((d)=> d!= null);
                    this.sections=layout;
                }
            });
        },
        getDOMcontent(type,fields){  
            type=type.toLowerCase();
            var html=''; 
            if(type == 'title' || type == 'subtitle'){
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'text').element+`}}</p>`;
            }else if(type == 'textarea'){
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'textarea').element+`}}</p>`;
            }
            else if(type == 'bigtext'){ 
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'WYSIWYG').element+`}}</p>`;
            }
            else if(type == 'image'){
                html=`<img
                        src="++{{`+fields.find((field)=> field.type == 'image').element+`}}"
                        alt="++{{`+fields.find((field)=> field.label == 'ALT').element+`}}"
                        title="++{{`+fields.find((field)=> field.label == 'TITLE').element+`}}"
                        style="++{{styles}}" class="++{{classes}}" />`;
            }
            else if(type == 'video'){
                html=`<div style="++{{styles}}" class="++{{classes}}" >++{{`+fields.find((field)=> field.type == 'text').element+`}}</div>`;
            }
            else if(type == 'button'){
                html=`<a  
                        href="++{{`+fields.find((field)=> field.label == 'URL').element+`}}" 
                        style="++{{styles}}" 
                        class="btn d-block ++{{classes}}"
                        >++{{`+fields.find((field)=> field.label == 'BUTTON TEXT').element+`}}</a>`;
            } 
            return html;
        },
        getAllFields(type){ 
            type=type.toLowerCase();
            switch(type){
                case 'title':
                    return [
                        {type:'text',label:'text content'},
                        {style:'font-size',type:'number',label:'font-size'},
                        {style:'color',type:'font-color',label:'font-color'},
                        {style:'font-family',type:'font-family',label:'font-family'},
                        {style:'text-align',type:'select',label:'Text align'},
                    ];
                case 'subtitle':
                    return [
                        {type:'text',label:'text content'},
                        {style:'font-size',type:'number',label:'font-size'},
                        {style:'color',type:'font-color',label:'font-color'},
                        {style:'font-family',type:'font-family',label:'font-family'},
                        {style:'text-align',type:'select',label:'Text align'},
                    ];
                case 'textarea':
                    return [
                        {type:'textarea',label:'Text content'},
                        {style:'font-size',type:'number',label:'font-size'},
                        {style:'color',type:'font-color',label:'Color'},
                    ];
                case 'bigtext':
                    return [
                        {type:'WYSIWYG','label':'text',value:'WYSIWYG'},
                    ];
                case 'image':
                    return [
                        {type:'image',label:'image'},
                        {type:'text',label:'alt'},
                        {type:'text',label:'title'},
                        {style:'width',type:'number',label:'width'},
                        {style:'height',type:'number',label:'height'},
                    ];
                case 'video':
                    return [
                        {type:'text',label:'Embed iframe input'}, 
                        {style:'width',type:'number',label:'width'},
                        {style:'height',type:'number',label:'height'},
                    ];
                case 'button':
                    return [
                        {type:'text',label:'button-text',value:''},
                        {type:'text',label:'url',value:'#'},
                        {style:'font-size',type:'number',label:'font-size',value:''},
                        {style:'color',type:'font-color',label:'font-color',value:''},
                        {style:'font-family',type:'font-family',label:'font-family',value:''},
                        {class:'margin-align',type:'select',label:'Button alignment',value:''},
                        {style:'background-color',type:'font-color',label:'background-color',value:''},
                        {style:'width',type:'number',label:'width',value:'100'},
                        {style:'height',type:'number',label:'height',value:''},
                    ];
                default:
                    return []
            }
        },
        uploadImage(e,el){
            var file=e.target; 
            if(file !=null && file.files.length <= 0){
                return false;
            }            
            let fileReader=new FileReader(); 
            fileReader.onload=(result)=>{ 
                this.uploadMediaImages(result.srcElement.result,el);
            } 
            fileReader.readAsDataURL(file.files[0]);
        }, 
        uploadMediaImages(data,el){ 
            console.log(el);
            this.$store.dispatch('post',{
                details:{image:data},
                endpoint:'/leffit/cms/add-media/'+this.$route.params.id
            })
            .then((data)=>{
                console.log(data); 
                this.updateField(el,data.data); 
            })
            .catch((error)=>{
                console.log(error.request); 
            });
        },
        buildFieldContent(type){
            var content="";
            var id=Math.floor(Math.random() * 999999999); 

            var data={
                id:1,
                title:'',
                description:'',
                content:'',
                blueprint:'',
                styles:'',
                mode:'initial',
                fields:[]
            };
            var fieldname='field'+id;
            var fieldnames=[];
            var fields=[];
            
 
            fields=this.getAllFields(type); 
            fields.forEach(element => {
                fieldname='field'+Math.floor(Math.random() * 999999999);
                data.fields.push({
                    element:fieldname,
                    type:element.type,
                    label:element.label ? element.label.replace('-',' ').toUpperCase() : element.title.replace('-',' ').toUpperCase(),
                    style_name:element.style,
                    class:element.class,
                    content:element.value ? element.value:element.type == 'text' ? type :'',
                });
                });

            data.styles=this.buildStyles(data);
            data.classes=this.buildClasses(data);

            data.blueprint=this.getDOMcontent(type,data.fields);
            data.content=data.blueprint; 
            // var final=this.bindValuesToContent(data);
            console.log(data);
            return data;
        },
        bindValuesToContent(field){ 
            var data='';
            if(field.settings)  {
                data=typeof field.settings == 'string' ? JSON.parse(field.settings):field.settings; 
            }else{
                data=field; 
            }
            if(data == null || data.fields == undefined){
                return field.name;
            }
            var fields=data.fields;
            var content=data.blueprint;
            data.styles=this.buildStyles(data);
            data.classes=this.buildClasses(data);

            fields.map((field)=>{
                content=content.replace('++{{'+field.element+'}}',field.content);
            });
            content=content.replace("++{{styles}}",data.styles);
            content=content.replace("++{{classes}}",data.classes);
            data.content=content;
            return data.content;
        },
        buildStyles(data){
            var styles=''; 
            var style_fields=data.fields.filter((field)=> field.style_name != undefined);
            style_fields.forEach(element => {
                if(element.type == 'number'){
                    styles+=element.style_name+':'+element.content+'px !important;';
                }else{
                    styles+=element.style_name+':'+element.content+';';
                } 
            });
            return styles;
        },
        buildClasses(data){ 
            var classes='';
            var class_fields=data.fields.filter((field)=> field.class != undefined);
            class_fields.forEach(element => { 
                classes+=element.content+' ';
            });
            return classes;
        },
    },
    mounted(){ 
        // this.$store.dispatch('get','/leffit/cms/blocktypes')
        // .then(data=>{
        //     console.log(data);
        //     this.blocks = data.data.data;
        //     var context=this;
        //     this.getSectionType();
        //     setTimeout(()=>{
        //         window.$('.select').val(this.item.fieldtype_id).select2().change(function(){
        //             context.item.fieldtype_id=window.$(this).val();
        //         });
        //     },500);
        // });

       this.getSectionType();
    },
}
</script>

<style scoped>
    p{
        margin: 0px;
    }  
    .cover{
        min-height: 100px;  
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .edit-container{
        border: 1px solid lightgrey;
        background: #d3d3d336;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 999999999;
    }
    #editor{
        height:100%
    }
</style> 
<style scoped>
    
    .sect{
        width: 33%;
        height: 200px;
        display: inline-block;
        text-align: center;
        padding: 10px;
    }
    .hold{
        background: lightblue;
        height: 100%;
    }
    .hold p{
            overflow-wrap: break-word;
    }
    .hold:hover{
        cursor: pointer;
    }
</style>

<style scoped> 
    .container-fluid{
        padding: 0px 10px;
    }
    .tols{
        display: flex;
        justify-content: flex-end;
    }
    .templateHolder{ 
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        margin-top: 80px;
        width: 670px;
        border: 1px solid lightgray;
        padding: 10px;
        height: 90%;
        overflow-y: scroll;
    }
    .editBoard{
        position: absolute;
        top:0%;
        bottom: 5%;
        right: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;
        width: 500px; 
        padding: 10px;
        height: 94%;
        overflow-y: scroll;
        background: #f2f2f2;
    }
    .editBoard h4{
        font-size: 16px;
        color: gray;
    }
    .templateHolder.edit-mode{
        right: initial;
        left: 10px;
        transition: all .5s linear;
        margin-left: 0px;
    }
        
    .tools{
        padding: 10px 5px; 
        background: #333333; 
        width: 100%;
        z-index: 99;
        color: white;
        left: 0;
        right: 0;
        margin: auto;
    }
    .tools .title{
        display: flex;
        height: 40px;
        margin: 0px;
    }
    .tools .title div.title{
        font-size: 20px;
        height: 100%; 
        display: block;
        margin-left: 20px;
        padding-top:8px;
    }
    .tool{ 
        color: gray;
        font-weight: 500;
        font-size: smaller; 
    }
    .temp{
        position: relative;  
    }
    .temp:hover{
        cursor: pointer; 
    }
    .temp:hover .top-tools{
        display: block;
    }
    .temp.editting{
        border: 2px dashed red;
    }
    .temp:hover .top-tools button{
        background: black;
    }
    .temp:hover .sectionTools button i{
        font-size: 12px;
    }
    .sectionTools{
        position: absolute;
        top:0;
        right:0;  
        z-index: 999;
        display: none;
    }
    .sectionTools button.btn{
        background: #000000cc;
        color: white;
        border-radius: 0px; 
        font-size: 10px;
        padding: 3px 5px;
    }
    .editBody{
        margin-top:30px;
    }
    .uploadImage{
        width:150px;
        height:150px;
        border:2px solid lightgray;
        background: #d3d3d3c9;
        position: relative;
        transition: all .1s linear;
    }
    .uploadImage:hover{
        transform: scale(0.95);
    }
    .uploadImage span{
        position: absolute;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        margin: auto;
        height: fit-content;
        width: fit-content;
        color: gray;
    }
    .textEditor{
        margin-top: 20px;
        text-align: left;
    }
    .textEditor label{
        color:red;
    }
    .editFooter{
        /* position: absolute; */
        /* bottom: 10px; */
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .editFooter .btn{
        border-radius: 1px;
    }

    .btm-tools{ 
        left: 0;
        right: unset;
        bottom: 0;
        top: unset;
    }
       
</style>
