export default `
<section class="bg-white first " style="">
    <div class="row bod">
        <div class="img1 col-sm-6">
            <img src="++{{section22Image}}">
        </div>
        <div class="href col-sm-6">
            <a id="section22linktext" href="++{{section22link}}">++{{section22linktext}}</a>
        </div>	
    </div>			
</section>
`