<template>
    <div class="row m-0 p-2 mb-5">
        <div class="col-md-2">
            <ul class="nav flex-column">
                <li class="nav-item active">
                    <a class="nav-link"  @click="displayImagesTab('dutch')" href="#">{{ $t('lefficient.languages.nl.nl') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  @click="displayImagesTab('english')" href="#">{{ $t('lefficient.languages.nl.en') }}</a>
                </li>
            </ul>
        </div>
        <div class="col-md-10 ">
            <div class="col-12">
                <input @change="readImage()" type="file" class="d-none" id="fineInput">
                <button @click="trigger()" data-target="#uploadImage" data-toggle="modal" class="btn btn-success btn-sm">{{ $t('lefficient.system.add_photo') }}</button>

                <button v-show="imagesTab.dutch" @click="copy_image('nl','en')" class="btn btn-success btn-sm">{{ $t('lefficient.system.copy_to') }} {{ $t('lefficient.languages.nl.en') }}</button>
                <button v-show="imagesTab.english" @click="copy_image('en','nl')" class="btn btn-success btn-sm">{{ $t('lefficient.system.copy_to') }} {{ $t('lefficient.languages.nl.nl') }}</button>
                <div class="tab-content">
                    <div v-show="imagesTab.dutch" id="dutch" class="tab-pane active">
                        <table v-if="item.productimages != undefined" class="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>{{ $t('lefficient.system.filename') }}</th> 
                                </tr>
                            </thead>
                            <tbody style="width:100%">
                                
                                <tr  v-for="(img,index) in item.productimages_nl" :key="index">
                                    <td>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <!-- <button @click="toggleEdit(img.id)" v-if="img.disabled" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-remove"></i></button> -->
                                            <!-- <button @click="updateImage(img)" v-if="img.disabled" class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-check"></i></button> -->

                                            <!-- <button @click="toggleEdit(img.id)" v-if="!img.disabled" class="btn btn-sm pl-2 pr-2 btn-primary"><i class="fa fa-edit"></i></button> -->
                                            <button v-if="index> 0"
                                                    @click="changeOrder(index,'up','nl')"
                                                    class="btn btn-sm pl-2 pr-2 btn-warning"
                                            >
                                              <i class="fa fa-chevron-up"></i>
                                            </button>
                                            <button v-if="index < item.productimages_nl.length -1" @click="changeOrder(index,'down','nl')" class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-chevron-down"></i></button>
                                            <button @click="deleteImage(img.id)" v-if="!img.disabled" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                    <td>
                                        <productimage :url="img.url"></productimage>
                                        
                                        <!-- <productimage :url="base_url+'/storage/'+img.id+'/'+img.file_name"></productimage> -->
                                    </td>
                                    <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.file_name" id=""></td>
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.title" id=""></td> -->
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.alt" id=""></td> -->
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.caption" id=""></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div  v-show="imagesTab.english" id="english" class="tab-pane active">
                        <table v-if="item.productimages != undefined" class="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>{{ $t('lefficient.system.filename') }}</th>
                                    <th>{{ $t('lefficient.system.title') }}</th> 
                                </tr>
                            </thead>
                            <tbody>
                                  <tr  v-for="(img,index) in item.productimages_en" :key="index">
                                    <td>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <!-- <button @click="toggleEdit(img.id)" v-if="img.disabled" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-remove"></i></button> -->
                                            <!-- <button @click="updateImage(img)" v-if="img.disabled" class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-check"></i></button> -->

                                            <!-- <button @click="toggleEdit(img.id)" v-if="!img.disabled" class="btn btn-sm pl-2 pr-2 btn-primary"><i class="fa fa-edit"></i></button> -->
                                            <button v-if="index> 0" @click="changeOrder(index,'up','en')" class="btn btn-sm pl-2 pr-2 btn-warning"><i class="fa fa-chevron-up"></i></button>
                                            <button v-if="index < item.productimages_en.length -1" @click="changeOrder(index,'down','en')" class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-chevron-down"></i></button>
                                            <button @click="deleteImage(img.id)" v-if="!img.disabled" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                    <td>
                                        <productimage :url="img.url" ></productimage>
                                        
                                        <!-- <productimage :url="base_url+'/storage/'+img.id+'/'+img.file_name"></productimage> -->
                                    </td>
                                    <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.file_name" id=""></td>
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.title" id=""></td> -->
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.alt" id=""></td> -->
                                    <!-- <td><input type="text" class="edit form-control" :disabled="!img.disabled" v-model="img.caption" id=""></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

        <div id="uploadImage" class="modal">
            <div class="modal-dialog">
                <div class="modal-content" >

                    <div class="modal-header">Add product image</div>
                    <div class="modal-body pt-5 pb-1" @dragleave="dragLeave($event)" @dragover="dragOver($event)" @drop="drop($event)">
                       <div class="row d-flex">
                            <div v-for="(img,index) in payloadImages" :key="index" v-bind:style="{ backgroundImage: 'url(' + img.source + ')' }" class="imageHolder">
                                <i @click="removeFile(img.id)" class="fa fa-remove"></i>
                            </div>
                            <div  @click="trigger()" class="addmore">
                                <i class="fa fa-plus"></i>
                            </div>
                       </div>

                       <div  class="row mt-2">
                           <button id="removeCancel" data-dismiss="modal" class="btn btn-danger btn-sm remove">Cancel</button>
                           <button v-if="payloadImages.length > 0" @click="upload()" class="btn btn-success btn-sm">Upload Images</button>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import productimage from "./productimage";
export default {
    name:'productImages',
    components:{productimage},
    props:['item','product'],
    data(){
        return {
            images:[],
            payloadImages:[], 
            imagesTab:{
                dutch:true,
                english:false,
                langx:false,etc:false
            }, 
        }
    },
    computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint,
            user:state=>state.leffit.user,
            token:state=>state.leffit.token,
            productImages:state=>state.leffit.productImages,
        }),
    },
    methods:{
        changeOrder(index,direction,lang){
            var data=this.item['productimages_'+lang];
            if(direction == 'up'){
                var temp=data[index];
                data[index]=data[index-1];
                data[index-1]=temp;
            }else if(direction == 'down'){
                temp=data[index];
                data[index]=data[index+1];
                data[index+1]=temp;
            }
            var payload={};
            data.forEach((img,index)=>{
                payload[img.id]=index;
            });
            console.log(payload);
            this.$store.dispatch('post',{endpoint:'/leffit/products/change-order-productimages/'+this.item.id+'/'+lang,details:{order:payload}})
            .then(data=>{
                console.log(data);
                var images=data.data['productimages_'+lang];
                // this.item['productimages_'+lang]=null;
                this.item['productimages_'+lang]=images.sort((a,b)=> a.pickorder - b.pickorder);
                console.log(images);
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
                // window.location.reload();
            })
            .catch((error)=>{
                console.log(error.request);
            });

        },
        copy_image(from,to){
             this.$store.dispatch('post',{endpoint:'/leffit/products/copy-image/'+this.item.id+'/'+from+'/'+to})
            .then(data=>{
                console.log(data);
                window.location.reload();
                // this.item.productimages=[...data.data,...this.item.productimages];
                // this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            })
            .catch((error)=>{
                window.console.log(error.request);
            });
        },
        displayImagesTab(field){
            this.imagesTab={
                dutch:false,
                english:false,
                langx:false,etc:false
            };
            this.imagesTab[field]=true;
        },
        dragOver(e){
            e.stopPropagation();
            e.preventDefault();
            var el=e.target;
            el.style.background='lightgray';
        },
        dragLeave(e){
            var el=e.target;
            el.style.background='transparent';
        },
        drop(e){
            e.stopPropagation();
            e.preventDefault();
            var files = e.target.files || e.dataTransfer.files;
            // console.log(files);
            // this.dragFile=files;
            this.readImage(files[0]);
            this.dragLeave(e);
        },
        updateImage(image){
            this.$store.dispatch('put',{endpoint:'/leffit/products/productimages/'+image.id,details:image})
            .then(data=>{
                console.log(data);
                // this.item.productimages=[...data.data,...this.item.productimages];
                this.$store.dispatch('makeNotification',{title:"Image UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
            this.toggleEdit(image.id);
        },
        toggleEdit(id){
            this.item.productimages=this.item.productimages.map((img)=>{

                if(img.id == id){
                    img.disabled= img.disabled == undefined ? true:!img.disabled;
                }else{
                    img.disabled=false;
                }
                return img;
            });
        },
        deleteImage(id){
            if(!confirm("Are you sure you want to remove this item?")){
                return false;
            }
            var lang=this.imagesTab.english ? 'en':'nl';
            var temp=this.item['productimages_'+lang].filter((img)=> img.id != id); 
            console.log(temp);
            this.item['productimages_'+lang]=[];
            this.hide=true;
            this.item['productimages_'+lang]=temp; 
            
             this.$store.dispatch('delete','/leffit/products/delete-productimages/'+this.product+'/'+id+'/'+lang)
            .then(data=>{
                console.log(data);
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
            // window.location.reload();
        },
        upload(){
            var formData=new FormData();

            this.payloadImages.forEach((element,index) => {
                formData.append('files'+index,element.file);
            });
            var lang=this.imagesTab.english ? 'en':'nl';
            formData.append('lang',lang);
            console.log(lang);
            
            this.$store.dispatch('post',{endpoint:'/leffit/products/add-productimages/'+this.item.id,details:formData})
            .then(data=>{
                console.log(data);
                var images=data.data['productimages_'+lang];
                this.item['productimages_'+lang]=images;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
                // window.location.reload();
            });

            document.getElementById('removeCancel').click();
        },
        trigger(){
            document.getElementById('fineInput').click();
        },
        removeFile(id){
            this.payloadImages=this.payloadImages.filter((img)=> img.id != id);
        },
        readImage(upload=null){
            if(upload == null){
                upload=document.getElementById('fineInput').files[0];
            }
			var formats=['image/jpeg','image/jpg','image/png','image/PNG','image/HEIC'];
			if(formats.find((img)=> upload.type == img) == undefined){
				alert('file format not supported');
				return false;
			}
			var fileReader=new FileReader();
            var data={};
			fileReader.onload=(result)=>{
                data.source=result.srcElement.result;
                data.file=upload;
                data.id=Math.random() * 9999;
                this.payloadImages.push(data);
			}
            fileReader.readAsDataURL(upload);

        }
    },
    mounted(){ 
        this.item.productimages_nl=this.item.productimages_nl.sort((a,b)=> a.pickorder - b.pickorder);
    }
}
</script>
<style scoped>
    
.edit{
		background-color: white !important;
		transition: all .3s linear;
	}
.edit[disabled]{
		background-color: transparent !important;
		border:none;
		overflow: visible;
		cursor: initial;
		transition: all .3s linear;
	}
.imgh{
		display: block;
		width: 70px;
		height: 70px;
        border:1px solid grey;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
	}
    .imageHolder{
        width: 100px;
        height: 100px;
        border:1px solid lightgray;
        margin: auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 10px;
        position: relative;
    }
    .imageHolder .fa{
        float: right;
        color: red;
        font-size: 20px;
    }
    .addmore{
        width: 100px;
        height: 100px;
        background: #d3d3d37a;
        margin: auto;
        margin-top: 10px;
        position: relative;
    }
    .addmore .fa{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10px;
        height: 10px;
    }
</style>