<template>
    <table class="table table-responsive table-hover">
        <thead>
          <tr>
            <th></th>
            <th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.product.'+attribute) }}</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th><a @click="searchTable()" class="btn btn-primary"><i class="fa fa-search"></i></a></th>
            <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search.search[attribute]" class="form-control"></th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(item, index) in pagedata">
            <td>
              <!-- <router-link :to="'/admin/products/product/'+item.id" class="btn btn-open">
                <i class="fa fa-search"></i>
              </router-link> -->
              <router-link :to="'/admin/website/pagetypes/'+item.id+'/edit'" class="btn btn-warning">
                <i class="fa fa-pencil"></i>
              </router-link>
            </td>
            <td :key="index" v-for="(attribute,index) in attributes">{{ translate(item[attribute]) }}</td>
          </tr>
        </tbody>
    </table>
</template>

<script>
export default { 
    data () {
      return {
        pagedata : [],
        attributes : [ 
          'name',  
          'created_at'
        ],
        search : {search:{}},
      }
    },
    methods: {
    translate (input) {
    if(input){
        if(input.constructor === String){
        if(input.indexOf('leffit.products')!==-1){
            return this.$t(input);
        }else{
            return input;
        }
        }
    }
    return input;
    },
    searchTable(){  
        console.log(this.search);
        this.$store.dispatch('post',{details:this.search,endpoint:'/leffit/cms/pagetypes/search'})
        .then((data)=>{
            console.log(data);
            this.pagedata=data.data.data;
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    },
    mounted(){
      this.$store.dispatch('post',{endpoint:'/leffit/cms/pagetypes/search',details:{search:{limit:10}}})
        .then(data=>{
          console.log(data);
      this.pagedata = data.data.data;
    });
	},
}
</script>

<style lang="css" scoped>
</style>