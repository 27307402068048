export default `
<section class="four bg-white  ">
<div class="row pt-4 mb-3">
    <div class="col-md-6">
        <h2 id="section19header" class="h2">++{{section19header}}</h2>
        <h6 id="section19Text1" class="p">++{{section19Text1}}</h6>
    </div>
    <div class="col-md-6 text-center">
        <img src="++{{section19Image1}}">
    </div>
</div>
</section>
`