<template>
    <div id="dashboard" class="container-fluid mt-0">
        <div class="row">
            <div class="col-12">
                <div id="exampleModal" class="modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{$t('leffit.courses.sections.new_section')}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form action="" class="mb-5" @submit="createItem($event)">
                                <div class="form-group">
                                    <input v-model="title" id="title" type="text" class="form-control" :placeholder="$t('leffit.courses.sections.template_title')">
                                </div>
                                <formerror />
                            </form>
                            <button @click="createItem($event)" type="submit" class="btn btn-success">{{$t('leffit.courses.sections.create')}}</button>
                            <button type="button" class="btn btn-secondary float-right" data-dismiss="modal">{{$t('leffit.courses.sections.close')}}</button>
                        </div>
                        </div>
                    </div>
                </div>

                <button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-success float-right mb-3">
                    {{$t('leffit.courses.sections.new_section')}}
                </button>
            </div>
        <div class="col-12">
            <table id="example" class="display" style="width:100%">
                <thead>
                    <tr>
                        <th>{{$t('leffit.courses.sections.title')}}</th>
                        <!-- <th>Short Description</th> -->
                        <th>{{$t('leffit.courses.sections.Action')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in items" :key="index">
                        <td><a :href="'sections/'+item.id">{{item.name}}</a></td>

                        <td>
                            <div>
                                <a :href="'sections/'+item.id" class="btn btn-sm btn-success">OPEN</a> &nbsp;
                                <button @click="deleteN(item.id)" class="btn btn-sm btn-danger">DELETE</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</template>

<script>
import formerror from "../formErrors";
import { mapState } from "vuex";
export default {
    name:'newletterIndex',
    components:{formerror},
    data(){
        return {
            title:'',
            items:[]
        }
    },
    computed:{...mapState({
    })},
    updated(){
        window.$('#example').DataTable();
    },
    created(){
        this.$store.state.leffit.loader.show=true;
        this.$store.dispatch('get','/leffit/cms/sectiontypes')
        .then(data=>{
            console.log(data);
            this.items=data.data.data.filter((d)=> d.type == 'course');
        })
        .catch(error=>{
            this.$store.state.leffit.formErrors.push('Server error!!');
            console.log(error.request);
            this.$store.state.leffit.loader.show=false;
        });
    },
    methods:{
        createItem(e){
            e.preventDefault();
            this.$store.state.formErrors=[];

            if(this.title.trim().length <= 0){
                this.$store.state.formErrors.push('Title field is required.');
                return false;
            }
            this.$store.state.loader=true;

            let payload={name:this.title,type:'course'};
            this.$store.dispatch('post',{endpoint:'/leffit/cms/sectiontypes',details:payload})
            .then(data=>{
                console.log(data);

                this.$store.state.template=data.data.data;
                this.$store.state.loader=false;

                window.$("#exampleModal").modal('hide');
                this.$router.push('/admin/courses/sections/'+data.data.data.id);
            })
            .catch(error=>{
                this.$store.state.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.loader=false;
            });
        },
        deleteN(id){
            let nlt=this.items.find((dt=> dt.id == id));
            nlt.content=JSON.parse(nlt.content);
            if(nlt.content == null){
                nlt.content=[];
            }
            this.$store.state.leffit.items=this.items.filter((dt=> dt.id!=id));
            let images=[];
            nlt.content.forEach(element => {
                console.log(element);
                element.fields.forEach((fd)=>{
                    if(fd.type == 'image'){
                         images.push(fd.content);
                    }
                });
            });
            console.log(images);
            // return false;
            this.$store.dispatch('delete','/leffit/courses/lessons/'+id)
            .then(data=>{
                console.log(data);
                this.$store.state.leffit.loader=false;
            })
            .catch(error=>{
                this.$store.state.leffit.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.leffit.loader=false;
            });
        }
    }
}
</script>

<style scoped>
    #dashboard{
        padding: 40px auto;
        position: absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        height: fit-content;
        width: 100%;
    }
</style>

<style >
    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
        color: gray;
        font-size: 14px;
    }
    .modal .btn-secondary{
        padding: 7px 20px;
        font-size: 13px;
        border-radius: 2px;
        background: #cc4b4b;
        border: none;
    }
    .modal .btn-success{
        padding: 7px 20px;
        font-size: 13px;
        border-radius: 2px;
        background: green;
        border: none;
        margin-right: 10px;
    }
    .container-fluid{
        padding: 0px 100px;
    }
</style>
