<template>
  <div>
    <div class="head row">
      <h3 class="pl-3">{{ $t("leffit.crm.organizations") }}</h3>
      <div class="col-12 p-0 m-0">
        <button
          data-target="#addnew"
          data-toggle="modal"
          class="btn btn-success btn-sm"
        >
          {{ $t("leffit.crm.add_new_organization") }}
        </button>

        <button @click="exportproduct('organization')" class="btn btn-default btn-sm">{{ $t('leffit.crm.export') }}</button>
        <!-- <button @click="trigger()" class="btn btn-default btn-sm">{{ $t('leffit.crm.import') }}</button> -->
        <input
          @change="importProduct('organization')"
          type="file"
          id="file"
          style="display:none"
        />

        <a
          @click="searchItem(1, 0)"
          class="btn btn-sm ml-2"
          style="border:1px solid lightgray"
          >{{ $t("leffit.crm.organizations") }}</a
        >
        <a
          @click="searchItem(1, 2)"
          class="btn btn-sm ml-2"
          style="border:1px solid lightgray"
          >{{ $t("leffit.crm.suppliers") }}</a
        >
        <a
          @click="searchItem(1, 1)"
          class="btn btn-sm ml-2"
          style="border:1px solid lightgray"
          >{{ $t("leffit.crm.customers") }}</a
        >
      </div>
      <div v-if="import_error.length > 0" class="col-12">
        <div class="alert alert-danger">
          <p v-html="import_error.substr(0, 500) + '...'"></p>
        </div>
      </div>
    </div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("leffit.crm.organization.name") }}</th>
          <th>{{ $t("leffit.crm.organization.address") }}</th>
          <th>{{ $t("leffit.crm.organization.address2") }}</th>
          <th>{{ $t("leffit.crm.organization.zipcode") }}</th>
          <th>{{ $t("leffit.crm.organization.city") }}</th>
          <th>{{ $t("leffit.crm.organization.country") }}</th>
          <th>{{ $t("leffit.crm.organization.phone") }}</th>
          <th>{{ $t("leffit.crm.organization.mobile") }}</th>
          <th>{{ $t("leffit.crm.organization.fax") }}</th>
          <th>{{ $t("leffit.crm.organization.email") }}</th>
          <th>{{ $t("leffit.crm.organization.picture") }}</th>
          <th>{{ $t("leffit.crm.organization.coc") }}</th>
          <th>{{ $t("leffit.crm.organization.vat_number") }}</th>
          <th>{{ $t("leffit.crm.organization.iban_name") }}</th>
          <th>{{ $t("leffit.crm.organization.iban_number") }}</th>
          <th>{{ $t("leffit.crm.organization.swift_code") }}</th>
          <th>{{ $t("leffit.crm.organization.language") }}</th>
          <th>{{ $t("leffit.crm.organization.remarks") }}</th>
          <th>{{ $t("leffit.crm.organization.status_id") }}</th>
          <th>{{ $t("leffit.crm.organization.parent_id") }}</th>
          <th>{{ $t("leffit.crm.organization.organizationtype_id") }}</th>
          <th>{{ $t("leffit.crm.organization.created_at") }}</th>
          <th>{{ $t("leffit.crm.organization.updated_at") }}</th>
          <th>{{ $t("leffit.crm.organization.deleted_at") }}</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <a @click="searchItem()" class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th v-for="(attribute, index) in attributes" :key="index">
            <input
              type="text"
              v-model="search.search[attribute.attribute]"
              class="form-control"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in pagedata.data" :key="index">
          <td>
            <router-link
              :to="'/admin/crm/organization/' + item.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              :to="'/admin/crm/organization/' + item.id + '/edit'"
              class="btn btn-warning"
            >
              <i class="fa fa-pencil"></i>
            </router-link>
            <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </a>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address2 }}</td>
          <td>{{ item.zipcode }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.country }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.fax }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.picture }}</td>
          <td>{{ item.coc }}</td>
          <td>{{ item.vat_number }}</td>
          <td>{{ item.iban_name }}</td>
          <td>{{ item.iban_number }}</td>
          <td>{{ item.swift_code }}</td>
          <td>{{ item.language }}</td>
          <td>{{ item.remarks }}</td>
          <td>{{ item.status_id }}</td>
          <td>{{ item.parent_id }}</td>
          <td>{{ item.organizationtype_id }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.updated_at }}</td>
          <td>{{ item.deleted_at }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <pagination
        :limit="2"
        :data="pagedata"
        @pagination-change-page="searchItem"
      ></pagination>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id="addnew">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("leffit.crm.organization.add_new") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit="create($event)">
              <div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_name">{{
                      $t("leffit.crm.organization.name")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_name"
                      v-model="item.name"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_address">{{
                      $t("leffit.crm.organization.address")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_address"
                      v-model="item.address"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_address2">{{
                      $t("leffit.crm.organization.address2")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_address2"
                      v-model="item.address2"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-4">
                    <label for="organization_zipcode">{{
                      $t("leffit.crm.organization.zipcode")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_zipcode"
                      v-model="item.zipcode"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_city">{{
                      $t("leffit.crm.organization.city")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_city"
                      v-model="item.city"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="contact_country">{{
                      $t("leffit.crm.contact.country")
                    }}</label>
                    <select2
                      :options="
                        country.map((c) => {
                          return { id: c.code, text: c.name };
                        })
                      "
                      v-model="item.country"
                      style="position:relative;height:38px"
                      class=" country"
                      id="contact_country"
                    ></select2>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_phone">{{
                      $t("leffit.crm.organization.phone")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_phone"
                      v-model="item.phone"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_mobile">{{
                      $t("leffit.crm.organization.mobile")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_mobile"
                      v-model="item.mobile"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_fax">{{
                      $t("leffit.crm.organization.fax")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_fax"
                      v-model="item.fax"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_email">{{
                      $t("leffit.crm.organization.email")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_email"
                      v-model="item.email"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_coc">{{
                      $t("leffit.crm.organization.coc")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_coc"
                      v-model="item.coc"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_vat_number">{{
                      $t("leffit.crm.organization.vat_number")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_vat_number"
                      v-model="item.vat_number"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-4">
                    <label for="organization_iban_name">{{
                      $t("leffit.crm.organization.iban_name")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_iban_name"
                      v-model="item.iban_name"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_iban_number">{{
                      $t("leffit.crm.organization.iban_number")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_iban_number"
                      v-model="item.iban_number"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_swift_code">{{
                      $t("leffit.crm.organization.swift_code")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_swift_code"
                      v-model="item.swift_code"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="contact_language">{{
                      $t("leffit.crm.contact.language")
                    }}</label>
                    <select2
                      :options="
                        language.map((l) => {
                          return { id: l.code, text: l.name };
                        })
                      "
                      v-model="item.language"
                      style="position:relative;height:38px"
                      class=" language"
                      id="contact_language"
                    ></select2>
                  </div>
                  <div class="form-group col-6 currency">
                    <label for="type">{{
                      $t("leffit.products.product.currency")
                    }}</label>
                    <v-select
                      style="font-size:15px;"
                      v-model="item.currency"
                      :options="
                        currencies.map((d) => {
                          return { label: d.name, id: d.id };
                        })
                      "
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_remarks">{{
                      $t("leffit.crm.organization.remarks")
                    }}</label>
                    <textarea
                      class="form-control WYSIWYG"
                      id="organization_remarks"
                      v-model="item.remarks"
                    ></textarea>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_status_id">{{
                      $t("leffit.crm.organization.status_id")
                    }}</label>
                    <select
                      class="form-control"
                      id="organization_status_id"
                      v-model="item.status_id"
                    >
                      <option value="1">{{
                        $t("leffit.crm.organization.active")
                      }}</option>
                      <option value="0">{{
                        $t("leffit.crm.organization.inactive")
                      }}</option>
                    </select>
                  </div>
                </div>

                <!-- <fieldset v-for="(group,index) in item.extra" :key="index" class="group">
						<legend>{{index}}</legend>
						<div v-for="(field,index2) in group" :key="index2" class="form-group">
							<label for="">{{field.label}}</label>
							<input v-model="field.value" type="text" class="form-control">
						</div>
					</fieldset> -->

                <div class="row p-0 m-0">
                  <button class="btn btn-success">
                    <span v-if="!loading">CREATE</span>
                    <span v-else>SAVING...</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              id="closemodal"
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Close
            </button>
            <button @click="create()" type="button" class="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import axios from "axios";
export default {
  name: "index",
  data() {
    return {
      pagedata: {},
      item: {
        name: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "NL",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        picture: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "NL",
        remarks: "",
        status_id: 1,
        parent_id: "",
        organizationtype_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        currency: "",
      },
      language: language,
      country: country,
      search: { search: {} },
      attributes: [
        { attribute: "name", value: "" },
        { attribute: "address", value: "" },
        { attribute: "address2", value: "" },
        { attribute: "zipcode", value: "" },
        { attribute: "city", value: "" },
        { attribute: "country", value: "" },
        { attribute: "phone", value: "" },
        { attribute: "mobile", value: "" },
        { attribute: "fax", value: "" },
        { attribute: "email", value: "" },
        { attribute: "picture", value: "" },
        { attribute: "coc", value: "" },
        { attribute: "vat_number", value: "" },
        { attribute: "iban_name", value: "" },
        { attribute: "iban_number", value: "" },
        { attribute: "swift_code", value: "" },
        { attribute: "language", value: "" },
        { attribute: "remarks", value: "" },
        { attribute: "status_id", value: "" },
        { attribute: "parent_id", value: "" },
        { attribute: "organizationtype_id", value: "" },
        { attribute: "created_at", value: "" },
        { attribute: "updated_at", value: "" },
        { attribute: "deleted_at", value: "" },
      ],
      import_error: "",
      loading: false,
      currencies: [],
    };
  },

  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
  },
  methods: {
    trigger() {
      document.getElementById("file").click();
    },
    exportproduct(tablename) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/export/?model=" +
            tablename,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          console.log(result)
          const type = result.headers["content-type"];
          const blob = new File([result.data], {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "organizations.xlsx";
          link.click();
          // window.location.href = process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+'&colname='+this.tablefield+'&token='+token;
        })
        .catch((error) => {
          if (error.request.status == 500) {
            this.$store.dispatch("makeNotification", {
              title: "Server error",
              text: "Unable to export server error occured!",
              type: "danger",
            });
          }
        });
    },
    importProduct(filterName) {
      this.import_error = "";
      let formData = new FormData();
      formData.append("file", document.getElementById("file").files[0]);
      let header = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/import/?model=" +
            filterName,
          formData,
          header
        )
        .then((result) => {
          this.textmsg.success = result.data.status;
          let self = this;
          this.$store.dispatch("makeNotification", {
            title: "Successfull",
            text: "Your importation was successfull",
            type: "success",
          });
          setTimeout(function() {
            self.textmsg.success = null;
          }, 5000);
        })
        .catch((error) => {
          this.processImportError(error);
          this.textmsg.error = "Error :(";
          let self = this;
          setTimeout(function() {
            self.textmsg.error = null;
          }, 5000);
        });
    },
    create(e) {
      e.preventDefault();

      this.item.language = document.getElementById("contact_language").value;
      this.item.country = document.getElementById("contact_country").value;
      if (this.item.currency) {
        this.item.currency_id = this.item.currency.id;
      }

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/organizations",
          details: this.item,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM ADDED",
            text: this.item.name + " was added successfully",
            type: "success",
          });
          this.pagedata.unshift(data.data.data);
          document.getElementById("closemodal").click();
        })
        .catch((error) => {
          error;
        });
    },
    deleteItem(id) {
      this.pagedata = this.pagedata.filter((dt) => dt.id != id);
      this.$store
        .dispatch("delete", "/leffit/crm/organizations/" + id)
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: this.item.name + " was removed successfully",
            type: "success",
          });
        });
    },
    enterSearch(field, value) {
      this.search[field] = value;
    },
    searchItem(page = 1, type = 0) {
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/organizations/search?page=" + page,
          details: { ...this.search, type: type },
        })
        .then((data) => {
          this.pagedata = data.data;
        })
        .catch((error) => {
          error;
        });
    },
    processImportError(error) {
      if (error.request.status == 422) {
        var response = JSON.parse(error.request.response);
        var errors = response.errors;
        var row = "";
        var error_text = "";
        for (var err in errors) {
          var title = err.split(".");
          if (title.length > 1) {
            row = "Row " + title[0];
          }
          error_text = errors[err][0].replace(title[0] + ".", "");
          this.import_error += row + " " + error_text + "<br />";
        }
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Your importation was not successfull",
          type: "danger",
        });
      }
    },
  },
  mounted() {
    this.searchItem();

    this.$store
      .dispatch(
        "get",
        "/lefficient/leffit/packages/settings/crm_organization_settings"
      )
      .then((data) => {
        var temp = this.item;
        if (temp.sett) temp.extra = data.data;
        this.item = null;
        this.item = temp;
      })
      .catch((error) => {
        error;
      });

    // Getting currencies
    this.$store
      .dispatch("get", "/lefficient/leffit/currencies")
      .then((data) => {
        this.currencies = data.data.data;
        var default_currency = this.currencies.find((d) => d.code == "EUR");
        setTimeout(() => {
          var temp = this.item;
          temp.currency = {
            label: default_currency.name,
            id: default_currency.id,
          };
          this.item = null;
          this.item = temp;
        }, 500);
      });
  },
};
</script>

<style lang="css">
.currency .vs__selected-options {
  height: 32px;
}
</style>
