<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.stock.warehouses.create') }}</h3>
      	</div>
        <div class="">
           <div > 
				<div class="card" style="border:none"> 

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.products.stock.warehouses.name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name">
                    </div> 
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                    <label for="contact_address">{{ $t('leffit.crm.contact.address') }}</label>
                    <input type="text" class="form-control" id="contact_address" v-model="item.address">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                    <label for="contact_address2">{{ $t('leffit.crm.contact.address2') }}</label>
                    <input type="text" class="form-control" id="contact_address2" v-model="item.address2">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-4">
                    <label for="contact_zipcode">{{ $t('leffit.crm.contact.zipcode') }}</label>
                    <input type="text" class="form-control" id="contact_zipcode" v-model="item.zipcode">
                    </div>
                
                    <div class="form-group col-4">
                    <label for="contact_city">{{ $t('leffit.crm.contact.city') }}</label>
                    <input type="text" class="form-control" id="contact_city" v-model="item.city">
                    </div>
                
                    <div class="form-group col-4">
                        <label for="contact_country">{{ $t('leffit.crm.contact.country') }}</label>
                        <select style="position:relative;width:100%" class="form-control country" id="contact_country" v-model="item.country">
                            <option :key="index" v-for="(ct,index) in country" :value="ct.code" >{{ct.name}}</option>
                        </select>
                    </div> 
                </div>

                <div class="form-row">
                    <div class="form-group col-6">
                    <label for="contact_phone">{{ $t('leffit.crm.contact.phone') }}</label>
                    <input type="text" class="form-control" id="contact_phone" v-model="item.phone">
                    </div>
                
                    <div class="form-group col-6">
                    <label for="contact_mobile">{{ $t('leffit.crm.contact.mobile') }}</label>
                    <input type="text" class="form-control" id="contact_mobile" v-model="item.mobile">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-6">
                    <label for="contact_fax">{{ $t('leffit.crm.contact.fax') }}</label>
                    <input type="text" class="form-control" id="contact_fax" v-model="item.fax">
                    </div>
                
                    <div class="form-group col-6">
                    <label for="contact_email">{{ $t('leffit.crm.contact.email') }}</label>
                    <input type="email" class="form-control" id="contact_email" v-model="item.email">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                    <label for="contact_picture">{{ $t('leffit.crm.contact.picture') }}</label>
                    <input type="text" class="form-control" id="contact_picture" v-model="item.picture">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                    <label for="contact_language">{{ $t('leffit.crm.contact.language') }}</label> 
                    <select style="position:relative;width:100%" class="form-control language" id="contact_language" v-model="item.language">
                        <option :key="index" v-for="(lg,index) in language" :value="lg.code">{{lg.name}}</option>
                    </select>
                    </div>
                </div> 

                <div class="form-row">
                    <div class="form-group col-12">
                    <label for="contact_remarks">{{ $t('leffit.crm.contact.remarks') }}</label>
                    <textarea  class="form-control WYSIWYG" id="organization_remarks" v-model="item.remarks"></textarea>
                    </div>
                </div> 
                
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="contact_organization_id">{{ $t('leffit.crm.contact.organization_id') }}</label>
                        <select style="position:relative;width:100%" class="form-control organizations" id="contact_organizations" v-model="item.organizations">
                            <option :key="index" v-for="(org,index) in organizations" :value="org.id">{{org.name}}</option>
                        </select>
                    </div>
                </div>  
                <button @click="create()" class="btn btn-success">{{ $t('lefficient.system.create') }}</button>
 
				</div>
			</div>
        </div>
    </div>
</template>

<script>
import country from '../../../../data/country';
import language from '../../../../data/language';
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',language:''
            },
            country:country,
            language:language, 
            organizations:[]
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            } 
            this.$store.dispatch('post',{endpoint:'/leffit/products/warehouses',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Warehouse created",text:'Warehouse was created automatically'});
                // this.item={};
                // window.location.href="/admin/stocks/settings/warehouses";
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){
        var context=this;
        window.$('.language').select2().change(function(){
            context.item.language=window.$(this).val();
        });
         window.$('.country').select2().change(function(){
            context.item.country=window.$(this).val();
        }); 

        this.$store.dispatch('get','/leffit/crm/organizations')
        .then(data=>{ 
            this.organizations=data.data.data;
            
             window.$('.organizations').select2().change(function(){
                context.item.organization_id=window.$(this).val();
            });
        });
    }
    
}
</script>
