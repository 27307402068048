<template>
	<div>
		<h2>Export Settings</h2>

        <div class="row m-0 p-0">
            <div class="accordion" id="accordion1" style="width:100%">
                <div v-for="(module,index) in modules" :key="index" class="card mb-2" style="border:none">
                    <div style="background: #8080804f;" class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <a style="color: black;" class="btn btn-link btn1" data-toggle="collapse" :href="'#module'+index" aria-expanded="true" :aria-controls="'#module'+index">
                            {{module.modulename}}
                            
                            <div class="float-right"> 
                                <button @click="save()" style="background:white" class="btn btn-mute">Update</button>
                            </div>
                        </a>
                    </h2>
                    </div> 
                    <div :id="'module'+index" class="collapse" aria-labelledby="headingOne" data-parent="#accordion1">
                        <div class="accordion" id="accordion2">
                            <div v-for="(model,index2) in module.models" :key="index2" class="card" style="border-color:lightgray;border-radius: 0;">
                                <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <a class="btn btn-link btn1" data-toggle="collapse" :href="'#model'+index+'-'+index2" aria-expanded="true" :aria-controls="'#model'+index+'-'+index2">
                                        {{model.name}} 
                                    </a> 
                                    <div class="float-right">
                                        <button @click="exportData(model.export_name)" style="background:white" class="btn btn-mute mr-1">Export  </button>
                                    </div>
                                </h2>
                                </div> 
                                <div :id="'model'+index+'-'+index2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion2">
                                    <!-- <div > -->
                                        <label v-for="(attr,index3) in model.attributes" :key="index3" class="card-body attr p-0 pl-4 pt-2 pb-2 pr-2" :for="attr.name+'attr'+index+''+index2+''+index3">
                                            <input @change="save()" v-model="attr.export" :id="attr.name+'attr'+index+''+index2+''+index3" type="checkbox">
                                            {{attr.name}}
                                        </label>
                                    <!-- </div> -->
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>
        </div>
	</div>
</template>

<script>
import axios from 'axios';
	export default {
		data(){
            return {
                modules:[
                    
                ],
                tablefield:null
            }
        },
        methods:{
            exportData(tablename) {
                this.save();
                console.log(tablename)
                axios.get(process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export?model='+tablename+'&colname='+this.tablefield,
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then(result => {
                    console.log(result);
                    const type = result.headers['content-type']
                    const blob = new File([result.data],{type: 'application/octet-stream'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = tablename+'.xlsx'
                    link.click(); 
                    // var token = JSON.parse(localStorage.getItem('token'));
                    // console.log(process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+'&colname='+this.tablefield+'&token='+token);
                    // window.location.href = process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+'&colname='+this.tablefield+'&token='+token;
                                        
                })
                .catch(error => {
                    console.log(error.request);
                    if(error.request.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                    }
                })
            },
            save(){ 
                this.$store.dispatch('put',{
                    details:{root:JSON.stringify(this.modules)},
                    endpoint:'/lefficient/leffit/packages/settings/export',
                })
                .then((data)=>{
                    console.log(data);
                    this.$store.dispatch('makeNotification',{title:'ITEM UPDATE',text:'Item was updated successfully',type:'success'});
                })
                .catch((error)=>{
                    console.log(error);
                });
            }
        },
        created(){
            this.$store.dispatch('get','/lefficient/leffit/packages/settings/export')
            .then((data)=>{
                var temp=JSON.parse(data.data.setting);
                this.modules=temp;

                // this.modules=this.modules.map((md)=>{
                //     var server_module=temp.find((e)=> e.modulename == md.modulename);
                //     if(server_module) {
                //         md.models=md.models.map((d)=>{
                //             var server_model=server_module.models.find((e)=> e.name == d.name);
                //             if(server_model){
                //                 server_model.attributes=d.attributes.map((atr)=>{ 
                //                     var attr=server_model.attributes.find((sm)=> sm.name == atr.name); 
                //                     if(attr){
                //                         return attr
                //                     }
                //                     return atr;
                //                 })
                //                 return server_model;
                //             }
                //             return d;
                //         });
                //     }

                //     return md;
                // });
                console.log(this.modules);
            })
            .catch((error)=>{
                console.log(error);
            });
        }
	}
</script>

<style scoped>
	.btn1{
        width: 100%;
        text-align: left;
        border: none;
    }
    .attr:hover{
        cursor: pointer;
        background: #d8d8d87a !important;
    }
    .attr{
        width: 100%;
    }
</style>