<template>
	<div>
		<div class="head row p-0">
        	<h3 class="pl-3">{{ $t('leffit.settings.users') }}</h3>
			<div class="col-12 p-0 m-0">
				<router-link to="/admin/settings/users/create" class="btn btn-success btn-sm">Add new</router-link>
			</div>
      	</div>
      	<table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th :key="index" v-for="(attribute,index) in attributes">{{ $t('lefficient.leffit.user.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr :key="index" v-for="(item, index) in pagedata">
      				<td>
      					<router-link :to="'/admin/settings/user/'+item.id" class="btn btn-open">
      						<i class="fa fa-search"></i>
      					</router-link>
      					<router-link :to="'/admin/settings/user/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
						<a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes">{{ item[attribute] }}</td>
      			</tr>
      		</tbody>
      	</table>
	</div>
</template>

<script>

export default {
  	name: 'index',
  	data () {
    	return {
    		pagedata : [],
            attributes : [
				'display_name',
				'firstname',
				'lastname',
				'address',
				'address2',
				'zipcode',
				'city',
				'country',
				'phone',
				'mobile',
				'website',
				'profile_image',
				'date_of_birth',
				'member_since',
				'member_end',
				'about_me',
				'userstatus',
				'settings',
				'email',
				'email_verified_at',
				'password',
				'gender',
            ],
			search : {},
    	}
	},   
	methods:{ 
		deleteItem(id){
			this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
			this.$store.dispatch('delete','/lefficient/leffit/users/'+id)
			.then(data=>{
				console.log(data);
				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:this.item.display_name+' was removed successfully',type:'success'});
				
			});
		}
	},
  	mounted(){
	    this.$store.dispatch('get','/lefficient/leffit/users')
        .then(data=>{
        	console.log(data);
			this.pagedata = data.data.data;
		})
		.catch((error)=>{
			window.console.log(error.request);
		});
	},
}
</script>

<style lang="css" scoped>
</style>