<template>
  <div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("leffit.crm.messagess.title") }}</th>
          <th>{{ $t("leffit.crm.messagess.orgnization") }}</th>
          <th>{{ $t("leffit.crm.messagess.user") }}</th>
          <th>{{ $t("leffit.crm.messagess.status") }}</th>
          <th></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th></th>
          <th>
            <input type="text" v-model="search.title" class="form-control" />
          </th>
          <th>
            <input
              type="text"
              v-model="search.organization"
              class="form-control"
            />
          </th>
          <th>
            <input type="text" v-model="search.user" class="form-control" />
          </th>
          <th>
            <select
              v-model="search.message_status_id"
              class="form-control"
              id=""
            >
              <option
                v-for="(status, index) in statuses"
                :key="index"
                :value="status.id"
                >{{ status.name }}</option
              >
            </select>
          </th>
          <th>
            <button @click="getMessages" class="btn btn-success btn-sm">
              <i class="fas fa-search"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="messages.data && messages.data.length > 0">
        <tr v-for="(message, index) in messages.data" :key="index">
          <td>
            <router-link
              :to="'/admin/crm/message/' + message.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
          </td>
          <td>{{ message.title }}</td>
          <td>{{ message.organization_name }}</td>
          <td v-if="message.user_display_name != null">
            {{ message.user_display_name }}
          </td>
          <td v-else class="text-center">---</td>
          <td class="text-center">
            <span v-if="message.status_name != null">{{
              message.status_name
            }}</span>
            <span v-else>---</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <pagination
        :limit="2"
        :data="this.messages"
        @pagination-change-page="getMessages"
      ></pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "messagesTable",
  data() {
    return {
      messages: {},
      statuses: [],
      search: { title: "", organization: "", user: "", message_status_id: "" },
    };
  },
  methods: {
    getMessages(page = 1) {
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/message/search?page=" + page,
          details: { search: this.search },
        })
        .then((data) => {
          this.messages = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatuses() {
      this.$store
        .dispatch("get", "/leffit/crm/messagestatus")
        .then((data) => {
          this.statuses = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getStatuses();
    this.getMessages();
  },
};
</script>
