<template>
    <div>
        <table class="table table-responsive table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th v-for="(attribute,index) in attributes" :key="index">{{ $t('leffit.orders.order.'+attribute) }}</th>
                </tr>
            </thead> 
            <tbody>
                <tr v-for="(item, index) in pagedata.data" :key="index">
                    <td v-if="!hideIcons">
                        <router-link :to="order_url+'/create/'+item.id+''" class="btn btn-open">
                            <i class="fa fa-search"></i>
                        </router-link> 
                        <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                    <td v-else-if="showSalesOpen">
                        <a :href="'/admin/sales/orders/sales_order/create/'+item.id" class="btn btn-open">
                            <i class="fa fa-search"></i>
                        </a>
                    </td>
                    <td v-for="(attribute,index) in attributes" :key="index">
                        {{ $t(item[attribute]) }}
                    </td>
                </tr>
            </tbody>
        </table>
    
    </div>
</template>

<script>
export default {
    name:'order-table',
    props:['pagedata','order_url','ordertype_id','hideIcons','showSalesOpen'],
    data(){
        return {
            attributes : [
                'organization_name',
                'firstname',
                'lastname',
                'companyname',
                'address',
                'zipcode',
                'city',
                'country',
                'email',
                'phone',
                'orderstatus',  
                'order_date',  
            ],
            search : {},
        }
    }, 
    methods:{
        deleteItem(id){ 
            this.pagedata.data=this.pagedata.data.filter((dt)=> dt.id != id);
      			this.$store.dispatch('delete','/leffit/orders/orders/'+id)
      			.then(data=>{
      				console.log(data);
      				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Order was removed successfully',type:'success'});
      			});
        }
    },
    created(){
        // this.$store.dispatch('get','/leffit/orders/order-types/'+this.ordertype_id)
        // .then(data=>{
        //     console.log(data);
        //     var temp=data.data.data;
        //     temp=JSON.parse(temp.settings);
        //     this.attributes=temp.index;
        // });
    }
}
</script>