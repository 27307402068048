<template>
  <div>
    <div class="head row">
      <h3 class="pl-3">{{ $t("leffit.crm.messages") }}</h3>
    </div>
    <messages-table></messages-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import messagesTable from "./messages-table";
import axios from "axios";
export default {
  name: "index",
  components: { "messages-table": messagesTable },
};
</script>

<style lang="css" scoped></style>
