<template>
    <div style="position:relative;height:100vh"> 
        <div class="form-group">
            <label class="col-12 p-0" for="">Field Type</label>
            <select style="width:100%" class="form-control col-12 select">
                <option value="">Select field type</option>
                <option v-for="(fd,index) in fieldtypes" :key="index" :value="fd.id">{{fd.name}}</option>
            </select> 
        </div>
        <div class="form-group">
            <label for="">Name</label>
            <input v-model="item.name" type="text" class="form-control">
        </div>

        <div  class="row row-field">
            <div class="col-12 fields" style="display: contents;">
                <div v-for="(field,index) in data.fields" :key="index" :class="['text','textarea','WYSIWYG'].find((fd)=>fd == field.type) ? 'col-12':'col-md-3'">
                    <div v-if="field.type == 'text'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label>
                        <input @keyup="bindValuesToContent()" type="text" class="form-control" v-model="field.content">
                    </div>
                    <div v-if="field.type == 'textarea'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label>
                        <textarea @keyup="bindValuesToContent()" class="form-control" v-model="field.content"></textarea>
                    </div>
                    <div v-if="field.type == 'WYSIWYG'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label>
                        <Editor :change="bindValuesToContent()" v-model="field.content"></Editor>
                    </div>
                    <div v-if="field.type == 'image'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">Upload image</label>
                        <input type="file" accept="image/*" @change="uploadImage($event,field.element)">
                    </div>
                    <div v-if="field.type == 'number'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}} (PX)</label>
                        <input @keyup="bindValuesToContent()" type="number" placeholder="25px" class="form-control" v-model="field.content">
                    </div>
                    <div v-if="field.type == 'font-color'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label>
                        <input @change="bindValuesToContent()" type="color" class="form-control" v-model="field.content">
                    </div>
                    <div v-if="field.type == 'font-family'" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label> 
                        <font-picker v-model="field.content"  :api-key="'AIzaSyBSqhPLj1EhSlhfNHWQKJjzOFmGXqSC0Cs'" :options="{}" :active-font="field.content" @change="changeFont($event,field.element)"></font-picker>
                    </div>
                    <div v-if="field.type == 'select' && field.style_name" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label> 
                        <select class="form-control" @change="bindValuesToContent()" v-model="field.content" id="">
                            <option v-for="(option,index2) in options[field.style_name]" :key="index2" :value="option.value">{{option.text}}</option>
                        </select>
                    </div>
                    <div v-if="field.type == 'select' && field.class" class="form-group m-0 pl-0 pr-0 col-12 ">
                        <label for="">{{field.label}}</label> 
                        <select class="form-control" @change="bindValuesToContent()" v-model="field.content" id="">
                            <option v-for="(option,index2) in options[field.class]" :key="index2" :value="option.value">{{option.text}}</option>
                        </select>
                    </div>
                </div> 
            </div>
            
            <div class="col-12 doc" v-html="data.content"></div>
        </div>

        <button @click="update()" class="btn btn-success">{{ $t('leffit.website.update') }}</button>
    </div>
</template>
<script>
import FontPicker from 'font-picker-vue';
export default {
    components:{'font-picker':FontPicker},
    data(){
        return {
            item:{
                name:'',fieldtype_id:'',fieldtype:{}
            },
            fieldtypes:[],
            data:{
                id:1,
                title:'',
                description:'',
                content:'',
                blueprint:'',
                styles:'',
                mode:'initial',
                fields:[]
            },
            options:{
                'text-align':[{text:'left',value:'left'},{text:'center',value:'center'},{text:'right',value:'right'}],
                'margin-align':[{text:'left',value:'mr-auto'},{text:'center',value:'m-auto'},{text:'right',value:'ml-auto'}],
            }
        }
    }, 
    methods:{
        getDOMcontent(type,fields){ 
            var html='';
            console.log(type);
            if(type == 'text'){
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'text').element+`}}</p>`;
            }else if(type == 'textarea'){
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'textarea').element+`}}</p>`;
            }
            else if(type == 'WYSIWYG'){
                html=`<p style="++{{styles}}" class="++{{classes}}">++{{`+fields.find((field)=> field.type == 'WYSIWYG').element+`}}</p>`;
            }
            else if(type == 'image'){
                html=`<img
                        src="++{{`+fields.find((field)=> field.type == 'image').element+`}}"
                        alt="++{{`+fields.find((field)=> field.label == 'ALT').element+`}}"
                        title="++{{`+fields.find((field)=> field.label == 'TITLE').element+`}}"
                        style="++{{styles}}" class="++{{classes}}" />`;
            }
            else if(type == 'video'){
                html=`<div style="++{{styles}}" class="++{{classes}}" >++{{`+fields.find((field)=> field.type == 'text').element+`}}</div>`;
            }
            else if(type == 'button'){
                html=`<a  
                        href="++{{`+fields.find((field)=> field.label == 'URL').element+`}}" 
                        style="++{{styles}}" 
                        class="btn d-block ++{{classes}}"
                        >++{{`+fields.find((field)=> field.label == 'BUTTON TEXT').element+`}}</a>`;
            }
            return html;
        },
        buildFieldContent(){
            var content="";
            var id=Math.floor(Math.random() * 999999999);
            var type=this.item.fieldtype.name;

            var data=this.data;
            var fieldname='field'+id;
            var fieldnames=[];
            var fields=[];
            
 
            fields=this.getAllFields(this.item.fieldtype.name); 
            fields.forEach(element => {
                fieldname='field'+Math.floor(Math.random() * 999999999);
                data.fields.push({
                    element:fieldname,
                    type:element.type,
                    label:element.label ? element.label.replace('-',' ').toUpperCase() : element.title.replace('-',' ').toUpperCase(),
                    style_name:element.style,
                    class:element.class,
                    content:element.value ? element.value:'',
                });
                });

            data.styles=this.buildStyles(data);
            data.classes=this.buildClasses(data);

            data.blueprint=this.getDOMcontent(type,data.fields);
            data.content=data.blueprint; 
            this.bindValuesToContent(data);
        }, 
        bindValuesToContent(data){
            if(!data){
                data=this.data;
            } 
            var fields=data.fields;
            var content=data.blueprint;
            data.styles=this.buildStyles(data);
            data.classes=this.buildClasses(data);

            fields.map((field)=>{
                content=content.replace('++{{'+field.element+'}}',field.content);
            });
            content=content.replace("++{{styles}}",data.styles);
            content=content.replace("++{{classes}}",data.classes);

            this.data.content=content;
            console.log(this.data);
        },
        buildStyles(data){
            var styles=''; 
            var style_fields=data.fields.filter((field)=> field.style_name != undefined);
            style_fields.forEach(element => {
                if(element.type == 'number'){
                    styles+=element.style_name+':'+element.content+'px !important;';
                }else{
                    styles+=element.style_name+':'+element.content+';';
                } 
            });
            return styles;
        },
        buildClasses(data){ 
            var classes='';
            var class_fields=data.fields.filter((field)=> field.class != undefined);
            class_fields.forEach(element => { 
                classes+=element.content+' ';
            });
            return classes;
        },
        getAllFields(type){
            //Fields with '-'(dash) are css styles fields 
            //Fields with no '-' are values
            switch(type){
                case 'text':
                    return [
                        {type:'text',label:'text content'},
                        {style:'font-size',type:'number',label:'font-size'},
                        {style:'color',type:'font-color',label:'font-color'},
                        {style:'font-family',type:'font-family',label:'font-family'},
                        {style:'text-align',type:'select',label:'Text align'},
                    ];
                case 'textarea':
                    return [
                        {type:'textarea',label:'Text content'},
                        {style:'font-size',type:'number',label:'font-size'},
                        {style:'color',type:'font-color',label:'Color'},
                    ];
                case 'WYSIWYG':
                    return [
                        {type:'WYSIWYG','label':'text'},
                    ];
                case 'image':
                    return [
                        {type:'image',label:'image'},
                        {type:'text',label:'alt'},
                        {type:'text',label:'title'},
                        {style:'width',type:'number',label:'width'},
                        {style:'height',type:'number',label:'height'},
                    ];
                case 'video':
                    return [
                        {type:'text',label:'Embed iframe input'}, 
                        {style:'width',type:'number',label:'width'},
                        {style:'height',type:'number',label:'height'},
                    ];
                case 'button':
                    return [
                        {type:'text',label:'button-text',value:''},
                        {type:'text',label:'url',value:''},
                        {style:'font-size',type:'number',label:'font-size',value:''},
                        {style:'color',type:'font-color',label:'font-color',value:''},
                        {style:'font-family',type:'font-family',label:'font-family',value:''},
                        {class:'margin-align',type:'select',label:'Button alignment',value:''},
                        {style:'background-color',type:'font-color',label:'background-color',value:''},
                        {style:'width',type:'number',label:'width',value:'100'},
                        {style:'height',type:'number',label:'height',value:''},
                    ];
                default:
                    return []
            }
        },
        changeFont(d,el){  
            this.updateField(el,d.family);
        },
        uploadImage(e,el){
            var file=e.target; 
            if(file !=null && file.files.length <= 0){
                return false;
            }            
            let fileReader=new FileReader(); 
            fileReader.onload=(result)=>{ 
                this.uploadMediaImages(result.srcElement.result,el);
            } 
            fileReader.readAsDataURL(file.files[0]);
        }, 
        updateField(el,value){
            this.data.fields=this.data.fields.map((d)=>{
                if(d.element == el){
                    d.content=value;
                }
                return d;
            });
            this.bindValuesToContent();
        },
        uploadMediaImages(data,el){ 
            console.log(el);
            this.$store.dispatch('post',{
                details:{image:data},
                endpoint:'/leffit/cms/add-media/'+this.$route.params.id
            })
            .then((data)=>{
                console.log(data); 
                this.updateField(el,data.data); 
            })
            .catch((error)=>{
                console.log(error.request); 
            });
        },
        update(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            this.item.settings=JSON.stringify(this.data);
            this.$store.dispatch('put',{endpoint:'/leffit/cms/fields/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Field Update",text:'Field was saved successfully'});
			}).catch((error)=>{
				console.log(error);
			});
        },

    },
    created(){
        this.$store.dispatch('get','/leffit/cms/fields/'+this.$route.params.id)
        .then(data=>{
            console.log(data);
            this.item = data.data.data; 
            this.data.id=Math.ceil(Math.random() * 999999999);
            this.data.title=this.item.name;
            
            var temp=JSON.parse(this.item.settings);
            if(this.item.settings == null || temp.length == 0){
                this.buildFieldContent();
            }else{
                this.data=temp;
                this.bindValuesToContent(this.data);
            }
            console.log(this.data);
        });

        this.$store.dispatch('get','/leffit/cms/fieldtypes')
        .then(data=>{
            // console.log(data);
            this.fieldtypes = data.data.data;
            
            var context=this;
            setTimeout(()=>{
                window.$('.select').val(this.item.fieldtype_id).select2().change(function(){
                    context.item.fieldtype_id=window.$(this).val();
                });
            },500);
        });
    },
}
</script>

<style scoped> 
    .row-field{
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        background: #f3f3f3;
    }
    .doc{
        padding: 10px 0px;
        background: white;
        /* text-align: center; */
    }
    .sectionBody{
        height: 100px;
        background: #8dabb9;
    }
    .sectionBody p{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
    .sect{
        width: 33%;
        height: 200px;
        display: inline-block;
        text-align: center;
        padding: 10px;
    }
    .hold{
        background: lightblue;
        height: 100%;
    }
    .hold p{
            overflow-wrap: break-word;
    }
    .hold:hover{
        cursor: pointer;
    }
</style>

<style scoped> 
        .fields{
            background: #f3f3f3;
        }
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        }
        .templateHolder{ 
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            margin: auto;
            margin-top: 80px;
            width: 670px;
            border: 1px solid lightgray;
            padding: 10px;
            height: 90%;
            overflow-y: scroll;
        }
        .editBoard{
            position: absolute;
            top:0%;
            bottom: 5%;
            right: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            width: 500px; 
            padding: 10px;
            height: 94%;
            overflow-y: scroll;
            background: #f2f2f2;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        } 
         
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            position: absolute;
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative;  
        }
        .temp:hover{
            cursor: pointer; 
        }
        .temp:hover .sectionTools{
            display: block;
        }
        .temp.editting{
            border: 2px dashed red;
        }
        .temp:hover .sectionTools button{
            background: black;
        }
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        }
        .uploadImage:hover{
            transform: scale(0.95);
        }
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       
</style>
<style scoped>
    div img{
        width:100% !important;
    }
</style>