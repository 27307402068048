<template>
	<div>
		<div class="head row p-0 m-0">
            <h4 v-show="isDisabled" ><router-link :to="this.$route.fullPath.replace('/'+$route.params.id,'')" class="btn btn-secondary"><i class="fa fa-arrow-left"></i> Back</router-link></h4>
        	<h4 v-show="isDisabled" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> EDIT</router-link></h4>
			<!-- <h4 v-show="isDisabled" ><a @click="generatePassword()" href="#generatePassword" class="btn btn-primary"><i class="fa fa-edit"></i> GENERATE PASSWORD</a></h4> -->
      	</div>

		<div v-show="isDisabled" class="row">
            <div class="col-sm-12 col-md-6">
				<div class="card basicDetailsCard">
					<h4>BASIC DETAILS</h4>
					<div id="">
						<div class="bsc">
							<b>Name</b>
							<h6>{{item.name}}</h6>
						</div> 
						<div class="bsc">
							<b>Address</b>
							<h6>{{item.address}}</h6>
						</div>
						<div class="bsc">
							<b>Address2</b>
							<h6>{{item.address2}}</h6>
						</div>
						<div class="bsc">
							<b>Zipcode</b>
							<h6>{{item.zipcode}}</h6>
						</div>
						<div class="bsc">
							<b>City</b>
							<h6>{{item.city}}</h6>
						</div>
						<div class="bsc">
							<b>Country</b>
							<h6>{{item.country}}</h6>
						</div>
						<div class="bsc">
							<b>Phone</b>
							<h6>{{item.phone}}</h6>
						</div>
						<div class="bsc">
							<b>Mobile</b>
							<h6>{{item.mobile}}</h6>
						</div>
						<div class="bsc">
							<b>Fax</b>
							<h6>{{item.fax}}</h6>
						</div>
						<div class="bsc">
							<b>email</b>
							<h6>{{item.email}}</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6"> 
				<div class="card basicDetailsCard">
					<h4>Other Details</h4>
					<div id=""> 
						<div class="bsc bsc2">
							<b>Language:</b>
							<h6>{{item.language}}</h6>
						</div> 
					</div>
				</div> 
			</div> 
			<div class="col-12">
				<div class="card basicDetailsCard">
					<h4>Remarks</h4>
					<div id="">
						<div class="bsc"> 
							<h6 style="text-transform:none">{{item.remarks}}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form  v-show="!isDisabled" @submit="updateItem($event)">
			<div> 
				<div class="card">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="title">{{ $t('leffit.products.stock.warehouses.name') }}</label>
                            <input type="text" class="form-control" id="title" v-model="item.name">
                        </div> 
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                        <label for="contact_address">{{ $t('leffit.crm.contact.address') }}</label>
                        <input type="text" class="form-control" id="contact_address" v-model="item.address">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                        <label for="contact_address2">{{ $t('leffit.crm.contact.address2') }}</label>
                        <input type="text" class="form-control" id="contact_address2" v-model="item.address2">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                        <label for="contact_zipcode">{{ $t('leffit.crm.contact.zipcode') }}</label>
                        <input type="text" class="form-control" id="contact_zipcode" v-model="item.zipcode">
                        </div>
                    
                        <div class="form-group col-4">
                        <label for="contact_city">{{ $t('leffit.crm.contact.city') }}</label>
                        <input type="text" class="form-control" id="contact_city" v-model="item.city">
                        </div>
                    
                        <div class="form-group col-4">
                        <label for="contact_country">{{ $t('leffit.crm.contact.country') }}</label>
                        <select style="position:relative;width:100%" class="form-control country" id="contact_country" v-model="item.country">
                            <option :key="index" v-for="(ct,index) in country" :value="ct.code" >{{ct.name}}</option>
                        </select>
                        </div> 
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6">
                        <label for="contact_phone">{{ $t('leffit.crm.contact.phone') }}</label>
                        <input type="text" class="form-control" id="contact_phone" v-model="item.phone">
                        </div>
                    
                        <div class="form-group col-6">
                        <label for="contact_mobile">{{ $t('leffit.crm.contact.mobile') }}</label>
                        <input type="text" class="form-control" id="contact_mobile" v-model="item.mobile">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-6">
                        <label for="contact_fax">{{ $t('leffit.crm.contact.fax') }}</label>
                        <input type="text" class="form-control" id="contact_fax" v-model="item.fax">
                        </div>
                    
                        <div class="form-group col-6">
                        <label for="contact_email">{{ $t('leffit.crm.contact.email') }}</label>
                        <input type="email" class="form-control" id="contact_email" v-model="item.email">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                        <label for="contact_picture">{{ $t('leffit.crm.contact.picture') }}</label>
                        <input type="text" class="form-control" id="contact_picture" v-model="item.picture">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                        <label for="contact_language">{{ $t('leffit.crm.contact.language') }}</label> 
                        <select style="position:relative;width:100%" class="form-control language" id="contact_language" v-model="item.language">
                            <option :key="index" v-for="(lg,index) in language" :value="lg.code">{{lg.name}}</option>
                        </select>
                        </div>
                    </div> 

                    <div class="form-row">
                        <div class="form-group col-12">
                        <label for="contact_remarks">{{ $t('leffit.crm.contact.remarks') }}</label>
                        <textarea  class="form-control WYSIWYG" id="organization_remarks" v-model="item.remarks"></textarea>
                        </div>
                    </div> 
                    
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="contact_organization_id">{{ $t('leffit.crm.contact.organization_id') }}</label>
                            <select style="position:relative;width:100%" class="form-control organization" id="contact_organizations" v-model="item.organizations">
                                <option :key="index" v-for="(org,index) in organizations" :value="org.id">{{org.name}}</option>
                            </select>
                        </div>
                    </div>  
				</div>
			</div>	 
			<div class="row p-0 m-0">
				<button class="btn btn-success">UPDATE</button>
			</div>
		</form>

	</div>
	
	
</template>

<script>
import { mapState } from 'vuex';
import language from '../../../../data/language';
import country from '../../../../data/country';
export default { 
  	name: 'item',
  	data () {
	    return {
	    	item : {
	    		'name' 		: '', 
				'address' 		: '',
				'address2' 		: '',
				'zipcode' 		: '',
				'city' 			: '',
				'country' 		: '',
				'phone' 		: '',
				'mobile' 		: '',
				'fax' 			: '',
				'email' 		: '',
				'picture' 		: '',
				'language' 		: '',
				'remarks' 		: '',
				'created_at' 	: '',
				'updated_at' 	: '',
				'deleted_at' 	: '',
			},
			language:language,
			country:country,
            profileImageSet:false,
            organizations:[]
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
  		isDisabled : function(){
  			return this.$route.path.search('edit') == -1
  		}
	},
	created(){   
	},  
	methods:{  
		updateItem(e){
			e.preventDefault();   
 
			this.item.language=document.getElementById('contact_language').value;
            this.item.country=document.getElementById('contact_country').value; 
            this.item.organization_id=document.getElementById('contact_organizations').value; 


			this.$store.dispatch('put',{endpoint:'/leffit/products/warehouses/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data.data); 
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
                window.location.href=this.$route.fullPath.replace('/edit','');
			}).catch((error)=>{
				console.log(error);
			});
		}, 
	},
  	mounted(){
          console.log(this.$route);
        this.id=this.$route.params.id;
	    this.$store.dispatch('get','/leffit/products/warehouses/'+this.id)
        .then(data=>{
        	console.log('result',data.data.data);
			this.item = data.data.data;
            this.item.optin= this.item.optin == null ? 0: this.item.optin; 
            
			window.$('.language').val(this.item.language).select2();
			window.$('.country').val(this.item.country).select2();
        }); 
        this.$store.dispatch('get','/leffit/crm/organizations')
        .then(data=>{ 
            this.organizations=data.data.data; 
            setTimeout(()=>{
                window.$('.organization').val(this.item.organization_id).select2();
            },200);
        });
		
	}
}
</script>

<style lang="css" scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>