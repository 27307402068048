<template>
  <div>
    <div class="head row" style="justify-content: space-between">
      <h4 v-show="isDisabled" class="d-flex">
        <router-link
          :to="this.$route.fullPath + '/edit'"
          class="btn btn-secondary"
          ><i class="fa fa-edit"></i>
          {{ $t("lefficient.system.edit") }}</router-link
        >
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-print"></i> {{ $t("leffit.products.product.ean") }}
          </button>
          <div
            v-if="item.ean"
            class="dropdown-menu"
            aria-labelledby="dropdownMenu2"
          >
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.barcode_1/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Barcode 1
            </a>
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.barcode_2/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Barcode 2
            </a>
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.barcode_3/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Barcode 3
            </a>
          </div>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-print"></i>
            {{ $t("leffit.products.product.label") }}
          </button>
          <div
            v-if="item.ean"
            class="dropdown-menu"
            aria-labelledby="dropdownMenu2"
          >
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.product_packing_1/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Packing 1
            </a>
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.product_packing_2/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Packing 2
            </a>
            <a
              :href="
                url +
                  '/lefficient/leffit/pdf/products.product_packing_3/' +
                  item.id +
                  '/nl'
              "
              target="_blank"
              class="dropdown-item"
              type="button"
            >
              Packing 3
            </a>
          </div>
        </div>
      </h4>

      <!-- <div class="">
				<p>Sold <span class="text-success">{{item.amount_sold_out}}</span> times</p>
			</div> -->
    </div>
    <div v-show="isDisabled" class="row">
      <div class="col-sm-12 col-md-6 p-0">
        <div class="card basicDetailsCard">
          <div
            class="row"
            style="justify-content: space-between; padding: 0px 15px"
          >
            <h4>{{ $t("leffit.products.product.basic_details") }}</h4>
            <div>
              <button
                v-if="editting.basic"
                @click="
                  editting.basic = false;
                  updateItem($event);
                "
                style="margin: 0px; height: 35px; margin-top: -5px"
                class="btn ml-2 btn-success float-right"
              >
                <i class="fa fa-check"></i>
              </button>
              <button
                v-if="editting.basic"
                @click="editting.basic = false"
                style="margin: 0px; height: 35px; margin-top: -5px"
                class="btn btn-danger float-right"
              >
                <i class="fa fa-remove"></i>
              </button>
            </div>
            <button
              v-if="!editting.basic"
              @click="editting.basic = true"
              style="margin: 0px; height: 35px; margin-top: -5px"
              class="btn float-right"
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.name") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.name"
                  class="form-control edit"
                  :disabled="!editting.basic"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.ean") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.ean"
                  class="form-control edit"
                  :disabled="!editting.basic"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.casa_productcode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.casa_productcode"
                  class="form-control edit"
                  :disabled="!editting.basic"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.casa_seizoenscode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.casa_seizoenscode"
                  class="form-control edit"
                  :disabled="!editting.basic"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.virgocode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.virgocode"
                  class="form-control edit"
                  :disabled="!editting.basic"
                  id=""
                />
              </h6>
            </div>
            <!-- <div class="bsc bsc2">
								<b>{{ $t('leffit.products.product.description') }}:</b>
								<h6><input type="text" v-model="item.description" class="form-control edit" :disabled="!editting.basic" id=""></h6>
							</div> -->
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.product_type") }}:</b>
              <h6>
                <select
                  v-model="item.producttype_id"
                  class="form-control edit select3"
                  :disabled="!editting.basic"
                  name=""
                  id="producttypeSelect"
                >
                  <option
                    v-for="(type, index) in producttypes"
                    :key="index"
                    :value="type.id"
                    v-html="translate(type.name)"
                  ></option>
                </select>
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.product_status") }}:</b>
              <h6>
                <select
                  v-model="item.productstatus_id"
                  class="form-control edit select4"
                  :disabled="!editting.basic"
                  name=""
                  id="productstatusSelect"
                >
                  <option
                    v-for="(type, index) in productstatuses"
                    :key="index"
                    :value="type.id"
                    v-html="translate(type.name)"
                  ></option>
                </select>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card basicDetailsCard">
          <div
            class="row"
            style="justify-content: space-between; padding: 0px 15px"
          >
            <h4>{{ $t("leffit.products.product.other_details") }}</h4>
            <div>
              <button
                v-if="editting.other"
                @click="
                  editting.other = false;
                  updateItem($event);
                "
                style="margin: 0px; height: 35px; margin-top: -5px"
                class="btn ml-2 btn-success float-right"
              >
                <i class="fa fa-check"></i>
              </button>
              <button
                v-if="editting.other"
                @click="editting.other = false"
                style="margin: 0px; height: 35px; margin-top: -5px"
                class="btn btn-danger float-right"
              >
                <i class="fa fa-remove"></i>
              </button>
            </div>
            <button
              v-if="!editting.other"
              @click="editting.other = true"
              style="margin: 0px; height: 35px; margin-top: -5px"
              class="btn float-right"
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.price_from") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.price_from"
                  :disabled="!editting.other"
                  class="form-control edit"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.price") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.price"
                  :disabled="!editting.other"
                  class="form-control edit"
                  id=""
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.vat") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.vat"
                  :disabled="!editting.other"
                  class="form-control edit"
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.stock") }}</b>
              <h6>
                <input
                  type="number"
                  :value="item.stock ? item.stock : 0"
                  :disabled="true"
                  class="form-control edit"
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.weight") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.weight"
                  :disabled="!editting.other"
                  class="form-control edit"
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.silver_weight") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.silver_weight"
                  :disabled="!editting.other"
                  class="form-control edit"
                />
              </h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product.ring_size") }}</b>
              <h6>
                <input
                  type="number"
                  v-model="item.ring_size"
                  :disabled="!editting.other"
                  class="form-control edit"
                />
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form @submit="updateItem($event)">
      <div v-show="!isDisabled">
        <div class="card">
          <div class="form-row">
            <div class="form-group col-6">
              <label for="contact_title">{{
                $t("leffit.products.product.name")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_title"
                v-model="item.name"
              />
            </div>

            <div class="form-group col-6">
              <label for="contact_firstname">{{
                $t("leffit.products.product.ean")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_firstname"
                v-model="item.ean"
              />
            </div>

            <div class="form-group col-6">
              <b>{{ $t("leffit.products.product.casa_productcode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.casa_productcode"
                  class="form-control"
                />
              </h6>
            </div>
            <div class="form-group col-6">
              <b>{{ $t("leffit.products.product.casa_seizoenscode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.casa_seizoenscode"
                  class="form-control"
                />
              </h6>
            </div>
            <div class="form-group col-6">
              <b>{{ $t("leffit.products.product.virgocode") }}:</b>
              <h6>
                <input
                  type="text"
                  v-model="item.virgocode"
                  class="form-control"
                  id=""
                />
              </h6>
            </div>

            <!-- <div class="form-group col-6">
                            <label for="contact_prelastname">{{ $t('leffit.products.product.description') }}</label>
                            <input type="text" :disabled="isDisabled" class="form-control" id="contact_prelastname" v-model="item.description">
                        </div> -->

            <div class="form-group col-6">
              <label for="type">{{
                $t("leffit.products.product.product_type")
              }}</label>
              <select
                v-model="item.producttype_id"
                style="position: relative"
                class="form-control select1"
                id="type"
                :disabled="isDisabled"
              >
                <option
                  :key="index"
                  v-for="(ct, index) in producttypes"
                  :value="ct.id"
                >
                  {{ $t(ct.name) }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="status">{{
                $t("leffit.products.product.product_status")
              }}</label>
              <select
                v-model="item.productstatus_id"
                style="position: relative"
                class="form-control select"
                id="status"
                :disabled="isDisabled"
              >
                <option
                  :key="index"
                  v-for="(st, index) in productstatuses"
                  :value="st.id"
                >
                  {{ $t(st.name) }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label for="contact_address2">{{
                $t("leffit.products.product.price")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_address2"
                v-model="item.price"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-4">
              <label for="contact_zipcode">{{
                $t("leffit.products.product.vat")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_zipcode"
                v-model="item.vat"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_city">{{
                $t("leffit.products.product.stock")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_city"
                v-model="item.stock"
              />
            </div>

            <div class="form-group col-4">
              <label for="contact_country">{{
                $t("leffit.products.product.weight")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="contact_country"
                v-model="item.weight"
              />
            </div>
          </div>
          <div class="row p-0 m-0">
            <button class="btn btn-success">
              {{ $t("lefficient.system.update") }}
            </button>
          </div>
        </div>
      </div>
    </form>

    <div v-if="setting_ready && group_tabs.length > 0" class="card mt-2">
      <tabsMeta
        :item="item"
        :tabs="group_tabs"
        :options="options"
        :package_name="'product_settings'"
      ></tabsMeta>
    </div>
    <div class="row" v-if="setting_ready">
      <ul
        class="nav nav-pills nav-fill"
        style="border: 1px solid lightgray; padding: 6px; width: 99%"
      >
        <li class="nav-item">
          <a
            :class="tabsController.description ? 'nav-link active' : 'nav-link'"
            href="#description"
            @click="toggleTab('description')"
            >{{ $t("leffit.products.product.description") }}</a
          >
        </li>
        <!-- <li class="nav-item">
					<a :class="tabsController.definition ? 'nav-link active':'nav-link'" href="#definition" @click="toggleTab('definition')">{{ $t('leffit.products.product.definition') }}</a>
				</li> -->
        <li v-if="settings.stock" class="nav-item">
          <a
            :class="tabsController.stock ? 'nav-link active' : 'nav-link'"
            href="#stock"
            @click="toggleTab('stock')"
            >{{ $t("leffit.products.product.stock") }}</a
          >
        </li>
        <li v-if="settings.filters" class="nav-item">
          <a
            :class="tabsController.filters ? 'nav-link active' : 'nav-link'"
            href="#filters"
            @click="toggleTab('filters')"
            >{{ $t("leffit.products.product.filters") }}</a
          >
        </li>
        <li v-if="settings.images" class="nav-item">
          <a
            :class="tabsController.images ? 'nav-link active' : 'nav-link'"
            href="#images"
            @click="toggleTab('images')"
            >{{ $t("leffit.products.product.images") }}</a
          >
        </li>
        <li v-if="settings.purchase_pricelevels" class="nav-item">
          <a
            :class="
              tabsController.purchase_pricelevels
                ? 'nav-link active'
                : 'nav-link'
            "
            href="#purchase_price"
            @click="toggleTab('purchase_pricelevels')"
            >{{ $t("leffit.products.product.purchase_pricelevels") }}</a
          >
        </li>
        <li v-if="settings.sales_pricelevels" class="nav-item">
          <a
            :class="
              tabsController.sales_pricelevels ? 'nav-link active' : 'nav-link'
            "
            href="#purchase_price"
            @click="toggleTab('sales_pricelevels')"
            >{{ $t("leffit.products.product.sales_pricelevels") }}</a
          >
        </li>
        <li v-if="settings.prices" class="nav-item">
          <a
            :class="tabsController.prices ? 'nav-link active' : 'nav-link'"
            href="#prices"
            @click="toggleTab('prices')"
            >{{ $t("leffit.products.product.prices") }}</a
          >
        </li>
        <li v-if="settings.suppliers" class="nav-item">
          <a
            :class="tabsController.suppliers ? 'nav-link active' : 'nav-link'"
            href="#suppliers"
            @click="toggleTab('suppliers')"
            >{{ $t("leffit.crm.suppliers") }}</a
          >
        </li>
        <!-- <li v-if="settings.website" class="nav-item">
					<a :class="tabsController.website ? 'nav-link active':'nav-link'" href="#website" @click="toggleTab('website')">{{ $t('leffit.cms.website') }}</a>
				</li>  -->
        <li
          class="nav-item"
          v-if="
            item.producttype == 'leffit.products.product_types.plannable' &&
              settings.plannable
          "
        >
          <a
            :class="tabsController.plannable ? 'nav-link active' : 'nav-link'"
            href="#plannable"
            @click="toggleTab('plannable')"
            >{{ $t("leffit.products.product.plannable") }}
          </a>
        </li>
        <li
          v-for="(type, index) in filteredRelateds"
          :key="index"
          class="nav-item"
        >
          <a
            :class="tabsController[type.value] ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab(type.value)"
            >{{ type.label }}
          </a>
        </li>
        <li v-if="settings.payment" class="nav-item">
          <a
            :class="
              tabsController.paymentplans ? 'nav-link active' : 'nav-link'
            "
            href="#related"
            @click="toggleTab('paymentplans')"
            >Paymentplans</a
          >
        </li>
        <li v-if="settings.email" class="nav-item">
          <a
            :class="tabsController.emails ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('emails')"
            >Emails</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="tabsController.orderlang ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('orderlang')"
            >{{ $t("leffit.products.product.orderconfirmation") }}</a
          >
        </li>
        <li
          class="nav-item"
          v-if="item.producttype == 'leffit.products.product_types.digital'"
        >
          <a
            :class="tabsController.digital ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('digital')"
            >Digital Product</a
          >
        </li>
        <li
          v-if="
            item.producttype == 'leffit.products.product_types.subscription'
          "
          class="nav-item"
        >
          <a
            :class="
              tabsController.subscription ? 'nav-link active' : 'nav-link'
            "
            href="#related"
            @click="toggleTab('subscription')"
            >Subscription</a
          >
        </li>
        <li
          v-if="item.producttype == 'leffit.products.product_types.stripescard'"
          class="nav-item"
        >
          <a
            :class="
              tabsController.stripescards ? 'nav-link active' : 'nav-link'
            "
            href="#related"
            @click="toggleTab('stripescards')"
            >Stripe Cards</a
          >
        </li>
        <li
          v-if="item.producttype == 'leffit.products.product_types.appointment'"
          class="nav-item"
        >
          <a
            :class="tabsController.appointment ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('appointment')"
            >Appointment</a
          >
        </li>
        <li
          v-if="
            item.producttype == 'leffit.products.product_types.combined_product'
          "
          class="nav-item"
        >
          <a
            :class="tabsController.combined ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('combined')"
            >Combined Product</a
          >
        </li>
        <li
          v-if="item.producttype == 'leffit.products.product_types.course'"
          class="nav-item"
        >
          <a
            :class="tabsController.course ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('course')"
            >Course</a
          >
        </li>
        <!-- <li class="nav-item">
          <a
            :class="tabsController.warehouse ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('warehouse')"
            >{{ $t("leffit.products.product.warehouses") }}</a
          >
        </li> -->
        <li class="nav-item">
          <a
            :class="tabsController.backorder ? 'nav-link active' : 'nav-link'"
            href="#related"
            @click="toggleTab('backorder')"
            >Backorders</a
          >
        </li>
        <!-- <li class="nav-item">
					<a :class="'nav-link'" href="#related" @click="toggleTab('contact')">Contact</a>
				</li> -->
        <li class="nav-item">
          <a
            :class="tabsController.size ? 'nav-link active' : 'nav-link'"
            href="#size"
            @click="toggleTab('size')"
            >{{ $t("leffit.products.product.sizing") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="
              tabsController.casa_filters ? 'nav-link active' : 'nav-link'
            "
            href="#casa_filters"
            @click="toggleTab('casa_filters')"
            >{{ $t("leffit.products.product.casa_filters") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="tabsController.bijenkorf ? 'nav-link active' : 'nav-link'"
            href="#bijenkorf"
            @click="toggleTab('bijenkorf')"
            >{{ $t("leffit.products.product.bijenkorf") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="tabsController.bolcom ? 'nav-link active' : 'nav-link'"
            href="#bolcom"
            @click="toggleTab('bolcom')"
            >{{ $t("leffit.products.product.bolcom") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="tabsController.seo ? 'nav-link active' : 'nav-link'"
            href="#seo"
            @click="toggleTab('seo')"
            >{{ $t("leffit.products.product.seo") }}</a
          >
        </li>
      </ul>

      <div v-if="loaded && settings" class="col-12 p-0">
        <div
          class="tab-content"
          id="myTabContent"
          style="width: 99%; margin-top: 10px; background: #d3d3d340"
        >
          <div
            :class="
              tabsController.description
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="description"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <description :item="item"></description>
          </div>
          <div
            v-if="settings.stock"
            :class="
              tabsController.stock
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="stock"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <stocks :key="stockLoaded" :stocks="stockData"></stocks>
          </div>
          <div
            v-if="settings.filters"
            :class="
              tabsController.filters
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="filters"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <productFilters :item="item"></productFilters>
          </div>
          <div
            v-if="settings.images && item.productimages_nl"
            :class="
              tabsController.images
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="images"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <productImages :item="item" :product="item.id"></productImages>
          </div>
          <div
            v-if="settings.purchase_pricelevels"
            :class="
              tabsController.purchase_pricelevels
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="purchase_pricelevels"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <pricelevelspurchase
              :item="item"
              :pricelevels="pricelevels"
            ></pricelevelspurchase>
          </div>
          <div
            v-if="settings.sales_pricelevels"
            :class="
              tabsController.sales_pricelevels
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="sales_pricelevels"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <pricelevelssale
              :item="item"
              :pricelevels="pricelevels"
            ></pricelevelssale>
          </div>
          <div
            v-if="settings.prices"
            :class="
              tabsController.prices
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="prices"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <pricelevels :item="item" :pricelevels="pricelevels"></pricelevels>
          </div>
          <div
            v-if="settings.suppliers"
            :class="
              tabsController.suppliers
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="suppliers"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <suppliers :item="item"></suppliers>
          </div>
          <div
            v-if="settings.website"
            :class="
              tabsController.website
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="website"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <website :item="item"></website>
          </div>
          <div
            v-if="settings.payment"
            :class="
              tabsController.paymentplans
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="paymentplans"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <paymentplans :item="item"></paymentplans>
          </div>
          <div
            :class="
              tabsController.emails
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="emails"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <emails :item="item"></emails>
          </div>
          <div
            :class="
              tabsController.orderlang
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="orderlang"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <orderlang :item="item"></orderlang>
          </div>
          <div
            v-if="
              item.producttype == 'leffit.products.product_types.plannable' &&
                settings.plannable
            "
            :class="
              tabsController.plannable
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="plannable"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="card">
              <div class="form-group">
                <label>Time before</label>
                <datetime
                  type="datetime"
                  v-model="item.plannable.time_before"
                  input-class="form-control"
                ></datetime>
              </div>
              <div class="form-group">
                <label>Time after</label>
                <datetime
                  type="datetime"
                  v-model="item.plannable.time_after"
                  input-class="form-control"
                ></datetime>
              </div>
              <div class="form-group">
                <label>Time task</label>
                <datetime
                  type="datetime"
                  v-model="item.plannable.time_task"
                  input-class="form-control"
                ></datetime>
              </div>
              <div class="form-group">
                <label>Appointments</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="item.plannable.occurrences"
                />
              </div>
              <button
                type="submit"
                class="btn btn-success"
                @click.prevent="updateItem($event)"
              >
                Save
              </button>
            </div>
          </div>
          <div
            v-for="(type, index) in relateds"
            :key="'tab' + index"
            :class="
              tabsController[type.value]
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            :id="'related_' + type.value"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <product
              :type="type.value"
              :product="item.id"
              :item="item.relateds[type.value]"
              :products="products"
            ></product>
          </div>
          <div
            v-if="item.digital_products"
            :class="
              tabsController.digital
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="digital"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <digital :item="item"></digital>
          </div>
          <div
            v-if="
              item.producttype == 'leffit.products.product_types.subscription'
            "
            :class="
              tabsController.subscription
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="subscription"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <subscription :item="item"></subscription>
          </div>
          <div
            v-if="
              item.producttype == 'leffit.products.product_types.stripescard'
            "
            :class="
              tabsController.stripescards
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="stripescards"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <stripescards :item="item"></stripescards>
          </div>
          <div
            v-if="
              item.producttype == 'leffit.products.product_types.appointment'
            "
            :class="
              tabsController.appointment
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="appointment"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <appointment :item="item"></appointment>
          </div>
          <div
            v-if="
              item.producttype ==
                'leffit.products.product_types.combined_product'
            "
            :class="
              tabsController.combined
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="combined"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <product
              :type="'combined_producttype_' + item.producttype_id"
              :product="item.id"
              :item="
                item.relateds['combined_producttype_' + item.producttype_id]
              "
            ></product>
          </div>
          <div
            v-if="item.producttype == 'leffit.products.product_types.course'"
            :class="
              tabsController.course
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="course"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <course :product="item.id" :item="item.relateds['course']" />
          </div>
          <div
            :class="
              tabsController.contact
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="contact"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <contact :item="item" />
          </div>
          <div
            :class="
              tabsController.warehouse
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="warehouse"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <warehouses :key="stockLoaded" :item="item" :stocks="stockData" />
          </div>
          <div
            :class="
              tabsController.backorder
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="backorder"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <backorders :item="item" />
          </div>
          <div
            :class="
              tabsController.size
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="size"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <Size :item="item" />
          </div>
          <div
            :class="
              tabsController.bijenkorf
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="bijenkorf"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <webshop :item="item" :filter_id="2" />
          </div>
          <div
            :class="
              tabsController.bolcom
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="bolcom"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <webshop :item="item" :filter_id="3" />
          </div>
          <div
            :class="
              tabsController.casa_filters
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="casa_filters"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <webshop :item="item" :filter_id="1" />
          </div>
          <div
            :class="
              tabsController.seo
                ? 'tab-pane fade show active'
                : 'tab-pane fade show'
            "
            id="bolcom"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <seo :product="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import productFilters from "./layouts/filters";
import productImages from "./layouts/images";
import product from "./layouts/product";
import course from "./layouts/course";
import website from "./layouts/websites";
import suppliers from "./layouts/suppliers";
import pricelevels from "./layouts/pricelevels";
import pricelevelspurchase from "./layouts/pricelevels_purchase";
import pricelevelssale from "./layouts/pricelevel_sales";
import stocks from "./layouts/stocks";
import paymentplans from "./layouts/paymentplans";
import emails from "./layouts/emails";
import tabsMeta from "../../components/tabs_meta";
import orderlang from "./layouts/orderlang";
import digital from "./layouts/digital";
import subscription from "./layouts/subscription";
import stripescards from "./layouts/stripescards";
import appointment from "./layouts/appointments";
import contact from "./layouts/contact";
import warehouses from "./layouts/warehouse";
import backorders from "./layouts/backorders";
import Size from "./layouts/size.vue";
import webshop from "./layouts/webshops.vue";
import description from "./layouts/description";
import Seo from "./layouts/seo.vue";

export default {
  props: ["id", "edit"],
  components: {
    contact,
    productFilters,
    productImages,
    product,
    website,
    suppliers,
    pricelevels,
    pricelevelspurchase,
    pricelevelssale,
    stocks,
    tabsMeta,
    paymentplans,
    emails,
    orderlang,
    digital,
    subscription,
    stripescards,
    appointment,
    course,
    warehouses,
    backorders,
    Size,
    webshop,
    description,
    Seo,
  },
  name: "item",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      tabsController: {
        definition: false,
        stock: true,
        filters: false,
        images: false,
        prices: false,
        suppliers: false,
        website: false,
        versions: false,
        related: false,
        plannable: false,
        paymentplans: false,
        purchase_price: false,
        warehouse: false,
        backorders: false,
        emails: false,
        size: false,
        bijenkorf: false,
        bolcom: false,
        orderlang: "",
        description: false,
        seo: false,
      },
      item: {
        id: "",
        name: "",
        description: "",
        producttype_id: "",
        ean: "",
        productstatus_id: "",
        price: "",
        silver_weight: "",
        weight: "",
        vat: "",
        stock: "",
        size: "",
        remarks: "",
        fax: "",
        email: "",
        picture: "",
        function: "",
        birthday: "",
        gender: "",
        coc: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        productfilters: [],
        version_products: [],
      },
      language: language,
      profileImageSet: false,
      editting: { basic: false, other: false },
      producttypes: [],
      products: [],
      productstatuses: [],
      productPriceLevels: {},
      loaded: false,
      setting_ready: false,
      settings: {},
      price_settings: {},
      relateds: [],
      group_tabs: [],
      options: [],
      plans: [],
      productsLoaded: false,
      pricelevels: [],
      selectedTab: "",
      stockData: [],
      stockLoaded: "propress",
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    isDisabled: function() {
      return !(this.edit == "edit");
    },
    filteredRelateds: function() {
      return this.relateds.filter(
        (item) => this.settings[item.value] != undefined
      );
    },
  },
  methods: {
    toggleTab(field) {
      if (this.selectedTab != field) {
        this.tabsController = {
          filters: false,
          images: false,
          prices: false,
          suppliers: false,
          website: false,
          versions: false,
          related: false,
          plannable: false,
          definition: false,
          description: false,
          casa_filters: false,
        };
        this.tabsController[field] = true;
        this.selectedTab = field;
      }
    },
    translate(input) {
      if (input) {
        if (input.constructor === String) {
          if (input.indexOf("leffit.products") !== -1) {
            return this.$t(input);
          } else {
            return input;
          }
        }
      }

      return input;
    },
    updateItem(e) {
      if (e) {
        e.preventDefault();
      }

      let notification = {
        title: "Product",
        text: "Updated successfully",
        type: "success",
      };
      this.item.paymentplans = JSON.stringify(this.plans);
      this.$store
        .dispatch("put", {
          endpoint: "/leffit/products/products/" + this.item.id,
          details: this.item,
          notification: notification,
        })
        .then((data) => {
          console.log("resultkk", data.data);
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    trigger() {
      document.getElementById("file").click();
    },
    cancelImage() {
      document.getElementById("imgHolder").style.backgroundImage =
        "url('" + this.item.picture + "')";
      this.profileImageSet = false;
    },
    readFile() {
      var file = document.getElementById("file").files[0];

      let formats = ["image/png", "image/jpg", "image/jpeg", "image/PNG"];

      if (!formats.find((ft) => ft == file.type)) {
        alert("file format not supported");
        return false;
      }

      var fileReader = new FileReader();
      fileReader.onload = (result) => {
        document.getElementById("imgHolder").style.backgroundImage =
          "url('" + result.srcElement.result + "')";
        this.profileImageSet = true;
      };

      fileReader.readAsDataURL(file);
    },
    getProductImages() {
      if (this.item.productimages) {
        this.item.productimages.forEach((image) => {
          this.$store
            .dispatch(
              "getImage",
              "/leffit/products/view-productimages/" + image.id
            )
            .then((result) => {
              var reader = new window.FileReader();
              var context = this;
              reader.readAsDataURL(result.data);
              reader.onload = function() {
                var imageDataUrl = reader.result;
                context.$store.state.leffit.productImages[
                  image.id
                ] = imageDataUrl;

                var temp = context.item.productimages;
                context.item.productimages = [];
                context.item.productimages = temp;
              };
              // console.log(tmp);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    },
    // sortPriceLevels(){
    // 	var temp={};
    // 	temp['leffit.products.pricelevel.purchase_price_latest']=this.getPriceLevel('leffit.products.pricelevel.purchase_price_latest');
    // 	temp['leffit.products.pricelevel.purchase_cost_latest']=this.getPriceLevel('leffit.products.pricelevel.purchase_cost_latest');
    // 	temp['leffit.products.pricelevel.cost_price']=this.getPriceLevel('leffit.products.pricelevel.cost_price');
    // 	temp['leffit.products.pricelevel.wholesale']=this.getPriceLevel('leffit.products.pricelevel.wholesale');
    // 	this.productPriceLevels=temp;
    // },
    getPriceLevel(key) {
      var v = this.item.pricelevels.find((d) => d.key == key);
      return v;
    },
    async getProducts() {
      console.log("Products");
      await this.$store
        .dispatch("get", "/leffit/products/light-weight")
        .then((data) => {
          this.products = data.data.data;
          window.$(".select2-container").css("width", "100%");
        });
      console.log("end products");
    },
    sortPriceLevels() {
      this.pricelevels = this.price_settings.pricelevels.map((d) => {
        d.price = 0;
        d.key = "leffit.products.pricelevel." + d.key;
        var pricelevel = this.item.pricelevels.find((p) => p.key == d.key);
        if (pricelevel != undefined) {
          d.price = pricelevel.price;
          d.alreadyset = true;
          d.id = pricelevel.id;
        }
        return d;
      });

      this.sales_pricelevels = this.price_settings.sales_pricelevels.map(
        (d) => {
          d.price = 0;
          d.key = "leffit.products.pricelevel." + d.key;
          var pricelevel = this.item.pricelevels.find((p) => p.key == d.key);
          if (pricelevel != undefined) {
            d.price = pricelevel.price;
            d.alreadyset = true;
            d.id = pricelevel.id;
          }
          return d;
        }
      );
    },
  },

  async created() {
    await this.getProducts();
    console.log("product_settings");
    await this.$store
      .dispatch("get", "/lefficient/leffit/packages/settings/product_settings")
      .then((data) => {
        if (data.data != null) {
          if (data.data.setting != null) {
            var settings = JSON.parse(data.data.setting);
            settings.tabs.forEach((element) => {
              this.settings[element.field] = element.show;
            });

            this.relateds = settings.related_types
              ? settings.related_types
              : [];

            var translated_label = {
              version: this.$t("leffit.products.product.versions"),
              sizes: this.$t("leffit.products.product.sizes"),
              related: this.$t("leffit.products.product.relateds"),
            };

            this.relateds = this.relateds.map((rel) => {
              rel.label = translated_label[rel.value];
              return rel;
            });

            this.options = settings.options
              ? JSON.parse(JSON.stringify(settings.options))
              : [];

            this.group_tabs = settings.group_tabs
              ? JSON.parse(JSON.stringify(settings.group_tabs))
              : [];

            this.price_settings.pricelevels = settings.pricelevels
              ? JSON.parse(JSON.stringify(settings.pricelevels))
              : [];

            this.price_settings.sales_pricelevels = settings.pricelevels
              ? JSON.parse(JSON.stringify(settings.sales_pricelevels))
              : [];

            this.setting_ready = true;
            // console.log(this.relateds);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("end product_settings");

    console.log("one product");
    await this.$store
      .dispatch("get", "/leffit/products/products/" + this.id)
      .then((data) => {
        this.item = data.data.data;
        if (this.item.stock) {
          this.item.stock = this.item.stock < 0 ? 0 : this.item.stock;
        }

        this.sortPriceLevels();

        if (this.item.plannable != null) {
          this.item.plannable.time_before = new Date(
            this.item.plannable.time_before
          ).toISOString();
          this.item.plannable.time_after = new Date(
            this.item.plannable.time_after
          ).toISOString();
          this.item.plannable.time_task = new Date(
            this.item.plannable.time_task
          ).toISOString();
        }

        window.$(".select2").select2();
        this.getProductImages();
        this.loaded = true;
      });
    console.log("end one product");

    this.$store
      .dispatch("get", "/leffit/products/producttypes")
      .then((data) => {
        // console.log('result producttypes',data);
        this.producttypes = data.data.data;
        var context = this;
        window
          .$(".select3")
          .select2()
          .change(function() {
            context.item.producttype_id = window.$(".select3").val();
            window.console.log(window.$(".select3").val());
          });
      });

    this.$store
      .dispatch("get", "/leffit/products/productstatuses")
      .then((data) => {
        // console.log('result productstatuses',data);
        this.productstatuses = data.data.data;
        var context = this;
        window
          .$(".select4")
          .select2()
          .change(function() {
            context.item.productstatus_id = window.$(".select4").val();
            window.console.log(window.$(".select4").val());
          });
      });
    console.log("STOCKS");
    this.$store
      .dispatch(
        "get",
        "/leffit/products/product-stocks/" + this.$route.params.id
      )
      .then((data) => {
        this.stockData = data.data.data;
        this.stockLoaded = "done";
        console.log("ENDSTOCKS");
      });
  },
};
</script>

<style lang="css" scoped>
.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: white !important;
  border: none !important;
}
.imgh {
  display: block;
  width: 70px;
  height: 70px;
  border: 1px solid grey;
}
.edit {
  background-color: #80808014 !important;
  transition: all 0.3s linear;
}
.edit[disabled] {
  background-color: white !important;
  border: none;
  overflow: visible;
  cursor: initial;
  transition: all 0.3s linear;
}
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
