<template>
    <div id="login">

        <div id="login_module" class="">
            <img :src="logo" alt="">
            <div id="form">
                <form action="" @submit.prevent="signin()">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="login.email" :placeholder="$t('lefficient.leffit.user.email')">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" v-model="login.password" :placeholder="$t('lefficient.leffit.user.password')">
                    </div>
                    <div class="form-group">
                        <p v-if="login_task.error" class="msg text-danger">{{login_task.error_msg}}</p>
                        <p v-if="login_task.successfull" class="msg text-success">{{login_task.success_msg}}</p>
                        <button :disabled="(login_task.task_loader ? true:false)" class="btn btn-party btn-mijn btn-block p-2" type="submit">
                            <!-- <div id="task_loader" v-if="login_task.task_loader"></div> -->
                            <loader />
                            <span v-if="!this.loader.show">{{login_task.btntext}}</span>
                        </button>
                    </div>
                
                </form>
                <div class="row foot">
                    <div class="col-md-12 text-right">
                        <a href="/forgot-password">{{ $t('login.password_forgot') }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions , mapState } from 'vuex';
export default {
    name:'login',
    computed:{
    ...mapState({
        loader:state=>state.leffit.loader
    }),
    },
    data(){
        return {
            login:{
                email:process.env.VUE_APP_ADMIN_EMAIL,
                password:process.env.VUE_APP_ADMIN_PASSWORD,
            },
            login_task:{
                sent:false,
                error:false,
                error_msg:'',
                success:false,
                success_msg:'',
                btntext:this.$t('login.login'),
                task_loader:false
            },
            logo : process.env.VUE_APP_LOGO_URL,
        }
    }, 
    created(){
        if(process.env.VUE_APP_TESTMODE==1){
            this.login = {
                email:'info@lefficient.nl',
                password:'secret'
            };
        }
    },
    methods:{
        ...mapActions({
            signIn: 'auth/signIn',
        }),

        async signin () {
            this.login_task.error=false;

            this.$root.$i18n.locale = 'nl';
            window.localStorage.setItem('Language', 'nl');


            this.error = false;
            this.$store.state.auth.user = null;

            if(this.login.email.trim().length == 0 || this.login.password.trim().length == 0){
                this.login_task.error=true;
                this.login_task.error_msg= this.$t('error.field_empty', {'field': 'the email and password'});

                return false;
            } 

            await this.signIn(this.login).then((response)=>{
                console.log(response, 'ANTWOORD');
                // window.location = '/login';
            }).catch((error)=>{
                console.log(error, 'ERROR ANTWOORD');
            })
        },


    }
}
</script>

<style scoped>
    
    .form-control{
        background-color: #ffffffcc;
    }
    #login{
        height: 100%;
        /* background-image: linear-gradient(to right ,#00897e,#0090b5,#005197,#044580,#262261) !important ; */
    }
    .msg{
        font-size: 12px;
        color: white !important;
        background: #dc3545;
        padding: 1px 12px;
        width: fit-content;
        margin: 0px auto;
        margin-bottom: 10px;
    }
    #task_loader{
        width: 20px;
        height: 20px;
        border: 2px solid gray;
        border-top: 2px solid white;
        border-radius: 50%;
        position: absolute;
        left: 15%;
        margin-top: 0.7%;
        animation: spin .6s linear infinite;
    }
    
    @keyframes spin{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }

    #login_module .foot a{
        font-size: 13px;
        font-weight: bold;
        color: var(--color-1);
        display: block;
    }
    #login_module{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto; 
        display: table;
        border-radius: 5px; 
        width: 350px;
        height: fit-content;
    }
    #login_module img{
        width: 265px !important;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px; 
        border-radius: 7px; 
        padding: 5px 7px;
    }
    #login_module h2{
        text-align: center;
        margin: 20px 0px;
        color: #014d90;
        font-weight: 700;
    }
    #login_module form{
        text-align: center;
        
    }
    #login_module form input:focus{
        border:1px solid #014d90;
        box-shadow: none;
    }
    #login_module form input{
       font-size: 1rem;
        border-radius: 15px;
        padding: 25px 15px;
    }
     #login_module .btn-mijn{
        margin: 0 auto;
        padding: .300rem .75rem;
        background: var(--color-2) !important;
        border-radius:10px;
     }
</style>

<style>
    @media (max-width: 990px) {
        html,body,#app{
            height: 100%;
        }
        #login_module{
            width: 300px !important;
        }
    }
</style>
