import pricatImport from "./../../../../src/leffit/casa/pricat/import";
import pricatExport from "./../../../../src/leffit/casa/pricat/export";

export default 	[
    {
        path:"pricat/import",
        component: pricatImport,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"pricat/export",
        component: pricatExport,
        meta:{
            AuthRequired:true
        },
    },
];