<template>
    <div>
        <h4>UPLOAD EAN IMAGES</h4>

        <div class="row">
            <div class="col-12">
                <a @dragover="allowDrop($event)" @drop="dropped($event)" @click="trigger()" href="javascript:void(0)">
                    <div class="drg-drp">
                        <input @change="readFiles()" type="file" accept="image/*" class="d-none" id="files" multiple>
                        <h4>Drag And Drop Files</h4>
                    </div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-2">
                <div class="form-group form-check">
                    <input type="checkbox" v-model="lang.en" class="form-check-input">
                    <label for="en" class="p-0 form-check-label">Engels</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-2">
                <div class="form-group form-check">
                    <input type="checkbox" v-model="lang.nl" class="form-check-input">
                    <label for="en" class="p-0 form-check-label">Nederlands</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="!uploaded" class="col-12">
                <ol class="p-0">
                    <li class="file-row" v-for="(file,index) in files" :key="index">
                        <b>
                            <span>{{file.name}}</span>
                            <a @click="removeFile(index)" href="#"><i class="fas fa-times"></i></a>
                        </b>
                    </li>
                </ol>
            </div>
            <div v-else class="col-12">
                <div class="alert alert-success">
                    <p class="p-0 m-0">{{message}}</p>
                </div>
                <div v-if="errors.error_filenames.length > 0">
                    <h5>Error Filenames</h5>
                    <ol class="p-0">
                        <li class="file-row" v-for="(filename,index) in errors.error_filenames" :key="index">
                            <b>
                                <span>{{filename}}</span>
                            </b>
                        </li>
                    </ol>
                </div>
                <div v-if="errors.error_image_exist.length > 0">
                    
                <h5>Image Already Exist</h5>
                <ol class="p-0">
                    <li class="file-row" v-for="(product,index) in errors.error_image_exist" :key="index">
                        <a target="_blank" :href="'/admin/products/product/'+product.id"><span>{{product.name}}</span></a>
                    </li>
                </ol>
                </div>
                
            </div>
        </div>

        <div v-if="files.length > 0" class="row">
            <div class="col-12">
                <button @click="upload()" class="btn btn-success">
                    <span v-if="!uploading">UPLOAD IMAGES</span>
                    <span v-else>UPLOADING...</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            files:[],
            lang:{
                en:true,
                nl:true
            },
            uploading:false,
            uploaded:false,
            errors:[],
            message:''
        }
    },
    methods:{
        dropped(e){
            e.preventDefault();
            var files = [...e.dataTransfer.files];
            var format = ['jpg','JPG','JPEG','jpg','png','PNG'];
            files = files.filter((d)=> format.find((f)=> f == d.name.split('.')[1]));
            this.files = [...this.files, ...files];
            
        },
        allowDrop(e){e.preventDefault();},
        trigger(){
            window.$("#files").click();
        },
        upload(){
            if(this.uploading) return false;

            this.uploading = true;
            var payload = new FormData();
            payload.append('nl', this.lang.nl);
            payload.append('en', this.lang.en);
            this.files.map((file, index)=>{ 
                payload.append('file_'+index, file);
            });
            console.log(payload);
            
            this.$store.dispatch('post', {endpoint:'/leffit/products/upload-ean-images', details:payload})
            .then((data)=>{
                console.log(data);
                this.errors = data.data.errors;
                this.uploaded=true;
                this.uploading = false;
                this.message = "Image upload was successfull";
            })
            .catch((error)=>{
                console.log(error.request);
                this.uploading = false;
            });
        },
        readFiles(){
            var files=document.getElementById('files').files
            console.log(files);
            this.files = [...this.files, ...files];
        },
        removeFile(index){
            if(!confirm("Are you sure you want to remove this file?")) return false

            var temp = this.files;
            temp.splice(index, 1);
            this.files = temp;
        }
    }
}
</script>
<style scoped>
    ul{
        /* list-style-type: none; */
        padding: 0px;
    }
    .file-row{}
    .file-row b{
        display: block;
    }
    .file-row b span{
        color: grey;
    }
    .file-row b a{
        color: red;
        margin-left: 10px;
    }
    .drg-drp{
        border: 1px solid lightgray;
        width: 100%;
        height: 500px;
        position: relative;
    }
    .drg-drp:hover{
        background: #f2f2f2;
    }
    .drg-drp h4{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;
        display: block;
        padding: 0;
        color: grey;
        font-weight: 500;
        font-size: 17px;
    }
</style>