var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"w-100"},[_c('div',[_vm._v(_vm._s(_vm.label))]),(_vm.type == 10)?_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.onChange($event)}}}):_vm._e(),(_vm.type == 1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:_vm.cClass,attrs:{"type":"text"},domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}}):_vm._e(),(_vm.type == 4)?_c('Select2',{attrs:{"options":_vm.options},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),(_vm.type == 3)?_c('editor',{attrs:{"init":{
          height: 300,
          menubar: false,
          plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),(_vm.url)?_c('div',[_c('img',{staticClass:"rounded float-left img-thumbnail previ",attrs:{"src":_vm.url}}),(_vm.file != null)?_c('button',{staticClass:"ml-1 btn btn-sm btn-primary",on:{"click":_vm.copyToLanguage}},[_vm._v("Copy to "+_vm._s(_vm.lang == 'nl' ? 'English' : 'Deutch'))]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }