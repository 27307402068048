<template>
  <div>
    <div class="head row p-0 m-0">
      <h4>{{ $t("leffit.casa.pricat.import") }}</h4>
    </div>

    <form @submit="updateItem($event)">
      <div class="row p-0 m-0">
        <div class="col-12">
          <textarea
            placeholder="Plak hier de pricat..."
            v-model="content"
            class="form-control"
            rows="12"
          ></textarea>
        </div>
      </div>
      <div class="row p-0 m-0 mt-2">
        <div class="col-12">
          <button class="btn btn-success">Importeer</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "pricat_import",

  data() {
    return {
      content: "",
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
  },
  created() {},
  methods: {
    updateItem(e) {
      e.preventDefault();
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/casa/pricat/import",
          details: this.content,
        })
        .then(() => {
          this.$store.dispatch("makeNotification", {
            title: "Pricat",
            text: "Pricat imported",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    // trigger(){
    // 	document.getElementById('file').click();
    // },
    // cancelImage(){
    // 	document.getElementById('imgHolder').style.backgroundImage="url('"+this.item.picture+"')";
    // 	this.profileImageSet=false;
    // },
    // readFile(){
    //         var file=document.getElementById('file').files[0];

    //         let formats=['image/png','image/jpg','image/jpeg','image/PNG'];

    //         if(!formats.find((ft=> ft == file.type))){ alert('file format not supported'); return false; }

    //         var fileReader=new FileReader();
    //         fileReader.onload=(result)=>{
    // 	document.getElementById('imgHolder').style.backgroundImage="url('"+result.srcElement.result+"')";
    // 	this.profileImageSet=true;
    //         };
    //         fileReader.readAsDataURL(file);
    //       } ,
  },
  mounted() {
    //    this.$store.dispatch('get','/leffit/crm/contacts/'+this.id)
    //       .then(data=>{
    //       	console.log('result',data.data.data[0]);
    // 	this.item = data.data.data[0];
    // 	this.item.optin= this.item.optin == null ? 0: this.item.optin;
    // 	window.$('.language').val(this.item.language).select2();
    // 	window.$('.country').val(this.item.country).select2();
    // 	document.getElementById('contact_language').value=this.item.language;
    // });
  },
};
</script>

<style lang="css" scoped>
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
