export default {
    next(state){
        // console.log(state.regs[state.regProps.activeIndex]);
        state.regProps.activeIndex+=1;
        state.regProps.step=state.regs[state.regProps.activeIndex].step;
    },
    prev(state){
        state.regProps.activeIndex-=1;
        state.regProps.step=state.regs[state.regProps.activeIndex].step;
    },
    setUser(state,user){
        for(var key in user){
            user[key]=user[key] == null ? '':user[key];
        }
        state.user=user;
        localStorage.setItem('user',JSON.stringify(user));
        // window.console.log(user);
    },
    setToken(state,token){
        state.token=token;
        localStorage.setItem('token',JSON.stringify(token));
        localStorage.setItem('token_last_set',JSON.stringify(Date.now()));
        // window.console.log(token);
    },  
    logout(state){
         state.user={};
         state.token="";
         localStorage.removeItem('user');
         localStorage.removeItem('token');
    },
}