 import userIndex from "../../../../src/leffit/settings/users/index";
 import userItem from "../../../../src/leffit/settings/users/item";
 import createuser from "../../../../src/leffit/settings/users/create";

 import roleIndex from "../../../../src/leffit/settings/roles/index";
 import createrole from "../../../../src/leffit/settings/roles/create";
 import roleItem from "../../../../src/leffit/settings/roles/item";

 import permissionIndex from "../../../../src/leffit/settings/permissions/index";
 import createpermission from "../../../../src/leffit/settings/permissions/create";
 import permissionItem from "../../../../src/leffit/settings/permissions/item";

 import generator from "../../../../src/leffit/settings/generator/index";
 import routes from "../../../../src/leffit/settings/routes/index";

 import ordertypes from "../../../../src/leffit/settings/orders/ordertypes/index";
 import createordertype from "../../../../src/leffit/settings/orders/ordertypes/create";
 import ordertypeitem from "../../../../src/leffit/settings/orders/ordertypes/item";

 import packageSettingForm from "../../../../src/leffit/settings/packages/package/index";
 import packageSettingIndex from "../../../../src/leffit/settings/packages/index";
 import packageModelSettingIndex from "../../../../src/leffit/settings/packages/package/model";
 import purchase_keurloon_settings from "../../../../src/leffit/settings/packages/purchase_keurloon";

 import importIndex from "../../../../src/leffit/settings/import_emport/import";
import exportIndex from "../../../../src/leffit/settings/import_emport/export";
import bulkfiles from "../../../../src/leffit/settings/import_emport/bulkfiles";
import currencies from "../../../../src/leffit/settings/currencies/list";


export default 	[  
    {
        path:"users",
        component: userIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"users/create",
        component: createuser,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"user/:id",
        component: userItem,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"user/:id/edit",
        component: userItem,
        meta:{
            AuthRequired:true,
            edit:true
        },
    },

    {
        path:"roles",
        component: roleIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"roles/create",
        component: createrole,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"role/:id",
        component: roleItem,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"role/:id/edit",
        component: roleItem,
        meta:{
            AuthRequired:true,
            edit:true
        },
    },
     {
        path:"roles",
        component: roleIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"roles/create",
        component: createrole,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"role/:id",
        component: roleItem,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"packages",
        component: packageSettingIndex,
        meta:{
           AuthRequired:true
        },
    },
    {
        path:"packages/model/:id",
        component: packageModelSettingIndex,
        meta:{
           AuthRequired:true
        },
    },
    {
        path:"packages/config/purchase_keurloon",
        component: purchase_keurloon_settings,
        meta:{
           AuthRequired:true
        },
    },
    {
        path:"packages/:id",
        component: packageSettingForm,
        meta:{
           AuthRequired:true
        },
    },
    {
        path:"role/:id/edit",
        component: roleItem,
        meta:{
            AuthRequired:true,
            edit:true
        }, 
    },  
    {
        path:"generator",
        component: generator,
        meta:{
            AuthRequired:true
        }, 
    }, 
    {
        path:"routes",
        component: routes,
        meta:{
            AuthRequired:true
        }, 
    }, 
    {
        path:"ordertypes",
        component: ordertypes,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"ordertypes/create",
        component: createordertype,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"ordertypes/:id",
        component: ordertypeitem,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"ordertypes/:id/edit",
        component: ordertypeitem,
        meta:{
            AuthRequired:true,
            edit:true
        },
    },
    {
        path:"permissions",
        component: permissionIndex,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"permissions/create",
        component: createpermission,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"permission/:id",
        component: permissionItem,
        meta:{
            AuthRequired:true
        },
    },
    {
        path:"permission/:id/edit",
        component: permissionItem,
        meta:{
            AuthRequired:true,
            edit:true
        },
    },
    {
        path:"import",
        component: importIndex,
        meta:{
            AuthRequired:true 
        }, 
    }, 
    {
        path:"export",
        component: exportIndex,
        meta:{
            AuthRequired:true
        }, 
    }, 
    {
        path:"bulk-files",
        component: bulkfiles,
        meta:{
            AuthRequired:true
        }, 
    }, 
    {
        path:"currencies",
        component: currencies,
        meta:{
            AuthRequired:true
        }, 
    }, 
];