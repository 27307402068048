<template>
    <div style="position:relative;height:100vh"> 
        <div class="row tools">
            <div class="title col-12 col-sm-6 col-md-5">
                <router-link to='/admin/newsletters' style="padding:0px 20px;color:white;font-size:20px" class="btn"><i class="fa fa-arrow-left"></i> </router-link>
                <div class="title">
                    <input @keyup="saveTitle($event)" id="edittitle" style="color:white;text-align:center;background: #404040;border: none;border-radius: 0;" v-if="this.editTitle" type="text"  class="form-control" v-model="newsletter.name" />
                    <span style="color:white;text-transform: capitalize;" @click="setTitleFocus()" v-if="!this.editTitle">{{this.newsletter.name}}</span>
                </div>
            </div>
            <div class="tols col-12 col-sm-6 col-md-7"> 
                <div class="tool"><button :disabled="!changesMade" @click="saveToServer()" style="padding:5px 20px;color:white" class="btn"><i class="fa fa-save"></i> SAVE</button></div> &nbsp;
                <div class="tool"><button v-if="this.editMode && this.activeSection != null" @click="closeEdit()" style="padding:5px 20px;color:white" class="btn btn-danger"><i class="fa fa-reverse"></i> CLOSE EDIT MODE</button></div>
            </div>
        </div>
        
        <div id="templateHolder" :class="this.editMode ? 'templateHolder edit-mode':'templateHolder'">  
            <section class="container" id="app">
                <section class="row">
                    <!-- <div class="col-sm-0 col-md-1 col-lg-2"></div>  -->
                    <div class="col-sm-12 col-md-12 col-lg-12 holder mb-5">
                        <div @click="edittingMode(section,section.unique_id)" :id="'section'+section.unique_id" v-for="(section,index) in this.sections" :key="index" :class="section.mode == 'edit' ? 'temp editting':'temp'" >
                            <div class="sectionTools">
                                <button v-if="sections.length > 1 && index != 0" title="shift section upward" @click="up($event,section,index)" class="btn"><i class="fa fa-chevron-up"></i></button>
                                <button v-if="sections.length > 1 && index != sections.length-1" title="shift section downward" @click="down($event,section,index)" class="btn"><i class="fa fa-chevron-down"></i></button>
                                <button @click="deleteSection($event,section)" class="btn"><i class="fa fa-trash"></i></button>
                            </div>
                            <div class="sectionBody" 
                                v-html="section.content.split('++').map((tct)=>{ 
                                for(let c=0;c<section.fields.length;c++){
                                    tct=tct.replace('{{'+section.fields[c].element+'}}',section.fields[c].content)
                                }
                                return tct;
                             }).join('')"
                            ></div>
                        </div>
                    </div>
                </section>
            </section>
            <div @click="openModal()" class="defaultTemp">
                <button  class="btn"><i class="fa fa-plus"></i></button>
                <br> <br>
                <p>Add new section</p>
            </div> 
        </div> 

        <div v-if="this.editMode && this.activeSection != null" class="editBoard">
            <h4>EDITTING SECTION <b style="background:lightgray" class="pl-2 pr-2">{{this.activeSection.title}}</b></h4>

            <div class="editBody">
                <div v-for="(field,index) in this.activeSection.fields" :key="index" class="fields">
                    <label for="">{{field.label}}</label>
                    <div v-if="field.type == 'image'" class="uploadImage mt-2">
                        
                        <img :src="field.content" width="100%" height="100%" alt="">
                        <input class="d-none" type="file" name="" @change="readFIle(field.element)" :id="field.element">
                        <span @click="triggerFileInput(field.element)" v-if="field.content == ''"><i class="fa fa-plus"></i></span>
                        <span @click="removeImage(field)" v-if="field.content != ''"><i class="fa fa-remove text-danger"></i></span>
                    </div>

                    <div v-if="field.type == 'headerText' || field.type == 'buttonText' || field.type == 'link'" class="textEditor form-group">
                        <label for="">{{field.label}}</label>
                         <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" @keyup="updateSection(field.element)" />
                    </div>

                    <div v-if="field.type == 'bodyText'" class="textEditor">
                        <label for="">{{field.label}}</label>
                        <tinymce-editor  v-model="field.content" @keyup="updateSection(field.element)" class="form-control"   :init="{height: 400, force_br_newlines:true}" plugins="lists" toolbar="undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist"></tinymce-editor>
                    </div>
                </div>
            </div>

            <div class="editFooter" style="display:flex;justify-content:space-around">
                <button @click="saveChanges()" class="btn btn-success"><i class="fa fa-save"></i> Update Section</button>

                <button id="button" @click="reverseChanges()" class="btn btn-danger ml-3"><i class="fa fa-refresh"></i> Reverse Changes</button>
            </div>
        </div>
        <mailTemplates></mailTemplates>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import { mapState } from "vuex"
    import mailTemplates from "./templates"
    export default {
        name:'create_template',
        components:{
            mailTemplates,
            'tinymce-editor': Editor 
        },
        computed:{
            ...mapState({
                newsletter:state=>state.leffit.template,
                sections:state=>state.leffit.sections,
                activeSection:state=>state.leffit.activeSection,
                changesMade:state=>state.leffit.changesMade,
            })
        },
        data(){
            return {
                editMode:false,
                editBackup:null,
                activeIndex:0,
                updatedImages:[],
                editTitle:false 
            }
        },
        mounted(){
            console.log(this.newsletter);
        },
        created() {
            let id=this.$route.params.id; 
            if(this.newsletter.name == ''){
                this.$store.state.leffit.loader=true;
                this.$store.dispatch('get','/leffit/newsletters/get-newsletters/'+id).
                then(data=>{
                    
                    if(data.data == ''){ this.$router.push('/');}

                    let template=data.data;
                    console.log(template);
                    this.$store.state.leffit.template=template;
                    if(template.content != null && template.content != ''){
                        this.$store.state.leffit.sections=JSON.parse(template.content);
                        template.content='';
                        this.$store.state.leffit.template=template;
                    }

                    this.$store.state.leffit.loader=false;
                }).catch(error=>{
                    console.log(error);
                    this.$store.state.leffit.loader=false;
                    this.$router.push('/');
                })
            }
        },
        methods:{
            closeEdit(){
                this.editMode=false;
                // let sections=this.sections.map((dt)=>{ 
                //     dt.mode='initial';
                //     return dt;
                // });
            },
            up(e,section,index){
                e.stopPropagation();
                
                let temp=JSON.stringify(this.sections);
                temp=JSON.parse(temp);
                let tmp=temp[index-1]; 
                temp.splice(index-1,1);
                let temp2=[];
                
 
                for(var count=0;count<this.sections.length;count++){
                    // console.log(count);
                    if(count == index){  
                        temp2.push(tmp);
                    }
                    if(temp[count] != undefined){
                        temp2.push(temp[count]);
                    }
                }
                

                this.$store.state.leffit.sections=temp2;
            },
            down(e,section,index){
                e.stopPropagation();
                index+=1;
                let temp=JSON.stringify(this.sections);
                temp=JSON.parse(temp);
                let tmp=temp[index-1]; 
                temp.splice(index-1,1);
                let temp2=[];
                
 
                for(var count=0;count<this.sections.length;count++){
                    // console.log(count);
                    if(count == index){  
                        temp2.push(tmp);
                    }
                    if(temp[count] != undefined){
                        temp2.push(temp[count]);
                    }
                }
                

                this.$store.state.leffit.sections=temp2;
            },
            saveTitle(e){
                if(e.button == 13 || e.which == 13){
                    let value=document.getElementById('edittitle').value;
                    this.$store.state.leffit.template.name=value;
                    this.editTitle=false;
                    this.saveToServer();
                }else{
                    this.$store.state.leffit.changesMade=true;
                }
            },
            setTitleFocus(){
                this.editTitle=true
                setTimeout(()=>{
                    document.getElementById('edittitle').focus();
                },200);
            },
            saveToServer(){

                let template=this.newsletter;
                let sections=JSON.stringify(this.sections);
                template.content=sections;

                this.$store.state.leffit.loader=true;
                console.log(template);

                // return false;
                this.$store.dispatch('put',{endpoint:'/leffit/newsletters/newsletters/'+template.id,details:template})
                .then(data=>{
                    console.log(data);
                    this.$store.state.leffit.loader=false;
                    this.$store.state.leffit.changesMade=false;
                })
                .catch(error=>{
                    this.$store.state.leffit.formErrors.push('Server error!!');
                    console.log(error.request);
                    this.$store.state.leffit.loader=false;
                });
            },
            updateSection(id){
                // console.log(id);
                let element=document.getElementById(id);
                if(!element){
                    return false;
                }
                
                this.$store.state.leffit.sections=this.$store.state.leffit.sections.map((dt)=>{ 
                    
                    if(dt.unique_id == this.activeIndex){ 
                        dt.fields=dt.fields.map((dd)=>{
                            this.$store.state.leffit.changesMade=true;
                            if(dd.element == id){dd.content=element.value;} 
                            return dd;
                        });
                    } 
                    return dt;
                });
                
  
            },
            updateSectionValue(field_el,value){ 
                 
                this.$store.state.leffit.sections=this.$store.state.leffit.sections.map((dt)=>{ 
                    if(this.$store.state.leffit.activeSection.unique_id == dt.unique_id){
                        dt.fields=dt.fields.map((dd)=>{
                            if(dd.element == field_el){this.$store.state.leffit.changesMade=true; dd.content=value;} 
                            return dd;
                        });
                    } 
                    return dt;
                })
  
            },
            triggerFileInput(element){
                let el=document.getElementById(element);
                if(el){
                    el.click();
                }
            },
            readFIle(element){
                let file=document.getElementById(element);
                if(file !=null && file.files.length <= 0){
                    return false;
                }

                
                let formats=['image/jpeg','image/jpg','image/JPEG','image/JPG','image/PNG','image/png'];
                if(!formats.find((ft=> ft == file.files[0].type))){ alert('file format not supported'); return false; }
                
                let fileReader=new FileReader();

                fileReader.onload=(result)=>{ 
                    this.updateSectionValue(element,result.srcElement.result);
                    this.updatedImages.push({
                        field:this.activeSection.id,element,value:file.files[0]
                    });

                    console.log(this.updatedImages);
                }

                fileReader.readAsDataURL(file.files[0]);

                
            },
            removeImage(field){ 
                this.$store.state.leffit.changesMade=true;
                this.updateSectionValue(field.element,''); 

                this.updatedImages=this.updatedImages.filter(data=> data.element != field.element);

                console.log(this.updatedImages);
            },
            openModal(){
                this.$store.state.leffit.changesMade=true;
                this.$store.state.leffit.templateModal=true;
            },
            edittingMode(section,unique_id){ 
                
                this.editMode=true; 
                this.editBackup=JSON.stringify(this.$store.state.leffit.sections);

                let sections=this.$store.state.leffit.sections.map((dt)=>{  
                    dt.mode='initial';
                    if(dt.unique_id == unique_id){ 
                        dt.mode='edit';
                        section=dt; 
                        this.activeIndex=dt.unique_id;
                    }
                     
                    return dt;
                })
 

                console.log(sections);
                this.$store.state.leffit.sections=sections;
                this.$store.state.leffit.activeSection=section; 

                window.$("#templateHolder").animate({
                    scrollTop:window.$("#section"+unique_id).offset().top
                });

                
            },
            deleteSection(e,section){
                e.stopPropagation();
                e.eventBubble=false;

                this.editMode=true;

                let sections=this.sections.filter((dt)=> dt.unique_id != section.unique_id);

                this.$store.state.leffit.sections=sections;
                this.$store.state.leffit.activeSection=null;
                this.editMode=false;
                this.$store.state.leffit.changesMade=true;
                
            },
            reverseChanges(){ 
                this.$store.state.leffit.sections=JSON.parse(this.editBackup);

                let section=this.sections.find((dt)=>this.activeIndex == dt.unique_id);
                section.mode='edit';
                this.$store.state.leffit.activeSection=section;
                this.$store.state.leffit.changesMade=false;

            },
            saveChanges(){
                this.editMode=false;
                let sections=this.sections.map((dt)=>{ 
                    dt.mode='initial';
                    return dt;
                });

                this.$store.state.leffit.sections=sections;

                //if a new image was uploaded
                if(this.updatedImages.length > 0){
                    this.$store.state.leffit.loader=true;
                    let count=0;
                    
                    this.updatedImages.map((dt)=>{
                        let formData=new FormData();
                        formData.append('file',dt.value); 
                        this.$store.dispatch('post',{endpoint:'leffit/newsletters/upload-newsletters-image/'+this.newsletter.id,details:formData})
                        .then(data=>{
                            // console.log(data);
                            this.updateSectionValue(dt.element,data.data);
                            count+=1;
                            if(count == this.updatedImages.length){
                                this.$store.state.leffit.loader=false; 
                            }
                        })
                        .catch(()=>{
                            this.$store.state.leffit.formErrors.push('Server error!!');
                            // console.log(error.request);
                            this.updateSectionValue(dt.element,'');
                            count+=1;
                            if(count == this.updatedImages.length){
                                this.$store.state.leffit.loader=false; 
                            }
                        });
                    }); 
                }

                this.$store.state.leffit.changesMade=true;
            }
        }
    }
</script>

<style scoped> 
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        }
        .templateHolder{ 
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            margin: auto;
            margin-top: 80px;
            width: 670px;
            border: 1px solid lightgray;
            padding: 10px;
            height: 90%;
            overflow-y: scroll;
        }
        .editBoard{
            position: absolute;
            top:0%;
            bottom: 5%;
            right: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            width: 500px; 
            padding: 10px;
            height: 94%;
            overflow-y: scroll;
            background: #f2f2f2;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        }
        .defaultTemp .btn{
            border:1px solid lightgray;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: all .2s linear;
            color: gray;
        }
        .defaultTemp:hover .btn{
            transform: scale(1.3);
            background: white;
        }
        .defaultTemp:hover {
            transform: scale(0.9);
            background: #d3d3d31a;
        }
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            position: absolute;
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative; 
            margin-bottom: 10px;
        }
        .temp:hover{
            cursor: pointer; 
        }
        .temp:hover .sectionTools{
            display: block;
        }
        .temp.editting{
            border: 2px dashed red;
        }
        .temp:hover .sectionTools button{
            background: black;
        }
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        }
        .uploadImage:hover{
            transform: scale(0.95);
        }
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       
</style>
<style scoped>
    div img{
        width:100% !important;
    }
</style>