<template>
    <div>
        <div class="m-0">
            <div class="card row m-0">
                <div class="col-12  d-flex" style="justify-content:space-between">
                    <div class="form-group mr-3" style="width:100%">
                        <label for="">Select Model</label>
                        <select v-model="options.model" class="form-control" id="">
                            <option value=""></option>
                            <option value="product">Product</option>
                        </select>
                    </div>
                    <div class="form-group mr-3" style="width:100%">
                        <label for="">Select Delimiter</label>
                        <select v-model="options.delimiter" class="form-control" id="">
                            <option value="_">underscore _ </option> 
                            <option value="-">Dash -</option> 
                        </select>
                    </div>
                    <div class="form-group" style="width:100%">
                        <label for="">Select File type</label>
                        <select v-model="options.file_type" class="form-control" id="">
                            <option value="images"> Images </option> 
                            <option value="excel"> Excel </option> 
                        </select>
                    </div>
                </div>
                <div class="col-12 d-block">
                    <b-form-file id="files" :accept="accepted[options.file_type]" class="d-none" v-model="selected_files" @change="processFiles()"  :multiple="options.file_type == 'images' ? true:false"></b-form-file>
                    <div v-if="files.length == 0" class="" id="upload-zone">
                        <div @click="trigger()" class="toggler">
                            <i class="fas fa-upload"></i>
                            <h3>DROP FILES HERE</h3>
                            <p style="font-size:17px;color:red">OR</p>
                            <h5 style="font-size:18px;font-weight:normal"> Select files</h5>
                            <!-- <input type="file" multiple id="files" class="d-none"> -->
                        </div>
                    </div>
                    <div v-else>
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Ean</th>
                                <th scope="col">Product name</th>
                                <th scope="col">File type</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file,index) in files" :key="index">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{file.ean}}</td>
                                    <td>{{file.product_name}}</td>
                                    <td>{{file.type}}</td>
                                    <td><a @click="removefile(index)" href="#"><i class="fas fa-remove text-danger"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12" v-if="uploading || uploaded &&( !error_occured && files.length > 0)">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="'width:' +upload_progress+'%;background:#343a40'" :aria-valuenow="upload_progress" aria-valuemin="0" aria-valuemax="100">{{upload_progress}}%</div>
                    </div>
                    <div v-if="uploaded" class="alert alert-success">
                        <ul v-if="uploaded_list.length > 0">
                            <li v-for="(name,index) in uploaded_list" :key="index"><b>{{name}}</b></li>
                        </ul>
                        <div v-else>
                            <b>No file was uploaded correctly.</b>
                        </div>
                    </div>
                    <div v-if="uploaded" class="alert alert-danger">
                        <ul v-if="not_uploaded_list.length > 0">
                            <li v-for="(name,index) in not_uploaded_list" :key="index"><b>{{name}}</b></li>
                        </ul>
                        <div v-else>
                            <b>There were no errors</b>
                        </div>
                    </div>
                </div>
                <div v-if="error_occured" class="alert alert-danger">
                    <p>Opps server error occured while uploading files</p>
                </div>
                <div class="col-12 d-block" >
                    <button v-if="files.length > 0" @click="uploadFiles()"  class="btn btn-success float-right">UPLOAD</button>
                    <button v-if="files.length > 0" @click="trigger()" class="btn btn-dark btn-sm"><i class="fas fa-plus"></i> ADD MORE FILES</button>
                    <button v-if="files.length > 0" @click="removeAll()" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i> &nbsp; REMOVE ALL FILES</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
    export default {
        data(){
            return {
                options:{
                    model:'',file_type:'images',delimiter:'_'
                },
                selected_files:[],
                files:[],
                accepted:{
                    images:'image/*',
                    excel:'.xlsx, .csv'
                },
                uploading:false,
                uploaded:false,
                error_occured:false,
                uploaded_list:[],
                not_uploaded_list:[],
            }
        },
        computed:{
            ...mapState({
                upload_progress:(state)=>state.leffit.upload_progress
            })
        },
        methods:{
            uploadFiles(){
                if(this.options.model == ''){
                    alert('Pick a model');
                    return false;
                }
                if(this.options.file_type == 'images'){
                    this.uploadImages();
                }else{
                    this.uploadExcel();
                }
            },
            uploadExcel(){
                var payload=new FormData();
                let count=0;

                this.uploading=true;
                this.uploaded=false;
                this.error_occured=false;
                this.uploaded_list=[];
                this.not_uploaded_list=[];

                payload.append('file',this.files[0].file);
                payload.append('model',this.options.model); 

                this.$store.dispatch("post_progress",{
                    endpoint:'/lefficient/leffit/import',
                    details:payload
                })
                .then((data)=>{
                    console.log(data);
                    this.uploading=false; 
                    this.uploaded=true; 
                })
                .catch((error)=>{
                    console.log(error.request);
                    this.uploading=false;
                    this.error_occured=true;
                });
            },
            uploadImages(){
                var payload=new FormData();
                let count=0;

                this.uploading=true;
                this.uploaded=false;
                this.error_occured=false;
                this.uploaded_list=[];
                this.not_uploaded_list=[];

                this.files.forEach(element => {
                    payload.append('file_'+count,element.file);
                    count++;
                }); 
                payload.append('model',this.options.model);
                payload.append('delimiter',this.options.delimiter);

                this.$store.dispatch("post_progress",{
                    endpoint:'/lefficient/leffit/bulk-image-upload',
                    details:payload
                })
                .then((data)=>{
                    console.log(data);
                    this.uploading=false;
                    // this.files=[];
                    this.uploaded=true;
                    this.uploaded_list=data.data.uploaded;
                    this.not_uploaded_list=data.data.not_uploaded;
                })
                .catch((error)=>{
                    console.log(error.request);
                    this.uploading=false;
                    this.error_occured=true;
                });
            },
            trigger(){
                if(this.options.file_type == '' || this.options.delimiter == ''){
                    alert("Kindly pick a file type and a delimiter before proceeding");
                    return false;
                } 
                document.getElementById('files').click();
            },
            removeAll(){
                if(!confirm("This action will remove all files")){
                    return false;
                }
                this.files=[];
                this.selected_files=[];
            },
            removefile(index){
                var part1=this.files.slice(0,index);
                var part2=this.files.slice(index+1,this.files.length);
                
                this.files=[...part1,...part2];
            },
            processFiles(){
                setTimeout(()=>{ 
                    if(this.options.file_type == 'images'){
                        var count=this.selected_files.length;
                        this.selected_files=this.selected_files.filter((d)=>this.is_valid_filename(d.name));
                        if(count != this.selected_files.length){
                            alert("Some of the selected files don't have the right filename format.");
                        }
                        var temp=this.selected_files.map((file)=>{
                            var name=this.splitname(file.name);
                            return {
                                file:file,
                                ean:name[0],
                                product_name:name[1].split('.')[0],
                                type:file.type
                            };
                        });
                        this.files.push(...temp);
                    }else if(this.options.file_type == 'excel'){
                        var file=this.selected_files; 
                        var name=file.name;
                        this.files=[{
                            file:file,
                            ean:'',
                            product_name:name.split('.')[0],
                            type:file.type
                        }];
                    }
                    this.selected_files=[]; 
                },100); 
            },
            is_valid_filename(name){ 
                return name.split(this.options.delimiter).length >= 2 ? true:false
            },
            splitname(name){
                return name.split(this.options.delimiter);
            }
        },
    }
</script>
<style scoped>
    #upload-zone{
        width:100%;
        height:400px;
        border: 1px dashed lightgrey;
        margin-bottom: 20px;
        position: relative; 
        
    }
    .toggler{
        position: absolute;
        height:fit-content;
        width:fit-content;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin: auto;
        font-size: 50px;
        color: #4e5467;
        text-align: center;
        transition: all .2s linear;
    }
    #upload-zone:hover .toggler{
        transform: scale(1.1);
    }
    .toggler:hover{
        cursor: pointer;
    }
</style>