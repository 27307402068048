<template>
    <div class="nest">
        <div @click="toggleNode(page.id,$event)"  v-for="(page,index) in data" :key="index" class="nested" style="background:#4f5467;padding-left:10px">  
            <a 
                class="menu"
                :class="{ active : getUrl(page) == $route.path}" 
                data-toggle="collapse"
                :href="'#'" 
                style="padding-bottom:0px;"
                >
                <div class="ina">
                    <div style="color:white" class="title"> {{ page.name}} </div>
                    <i class="fa fa-chevron-down colp-icon" v-if=" page.children.length > 0 && opened_pages.find(d=> d ==page.id) != undefined"></i>
                    <i class="fa fa-chevron-right colp-icon" v-if=" page.children.length > 0 && opened_pages.find(d=> d ==page.id) == undefined"></i>
                </div> 
                <div class="page-tools mt-1">
                    <a @click="deletePage(page.id,$event)" href="#"><i class="fas fa-trash"></i></a>
                    <a @click="toggleAddPage(page.id,$event)" href="#pageModal" data-toggle='modal'><i class="fas fa-plus"></i></a>
                    <a :href="'/admin/website/'+page.id+'/edit'"><i class="fas fa-edit"></i></a>
                    <a @click="hidePage(page.id,$event)">
                        <i v-if="page.visible == 1" style="color:white"  class="fas fa-eye"></i>
                        <i v-else style="color:white" class="fas fa-eye-slash"></i>
                    </a>
                    <a @click="lockPage(page.id,$event)" href="#">
                        <i v-if="page.locked == 1" class="fas fa-lock"></i>
                        <i v-else class="fas fa-lock-open"></i>
                    </a>
                </div> 
            </a>
            <div :class="opened_pages.find(d=> d ==page.id) != undefined? 'children open':'children'">
                <innerpages v-if="page.children.length > 0" :data="page.children"></innerpages>
            </div>
        </div>
    </div>
</template>
<script>  
    export default {
        name:'innerpages',  
        props:['data'] ,
        data(){
            return {  
                opened_pages:[],
                pages:[],
            }
        },
        methods:{ 
            getUrl(page){
                return '/admin/website/'+page.id+'/edit';
            },
            deletePage(id,e){
                e.stopPropagation();

                if(!confirm("Are you sure you want to delete this item?")){
                    return false;
                }
                this.$store.dispatch('delete','/leffit/cms/pages/'+id)
                .then((data)=>{
                    console.log(data);
                    this.$store.state.leffit.pages=data.data.data;
                    this.data=this.$store.state.leffit.pages;
                })
                .catch((error)=>{
                    console.log(error.request);
                });
            },
            hidePage(id,e){
                e.stopPropagation();
                this.$store.dispatch('get','/leffit/cms/pages/hide/'+id)
                .then((data)=>{
                    console.log(data);
                    this.$store.state.leffit.pages=data.data.data;
                    this.data=this.$store.state.leffit.pages;
                })
                .catch((error)=>{
                    console.log(error.request);
                });
            },
            lockPage(id,e){
                e.stopPropagation();
                this.$store.dispatch('get','/leffit/cms/pages/lock/'+id)
                .then((data)=>{
                    console.log(data);
                    this.$store.state.leffit.pages=data.data.data;
                    this.data=this.$store.state.leffit.pages;
                })
                .catch((error)=>{
                    console.log(error.request);
                });
            },
            toggleAddPage(data,e){
                e.stopPropagation(); 
                this.$store.state.leffit.page.parent_id=data; 
                this.$store.state.leffit.page.editting=false; 
                window.$('#pageModal').modal('show');
            },
            toggleNode(id,e){
                e.stopPropagation();
                if(this.opened_pages.find(d=> d == id)){
                    var temp=this.opened_pages.filter(d=>d != id); 
                    this.$store.state.leffit.opened_pages=temp;
                    this.opened_pages=this.$store.state.leffit.opened_pages;
                }else{
                    this.$store.state.leffit.opened_pages.push(id);
                    this.opened_pages=this.$store.state.leffit.opened_pages;
                } 
                
                // open pages hist
                this.setCurrentOpenPages(id);
            },
            getCurentOpenPages(){
               const open_pages = window.localStorage.getItem('opened_pages'); 
               console.log(open_pages);
               let open_pages_arr = [];
               if(open_pages){
                   open_pages_arr = open_pages.split(",");
               }
               
               return open_pages_arr;
            },
            setCurrentOpenPages(id){
                let open_pages_arr=[];
                open_pages_arr = this.getCurentOpenPages();

                if(open_pages_arr.find(d=> d == id)){
                    var temp=open_pages_arr.filter(d=>d != id); 
                    open_pages_arr = temp;
                }else{
                    open_pages_arr.push(id);
                }

                localStorage.setItem('opened_pages', open_pages_arr);
                console.log(open_pages_arr);
            }
        },
        created(){
           if(this.$route.path=='/admin/website'){
               localStorage.setItem('opened_pages', '');
           }  

           const open_pages = window.localStorage.getItem('opened_pages'); 
           if(open_pages){
               let open_pages_arr = open_pages.split(",");
               this.opened_pages= open_pages_arr;
               console.log(open_pages_arr);
           }
           

           console.log('route path: ',this.$route.path);
          
        }
    }
</script>

<style scoped>
	.children{
		height:0;
		overflow:hidden;
		transition: all .3s linear;
	}
	.children.open{
		height:auto; 
		max-height: 50%;
		transition: all .3s linear;
	}
	.page-tools{
		padding-left: 9px;
		line-height: 1;
		padding-bottom: 5px;
	}
	.page-tools a{
		color: #d5d3d3e6;
		font-size: 13px;
		margin: 0px 2px;
	}
	.page-tools a i{}
</style>
<style scoped>
    .collaps{
        margin-top: 0px;
    }
    .collaps a.menu.active{
        background: var(--color-2); 
    }
    .collaps a.menu:hover{
        background: var(--color-2);
    } 
    .collaps a.menu{
        text-decoration: none;
        display: block;
        text-align: left;
        padding: 5px 0px;
        transition: all .3s ease-in-out; 
        color: var(--color-2) !important; 
    }
    .menu[aria-expanded='true'] {
        background: var(--color-2);
    }
    .menu[aria-expanded='true'] .collapse{
        background: var(--color-1);
    }
    .collaps a.menu .collapse{ 
    }
 .collapse {
        /* background: var(--color-1); */
        padding: 0px;
        border-radius: 0px;
    }
    .collaps .collapse  a.submenu{ 
        padding: 5px 0px 0px 15px;
        display: block;
        font-size: 14px;
    }
     .collaps .collapse  a.submenu .title{ 
         color: #b9b9b9;
    }
    .collaps .ina{
        position: relative;
        padding: 3px 10px;
		line-height: 1;
    }

    .collaps .colp-icon{
        color: white;
        position: absolute;
        top: 7px;
        bottom: 0;
        height: 18px;
        margin: auto;
        right: 6px;
        font-size: 12px;
        display: block;
    }
    .collaps.active .colp-icon{
        display: block;
        color: var(--color-1);
    }
    .collaps .title{
        color: var(--color-1);
        font-weight:500;
        margin: 0;
        min-height: unset;
		font-size: 16px;
    }
    .collaps .active .title{
        color: #ededed !important;
    } 
    .collaps .menuIcons{}
    .collaps .menuIcons a{
        color: lightgray;
        margin-right: 6px;
    }
    .collaps.active .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps:hover .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps .menuIcons a:hover{
        color: gray;
    }
    .collaps .menuIcons a i.fa{}



    .icons{}
    .icons a{
        text-decoration: none;
        margin-right: 7px;
        padding: 0px 4px;
        border-radius: 13px;
    }
    .icons a i{  }

    .icons a:first-child{
        border:2px solid #fb9c7e70;
    }
    .icons a:first-child i{
        color: #fb9c7e70;
    }
    .icons a:nth-child(2){
        border:2px solid #4f546754;
    }
    .icons a:nth-child(2) i{
           color: #4f546754;
    }
     .icons a:nth-child(3){
        border:2px solid #d7e3f0;
    }
    .icons a:nth-child(3) i{
           color: #d7e3f0;
    }

    .icons a:nth-child(4){
        border:2px solid #17c0c873;
    }
    .icons a:nth-child(4) i{
           color: #17c0c873;
    }
    .top{
        display: flex;
        padding:5px 5px 10px;
        justify-content: space-between;
        white-space: nowrap;
    }
    .switch{
        background: var(--color-1);
        padding:10px 19px;
        border-radius: 10px;
        position: relative;
        margin-right: 5px;
    }
    .switch span{
       position: absolute;
        height: 79%;
        background: white;
        width: 17px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        margin: auto; 
        transition: all 1s linear;
    }
    .switch.on span{
        right: 2px;
    }
    .switch.off span{
        left: 2px;
    }

</style>
