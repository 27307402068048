<template>
  <div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th :key="index" v-for="(attribute, index) in attributes">
            {{ $t("leffit.products.product." + attribute) }}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <a @click="searchTable()" class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th :key="index" v-for="(attribute, index) in attributes">
            <input
              type="text"
              v-model="search.search[attribute]"
              class="form-control"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :key="index" v-for="(item, index) in products.data">
          <td>
            <router-link
              :to="'/admin/products/product/' + item.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              :to="'/admin/products/product/' + item.id + '/edit'"
              class="btn btn-warning"
            >
              <i class="fa fa-pencil"></i>
            </router-link>
          </td>
          <td
            :key="index"
            v-for="(attribute, index) in attributes"
            v-html="translate(item[attribute])"
          ></td>
        </tr>
      </tbody>
    </table>
    <div>
      <pagination
        :limit="2"
        :data="products"
        @pagination-change-page="searchTable"
      ></pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "product-table",
  data() {
    return {
      attributes: [
        "name",
        "description",
        "ean",
        "producttype",
        "productstatus",
        "price",
        "weight",
        "vat",
        "stock",
        "casa_productcode",
        "created_at",
        "updated_at",
        "deleted_at",
      ],
      search: { search: {} },
      products: {},
    };
  },
  methods: {
    searchTable(page = 1) {
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/search?page=" + page,
          details: this.search,
        })
        .then((data) => {
          this.products = data.data;
          this.$emit('onSearchTable',this.search)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    translate(input) {
      if (input) {
        if (input.constructor === String) {
          if (input.indexOf("leffit.products") !== -1) {
            return this.$t(input);
          } else {
            return input;
          }
        }
      }

      return input;
    },
  },
  mounted() {
    this.searchTable();
  },
};
</script>

<style>
.pagination > .page-item.active > a {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}
</style>
