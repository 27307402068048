<template>
    	<div class="row p-2 m-0 mb-5">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ $t('leffit.crm.suppliers') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.purchase_cost') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.purchase_currency') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.purchase_price') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.supplier_product_identifier') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.vat') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.delivery_time') }}</th>
                        <th>{{ $t('leffit.orders.orderrow.remarks') }}</th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td>
                            <button data-toggle="modal" data-target="#remarkModal" class="btn btn-primary"><i class="fa fa-plus"></i></button>
                        </td>
                        <td>
                            <Select2 id="selectOrganization" v-model="product_organization.organization_id" :options="supplierOptions" />
                        </td>
                        <td><input type="text" class="form-control" v-model="product_organization.purchase_cost"></td>
                        <td>
                            <Select2 id="selectCurrency" v-model="product_organization.purchase_price_currency_id" :options="currencyOptions" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="product_organization.purchase_price">
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="product_organization.supplier_product_identifier">
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="product_organization.vat">
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="product_organization.delivery_time">
                        </td>
                    </tr>
                </thead>
                <tbody>
                        <tr v-for="(sup,index) in item.suppliers" :key="index">
                            <td v-if="!sup.editting">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button @click="editSupplier(sup)"  class="btn btn-sm pl-2 pr-2 btn-primary"><i class="fa fa-edit"></i></button>
                                    <button @click="reOrder('up',index)"  v-if="index > 0" class="btn btn-sm pl-2 pr-2 btn-warning"><i class="fa fa-chevron-up"></i></button>
                                    <button @click="reOrder('down',index)" v-if="index < item.suppliers.length -1 " class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-chevron-down"></i></button>
                                    <button @click="remove(sup.id)" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-trash"></i></button>
                                </div>
                            </td>
                            <td v-else>
                                <button @click="processSupplier()"  class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-check"></i></button>
                                <button @click="closeSupplier(sup)"  class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-remove"></i></button>
                            </td>
                            <td>
                                <span>{{sup.name}}</span>
                                <!-- <Select2 v-else id="selectOrganization" v-model="sup.pivot.organization_id" :options="supplierOptions" /> -->
                            </td>
                            <td>
                                <span v-if="!sup.editting">{{sup.pivot.purchase_cost}}</span>
                                <input v-else type="text" class="form-control" v-model="sup.pivot.purchase_cost">
                            </td>
                            <td v-if="sup.editting">
                                <span v-if="!sup.editting">{{sup.pivot.purchase_currency}}</span>
                                <Select2 v-else id="selectCurrency" v-model="sup.pivot.purchase_price_currency_id" :options="currencyOptions" />
                            </td>
                            <td v-else>
                                <span v-html="getCurrency(sup.pivot.purchase_price_currency_id).sign"></span>
                            </td>
                            <td>
                                <span v-if="!sup.editting">{{sup.pivot.purchase_price}}</span>
                                <input v-else type="text" class="form-control" v-model="sup.pivot.purchase_price">
                            </td>
                            <td>
                                <span v-if="!sup.editting">{{sup.pivot.supplier_product_identifier}}</span>
                                <input v-else type="text" class="form-control" v-model="sup.pivot.supplier_product_identifier">
                            </td>
                            <td>
                                <span v-if="!sup.editting">{{sup.pivot.vat}}</span>
                                <input v-else type="text" class="form-control" v-model="sup.pivot.vat">
                            </td>
                            <td>
                                <span v-if="!sup.editting">{{sup.pivot.delivery_time}}</span>
                                <input v-else type="text" class="form-control" v-model="sup.pivot.delivery_time">
                            </td>
                            <td ><a @click="setRemark(sup.pivot)" href="#viewremarkModal" data-toggle='modal'>view remark</a></td>
                        </tr>
                </tbody>
            </table>

            <!-- ADD REMARKS -->
            <div class="modal" id="remarkModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">ADD REMARKS</h4>
                        </div>
                        <div class="modal-body">
                            <editor
                                v-model="product_organization.remarks"
                                :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                }"
                            />
                        </div>

                        <div class="form-group text-center">
                            <button @click="addSupplier()" class="btn btn-success">ADD</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- EDIT REMARKS -->
            <div class="modal" id="editRemarkModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">EDIT REMARKS</h4>
                        </div>
                        <div class="modal-body">  
                            <editor
                                v-model="remarks"
                                :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                }"
                                id="remarks2"
                            />
                        </div>

                        <div class="form-group text-center">
                            <button @click="updateSupplier()" class="btn btn-success">ADD</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- VIEW REMARKS -->
            <div class="modal" id="viewremarkModal">
                <div class="modal-dialog">
                    <div class="modal-content"> 
                        <div class="modal-header">
                            <h4 class="modal-title">{{viewRemarks.name}}</h4>
                        </div> 
                        <div class="modal-body">
                            <p v-html="viewRemarks.remarks"></p>
                        </div>
                        <div class="form-group text-center">
                            <button data-dismiss='modal' class="btn btn-danger">CLOSE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
</template>
<script>
export default {
    name:'suppliers',
    props:['item','product'],
    data(){
        return {
            allSuppliers:[],
            suppliers:[],
            currencies:[],
            supplier:{},
            currency:{},
            product_organization:{
                product_id      : this.item.id,
                organization_id : 1,
                purchase_cost   : 2.00,
                purchase_price   : 3.00,
                purchase_price_currency_id   : 35,
                supplier_product_identifier  : 'PRODUCTCODE LEVERANCIER',
                vat  : 21,
                delivery_time  : 2,
                remarks: 'OPMERKING',
            },
            viewRemarks:{name:'',remarks:''},
            edittingSupplier:false,
            editSupplierObj:{pivot:{remarks:''}},
            remarks:''
        }
    },
    computed: {
        currencyOptions () {
            var options = [{id:'',text:'Select Currency'}];
            var thas = this;
            this.currencies.forEach(function(item){
                let text = thas.decodeEntities(item.sign);
                options.push({id:item.id, text: text});
            });
            return options;
        },
        supplierOptions(){
            var options = [{id:'',text:'Select Supplier'}];
            this.allSuppliers.forEach(function(item){ 
                options.push({id:item.id, text: item.name});
            });
            return options;
        }
    },
    methods:{
        getCurrency(id){
            console.log(id);
            var temp = this.currencies.find((item)=> item.id == id);
            return temp ? temp : {};
        },
        processSupplier(){
            // this.editSupplierObj=data;
            console.log(this.remarks);
            window.$("#editRemarkModal").modal('show');
            setTimeout(()=>{
                window.$("#ders").css('display','block');
            },500);
        },
        updateSupplier(){  
            var data=this.editSupplierObj; 
            data.pivot.remarks=this.remarks;
            this.$store.dispatch('post',{
                details:data.pivot,
                endpoint:'/leffit/products/update-product-organization'
            })
            .then((data)=>{
                console.log(data);
            })
            .catch((error)=>{
                console.log(error);
            });
            this.closeSupplier(data);
            window.$("#editRemarkModal").modal('hide');
        },
        editSupplier(value){  
            var temp=this.item.suppliers;
            temp=temp.map((d)=>{
                if(d.id == value.id){
                    d.editting=true;
                }
                return d;
            });
            this.item.suppliers=temp;
            this.editSupplierObj=value; 
            this.remarks=value.pivot.remarks;
        },
        closeSupplier(value){ 
            var temp=this.item.suppliers;
            temp=temp.map((d)=>{
                if(d.id == value.id){
                    d.editting=false;
                }
                return d;
            });
            this.item.suppliers=temp; 
        },
        reOrder(direction,index){
            var data=this.item.suppliers;
            if(direction == 'up'){
                var temp=data[index];
                data[index]=data[index-1];
                data[index-1]=temp;
            }else if(direction == 'down'){
                temp=data[index];
                data[index]=data[index+1];
                data[index+1]=temp;
            }
            this.item.suppliers=JSON.parse(JSON.stringify(data)); 
        
            var payload=this.item.suppliers.map((d,index)=>{
                return {
                    index,
                    id:d.id
                };
            }); 
            console.log(payload);

            this.$store.dispatch('post',{
                details:payload,
                endpoint:'/leffit/products/reordersupplier/'+this.product
            })
            .then((data)=>{
                console.log(data);
            })
            .catch((error)=>{
                console.log(error);
            });

        },
        setRemark(value){
            console.log(value);
            this.viewRemarks=value;
        },
        addSupplier(){
            this.product_organization.product_id = this.item.id;
            
            console.log(this.product_organization);

            this.$store.dispatch('post',{endpoint : '/leffit/products/add-product-organization', details:this.product_organization})
            .then(data=>{
                console.log('result',data.data);
                // this.filters = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM ADDED",text:this.item.name+' was updated successfully',type:'success'});
                window.$("#remarkModal").modal('hide');
                this.$store.dispatch('get','/leffit/products/products/'+this.item.id)
                .then(data=>{
                    this.item = data.data.data;
                }); 
            });
        },
        remove(id){
            if(!window.confirm('Are you sure you want to remove this item')){
                return false;
            }
            
            this.item.suppliers=this.item.suppliers.filter(ft => ft.id != id);
            this.$store.dispatch('delete','/leffit/products/remove-productorganizations/'+this.item.id+'/'+id)
            .then(data=>{
                // console.log('result',data.data.data);
                // this.filters = data.data.data;
                this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});
            });
        },
        updateSelect2(){
            console.log('update select2');
            this.product_organization.organization_id = document.getElementById('selectOrganization').value;
            this.product_organization.purchase_price_currency_id = document.getElementById('selectCurrency').value;
        }
    },
    mounted(){ 
	    this.$store.dispatch('get','/leffit/crm/organizations/type/creditors')
        .then(data=>{
            console.log('suppliers', data.data.data);
			this.allSuppliers = data.data.data;
			// window.$('#selectOrganization').select2();
		});

        this.$store.dispatch('get','/lefficient/leffit/currencies/active')
        .then(data=>{
            this.currencies = data.data.data;
            // window.$('#selectCurrency').select2();
        }); 
	}
}

function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}
</script>
<style scoped>

</style>