<template>
	<div>
		<div class="head row p-0 m-0">
            <div v-show="isDisabled" class="col-12 p-0 m-0"><h3 class="pl-3">{{ item.name }}</h3></div>
            <div v-show="!isDisabled" class="col-12 p-0 m-0"><h3 class="pl-3">{{ $t('lefficient.system.edit_field', [item.name]) }}</h3></div>
        	<h4 v-show="isDisabled" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> {{ $t('lefficient.system.edit') }}</router-link></h4>
      	</div>
		<div v-show="isDisabled" class="row">
				<div class="col-sm-12 col-md-12"> 
					<div class="card basicDetailsCard">
						<h4>BASIC DETAILS</h4>
						<div id="">
							<div class="bsc bsc2">
								<b>Name:</b>
								<h6>{{item.name}}</h6>
							</div> 
						</div>
					</div> 
				</div> 
			</div>
		<form @submit="updateItem($event)"> 
			<div v-show="!isDisabled"> 
				<div class="card"> 
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label for="filter_title">{{ $t('leffit.products.product_type') }}</label>
                            <input type="text" :disabled="isDisabled" class="form-control" id="filter_title" v-model="item.name">
                        </div> 
                    </div>
  
					<div class="row p-0 m-0">
						<button class="btn btn-success">{{ $t('lefficient.system.save') }}</button>
					</div>
				</div>
			</div>	  
		</form>
		<div v-if="setting_ready" class="card mt-2">
			<tabsMeta :item="item" :tabs="group_tabs" :options="options" :package_name="'product_type_settings'"></tabsMeta>
		</div>
		<div class="row" v-if="setting_ready">
			<ul class="nav nav-pills nav-fill" style="border: 1px solid lightgray;padding: 6px;width: 99%;">
				<li v-if="settings.stock && item.name != 'leffit.products.product_types.digital'" class="nav-item">
					<a :class="tabsController.stock ? 'nav-link active':'nav-link'" href="#stock" @click="toggleTab('stock')">{{ $t('leffit.products.product.stock') }}</a>
				</li>
				<li v-if="settings.shipping" class="nav-item">
					<a :class="tabsController.shipping ? 'nav-link active':'nav-link'" href="#shipping" @click="toggleTab('shipping')">{{ $t('leffit.products.product.shipping') }}</a>
				</li>
				<li v-if="settings.combined" class="nav-item">
					<a :class="tabsController.combined ? 'nav-link active':'nav-link'" href="#combined" @click="toggleTab('combined')">{{ $t('leffit.products.product.combined') }}</a>
				</li>
				<li v-if="settings.default" class="nav-item">
					<a :class="tabsController.default ? 'nav-link active':'nav-link'" href="#default" @click="toggleTab('default')">{{ $t('leffit.products.product.default') }}</a>
				</li>
			</ul>

			<div class="col-12 p-0" >
				<div class="tab-content" id="myTabContent" style="width:99%;margin-top: 10px;background: #d3d3d340;">
					<div v-if="settings.stock && item.name != 'leffit.products.product_types.digital'" :class="tabsController.stock ? 'tab-pane fade show active':'tab-pane fade show'" id="stock" role="tabpanel" aria-labelledby="home-tab">
						<stock :settings="item_settings"></stock>
					</div>
					<div v-if="settings.shipping" :class="tabsController.shipping ? 'tab-pane fade show active':'tab-pane fade show'" id="shipping" role="tabpanel" aria-labelledby="home-tab">
						<shipping :settings="item_settings"></shipping>
					</div>
					<div v-if="settings.combined" :class="tabsController.combined ? 'tab-pane fade show active':'tab-pane fade show'" id="combined" role="tabpanel" aria-labelledby="home-tab">
						<!-- <stocks></stocks> -->
					</div>
					<div v-if="settings.default" :class="tabsController.default ? 'tab-pane fade show active':'tab-pane fade show'" id="default" role="tabpanel" aria-labelledby="home-tab">
						<defaultTab :settings="item_settings"></defaultTab>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	
</template>

<script>
import { mapState } from 'vuex';
import language from '../../../../data/language';
import stock from './layouts/stock';
import shipping from './layouts/shipping';
import defaultTab from './layouts/default';
import tabsMeta from '../../../components/tabs_meta';
import { error } from 'util';

export default {
	props:['id', 'edit'], 
	name: 'productFilter',
	components:{stock,shipping,defaultTab,tabsMeta},
  	data () {
	    return {
	    	item : {
                'name' 		: '', 
			},
			tabsController:{
				stock:true,
				shipping:false,
				combined:false,
				default:false,
			},
			settings:{},
			item_settings:{stock_mutation:true,shipping:'',default:''},
			language:language,
            profileImageSet:false,
			filters:[],
			options:[],
			types:[],
			group_tabs:[],
			setting_ready:false
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
  		isDisabled : function(){
  			return !(this.edit == 'edit')
  		}
	},  
	methods:{
		updateItem(e){
			if(e){
				e.preventDefault();
			}
			this.item.settings=this.item_settings;
			this.$store.dispatch('put',{endpoint:'/leffit/products/producttypes/'+this.item.id,details:this.item})
			.then(data=>{console.log('result',data.data); this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.name+' was updated successfully',type:'success'});}).catch((error)=>{console.log(error.request);});
		},
		toggleTab(field){
			this.tabsController={
				stock:false,
				shipping:false,
				combined:false,
				default:false,
			}
			this.tabsController[field]=true; 
		},
	},
  	mounted(){ 
	    this.$store.dispatch('get','/leffit/products/producttypes/'+this.id)
        .then(data=>{
        	console.log('result',data.data.data);
			this.item = data.data.data;  
			if(this.item.settings != null){
				var settings=JSON.parse(this.item.settings);
				// console.log(settings) ;
				this.item_settings={...this.item_settings,...settings};
			}
			// console.log(this.item_settings);
		}).catch((error)=>{
			console.log(error.request);
		});


		this.$store.dispatch('get', '/lefficient/leffit/packages/settings/product_type_settings')
        .then(data => {
            // console.log(data);
            if(data.data != null){
                if(data.data.setting != null){
					var settings=JSON.parse(data.data.setting);  
					settings.tabs.forEach(element => { 
						this.settings[element.field]=element.show;
					});
					// console.log(settings);
					// this.relateds=settings.related_types ? settings.related_types:[];
					this.options=settings.options ? JSON.parse(JSON.stringify(settings.options)):[]; 
					this.group_tabs=settings.group_tabs ? JSON.parse(JSON.stringify(settings.group_tabs)):[];
					this.setting_ready=true;
					// console.log(this.relateds);
                }
			}
        })
        .catch((error)=>{
            console.log(error);
        });
	}
}
</script>

<style lang="css" scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>