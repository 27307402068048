import Vue from "vue";
import App from "./App.vue";
import router from "./../router/index";
import store from "./../store/index";
import notifications from "../helpers/components/notifications";
import loader from "../helpers/components/loader";
import i18n from "./../lang/index";
import Select2 from "v-select2-component";
import vSelect from "vue-select";
import VueCollapse from "vue2-collapse";
import FontPicker from "font-picker-vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import { VuejsDatatableFactory } from "vuejs-datatable";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname != "/login") {
        window.location = "/login";
      }
    }
    return error;
  }
);

// change missing handler
// i18n.missing = (locale, key, vm, values) => {
// let item = {
// 'locale' : locale,
// 'key' : key,
// }
// store.dispatch('post',{endpoint:'/lefficient/leffit/translations',details:item})
// .then()
// .catch((error)=>{
//     console.log(error.request);
// });
// }

Vue.config.productionTip = false;
Vue.use(VuejsDatatableFactory);
// Bootstrap
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.component("notifications", notifications);
Vue.component("loader", loader);
Vue.use(VueCollapse);
Vue.use(FontPicker);
Vue.component("multiselect", Multiselect);
Vue.component("pagination", require("laravel-vue-pagination"));

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.component("Select2", Select2);
Vue.component("v-select", vSelect);
import Editor from "@tinymce/tinymce-vue";
Vue.component("Editor", Editor);

import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

// Vue.use(Datetime)
Vue.component("datetime", Datetime);

import VCalendar from "v-calendar";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "v", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.mixin({
  methods: {
    get_permissions() {
      // console.log('all_leffit_modules',this.$store.state.leffit.user.all_leffit_modules);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    decodeEntities(encodedString) {
      return encodedString.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
      });
    },
    user_has(type, name) {
      if (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.id !== 1 &&
        name === "settings"
      ) {
        return false;
      }

      if (type == "permission") {
        return this.$store.state.leffit.user.all_permissions.includes(name);
      } else if (type == "role") {
        let roles = this.$store.state.leffit.user.roles;
        let hasRole = false;
        if (roles.length > 0) {
          roles.forEach(function(role) {
            if (role.name.toLowerCase() == name.toLowerCase()) {
              hasRole = true;
            }
          });
        }
        return hasRole;
      } else if (type == "module") {
        if (
          this.$store.state.auth.user &&
          this.$store.state.auth.user.hasOwnProperty("all_leffit_modules")
        ) {
          return this.$store.state.auth.user.all_leffit_modules.includes(name);
        }
      }
      return false;
    },
    dymoPrint(data) {
      var labelXml = `<DieCutLabel Version="8.0" Units="twips">
          <PaperOrientation>Landscape</PaperOrientation>
          <Id>Address</Id>
          <PaperName>30252 Address</PaperName>
          <DrawCommands/>
          <ObjectInfo>
          <TextObject>
          <Name>Text</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>True</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText/>
          </TextObject>
          <Bounds X="332" Y="150" Width="4455" Height="1260" />
          </ObjectInfo>
          </DieCutLabel>`;

      // SET xml label definitions
      var label = dymo.label.framework.openLabelXml(labelXml);

      // Set what to print
      label.setObjectText("Text", data);

      // Get available printers
      var printers = dymo.label.framework.getPrinters();
      if (printers.length == 0)
        alert("No DYMO printers are installed. Install DYMO printers.");

      var printerName = "";
      for (var i = 0; i < printers.length; ++i) {
        var printer = printers[i];
        if (printer.printerType == "LabelWriterPrinter") {
          printerName = printer.name;
          break;
        }
      }

      //Trigger print
      label.print(printerName);
    },
  },
});

Vue.config.productionTip = false;
// store.state.leffit = {
//     module_id : false,
//   };
store.dispatch("auth/me").then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
