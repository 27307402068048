<template>
  <div>
    <div class="row">
      <div id="" class="col-10 upload-container">
        <input @change="upload()" type="file" class="d-none" id="file" />
        <label class="w-100 h-100" for="file">
          <div class="label text-center">
            <i class="fas fa-plus"></i>
            <h5 v-if="loading">UPLOADING</h5>
            <h5 v-else>UPLOAD SALES REPORT</h5>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async upload() {
      if (this.loading) return false;

      var file = document.getElementById("file").files[0];
      console.log(file);
      var formData = new FormData();
      formData.append("file", file);

      this.loading = true;

      var response = await this.$store.dispatch("post", {
        endpoint: "/sales-report/import",
        details: formData,
      });
      console.log(response);
      if (response.status == 200) {
        this.$store.dispatch("displayNotification", {
          type: "success",
          id: Math.random() * 100,
          message: "Sales Report was uploaded successfully.",
          interval: 500,
        });
      }
      this.loading = false;
    },
  },
  create() {},
};
</script>
<style scoped>
.label {
  position: absolute;
  height: fit-content;
  width: fit-content;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upload-container {
  border: 1px solid lightgray;
  height: 400px;
}
</style>
