<template>
    <div @click="enlarge()" :class="enlargeMode ? 'builder-active':''" style="position:relative;height:100vh"> 
        <font-picker class="d-none" :api-key="'AIzaSyBSqhPLj1EhSlhfNHWQKJjzOFmGXqSC0Cs'" :options="{}" ></font-picker>
        <div class="row tools">
            <div class="title col-12 col-sm-6 col-md-5">
                <a href='#' @click="closeEdit()" style="padding:0px 20px;color:white;font-size:20px" class="btn"><i class="fa fa-arrow-left"></i> </a>
                <div class="title">
                    <input @keyup="saveTitle($event)" id="edittitle" style="color:white;text-align:center;background: #404040;border: none;border-radius: 0;" v-if="this.editTitle" type="text"  class="form-control" v-model="newsletter.name" />
                    <span style="color:white;text-transform: capitalize;" @click="setTitleFocus()" v-if="!this.editTitle">{{this.newsletter.name}}</span>
                </div>
            </div>
            <div class="tols col-12 col-sm-6 col-md-7"> 
                <div class="tool"><a :href="previewUrl()" target="_blank" style="padding:5px 20px;color:white" class="btn"><i class="fa fa-eye mr-2"></i>  PREVIEW</a></div> &nbsp;
                <div class="tool"><button :disabled="!changesMade" @click="saveToServer()" style="padding:5px 20px;color:white" class="btn"><i class="fa fa-save"></i> SAVE</button></div> &nbsp;
                <div class="tool"><button  @click="closeEdit($event)" style="padding:5px 20px;color:white" class="btn btn-danger"><i class="fa fa-reverse"></i> CLOSE EDIT MODE</button></div>
            </div>
        </div>
        
        <div class="row m-0"> 
            <div style="width: 30.5%;">
                <div v-if="this.editMode && this.activeSection != null" class="editBoard">
                    <h4>EDITTING SECTION <b style="background:lightgray" class="pl-2 pr-2">{{this.activeSection.title}}</b></h4>
                    <div class="editFooter" style="display:flex;justify-content:space-between">
                        <button @click="saveChanges()" class="btn btn-success"><i class="fa fa-save"></i> Update Section</button>
                        <button id="button" @click="reverseChanges()" class="btn btn-danger ml-3"><i class="fa fa-refresh"></i> Reverse Changes</button>
                    </div>
                    <div class="editBody">
                        <div v-for="(field,index) in this.activeSection.fields" :key="index" class="fields">
                            <!-- <label for="">{{field.label}}</label> -->
                            <div v-if="field.type == 'image'" class="uploadImage mt-2"> 
                                <img :src="field.content" width="auto"   style="height:150px" alt="">
                                <input class="d-none" type="file" name="" @change="readFile(field.element)" :id="field.element">
                                <span @click="triggerFileInput(field.element)" v-if="field.content == ''"><i class="fa fa-plus"></i></span>
                                <span @click="removeImage(field)" v-if="field.content != ''"><i class="fa fa-remove text-danger"></i></span>
                            </div>
                            
                            <div v-if="field.type == 'embed-video' || field.type == 'text' || field.type == 'headerText'"  class="textEditor form-group">
                                <label for="">
                                    <span>{{field.label}}</span>
                                    <a v-if="field.styles != undefined  && field.label != 'EMBED IFRAME INPUT' && field.label != 'URL' && field.label != 'ALT' && field.label != 'IMAGE TITLE'" href="#" @click="toggleOpenSetting(field,true)"><i class="fas fa-cog"></i></a>
                                </label>
                                <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" />
                                <!-- TEXT SETTINGS -->
                                    <div v-if="field.styles != undefined && field.label != 'URL' && field.openSetting" class="text-settings-editor">
                                        <button @click="toggleOpenSetting(field,false)" class="btn btn-sm close-btn-setting mb-3 d-block ml-auto">close</button>
                                        <div  class="form-group m-0 pl-0 pr-0 col-12 ">
                                            <label for="">FONT SIZE (PX)</label>
                                            <input @change="updateBlockStyle()" type="number" placeholder="25px" class="form-control" v-model="field.styles.size">
                                        </div>
                                        <div  class="form-group m-0 pl-0 pr-0 col-12 ">
                                            <label for="">FONT COLOR</label>
                                            <input @change="updateBlockStyle()" type="color" class="form-control" v-model="field.styles.color">
                                        </div>
                                        <div   class="form-group m-0 pl-0 pr-0 col-12 ">
                                            <label for="">FONT FAMILY</label> 
                                            <div class="col-12">
                                                <font-picker v-model="field.styles.family"  :api-key="'AIzaSyBSqhPLj1EhSlhfNHWQKJjzOFmGXqSC0Cs'" :options="{}" :active-font="field.styles.family" @change="(e)=>{field.styles.family=e.family;updateBlockStyle()}" ></font-picker>
                                            </div>
                                        </div>
                                        <div  class="form-group m-0 pl-0 pr-0 col-12 ">
                                            <label for="">TEXT ALIGN</label> 
                                            <select @change="updateBlockStyle()" class="form-control" v-model="field.styles.align" id="">
                                                <option value="left">Left</option>
                                                <option value="center">Center</option>
                                                <option value="right">Right</option>
                                            </select>
                                        </div>
                                    </div>
                                <!--  -->
                            </div>
                            <div v-if="field.type == 'class'" class="textEditor form-group">
                                <label for="">{{field.label}}</label>
                                <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" />
                            </div>
                            <div v-if="field.type == 'height'" class="textEditor form-group">
                                <label for="">{{field.label}}</label>
                                <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" />
                            </div>

                            <div v-if=" field.type == 'buttonText' || field.type =='buttonlink' || field.type =='linkText' "  class="textEditor form-group">
                                <label for="">{{field.label}}</label>
                                <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" />
                                
                            </div>
                            
                            <div v-if="field.type == 'link' " class="textEditor form-group">
                                <label for="">{{field.label}}</label>
                                <input autocomplete="false" type="text" class="form-control" :id="field.element" v-model="field.content" />
                            </div>

                            <div v-if="field.type == 'bodyText' || field.type == 'WYSIWYG'" class="textEditor">
                                <label for="">{{field.label}}</label>
                                <editor
                                    v-model="field.content"
                                    :init="{
                                        height: 400,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                    }"
                                />
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
            <div :style="this.editMode && this.activeSection != null ? 'width: 66%;margin-right:34px':'width:100%'">
                <div id="templateHolder" :class="this.editMode ? 'templateHolder edit-mode':'templateHolder'">  
                    <section class="" id="app">
                        <section class="row p-0 m-0"> 
                            <div class="col-sm-12 col-md-12 col-lg-12 holder mb-5">  
                                <!--  EACH ROW -->
                                <div 
                                    v-for="(row,index) in sections"
                                    :key="index"
                                    :class="'row m-0 each-row '+processRowAttr(row.settings)[0]"
                                    :style="' '+processRowAttr(row.settings)[1]"
                                    > 
                                    <!-- RESIZER -->
                                    <div class="resizer" v-if="resizer.row == index" @mousemove="mouseMove($event)" @mouseout="mouseUp()" @mouseup="mouseUp()"></div>

                                    <!-- EACH COLUMN -->
                                    <div :class="section.classes.join(' ')+' column'" v-for="(section,index2) in row.sections" :key="index2">
                                        <div @click="edittingMode(row,section.unique_id,index)" :id="'section'+section.unique_id"  :class="section.mode == 'edit' || resizer.id == section.unique_id? 'temp editting':'temp'" >
                                            <div class="sectionTools">
                                                <button @click="displaySettingsModal($event,section)" class="btn"><i class="fa fa-cog"></i></button>
                                                <button v-if="sections.length > 1 && index != 0" title="shift section upward" @click="up($event,section,index)" class="btn"><i class="fa fa-chevron-up"></i></button>
                                                <button v-if="sections.length > 1 && index != sections.length-1" title="shift section downward" @click="down($event,section,index)" class="btn"><i class="fa fa-chevron-down"></i></button>
                                                <button @click="deleteSection($event,section,index)" class="btn"><i class="fa fa-trash"></i></button>
                                            </div> 
                                            <div class="nav-width"> 
                                                <button @mousedown="mouseDown($event,section.settings.container.column ,index,section.unique_id)" @mouseup="mouseUp()"  class="btn-right"><i class="fas fa-arrows-alt-h"></i></button>
                                            </div>

                                            <span v-html="section.html"> </span> 
                                        </div>
                                    </div> 
                                    <div class="row-tools">
                                        <button @click="$store.state.leffit.activeRow=index;openModal()" class="btn mr-2"><i class="fas fa-plus"></i></button>
                                        <button @click="displaySettingsModal($event,row)" class="btn mr-2"><i class="fas fa-cog"></i></button>
                                        <button @click="removeRow($event,row)" class="btn mr-2"><i class="fas fa-trash"></i></button>
                                    </div> 
                                </div> 
                            </div>
                        </section>
                    </section>
                    <div @click="add_new_row()" class="defaultTemp">
                        <button  class="btn"><i class="fa fa-plus"></i></button>
                        <br> <br>
                        <p>ADD NEW ROW</p>
                    </div> 
                </div>  
            </div> 
        </div>

        <div class="modal" id="edittingSection">
            <div class="modal-dialog modal-lg">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h4 class="modal-title">EDIT SECTION SETTINGS</h4> 
                    </div> 
                    <div class="modal-body">
                        <div class="row m-0">
                            <div class="col-12 d-flex" style="width: 100%;justify-content: space-between;">
                                <button @click="saveSectionSettings()" class="btn btn-success">SAVE</button>
                                <button @click="closeEditSection()" class="btn btn-danger btn-sm">CANCEL</button>
                            </div>
                        </div>
                        <fieldset>
                            <legend>Container </legend>
                            <div class="row m-0">
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Type</label>
                                        <select v-model="edit_setting_section.settings.container.type" class="form-control">
                                            <option value="boxed">Boxed</option>
                                            <option value="fluid">Fluid</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Align</label>
                                        <select v-model="edit_setting_section.settings.container.align" class="form-control">
                                            <option value="left">Left</option>
                                            <option value="center">Center</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Text Align</label>
                                        <select v-model="edit_setting_section.settings.container.align_text" class="form-control">
                                            <option value="left">Left</option>
                                            <option value="center">Center</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Margin</legend>
                            <div class="row m-0">
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Top</label>
                                        <select v-model="edit_setting_section.settings.margin.top" class="form-control" id=""> <option v-for="(mt,index) in margins_top" :key="index" :value="mt">{{mt}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Right</label>
                                        <select v-model="edit_setting_section.settings.margin.right" class="form-control" id=""> <option v-for="(mt,index) in margins_right" :key="index" :value="mt">{{mt}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Bottom</label>
                                        <select v-model="edit_setting_section.settings.margin.bottom" class="form-control" id=""> <option v-for="(mt,index) in margins_bottom" :key="index" :value="mt">{{mt}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Left</label>
                                        <select v-model="edit_setting_section.settings.margin.left" class="form-control" id=""> <option v-for="(mt,index) in margins_left" :key="index" :value="mt">{{mt}}</option> </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Padding</legend>
                            <div class="row m-0">
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Top</label>
                                        <select v-model="edit_setting_section.settings.padding.top" class="form-control" id=""><option v-for="(pd,index) in paddings_top" :key="index" :value="pd">{{pd}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Right</label>
                                        <select v-model="edit_setting_section.settings.padding.right" class="form-control" id=""> <option v-for="(pd,index) in paddings_right" :key="index" :value="pd">{{pd}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Bottom</label>
                                        <select v-model="edit_setting_section.settings.padding.bottom" class="form-control" id=""> <option v-for="(pd,index) in paddings_bottom" :key="index" :value="pd">{{pd}}</option> </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mr-4">
                                    <div class="form-group">
                                        <label for="">Left</label>
                                        <select v-model="edit_setting_section.settings.padding.left" class="form-control" id=""> <option v-for="(pd,index) in paddings_left" :key="index" :value="pd">{{pd}}</option> </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Background</legend>
                            <div class="row m-0">
                                <div class="col-6 col-sm-9 col-md-10"  :style="edit_setting_section.settings.background">
                                    <img :src="backgroundImage" class="center-image" alt="">
                                </div>
                                <div class="col-6 col-sm-3 col-md-2 text-right">
                                    <input id="colorPicker" type="color" class="d-none" v-model="edit_setting_section.settings.background.backgroundColor" >
                                    <input type="file" @change="readFile2()" class="d-none" id="image-file" />
                                    <button @click="pickColor()" class="btn">Pick Color</button>
                                    <button @click="triggerImage()" v-if="!uploaded_setting_image" class="btn">Pick Image</button>
                                    <button @click="saveBackgroundImage()" v-else class="btn btn-success">Save Image</button>
                                    <button @click="removeSettingBg()" v-if="edit_setting_section.settings.background.backgroundImage" class="btn btn-danger">Remove Image</button>
                                </div> 
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="">Background Size</label>
                                        <select v-model="edit_setting_section.settings.background.size" class="form-control" id="">
                                            <option value="cover">Cover</option>
                                            <option value="contain">Contain</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="">Background Position</label>
                                        <select v-model="edit_setting_section.settings.background.position" class="form-control" id="">
                                            <option value="top">Top</option>
                                            <option value="left">Left</option>
                                            <option value="bottom">Bottom</option>
                                            <option value="right">Right</option>
                                            <option value="center">Center</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Font</legend>
                            <div class="row m-0"> 
                                <div class="form-group col-3">
                                    <label for="">Font size</label>
                                    <input type="number" v-model="edit_setting_section.settings.font.size" class="form-control">
                                </div>
                                <div class="form-group col-3 ml-3">
                                    <label for="">Font color</label> 
                                    <input type="color" v-model="edit_setting_section.settings.font.color" class="form-control">
                                </div>
                            </div>
                        </fieldset> 
                    </div>
                    <div class="modal-footer">
                        
                    </div>
                </div>
            </div>
        </div>
        <mailTemplates></mailTemplates>
        <preview v-if="preview_show && loaded" :items="sections" />
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import mailTemplates from "./templates"
    import FontPicker from 'font-picker-vue';
    import preview from './preview';
    export default {
        name:'create_template',
        components:{
            mailTemplates,
            'font-picker':FontPicker,
            'preview':preview
        },
        props:['get_url','media_url'],
        computed:{
            ...mapState({
                newsletter:state=>state.leffit.template,
                sections:state=>state.leffit.sections,
                activeSection:state=>state.leffit.activeSection,
                changesMade:state=>state.leffit.changesMade,
            })
        },
        data(){
            return {
                editMode:false,
                enlargeMode:false,
                editBackup:null,
                activeIndex:0,
                updatedImages:[],
                editTitle:false,
                uploadings:[],
                default_settings:{
                    margin:{top:'',right:'',left:'',bottom:''},
                    padding:{top:'',right:'',left:'',bottom:''},
                    background:{backgroundColor:'transparent','backgroundImage':'','position':'','size':''},
                    font:{color:'black',size:''},
                    container:{type:'boxed',column:3,align:'left'},
                },
                edit_setting_section:{settings:{}},
                margins_top:['','mt-1','mt-2','mt-3','mt-4','mt-5'],
                margins_bottom:['','mb-1','mb-2','mb-3','mb-4','mb-5'],
                margins_right:['','mr-1','mr-2','mr-3','mr-4','mr-5'],
                margins_left:['','ml-1','ml-2','ml-3','ml-4','ml-5'],
                paddings_top:['','pt-1','pt-2','pt-3','pt-4','pt-5'],
                paddings_bottom:['','pb-1','pb-2','pb-3','pb-4','pb-5'],
                paddings_right:['','pr-1','pr-2','pr-3','pr-4','pr-5'],
                paddings_left:['','pl-1','pl-2','pl-3','pl-4','pl-5'],
                backgroundImage:null,
                uploaded_setting_image:false,
                mouse_down:false,
                xAxis:0,resizer:{col:0,row:null,id:0},
                distance_drag:0,
                preview_show:false,loaded:false
            }
        }, 
        mounted(){ 
          
        },
        methods:{
            previewUrl(){
                var path = '/preview';
                if (window.location.href.indexOf("modules") > -1) {
                    path += '/module/' + this.$route.params.id;
                }
                if (window.location.href.indexOf("programs") > -1) {
                    path += '/program/' + this.$route.params.id;
                }
                if (window.location.href.indexOf("lessons") > -1) {
                    path += '/lesson/' + this.$route.params.id;
                }
                
                return process.env.VUE_APP_FRONTEND_URL + path;
            },
            toggleOpenSetting(field,value){
                console.log('OpenSettings', field);
                var temp=this.activeSection;
                temp.fields=temp.fields.map((d)=>{
                    d.openSetting=false;
                    if(d.element == field.element){
                        d.openSetting=value;
                    }  
                    return d;
                });
                this.$store.state.leffit.activeSection=null; 
                this.$store.state.leffit.activeSection=temp; 
            },
            removeRow(e,row){
                this.$store.state.leffit.sections=this.sections.filter((d)=> d.unique_id != row.unique_id);
                this.saveToServer();
            }, 
            enlarge(){
                this.enlargeMode=true;
            },
            mouseDown(e,col,row,id){ 
                this.mouse_down=true;
                this.xAxis=e.clientX;
                this.resizer.col=col;
                this.resizer.row=row;
                this.resizer.id=id;
            },
            mouseUp(){
                this.mouse_down=false;
                this.resizer.col=0;
                this.resizer.row=null;
                this.resizer.id=0;
            },
            mouseMove(e){
                var col=this.resizer.col;
                var row=this.resizer.row;
                var id=this.resizer.id;
                
                
                if(this.mouse_down){ 
                    if(this.distance_drag >= 15){
                        if(this.xAxis < e.clientX){ 
                            this.xAxis=e.clientX;
                            this.changeWidth(e,'+',col+1,row,id); 
                        }else if(this.xAxis > e.clientX){
                            this.xAxis=e.clientX;
                            this.changeWidth(e,'-',col-1,row,id);
                        }
                        this.distance_drag=0;
                        // console.log('initialised drag');
                    }
                    this.distance_drag=this.distance_drag+1;
                    // console.log(this.distance_drag);
                }
            },
            changeWidth(e,op,col,row,id){
                e.stopPropagation(); 
                if(op == '+' && col > 12){
                    col=12;
                }
                if(op == '-' && col < 1){
                    col=1;
                }

                let sections=this.sections; 
                let selected_row=JSON.parse(JSON.stringify(sections[row]));
                selected_row.sections=selected_row.sections.map((d)=>{ 
                    if(d.unique_id == id){ 
                        d.settings.container.column=col; 
                    }  
                    return d;
                });
                sections[row]=selected_row;
                this.resizer.col=col;
                // this.$store.state.leffit.sections=[];
                // this.$store.state.leffit.sections=sections;
                
                this.bindValues(sections);
                this.$store.state.leffit.changesMade=true;
                
            },
            add_new_row(){
                this.$store.state.leffit.sections.push({sections:[],settings:this.default_settings,unique_id:Math.floor(Math.random()*9999999)});
                this.bindValues(this.sections); 
            },
            closeEdit(e){
                e.stopPropagation();
                
                this.editMode=false;
                this.enlargeMode=false 
            },
            removeSettingBg(){
                this.edit_setting_section.settings.background.backgroundImage='';
                this.backgroundImage='';
            },
            saveBackgroundImage(){
                this.uploadings.push(true);
                this.$store.dispatch('post',{
                    details:{image:this.backgroundImage},
                    endpoint:this.media_url+this.$route.params.id
                })
                .then((data)=>{
                    console.log(data); 
                    this.edit_setting_section.settings.background.backgroundImage=data.data;
                    this.backgroundImage=data.data;
                })
                .catch((error)=>{
                    console.log(error.request); 
                });
            },
            triggerImage(){
                document.getElementById('image-file').click();
            },
            pickColor(){
                document.getElementById('colorPicker').click();
            },
            readFile2(){
                var file=document.getElementById('image-file').files[0];
                
                let formats=['image/png','image/jpg','image/jpeg','image/PNG'];
                if(!formats.find((ft=> ft == file.type))){ alert('file format not supported'); return false; }

                var fileReader=new FileReader();
                fileReader.onload=(result)=>{
                    this.uploaded_setting_image=true;
                    this.backgroundImage=result.srcElement.result;
                };
                fileReader.readAsDataURL(file); 
            },
            saveSectionSettings(){  

                if(this.edit_setting_section.fields != undefined){ //its a columns
                    this.$store.state.leffit.sections=this.sections.map((dt)=>{
                        dt.sections=dt.sections.map((d)=>{
                            if(d.unique_id == this.edit_setting_section.unique_id){ 
                                return this.edit_setting_section;
                            } 
                            return d;
                        });
                        return dt;
                    });
                }else{ //its a row
                    this.$store.state.leffit.sections=this.sections.map((dt)=>{
                        console.log('row',dt);
                        if(dt.unique_id == this.edit_setting_section.unique_id){ 
                            return this.edit_setting_section;
                        } 
                        return dt;
                    });
                }
               
                
                this.saveToServer();
                this.closeEditSection();
            },
            displaySettingsModal(e,section){
                console.log(section);
                e.stopPropagation();
                window.$("#edittingSection").modal('show'); 

                if(section.settings == undefined){
                    section.settings=this.default_settings;
                }
                section.settings={...this.default_settings,...section.settings};
                this.edit_setting_section=section;
                this.backgroundImage=section.settings.background.backgroundImage;
            }, 
            closeEditSection(){
                window.$("#edittingSection").modal('hide');
            },
            up(e,section,index){
                e.stopPropagation();
                
                let temp=JSON.stringify(this.sections);
                temp=JSON.parse(temp);
                let tmp=temp[index-1]; 
                temp.splice(index-1,1);
                let temp2=[];
                
 
                for(var count=0;count<this.sections.length;count++){
                    // console.log(count);
                    if(count == index){  
                        temp2.push(tmp);
                    }
                    if(temp[count] != undefined){
                        temp2.push(temp[count]);
                    }
                }
                

                this.$store.state.leffit.sections=temp2;
                this.$store.state.leffit.changesMade=true;
            },
            down(e,section,index){
                e.stopPropagation();
                index+=1;
                let temp=JSON.stringify(this.sections);
                temp=JSON.parse(temp);
                let tmp=temp[index-1]; 
                temp.splice(index-1,1);
                let temp2=[];
                
 
                for(var count=0;count<this.sections.length;count++){
                    // console.log(count);
                    if(count == index){  
                        temp2.push(tmp);
                    }
                    if(temp[count] != undefined){
                        temp2.push(temp[count]);
                    }
                }
                

                this.$store.state.leffit.sections=temp2;
                this.$store.state.leffit.changesMade=true;
            },
            saveTitle(e){
                if(e.button == 13 || e.which == 13){
                    let value=document.getElementById('edittitle').value;
                    this.$store.state.leffit.template.name=value;
                    this.editTitle=false;
                    this.saveToServer();
                }else{
                    this.$store.state.leffit.changesMade=true;
                }
            },
            setTitleFocus(){
                this.editTitle=true
                setTimeout(()=>{
                    document.getElementById('edittitle').focus();
                },200);
            },
            saveToServer(){
                console.log('saveToServer', this.sections)

                let template=this.newsletter;
                let sections=JSON.stringify(this.sections);
                template.content=sections;

                this.$store.state.leffit.loader=true;
                // console.log(template); 
                this.$store.dispatch('put',{endpoint:this.get_url+template.id,details:template})
                .then(data=>{
                    console.log(data);
                    this.$store.state.leffit.loader=false;
                    this.$store.state.leffit.changesMade=false;
                    this.$store.dispatch('makeNotification',{title:"UPDATE",text:'Section was updated',type:'success'});
                })
                .catch(error=>{
                    this.$store.state.leffit.formErrors.push('Server error!!');
                    console.log(error.request);
                    this.$store.state.leffit.loader=false;
                });
                this.bindValues(this.sections);
            },
            updateSection(id){ 
                let element=document.getElementById(id);
                if(!element){
                    return false;
                }
                
                this.$store.state.leffit.sections=this.$store.state.leffit.sections.map((dt)=>{ 
                    
                    if(dt.unique_id == this.activeIndex){ 
                        dt.fields=dt.fields.map((dd)=>{
                            this.$store.state.leffit.changesMade=true;
                            if(dd.element == id){dd.content=element.value;} 
                            return dd;
                        });
                    } 
                    return dt;
                });
                this.bindValues(this.sections);
  
            },
            updateSectionValue(field_el,value){  
                var row=this.activeSection.row;
                var sections=this.$store.state.leffit.sections;
                sections[row].sections=this.sections[row].sections.map((dt)=>{ 
                    if(this.activeSection.unique_id == dt.unique_id){
                        dt.fields=dt.fields.map((dd)=>{
                            if(dd.element == field_el){this.$store.state.leffit.changesMade=true; dd.content=value;} 
                            return dd;
                        });
                    } 
                    return dt;
                });
                this.$store.state.leffit.sections=sections;
            },
            triggerFileInput(element){
                let el=document.getElementById(element);
                if(el){
                    el.click();
                }
            },
            readFile(element){
                let file=document.getElementById(element);
                if(file !=null && file.files.length <= 0){
                    return false;
                }

                
                let formats=['image/jpeg','image/jpg','image/JPEG','image/JPG','image/PNG','image/png'];
                if(!formats.find((ft=> ft == file.files[0].type))){ alert('file format not supported'); return false; }
                
                let fileReader=new FileReader();

                fileReader.onload=(result)=>{
                    this.updatedImages.push({
                        field:this.activeSection.id,element,value:file.files[0]
                    });
                    this.uploadMediaImages(element,result.srcElement.result);
                }

                fileReader.readAsDataURL(file.files[0]);

                
            },
            uploadMediaImages(element,data){
                this.uploadings.push(true);
                this.$store.dispatch('post',{
                    details:{image:data},
                    endpoint:this.media_url+this.$route.params.id
                })
                .then((data)=>{
                    console.log(data);
                    this.updateSectionValue(element,data.data);
                    this.uploadings.pop(); 
                })
                .catch((error)=>{
                    console.log(error.request);
                    this.uploadings.unshift();
                });
            },
            removeImage(field){ 
                this.$store.state.leffit.changesMade=true;
                this.updateSectionValue(field.element,''); 

                this.updatedImages=this.updatedImages.filter(data=> data.element != field.element);
 
            },
            openModal(){
                this.$store.state.leffit.changesMade=true;
                this.$store.state.leffit.templateModal=true;
            },
            updateBlockStyle(){
                var style_data={align:'text-align',color:'color',family:'font-family',size:'font-size'};
                var dt=this.activeSection;
                var domParser=new DOMParser();
                var html=domParser.parseFromString(dt.content,"text/html"); 
                var body='';

                // console.log('STYLE',html);

                //this code will repair any existing section without ID
                dt.fields.forEach(field => {
                    var ps=html.getElementsByTagName('p');
                    var h1=html.getElementsByTagName('h1');
                    var h2=html.getElementsByTagName('h2');
                    var h3=html.getElementsByTagName('h3');
                    var h4=html.getElementsByTagName('h4');
                    var h5=html.getElementsByTagName('h5');
                    var h6=html.getElementsByTagName('h6');

                    var els=[...ps,...h1,...h2,...h3,...h4,...h5,...h6];

                    for(var c=0;c<els.length;c++){ 
                        if(!window.$(els[c]).attr('id')){
                            window.$(els[c]).attr('id',field.element);
                        }
                    }
                    body=html.body; 
                    dt.content=window.$(body).html();
                    dt.html=dt.content;
                }); 
                //-----------------------------------------

                html=domParser.parseFromString(dt.content,"text/html"); 
                dt.fields.forEach(field => {
                    var el=html.getElementById(field.element);
                    if(el){ 
                        for(var style in field.styles){
                            if(style_data[style]){
                                if(field.styles[style]){
                                    el.style[style_data[style]]=field.styles[style];
                                }
                            }
                        } 
                    } 
                    body=html.body; 
                    dt.content=window.$(body).html();

                    // fontsize px => rem
                        [...dt.content.matchAll(new RegExp('font-size', 'gi'))]
                        .map(a => a.index)
                        .forEach(function(start_index){

                            // get substring font-size
                            var substr =  dt.content.substr(start_index, dt.content.substr(start_index).indexOf(';'));
                            // replace substr
                            var substr_rem_replace = dt.content.substr(start_index, dt.content.substr(start_index).indexOf(';')).replace('px','rem');

                            // console.log('dt.content before',dt.content);
                            // console.log('substr',substr);
                            // console.log('substr_rem_replace',substr_rem_replace);
                            
                            // write back
                            dt.content = dt.content.replace(substr, substr_rem_replace);

                            // console.log('dt.content after',dt.content);
                        });

                    dt.html=dt.content;
                    
                    dt.fields.forEach((fd)=>{
                        dt.html=dt.html.replace("++{{"+fd.element+"}}",fd.content);
                    });
                    
                    
                });  
                // console.log(dt);
                
                
                this.updateSectionView(dt);
                this.$store.state.leffit.activeSection=dt;
                
                
            },
            updateSectionView(section){
                let sections=this.sections;
                var row=this.activeSection.row;
                sections[row].sections=this.sections[row].sections.map((dt)=>{   
                    if(dt.unique_id == this.activeSection.unique_id){  
                        return section;
                    }
                     
                    return dt; 
                });  
                this.$store.state.leffit.sections=[];
                this.$store.state.leffit.sections=sections;
            },
            edittingMode(section,unique_id,row){ 
                console.log(section);
                var style_edittable_fields=['text',"buttonText","headerText"]; 
                
                this.editMode=true; 
                this.editBackup=JSON.stringify(this.$store.state.leffit.sections);

                let sections=this.sections;
                sections[row].sections=this.sections[row].sections.map((dt)=>{  
                    dt.mode='initial';
                    if(dt.unique_id == unique_id){ 
                        dt.mode='edit';
                        section=dt; 
                        this.activeIndex=dt.unique_id; 
                        dt.fields=dt.fields.map((field)=>{
                            if(!field.styles){ 
                                if(style_edittable_fields.find((type)=> type == field.type) ){
                                    field.styles={size:'20',color:'black',font:'',align:'left'};
                                }
                            }
                            return field;
                        }); 
                    }
                     
                    return dt; 
                });
                section.row=row;
                this.$store.state.leffit.sections=sections; 
                this.$store.state.leffit.activeSection=section; 

                window.$("#templateHolder").animate({
                    scrollTop:window.$("#section"+unique_id).offset().top
                });

                
            },
            deleteSection(e,section,row){
                e.stopPropagation();
                e.eventBubble=false;

                if(!confirm("Are you sure you want to delete")){
                    return false;
                }

                this.editMode=true; 
                    
                let sections=this.sections;
                sections[row].sections=this.sections[row].sections.filter((dt)=> dt.unique_id != section.unique_id);

                this.$store.state.leffit.sections=sections;
                this.$store.state.leffit.activeSection=null;
                this.editMode=false;
                this.$store.state.leffit.changesMade=true;
                
            },
            reverseChanges(){ 
                this.$store.state.leffit.sections=JSON.parse(this.editBackup);

                let section=this.sections.find((dt)=>this.activeIndex == dt.unique_id);
                section.mode='edit';
                this.$store.state.leffit.activeSection=section;
                this.$store.state.leffit.changesMade=false;

            },
            saveChanges(){
                this.editMode=false;
                let sections=this.sections.map((dt)=>{ 
                    dt.mode='initial';
                    return dt;
                });

                this.$store.state.leffit.sections=sections;

                //if a new image was uploaded
                // if(this.updatedImages.length > 0){
                //     this.$store.state.leffit.loader=true;
                //     let count=0;
                    
                //     this.updatedImages.map((dt,index)=>{
                //         let formData=new FormData();
                //         formData.append('file',dt.value); 
                //         this.$store.dispatch('post',{endpoint:'leffit/courses/upload-lesson-image/'+this.newsletter.id,details:formData})
                //         .then(data=>{
                //             console.log(data);
                //             this.updateSectionValue(dt.element,data.data);
                //             count+=1;
                //             if(count == this.updatedImages.length){
                //                 this.$store.state.leffit.loader=false; 
                //             }
                //         })
                //         .catch(error=>{
                //             this.$store.state.leffit.formErrors.push('Server error!!');
                //             console.log(error.request);
                //             this.updateSectionValue(dt.element,'');
                //             count+=1;
                //             if(count == this.updatedImages.length){
                //                 this.$store.state.leffit.loader=false; 
                //             }
                //         });
                //     }); 
                // }

                this.$store.state.leffit.changesMade=true;
                this.saveToServer();
            },
            bindValues(rows){
                rows=rows.map((row)=>{
                    // console.log(row.sections);
                    if(row.sections == undefined){
                        var temp=row;
                        row={sections:[],settings:{}};
                        row.sections=temp;
                        row.settings={...this.default_settings};
                        row.unique_id=Math.floor(Math.random()*9999999);
                    } 
                    row.sections=row.sections.map((section)=>{ 
                        var content=section.content;
                        section.fields.map((field)=>{
                            content=content.replace('++{{'+field.element+'}}',field.content); 
                        }); 
                        section.settings={...this.default_settings,...section.settings};
                        section.classes=[];
                        section.html=this.appendPropsToElement(content,section.settings);
                        section.mode='initial';

                        //
                        if(section.settings.container.column){
                            section.classes.push('col-md-'+section.settings.container.column);
                        }

                        //alignment type
                        if(section.settings.container.align == 'right'){ 
                            section.classes.push("ml-auto");
                        }else if(section.settings.container.align == 'center'){ 
                            section.classes.push("m-auto");
                        }else{
                            section.classes.push("mr-auto");
                        }

                        //alignment text-type
                        if(section.settings.container.align_text == 'right'){ 
                            section.classes.push("text-right");
                        }else if(section.settings.container.align_text == 'center'){ 
                            section.classes.push("text-center");
                        }else{
                            section.classes.push("text-left");
                        }

                        return section;
                    });
                    return row;
                });
                // console.log(rows);
                this.$store.state.leffit.sections=rows;
            },
            appendPropsToElement(element,settings){ 
                if(settings == undefined){
                    return element;
                }
                var domParser=new DOMParser();
                var html=domParser.parseFromString(element,"text/html");
                var body=html.body;
                var first=body.children[0]; 
                
                window.$(first).removeClass('bg-white');
                //attaching setting to the element
                    //margins
                    for(var p in settings.padding){window.$(first).addClass(settings.padding[p]);}
                   //paddings
                    for(var m in settings.margin){ window.$(first).addClass(settings.margin[m]);}      
                    //background
                    window.$(first).css('background-color',settings.background.backgroundColor);
                    window.$(first).css('background-image','url('+settings.background.backgroundImage+')');
                    window.$(first).css('background-size',settings.background.size);
                    window.$(first).css('background-position',settings.background.position);
                    window.$(first).css('color',settings.font.color);

                    if(settings.font.size != ''){
                        window.$(first).css('font-size',settings.font.size);
                    }
                    if(settings.container){
                        //container type 
                        if(settings.container.type == 'fluid'){ 
                            window.$(first).removeClass('container');
                        }else{
                            window.$(first).addClass('container');
                        }
                        

                    }

                    
 
                return window.$(body).html();
            },
            processRowAttr(settings){
                var classes="";
                var styles="";
                //margins
                for(var p in settings.padding){classes+=settings.padding[p]+" "}
                //paddings
                for(var m in settings.margin){ classes+=settings.margin[m]+" "}

                // container
                if(settings.container.type == 'boxed'){
                    classes+="container ";
                }



                if(settings.background.backgroundImage != ''){
                    styles+="background-image:url("+settings.background.backgroundImage+");"
                }
                if(settings.background.backgroundColor != ''){
                    styles+="background-color:"+settings.background.backgroundColor+";";
                }
                if(settings.background.position != ''){
                    styles+="background-position:"+settings.background.position+";";
                }
                if(settings.background.size != ''){
                    styles+="background-size:"+settings.background.size+";";
                }
                if(settings.font.size != ''){
                    styles+="font-size:"+settings.font.size+";";
                }
                if(settings.font.color != ''){
                    styles+="color:"+settings.font.color+";";
                }

                return [classes,styles];
            },
            repairData(data){ 
                if(data.sections != undefined){
                    return data;
                }
                if(data.length == 0){
                    return [];
                }
                if(data[0].id != undefined){
                    data=data.map((d)=> {return [d];});
                }
                // console.log(data);
                return data;
            }
        },
        created(){
            this.edit_setting_section.settings=this.default_settings;
            let id=this.$route.params.id; 
            if(this.newsletter.name == ''){
                this.$store.state.leffit.loader=true;
                this.$store.dispatch('get',this.get_url+id).
                then(data=>{
                    console
                    if(data.data == ''){ this.$router.push('/admin/courses/lessons');}
                    let template=data.data.data; 
                    this.$store.state.leffit.template=template;
                    if(template.content != null && template.content != ''){ 
                        var sections=JSON.parse(template.content);  
                        sections=this.repairData(sections);
                        this.bindValues(sections);
                        template.content='';
                        this.$store.state.leffit.template=template;
                        this.loaded=true;
                        
                    }
                    this.$store.state.leffit.loader=false;
                }).catch(error=>{
                    // console.log(error);
                    this.$store.state.leffit.loader=false;
                    this.$router.push('/');
                })
            }
        }
    }
</script>

<style scoped>
        .close-btn-setting{
            background: red;
            color: white;
            padding: 5px 10px;
            font-size: 9px;
            font-weight: 600;
        }
        .text-settings-editor{
            position: fixed;
            background: black;
            width: 320px;
            padding: 25px 10px;
            z-index: 99999;
            box-shadow: 3px 8px 17px lightgrey;
            border-radius: 0px;
            margin-top: -38px;
            margin-left: 292px;
        }
        .resizer{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99999; 
            background: transparent;
        }
        .color-box{
            height: 40px;
            width:80px;
        }
        .column:hover .nav-width{
            display: block !important;
        }
        .nav-width{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99; 
            display: none;
        }
        .nav-width button{
            background: black;
            color: white;
            vertical-align: revert;
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            margin: auto; 
            border: none;
        }
        .nav-width button.btn-left{
            left: 0px;
        }
        .nav-width button.btn-right{
            right: 0px;
        } 
        .nav-width button.btn-right:hover{
            cursor: col-resize;
        }
        .each-row:hover .row-tools{
            display: block;
        }
        .row-tools{
            position: absolute;
            left: 0;
            top: 0px; 
            height: fit-content;
            margin: auto;
            display: none;
            z-index: 9999999;
        }
        .row-tools .btn{ 
            background: #00BCD4;
            color: white;
            display: block;
        }
        .each-row:hover{
            outline: 1px solid #00BCD4;
            margin: 0;
        }
        .each-row{
            margin: auto !important;
            width: 100%;
            min-height: 150px;
            outline: 1px dashed #00bcd47d;
            position: relative;
            /* max-width: 100%; */
        }
        .builder-active{
            position: fixed !important; 
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999999;
            background: white;
        }
        .center-image{
            height: 100px;
            width: auto !important;
            margin: auto;
            display: block;
        }
        fieldset{
            border:1px solid #d3d3d352;padding: 10px;
        }
        legend{
            width:auto;
        }
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        } 
        .templateHolder{ 
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            margin: auto;
            margin-top:5px;
            width: 100%;
            border: 1px solid lightgray;
            padding: 0px;
            height: 90%;
            overflow-y: scroll;
            height: 100vh;
        }
        .editBoard{ 
            padding: 10px 10px;
            overflow-y: scroll;
            height: calc(100vh - 50px);
            background: #f2f2f2;
            margin-left: 10px;
            padding-bottom: 30px;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        }
        .defaultTemp .btn{
            border:1px solid lightgray;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: all .2s linear;
            color: gray;
        }
        .defaultTemp:hover .btn{
            transform: scale(1.3);
            background: white;
        }
        .defaultTemp:hover {
            transform: scale(0.9);
            background: #d3d3d31a;
        }
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            /* position: absolute; */
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative; 
            margin-bottom: 10px;
        }
        .temp:hover{
            cursor: pointer; 
            outline: 1px dotted red;
        }
        .temp:hover .sectionTools{
            display: block;
        }
        .temp.editting{
            border: 2px dashed red;
        }
        .temp:hover .sectionTools button{
            background: black;
        }
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9999;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        }
        .uploadImage:hover{
            transform: scale(0.95);
        }
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto
        {
            padding: 0px !important;
        }
</style>
<style >
    div img{
        width:100% !important;
    } 
    div iframe{
        /* width:100% !important;
        height:100% !important; */
    }
    div.needs-cover::after{
        content: '';
        display: block;
        position: absolute;
        background: #00000014;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
</style>