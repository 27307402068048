<template>
  <div>
    <div class="head row p-0 m-0">
      <h4 v-show="isDisabled">
        <router-link
          :to="this.$route.fullPath + '/edit'"
          class="btn btn-secondary"
          ><i class="fa fa-edit"></i> EDIT</router-link
        >
      </h4>
    </div>
    <div v-show="isDisabled" class="row">
      <div class="col-sm-12 col-md-10">
        <div class="card basicDetailsCard">
          <div class="bsc">
            <b>Name:</b>
            <h6>{{ item.name }}</h6>
          </div>
          <div class="bsc">
            <b>Address</b>
            <h6>{{ item.address }}</h6>
          </div>
          <div class="bsc">
            <b>Address2</b>
            <h6>{{ item.address2 }}</h6>
          </div>
          <div class="bsc">
            <b>Zipcode</b>
            <h6>{{ item.zipcode }}</h6>
          </div>
          <div class="bsc">
            <b>City</b>
            <h6>{{ item.city }}</h6>
          </div>
          <div class="bsc">
            <b>Country</b>
            <h6>{{ item.country }}</h6>
          </div>
          <div class="bsc">
            <b>Phone</b>
            <h6>{{ item.phone }}</h6>
          </div>
          <div class="bsc">
            <b>Mobile</b>
            <h6>{{ item.mobile }}</h6>
          </div>
          <div class="bsc">
            <b>Fax</b>
            <h6>{{ item.fax }}</h6>
          </div>
          <div class="bsc">
            <b>email</b>
            <h6>{{ item.email }}</h6>
          </div>
          <div class="bsc">
            <b>Remarks</b>
            <p v-html="item.remarks"></p>
          </div>
        </div>
      </div>
    </div>
    <form v-show="!isDisabled" @submit="updateItem($event)">
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_name">{{
              $t("leffit.crm.organization.name")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_name"
              v-model="item.name"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_address">{{
              $t("leffit.crm.organization.address")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_address"
              v-model="item.address"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_address2">{{
              $t("leffit.crm.organization.address2")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_address2"
              v-model="item.address2"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-4">
            <label for="organization_zipcode">{{
              $t("leffit.crm.organization.zipcode")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_zipcode"
              v-model="item.zipcode"
            />
          </div>

          <div class="form-group col-4">
            <label for="organization_city">{{
              $t("leffit.crm.organization.city")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_city"
              v-model="item.city"
            />
          </div>

          <div class="form-group col-4">
            <label for="contact_country">{{
              $t("leffit.crm.contact.country")
            }}</label>
            <select
              style="position:relative"
              :disabled="isDisabled"
              class="form-control country"
              id="contact_country"
            >
              <option
                selected
                :key="index"
                v-for="(ct, index) in country"
                :value="ct.code"
                >{{ ct.name }}</option
              >
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="organization_phone">{{
              $t("leffit.crm.organization.phone")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_phone"
              v-model="item.phone"
            />
          </div>

          <div class="form-group col-6">
            <label for="organization_mobile">{{
              $t("leffit.crm.organization.mobile")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_mobile"
              v-model="item.mobile"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="organization_fax">{{
              $t("leffit.crm.organization.fax")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_fax"
              v-model="item.fax"
            />
          </div>

          <div class="form-group col-6">
            <label for="organization_email">{{
              $t("leffit.crm.organization.email")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_email"
              v-model="item.email"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_remarks">{{
              $t("leffit.crm.organization.remarks")
            }}</label>
            <textarea
              :disabled="isDisabled"
              class="form-control WYSIWYG"
              id="organization_remarks"
              v-model="item.remarks"
            ></textarea>
          </div>
        </div>
        <div class="row p-0 m-0">
          <button class="btn btn-success">UPDATE</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";

export default {
  props: ["id", "edit"],
  name: "item",
  data() {
    return {
      item: {
        name: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        picture: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "",
        remarks: "",
        status_id: "",
        parent_id: "",
        organizationtype_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      language: language,
      country: country,
      profileImageSet: false,
      genCLoading: false,
      genDLoading: false,
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    isDisabled: function() {
      return !(this.$route.params.edit == "edit");
    },
  },
  methods: {
    updateItem(e) {
      e.preventDefault();
      this.item.remarks = window.tinyMCE
        .get("organization_remarks")
        .getContent();
      this.item.country = document.getElementById("contact_country").value;

      this.$store
        .dispatch("put", {
          endpoint: "/leffit/crm/addresses/" + this.item.id,
          details: this.item,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.name + " was updated successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
  },
  mounted() {
    this.$store
      .dispatch("get", "/leffit/crm/addresses/" + this.$route.params.id)
      .then((data) => {
        console.log(data);
        this.item = data.data.data;
        window
          .$(".language")
          .val(this.item.language)
          .select2();
        window
          .$(".country")
          .val(this.item.country)
          .select2();
      });
    window.tinymce.init({
      selector: "textarea", // change this value according to your HTML
      plugin: "a_tinymce_plugin",
      a_plugin_option: true,
      a_configuration_option: 400,
    });
  },
};
</script>

<style lang="css" scoped>
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
