<template>
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-pills nav-fill">
        <li v-for="(tab, index) in group_tabs" :key="index" class="nav-item">
          <a class="nav-link" data-toggle="tab" :href="'#tab' + tab.id">{{
            tab.name
          }}</a>
        </li>
      </ul>
    </div>
    <div class="col-12">
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in group_tabs"
          :key="index"
          class="tab-pane fade show"
          :id="'tab' + tab.id"
          role="tabpanel"
        >
          <div class="row mt-3">
            <div class="col-12 col-md-4 col-lg-3">
              <ul class="nav nav-pills flex-column ">
                <li
                  v-for="(group, index2) in tab.groups"
                  :key="index2"
                  class="nav-item"
                >
                  <a
                    style="text-align: center;"
                    data-toggle="tab"
                    class="nav-link"
                    :href="'#nav-group-home' + index2 + index"
                    >{{ group.group }}</a
                  >
                </li>
              </ul>
            </div>
            <div v-if="show_meta" class="col-12 col-md-8 col-lg-9">
              <div class="tab-content" id="nav-tabContent">
                <div
                  v-for="(group, index_g) in tab.groups"
                  :key="index_g"
                  class="tab-pane fade"
                  :id="'nav-group-home' + index_g + index"
                >
                  <div
                    v-for="(field, index) in group.fields"
                    :key="index"
                    class="form-group"
                  >
                    <div v-if="field.settings.type == 'text'">
                      <label for="">{{ field.label }}</label>
                      <input
                        type="text"
                        v-model="field.value"
                        class="form-control"
                      />
                    </div>
                    <div v-else-if="field.settings.type == 'select'">
                      <label for="">{{ field.label }}</label>
                      <select v-model="field.value" class="form-control" id="">
                        <option
                          v-for="(opt, index2) in options.filter(
                            (d) => field.id == d.field_id
                          )"
                          :key="index2"
                          :value="opt.value"
                        >
                          {{ opt.label }}
                        </option>
                      </select>
                    </div>
                    <div v-else-if="field.settings.type == 'tinymce'">
                      <label for="">{{ field.label }}</label>
                      <Editor v-model="field.value"></Editor>
                    </div>
                  </div>
                  <div class="col-12">
                    <button @click="updateGroup(group)" class="btn btn-sm">
                      UPDATE
                    </button>
                  </div>

                  <div
                    v-if="multiple_data[group.group] != undefined"
                    class="col-12"
                  >
                    <table class="table table-responsive table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            v-for="(hd, index) in multiple_data[group.group]
                              .headers"
                            :key="index"
                          >
                            {{ hd }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, index) in multiple_data[group.group]
                            .data"
                          :key="index"
                        >
                          <th>{{ index + 1 }}</th>
                          <td
                            v-for="(hd, index2) in multiple_data[group.group]
                              .headers"
                            :key="index2"
                          >
                            {{ row[hd] }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: { required: true },
    tabs: { required: true },
    options: { required: true },
    package_name: { required: true },
  },
  data() {
    return {
      multiple_data: {},
      show_meta: false,
      group_tabs: [],
    };
  },
  methods: {
    updateGroup(group) {
      var groups = {};
      groups[group.group] = {};
      group.fields.forEach((element) => {
        groups[group.group][element.key] = element;
      });

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/update-meta-model/" + this.$route.params.id,
          details: { data: groups, model: this.package_name },
        })
        .then((data) => {
          this.processMetas(data.data.extra);
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: "Item was updated successfully",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e.request);
        });
    },
    processMultipl(data) {
      var temp = {};
      var field = "";
      var item = {};
      var headers = [];
      var pickorder = [];

      for (var group in data) {
        temp[group] = { data: [] };
        data[group].pickorder.forEach((pk) => {
          //ex 0,1,2
          var row = {};
          data[group].headers.forEach((header) => {
            //ex height weight
            field = data[group].data[header];
            var value = field.find((d) => d.pickorder == parseInt(pk));
            row[header] = value ? value.value : "";
          });
          temp[group].data.push(row);
        });
        temp[group].headers = data[group].headers;
        temp[group].pickorder = data[group].pickorder;
      }
      return temp;
    },
    makeTurple(data) {
      var temp = [];
      data.forEach((p) => {
        if (!temp.find((d) => d.toString() == p.toString())) {
          temp.push(p.toString());
        }
      });

      return temp;
    },
    processMetas(metas) {
      this.show_meta = false;
      var temp = {};
      var field = null;
      var multiple_data = [];
      var pickorder = [];
      var headers = [];
      var check_multiple = false;

      for (var grp in metas) {
        temp[grp] = [];
        headers = [];
        pickorder = [];
        check_multiple = false;
        for (var fd in metas[grp]) {
          field = metas[grp][fd];
          temp[grp].push(field);
          if (field.settings.group_type == "multiple") {
            check_multiple = true;
            if (!multiple_data[grp]) {
              multiple_data[grp] = { headers: [], pickorder: [], data: [] };
            }
            multiple_data[grp].data[fd] = field.data;
            headers.push(fd);
            pickorder.push(...field.data.map((d) => d.pickorder));
          }
        }
        if (check_multiple) {
          multiple_data[grp].headers = this.makeTurple(headers);
          multiple_data[grp].pickorder = this.makeTurple(pickorder);
        }
      }
      this.group_tabs = this.tabs.map((tb) => {
        tb.groups = tb.groups.map((grp) => {
          grp.fields = temp[grp.group];
          console.log(grp);
          return grp;
        });
        return tb;
      });

      this.multiple_data = null;
      this.multiple_data = this.processMultipl(multiple_data);
      this.show_meta = true;
      console.log(this.group_tabs);
    },
  },
  created() {
    setTimeout(() => {
      this.processMetas(this.item.extra);
    }, 1000);
  },
};
</script>
<style scoped>
.flex-column a.nav-link {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.flex-column a.nav-link.active {
  background: #4e5467;
}
</style>
