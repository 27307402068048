
import section1 from '../newletters/mailtemplate/section1'
import section2 from '../newletters/mailtemplate/section2'
import section3 from '../newletters/mailtemplate/section3'
import section4 from '../newletters/mailtemplate/section4'
import section5 from '../newletters/mailtemplate/section5'
import section7 from '../newletters/mailtemplate/section7'
import section6 from '../newletters/mailtemplate/section6'
import section8 from '../newletters/mailtemplate/section8'
import section9 from '../newletters/mailtemplate/section9'
import section10 from '../newletters/mailtemplate/section10'
import section11 from '../newletters/mailtemplate/section11'
import section12 from '../newletters/mailtemplate/section12'
import section13 from '../newletters/mailtemplate/section13'
import section14 from '../newletters/mailtemplate/section14'
import section15 from '../newletters/mailtemplate/section15'
import section16 from '../newletters/mailtemplate/section16'
import section17 from '../newletters/mailtemplate/section17'
import section18 from '../newletters/mailtemplate/section18'
import section19 from '../newletters/mailtemplate/section19'
import section20 from '../newletters/mailtemplate/section20'
import section21 from '../newletters/mailtemplate/section21'
import section22 from '../newletters/mailtemplate/section22'
import section23 from '../newletters/mailtemplate/section23'
import section24 from '../newletters/mailtemplate/section24'
import section25 from '../newletters/mailtemplate/section25'
import section26 from '../newletters/mailtemplate/section26'
import section27 from '../newletters/mailtemplate/section27'
import section28 from '../newletters/mailtemplate/section28'
import section29 from '../newletters/mailtemplate/section29'


export default [
    {
        id:29,
        title:'Embed video',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section29,
        mode:'initial',
        fields:[
            {
                element:'sectioniframe',
                type:'embed-video',
                label:'Embed iframe',
                content:'<iframe width="560" height="315" src="https://www.youtube.com/embed/gq9w14ag0ls" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }, 
            {
                element:'sectionClass',
                type:'class',
                label:'Video width',
                content:'col-12 m-auto'
            },
            {
                element:'sectionHeight',
                type:'height',
                label:'Video height',
                content:'300px'
            },
        ]
    }, 
    {
        id:1,
        title:'An header and a text',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section1,
        mode:'initial',
        fields:[
            {
                element:'section1Header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan misschien wel over twee regels.`
            },
            {
                element:'section1Text',
                type:'bodyText',
                label:'Main  Body Text',
                content:`
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
                    Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                `
            }
        ]
    },
    {
        id:2,
        title:'3 columns with header and text',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section2,
        mode:'initial',
        fields:[
            {
                element:'section2Header',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links met foto bv voor shp in hendrerit njnu`
            },
            {
                element:'section2Image1',
                type:'image',
                label:'Column 1 Image',
                content:`/assets/newsletter/small_bg.png`
            }, 
           
            {
                element:'section2Text1',
                type:'bodyText',
                label:'Column 1 Text',
                content:`  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat `
            },
            {
                element:'section2Image2',
                type:'image',
                label:'Column 2 Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section2Text2',
                type:'bodyText',
                label:'Column 2 Text',
                content:`  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat `
            },
            {
                element:'section2Image3',
                type:'image',
                label:'Column 3 Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section2Text3',
                type:'bodyText',
                label:'Column 3 Text',
                content:`  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat `
            },
        ]
    },
    {
        id:3,
        title:'Body Text and Footer Button',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section3,
        mode:'initial',
        fields:[ 
            {
                element:'section3Text1',
                type:'bodyText',
                label:'Main  Body Text',
                content:`
                    Lorem ipsum dolor sit amet, consectetuernissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
                `
            },
            {
                element:'section3Text2',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section3Link1',
                type:'link',
                label:'Button Link',
                content:``
            }
        ]
    },
    {
        id:4,
        title:'Single header and single main text',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section4,
        mode:'initial',
        fields:[
            {
                element:'section4Header1',
                type:'headerText',
                label:'Header Text',
                content:'Hier kan een koptekst komen te staan misschien wel over twee regels.'
            },
            {
                element:'section4Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
                Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,`
            },
        ]
    },
    {
        id:5,
        title:'Header ,Body and Button',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section5,
        mode:'initial',
        fields:[
            {
                element:'section5header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan misschien wel over twee regels.`
            },
            {
                element:'section5Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat`
            },
            {
                element:'section5Text2',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section5Link1',
                type:'link',
                label:'Button Link',
                content:``
            }
        ]
    },
    {
        id:7,
        title:'Header ,Body and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section7,
        mode:'initial',
        fields:[
            {
                element:'section7header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section7Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section7Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]
    },
    {
        id:6,
        title:'linktext and link',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section6,
        mode:'initial',
        fields:[
            {
                element:'section6linktext',
                type:'linkText',
                label:'Link Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section6link',
                type:'link',
                label:'Link',
                content:`` 
            }
        ]
    },
    {
        id:8,
        title:'section8Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section8,
        mode:'initial',
        fields:[
            {
                element:'section8Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]   
    },

    {
        id:9,
        title:'Header ,Body and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section9,
        mode:'initial',
        fields:[
            {
                element:'section9header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section9Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section9Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            } 
        ]
    },
    {
        id:10,
        title:'linktext and link',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section10,
        mode:'initial',
        fields:[
            {
                element:'section10linktext',
                type:'linkText',
                label:'Link Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section10link',
                type:'link',
                label:'Link',
                content:`` 
            }
        ]
    },
    {   id:11,
        title:'Header ,Body and Header2',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section11,
        mode:'initial',
        fields:[
            {
                element:'section11header',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links`
            },
            {
                element:'section11header1',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 1 links`
            },
            {
                element:'section11Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat`
            },
            {
                element:'section11header2',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 1 links`
            },
            {
                element:'section11Text2',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt  `
                
            },
            {
                element:'section11header3',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links`
            },
            {
                element:'section11Text3',
                type:'bodyText',
                label:'Body Text',
               content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt `
            } 
        ]
    },
    {
        id:12,
        title:'text and link',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section12,
        mode:'initial',
        fields:[
            {
                element:'section12text',
                type:'Text',
                label:'Text',
                content:`Je krijgt deze mail omdat je bij ons op de maillijst staat.`  
            },
            {
                element:'section12linktext',
                type:'link text',
                label:'Link Text',
                content:`Afmelden` 
            },
            {
                element:'section12link',
                type:'link',
                label:'Link',
                content:`` 
            },
        ]
    },
    {
        id:13,
        title:'linktext and link',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section13,
        mode:'initial',
        fields:[
            {
                element:'section13linktext',
                type:'linkText',
                label:'Link Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section13link',
                type:'link',
                label:'Link',
                content:`` 
            }
        ]
    },
    {
        id:14,
        title:'section14Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section14,
        mode:'initial',
        fields:[
            {
                element:'section14Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]   
    },
    {
        id:15,
        title:'An header and a text',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section15,
        mode:'initial',
        fields:[
            {
                element:'section15Header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan misschien wel over twee regels.`
            },
            {
                element:'section15Text',
                type:'bodyText',
                label:'Main  Body Text',
                content:`
                    Lorem ipsum dolor sit amet, auscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
                    Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                `
            }
        ]
    },
    {
        id:16,
        title:'Header ,Body and Button',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section16,
        mode:'initial',
        fields:[
            {
                element:'section16header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan misschien wel over twee regels.`
            },
            {
                element:'section16Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat`
            },
            {
                element:'section16Text2',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section16Link1',
                type:'link',
                label:'Button Link',
                content:``
            }
        ]
    },
    {
        id:17,
        title:'linktext, link and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section17,
        mode:'initial',
        fields:[
            {
                element:'section17linktext',
                type:'linkText',
                label:'Link Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section17link',
                type:'link',
                label:'Link',
                content:`` 
            },
            {
                element:'section17Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png` 
            }
        ]
    },
    {
        id:18,
        title:'section18Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section18,
        mode:'initial',
        fields:[
            {
                element:'section18Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]   
    },
    {
        id:19,
        title:'Header ,Body and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section19,
        mode:'initial',
        fields:[
            {
                element:'section19header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section19Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section19Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]
    },
    {   id:20,
        title:'Header ,Image and Header2',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section20,
        mode:'initial',
        fields:[
            {
                element:'section20header',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links`
            },
            {
                element:'section20header1',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 1 links`
            },
            {
                element:'section20Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section20header2',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 1 links`
            },
            {
                element:'section20Image2',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png `
                
            },
            {
                element:'section20header3',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links`
            },
            {
                element:'section20Image3',
                type:'image',
                label:'Image',
               content:`/assets/newsletter/small_bg.png`
            } 
        ]
    },
    {
        id:21,
        title:'buttontext and link',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section21,
        mode:'initial',
        fields:[
            {
                element:'section21buttontext',
                type:'buttonText',
                label:'Button Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section21link',
                type:'buttonlink',
                label:'Button Link',
                content:`` 
            }
        ]
    },
    {
        id:22,
        title:'linktext, link and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section22,
        mode:'initial',
        fields:[
            {
                element:'section22linktext',
                type:'linkText',
                label:'Link Text',
                content:`Bekijk deze email in uw browser`  
            },
            {
                element:'section22link',
                type:'link',
                label:'Link',
                content:`` 
            },
            {
                element:'section22Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png` 
            }
        ]
    },
    {
        id:23,
        title:'Header ,Body ,Image and Button',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section23,
        mode:'initial',
        fields:[
            {
                element:'section23header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan misschien wel over twee regels.`
            },
            {
                element:'section23Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat`
            },
            {
                element:'section23Text2',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section23Link1',
                type:'link',
                label:'Button Link',
                content:``
            },
            {
                element:'section23Image',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png` 
            }
        ]
    },
    {   id:24,
        title:'Header ,Image and Header2',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section24,
        mode:'initial',
        fields:[
            {
                element:'section24header',
                type:'headerText',
                label:'Header Text',
                content:`Een kopregel boven 3 links met foto bv voor shp in hendrerit njnu`
            },
            {
                element:'section24Text1',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section24Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section24Text2',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section24Image2',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png `
                
            },
            {
                element:'section24Text3',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section24Image3',
                type:'image',
                label:'Image',
               content:`/assets/newsletter/small_bg.png`
            } 
        ]
    },
    {
        id:25,
        title:'Header ,Body ,Button and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section25,
        mode:'initial',
        fields:[
            {
                element:'section25header1',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section25header2',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section25Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section25Text2',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section25Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section25Link1',
                type:'link',
                label:'Button Link',
                content:``
            },
            {
                element:'section25Text3',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section25Image2',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png` 
            }

        ]
    },
    {
        id:26,
        title:'Header ,Body and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section26,
        mode:'initial',
        fields:[
            {
                element:'section26header',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section26Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section26Image1',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            }
        ]
    },
    {
        id:27,
        title:'Header and Body',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section27,
        mode:'initial',
        fields:[
            {
                element:'section27header1',
                type:'headerText',
                label:'Header Text',
                content:`Hier kan een koptekst komen te staan`
            },
            {
                element:'section27Text1',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
            {
                element:'section27Text2',
                type:'bodyText',
                label:'Body Text',
                content:`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem`
            },
        ]
    },
    {
        id:28,
        title:'Header ,Body ,Button and Image',
        description:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit saepe alias, labore earum ratione culpa',
        content:section28,
        mode:'initial',
        fields:[
            {
                element:'section28header1',
                type:'headerText',
                label:'Header Text',
                content:`Betaal nu!`
            },
            {
                element:'section28header2',
                type:'headerText',
                label:'Header Text',
                content:`We vragen je vriendelijk het bedrag uiterlijk 1 januari 2019 aan ons over te maken`
            },
            {
                element:'section28header3',
                type:'headerText',
                label:'Header Text',
                content:`We vragen je vriendelijk het bedrag uiterlijk 1 januari 2019 aan ons over te maken`
            },
            {
                element:'section28header4',
                type:'headerText',
                label:'Header Text',
                content:`Naam rekeninghouder`
            },
            {
                element:'section28header5',
                type:'headerText',
                label:'Header Text',
                content:`Leffix`
            },
            {
                element:'section28header6',
                type:'headerText',
                label:'Header Text',
                content:`IBAN`
            },
            {
                element:'section28header7',
                type:'headerText',
                label:'Header Text',
                content:`NL00ABC0000000000`
            },
            {
                element:'section28header8',
                type:'headerText',
                label:'Header Text',
                content:`BIC`
            },
            {
                element:'section28header9',
                type:'headerText',
                label:'Header Text',
                content:`ABCANL00000000000`
            },
            {
                element:'section28header10',
                type:'headerText',
                label:'Header Text',
                content:`Referentie`
            },
            {
                element:'section28header11',
                type:'headerText',
                label:'Header Text',
                content:`1234567`
            },
            {
                element:'section28Image1',
                type:'image',
                label:'Image 1',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section28Image2',
                type:'image',
                label:'Image 2',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section28Image3',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section28Image4',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section28Image5',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png`
            },
            {
                element:'section28Text1',
                type:'buttonText',
                label:'Button Text',
                content:`button met een link `
            },
            {
                element:'section25Image2',
                type:'image',
                label:'Image',
                content:`/assets/newsletter/small_bg.png` 
            }

        ]
    }
]