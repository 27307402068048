<template>
    <div class="row">
        <div class="col-12">
                <!-- <div class="row">
                    <div class="form-group col-12">
                        <label for="">Code</label>
                        <input v-model="voucher.code" type="text" class="form-control">
                    </div>
                </div> -->
                <div class="row">
                    <div class="form-group col-6">
                        <label for="">{{ $t('leffit.products.voucher.create_page.start') }}</label>
                        <input @change="validateStartDate()" v-model="voucher.starts_at" type="datetime-local" class="form-control">
                    </div>
                    <div class="form-group col-6">
                        <label for="">{{ $t('leffit.products.voucher.create_page.expire') }}</label>
                        <input @change="validateEndDate()" v-model="voucher.expires_at" type="datetime-local" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="">{{ $t('leffit.products.voucher.create_page.value_type') }}</label>
                        <select v-model="voucher.value_type" id="" class="form-control">
                            <option value="price">Price</option>
                            <option value="percentage">Percentage</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="">{{ $t('leffit.products.voucher.create_page.value') }}</label>
                        <input v-model="voucher.value" type="number" class="form-control" placeholder="Ex. 120">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <label for="">{{ $t('leffit.products.voucher.create_page.validity') }}</label>
                        <select v-model="voucher.validity" class="form-control">
                            <option value="once">Once</option>
                            <option value="unlimited">Unlimited</option>
                            <option value="products">Products</option>
                            <option value="users">Users</option>
                        </select>
                    </div>
                    <div v-if="voucher.validity == 'products'" class="col-12">
                        <multiselect  track-by="id" :name="'prods'" label="name" v-model="selected_products" :options="products.map((d)=>{ return {name:d.name,id:d.id};})" :multiple="true" >
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{option.name}}</strong></template>
                        </multiselect>
                    </div>
                    <div v-if="voucher.validity == 'users'" class="col-12">
                        <multiselect  track-by="id" :name="'prods'" label="name" v-model="selected_contacts" :options="contacts.map((d)=>{ return {name:d.firstname+' '+d.lastname,id:d.id};})" :multiple="true" >
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{option.name}}</strong></template>
                        </multiselect>
                    </div>
                    <div class="col-12">
                        <button @click="submit()" type="button" class="btn btn-success">
                            <span v-if="!loading">{{ $t('leffit.products.voucher.create_page.create_button') }}</span>
                            <span v-else>CREATING....</span>
                        </button>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
const moment = require('moment')
require('moment/locale/nl')

export default {
    data(){
        return {
            voucher:{
                starts_at:moment().format('YYYY-MM-DD')+'T'+moment().format('LT'),expires_at:moment().format('YYYY-MM-DD')+'T'+moment().format('LT'),
                validity:'',value:'',value_type:'price'
            },
            selected_products:[],
            products:[],
            contacts:[],
            loading:false,
            selected_contacts:[]
        }
    },
    computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint,
            user:state=>state.leffit.user,
            token:state=>state.leffit.token,
        }),
    },
    methods:{
        validateStartDate(){
            var start=this.voucher.starts_at;
            start=new Date(start).getTime();
            var start_date_diff=new Date().getTime() - start;
            if(start_date_diff > 0){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Time Error",text:"Please don't select a date in the past"});
                this.voucher.starts_at=moment().format('YYYY-MM-DD')+'T'+moment().format('LT');
                return false;
            }
            return true;
        },
        validateEndDate(){
            var start=this.voucher.starts_at;
            var expires_at=this.voucher.expires_at;

            start=new Date(start).getTime();
            expires_at=new Date(expires_at).getTime();
            var start_date_diff=expires_at - start;
            var end_date_diff=expires_at - new Date().getTime();

            if(end_date_diff < 0){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Time Error",text:"Please don't select a date in the past"});
                this.voucher.expires_at=moment().format('YYYY-MM-DD')+'T'+moment().format('LT');
                return false;
            }
            if(start_date_diff <= 0){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Time Error",text:"Please select a date beyond start date"});
                this.voucher.expires_at=this.voucher.starts_at;
                return false;
            }

            return true;
        },
        submit(){

            if(this.voucher.starts_at.length == 0 || this.voucher.expires_at.length == 0 || this.voucher.validity.length == 0 ){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }



            if(!this.validateStartDate() || !this.validateEndDate()){
                return false;
            }

            this.loading=true;
            this.voucher.data=[];
            if(this.voucher.validity == 'products'){
                this.voucher.data=this.selected_products.map((d)=> d.id);
            }
            else if(this.voucher.validity == 'users'){
                this.voucher.data=this.selected_contacts.map((d)=> d.id);
            }

            console.log(this.voucher);

            this.$store.dispatch('post',{endpoint:'/leffit/payments/add-voucher?token='+this.token,details:this.voucher})
			.then(data=>{
                console.log('result',data);
                this.loading=false;
                this.$store.dispatch('makeNotification',{type:'success',title:"Voucher created",text:'Voucher was created successfully'});
                // this.$router.back();
                window.location.href = "/admin/products/vouchers";
			}).catch((error)=>{
                console.log(error);
                this.loading=false;
			});
        }
    },
    created(){
        this.$store.dispatch('get','/leffit/products/products/cache')
        .then(data=>{
            console.log(data);
            this.products = data.data.data;
        }).catch((error)=>{
            window.console.log(error);
        })
        this.$store.dispatch('get','/leffit/crm/contacts')
        .then(data=>{
            console.log(data);
            this.contacts = data.data.data;
        }).catch((error)=>{
            window.console.log(error);
        })
    }

}
</script>
