<template>
    <div id="dashboard" class="container-fluid mt-0">
        <div class="row">
            <div class="col-12">
                <div id="exampleModal" class="modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase">{{$t('leffit.courses.lessons.new_lesson')}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form action="" class="mb-5" @submit="createNewsletter($event)">
                                <div class="form-group">
                                    <input v-model="title" id="title" type="text" class="form-control" :placeholder="$t('leffit.courses.lessons.template_title')">
                                </div>
                                <!-- <div class="form-group">
                                    <textarea style="width:100%;border:1px solid grey" v-model="description"  name="" id="" cols="30" rows="10"></textarea>
                                </div> -->
                                <formerror />
                            </form>
                            <button @click="createNewsletter($event)" type="submit" class="btn btn-success">{{$t('leffit.courses.lessons.create')}}</button>
                            <button type="button" class="btn btn-secondary float-right" data-dismiss="modal">{{$t('leffit.courses.lessons.close')}}</button>
                        </div>
                        </div>
                    </div>
                </div>

                <button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-success float-right mb-3">
                    {{$t('leffit.courses.lessons.new_lesson')}}
                </button>
            </div>
        <div class="col-12">
            <table id="example" class="display" style="width:100%">
                <thead>
                    <tr>
                        <th>{{$t('leffit.courses.lessons.name')}}</th>
                        <th>{{$t('leffit.courses.lessons.Action')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(nlt,index) in newsletters" :key="index">
                        <td><a :href="'lessons/edit/'+nlt.id">{{nlt.name}}</a></td>
                        <td>
                            <div>
                                <a :href="'lessons/edit/'+nlt.id" class="btn btn-sm btn-success">{{$t('leffit.courses.lessons.open')}}</a> &nbsp;
                                <button @click="deleteN(nlt.id)" class="btn btn-sm btn-danger">{{$t('leffit.courses.lessons.delete')}}</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</template>

<script>
import formerror from "../formErrors";
import { mapState } from "vuex";
export default {
    name:'newletterIndex',
    components:{formerror},
    data(){
        return {
            title:'',
            description:''
        }
    },
    computed:{...mapState({
        newsletters:state=>state.leffit.newsletters
    })},
    updated(){
        window.$('#example').DataTable();
    },
    created(){
        this.$store.state.leffit.loader.show=true;
        this.$store.dispatch('get','/leffit/courses/lessons')
            .then(data=>{
                console.log(data);
                this.$store.state.leffit.newsletters=data.data.data;
                this.$store.state.leffit.loader.show=false;
                console.log(this.$store.state.leffit.newsletters);
            })
            .catch(error=>{
                this.$store.state.leffit.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.leffit.loader.show=false;
            });


    },
    methods:{
        createNewsletter(e){
            e.preventDefault();
            this.$store.state.formErrors=[];

            if(this.title.trim().length <= 0){
                this.$store.state.formErrors.push('Title field is required.');
                return false;
            }
            this.$store.state.loader=true;

            let payload={name:this.title,description:this.description};
            this.$store.dispatch('post',{endpoint:'/leffit/courses/lessons',details:payload})
            .then(data=>{
                console.log(data);

                this.$store.state.template=data.data.data;
                this.$store.state.loader=false;

                window.$("#exampleModal").modal('hide');
                this.$router.push('/admin/courses/lessons/edit/'+data.data.data.id);
            })
            .catch(error=>{
                this.$store.state.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.loader=false;
            });
        },
        deleteN(id){
            let nlt=this.newsletters.filter((dt=> dt.id != id));
            this.$store.state.leffit.newsletters=[];
            setTimeout(()=>{
                this.$store.state.leffit.newsletters=nlt;
            },10);

            this.$store.dispatch('delete','/leffit/courses/lessons/'+id)
            .then(data=>{
                console.log(data);
                this.$store.state.leffit.loader=false;
            })
            .catch(error=>{
                this.$store.state.leffit.formErrors.push('Server error!!');
                console.log(error.request);
                this.$store.state.leffit.loader=false;
            });
        }
    }
}
</script>

<style scoped>
    #dashboard{
        padding: 40px auto;
        position: absolute;
        top:0px;
        bottom:0px;
        margin: auto;
        height: fit-content;
        width: 100%;
    }
</style>

<style >
    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
        color: gray;
        font-size: 14px;
    }
    .modal .btn-secondary{
        padding: 7px 20px;
        font-size: 13px;
        border-radius: 2px;
        background: #cc4b4b;
        border: none;
    }
    .modal .btn-success{
        padding: 7px 20px;
        font-size: 13px;
        border-radius: 2px;
        background: green;
        border: none;
        margin-right: 10px;
    }
    .container-fluid{
        padding: 0px 100px;
    }
</style>
