// import Vue from 'vue'
// import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import Allsections from "../../src/leffit/newletters/sections"


var endpoint = 'https://api.' + location.hostname+'/api/v1';

if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
    endpoint = process.env.VUE_APP_API_BASE_URL;
}else if(process.env.VUE_APP_API_BASE_URL){
    if(process.env.VUE_APP_API_BASE_URL.length>0){
        endpoint = process.env.VUE_APP_API_BASE_URL;
    }
}

const leffit={
    state:{
        endpoint:endpoint,
        notifications:{info:[],success:[],warning:[],danger:[],general:[]},
        loader:{
            type:'page',
            text:'',
            show:false,
        },
        access_token:'',  
        refresh_token:'',
        token:'',
        user:{},
        redirectAfterLogin:'/admin',
        templateModal:false,
        templateViewData:Allsections,
        template:{name:''},
        newsletters:[],
        mailtemplate:[],
        sections:[],
        changesMade:false,
        activeSection:{ id:0,title:'',image:'',content:''}, 
        activeRow:0,
        formErrors:[],
        lockscreen:{show:false,loading:false},
        productImages:{}, 
        pages:[],
        opened_pages:[], 
        upload_progress:0,
        page:{
            'name':'',
            'pagetype_id':'',
            'visible':0,
            'locked':0,
            'parent_id':0,
            'pickorder':0,
            'in_slug':'',
            'settings':'',
        },
        page_preview:false,
        editor_values:{
            image:{file:null,src:'',alt:''},
            inputs:{},
            options:[],
            section:0   
        },
        values:{section:0,value:{}},
        showEditor:false,
        selected_field:{fieldtype:{}},
    },
    mutations:mutations,
    getters:getters,
    actions:actions
};

// console.log('endpoint', endpoint);

export default leffit;
