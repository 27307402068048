<template>
    <div>
        <h3>Purchase keurloon</h3>
        <table class="table table-responsive">
            <thead>
                <tr>
                    <td>Min. Amount</td>
                    <td>Max. Amount</td>
                    <td>Price Per</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(pk,index) in list" :key="index">
                    <td>
                        <div v-if="pk.edit" class="form-group">
                            <input type="text" class="form-control" v-model="pk.min_amount">
                        </div>
                        <span v-else>{{pk.min_amount}}</span>
                    </td>
                    <td>
                        <div v-if="pk.edit" class="form-group">
                            <input type="text" class="form-control" v-model="pk.max_amount">
                        </div>
                        <span v-else>{{pk.max_amount}}</span>
                    </td>
                    <td>
                        <div v-if="pk.edit" class="form-group">
                            <input type="text" class="form-control" v-model="pk.price_per">
                        </div>
                        <span v-else>{{pk.price_per}}</span>
                    </td>
                    <td>
                        <a v-if="!pk.edit" href="javascript:void(0)" @click="editPk(pk)"><i class="fas fa-edit"></i></a>
                        <a v-else class="text-success" href="javascript:void(0)" @click="updatePk(pk)"><i class="fas fa-check "></i> save</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                list:[]
            }
        },
        methods:{
            editPk(pk){
                pk.edit = !pk.edit;
            },
            updatePk(pk){
                this.$store.dispatch('put',{
                    endpoint:'/lefficient/leffit/PurchaseKeurloon/'+pk.id,
                    details:pk
                })
                .then((data)=>{
                    console.log(data);
                })
                .catch((error)=>{
                    console.log(error);
                });
                pk.edit = false;
            }
        },
        created(){
            this.$store.dispatch('get','/lefficient/leffit/PurchaseKeurloon')
            .then((data)=>{
                console.log(data);
                this.list = data.data.map((d)=>{
                    d.edit = false;
                    return d;
                })
            })
            .catch((error)=>{
                console.log(error);
            });
        }
    }
</script>