export default `
<section class="six bg-white text-center pb-3" >
<div class="">
    <h2 id="section20header" class="h2 pt-3">++{{section20header}}</h2>
    <div class="row mt-3">
        <div class="col-sm-4 col-lg-4" >
            <img src="++{{section20Image1}}">
            <h3 id="section20header1">++{{section20header1}}</h3>
        </div>
        <div class="col-sm-4 col-lg-4">
            <img src="++{{section20Image2}}">
            <h3 id="section20header2">++{{section20header2}}</h3>
        </div>
        <div class="col-sm-4 col-lg-4">
            <img src="++{{section20Image3}}">
            <h3 id="section20header3">++{{section20header3}}</h3>
        </div>
    </div>
</div>
</section>
`

