<template>
    <div class="row">
        <div class="col-12 text-center">
            <label for="">Default Producttype</label>
            <select @change="$parent.updateItem()" class="form-control" v-model="settings.default" id="">
                <option v-for="(opt,index) in options" :key="index" :value="opt">{{opt}}</option>
            </select>
        </div>
    </div>
</template>
<script>
    export default {
        props:['settings'],
        data(){
            return {
                value:0,
                options:['Custom','Product','Digital product','Subscription','Stripescard','Appointment','Combined product']
            }
        },
        created(){
            
        }
    }
</script>
<style scoped>

</style>