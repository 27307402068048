<template>
    <div class="row p-3">
        <div class="form-group col-12 col-md-12">
            <label for="">Amount</label>
            <input type="number" class="form-control" v-model="stripecard.stripes" >
        </div> 
        <div class="form-group col-12 col-md-6">
            <label for="">Duration</label>
            <input type="number" class="form-control" v-model="stripecard.duration">
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Period</label>
            <select class="form-control" v-model="stripecard.period">
                <option value="days">Days</option>
                <option value="month">Months</option>
                <option value="year">Years</option>
            </select>
        </div> 
        <div class="form-group col-12 ">
            <button @click="addNewPlan()" class="btn btn-sm btn-success" style="margin-top:-5px">
                Create
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return {
            stripecard:{
                stripes:0,
                duration:0,
                period:''
            }
        }
    },
    methods:{
        addNewPlan(){ 
            if(this.stripecard.stripes == '' || this.stripecard.duration == '' || this.stripecard.period == '' ){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'All fields are required',type:'danger'});
                return false;
            } 

            var temp={};
            if(this.item.producttype_data != null){
                temp=JSON.parse(this.item.producttype_data); 
            }
            temp.stripecard=this.stripecard;
            
            this.$parent.item.producttype_data=JSON.stringify(temp);
            this.$parent.updateItem(); 
        }
    },
    created(){
        if(this.item.producttype_data != null){
            var temp=JSON.parse(this.item.producttype_data);
            this.stripecard=temp.stripecard ? temp.stripecard:this.stripecard;
        }
    }
}
</script>
<style scoped>

</style>