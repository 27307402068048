var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 pt-2"},[_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:_vm.tabsController.english ? 'nav-link active':'nav-link',attrs:{"href":"#english","data-toggle":"tab","role":"tab","aria-controls":"english","aria-selected":"true"},on:{"click":function($event){return _vm.toggleTab('english')}}},[_vm._v(_vm._s(_vm.$t('lefficient.languages.english')))])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:_vm.tabsController.dutch ? 'nav-link active':'nav-link',attrs:{"href":"#dutch","data-toggle":"tab","role":"tab","aria-controls":"dutch","aria-selected":"false"},on:{"click":function($event){return _vm.toggleTab('dutch')}}},[_vm._v(_vm._s(_vm.$t('lefficient.languages.dutch')))])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{class:_vm.tabsController.english ? 'tab-pane fade show active':'tab-pane fade show',attrs:{"id":"english","role":"tabpanel","aria-labelledby":"english-tab"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('lefficient.system.body')))]),_c('editor',{attrs:{"init":{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                    }},model:{value:(_vm.description.en),callback:function ($$v) {_vm.$set(_vm.description, "en", $$v)},expression:"description.en"}})],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success btn-s",on:{"click":function($event){return _vm.updateDescription()}}},[_vm._v(_vm._s(_vm.$t('lefficient.system.update')))])])]),_c('div',{class:_vm.tabsController.dutch ? 'tab-pane fade show active':'tab-pane fade show',attrs:{"id":"dutch","role":"tabpanel","aria-labelledby":"dutch-tab"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('lefficient.system.body')))]),_c('editor',{attrs:{"init":{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                    }},model:{value:(_vm.description.de),callback:function ($$v) {_vm.$set(_vm.description, "de", $$v)},expression:"description.de"}})],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success btn-s",on:{"click":function($event){return _vm.updateDescription()}}},[_vm._v(_vm._s(_vm.$t('lefficient.system.update')))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }