import { render, staticRenderFns } from "./orderlang.vue?vue&type=template&id=15dd867c&scoped=true&"
import script from "./orderlang.vue?vue&type=script&lang=js&"
export * from "./orderlang.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15dd867c",
  null
  
)

export default component.exports