<template>
  <div class="d-flex flex-column flex-lg-row" id="mainBody">
    <sidebarInnerHardcoded :menu="menu" />
    <div style="width: 100% !important" class="mainPanel">
      <router-view />
    </div>
  </div>
</template>

<script>
import sidebarInnerHardcoded from "./../../layout/components/sidebar_inner_hardcoded";
export default {
  name: "index",
  components: { sidebarInnerHardcoded },

  data() {
    return {
      menu: [
        {
          title: this.$t("leffit.products.stock.stocks"),
          url: "/admin/stocks/stocks",
          children: [],
        },
        {
          title: this.$t("leffit.bijenkorf.sales_report"),
          url: "/admin/stocks/sales-report/import",
          children: [],
        },
        {
          title: "Sales Report Order",
          url: "/admin/stocks/warehouse_sales_report",
          children: [],
        },
        {
          title: "Interfiliaal verzenden",
          url: "/admin/stocks/warehouse_sent",
          children: [],
        },
        {
          title: "Interfiliaal ontvangen",
          url: "/admin/stocks/warehouse_received",
          children: [],
        },
        {
          title: "Beginvoorraad",
          url: "/admin/stocks/stock_start",
          children: [],
        },
        {
          title: this.$t("leffit.products.settings"),
          url: "",
          children: [
            {
              title: this.$t("leffit.products.warehouses"),
              url: "/admin/stocks/settings/warehouses",
              children: [],
            },
            {
              title: this.$t("leffit.products.stock.stock_locations"),
              url: "/admin/stocks/settings/stock_locations",
              children: [],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
</style>