<template>
  <div>
    <div class="col-12 mt-4">
      <a href="/admin/sales/orders/sales_order/create">
        <button class="btn btn-primary">
          {{ $t("leffit.crm.contact.new_order") }}
        </button>
      </a>
    </div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th v-for="(attribute, index) in attributes" :key="index">
            {{ $t("leffit.orders.order." + attribute) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(itm, index) in page_data" :key="index">
          <td>
            <!-- <router-link :to="'/admin/'+tmp_ordertypes[this.ordertype_id]['module']+'/orders/' + tmp_ordertypes[this.ordertype_id]['name']'/create/'+itm.id+''" class="btn btn-open">
                            <i class="fa fa-search"></i>
                        </router-link>  -->
            <a
              :href="'/admin/sales/orders/sales_order/create/' + itm.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </a>
          </td>
          <td v-for="(attribute, index) in attributes" :key="index">
            {{ $t(itm[attribute]) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      attributes: [
        "organization_name",
        "firstname",
        "lastname",
        "companyname",
        "address",
        "zipcode",
        "city",
        "country",
        "email",
        "phone",
        "orderstatus",
        "order_date",
      ],
      page_data: [],
    };
  },
  methods: {
    deleteItem(id) {
      if (!confirm("Are you sure you want to delete this item?")) {
        return false;
      }
      this.page_data = this.page_data.filter((ft) => ft.id != id);
      this.$store
        .dispatch(
          "get",
          "/leffit/crm/remove-contact-order/" + this.$route.params.id + "/" + id
        )
        .then(() => {
          // this.users = data.data.data;
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: "Item was updated successfully",
            type: "success",
          });
        });
    },
  },
  created() {
    this.$store
      .dispatch(
        "get",
        "/leffit/crm/get-contact-orders/" +
          this.$route.params.id +
          "?ordertype_id=120"
      )
      .then((data) => {
        console.log(data);
        this.page_data = data.data;
      })
      .catch((error) => {
        console.log(error.request);
      });
  },
};
</script>
