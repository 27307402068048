<template>
	<div>
		<h2>Create an appointment</h2>

		<div class="form-row">
			<div class="form-group col-12">
                <label for="type">{{ $t('leffit.products.product.product_type') }}</label>
                <select v-model="appointment.plannable_id" style="position:relative" class="form-control select1" id="type">
                    <option :key="index" v-for="(plannable,index) in plannables" :value="plannable.id">{{ plannable.model }}</option>
                </select>
            </div> 
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label for="start">Start</label>
				<datetime type="datetime" v-model="appointment.start" input-class="form-control"></datetime>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label for="end">End</label>
				<datetime type="datetime" v-model="appointment.end" input-class="form-control"></datetime>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label for="description">Description</label>
				<input type="text" v-model="appointment.description" class="form-control" id="description">
			</div>
		</div>

		<div class="form-row">
			<button class="btn btn-success" @click.prevent="create">Create</button>
		</div>

	</div>
</template>

<script>

export default {
	components:{},
  	name: 'createAppointmnet',
  	data () {
	    return {
	    	appointment: {
	    		start: new Date().toISOString(),
	    		end: new Date().toISOString(),
	    		description: '',
	    		plannable_id: '1'
	    	},
	    	plannables: []
	    }
  	},
  	computed : {
        
	},
	created(){ 
		
	},  
	methods: {
		create: function() {
			this.$store.dispatch('post',{endpoint:'/leffit/plannables/appointments',details:this.appointment})
	        .then(data=>{
	        	console.log('plannables',data);
				// this.plannables = data.data.data;
				// var context=this;
				// window.$('.select1').select2().change(function(){
				// 	context.appointment.plannable_id=window.$('.select1').val();
				// 	window.console.log(window.$('.select1').val()); 
				// });
			});
		}
	},
	mounted(){
        this.$store.dispatch('get','/leffit/plannables/plannables')
        .then(data=>{
        	console.log('plannables',data);
			this.plannables = data.data.data;
			var context=this;
			window.$('.select1').select2().change(function(){
				context.appointment.plannable_id=window.$('.select1').val();
				window.console.log(window.$('.select1').val()); 
			});
		}); 
	},
}
</script>

<style lang="css" scoped>
	
</style>