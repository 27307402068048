<template>
    <div class="col-md-3 tools">
        <div class="top" v-if="1==0">
            <div class="switch on"><span></span></div>

            <div class="icons">
                <a href=""><i class="fa fa-pencil"></i></a>
                <a href=""><i class="fa fa-edit"></i></a>
                <a href=""><i class="fa fa-eye"></i></a>
                <a href=""><i class="fa fa-edit"></i></a>
            </div>
        </div>
        <div class="collaps">
            
            <!-- <a data-toggle="collapse" href="#menuCollapse" class="menu active">
                <div class="ina">
                    <div class="title">HomePage</div>
                    <div class="menuIcons">
                        <a href="#"><i class="fa fa-trash"></i></a>
                        <a href="#"><i class="fa fa-plus"></i></a>
                        <a href="#"><i class="fa fa-pencil"></i></a>
                        <a href="#"><i class="fa fa-lock"></i></a>
                    </div>
                    <i class="fa fa-chevron-down colp-icon"></i>
                </div>

                <div class="collapse show" id="menuCollapse">
                        <div>
                            <a data-toggle="collapse" href="#menuCollapse" class="submenu">
                            <div class="ina">
                                <div class="title">HomePage</div>
                                <div class="menuIcons">
                                    <a href="#"><i class="fa fa-trash"></i></a>
                                    <a href="#"><i class="fa fa-plus"></i></a>
                                    <a href="#"><i class="fa fa-pencil"></i></a>
                                    <a href="#"><i class="fa fa-lock"></i></a>
                                </div>
                                <i class="fa fa-chevron-down colp-icon"></i>
                            </div>
                        </a>
                            <a data-toggle="collapse" href="#menuCollapse" class="submenu">
                            <div class="ina">
                                <div class="title">HomePage</div>
                                <div class="menuIcons">
                                    <a href="#"><i class="fa fa-trash"></i></a>
                                    <a href="#"><i class="fa fa-plus"></i></a>
                                    <a href="#"><i class="fa fa-pencil"></i></a>
                                    <a href="#"><i class="fa fa-lock"></i></a>
                                </div>
                                <i class="fa fa-chevron-down colp-icon"></i>
                            </div>
                        </a>
                            <a data-toggle="collapse" href="#menuCollapse" class="submenu">
                            <div class="ina">
                                <div class="title">HomePage</div>
                                <div class="menuIcons">
                                    <a href="#"><i class="fa fa-trash"></i></a>
                                    <a href="#"><i class="fa fa-plus"></i></a>
                                    <a href="#"><i class="fa fa-pencil"></i></a>
                                    <a href="#"><i class="fa fa-lock"></i></a>
                                </div>
                                <i class="fa fa-chevron-down colp-icon"></i>
                            </div>
                        </a>
                        </div>
                </div>
            </a> -->

            <div v-for="(modulepage, index) in menus" :key="index">
                <a  v-if="modulepage.parent_id == 0 && modulepage.children.length == 0"
                    class="menu"
                    :class="{ active : modulepage.id == $route.params.page_id}"
                    :href="'/admin/module/'+$route.params.module_id+'/page/'+modulepage.id"
                    :key="index">
                    <div class="ina">
                        <div class="title">
                            {{ modulepage.name }}
                        </div>
                        <div class="menuIcons" v-if="1==0">
                            <a href="#"><i class="fa fa-trash"></i></a>
                            <a href="#"><i class="fa fa-plus"></i></a>
                            <a href="#"><i class="fa fa-pencil"></i></a>
                            <a href="#"><i class="fa fa-lock"></i></a>
                        </div>
                        <i class="fa fa-chevron-down colp-icon" v-if="modulepage.children.length>0"></i>
                    </div>
                </a>

                <!-- <a  v-if="modulepage.parent_id == 0 && modulepage.children.length == 0" class="menu" v-for="(modulepage, index) in menus" :href="'/admin/module/'+$route.params.module_id+'/page/'+modulepage.id" :key="index"> -->

                <a  v-else-if="modulepage.parent_id == 0 && modulepage.children.length > 0"
                    class="menu active"
                    :class="{ active : modulepage.id == $route.params.page_id}"
                    data-toggle="collapse"
                    :href="'#menuCollapse_'+modulepage.id"
                    >
                    <div class="ina">
                        <div class="title">{{ modulepage.name }}</div>
                        <i class="fa fa-chevron-down colp-icon"></i>
                    </div>

                    <div class="collapse show" :id="'menuCollapse_'+modulepage.id">
                        <div>
                            <a :key="cindex" v-for="(modulechildpage, cindex) in modulepage.children"
                                :href="'/admin/module/'+$route.params.module_id+'/page/'+modulechildpage.id"
                                class="submenu mmn">
                                <div class="ina">
                                    <div class="title">{{ modulechildpage.name }}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </a>
            </div>

                <a class="menu" v-if="this.$route.path === '/admin/settings'">
                    <div class="ina">
                        <div class="title">HomePage 3 </div>
                        <div class="menuIcons">
                            <a href="#"><i class="fa fa-trash"></i></a>
                            <a href="#"><i class="fa fa-plus"></i></a>
                            <a href="#"><i class="fa fa-pencil"></i></a>
                            <a href="#"><i class="fa fa-lock"></i></a>
                        </div>
                        <i class="fa fa-chevron-down colp-icon"></i>
                    </div>
                </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name:'sidebarInner',
    data(){
        return {
            menus:[],
            current_module_id:false,
        }
    },
    computed: {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
        module_id :
        {
            get(){
                return this.current_module_id;
            },
            set(){
                this.current_module_id = this.$route.params.module_id;
            }
        },
        temp_module_id: function(){
            return this.$route.params.module_id;
        }
    },

    created(){
        if(this.$route.params.module_id){
            this.$store.dispatch('get',this.endpoint+'/lefficient/leffit/modulepages/module/'+this.temp_module_id)
            .then(data=>{
                this.menus = data.data.data;
            });
        }

        // this.$store.dispatch('get',this.$store.state.endpoint+'lefficient/leffit/modulepages')
        // .then((data)=>{
        //     this.menus=data.data.data;
        //     console.log(data);
        // })
        // .catch(error=>{
        //     console.log(error.request);
        // })
    },
    methods:{
        getlayouts(id){
        this.$store.dispatch('get',this.endpoint+'/lefficient/leffit/layouts/'+id)
        .then(data=>{
          //  this.$Debug('layout:',JSON.parse(data.layout));
            this.layout_data = JSON.parse(data.data.data.layout);
        });
      }
    }
}
</script>

<style scoped>
   
    .collaps{
        margin-top: 0px;
    }
    .collaps a.menu.active{
        background: var(--color-2);
    }
    .collaps a.menu:hover{
        background: var(--color-2);
    }
    .collaps a.menu{
        text-decoration: none;
        display: block;
        text-align: left;
        padding: 0px 0px;
        transition: all .3s ease-in-out;
        margin-bottom: 5px;
        padding-top: 0px;
    }
    .collaps a.menu .collapse{ }
   .collaps a.menu .collapse {
        background: var(--color-1);
        padding: 0px;
        border-radius: 0px;
    }
    .collaps a.menu .collapse  a.submenu{
        padding: 5px 0px 0px 15px;
        display: block;
        font-size: 14px;
    }
    
     .collaps a.menu .collapse  a.submenu .title{
        color: #b9b9b9;
    }
    
    .collaps a.menu .ina{
        position: relative;
        padding: 0px 10px;
    }

    .collaps a.menu .colp-icon{
        color: gray;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 18px;
        margin: auto;
        right: 10px;
        font-size: 12px;
        display: none;
    }
    .collaps a.menu.active .colp-icon{
        display: block;
        color: var(--color-1);
    }
    .collaps a.menu .title{
        color: gray;
        font-weight:500;
        margin: 0;
        min-height: unset;
    }
    .collaps a.menu.active .title{
        color: #ededed;
    }
    .collaps a.menu:hover .title{
        color: white;
    }
    .collaps a.menu .menuIcons{}
    .collaps a.menu .menuIcons a{
        color: lightgray;
        margin-right: 6px;
    }
    .collaps a.menu.active .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps a.menu:hover .menuIcons a{
        color: #e8e8e87d;
    }
    .collaps a.menu .menuIcons a:hover{
        color: gray;
    }
    .collaps a.menu .menuIcons a i.fa{}



    .icons{}
    .icons a{
        text-decoration: none;
        margin-right: 7px;
        padding: 0px 4px;
        border-radius: 13px;
    }
    .icons a i{  }

    .icons a:first-child{
        border:2px solid #fb9c7e70;
    }
    .icons a:first-child i{
        color: #fb9c7e70;
    }
    .icons a:nth-child(2){
        border:2px solid #4f546754;
    }
    .icons a:nth-child(2) i{
           color: #4f546754;
    }
     .icons a:nth-child(3){
        border:2px solid #d7e3f0;
    }
    .icons a:nth-child(3) i{
           color: #d7e3f0;
    }

    .icons a:nth-child(4){
        border:2px solid #17c0c873;
    }
    .icons a:nth-child(4) i{
           color: #17c0c873;
    }
    .top{
        display: flex;
        padding:5px 5px 10px;
        justify-content: space-between;
        white-space: nowrap;
    }
    .switch{
        background: var(--color-1);
        padding:10px 19px;
        border-radius: 10px;
        position: relative;
        margin-right: 5px;
    }
    .switch span{
       position: absolute;
        height: 79%;
        background: white;
        width: 17px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 1s linear;
    }
    .switch.on span{
        right: 2px;
    }
    .switch.off span{
        left: 2px;
    }

</style>
