export default `
<section class="six bg-white text-center pb-3" >
<div class="">
    <h2 id="section24header" class="h2 pt-3">++{{section24header}}</h2>
    <div class="row mt-3">
        <div class="col-sm-4 col-lg-4" >
            <img src="++{{section24Image1}}">
            <button id="section24Text1" class="btn pad btn-md mb-4 mt-3">++{{section24Text1}} </button> 
        </div>
        <div class="col-sm-4 col-lg-4">
            <img src="++{{section24Image2}}">
            <button id="section24Text2" class="btn pad btn-md mb-4 mt-3">++{{section24Text2}}</button>
        </div>
        <div class="col-sm-4 col-lg-4">
            <img src="++{{section24Image3}}">
            <button id="section24Text3" class="btn pad btn-md mb-4 mt-3">++{{section24Text3}} </button>
        </div>
    </div>
</div>
</section>

`
