<template>
    <div style="position:relative;height:100vh">  
        
        <div id="templateHolder" :class="'templateHolder'">  
            <section class="container" id="app">
                <section class="row">
                    <!-- <div class="col-sm-0 col-md-1 col-lg-2"></div>  -->
                    <div class="col-sm-12 col-md-12 col-lg-12 holder mb-5">
                        <div   v-for="(section,index) in sections" :key="index" :class="'temp'" >
                            <div class="sectionBody">
                                <!-- <p>{{section.name}}</p> -->
                                    <div  v-for="(row,indexr) in section.layout" :key="indexr"  class="sectionBody row m-0">
                                        <div   v-for="(col,indexc) in row" :key="indexc+'r'" :class="'part col-'+col.width" style="positive:relative">
                                            <!-- {{col.name}} -->
                                            <div v-if='render'
                                              
                                             v-for="(field,findex) in col.fields" 
                                             :key="findex" 
                                             class="field"> 

                                                <get-field-type-display
                                                     :data="getValue({
                                                        page_id:item.id,
                                                        pagetype_id:pagetype.id,
                                                        sectiontype_id:section.id,
                                                        blocktype_id:col.id,
                                                        field_id:field.id,
                                                        fieldtype_id:field.fieldtype.id,
                                                        section:0,
                                                        row:indexr,
                                                        column:indexc,
                                                     })" 
                                                     :fieldtype='field.fieldtype'>
                                                </get-field-type-display>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>  
        </div>   
    </div>
</template>
<script>
    import { mapState } from 'vuex'; 
    import getFieldTypeDisplay from './getfieldtypecomponent';
    export default {
        components:{
            'get-field-type-display':getFieldTypeDisplay, 
        },
        data(){
            return {
                item:{
                    name:'',values:[]
                },
                sections:[],
                loading:false,
                fieldtypes:[],
                pagetype:{},
                render:true, 
            }
        }, 
        computed:{
            ...mapState({
                editor_values:(state)=> state.leffit.editor_values,
                values:(state)=> state.leffit.values,
                showEditor:(state)=> state.leffit.showEditor,
                selected_field:(state)=> state.leffit.selected_field,
            })
        },
        methods:{ 
            getValue(data){
                var result=null;
                // console.log(data);
                result=this.item.values.find((d)=>
                     
                    (
                        d.page_id == data.page_id &&
                        d.pagetype_id == data.pagetype_id &&
                        d.sectiontype_id == data.sectiontype_id &&
                        d.blocktype_id == data.blocktype_id &&
                        d.field_id == data.field_id &&
                        d.fieldtype_id == data.fieldtype_id &&
                        d.section == data.section &&
                        d.row == data.row &&
                        d.column == data.column 
                    )
                );
                
                if(result != undefined){
                    result=JSON.stringify(result);
                    result=JSON.parse(result);
                }

                return result;
            },
            getPageType(id){
                this.$store.dispatch('get','/leffit/cms/pagetypes/'+id)
                .then(data=>{
                    console.log(data); 
                    this.pagetype=data.data.data;
                    var result=data.data.data;   
                        result=result.sectiontypes.map((sectiontype)=>{
                            var styp=sectiontype.blocktypes.map((blocktype)=>{
                                var btyp = blocktype.fields.map((field)=>{
                                return field.fieldtype;
                                }); 
                                return {
                                    data:btyp,
                                    ...blocktype
                                }
                            }); 
                            return {
                                data:styp,
                                ...sectiontype
                            }
                        }); 
                    this.sections=result;
                    console.log(result);

                    var settings='';
                    result=result.map((d)=>{
                        settings=JSON.parse(d.settings);
                        if(settings.layout != undefined){
                            var layout=settings.layout; 
                            layout=layout.map((row)=>{
                                row=row.map((col)=>{
                                    var block=d.data.find((blk)=>blk.id ==  col.blocktype_id);
                                    block=JSON.stringify(block);
                                    block=JSON.parse(block);
                                    block.width=col.width;
                                    return block;
                                });
                                return row;
                            });
                            layout=layout.filter((d)=> d!= null);
                            d.layout=layout;
                        }
                        return d;
                    });
                }); 
            }, 
        },
        mounted(){
            this.$store.dispatch('get','/leffit/cms/pages/'+this.$route.params.id)
            .then((data)=>{
                console.log(data);
                this.item=data.data.data;
                this.getPageType(this.item.pagetype_id); 
            })
            .catch((error)=>{
                console.log(error.request);
            });
            this.$store.state.leffit.page_preview=true;
        },
    }
</script>
<style scoped>
#editor{
        height: 400px;
        width: 70%;
        background: white;
        position: fixed;
        bottom: 0;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        -webkit-box-shadow: 0px -4px 5px #e6e6e6;
        box-shadow: 0px -4px 5px #e6e6e6;
        margin: auto;
        /* left: 0; */
        right: 0;
        padding: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    #editor.hide{
        height: 0px; 
        padding: 0;
    }
    .img-uploader{
        border: 1px solid lightgray;
        padding: 20px 20px;
        text-align: center;
        color: grey;
        height: 200px;
        width: 250px;
        border-radius: 4px;
        background-size: cover; 
    }
     
    .tool-bottom{
        bottom:0 !important;
        top:unset !important;
    }
    .sectionBody{ 
        background: white;
        padding: 2px;
    }
    .sectionBody p{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
</style>
<style scoped> 
    .sectionBody p{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    } 
</style>
<style scoped> 
        .container-fluid{
            padding: 0px 10px;
        }
        .tols{
            display: flex;
            justify-content: flex-end;
        }
        .templateHolder{ 
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: white;
            margin: 0;
            height: 100%;
            z-index: 99999999;
            overflow: scroll;
        }
        .editBoard{
            position: absolute;
            top:0%;
            bottom: 5%;
            right: 0;
            width: fit-content;
            height: fit-content;
            margin: auto;
            width: 500px; 
            padding: 10px;
            height: 94%;
            overflow-y: scroll;
            background: #f2f2f2;
        }
        .editBoard h4{
            font-size: 16px;
            color: gray;
        }
        .templateHolder.edit-mode{
            right: initial;
            left: 10px;
            transition: all .5s linear;
            margin-left: 0px;
        }
        .defaultTemp{
            border:1px solid lightgray;
            text-align: center;
            padding: 30px 10px;
            transition: all .2s linear;
            margin-top: 30px;
        } 
         
        .tools{
            padding: 10px 5px;
            top: -80px;
            background: #333333;
            position: absolute;
            width: 100%;
            z-index: 99;
            color: white;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tools .title{
            display: flex;
            height: 40px;
            margin: 0px;
        }
        .tools .title div.title{
            font-size: 20px;
            height: 100%; 
            display: block;
            margin-left: 20px;
            padding-top:8px;
        }
        .tool{ 
            color: gray;
            font-weight: 500;
            font-size: smaller; 
        }
        .temp{
            position: relative;  
        } 
        .sectionTools{
            position: absolute;
            top:0;
            right:0;  
            z-index: 9;
            display: none;
        }
        .sectionTools button.btn{
            background: #000000cc;
            color: white;
            border-radius: 0px;
        }
        .editBody{
            margin-top:30px;
        }
        .uploadImage{
            width:150px;
            height:150px;
            border:2px solid lightgray;
            background: #d3d3d3c9;
            position: relative;
            transition: all .1s linear;
        } 
        .uploadImage span{
            position: absolute;
            top:0;
            bottom: 0;
            left:0;
            right:0;
            margin: auto;
            height: fit-content;
            width: fit-content;
            color: gray;
        }
        .textEditor{
            margin-top: 20px;
            text-align: left;
        }
        .textEditor label{
            color:red;
        }
        .editFooter{
            /* position: absolute; */
            /* bottom: 10px; */
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .editFooter .btn{
            border-radius: 1px;
        }
       
</style>
<style scoped>
    div img{
        width:100% !important;
    }
</style>