<template>
	<router-view :key="$route.path+'_settings_'+selected_language" />
</template>
<script>
export default {
	data(){
		return {
			selected_language:''
		}
	}
}
</script>