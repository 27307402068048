<template>
	<div class="row" id="mainBody">
		<div class="col-lg-10 col-md-9 mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
  	name: 'index',
}
</script>

<style lang="css" scoped>
</style>