<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},

  	data () {
    	return {
    		menu : [
    			{
    				title 	: 'Programs',
    				url 	: '/admin/courses/programs',
    				children : [],
                }, 
                {
    				title 	: 'Modules',
    				url 	: '/admin/courses/modules',
    				children : [],
                },
                {
    				title 	: 'Lessons',
    				url 	: '/admin/courses/lessons',
    				children : [],
                },
				{
    				title 	: 'Files',
    				url 	: '/admin/courses/files',
    				children : [],
				},
				{
    				title 	: 'Sections',
    				url 	: '/admin/courses/sections',
    				children : [],
                }
    		],
    	}
  	}
}
</script>

<style lang="css" scoped>
</style>