<template>
    <div v-if="this.templateModal" id="templateModal">
        <div class="head">
            <h3>Choose Template</h3>

            <div class="search"><input type="text" class="form-control" placeholder="Search template by name"></div>
            <div class="remove"><button @click="removeModal()" class="btn"><i class="fa fa-remove"></i></button></div>
        </div>
        <hr>
        <div class="body"> 
            <div class="temps">
                <div @click="processSection(temp)" v-for="(temp,index) in section_types" :key="'section'+index" class="temp">
                    <div class="img" >
                        <div class="preview" >
                            <div class="previewContainer">
                                
                            </div>
                        </div>
                    </div>
                    <h5>{{temp.name}}</h5>
                </div> 
                <div @click="selectTemp(temp.id)" v-for="(temp,index) in templateViews" :key="index" class="temp">
                    <div class="img" >
                        <div class="preview" >
                            <div class="previewContainer"
                             v-html="temp.content.split('++').map((tct)=>{ 
                                for(let c=0;c<temp.fields.length;c++){
                                    tct=tct.replace('{{'+temp.fields[c].element+'}}',temp.fields[c].content)
                                }
                                return tct;
                             }).join('')"
                             ></div>
                        </div>
                    </div>
                    <h5>{{temp.title}}</h5>
                    <p class="m-0">
                        {{temp.text}}
                    </p>
                </div>  
            </div>
        </div>
    </div>
</template>

<script> 
import { mapState} from "vuex";
export default {
    name:"mailTemplates",
    components:{
        
    },
    data(){
        return {
            section_types:[]
        }
    },
    computed:{
        ...mapState({
            templateModal:state=>state.leffit.templateModal,
            templateViews:state=>state.leffit.templateViewData,
            activeRow:state=>state.leffit.activeRow,
            sections:state=>state.leffit.sections
        })
    },
    methods:{
        processSection(section){
            var settings=JSON.parse(section.settings); 
            if(settings.length == 0){
                alert('This custom section has not been buildt.');
                return false;
            }
            var temp={};
            var align={left:'mr-auto',right:'ml-auto',center:'m-auto'};

            var content='';
            var fields=[];
            temp.mode='initial';
            temp.id=section.id; 
            temp.description=settings.description;
            temp.title=section.name;
            
            settings.layout.forEach((element,index)=>{
                var sub_el='';
                var sub2='';
                element.forEach((column) => {
                    sub_el='';
                    if(typeof column.settings == 'string'){
                        column.settings=JSON.parse(column.settings);
                    }

                    var classes=this.buildColumnClass(column); 
                    var styles=this.buildColumnStyle(column); 

                    classes=classes.replace('null','');
                    classes=classes.replace(null,'');
                    styles=styles.replace('null','');
                    styles=styles.replace(null,'');

                    if(column.settings.column){
                        if(column.settings.column.container.align){ 
                            classes+=' '+align[column.settings.column.container.align];
                        }
                        if(column.settings.column.container.type == 'fluid'){ 
                            classes+=' container-fluid';
                        }
                    }

                    sub_el+=this.bindValuesToContent(column);
                    console.log(column);
                    fields.push(...column.settings.fields);

                    column.rows.forEach((row)=>{
                        var sett=JSON.parse(row.settings);
                        fields.push(...sett.fields);
                        sub_el+=this.bindValuesToContent(row);
                    });
                    sub2+="<div class='col-12 col-md-"+column.width+' '+classes+"' style='"+styles+"'>"+sub_el+"</div>";
                });
                content+="<div class='row'>"+sub2+"</div>";
            });
            

            content="<div class=''>"+content+"</div>";
            temp.content=content; 
            fields=this.processStyleReference(fields);
            temp.fields=fields; 
            
            console.log(temp);
            
            this.addTemp(temp);
        },
        processStyleReference(fields){
            var styles_data={'color':'color','font-size':'size','font-family':'family','text-align':'align'};
            var main_fields=fields.filter((field)=> field.ref == '').map((field)=>{ field.styles={}; return field;});
            var style_fields=fields.filter((field)=> field.ref != '' && field.style_name);

            main_fields=main_fields.map((m_field)=>{
                style_fields
                    .filter((s_field)=> s_field.ref == m_field.element)
                    .forEach((fd)=>{ m_field.styles[styles_data[fd.style_name]]=fd.content; });

                return m_field;
            });
            return main_fields;
        },
        removeModal(){
            this.$store.state.leffit.templateModal=false;
        },
        selectTemp(id){
            let selected=this.templateViews.find(tp=>tp.id == id);
            selected=JSON.stringify(selected);
            selected=JSON.parse(selected);
            this.addTemp(selected);
        }, 
        addTemp(selected){
            selected.unique_id=Math.round(Math.random() * 9999);
            this.$store.state.leffit.activeSection=selected;
            if(this.sections[this.activeRow] == null){
                this.$store.state.leffit.sections[this.activeRow]={};
            }
            var sections=this.$store.state.leffit.sections[this.activeRow].sections;
            
            //deciding the position of the new section
            var last_section=sections[sections.length-1];
            var first_section=sections[0];
            
            if(sections.length > 0){
                if(last_section.classes.find((d)=> d == 'ml-auto' ) && !first_section.classes.find((d)=> d == 'mr-auto' )){
                    sections.unshift(selected);
                }else if(last_section.classes.find((d)=> d == 'ml-auto' ) && first_section.classes.find((d)=> d == 'mr-auto' )){
                    var temp=sections.slice(0,sections.length-1);
                    sections=[...temp,selected,sections[sections.length-1]];
                }else{
                    sections.push(selected);
                }
            }
            else{
                sections.push(selected);
            }
            this.$store.state.leffit.sections[this.activeRow].sections=sections;
            
            this.$store.state.leffit.templateModal=false;
            this.$parent.bindValues(this.$store.state.leffit.sections);
        },
        bindValuesToContent(field){   
            var data=typeof field.settings == 'string' ? JSON.parse(field.settings):field.settings; 
            if(data == null || data.fields == undefined){
                return field.name;
            }
            var fields=data.fields;
            var content=data.blueprint;
            data.styles=this.buildStyles(data);
            data.classes=this.buildClasses(data);

            fields.map((field)=>{
                content=content.replace('id++{{'+field.element+'}}',field.element); //replace only ID attribute
            });
            content=content.replace("++{{styles}}",data.styles);
            content=content.replace("++{{classes}}",data.classes);
            // content=content.replace("++{{id}}",data.classes);
            data.content=content;
            return data.content;
        },
        buildColumnClass(col){
            var classes="";
            var settings=col.settings ? col.settings.column : null;

            if(settings == null){
                return '';
            }

            for(var p in settings.padding){ classes+=settings.padding[p]+' '; }
            for(var m in settings.margin){ classes+=settings.margin[m]+' '; }

            var align={left:'mr-auto',right:'ml-auto',center:'m-auto'};
            if(settings.container.align){
                classes+=align[settings.container.align];
            } 
            if(settings.container.type == 'fluid'){
                classes+=' container-fluid';
            }
            return classes;
        },
        buildColumnStyle(col){
            var styles="";
            var settings=col.settings ? col.settings.column : null;
            if(settings == null){
                return '';
            }
            styles+="background-color:"+settings.background.backgroundColor+";";
            styles+="background-image:"+settings.background.backgroundImage+";";
            styles+="background-position:"+settings.background.position+";";
            styles+="background-size:"+settings.background.size+";";
            
            return styles;
        },
        buildStyles(data){
            var styles=''; 
            var style_fields=data.fields.filter((field)=> field.style_name != undefined);
            style_fields.forEach(element => {
                if(element.type == 'number'){
                    styles+=element.style_name+':'+element.content+'rem !important;';
                }else{
                    styles+=element.style_name+':'+element.content+';';
                } 
            });
            return styles;
        },
        buildClasses(data){ 
            var classes='';
            var class_fields=data.fields.filter((field)=> field.class != undefined);
            class_fields.forEach(element => { 
                classes+=element.content+' ';
            });
            return classes;
        },
    },
    created() {
        let button=0;
        
        document.onkeyup=(e)=>{
            button=e.which || e.button; 
            // console.dir(e.target);
            if(button == 27 && this.templateModal && e.target.nodeName != 'INPUT'){
                
                this.$store.state.leffit.templateModal=false;
            }
        }
        this.$store.dispatch('get','/leffit/cms/sectiontypes')
        .then(data=>{
            console.log(data);
            this.section_types=data.data.data.filter((d)=> d.type == 'course');
        })
    },
}
</script>

<style scoped>
   
    #templateModal{
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
        background: white;
        box-shadow: 0px 0px 10px #808080ba;
        width: 95%;
        height: 95%;
        padding: 20px;
        border-radius: 5px;
        z-index: 9999;
    }

    #templateModal .head{
        display: flex;
        justify-content: space-between;
    }
    #templateModal .head h3{
        color: gray;
        font-size: 20px;
    }
    #templateModal .head .search{
        width: 400px;
    }

    #templateModal .temps{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        overflow-y: scroll;
        padding: 10px;
        height:650px;
    }
    #templateModal .temps .temp{
        height: 350px;
        box-shadow: 1px 3px 5px #b8b8b8;
        padding: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        transition: all .2s linear;
        position: relative;
        
    }
    #templateModal .temps .temp:hover{
        box-shadow: 2px 7px 10px lightgray;
        cursor: pointer;
    }
     #templateModal .temps .temp:hover .img{
        transform: scale(0.98);
        border-radius: 2px; 
    }
    #templateModal .temps .temp .img{
        height: 60%;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        transition: all .2s linear;
        text-align: center;
        display: flex;
        justify-content: center;
        background: #333;
        overflow: hidden;
        border: 2px solid #333333;
    }   
    #templateModal .temps .temp .img .preview{
        transform: scale(0.4) !important;
    }
    .previewContainer{
        background: white;
        padding: 25px 0px;
    }
    #templateModal .temps .temp h5{
        color: black;
        margin-top: 10px;
        font-size: 15px;
    }
    #templateModal .temps .temp p{
        color: gray;
        font-size: 13px;
        text-align: justify;
    }
</style>
<style  scoped>
    @media (max-width:1000px){
        #templateModal .temps{ 
            grid-template-columns: 1fr 1fr 1fr; 
        }
    }

    @media (max-width:760px){
        #templateModal .temps{ 
            grid-template-columns: 1fr 1fr; 
        }
    }
    @media (max-width:400px){
        #templateModal .temps{ 
            grid-template-columns: 1fr; 
        }
    }
</style>


<style type="text/css" scoped>
   
    .text-align{
        text-align: center;
    }
    #app{ 
        text-align: center;
        font-family: Helvetica;
    }
    #app .holder{
        padding: 0;
    }

    .first{
        border: 1px solid;
        height: 10em;
    }
    #app .first .href{
        height: 100%;
        padding-top: 8em;
    }
    #app .first .href a{
        text-decoration: underline;	
        color: #111;
        font-size: 12px;
    }
    #app .third{ 
        border-bottom: 1px solid black;
        color: #404040;
    }
    #app  .h2{
        color: #E95D70;
        font-weight: bold;
        font-size: 25px;
    }
    #app .p{
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #404040;
    }
    #app .four{
        text-align: left;
        border-bottom: 1px solid #111;
    }

    #app .four .h2{
        color: #E95D70;
        font-weight: bold;
        font-size: 25px;
    } 
    #app .four .p{
        font-size: 15px;
        line-height: 18px !important;
        letter-spacing: 0.3px !important;
    }
    #app .five{
        border-bottom: 1px solid #111;
    }

    #app .five .btn{
        border-radius: 0;
        background-color:  #E95D70;
        color: #fff;
    }
    #app .six{
        border-bottom: 1px solid #111
    }
    #app .six img{
        height:auto !important; 
        width:100% !important;
    }
    #app .six .p{
        margin-top: 15px;
    }
    #app .seven a{
        color: #E95D70;
        text-decoration: underline;
    }
</style>