var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","height":"100vh"}},[_c('div',{class:'templateHolder',attrs:{"id":"templateHolder"}},[_c('section',{staticClass:"container",attrs:{"id":"app"}},[_c('section',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 holder mb-5"},_vm._l((_vm.sections),function(section,index){return _c('div',{key:index,class:'temp'},[_c('div',{staticClass:"sectionBody"},_vm._l((section.layout),function(row,indexr){return _c('div',{key:indexr,staticClass:"sectionBody row m-0"},_vm._l((row),function(col,indexc){return _c('div',{key:indexc+'r',class:'part col-'+col.width,staticStyle:{"positive":"relative"}},_vm._l((col.fields),function(field,findex){return (_vm.render)?_c('div',{key:findex,staticClass:"field"},[_c('get-field-type-display',{attrs:{"data":_vm.getValue({
                                                    page_id:_vm.item.id,
                                                    pagetype_id:_vm.pagetype.id,
                                                    sectiontype_id:section.id,
                                                    blocktype_id:col.id,
                                                    field_id:field.id,
                                                    fieldtype_id:field.fieldtype.id,
                                                    section:0,
                                                    row:indexr,
                                                    column:indexc,
                                                 }),"fieldtype":field.fieldtype}})],1):_vm._e()}),0)}),0)}),0)])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }