<template>
  <div id="generator">
    <div class="container">
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Import / Export
              </button>
            </h2>
          </div>

          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <label>Paste string</label>
                    <input id="importString" type="text" class="form-control" v-model="import_string">
                  </div>
                </div>
                <div class="col-4">
                  <button type="button" @click="import_function()" class="btn btn-primary btn-lg" v-if="import_string">Import</button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Faker test</label>
                    <br>
                    <button type="button" @click="fakerTestFields()" class="btn btn-primary btn-lg">Faker Test</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Export String</label>
                    <br>
                    {{ export_string }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                Model
              </button>
            </h2>
          </div>
          <div id="collapseTwo" class="collapse " aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Foldername:</label>
                    <input type="text" class="form-control" v-model="model.folder">
                  </div>

                  <div class="form-group">
                    <label>Namespace:</label>
                    <input type="text" class="form-control" v-model="model.namespace">
                  </div>
                  <div class="form-group">
                    <label>Modelname: First letter uppercase!</label>
                    <input type="text" class="form-control" v-model="model.name">
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label>Plural:</label><br>
                      <input type="checkbox" v-model="model.plural_custom"> Use custom
                    </div>
                    <div class="form-group col-md-9" v-if="model.plural_custom">
                      <label>Plural name:</label><br>
                      <input type="text" class="form-control" v-model="model.plural">
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Timestamps:</label><br>
                    <input type="checkbox" v-model="model.timestamps"> Use Timestamps
                  </div>

                  <div class="form-group">
                    <label>Softdeletes:</label><br>
                    <input type="checkbox" v-model="model.softDeletes"> Use Softdeletes
                  </div>

                  <div class="form-group">
                    <label>Enable Search:</label><br>
                    <input type="checkbox" v-model="model.search"> Add Search route
                  </div>

                  <div class="form-group">
                    <label>Hide base64:</label><br>
                    <input type="checkbox" v-model="model.base64_hide"> Remove base64 from model
                  </div>

                  <div class="form-group">
                    <label>Builder:</label><br>
                    <input type="checkbox" v-model="model.builder"> Use Leffit Builder
                  </div>

                  <div class="form-group">
                    <label>Project URL:</label><br>
                    <input type="text" v-model="model.project" class="form-control"> Post to project url
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <label>Permissions:</label><br>
                      <input type="checkbox" v-model="model.permissions"> Use Permissions
                    </div>
                    <div class="form-group col-md-9" v-if="model.permissions">
                      <label>Create extra permissions separate with | :</label><br>
                      <input type="text" class="form-control" v-model="model.extrapermissions">
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button  class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Fields
              </button>
            </h2>
          </div>
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Databasetype</th>
                    <th>Round</th>
                    <th>Required</th>
                    <th>Unique</th>
                    <th>Nullable</th>
                    <th>Default value</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="new_field.name">
                    </th>
                    <th class="text-center">
                      <select class="form-control" name="" id="" v-model="new_field.fieldtype">
                            <option v-for="(ft,index) in fieldtypes" :key="index+'_fieldtype'" :value="ft.label">{{ft.type}}</option>
                      </select> 
                    </th>
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="new_field.round" v-if="new_field.fieldtype == 'Decimal'">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="new_field.required">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="new_field.unique">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="new_field.nullable">
                    </th>
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="new_field.default">
                    </th>

                    <th class="text-center">
                      <button @click="addNewField()" class="btn btn-primary"><i class="fas fa-plus"></i></button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr draggable="true" class="drag" :key="index+'_fields_field'" v-for="(field, index) in fields" >
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="field.name">
                    </th>
                    <th class="text-center">
                      <select class="form-control" name="" id="" v-model="field.fieldtype">
                            <option v-for="(ft,index) in fieldtypes" :key="index+'_fieldtype'" :value="ft.label">{{ft.type}}</option>
                      </select> 
                    </th>
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="field.round" v-if="field.fieldtype == 'Decimal'">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="field.required">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="field.unique">
                    </th>
                    <th class="text-center">
                      <input type="checkbox" class="form-control" v-model="field.nullable">
                    </th>
                    <th class="text-center">
                      <input type="text" class="form-control" v-model="field.default">
                    </th>

                    <th class="text-center">
                      <button @click="addNewField()" class="btn btn-primary"><i class="fas fa-plus"></i></button>
                    </th> 

                    <td>
                      <div class="btn-group">
                        <button v-if="index > 0" @click="reOrder('up',index)" class="btn btn-success"><i class="fas fa-chevron-up"></i></button>
                        <button v-if="index < fields.length -1" @click="reOrder('down',index)" class="btn btn-primary"><i class="fas fa-chevron-down"></i></button>
                        <button @click="editField(field.id)" class="btn btn-warning"><i class="fas fa-pen"></i></button>
                        <button @click="removeField(field.id)" class="btn btn-danger"><i class="fas fa-trash"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card" v-if="model.builder">
          <div class="card-header" id="headingFieldtypes">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsgFieldtypes" aria-expanded="false" aria-controls="collapsgFieldtypes">
                Fieldtypes
              </button>
            </h2>
          </div>
          <div id="collapsgFieldtypes" class="collapse" aria-labelledby="headingFieldtypes" data-parent="#accordionExample">
            <div class="card-body">
              <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Fieldtype</th>
                    <th>Extra</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="fieldindex+'_fieldtype'" v-for="(field, fieldindex) in fields">
                    <td>
                      {{field.name}}
                    </td>
                    <td>
                      <select class="form-control" v-model="field.fieldInput">
                        <option></option>
                        <option :key="index+'fieldInputTypes'" v-for="(input, index) in fieldInputTypes" :value="input.name">
                          {{ input.label }}
                        </option>
                      </select>
                    </td>
                    <td>
                      {{field.fieldtypeInputSettings}}
                      <div v-if="field.fieldtypeInput">
                        {{ field.fieldtypeInput.settings }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>



        <div class="card">
          <div class="card-header" id="headingSeven">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsgSeven" aria-expanded="false" aria-controls="collapsgSeven">
                Relations
              </button>
            </h2>
          </div>
          <div id="collapsgSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
            <div class="card-body">
              <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Model</th>
                    <th>Type</th>
                    <th>Extra</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>
                      <input type="text" class="form-control" v-model="new_relation.name">
                    </th>
                    <th><input type="text" class="form-control" v-model="new_relation.model"></th>
                    <th>
                      <!-- dropdown with relation types oneToMany manyToMany etc, please implement like fieldtypes (so with an id field, and label) -->
                      <select v-model="new_relation.type" class="form-control">
                        <option :key="index+'_relationtype'" v-for="(rt,index) in  relationtypes" :value="rt">{{rt}}</option>
                      </select>
                    </th>
                    <th>
                      <!-- this field is used to add ->orderBy('') etc -->
                      <input type="text" class="form-control" v-model="new_relation.extra">
                    </th>

                    <th>
                      <button @click="addNewRelation()" class="btn btn-primary"><i class="fas fa-plus"></i></button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index+'_relation'" v-for="(relation, index) in relations">
                    <td>
                      <input type="text" class="form-control" v-model="relation.name">
                    </td>
                    <td>
                      <input type="text" class="form-control" v-model="relation.model">
                    </td>
                    <td>
                      <select v-model="relation.type" class="form-control">
                        <option :key="index+'_relationtype'" v-for="(rt,index) in  relationtypes" :value="rt">{{rt}}</option>
                      </select>
                    </td>
                    <td>
                      <input type="text" class="form-control" v-model="relation.extra">
                    </td>
                    <td>
                      <div class="btn-group">
                        <button @click="editRelation(relation.id)" class="btn btn-warning"><i class="fas fa-pen"></i></button>
                        <button @click="removeRelation(relation.id)" class="btn btn-danger"><i class="fas fa-trash"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFour">
            <h2 class="mb-0">
              <button  class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Swagger
              </button>
            </h2>
          </div>
          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Swagger</th>
                  </tr>
                </thead>
                <tbody>
                  <tr draggable="true" class="drag" :key="index+'_swagger_field'" v-for="(field, index) in fields" @dragenter="dragEnter(field)" @dragend='changePostion(field)' @dragstart='dragStart(field)' >
                    <td>{{field.name == ''? '.....':field.name}}</td>
                    <td>
                      <input type="text" class="form-control" v-model="field.swagger">
                    </td>
                  </tr>

                  <tr :key="index+'_swagger_relation'" v-for="(relation, index) in relations">
                    <td>
                      {{relation.name}}()
                    </td>
                    <td>
                      <input type="text" class="form-control" v-model="relation.swagger">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFive">
            <h2 class="mb-0">
              <button  class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Permissions
              </button>
            </h2>
          </div>
          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Roles</th>
                    <th>Permissions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr draggable="true" class="drag" :key="index+'_field_permission'" v-for="(field, index) in fields" @dragenter="dragEnter(field)" @dragend='changePostion(field)' @dragstart='dragStart(field)' >
                    <td class="text-center">{{field.name == ''? '.....':field.name}}</td>
                    <td class="text-center">
                      <input type="text" class="form-control" name="" id="" v-model="field.roles">
                    </td>
                    <td class="text-center">
                      <select class="form-control" v-model="field.permissions">
                        <option></option>
                        <option v-for="(extrapermission, pindex) in extrapermissions" :key="pindex" :value="extrapermission">{{ extrapermission }}</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="card">
          <div class="card-header" id="headingSix">
            <h2 class="mb-0">
              <button  class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Factory
              </button>
            </h2>
          </div>
          <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Factory</th>
                  </tr>
                </thead>
                <tbody>
                  <tr draggable="true" class="drag" :key="index" v-for="(field, index) in fields" >
                    <td class="text-center">{{field.name == ''? '.....':field.name}}</td>
                    <td class="text-center">
                      <select class="form-control" v-model="field.factory">
                        <option v-for="(faker, index) in fakeroptions" :key="index+'_field_faker'" :value="faker.value">{{ faker.label }}</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
          <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">BASE64</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div  class="modal-body ">
                    <button type="button" @click="doCopy" class="btn btn-primary mb-3">Copy!</button>
                    <button type="button" @click="doPost" class="btn btn-danger mb-3" v-on:submit.prevent>Post!</button>
                    <p style="background:#d3d3d366;border:1px solid lightgray" class="p-3">
                        php artisan leffit:crud {{this.base64}}
                    </p>
                    
                  </div>
                  <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div> -->
                </div>
              </div>
            </div>
          <div class="card-header" id="headingSeven">
           <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="create()" >CREATE</button>
          </div> 
      </div>
    </div>
  </div>
</template>

<script>
import options_faker from './options/faker.js'
import options_fieldtypes from './options/fieldtypes.js'
import options_inputTypes from './options/inputTypes.js'
export default {
  name: 'app',
  data(){
    return {
      import_string : '',
      model : {
        "name"              :   "",
        "folder"            :   "leffit/cms",
        "namespace"         :   "Leffit\\Cms",
        "plural_custom"     :   false,
        "plural"            :   "",
        "softDeletes"       :   true,
        "search"            :   true,
        "timestamps"        :   true,
        "permissions"       :   true,
        "extrapermissions"  :   "",
        "builder"           :   true,
        "roles"             :   "",
        "base64_hide"       :   0,
        "project"           :   "",
      },
      fields : [],
      fakeroptions : options_faker,
      new_field : {
        "name"                : "",
        "fieldtype"           : "",
        "fieldInput"          : "",
        "fieldInputSettings"  : "",
        "round"         : 2,
        "required"      : false,
        "unique"        : false,
        "nullable"      : false,
        "default"       : "",
        "swagger"       : "",
        "roles"         : "",
        "permissions"   : "",
        "factory"       : "",
      },
      clean_new_field : {
        "name"                : "",
        "fieldtype"           : "",
        "fieldInput"          : "",
        "fieldInputSettings"  : "",
        "round"         : 2,
        "required"      : false,
        "unique"        : false,
        "nullable"      : false,
        "default"       : "",
        "swagger"       : "",
        "roles"         : "",
        "permissions"   : "",
        "factory"       : "",
      },
      relations:[],
      relationtypes : ['belongsTo','belongsToMany','hasMany', 'hasOne'],
      new_relation : {
        "name"        : "",
        "type"        : "",
        "extra"       : "",
        "swagger"     : "",
        "model":''
      },
      fieldtypes : options_fieldtypes,
      fieldInputTypes : options_inputTypes,
      base64:'',
      draggedField:{},
      droppedOnField:{},
      droppedAt:0,
      droppedFirst:0
    }
  },
  methods:{
    reOrder(direction,index){
        var data=this.fields;
        if(direction == 'up'){
            var temp=data[index];
            data[index]=data[index-1];
            data[index-1]=temp;
        }else if(direction == 'down'){
            temp=data[index];
            data[index]=data[index+1];
            data[index+1]=temp;
        } 
        this.fields=[];
        this.fields=data;
    },
    dragEnter(field,first=0){ 
      this.droppedOnField=field;
      this.droppedFirst=first;
    },
    changePostion(field){ 
      let before=[];
      let after=[];
 
      before=this.cutList(field.id,this.droppedAt);
      // console.log(before);
      after=this.cutList(field.id,this.droppedAt);
      // console.log(after);
      this.fields=this.droppedFirst == 0 ? [...before,this.draggedField , ...after]:[this.draggedField , ...before , ...after];
      this.droppedAt=0;
    },
    dragStart(field){
      this.draggedField=field;
    },
    cutList(id , index){

      let temp=[]; 
      for(var count=index;count<this.fields.length;count++){
          // index= reverse == 0 ? count : this.fields.length - (count+1);

          if(this.fields[count].id != this.draggedField.id)
          { temp.push(this.fields[count]);}

          if(this.fields[count].id == this.droppedOnField.id ){
            this.droppedAt=count+1;
            break;
          } 
      } 

      return temp;
    },
    create(){
      let payload={};
      payload.fields=this.fields;
      payload.relations=this.relations;
      payload.model=this.model;
      payload.import_String=this.import_string;

      let json = JSON.stringify(payload);
      this.base64 = Buffer.from(json).toString("base64");
      // this.base64='php artisan command:crud '+this.base64;
    },
    removeField(index){
      console.log(index);
        this.fields=this.fields.filter((fd)=> index != fd.id);
    },
    editField(index){
        var selected={};

        this.fields=this.fields.filter((fd)=> {
          if(index == fd.id){
            console.log(fd);
            selected=fd;
            return false; 
          }
          return true;
        });

        this.new_field=selected; 
    },
    import_function(){ 
      this.export = decodeURIComponent(escape(window.atob(this.import_string)));
      this.export=JSON.parse(this.export);
      this.fields=this.export.fields;
      this.model=this.export.model;
      this.relations=this.export.relations;
    },
    addNewField : function(){
      if(this.new_field.nullable){
        this.new_field.default = "";
      }
      this.new_field.swagger = this.model.name + ' ' + this.new_field.name + ' attribute';

      var fieldtype = this.new_field.fieldtype.toLowerCase().replace(" ", "");
      
      this.new_field.factory = fieldtype;
      this.new_field.fieldInput = fieldtype;

      if(fieldtype=='string'){
        this.new_field.fieldInput = 'text';
      }
      if(fieldtype=='id'){
        this.new_field.fieldInput = 'hidden';
      }

      if(fieldtype=='longtext'){
        this.new_field.fieldInput = 'wysiwyg';
        this.new_field.factory = 'text';
      }
      if(fieldtype=='double'){
        this.new_field.factory = 'randomFloat';
      }

      this.new_field.id=this.fields.length;
      this.fields.push(this.new_field);

      this.new_field= {
        "name"                : "",
        "fieldtype"           : "",
        "fieldInput"          : "",
        "fieldInputSettings"  : "",
        "round"         : 2,
        "required"      : false,
        "unique"        : false,
        "nullable"      : false,
        "default"       : "",
        "swagger"       : "",
        "roles"         : "",
        "permissions"   : "",
        "factory"       : "",
      };
 
    },
    removeRelation(index){
        this.relations=this.relations.filter((fd)=> index != fd.id);
    },
    editRelation(index){
        var selected={};

        this.relations=this.relations.filter((fd)=> {
          if(index == fd.id){
            selected=fd;
            return false; 
          }
          return true;
        });

        this.new_relation=selected; 
    },
    addNewRelation: function(){  
      this.new_relation.id=this.relations.length;
      this.relations.push(this.new_relation);
      this.new_relation={"name" : "","type" : "","extra" : "","swagger" : "",};
    }, 

    doCopy: function () {
     navigator.clipboard.writeText('php artisan leffit:crud '+this.base64)
      .then(() => {
        // alert('Text copied to clipboard');
      })
      .catch(err => {
        // This can happen if the user denies clipboard permissions:
        alert('Could not copy text: ', err);
      });
    },
    doPost: function(){
      this.$store.dispatch('post', {details:{base64:this.base64},endpoint:'/leffit/generator'})
        .then((data)=>{
            console.log(data);
        })
        .catch(error=>{
            console.log(error.request);
        })
    },
    fakerTestFields: function(){
      let field;
      let fields_tmp = this.fields;
      this.model = {
        "name"              :   "FakerTest",
        "folder"            :   "",
        "plural_custom"     :   false,
        "plural"            :   "",
        "softDeletes"       :   true,
        "search"            :   true,
        "timestamps"        :   true,
        "permissions"       :   true,
        "extrapermissions"  :   "",
        "builder"           :   true,
        "roles"             :   "",
        "base64_hide"       :   0,
      };
      this.fakeroptions.forEach(function(tmp){
        if(tmp.value.length>0){
          field = {
                    "name"                : tmp.label.replace(/[^A-Za-z0-9\s!?]/g,'').replace(/ /g,""),
                    "fieldtype"           : tmp.fieldtype,
                    "fieldInput"          : tmp.inputtype,
                    "fieldInputSettings"  : "",
                    "round"         : 2,
                    "required"      : false,
                    "unique"        : false,
                    "nullable"      : false,
                    "default"       : "",
                    "swagger"       : "Tests faker "+tmp.value,
                    "roles"         : "",
                    "permissions"   : "",
                    "factory"       : tmp.value,
                  };

          fields_tmp.push(field);
        }
        console.log(tmp);
      });
      this.fields = fields_tmp;
    }
  },
  computed:{
    export_string : function(){
      return {
        "model"   : this.model,
        "fields"  : this.fields,
      };
    },
    extrapermissions : function(){
      if(this.model.hasOwnProperty('extrapermissions')){
        return this.model.extrapermissions.split("|");
      }else{
        return [];
      }
    }
  },
  components: {
    
  }
}
</script>

<style>
.card-body{
  text-align: left;
}
th input[type=checkbox], th input[type=radio]{
  width: auto;
  margin: auto;
}
.drag{
  cursor: move !important;
  position: relative;
}
</style>
