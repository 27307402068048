export default `
<section class="four bg-white  ">
<div class="row pt-4 mb-3">
    <div class="col-md-6 center">
        <h2 id="section23header"  class="h2">++{{section23header}}</h2>
        <h6 id="section23Text1"  class="p">++{{section23Text1}}</h6>
        <a  id="section23Text2" href="++{{section23Link1}}" class="btn btn-lg pl-4 pr-4 pt-2 pb-2 mb-4 mt-2 button">++{{section23Text2}}</a>
    </div>
    <div class="col-md-6 center">
        <img src="++{{section23Image}}">
    </div>
</div>
</section>
`