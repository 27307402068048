<template>
    <div class="row m-0 p-2 mb-5">
        <div class="col-12 m-0">
            <button style="float:right" class="btn btn-success"><i class="fas fa-edit"></i> EDIT</button>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-md-2 p-0">
                    <ul class="nav nav-tab flex-column ">
                        <li class="nav-item">
                            <a style="color:gray" @click="toggleLangTab(0)" :class="tabController.dutch ? 'nav-link active activ':'nav-link'" href="#dutch">Dutch</a>
                        </li>
                        <li class="nav-item">
                            <a style="color:gray"  @click="toggleLangTab(1)" :class="tabController.english ? 'nav-link active activ':'nav-link'" href="#english">English</a>
                        </li> 
                    </ul>
                </div>
                <div class="col-md-10">  
                        <div class="col-12 " style="border:1px solid gray;height:100%">
                        <div class="tab-content" id="myTabContent" style="width:99%;margin-top: 10px;background: #d3d3d340;">

                            <div :class="tabController.dutch ? 'tab-pane fade show active ':'tab-pane fade '" id="dutch" role="tabpanel" aria-labelledby="home-tab">
                                <ul class="nav nav-tabs p-2" id="myTab" role="tablist">
                                    <li v-for="(group,index) in labels" :key="index" class="nav-item mr-1">
                                        <a  style="color:gray"  @click="toggleInnerTab(group.tabname)" :class="innerTabs[group.tabname] ? 'nav-link active activ':'nav-link'"  data-toggle="tab" href="#tab1" role="tab" aria-controls="home" aria-selected="true">{{$t('leffit.productmetalabels.'+group.tabname)}}</a>
                                    </li>
                                </ul>
                                <div class="tab-content p-3" id="myTabContent">
                                    <div v-for="(group,index) in labels" :key="index"  :class="innerTabs[group.tabname] ? 'tab-pane fade show active':'tab-pane fade show'" id="tab1" role="tabpanel" aria-labelledby="home-tab">
                                        <div v-for="(data,index) in group.data" :key="index" class="form-group webGroup">
                                            <label v-if="data.settings.fieldtype_id == 0" for="">{{data.key}}</label>
                                            <label v-if="data.settings.fieldtype_id > 0" for="">{{data.label}}</label>

                                            <p v-if="data.settings.fieldtype_id == 0 && !data.editting" v-html="data.value"></p>
                                            <input v-model="data.value" type="text" v-if="data.settings.fieldtype_id == 0 && data.editting" class="form-control" />
                                            <input v-model="data.value" type="text" v-if="data.settings.fieldtype_id == 1" class="form-control" />
                                            <textarea v-model="data.value" v-if="data.settings.fieldtype_id == 2" id="" cols="30" rows="10" class="form-control"></textarea>
                                            <tinymce v-model="data.value" v-if="data.settings.fieldtype_id == 3"></tinymce>

                                            <p v-if="data.error && data.error.length > 0" style="font-size:14px" class="text-danger m-0 pt-1">{{data.error}}</p>
                                            <div class="pt-1">
                                                <button v-if="data.settings.fieldtype_id > 0" @click="submit(data)" class="btn btn-success btn-sm"><i style="font-size:13px" class="fa fa-check"></i> <b> Submit</b></button>
                                                <button v-if="data.settings.multiple" @click="addmore(data,index)" class="btn btn-primary btn-sm"><i style="font-size:13px" class="fa fa-plus"></i> <b> Add More</b></button>

                                                <button @click="data.editting=true" v-if="data.settings.fieldtype_id  == 0 && !data.editting"  class="btn btn-primary btn-sm"><i style="font-size:13px" class="fa fa-edit"></i></button>
                                                <button @click="updateMeta(data);data.editting=false" v-if="data.settings.fieldtype_id  == 0 && data.editting"  class="btn btn-success btn-sm"><i style="font-size:13px" class="fa fa-check"></i></button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div :class="tabController.english ? 'tab-pane fade show active ':'tab-pane fade show '" id="english" role="tabpanel" aria-labelledby="home-tab"> 
                                    <ul class="nav nav-tabs p-2 mr-1" id="myTab" role="tablist">
                                    <li v-for="(group,index) in labels" :key="index" class="nav-item">
                                        <a style="color:gray"  @click="toggleInnerTab(group.tabname)" :class="innerTabs[group.tabname] ? 'nav-link active activ':'nav-link'"  data-toggle="tab" href="#tab1" role="tab" aria-controls="home" aria-selected="true">{{$t('leffit.productmetalabels.'+group.tabname)}}</a>
                                    </li>
                                </ul>
                                <div class="tab-content p-3" id="myTabContent">
                                        <div v-for="(group,index) in labels" :key="index" :class="innerTabs[group.tabname] ? 'tab-pane fade show active':'tab-pane fade show'" id="tab1" role="tabpanel" aria-labelledby="home-tab">
                                        <div v-for="(data,index) in group.data" :key="index" class="form-group webGroup">
                                            <label for="">{{data.label}}</label>
                                            <input type="text" v-if="data.settings.fieldtype_id == 1" class="form-control" />
                                            <textarea v-if="data.settings.fieldtype_id == 2" id="" cols="30" rows="10" class="form-control"></textarea>
                                            <tinymce v-if="data.settings.fieldtype_id == 3"></tinymce>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

                <div class="modal">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">Edit</h4>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <input type="text" class="form-control">
                                </div>

                                <div class="row m-0 p-0">
                                    <button class="btn btn-success">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import tinymce from "@tinymce/tinymce-vue";
export default {
    name:'website',
    components:{tinymce},
    props:['item'],
    data(){
        return {
            labels:[],
            tabController:{
                dutch:true, 
                english:false
            },
            innerTabs:{}
        }
    },
    methods:{
        updateMeta(item){
            console.log(item);
            this.$store.dispatch('put',{
                endpoint:'/leffit/products/productmetas/'+item.id,
                details:item
            })
            .then((data)=>{
                console.log(data);
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        removeMeta(data,index){
            this.$store.dispatch('delete','/leffit/products/productmeta/'+data.id)
            .then(()=>{}).catch(()=>{});

            this.labels=this.labels.map((labl)=>{
                if(labl.group == data.group){   
                    labl.data=labl.data.filter((ft,indx)=> (index != indx ) );
                }
                return labl;
            });
            this.$store.dispatch('makeNotification',{title:data.key+" was has been removed successfully",type:'success'});
        },
        addmore(data,index){
            data.value='';
            this.labels=this.labels.map((ft)=>{
                if(ft.group == data.group){
                    ft.data.splice(index,0,data);
                } 
                return ft;
            });
        },
        submit(data){ 
            if(data.value.length <= 0){
                data.error="This field is required.";
                return false;
            }
            var temp={
                lang:this.tabController.dutch ?'NL':"EN",
                value:data.value,
                group:data.group,
                key:data.key,
                product_id:data.product_id,
                pickorder:0
            };
            
            this.$store.dispatch('post',{endpoint:'/leffit/products/productmeta',details:temp})
            .then(()=>{}).catch(()=>{});

            data.value='';
            this.labels=this.labels.map((labl)=>{
                if(labl.group == data.group){  
                    temp.settings={fieldtype_id:0}; 
                    var indx=null;
                    labl.data=labl.data.map((dt,index)=>{
                        if(dt.key == temp.key){
                            if(!dt.settings.multiple){
                                return temp;
                            }else{ indx=index;}
                        }
                        return dt;
                    });
                    if(indx != null){labl.data.splice(indx+1,0,temp);}
                }
                return labl;
            });
            this.$store.dispatch('makeNotification',{title:data.label.split('.')[4]+" was has been added successfully",type:'success'});
        },
        toggleLangTab(index){
            if(index == 0){
                this.tabController.english=false;
                this.tabController.dutch=true;
            }else if(index == 1){
                this.tabController.dutch=false;
                this.tabController.english=true;
            }
        },
        toggleInnerTab(label){ 
            this.labels.forEach((lb)=>{
                this.innerTabs[lb.tabname]=false; 
            });
            this.innerTabs[label]=true;
            var temp=this.innerTabs;
            this.innerTabs={};
            this.innerTabs=temp; 
        },
        fetchLabels(){
             this.$store.dispatch('get','/leffit/products/productmetalabels')
            .then(data=>{ 

                var results = data.data.data; 
                results.forEach((label) => {

                    if(label.settings != null){
                        label.settings=JSON.parse(label.settings);
                    }

                    label.value='';
                    label.product_id=this.$route.params.id; 
                    label.error="";

                    var check=this.labels.find((lb) => lb.group == label.group);
                    if(check == undefined){
                        var data={group:label.group,tab:false,tabname:label.group.split('.')[1],data:[]};
                        this.labels.push(data);
                        this.innerTabs[label.group.split('.')[1]]=false;
                    }
                    this.labels=this.labels.map((labl)=>{
                        if(labl.group == label.group){
                            var result=labl.data.find((f)=> f.key == label.key);
                            if(label.settings.multiple || (!label.settings.multiple && result == undefined)){ 
                                labl.data.push(label);
                            }
                        }
                        return labl;
                    });

                });

                // Setting the first active tab
                if(this.innerTabs['seo']){
                    this.innerTabs['seo']=true;
                } else{
                    this.innerTabs[results[0].group.split('.')[1]]=true;
                }
                console.log(data);
            }).catch((error)=>{
                window.console.log(error.request);
            });

        }
    },
    created() {  
       //FIRST FETCH ALL META RELATING TO THIS PRODUCT
       //THEN WE FETCH META LABELS
        this.$store.dispatch('get','/leffit/products/get-product_producMetas/'+this.$route.params.id)
        .then(data=>{ 
            // console.log(data); 
            var results = data.data; 
            results.forEach((label) => {
                label.error=""; 
                var check=this.labels.find((lb) => lb.group == label.group);
                if(check == undefined){
                    if(label.group){
                        if(label.group.split('.').length>0){
                            var data={group:label.group,tab:false,tabname:label.group.split('.')[1],data:[]};
                            this.labels.push(data);
                            this.innerTabs[label.group.split('.')[1]]=false; //initialise tab-open to be false
                        }
                    }
                }
                this.labels=this.labels.map((labl)=>{
                    if(labl.group == label.group){ 
                        if(!label.settings){
                            label.settings={fieldtype_id:0};  
                        }
                        label.editting=false;
                        labl.data.push(label);
                    }
                    return labl;
                });

            });
            // console.log(this.labels);
		}); 
    },
}
</script>
<style scoped>
    .activ{
        color: #007bff !important;
        font-weight: bold;
    }
    .webGroup{
        padding: 10px;
        border-radius: 5px;
        background: #ffffffc4;
    }
</style>