<template>
    <div v-if="loaded">
        <table class="table table-responsive table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.product.'+attribute) }}</th>
                </tr>
            </thead>
        <thead>
            <tr>
            <th><a @click="searchTable()" class="btn btn-primary"><i class="fa fa-search"></i></a></th>
            <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search.search[attribute]" class="form-control"></th>
            </tr>
        </thead>
            <tbody>
                <tr :key="index" v-for="(item, index) in pagedata.data">
                    <td>
                        <!-- <router-link :to="'/admin/stock/stock_locations/'+item.id" class="btn btn-open">
                            <i class="fa fa-search"></i>
                        </router-link> -->
                        <router-link :to="'/admin/stocks/settings/stock_locations/'+item.id+'/edit'" class="btn btn-warning">
                            <i class="fa fa-pencil"></i>
                        </router-link>
                        <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                    <td :key="index" v-for="(attribute,index) in attributes">{{ translate(item[attribute]) }}</td>
                </tr>
            </tbody>
        </table>
        <pagination
          :limit="2"
          :data="pagedata"
          @pagination-change-page="searchTable"
      ></pagination>
    </div>
</template>

<script>
export default {
  	data () {
    	return {
    		pagedata : [],
        attributes : [
        'name', 
        'created_at'
        ],
        search : {search:{}},
        loaded:false
    	}
  	},
    methods: {
      translate (input) {
        if(input){
          if(input.constructor === String){
            if(input.indexOf('leffit.products')!==-1){
              return this.$t(input);
            }else{
              return input;
            }
          }
        } 
        return input;
    },
     searchTable(page=1){  
        var temp=[];
          for(var s in this.search.search){
              temp.push({
                'attribute':s,
                'value':this.search.search[s]
              }); 
          }
          this.search.search=temp;
          console.log(this.search);
          this.$store.dispatch('post',{details:this.search,endpoint:'/leffit/products/stocklocations/search?page='+page})
          .then((data)=>{
            // console.log(data);
            this.loaded=true
            this.pagedata=data.data;
          })
          .catch((error)=>{
            console.log(error)
          })
        },
	   deleteItem(id){
        if(!confirm('Are you sure you want to delete?')){
          return false;
        }
        this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
        console.log(this.pagedata);
        this.$store.dispatch('delete','/leffit/products/stocklocations/'+id)
        .then(data=>{
          console.log(data);
          this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Item was removed successfully',type:'success'});
        });
      }
    },
  	mounted(){
      console.log('lle')
      this.searchTable()
	  //   this.$store.dispatch('post',{endpoint:'/leffit/products/stocklocations/search',details:{search:{limit:10}}})
    //     .then(data=>{
    //     	console.log(data);
		// 	this.pagedata = data.data;
		// });
	},
}
</script>

