import contacts from "./../../../../src/leffit/crm/contacts/index";
import contact from "./../../../../src/leffit/crm/contacts/item";
import organizations from "./../../../../src/leffit/crm/organizations/index";
import organization from "./../../../../src/leffit/crm/organizations/item";

import addresses from "./../../../../src/leffit/crm/addresses/index";
import addressesItem from "./../../../../src/leffit/crm/addresses/item";

import organizationtypes from "./../../../../src/leffit/crm/settings/organizationtypes/index";
import addresstypes from "./../../../../src/leffit/crm/settings/addresstypes/index";

import messages from "./../../../../src/leffit/crm/messages/index";
import messagesItem from "./../../../../src/leffit/crm/messages/item";

export default 	[
    {
        path:"organizations",
        component: organizations,
        meta:{
            AuthRequired:true,
            type:'all'
        }, 
    },
    {
        path:"suppliers",
        component: organizations,
        meta:{
            AuthRequired:true,
            type:'creditors'
        }, 
    },
    {
        path:"customers",
        component: organizations,
        meta:{
            AuthRequired:true,
            type:'debtors'
        }, 
    },
    {
        path        :   'organization/:id',
        component   :   organization,
        props       :   true,
    },
    {
        path        :   'organization/:id/:edit',
        component   :   organization,
        props       :   true,
    },
    {
        path:"contacts",
        component: contacts,
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path        :   'contact/:id',
        component   :   contact,
        props       :   true,
    },
    {
        path        :   'contact/:id/:edit',
        component   :   contact,
        props       :   true,
    },
    {
        path:"addresses",
        component: addresses,
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path:"addresses/:id",
        component: addressesItem,
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path:"addresses/:id/:edit",
        component: addressesItem,
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path:"settings",
        component: contacts,
        children: [
        {
            path:"organizationtypes",
            component: organizationtypes,
            meta:{
                AuthRequired:true
            }, 
        },
        {
            path:"addresstypes",
            component: addresstypes,
            meta:{
                AuthRequired:true
            }, 
        },
        ],
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path:"messages",
        component: messages,
        children: [ 
        
        ],
        meta:{
            AuthRequired:true
        }, 
    },
    {
        path:"message/:id",
        component: messagesItem,
        meta:{
            AuthRequired:true
        }, 
    }, 
];