<template>
    <div class="row">
        <div class="col-12 text-center p-5">
            <label style="font-size: 16px;" for="stockMut">
                <input @change="$parent.updateItem()" id="stockMut" type="checkbox" v-model="settings.stock_mutation">  &nbsp;
                Allow Stock Mutation
            </label>
        </div>
    </div>
</template>
<script>
    export default {
        props:['settings'],
        data(){
            return {

            }
        },
        created(){
            console.log(this.settings);
        }
    }
</script>
<style scoped>

</style>