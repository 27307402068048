<template>
  <div>
    <div
      id="generalCollapse"
      class="collapse show"
      aria-labelledby="headingOne"
      data-parent="#accordionExample"
    >
      <div class="card-body" style="background: white !important">
        <!-- <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="form-group form-check">
                            <input v-model="item.landingpage" type="checkbox" class="form-check-input" id="landingCheck">
                            <label class="form-check-label p-0" for="landingCheck">{{$t('leffit.websitehome.landing_page')}}</label>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="form-group form-check">
                            <input v-model="item.in_slug" type="checkbox" class="form-check-input" id="slugCheck">
                            <label class="form-check-label p-0" for="slugCheck">{{$t('leffit.websitehome.in_slug')}}</label>
                        </div>
                    </div>
                </div> -->
        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabs col-12">
              <li class="nav-item">
                <a
                  data-toggle="tab"
                  class="nav-link active"
                  role="tab"
                  href="#Nederlands"
                  >Nederlands</a
                >
              </li>
              <li class="nav-item">
                <a data-toggle="tab" class="nav-link" role="tab" href="#Engels"
                  >Engels</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="Nederlands">
                <div class="row">
                  <div class="form-group col-12 col-md-6">
                    <label>{{ $t("leffit.websitehome.name") }}</label>
                    <input
                      v-model="meta.nl.name"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label>{{ $t("leffit.websitehome.slug") }}</label>
                    <input
                      v-model="meta.nl.slug"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>{{ $t("leffit.websitehome.title") }}</label>
                    <input
                      v-model="meta.nl.title"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>{{ $t("leffit.websitehome.description") }}</label>
                    <textarea
                      v-model="meta.nl.description"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>{{ $t("leffit.websitehome.keyword") }}</label>
                    <textarea
                      v-model="meta.nl.keywords"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="col-12">
                    <button @click="updateGeneral()" class="btn btn-success">
                      <span v-if="saving">Updating...</span>
                      <span v-else>UPDATE</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Engels">
                <div class="row">
                  <div class="form-group col-12 col-md-6">
                    <label>Name</label>
                    <input
                      v-model="meta.en.name"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label>Slug</label>
                    <input
                      v-model="meta.en.slug"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>Title</label>
                    <input
                      v-model="meta.en.title"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>Description</label>
                    <textarea
                      v-model="meta.en.description"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="form-group col-12 col-md-12">
                    <label>Keyword</label>
                    <textarea
                      v-model="meta.en.keywords"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="col-12">
                    <button @click="updateGeneral()" class="btn btn-success">
                      <span v-if="saving">Updating...</span>
                      <span v-else>UPDATE</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["product"],
  data() {
    return {
      meta: {
        nl: {
          name: "",
          slug: "",
          title: "",
          description: "",
          keywords: "",
        },
        en: {
          name: "",
          slug: "",
          title: "",
          description: "",
          keywords: "",
        },
      },
      saving: false,
    };
  },
  methods: {
    async updateGeneral() {
      if (this.saving) return false;

      this.saving = true;
      var payload = {
        nl: {
          name: this.meta.nl.name,
          slug: this.meta.nl.slug,
          title: this.meta.nl.title,
          description: this.meta.nl.description,
          keywords: this.meta.nl.keywords,
        },
        en: {
          name: this.meta.en.name,
          slug: this.meta.en.slug,
          title: this.meta.en.title,
          description: this.meta.en.description,
          keywords: this.meta.en.keywords,
        },
      };

      var response = await this.$store.dispatch("post", {
        endpoint: "/leffit/products/update-product-seo/" + this.product.id,
        details: { meta: payload },
      });

      this.saving = false;
    },
  },
  created() {
    this.$store
      .dispatch("get", "/leffit/products/product-seo/" + this.$route.params.id)
      .then((data) => {
        // console.log(data);
        var meta = data.data;
        try {
          this.meta.nl = {
            name: meta.nl.find((d) => d.key == "name")?.value,
            slug: meta.nl.find((d) => d.key == "slug")?.value,
            title: meta.nl.find((d) => d.key == "title")?.value,
            description: meta.nl.find((d) => d.key == "description")?.value,
            keywords: meta.nl.find((d) => d.key == "keywords")?.value,
          };

          this.meta.en = {
            name: meta.en.find((d) => d.key == "name")?.value,
            slug: meta.en.find((d) => d.key == "slug")?.value,
            title: meta.en.find((d) => d.key == "title")?.value,
            description: meta.en.find((d) => d.key == "description")?.value,
            keywords: meta.en.find((d) => d.key == "keywords")?.value,
          };
        } catch (e) {
          console.log(e);
        }
      });
  },
};
</script>