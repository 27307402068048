<template>
    <div>
        <div class="row">
            <div class="col-12 d-flex" style="justify-content: space-between;">
                <h4>Purchase pricelevels</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex"> 
                <div class="form-group col-3">
                    <label for="">Label</label>
                    <input type="text" class="form-control" v-model="item.label">
                </div>
                <div class="form-group col-3">
                    <label for="">Key</label>
                    <input type="text" class="form-control" v-model="item.key">
                </div>

                <div class="form-group col-2">
                    <label for="">Default</label>
                    <input type="text" class="form-control" v-model="item.default">
                </div>

                <div class="form-group col-2">
                    <label for="">Show in purchase orders</label>
                    <select class="form-control" v-model="item.purchase_show">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div class="col-2">
                    <button @click="addnewPriceLevel()" class="btn btn-success btn-sm" style="margin-top:40px">Add new</button>
                </div>
            </div>
        </div>
        <div class="row pt-2" style="background:#d3d3d326"> 
            <div v-for="(type , index) in $parent.pricelevels" :key="index" class="col-12 d-flex"> 
                <div class="form-group m-0 col-3">
                    <label for="">Label</label>
                    <input type="text" class="form-control" v-model="type.label">
                </div>
                <div class="form-group m-0 col-2">
                    <label for="">Key</label>
                    <input type="text" class="form-control" v-model="type.key">
                </div>
                <div class="form-group m-0 col-1">
                    <label for="">Default</label>
                    <input type="text" class="form-control" v-model="type.default">
                </div>
                <div class="form-group m-0 col-2">
                    <label for="">Show in purchase orders</label>
                    <select class="form-control" v-model="type.purchase_show">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div class="col-2 d-flex">
                    <button @click="updatePriceLevel()" class="btn btn-primary btn-sm" style="margin-top:40px">update</button>
                    <button @click="changeIndex(index,'up')" v-if="index > 0" class="btn btn-warning btn-sm" style="margin-top:40px;padding: 5px 10px;"><i class="fas fa-chevron-up"></i></button>
                    <button @click="changeIndex(index,'down')" v-if="index < ($parent.pricelevels.length - 1)" class="btn btn-success btn-sm" style="margin-top:40px;padding: 5px 10px;"><i class="fas fa-chevron-down"></i></button>
                    <button @click="removePriceLevel(type)" class="btn btn-danger btn-sm" style="margin-top:40px"><i class="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        relateds:{required:true}
    },
    data(){
        return {
            item:{label:'',key:'',default:'',purchase_show:0}
        }
    },
    methods:{
        changeIndex(index,direction){
            var pricelevels = this.$parent.pricelevels;
            var temp=pricelevels[index];
            if(direction == 'up'){
                pricelevels[index] = pricelevels[index - 1];
                pricelevels[index - 1] = temp;
            }else{
                pricelevels[index] = pricelevels[index + 1];
                pricelevels[index + 1] = temp;
            }
            console.log(pricelevels);
            this.$parent.pricelevels = [];
            this.$parent.pricelevels = pricelevels;
            this.$parent.updateItem();
        },
        addnewPriceLevel(){
            this.item.id=Math.round(Math.random() * 2000); 
            this.$parent.pricelevels.push({
                "label":this.item.label,
                "key":this.item.key, 
                "id":this.item.id,
                "purchase_show":this.item.purchase_show,
                "default":this.item.default,
            });
            this.item={label:'',key:''};
            this.$parent.updateItem();
        },
        updatePriceLevel(){
            this.$parent.updateItem();
        },
        removePriceLevel(item){
            if(!confirm("Are you sure you want to delete this item?")){
                return false;
            }
            this.$parent.pricelevels=this.$parent.pricelevels.filter((d)=> d.label != item.label && d.value != item.value);
            this.$parent.updateItem();
        }
    },
    created(){

    }
}
</script>