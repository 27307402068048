<template>
    <div>
        <div class="col-12 form-group">
          <label for="">{{ $t('leffit.products.product.select_product') }}</label>
          <v-select label="text" v-model="selected" :options="options" name="" id="selectFilter" class="col-12" style="width:100%">
          </v-select>

          <button @click="addProduct()" class="btn btn-sm btn-block btn-primary">{{ $t('leffit.products.product.add_product') }}</button>
        </div>
        <table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.product.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a @click="searchTable()" class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search.search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr :key="index" v-for="(item, index) in products">
      				<td>
      					<router-link :to="'/admin/products/product/'+item.id" class="btn btn-open">
      						<i class="fa fa-search"></i>
      					</router-link>
      					<router-link :to="'/admin/products/product/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
                	<a @click="removeProduct(item.id);" :href="'#'" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes" v-html="translate(item[attribute])"></td>
      			</tr>
      		</tbody>
      	</table>    
    </div>
</template>
<script>

export default {
    name:'product-table', 
    data(){
        return {
            attributes : [
                'name',
                'description',
                'ean',
                'producttype',
                'productstatus',
                'price',
                'weight',
                'vat',
                'stock',
                'created_at',
                'updated_at',
                'deleted_at',
            ],
            search : {search:{}},
            products:[],data:[],
            options:[],selected:0
        }
    },
    methods:{
        addProduct(){
          setTimeout(()=>{
            var product=this.data.find((d)=> d.id == this.selected.id);
            this.$store.dispatch('get','/leffit/crm/add-product/'+this.$route.params.id+'/'+product.id)
            .then((data)=>{
              console.log(data); 
            })
            .catch((error)=>{
              console.log(error)
            });

            this.products.push(product);
          },200); 
        },
        removeProduct(id){ 
          if(confirm("Are you sure you want to delete this item")){
            return false;
          }
            this.$store.dispatch('get','/leffit/crm/remove-product/'+this.$route.params.id+'/'+id)
            .then((data)=>{
              console.log(data); 
            })
            .catch((error)=>{
              console.log(error)
            });

            this.products=this.products.filter((d)=> d.id != id);
        },
        searchTable(){
          console.log(this.search);
          this.$store.dispatch('post',{endpoint:'/leffit/products/search',details:{limit:10, search: this.search.search}})
          .then((data)=>{
            console.log(data);
            this.products=data.data.data;
          })
          .catch((error)=>{
            console.log(error)
          }) 
        },
        translate (input) {
          if(input){
            if(input.constructor === String){
              if(input.indexOf('leffit.products')!==-1){
                return this.$t(input);
              }else{
                return input;
              }
            }
          }
          
          return input;
        }
    }, 
    created(){ 
      this.$store.dispatch('post',{endpoint:'/leffit/products/search',details:{limit:10}})
      .then(data=>{
          console.log(data);
          var products = data.data.data; 
          this.data=products;
          this.options=products.map((p)=>{
            return {id:p.id,text:p.name};
          });
      }).catch((error)=>{
        window.console.log(error);
      });

      this.$store.dispatch('get','/leffit/crm/get-organization-product/'+this.$route.params.id)
      .then(data=>{
          console.log(data);
          this.products = data.data;
      }).catch((error)=>{
        window.console.log(error);
      })
    }
}
</script>