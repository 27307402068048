<template>
    <div>
        <div class="row">
            <div class="col-10 upload-container">
                <div class="btns">
                    <button @click="exportBolData()" class="btn btn-success">
                        <span v-if="!loading">Export Bol.com</span>
                        <span v-else>Exporting</span>
                    </button>

                    <button @click="exportBijenkorfData()" class="btn btn-success">
                        <span v-if="!loading2">Export Bijenkorf</span>
                        <span v-else>Exporting</span>
                    </button>
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            loading:false,
            loading2:false,
        }
    },
    methods: {
        async exportBolData(){
            if(this.loading) return false; 
            this.loading = true;
            
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export?model=webshop-bol&type=bol',
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if(response.status == 200 ){
                    console.log(response);
                    const type = response.headers['content-type']
                    const blob = new File([response.data],{type: 'application/octet-stream'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    var current = new Date();
                    link.download = current.getFullYear()+current.getMonth()+1+current.getDate()+'_bolcom.csv';
                    link.click(); 
                    
                    this.$store.dispatch('displayNotification',{
                        type:'success',
                        id:Math.round(Math.random()*100),
                        message:'Exported successfully.',
                        interval:5000,
                        autoDismiss:true
                    })
                }
                else{ 
                    if(response.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                    }
                } 
            this.loading = false;
        },
        async exportBijenkorfData(){
            if(this.loading2) return false; 
            this.loading2 = true;
            
            var response =await axios.get(process.env.VUE_APP_API_BASE_URL+
                    '/lefficient/leffit/export?model=webshop-bol&type=bijenkorf',
                    {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                if(response.status == 200 ){
                    console.log(response);
                    const type = response.headers['content-type']
                    const blob = new File([response.data],{type: 'application/octet-stream'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    // var current = new Date;
                    link.download = 'bijenkorf.csv';
                    link.click(); 
                    
                    this.$store.dispatch('displayNotification',{
                        type:'success',
                        id:Math.round(Math.random()*100),
                        message:'Exported successfully.',
                        interval:5000,
                        autoDismiss:true
                    })
                }
                else{ 
                    if(response.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                    }
                } 
            this.loading2 = false;
        }
    },
    create(){}
}
</script>
<style scoped>
    .btns{
        position: absolute;
        height: fit-content;
        width: fit-content;
        margin: auto;
        top:0;
        bottom:0;
        left:0;
        right:0;
    }
    .upload-container{ 
        height: 400px;
    }
</style>