<template>
  <table class="table table-responsive table-hover">
    <thead>
      <tr>
        <th></th>
        <th>{{ $t("lefficient.system.warehouse") }}</th>
        <th>{{ $t("lefficient.system.stockloc") }}</th>
        <th>{{ $t("lefficient.system.stocktype") }}</th>
        <th>{{ $t("lefficient.system.amount") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in pagedata">
        <td></td>
        <td>
          <span v-if="item.warehouse">
            {{ item.warehouse.name }}
          </span>
        </td>
        <td>
          <span v-if="item.stock_location">{{ item.stock_location.name }}</span>
        </td>
        <td>
          <span v-if="item.stocktype">{{ item.stocktype.name }}</span>
        </td>
        <td>
          {{ item.amount }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    stocks: null,
  },
  data() {
    return {
      pagedata: [],
    };
  },
  created() {
    if (this.stocks == null) {
      this.$store
        .dispatch(
          "get",
          "/leffit/products/product-stocks/" + this.$route.params.id
        )
        .then((data) => {
          this.pagedata = data.data.data;
        });
    } else {
      this.pagedata = this.stocks;
    }
  },
};
</script>

<style lang="css" scoped></style>
