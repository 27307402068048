import settingsIndex from "./../../../../src/leffit/products/settings/index";

import products from "./../../../../src/leffit/products/products/index";
import EanUpload from "./../../../../src/leffit/products/products/ean_upload";
import product from "./../../../../src/leffit/products/products/item";
import stockStickers from "./../../../../src/leffit/products/products/stockstickers";

import productFilters from "./../../../../src/leffit/products/settings/filters/index";
import productFilter from "./../../../../src/leffit/products/settings/filters/item";

import productTypes from "./../../../../src/leffit/products/settings/types/index";
import productType from "./../../../../src/leffit/products/settings/types/item";

import productstatuses from "./../../../../src/leffit/products/settings/statuses/index";
import productstatus from "./../../../../src/leffit/products/settings/statuses/item";

import meta_labels from "./../../../../src/leffit/products/settings/meta_labels/index";
import meta_label from "./../../../../src/leffit/products/settings/meta_labels/item";

import create from "../../../../src/leffit/products/products/create";

import vouchers from "../../../../src/leffit/products/vouchers/index";
import vouchersCreate from "../../../../src/leffit/products/vouchers/create";
import vouchersEdit from "../../../../src/leffit/products/vouchers/edit";

import webshopExport from '../../../../src/leffit/products/export/webshop'
import pricatExport from '../../../../src/leffit/products/export/pricat'
import VirgoImport from '../../../../src/leffit/products/virgo/import'

export default [
    {
        path: "products",
        component: products,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "ean_upload",
        component: EanUpload,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "create",
        component: create,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: 'product/:id',
        component: product,
        props: true,
    },
    {
        path: 'product/:id/:edit',
        component: product,
        props: true,
    },
    {
        path: "vouchers",
        component: vouchers,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "vouchers/create",
        component: vouchersCreate,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "stockstickers",
        component: stockStickers,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "vouchers/:id",
        component: vouchersEdit,
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "settings",
        component: settingsIndex,
        children: [
            {
                path: "productfilters",
                component: productFilters,
                meta: {
                    AuthRequired: true
                },
            },

            {
                path: 'productfilter/:id',
                component: productFilter,
                props: true,
                name: 'product_filter_item_page'
            },
            {
                path: 'productfilter/:id/:edit',
                component: productFilter,
                props: true,
            },

            {
                path: "producttypes",
                component: productTypes,
                meta: {
                    AuthRequired: true
                },
            },
            {
                path: 'producttype/:id',
                component: productType,
                props: true,
            },
            {
                path: 'producttype/:id/:edit',
                component: productType,
                props: true,
            },


            {
                path: "productstatuses",
                component: productstatuses,
                meta: {
                    AuthRequired: true
                },
            },
            {
                path: 'productstatus/:id',
                component: productstatus,
                props: true,
            },
            {
                path: 'productstatus/:id/:edit',
                component: productstatus,
                props: true,
            },


            {
                path: "productmeta_labels",
                component: meta_labels,
                meta: {
                    AuthRequired: true
                },
            },
            {
                path: 'meta_label/:id',
                component: meta_label,
                props: true,
            },
            {
                path: 'meta_label/:id/:edit',
                component: meta_label,
                props: true,
            },
        ],
        meta: {
            AuthRequired: true
        },
    },
    {
        path: "virgo/import",
        component: VirgoImport,
        meta: {
            AuthRequired: true
        }
    },
    {
        path: "pricat/export",
        component: pricatExport,
        meta: {
            AuthRequired: true
        }
    },
    {
        path: "webshop/export",
        component: webshopExport,
        meta: {
            AuthRequired: true
        }
    },
];