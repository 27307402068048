<template>
    <div>
        <!-- {{data}} -->
        <div v-if="fieldtype.name == 'WYSIWYG' || fieldtype.name == 'title'" class="text-field field">
            <p v-if="result.value.content || result.value.content == ''" v-html="result.value.content"></p>
            <p v-else class="empt"><i class="fas fa-font"></i> Enter your text here</p>
        </div>

        <!-- INPUT TEXT FIELD  -->
        <div v-else-if="fieldtype.name == 'text'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="text" class="form-control" id="" :placeholder="result.value.placeholder ? result.value.placeholder:''">
            <div v-if="!preview" class="protector"></div>
        </div>
        <!-- INPUT PASSWORD FIELD -->
        <div v-else-if="fieldtype.name == 'password'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="password" class="form-control" id="" :placeholder="result.value.placeholder ? result.value.placeholder:''">
            <div v-if="!preview" class="protector"></div>
        </div>
        <!-- INPUT FILE FIELD -->
        <div v-else-if="fieldtype.name == 'file'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="file" class="form-control" id="">
            <div v-if="!preview" class="protector"></div>
        </div>

        <!-- INPUT FILES FIELD -->
        <div v-else-if="fieldtype.name == 'files'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="file" class="form-control" id="" multiple>
            <div v-if="!preview" class="protector"></div>
        </div>

        <!-- INPUT DATETIME -->
        <div v-else-if="fieldtype.name == 'datetime'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="datetime-local" class="form-control" id="">
            <div v-if="!preview" class="protector"></div>
        </div>
        
        <!-- INPUT DATE -->
        <div v-else-if="fieldtype.name == 'date'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="date" class="form-control" id="">
            <div v-if="!preview" class="protector"></div>
        </div>

        <!-- INPUT TIME -->
        <div v-else-if="fieldtype.name == 'time'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <input @click="handleClick($event)" type="time" class="form-control" id="">
            <div v-if="!preview" class="protector"></div>
        </div>

        <!-- INPUT TEXTAREA -->
        <div v-else-if="fieldtype.name == 'textarea'" class="files-field field">
            <label for="" v-if="result.value.label">{{result.value.label}}</label>
            <textarea @click="handleClick($event)" type="time" class="form-control" id="" :placeholder="result.value.placeholder ? result.value.placeholder:''"></textarea>
            <div v-if="!preview" class="protector"></div>
        </div>

         <!-- INPUT CHECKBOX -->
        <div v-else-if="fieldtype.name == 'checkbox'" class="files-field field d-flex">
            <input @click="handleClick($event)" type="checkbox" class="form-control" :id="result.value.label ? result.value.label.replace(' ',''):''"> 
            <label style="padding-top:10px" :for="result.value.label ? result.value.label.replace(' ',''):''" v-if="result.value.label">
                {{result.value.label}} 
            </label>
            <div v-if="!preview" class="protector"></div>
        </div>

        <!-- INPUT CHECKBOXES -->
        <div v-else-if="fieldtype.name == 'checkboxes'" class="files-field field d-flex "> 
            <!-- {{result}} -->
            <div v-if="result.value">
                <div v-for="(option,index) in result.value.value" :key="index">
                    <input type="checkbox" :id="option.title ? option.title.replace(' ',''):''" name="" style="margin-right:10px">
                    <label style="margin-right:30px" :for="option.title ? option.title.replace(' ',''):''">{{option.title}}</label>
                </div>
            </div>
            <div class="text-center col-12" v-if=" result.value.value.length == 0" >{{fieldtype.name}}</div>
            <div v-if="!preview" class="protector"></div>
        </div>
        
        <!-- INPUT SELECT -->
        <div v-else-if="fieldtype.name == 'select'" class="files-field field d-flex "> 
            <!-- {{result}} -->
            <div v-if="result.value" class="col-12">
                <!-- <label style="margin-right:30px" >{{option.title}}</label> -->
                <select name=""  class="form-control col-12" id="">
                    <option  v-for="(option,index) in result.value.value" :key="index" :value="option.value">{{option.title}}</option>
                </select>
            </div>
            <div class="text-center col-12" v-if=" result.value.length == 0" >{{fieldtype.name}}</div>
            <div v-if="!preview" class="protector"></div>
        </div>
        <!-- IMAGES -->
        <div v-else-if="fieldtype.name == 'image'" class="image-field field">
            <div v-if="result.value.src">
                <img width="100%" :src="result.value.src" alt="">
            </div>
            <div v-else>
                <i class="fas fa-image"></i> Click here to add image
            </div>
        </div>
        <div v-else class="text-field field">
            {{fieldtype.name}}
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props:['fieldtype','data'],
    data(){
        return {
            result:{value:{}}
        }
    },
    computed:{
        ...mapState({
            preview:(state)=> state.leffit.page_preview
        })
    },
    methods:{
        handleClick(event){ 
            event.target.blur(); 
        }
    },
    created(){
        if(this.fieldtype.name == 'checkboxes'){
            console.log(this.data);
        }
        if(this.data != undefined && this.data.value != ''){
            this.result=this.data;
            try{
                this.result.value=JSON.parse(this.result.value);
            }
            catch(e){
//
            }
        }else{
            if(this.fieldtype.name == 'checkboxes' || this.fieldtype.name == 'select'){
                this.result.value.value=[];
            }
        }   
    }
}
</script>

<style scoped>
    input[type='checkbox']{
        width: 30px;
    display: inline;
    }
    .text-field{
        padding: 10px 0px;
        text-align: center;
        color: black;
    }
    .field{
        padding: 10px 0px;
        position: relative;
    }
    .protector{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
</style>