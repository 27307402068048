<template>
    <div>
        

        <div class="row pb-3 pt-1" style="border: 1px solid #d3d3d369;">
            <div class="col-12">
                <button class="btn" @click="trigger()"><i class="fas fa-plus"></i> Upload files</button>
                <input type="file" class="d-none" @change="addFile()" id="file" multiple />
                <input type="file" class="d-none" @change="addFile(1)" id="edit_file" />

                <input type="text" class="d-none" id="myInput" value=''/>
            </div>
            <div class="col-12 mt-3">
                <ul class="m-0 p-0">
                    <li v-for="(file,index) in files" :key="index" class="file mt-2 ml-2"> <a class="file_a" href="#"> {{file.name}} </a>  <a @click="removeFile(index)" class="file_remove" href="#"><i class="fas fa-remove"></i></a> </li>
                </ul>
            </div> 
        </div>
        <div class="col-12 mt-3">
            <button @click="submit()">submit</button>
        </div>

        <div class="col-12">
            <ul class="mt-3 p-0">
                <li v-for="(file,index) in media.images" :key="index" class="media_li mr-5">
                    <div class="media-div">
                        <div class="d-flex">
                            <div class="">
                                <img v-if="file.mime_type == 'image/png' || file.mime_type == 'image/jpg'" :src="file.path" width="50px" />
                                <img v-else-if="file.mime_type == 'application/pdf'" src="../../../assets/pdf.png" width="50px" />
                                <img v-else src="../../../assets/other.png" width="50px" />
                                <div class="d-flex">
                                    <a @click="deleteMedia(file.id)" class="mr-2" href="#"><i class="fas fa-trash text-danger"></i></a> 
                                    <a @click="trigger(1,file)" href="#"><i class="fas fa-edit text-primary"></i></a>
                                </div>
                            </div>
                            <div class="ml-2 mb-0 pt-2 w-100 text-left">
                                <p class="mb-0">{{substr(30,file.file_name)}}</p>
                                <input style="width:100%;border:none" @click="copy(file.path,$event)" class="d-none" :id="'copy_input'+index" :value="file.path" />
                                <a @click="copy(file.path,index)" href="#">COPY LINK</a>
                            </div>
                        </div>
                      
                        <!-- <div class="d-flex" style="justify-content:space-between">
                            <input style="width:70%;border:none" @click="copy(file.path,$event)" :value="file.path" />
                            <span style="width:30%">Copy link</span>
                        </div> -->
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                files:[],
                media:{},
                file:null
            }
        },
        methods: {
            copy(text,index) { 
                var copyText=document.getElementById("copy_input"+index);
                console.log(copyText);
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/
                document.execCommand("copy"); 
                this.$store.dispatch('makeNotification',{title:"LINK COPIED",text:'',type:'success'});
            },
            substr(end,data){
                if(end > data.length){
                    end=data.length;
                }
                data=data.substr(0,end);
                return data;
            },
            trigger(check=0,file=null){
                if(check == 0){
                    document.getElementById('file').click();
                }else{
                    this.file=file; 
                    document.getElementById('edit_file').click();
                }
            },
            removeFile(index){
                this.files.splice(index,1);
            },
            deleteMedia(id){
                if(!confirm('Are you sure you want to remove this file')){
                    return false;
                }
                this.$store.dispatch('get','/lefficient/leffit/remove-files/'+id)
                .then((data)=>{
                    console.log(data); 
                })
                .catch((error)=>{
                    console.log(error.request);
                });

                this.media.images=this.media.images.filter((d)=> d.id !=id);
            },
            addFile(check=0){ 
                if(check == 0){
                    var files=document.getElementById('file').files; 
                    for(var fl of files){
                        this.files.push(fl);  
                    }
                }else{
                    var file=document.getElementById('edit_file').files[0]; 
                    // console.log(this.file);
                    this.updateFile(file);
                }
            },
            updateFile(file){
                var payload=new FormData();

                payload.append('file',file);
                payload.append('name','courses_files');

                this.$store.dispatch('post',{
                    endpoint:'/lefficient/leffit/update-file/'+this.file.id,
                    details:payload
                })
                .then((data)=>{
                    console.log(data);
                    window.location.reload();
                })
                .catch((error)=>{
                    console.log(error.request);
                });
            },
            submit(){
                if(this.files.length == 0){
                    return false;
                }

                var payload=new FormData();

                this.files.forEach((element,index) => {
                    console.log(index);
                    payload.append('file'+index,element);
                });
                payload.append('name','courses_files');

                this.$store.dispatch('post',{
                    endpoint:'/lefficient/leffit/add-files',
                    details:payload
                })
                .then((data)=>{
                    console.log(data);
                        window.location.reload();
                })
                .catch((error)=>{
                    console.log(error.request);
                });
            }
        },  
        created() {
            this.$store.dispatch('get','/lefficient/leffit/get-files/courses_files')
            .then((data)=>{
                console.log(data);
                this.media=data.data;
            })
            .catch((error)=>{
                console.log(error.request);
            });
        }, 
    }
</script>
<style scoped>
    a{
        text-decoration:none;
    }
    .media-div img{
        height: 50px;
        width: auto !important;
        /* min-width: 350px; */
        margin-bottom:10px;
    }
    li{
        list-style-type: none;
    }
    .media_li{
        display: inline-block;
        box-shadow: 0px 0px 10px lightgrey;
        margin-bottom: 18px;
        text-align: center;
        min-width: 350px;
        max-width: 350px;
        padding:10px;
        border-radius: 5px;
    }
    .file{
        list-style-type: none;
        background: #d3d3d380;
        display: inline-block;
        padding: 2px 20px;
        border-radius: 13px;
    }
    .file .file_a{
        color: gray;
        text-decoration: none;
    }
    .file_remove{
        color:red;
        margin-left: 10px;
        font-size: 13px;
    }
</style>