import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    loader:false,
    notifications:{info:[],success:[],warning:[],danger:[],general:[]},
    showForgotPassword:false
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    UNSET_TOKEN(){
      localStorage.removeItem('token')
      axios.defaults.headers.common.Authorization
    },
    
    loader(state,payload){
      state.loader=payload;
    }
  },

  actions: {
    processError(context,error){
      let messages=[];

      switch(error.request.status){
        case 400:
          //bad request
          messages=[]
          break;
        case 401:
          //unauthorized
          messages=[{title:"Unauthorised",text:'You are not authorised'}];
         context.state.showForgotPassword=true;
          break;
        case 402:
          // forbidden
          var response = JSON.parse(error.request.response);
          messages=[{title:"ERROR",text:response.msg}]
          break;
        case 403:
          // forbidden
          messages=[]
          break;
        case 404:
          // not found
          messages=[{title:"Route error",text:'Route not found'}]
          break;
        case 422:
          response = JSON.parse(error.request.response);
          // validation
          window.console.log(response);
          for(var err in response.errors){
            messages.push({title:response.messages,text:response.errors[err][0]});
          }
          for(var err2 in response){
            messages.push({title:response.messages,text:response[err2][0]});
          }
          break;
        case 500:
          // internal error
          response =error.request;
          messages=[{title:"Server error",text:'Opps! server error occured'}]
          window.console.log(response);
          break;

        default:
          // error
          messages=[{title:"Unknown error",text:'Opps! error occured'}]
          break;
      }

      messages.forEach((msg)=>{
        var data={
          id:Math.round(Math.random() * 999),
          title:msg.title,
          message:msg.text,
          autoDismiss:true,
          type:'danger',
          interval:2000
        };
        context.dispatch('displayNotification',data);
      })

    },
    makeNotification(context,data,time=2000){
      var notif={
        id:Math.round(Math.random() * 999),
        title:data.status,
        message:data.statusText,
        autoDismiss:true,
        type:'success',
        interval:time
      };
      context.dispatch('displayNotification',notif);
    },
    removeNotification(context,data){
      setTimeout(() => {
        context.state.notifications[data.type]=context.state.notifications[data.type].filter((ntf)=> ntf.id != data.id);
      }, data.interval ? data.interval:0);
    },
    displayNotification(context,data){
      context.state.notifications[data.type].push(data);
      if(data.autoDismiss){
        context.dispatch('removeNotification',{id:data.id,type:data.type,interval:data.interval});
      }
    },
    async signIn ({ dispatch }, credentials) {
      dispatch('loader',true);
      
      await axios.get('/sanctum/csrf-cookie')
      
      var response = await axios.post('/login', credentials);
      console.log(response);
      if(response.status == 201){
        if(response.data){
          localStorage.setItem('token', response.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
        }
        dispatch('me');
        window.location = '/admin';
      }else{
       // 
      }
      
      dispatch('loader',false);

    }, 
    async signOut ({ dispatch }) {
      await axios.post('/logout')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common.Authorization
      return dispatch('me')
    }, 
    me ({ commit }) {
      return axios.get('/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        commit('UNSET_TOKEN', null)
      })
    },
    loader({commit},state){
      commit('loader',state)
    }
  }
}
