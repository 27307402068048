<template>
    <div>
        <div class="row p-2 m-0 mb-5">
                <table class="table table-hover"> 
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Times</th>
                            <th>Interval</th>
                        </tr>
                    </thead> 
                    <tbody>
                            <tr v-for="(subscription,index) in subscriptions" :key="index">
                                <td>
                                </td>
                                <td>{{subscription.created_at}}</td>
                                <td>{{subscription.amount}}</td>
                                <td>{{subscription.description}}</td>
                                <td>{{subscription.times}}</td>
                                <td>{{subscription.interval}}</td>
                            </tr>
                    </tbody>
                </table>
            </div> 
    </div>
</template>

<script>
export default {
    props:['id'],
    data(){
        return {
        subscriptions:[]
    }
    },
    created(){
        this.$store.dispatch('get','/leffit/crm/contact-subscriptions/'+this.id)
        .then(data=>{
          console.log(data);
          this.subscriptions=data.data;
        });
    }
}
</script>