export default [
        {
          "label" : "hidden",
          "name"  : "hidden",
          "settings" : [],
        },
        {
          "label" : "text",
          "name"  : "text",
          "settings" : [],
        },
        {
          "label" : "textarea",
          "name"  : "textarea",
          "settings" : [],
        },
        {
          "label" : "WYSIWYG",
          "name"  : "wysiwyg",
          "settings" : [],
        },
        {
          "label" : "select",
          "name"  : "select",
          "settings" : [{
            'label'   : 'options',
            'type'    : 'select',
            'options' : [
              {
                'value' :  '',
                'label' :  '',
              }
            ],
          }],
        },
        {
          "label" : "checkbox",
          "name"  : "checkbox",
          "settings" : [],
        },
        {
          "label" : "checkboxes",
          "name"  : "checkboxes",
          "settings" : [],
        },
        {
          "label" : "date",
          "name"  : "date",
          "settings" : [{
            'label' : 'format',
            'type'  : 'text',
          }],
        },
        {
          "label" : "time",
          "name"  : "time",
          "settings" : [],
        },
        {
          "label" : "datetime",
          "name"  : "datetime",
          "settings" : [],
        },
        {
          "label" : "image",
          "name"  : "image",
          "settings" : [{
            'label' : 'portrait/landscape',
            'type'  : 'select',
            'options' : [
              { 'value' : 'portrait', 'label' : 'portrait' },
              { 'value' : 'landscape', 'label' : 'landscape' },
            ],
          }],
        },
        {
          "label" : "file",
          "name"  : "file",
          "settings" : [{
            'label' : 'filetypes',
            'type'  : 'checkboxes',
            'options' : [
              {'value':'jpeg', 'label' : 'jpeg', 'selected' : true},
              {'value':'jpg', 'label' : 'jpg', 'selected' : true},
              {'value':'bmp', 'label' : 'bmp', 'selected' : false},
              {'value':'png', 'label' : 'png', 'selected' : true},
              {'value':'pdf', 'label' : 'pdf', 'selected' : true},
            ],
          }],
        },
        {
          "label" : "password",
          "name"  : "password",
          "settings" : [],
        },
        {
          "label" : "googlefont",
          "name"  : "googlefont",
          "settings" : [],
        },

        {
          "label" : "color",
          "name"  : "color",
          "settings" : [],
        },

        
      ]