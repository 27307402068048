<template>
  <div v-if="loaded">
    <div class="head row p-0">
      <h3 class="pl-3">
        {{ $t("leffit.products.product_filter.filters") }}
      </h3>
      <div class="col-12 p-0 m-0">
        <button
          data-target="#addnew"
          data-toggle="modal"
          class="btn btn-success btn-sm"
        >
          {{ $t("leffit.products.filter.to_add") }}
        </button>
      </div>
    </div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th :key="index" v-for="(attribute, index) in attributes">
            {{ $t("leffit.products.filters." + attribute) }}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <a
              @click="searchFilter()"
              href="javascript::void()"
              class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th :key="index" v-for="(attribute, index) in attributes">
            <input
              type="text"
              v-model="search.search[attribute]"
              class="form-control"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :key="index" v-for="(item, index) in pagedata.data">
          <td>
            <router-link
              :to="'/admin/products/settings/productfilter/' + item.id"
              class="btn btn-open"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              :to="
                '/admin/products/settings/productfilter/' + item.id + '/edit'
              "
              class="btn btn-warning"
            >
              <i class="fa fa-pencil"></i>
            </router-link>
            <a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </a>
          </td>
          <td
            :key="index"
            v-for="(attribute, index) in attributes"
            v-html="translate(item[attribute])"
          ></td>
        </tr>
      </tbody>
    </table>
    <pagination
      :limit="2"
      :data="pagedata"
      @pagination-change-page="searchFilter"
    ></pagination>
    <div class="modal" tabindex="-1" role="dialog" id="addnew">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("leffit.products.product_filter.add_new_filter2") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{
                  $t("leffit.products.product_filter.name")
                }}</label>
                <input v-model="item.name" type="text" class="form-control" />
              </div>
              <div class="form-group col-6">
                <label for="filter_parent">{{
                  $t("leffit.products.product_filter.parent")
                }}</label>
                <select2
                  @change="addFilter()"
                  v-model="item.parent"
                  :options="
                    pagedata.data.map((d) => {
                      return { id: d.id, text: d.name };
                    })
                  "
                  style="position: relative; width: 100%; height: auto"
                  class="form-control parent p-0 border-0"
                  id="filter_parent"
                ></select2>
                <div class="row m-0 mt-2">
                  <ul class="m-0 p-0">
                    <li
                      class="filter_li"
                      v-for="(filter, index) in parents"
                      :key="index"
                    >
                      <a href="#" class="anc"
                        >{{ getFilter(filter).name }}
                        <a @click="removeFilter(filter)" href="#"
                          ><i class="fas fa-remove"></i
                        ></a>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <label for="">{{
                  $t("leffit.products.product_filter.language")
                }}</label>
                <Select2
                  v-model="item.language"
                  :options="
                    language.map((lang) => {
                      return { id: lang.code, text: lang.name };
                    })
                  "
                  id="filter_language"
                ></Select2>
              </div>
              <div class="form-group col-sm-6">
                <label for="">{{
                  $t("leffit.products.product_filter.description")
                }}</label>
                <input
                  v-model="item.description"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-sm-6">
                <label for="">{{
                  $t("leffit.products.product_filter.pickorder")
                }}</label>
                <input
                  v-model="item.pickorder"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-12">
                <label for="filter_active" class="d-flex" style="width: 60px">
                  <input
                    :checked="item.active == 1 ? true : false"
                    @click="item.active = item.active == 0 ? 1 : 0"
                    style="height: 21px"
                    type="checkbox"
                    class="form-control d-block"
                    id="filter_active"
                  />
                  <div>{{ $t("leffit.products.product_filter.active") }}</div>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="closemodal"
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              {{ $t("leffit.products.product_filter.close") }}
            </button>
            <button @click="create()" type="button" class="btn btn-primary">
              {{ $t("leffit.products.product_filter.save_changes") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import language from "../../../../data/language";

export default {
  name: "index",
  data() {
    return {
      pagedata: [],
      attributes: [
        "name",
        "description",
        "lang",
        "pickorder",
        "active",
        "created_at",
        "updated_at",
        "deleted_at",
      ],
      search: { search: {} },
      language: language,
      item: {
        active: 0,
        name: "",
        description: "",
        parent: "",
        language: "",
        pickorder: "",
        created_at: "",
        updated_at: "",
      },
      parents: [],
      loaded: false,
    };
  },
  methods: {
    addFilter() {
      this.parents.push(this.item.parent);
    },
    removeFilter(id) {
      this.parents = this.parents.filter((d) => d != id);
    },
    getFilter(id) {
      return this.pagedata.data.find((d) => d.id == id);
    },
    create() {
      // this.item.language=document.getElementById('filter_language').value;
      // this.item.parent_id=document.getElementById('filter_parent').value;

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/productfilters",
          details: { ...this.item, parents: this.parents },
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM ADDED",
            text: this.item.name + " was added successfully",
            type: "success",
          });
          this.pagedata.data.unshift(data.data.data);
          document.getElementById("closemodal").click();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    deleteItem(id) {
      this.pagedata = this.pagedata.data.filter((dt) => dt.id != id);
      this.$store
        .dispatch("delete", "/leffit/products/productfilters/" + id)
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: this.item.name + " was removed successfully",
            type: "success",
          });
          // this.pagedata = data.data.data;
        });
    },
    translate(input) {
      if (input) {
        if (input.constructor === String) {
          if (input.indexOf("leffit.products") !== -1) {
            return this.$t(input);
          } else {
            return input;
          }
        }
      }

      return input;
    },
    searchFilter(page = 1) {
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/search-parent-filters?page=" + page,
          details: this.search,
        })
        .then((data) => {
          this.loaded = true;
          this.pagedata = data.data;
        });
    },
  },
  mounted() {
    //   window.$('.language').select2();
    //   window.$('.parent').select2();
    this.searchFilter();
  },
};
</script>
<style scoped>
.filter_li {
  list-style-type: none;
  margin: 5px 5px;
  display: inline-block;
}

.filter_li a.anc {
  background: #d3d3d363;
  padding: 1px 12px;
  border-radius: 17px;
  border: 1px solid #d3d3d369;
  color: grey;
  text-decoration: none;
}

.filter_li a.anc a {
  color: red;
  margin-left: 5px;
  font-size: 13px;
  text-decoration: none;
}
</style>
