<template>
  <div class="">
    <div class="head row p-0 m-0">
      <h4 v-show="isDisabled">
        <router-link
          :to="this.$route.fullPath + '/edit'"
          class="btn btn-secondary"
        >
          <i class="fa fa-edit"></i>
          {{ $t("leffit.crm.organization.edit") }}
        </router-link>
      </h4>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <h4>{{ $t("leffit.crm.organization.creditor_number") }}</h4>
          <button
            :disabled="genCLoading"
            v-if="item.creditor_nr == null || item.creditor_nr == ''"
            @click="generateNumber(1)"
            type="button"
            style="font-size:13px"
            class="btn btn-primary"
          >
            {{
              genCLoading
                ? $t("leffit.crm.organization.generating") + "..."
                : $t("leffit.crm.organization.generate_creditor_number")
            }}
          </button>
          <div v-else class="text-danger" style="height:45px">
            {{ item.creditor_nr }}
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <h4>{{ $t("leffit.crm.organization.debtor_number") }}r</h4>
          <button
            :disabled="genDLoading"
            v-if="item.debtor_nr == null || item.debtor_nr == ''"
            @click="generateNumber(2)"
            type="button"
            style="font-size:13px;background:#fb9c7e;color:white"
            class="btn"
          >
            {{
              genDLoading
                ? $t("leffit.crm.organization.generating") + "..."
                : $t("leffit.crm.organization.generate_debitor_number")
            }}
          </button>
          <div v-else class="text-danger" style="height:45px">
            {{ item.debtor_nr }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="isDisabled" class="row">
      <div class="col-sm-12 col-md-6">
        <div class="imgCard card">
          <h4>{{ $t("leffit.crm.organization.profile_image") }}</h4>
          <div
            v-bind:style="{ backgroundImage: 'url(' + item.picture + ')' }"
          ></div>
        </div>
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.organization.basic_details") }}</h4>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.coc") }}:</b>
              <h6>{{ item.coc }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.vat_number") }}:</b>
              <h6>{{ item.vat_number }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.iban_name") }}:</b>
              <h6>{{ item.iban_name }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.iban_number") }}:</b>
              <h6>{{ item.iban_number }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.switf_code") }}:</b>
              <h6>{{ item.swift_code }}</h6>
            </div>
          </div>
        </div>
        <div class="card basicDetailsCard">
          <h4>{{ $t("leffit.crm.organization.other_details") }}</h4>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.language") }}:</b>
              <h6>{{ item.language }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.crm.organization.currency") }}:</b>
              <h6>{{ item.currency.name }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card basicDetailsCard">
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.name") }}:</b>
            <h6>{{ item.name }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.address") }}</b>
            <h6>{{ item.address }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.address2") }}</b>
            <h6>{{ item.address2 }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.zipcode") }}</b>
            <h6>{{ item.zipcode }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.city") }}</b>
            <h6>{{ item.city }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.country") }}</b>
            <h6>{{ item.country }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.phone") }}</b>
            <h6>{{ item.phone }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.mobile") }}</b>
            <h6>{{ item.mobile }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.fax") }}</b>
            <h6>{{ item.fax }}</h6>
          </div>
          <div class="bsc">
            <b>{{ $t("leffit.crm.organization.email") }}</b>
            <h6>{{ item.email }}</h6>
          </div>
        </div>
      </div>
    </div>
    <form @submit="updateItem($event)">
      <div v-show="!isDisabled">
        <div style="background:white;" class="imgCard card">
          <h4 style="color:#4e5467 !important">UPLOAD IMAGE</h4>
          <div
            id="imgHolder"
            v-bind:style="{ backgroundImage: 'url(' + item.picture + ')' }"
          ></div>

          <div class="d-flex text-center pt-2">
            <input
              type="file"
              name=""
              id="file"
              class="d-none"
              @change="readFile($event)"
            />
            <button
              v-if="!this.profileImageSet"
              type="button"
              @click="trigger()"
              style="margin:auto;background:#fb9c7e;color:white;font-size: 12px;font-weight: 500;"
              class="btn "
            >
              Change Picture
            </button>
            <button
              v-if="this.profileImageSet"
              type="button"
              @click="cancelImage()"
              style="margin:auto;background:red;color:white;font-size: 12px;font-weight: 500;"
              class="btn "
            >
              Cancel Picture
            </button>
            <button
              v-if="this.file"
              type="button"
              @click="updateItem($event)"
              style="margin:auto;background:green;color:white;font-size: 12px;font-weight: 500;"
              class="btn "
            >
              Save Picture
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_name">{{
              $t("leffit.crm.organization.name")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_name"
              v-model="item.name"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_address">{{
              $t("leffit.crm.organization.address")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_address"
              v-model="item.address"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_address2">{{
              $t("leffit.crm.organization.address2")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_address2"
              v-model="item.address2"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-4">
            <label for="organization_zipcode">{{
              $t("leffit.crm.organization.zipcode")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_zipcode"
              v-model="item.zipcode"
            />
          </div>

          <div class="form-group col-4">
            <label for="organization_city">{{
              $t("leffit.crm.organization.city")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_city"
              v-model="item.city"
            />
          </div>

          <div class="form-group col-4">
            <label for="contact_country">{{
              $t("leffit.crm.contact.country")
            }}</label>
            <select2
              :options="
                country.map((c) => {
                  return { id: c.code, text: c.name };
                })
              "
              v-model="item.country"
              style="position:relative"
              :disabled="isDisabled"
              class="country"
              id="contact_country"
            ></select2>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="organization_phone">{{
              $t("leffit.crm.organization.phone")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_phone"
              v-model="item.phone"
            />
          </div>

          <div class="form-group col-6">
            <label for="organization_mobile">{{
              $t("leffit.crm.organization.mobile")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_mobile"
              v-model="item.mobile"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="organization_fax">{{
              $t("leffit.crm.organization.fax")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_fax"
              v-model="item.fax"
            />
          </div>

          <div class="form-group col-6">
            <label for="organization_email">{{
              $t("leffit.crm.organization.email")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_email"
              v-model="item.email"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <label for="organization_coc">{{
              $t("leffit.crm.organization.coc")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_coc"
              v-model="item.coc"
            />
          </div>

          <div class="form-group col-6">
            <label for="organization_vat_number">{{
              $t("leffit.crm.organization.vat_number")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_vat_number"
              v-model="item.vat_number"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-4">
            <label for="organization_iban_name">{{
              $t("leffit.crm.organization.iban_name")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_iban_name"
              v-model="item.iban_name"
            />
          </div>

          <div class="form-group col-4">
            <label for="organization_iban_number">{{
              $t("leffit.crm.organization.iban_number")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_iban_number"
              v-model="item.iban_number"
            />
          </div>

          <div class="form-group col-4">
            <label for="organization_swift_code">{{
              $t("leffit.crm.organization.swift_code")
            }}</label>
            <input
              type="text"
              :disabled="isDisabled"
              class="form-control"
              id="organization_swift_code"
              v-model="item.swift_code"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="contact_language">{{
              $t("leffit.crm.contact.language")
            }}</label>
            <select2
              :options="
                language.map((l) => {
                  return { id: l.code, text: l.name };
                })
              "
              v-model="item.language"
              style="position:relative"
              :disabled="isDisabled"
              class="language"
              id="contact_language"
            ></select2>
          </div>
          <div class="form-group col-6">
            <label for="type">{{
              $t("leffit.products.product.currency")
            }}</label>
            <v-select
              v-model="item.currency"
              :options="
                currencies.map((d) => {
                  return { label: d.name, id: d.id };
                })
              "
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_remarks">{{
              $t("leffit.crm.organization.remarks")
            }}</label>
            <textarea
              :disabled="isDisabled"
              class="form-control WYSIWYG"
              id="organization_remarks"
              v-model="item.remarks"
            ></textarea>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label for="organization_status_id">{{
              $t("leffit.crm.organization.status_id")
            }}</label>
            <select
              :disabled="isDisabled"
              class="form-control"
              id="organization_status_id"
              v-model="item.status_id"
            >
              <option value="1">{{
                $t("leffit.crm.organization.active")
              }}</option>
              <option value="0">{{
                $t("leffit.crm.organization.inactive")
              }}</option>
            </select>
          </div>
        </div>

        <!-- <fieldset v-for="(group,index) in item.extra" :key="index" class="group">
						<legend>{{index}}</legend>
						<div v-for="(field,index2) in group" :key="index2" class="form-group">
							<label for="">{{field.label}}</label>
							<input v-model="field.value" type="text" class="form-control">
						</div>
					</fieldset> -->

        <div class="row p-0 m-0">
          <button class="btn btn-success">
            <span v-if="!loading">UPDATE</span>
            <span v-else>UPDATING...</span>
          </button>
        </div>
      </div>
    </form>
    <div v-if="setting_ready && group_tabs.length > 0" class="card mt-2">
      <tabsMeta
        :item="item"
        :tabs="group_tabs"
        :options="options"
        :package_name="'crm_organization_settings'"
      ></tabsMeta>
    </div>
    <div class="card mt-2" v-if="setting_ready">
      <ul class="nav nav-pills nav-fill">
        <li v-if="shouldDisplay('contact')" class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#contact">{{
            $t("leffit.crm.organization.contact")
          }}</a>
        </li>
        <li v-if="shouldDisplay('address')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#address">{{
            $t("leffit.crm.organization.address")
          }}</a>
        </li>
        <!-- <li v-if="shouldDisplay('admins')" class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#admin">{{ $t('leffit.crm.organization.admins') }}</a>
				</li> -->
        <li v-if="shouldDisplay('order')" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#orders">{{
            $t("leffit.crm.organization.orders")
          }}</a>
        </li>
        <li v-if="shouldDisplay('product')" class="nav-item">
          <a class="nav-link " data-toggle="tab" href="#products">{{
            $t("leffit.crm.organization.products")
          }}</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-if="shouldDisplay('contact')"
          class="tab-pane fade show active"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <contact-table :item="item"></contact-table>
        </div>
        <div
          v-if="shouldDisplay('address')"
          class="tab-pane fade"
          id="address"
          role="tabpanel"
          aria-labelledby="address-tab"
        >
          <address-table :item="item"></address-table>
        </div>
        <div
          v-if="shouldDisplay('admins')"
          class="tab-pane fade"
          id="admin"
          role="tabpanel"
          aria-labelledby="address-tab"
        >
          <admin :item="item"></admin>
        </div>
        <div
          v-if="shouldDisplay('order')"
          class="tab-pane fade"
          id="orders"
          role="tabpanel"
          aria-labelledby="order-tab"
        >
          <div class="row col-12">
            <a href="/admin/sales/orders/sales_order/create">
              <button class="btn btn-primary">
                {{ $t("leffit.crm.contact.new_order") }}
              </button>
            </a>
          </div>
          <order-table
            :hideIcons="true"
            :showSalesOpen="true"
            :pagedata="orders"
            :ordertype_id="$route.params.id"
          ></order-table>
        </div>
        <div
          v-if="shouldDisplay('product')"
          class="tab-pane fade"
          id="products"
          role="tabpanel"
          aria-labelledby="product-tab"
        >
          <product-table></product-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import contactTable from "./contacts";
import addressTable from "./address";
import orderTable from "../../orders/order/table";
import productTable from "./products";
import admins from "./admins";
import tabsMeta from "../../components/tabs_meta";

export default {
  props: ["id", "edit"],
  name: "item",
  components: {
    tabsMeta,
    "contact-table": contactTable,
    "address-table": addressTable,
    "order-table": orderTable,
    "product-table": productTable,
    admin: admins,
  },
  data() {
    return {
      item: {
        name: "",
        address: "",
        address2: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        picture: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "",
        remarks: "",
        status_id: "",
        parent_id: "",
        organizationtype_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        extra: [],
        currency: {},
      },
      language: language,
      country: country,
      profileImageSet: false,
      genCLoading: false,
      genDLoading: false,
      contacts: [],
      address: [],
      orders: [],
      products: [],
      search: {},
      settings: [],
      setting_ready: false,
      group_tabs: [],
      options: [],
      file: null,
      loading: false,
      currencies: [],
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    isDisabled: function() {
      return !(this.edit == "edit");
    },
  },
  methods: {
    shouldDisplay(tab) {
      var check = false;
      if (this.settings != null) {
        var tb = this.settings.find((setting) => setting.field == tab);
        if (tb != undefined) {
          if (tb.show) {
            check = true;
          }
        }
      }

      return check;
    },
    generateNumber(type) {
      if (type == 1) {
        this.genCLoading = true;
        this.$store
          .dispatch(
            "get",
            "/leffit/crm/organizations/generate-creditor-number/" + this.item.id
          )
          .then((data) => {
            this.item.creditor_nr = data.data;
            // console.log(data);
            this.genCLoading = false;
            this.$store.dispatch("makeNotification", {
              title: "NUMBER GENERATED",
              text: this.item.name + " was updated successfully",
              type: "success",
            });
          })
          .catch((error) => {
            // console.log(error.request);
            this.genCLoading = false;
          });
      } else {
        this.genDLoading = true;
        this.$store
          .dispatch(
            "get",
            "/leffit/crm/organizations/generate-debtor-number/" + this.item.id
          )
          .then((data) => {
            this.item.debtor_nr = data.data;
            // console.log(data.data);
            this.genDLoading = false;
            this.$store.dispatch("makeNotification", {
              title: "NUMBER GENERATED",
              text: this.item.name + " was updated successfully",
              type: "success",
            });
          })
          .catch((error) => {
            // console.log(error.request);
            this.genDLoading = false;
          });
      }
    },
    updateItem(e) {
      e.preventDefault();
      this.loading = true;

      this.item.remarks = window.tinyMCE
        .get("organization_remarks")
        .getContent();
      this.item.language = document.getElementById("contact_language").value;
      this.item.country = document.getElementById("contact_country").value;
      if (this.item.currency) {
        this.item.currency_id = this.item.currency.id;
      }
      var formData = new FormData();

      for (var field in this.item) {
        if (field != "extra") {
          if (this.item[field] != null) {
            formData.append(field, this.item[field]);
          }
        }
      }

      formData.append("extra", JSON.stringify(this.item.extra));
      if (this.file != null) {
        formData.append("org_picture", this.file);
      }

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/update-organizations/" + this.item.id,
          details: formData,
        })
        .then((data) => {
          // console.log('result',data.data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.name + " was updated successfully",
            type: "success",
          });
          this.file = null;
          this.profileImageSet = false;
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          // console.log(error.request);
          this.loading = false;
        });
    },
    trigger() {
      document.getElementById("file").click();
    },
    cancelImage() {
      document.getElementById("imgHolder").style.backgroundImage =
        "url('" + this.item.picture + "')";
      this.profileImageSet = false;
    },
    readFile(e) {
      var file = document.getElementById("file").files[0];
      let formats = ["image/png", "image/jpg", "image/jpeg", "image/PNG"];

      if (!formats.find((ft) => ft == file.type)) {
        alert("file format not supported");
        return false;
      }

      var fileReader = new FileReader();
      fileReader.onload = (result) => {
        document.getElementById("imgHolder").style.backgroundImage =
          "url('" + result.srcElement.result + "')";
        this.profileImageSet = true;
        this.file = document.getElementById("file").files[0];
      };
      fileReader.readAsDataURL(file);
    },
  },
  async mounted() {
    await this.$store
      .dispatch("get", "/leffit/crm/organizations/" + this.id)
      .then((data) => {
        this.item = data.data.data;
        this.contacts = this.contacts.length == 0 ? [] : this.item.contacts;
        this.address = this.item.addresses;
      });
    window.tinymce.init({
      selector: "textarea", // change this value according to your HTML
      plugin: "a_tinymce_plugin",
      a_plugin_option: true,
      a_configuration_option: 400,
    });

    this.$store
      .dispatch(
        "get",
        "/lefficient/leffit/packages/settings/crm_organization_settings"
      )
      .then((data) => {
        // console.log(data);
        if (data.data != null) {
          if (data.data.setting != null) {
            var settings = JSON.parse(data.data.setting);
            this.settings = settings.tabs;
            if (settings.options) {
              this.options = JSON.parse(JSON.stringify(settings.options));
            }
            this.group_tabs = settings.group_tabs
              ? JSON.parse(JSON.stringify(settings.group_tabs))
              : [];
            this.setting_ready = true;
          }
        }
        console.log(this.settings);
      })
      .catch((error) => {
        console.log(error);
      });

    // Getting currencies
    await this.$store
      .dispatch("get", "/lefficient/leffit/currencies")
      .then((data) => {
        this.currencies = data.data.data;
      });

    // Getting orders
    this.$store
      .dispatch(
        "get",
        "/leffit/crm/get-organization-orders/" +
          this.$route.params.id +
          "?ordertype_id=120"
      )
      .then((data) => {
        this.orders = data.data;
      })
      .catch((error) => {
        console.log(error.request);
      });
  },
};
</script>

<style lang="css" scoped>
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
