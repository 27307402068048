<!--
    TODO
    - File upload for profile image
    - check if required fields are filled in before post
    - post and create account in api
    - show registration complete message
 -->
<template>
	<div id="register">

        <div id="register_module" class="col-12 col-sm-4 col-md-8 col-lg-8"> 
            <!-- <div v-if="messages.info" v-html="messages.info" class="mb-5"></div> -->
            <b>{{ $t('login.signup') }} <i class="fas fa-times"></i></b>
            <div id="form">
                <form action="" v-if="controllers.active==1 && !register_task.task_loader">
                    <div class="form-row" v-for="(row, index) in form" :key="index">
                        <div class="form-group" v-for="(field, index) in row" :key="index" :class="'col-sm-12 col-md-'+ 12/row.length">
                            <label>{{ attributes[field].label }} <span v-if="attributes[field].required">*</span></label>
                            <input v-if="attributes[field].inputtype == 'text'" class="form-control" v-model="attributes[field].value" type="text">
                            <input v-if="attributes[field].inputtype == 'password'" class="form-control" v-model="attributes[field].value" type="password">
                            <input v-else-if="attributes[field].inputtype == 'date'" class="form-control" v-model="attributes[field].value" type="date">
                            <textarea v-else-if="attributes[field].inputtype == 'textarea'" rows='1' class="form-control" v-model="attributes[field].value"></textarea>
                            <select v-else-if="attributes[field].inputtype == 'select'" class="form-control" v-model="attributes[field].value">
                                <option v-for="(option, index) in attributes[field].options" :key="index" :value="index">{{ option }}</option>
                            </select>
                            <span v-else-if="attributes[field].inputtype == 'file'" id="profile_image">
                                <div :style="'background-image:url('+preview_image+')'" class="imgCont">   
                                    <!-- <img :src="preview_image" alt=""> -->
                                </div>
                                <!-- <b @click="selectImage()">
                                    Voeg profielfoto toe
                                </b> -->
                                <input id="profile_image_file" type="file" @change="readfile($event)" class="form-control-file" v-if="true">
                            </span>
                            <span style="color:red;font-size:13px;margin-top:5px" v-if="responseError[attributes[field].label]">
                                {{responseError[attributes[field].label][0]}}
                            </span>
                        </div>
                    </div>
                    <div class="form-group" >
                        <p v-if="register_task.error" class="msg text-danger">{{register_task.error_msg}}</p>
                        <p v-if="register_task.successfull" class="msg text-success">{{register_task.success_msg}}</p>
                        <!-- <button :disabled="(register_task.task_loader ? true:false)" class="btn btn-party btn-mijn btn-block p-2" type="submit">
                            <div id="task_loader" v-if="register_task.task_loader"></div>
                            {{register_task.btntext}}
                        </button> -->
                    </div>
                </form>
                <form v-if="controllers.active == 2 && !register_task.task_loader" style="margin-bottom:200px">
                    <div class="form-row">
                        <p>Je kan voortaan inloggen met je e-mailadres.</p>
                        <div class="form-group col-12">
                            <label for="">Maak een wachtwoord</label> {{error.password }}
                            <input type="password" :class="error.password ? 'form-control error':'form-control'" v-model="staticAttributes.password">
                        </div>
                        <p>
                            Wachtwoord moet bestaan uit minimaal 8 tekens <br>
                            waaronder 1 nummer (0-9), 1 hoofdletter (A-Z), 1 kleine letter (a-z) en 1 symbool (!#*)
                        </p>
                        <div class="form-group col-12">
                            <label for="">Vul nogmaals je wachtwoord in</label>
                            <input type="password" :class="error.confirm_password ? 'form-control error':'form-control'" v-model="staticAttributes.confirm_password">
                            <span style="color:red;font-size:13px;margin-top:5px" v-if="error.confirm_password">
                                Password doesn't match.
                            </span>
                        </div>
                        <div class="col-12" id="errors" v-if="errors.length > 0">
                            <ul class="m-0 p-0 pl-3">
                                <li v-for="(error,index) in errors" :key="index">
                                    {{error.msg}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
                <form v-if="register_task.task_loader" style="margin:200px 0px;"> 
                    <div id="task_loader" ></div>  
                </form>
                <div id="profile" v-if="controllers.active == 4 && !register_task.task_loader">
                    <p>Controleer de onderstaande gegevens</p>
                    <ul class="col-12">
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Naam:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.firstname+' '+responseData.lastname}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Adres:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.address}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Telefoon:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.phone}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Mobiel:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.mobile}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Email:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.email}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Website:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData.website}}</div>
                        </li>
                        <li class="col-12 row m-0 p-0">
                            <div class="col-sm-12 col-lg-2">Geboortedag:</div>
                            <div class="col-sm-12 col-lg-10">{{responseData['date_of_birth']}}</div>
                        </li>
                    </ul>
                    <div class="col-12">
                        <!-- <div :style="'background-image:url('+preview_image+')'" class="imgCont2" v-if="preview_image.length != 0"></div> -->
                        <p>{{responseData['about_me']}}</p>
                    </div>
                    <div class="col-12 row m-0 p-0"> 
                        <div class="col-sm-12 col-lg-6 form-group form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Ik ga akkoord met de <a href="#">algemene voorwaarde</a></label>
                        </div>
                        <div class="col-sm-12 col-lg-6 form-group form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Ik ben geen robot</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="controller" style="border-top:1px solid gray;text-align:center">
                <button @click="prev()" v-if="controllers.active == 2" class="btn"><i class="fas fa-arrow-left"></i> Terug </button>
                <button v-if="controllers.active != 4 && !register_task.task_loader" :disabled='register_task.task_loader' @click="next()" class="btn">Verder <i class="fas fa-arrow-right"></i></button>
                <a href="/dashboard" v-if="controllers.active == 4" >
                    <button :disabled='register_task.task_loader' class="btn"><i class="fas fa-plus"></i> Profiel aanmaken </button>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name:'register',
    data(){
        return {
            staticAttributes:{password:'',confirm_password:''},
            attributes : {},
            form : [],
            register_task:{
                sent:false,
                error:false,
                error_msg:'',
                success:false,
                success_msg:'',
                btntext:"Registreer",
                task_loader:false
            },
            preview_image:'',
            selectedFile:{},
            messages : {},
            controllers:{ 
                active:1
            },
            errors:[],
            responseError:[],
            responseData:{},
            error:{
                password:false,
                confirm_password:false
            },
            loading:false
        }
    },
    methods:{
        prev(){
            if(this.active <= 1){
                this.controllers.active=1;
                return false;
            }

            this.controllers.active=this.controllers.active-1;
        },
        next(){
            if(this.controllers.active >= 2){ 
                
                if(!this.verifyPassword()){
                    return false;
                }
                this.controllers.active=3;

                this.attributes.Password=this.staticAttributes.password;   
                this.register();

                return false;
            }

            this.controllers.active=this.controllers.active+1;
        },
        verifyPassword(){
            this.error.password=false;
            this.error.confirm_password=false;

            let password=this.staticAttributes.password;
            let confirm_password=this.staticAttributes.confirm_password; 

          
            if(password.length < 8){ 
                this.error.password=true;
                return false; 
            }
            if(confirm_password != password){ 
                this.error.confirm_password=true;
                return false; 
            }
            
            // if(password.match(/\[0-9]/)){
            //     window.console.log('found number');

            //     return false;
            // }


            return true;
        },
        readfile(e){
            // window.console.log(); 
            let formats=['image/png','image/jpg','image/jpeg'];
            let selectedFile=e.target.files[0];

            let flag=formats.find((item)=>{
                return selectedFile.type==item;
            });
            if(!flag){
                alert('file format not supported');
            }

            this.selectedFile=selectedFile;
            let filereader=new FileReader();
            filereader.onload=()=>{
                this.preview_image=filereader.result;
                 
            }

            filereader.readAsDataURL(selectedFile);
        },
        register(){
            this.register_task.error=false;
            var item={};

            var payload=new FormData();

            //==============VALIDATING REQUIRED FIELDS =============================//
            for(var key in this.attributes){
                item=this.attributes[key];

                //MAKING SURE ALL REQUIRED FIELDS ARE NOT EMPTY FIELDS
                    if(item.required){
                        if(item.value.trim().length == 0){
                            this.register_task.error=true;
                            this.register_task.error_msg="Please fill the "+ item.label +" field";
                            this.controllers.active=1; 
                            this.responseError[item.label]=[item.label+' is required'] ;
                            return false;
                        } 
                    } 


                //PROCESSING AND CUSTOMISING THE PAYLOAD
                payload.append(item.label,item.value);
            }


            this.controllers.active=3;
            
             
            payload.append('profile_image',this.selectedFile);
            
  
            this.register_task.task_loader=true;
            this.register_task.btntext="request register...";
             

            this.$store.dispatch("register",payload)
            
            .then(data=>{
                window.console.log(data);

                setTimeout(()=>{
                    this.register_task.task_loader=false;
                },2000);
                
                // this.controllers.active=2;
                this.controllers.active=4;
                this.responseData=data.data;
                alert("Registeration was successful");

                // this.$store.commit('setUser',data.data.user);
                // this.$store.commit('setToken',data.data.token);

                
                // window.location="/dashboard";

            })
            
            .catch(error=>{
                setTimeout(()=>{
                    this.register_task.task_loader=false;
                      this.controllers.active=2;  
                },2000);
                
              
                this.errors=[];

                 window.console.log(JSON.parse(error.request.response)) ;

                if(error.request.status == 401){
                    // this.register_task.error=true;
                    // this.register_task.error_msg="invalid register details";

                    this.errors.push({
                            msg:"Oops! an error occurred! Please try again"
                        });
                }else if(error.request.status == 422){
                    var error_obj=JSON.parse(error.request.response);
                    
                    this.responseError=error_obj.errors;

                    this.register_task.error=true;
                    for(var key in error_obj.errors){
                        
                        this.errors.push({
                            msg:error_obj.errors[key][0]
                        });
                        // this.register_task.error_msg=error_obj.errors[key][0];
 
                    }
                    
                }
                else if(error.request.status == 400){
                     this.errors.push({
                        msg:"File format not supported."
                    });
                }
               
                  this.errors.push({
                        msg:"Oops! an error occurred! Please try again"
                    });
            });
        }
    },

    created(){
        // var user=JSON.parse(localStorage.getItem('user'));
        // var token=JSON.parse(localStorage.getItem('token'));

        // if(token != null && user !=null){
            // window.location='/dashboard';
        // }

        // this.$store.dispatch("get","users/register")

        // .then(data=>{
        //     window.console.log('data',data.data);
        //     this.attributes = data.data.attributes;
        //     this.form = data.data.form;
        //     this.messages = data.data.messages;

        // })
        
        // .catch(error=>{
        //     if(error.request.status == 401){
        //         this.error=true;
        //         this.error_msg="invalid login details";
        //     }
        //     // window.console.log(error.request);
            
        // });

    }
}
</script>
<style>
    body{ 
        /* background-image: linear-gradient(to right ,#00897e,#0090b5,#005197,#044580,#262261) !important ; */
    }
</style>
<style scoped>
    #errors{
        background: red;
        color: white;
        padding: 20px ;
        margin-top: 30px;
    }
    #profile{
        padding:20px 10px;
    }
    #profile .form-group .form-check-input{
        transform:scale(2);
        margin-left:0px;
    }
    #profile .form-group label{
        margin-left:25px;
    }
    #profile ul{
        list-style: none;
        margin:0px;
        padding:0px;
    }
    #profile ul li{
        margin:10px 0px !important;
    }
    #profile ul li div{
        padding:0px;
    }
    #profile_image_file{
        width:auto;
        margin-top: 10px;
    }
    #profile_image{
        display:flex;
        
    }
    .imgCont{
        border-radius:50%;
        border:1px solid gray;
        width:50px;
        height:50px;
        display:inline-block;
        background-position:center;
        background-size: cover;
    }
    .imgCont2{
        border-radius:50%;
        border:1px solid gray;
        width:200px;
        height:200px;
        display:inline-block;
        background-position:center;
        background-size: cover;
    }
    #profile_image b{
        display:inline-block;
        border:none !important;
        cursor: pointer;
    }
    #profile_image .imgCont img{
        
    }
    .controller{
        padding: 8px 0px;
        position: fixed;
        width: 100%;
        background: white;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .controller .btn{
       color:gray;
       background:white;
       
   }
   .controller .btn:first-child{
       margin-right:40px;
   }
    #register_module b{
        display: block;
        padding: 10px 10px;
        border-bottom:1px solid lightgray;
    }
    #register_module b i.fas{
        float: right;
        margin-right: 10px;
    }
    .msg{
        font-size: 12px;
    }
    #task_loader{
        width: 50px;
        height: 50px;
        border: 2px solid gray;
        border-top: 2px solid white;
        border-radius: 50%; 
        left: 15%; 
        margin:auto;
        animation: spin .6s linear infinite;
    }
    @keyframes spin{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    body{
        background-color:#f5f5f5 !important;
    }
    #register_module .foot{
        padding: 10px 16px;
    }
    #register_module .foot a{
        font-size: 13px;
        font-weight: bold;
        color: #545454;
    }
    #register_module{
        margin:50px auto;
        background: white;
        display: table;
        box-shadow:0px 0px 4px gray;
        border-radius:0px; 
        padding: 0px;
        transform: scale(0.9);
        transition: all .2s linear !important;
    }
    /* #register_module img{
        width: 265px;
        display: block;
        margin: 0 auto;
        margin-top: -13px;
        background-color: white;
        border-radius: 7px;
        box-shadow: 0px 0px 2px grey;
        padding: 5px 7px;
    } */
    #register_module h2{
        text-align: center;
        margin: 20px 0px;
        color: #014d90;
        font-weight: 700;
    }
    
    #register_module form input:focus{
        border:1px solid #014d90;
        box-shadow: none;
    }
    #register_module form input{
        font-size: 0.8rem; 
        padding: 0px 8px;
        height: 34px;
        border-radius: 2px;
    }
    #register_module form input.error{
        animation:flash .5s linear;
        border:1px solid red;
    }
    #register_module .btn-mijn{
         margin: 0 auto;
         padding: .300rem .75rem;
    }
    #register_module form{
        padding:10px 15px;
    }
    #register_module form label{
        font-weight:400;
        font-size:15px;
        margin-bottom:3px;
    }
    #register_module form .form-group{
        margin-bottom:9px;
    }

    @keyframes flash{
        0%{ 
            box-shadow:none;
        }
        100%{ 
            box-shadow:0px 0px 5px red;
        }
    }
</style>
