<template>
    <div>
        <div class="row">
            <div class="card col-12 col-md-12">
                <div style="width:120px" class="img m-auto">
                    <img  height="100px" :src="item.filename" id="imgHolder" alt="" class="img-responsive">
                </div>
               <div v-if="!editting">
                    <h4>{{item.title}}</h4> 
                    <p v-html="item.short_description"></p> 
                    <p v-html="item.description"></p>
               </div> 
               <form v-else action="" class="mb-5" @submit="createNewsletter($event)">
                    <div class="form-group">
                        <input v-model="item.title" id="title" type="text" class="form-control" placeholder="Enter template title">
                    </div>
                    <div class="form-group">
                        <label for="">Short Description</label>
                        <editor
                            v-model="item.short_description"
                            :init="{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                            }"
                        />
                    </div>
                    <div class="form-group">
                        <label for="">Description</label>
                        <editor
                            v-model="item.description"
                            :init="{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                            }"
                        />
                    </div> 
                    <div class="form-group">
                        <input type="file" class="form-control" id="file" @change="readFile()">
                    </div>
                </form>
                <div class="col-12">
                    <button @click="toggleEdit()" v-if="!editting" class="btn btn-success">EDIT</button>
                    <button @click="update()" v-else class="btn btn-success">UPDATE</button>
                </div>
            </div>

            <div class="card col-12">
                <h4>ADD NEW LESSON</h4>
                <div class="row col-12">
                    <div class="col-10">  
                        <Select2   @select="addLesson()"  v-model="value" :settings="{ settingOption: value, settingOption: value }" :options="options" />
                    </div>
                    <!-- <div class="col-2"> 
                        <button @click="addLesson()" class="btn btn-success plus-lesson btn-block"><i class="fa fa-plus"></i></button>
                    </div> -->
                </div>
            </div>
            <div class="col-12 card">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Title</th> 
                            <th>Description</th> 
                            <th colspan="2">Available After Period</th> 
                        </tr>
                    </thead> 
                    <tbody> 
                        <tr v-for="(ft,index) in item.lessons" :key="index"> 
                            <td>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                <button @click="reOrder('up',index)" v-if="index > 0" class="btn btn-sm pl-2 pr-2 btn-warning"><i class="fa fa-chevron-up"></i></button>
                                <button @click="reOrder('down',index)" v-if="index < item.lessons.length -1 " class="btn btn-sm pl-2 pr-2 btn-success"><i class="fa fa-chevron-down"></i></button>
                                <button @click="editAvailability(ft)" class="btn btn-sm pl-2 pr-2 btn-primary"><i class="fa fa-edit"></i></button>
                                <button @click="deleteLesson(ft.id)" class="btn btn-sm pl-2 pr-2 btn-danger"><i class="fa fa-trash"></i></button>
                            </div>
                            </td>
                            <td>{{ft.name}}</td> 
                            <td>{{ft.description}}</td> 
                            <td>
                                <button @click="editAvailability(ft)" class="btn btn-sm pl-2 pr-2 btn-primary"><i class="fa fa-edit"></i></button>
                            </td>
                            <td>
                                <span v-if="ft.number">{{ft.number}}</span> <span v-if="ft.period">{{ ft.period}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card pb-5" style="overflow-y:scroll;width:100%">
                <sectionBuilder
                    :get_url="'/leffit/courses/modules/'"
                    :media_url="'/leffit/courses/add-modules-media/'"
                ></sectionBuilder> 
            </div>
        </div>

        <div class="modal" id="validityModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">SET UP AVAILABILITY</h3>
                    </div>
                    <div class="modal-body"> 
                        <div class="card-body"> 
                            <div class="form-group">
                                <label for="">Period</label>
                                <select v-model="validity.period" class="form-control" id="">
                                    <option value="days">Day</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="">Number</label>
                                <input v-model="validity.number" type="number" class="form-control" >
                            </div>
                            <div class="form-group">
                                <button @click="updateValidity()" class="btn btn-success">
                                    <span v-if="editAvail">UPDATE</span>
                                    <span v-else>SUBMIT</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template> 
<script>
import {mapState} from 'vuex';
import sectionBuilder from "../sectionBuilder/create_template" 
export default {
    components:{
        sectionBuilder
    },
    data(){
        return {
            item:{},
            editting:false,
            lessons:[],
            options:[],
            value:0,
            validity:{
                period:'',
                number:0
            }, 
            editAvail:false
        }
    },
    computed:{
        ...mapState({})
    },
    methods:{
        editAvailability(data){
            var temp={};
            if(data.pivot.available_after != null){
                temp=JSON.parse(data.pivot.available_after);
            }
            this.value=data.id;
            this.validity=temp;
            this.editAvail=true;
            window.$("#validityModal").modal('show');
        }, 
        updateValidity(){
            if(this.validity.period.length  == 0 || this.validity.number == 0){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validation Error",text:'All validity fields are required.'});
                return false;
            }
            this.$store.dispatch('post',{
                details:this.validity,
                endpoint:'/leffit/courses/add-lesson/'+this.value+'/to-module/'+this.$route.params.id,
            })
            .then((data)=>{
                console.log(data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"UPDATE",text:''});
            })
            .catch((error)=>{
                console.log(error);
            });

            if(!this.editAvail){
                var val=this.lessons.find((d)=>d.id == this.value);
                this.item.lessons.push(val);
            }
            window.$("#validityModal").modal('hide');
        },
        reOrder(direction,index){
            var data=this.item.lessons;
            if(direction == 'up'){
                var temp=data[index];
                data[index]=data[index-1];
                data[index-1]=temp;
            }else if(direction == 'down'){
                temp=data[index];
                data[index]=data[index+1];
                data[index+1]=temp;
            }
            this.item.lessons=JSON.parse(JSON.stringify(data)); 

            var payload=this.item.lessons.map((d,index)=>{
                return {
                    index,
                    id:d.id
                };
            });
            console.log(payload);

            this.$store.dispatch('post',{details:this.item.lessons,endpoint:'/leffit/courses/reorder-lesson/'+this.$route.params.id})
                .then((data)=>{
                    console.log(data);
                    // this.item=data.data.data;
                })
                .catch((error)=>{
                    console.log(error);
                });

        },
        deleteLesson(id){
            this.$store.dispatch('get','/leffit/courses/remove-lesson/'+id+'/from-module/'+this.$route.params.id)
            .then((data)=>{
                console.log(data);
                // this.item=data.data.data;
            })
            .catch((error)=>{
                console.log(error);
            });
            this.item.lessons=this.item.lessons.filter((d)=> d.id != id);
        },
        toggleEdit(){
            this.editting=!this.editting;
        },
        update() {
            this.$store.dispatch('put',{details:this.item,endpoint:'/leffit/courses/modules/'+this.$route.params.id})
            .then((data)=>{
                console.log(data);
                // this.item=data.data.data;
            })
            .catch((error)=>{
                console.log(error);
            });
            
        },
        addLesson(){
            this.editAvail=false;
            this.validity={};
            window.$("#validityModal").modal('show');
        },
        readFile(){
            var file=document.getElementById('file').files[0];
            
            let formats=['image/png','image/jpg','image/jpeg','image/PNG'];
            if(!formats.find((ft=> ft == file.type))){ alert('file format not supported'); return false; }

            var fileReader=new FileReader();
            fileReader.onload=(result)=>{   
                this.profileImageSet=true;
                document.getElementById('imgHolder').setAttribute('src',result.srcElement.result);
                this.item.image=result.target.result;
            };
            fileReader.readAsDataURL(file); 
        }
    },
    created(){
        this.$store.dispatch('get','/leffit/courses/modules/'+this.$route.params.id)
        .then((data)=>{
            console.log(data);
            this.item=data.data.data;
        })
        .catch((error)=>{
            console.log(error);
        });

        this.$store.dispatch('get','/leffit/courses/lessons')
        .then((data)=>{ 
            this.lessons=data.data.data; 
            this.options=this.lessons.map((d)=>{
                return {
                    id:d.id,
                    text:d.name
                }
            });
            window.$(".select2-container").css('width','100%'); 
        })
        .catch((error)=>{
            console.log(error);
        });
    }
}   
</script>


<style lang="css" scoped>
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
    .plus-lesson{
        padding: 2px 10px;
        margin-top: -3px;
    }
</style>