<template>
  <div class="mb-5">
    <div class="head row p-0 m-0">
      <div v-show="isDisabled" class="col-12 p-0 m-0">
        <h3 class="pl-3">{{ item.name }}</h3>
      </div>
      <div v-show="!isDisabled" class="col-12 p-0 m-0">
        <h3 class="pl-3">
          {{ $t("lefficient.system.edit_field", [item.name]) }}
        </h3>
      </div>
      <h4 v-show="isDisabled">
        <router-link
          :to="this.$route.fullPath + '/edit'"
          class="btn btn-secondary"
          ><i class="fa fa-edit"></i>
          {{ $t("lefficient.system.edit") }}</router-link
        >
      </h4>
      <div v-show="isDisabled" class="col-12 p-0 m-0">
        <button
          @click="set()"
          data-target="#addnew"
          data-toggle="modal"
          class="btn btn-success btn-sm"
        >
          Add New Filter
        </button>
      </div>
    </div>
    <div v-show="isDisabled" class="row">
      <div class="col-sm-12 col-md-12">
        <div class="card basicDetailsCard">
          <h4>BASIC DETAILS</h4>
          <div class="row">
            <img :src="item.image" alt="" />
          </div>
          <div id="">
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.name") }}:</b>
              <h6>{{ item.name }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.english_name") }}:</b>
              <h6>{{ item.name_en }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.parent") }}:</b>
              <h6 v-if="item.parent != null">{{ item.parent.name }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.description") }}:</b>
              <h6>{{ item.description }}</h6>
            </div>
            <div class="bsc bsc2">
              <b
                >{{
                  $t("leffit.products.product_filter.english_description")
                }}:</b
              >
              <h6>{{ item.description_en }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.pickorder") }}:</b>
              <h6>{{ item.pickorder }}</h6>
            </div>
            <div class="bsc bsc2">
              <b>{{ $t("leffit.products.product_filter.active") }}:</b>
              <h6 v-show="item.active != 1">NO</h6>
              <h6 v-show="item.active == 1">YES</h6>
            </div>
          </div>
        </div>
        <!-- <div class="card">
					<h4>CHILDREN FILTER</h4>
					<table class="table table-responsive table-hover">
							<thead>
								<tr>
									<th></th>
									<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.filters.'+attribute) }}</th>
								</tr>
							</thead>
						<thead>
							<tr>
							<th><a class="btn btn-primary"><i class="fa fa-search"></i></a></th>
							<th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search[attribute]" class="form-control"></th>
							</tr>
						</thead>
							<tbody>
								<tr :key="index" v-for="(item, index) in pagedata">
									<td>
										<router-link :to="'/admin/products/settings/productfilter/'+item.id" class="btn btn-open">
											<i class="fa fa-search"></i>
										</router-link>
										<router-link :to="'/admin/products/settings/productfilter/'+item.id+'/edit'" class="btn btn-warning">
											<i class="fa fa-pencil"></i>
										</router-link>
										<a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
											<i class="fa fa-trash"></i>
										</a>
									</td>
									<td :key="index" v-for="(attribute,index) in attributes">{{ item[attribute] }}</td>
								</tr>
							</tbody>
					</table>

					<div class="modal" tabindex="-1" role="dialog" id="addnew">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">Add New Filter</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">
								<div class="row">
									<div class="form-group col-12">
										<label for="filter_title">{{ $t('leffit.products.product_filter.file') }}</label>
										<input type="file"  class="form-control" id="filter_file2" >
									</div>
									<div class="form-group col-sm-6 ">
										<label for="">Name</label>
										<input v-model="item2.name" type="text" class="form-control" />
									</div>
									<div class="form-group col-sm-6 ">
										<label for="">English Name</label>
										<input v-model="item2.name_en" type="text" class="form-control" />
									</div>
									<div class="form-group col-sm-6">
										<label for="">Description</label>
										<input v-model="item2.description" type="text" class="form-control" />
									</div>
									<div class="form-group col-sm-6">
										<label for="">English Description</label>
										<input v-model="item2.description_en" type="text" class="form-control" />
									</div>
									<div class="form-group col-sm-6 ">
										<label for="">Pickorder</label>
										<input v-model="item2.pickorder" type="text" class="form-control" />
									</div>

									<div class="form-group col-12 ">
										<label for="filter_active" class="d-flex" style="width:60px">
											<input :checked="item2.active == 1 ? true:false" @click="item2.active=item2.active == 0 ? 1:0" style="height: 21px;" type="checkbox" class="form-control d-block" id="filter_active"  />
											<div>{{ $t('leffit.products.product_filter.active') }}</div>
										</label>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<button id="closemodal" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Close</button>
								<button @click="create()" type="button" class="btn btn-primary">{{ $t('lefficient.system.save') }}</button>
							</div>
							</div>
						</div>
					</div>
				</div> -->
      </div>
    </div>
    <form @submit="updateItem($event)">
      <div v-show="!isDisabled">
        <div class="card">
          <div class="row">
            <img class="img-w" :src="item.image" alt="" />
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="filter_title">{{
                $t("leffit.products.product_filter.file")
              }}</label>
              <input
                type="file"
                :disabled="isDisabled"
                class="form-control"
                id="filter_file"
              />
            </div>
            <div class="form-group col-6">
              <label for="filter_title">{{
                $t("leffit.products.product_filter.name")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="filter_title"
                v-model="item.name"
              />
            </div>
            <div class="form-group col-6">
              <label for="filter_title">{{
                $t("leffit.products.product_filter.name_en")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="filter_title"
                v-model="item.name_en"
              />
            </div>

            <div class="form-group col-6">
              <label for="filter_prelastname">{{
                $t("leffit.products.product_filter.description")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="filter_prelastname"
                v-model="item.description"
              />
            </div>

            <div class="form-group col-6">
              <label for="filter_prelastname">{{
                $t("leffit.products.product_filter.description_en")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="filter_prelastname"
                v-model="item.description_en"
              />
            </div>

            <div class="form-group col-6">
              <label for="filter_lastname">{{
                $t("leffit.products.product_filter.pickorder")
              }}</label>
              <input
                type="text"
                :disabled="isDisabled"
                class="form-control"
                id="filter_lastname"
                v-model="item.pickorder"
              />
            </div>

            <div class="form-group col-12">
              <label for="filter_active" class="d-flex" style="width: 60px">
                <input
                  :checked="item.active == 1 ? true : false"
                  @click="item.active = item.active == 0 ? 1 : 0"
                  style="height: 21px"
                  type="checkbox"
                  :disabled="isDisabled"
                  class="form-control d-block"
                  id="filter_active"
                />
                <div>{{ $t("leffit.products.product_filter.active") }}</div>
              </label>
            </div>
          </div>

          <div class="row p-0 m-0">
            <button class="btn btn-success">
              {{ $t("lefficient.system.save") }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div v-if="setting_ready" class="row m-0">
      <div class="card col-12">
        <ul
          class="nav nav-pills nav-fill"
          style="border: 1px solid lightgray; padding: 6px; width: 99%"
        >
          <!-- <li v-if="settings.parent" class="nav-item">
						<a :class="tabsController.parents ? 'nav-link active':'nav-link'" href="#parent" @click="toggleTab('parents')">{{ $t('leffit.products.filter.parents') }}</a>
					</li> -->
          <li v-if="settings.children" class="nav-item">
            <a
              :class="tabsController.children ? 'nav-link active' : 'nav-link'"
              href="#children"
              @click="toggleTab('children')"
              >{{ $t("leffit.products.filter.children") }}</a
            >
          </li>
          <!-- <li v-if="settings.website" class="nav-item">
            <a
              :class="tabsController.websites ? 'nav-link active' : 'nav-link'"
              href="#websites"
              @click="toggleTab('websites')"
              >{{ $t("leffit.products.filter.websites") }}</a
            >
          </li> -->
          <li v-if="settings.product" class="nav-item">
            <a
              :class="tabsController.products ? 'nav-link active' : 'nav-link'"
              href="#products"
              @click="toggleTab('products')"
              >{{ $t("leffit.products.filter.products") }}</a
            >
          </li>
          <!-- <li v-if="settings.meta" class="nav-item">
            <a
              :class="tabsController.metas ? 'nav-link active' : 'nav-link'"
              href="#meta"
              @click="toggleTab('metas')"
              >{{ $t("leffit.products.filter.metas") }}</a
            >
          </li> -->
          <li class="nav-item">
            <a
              :class="tabsController.seo ? 'nav-link active' : 'nav-link'"
              href="#meta"
              @click="toggleTab('seo')"
              >{{ $t("leffit.products.filter.seo") }}</a
            >
          </li>
        </ul>
      </div>
      <div v-if="loaded" class="col-12 p-0">
        <div
          class="tab-content"
          id="myTabContent"
          style="width: 99%; margin-top: 10px; background: #d3d3d340"
        >
          <div
            v-if="settings.parent"
            :class="
              tabsController.parents
                ? 'tab-pane fade show active'
                : 'tab-pane fade '
            "
            id="parents"
            role="tabpanel"
          >
            <parents :item="item"></parents>
          </div>
          <div
            v-if="settings.children"
            :class="
              tabsController.children
                ? 'tab-pane fade show active'
                : 'tab-pane fade '
            "
            id="chil"
            role="tabpanel"
          >
            <children :item="item"></children>
          </div>
          <div
            v-if="settings.website"
            :class="
              tabsController.websites
                ? 'tab-pane fade show active'
                : 'tab-pane fade '
            "
            style="background: white"
            id="chil"
            role="tabpanel"
          >
            <builder></builder>
          </div>
          <div
            v-if="settings.product"
            :class="
              tabsController.products
                ? 'tab-pane fade show active'
                : 'tab-pane fade '
            "
            style="background: white"
            id="chil"
            role="tabpanel"
          >
            <products :item="item"></products>
          </div>
          <!-- <div  v-if="settings.meta" :class="tabsController.metas ? 'tab-pane fade show active':'tab-pane fade '" style="background:white" id="chil" role="tabpanel">
						<metas :item="item"></metas>
					</div> -->
          <div
            :class="
              tabsController.seo
                ? 'tab-pane fade show active'
                : 'tab-pane fade '
            "
            style="background: white"
            id="seo"
            role="tabpanel"
          >
            <seo :productfilter="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../../data/language";

import parents from "./layouts/parents";
import children from "./layouts/children";
import builder from "./layouts/builder";
import products from "./layouts/products";
// import metas from './layouts/meta';
import Seo from "./layouts/seo.vue";
export default {
  props: ["id", "edit"],
  name: "productFilter",
  components: {
    parents,
    children,
    builder,
    products,
    Seo,
    //  metas
  },
  data() {
    return {
      tabsController: {
        parents: true,
        children: false,
        websites: false,
        products: false,
        metas: false,
      },
      item: {
        name: "",
        name_en: "",
        pickorder: "",
        language: "",
        description_en: "",
        description: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        parent: { name: "" },
      },
      language: language,
      profileImageSet: false,
      filters: [],
      filters2: [],
      types: [],
      pagedata: [],
      attributes: [
        "name",
        "name_en",
        "description",
        "description_en",
        "language",
        "pickorder",
        "active",
        "created_at",
        "updated_at",
        "deleted_at",
      ],
      search: {},
      item2: {
        active: 0,
        name: "",
        description: "",
        parent: "",
        lang: 0,
        pickorder: 0,
        parent_id: 0,
        name_en: "",
        description_en: "",
      },
      parents: [],
      products: [],
      children: [],
      loaded: false,
      setting_ready: false,
      settings: {},
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
    isDisabled: function () {
      return !(this.edit == "edit");
    },
  },
  methods: {
    toggleTab(field) {
      this.tabsController = {
        parents: false,
      };
      this.tabsController[field] = true;
    },

    updateItem(e) {
      if (e != undefined) {
        e.preventDefault();
      }
      var formData = new FormData();
      formData.append("name", this.item.name);
      formData.append("name_en", this.item.name_en);
      formData.append("lang", this.item.lang);
      formData.append(
        "parent_id",
        this.item.parent_id ? this.item.parent_id : 0
      );
      formData.append(
        "pickorder",
        this.item.pickorder == null ? 0 : this.item.pickorder
      );
      formData.append("description", this.item.description);
      formData.append("description_en", this.item.description_en);
      formData.append("file", document.getElementById("filter_file").files[0]);
      formData.append("parents", JSON.stringify(this.parents));
      formData.append("children", JSON.stringify(this.children));
      formData.append("products", JSON.stringify(this.products));
      console.log(formData);
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/productfilters/" + this.item.id,
          details: formData,
        })
        .then((data) => {
          this.item = data.data.data;
          console.log("result", data.data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM UPDATED",
            text: this.item.name + " was updated successfully",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    set() {},
    deleteItem(id) {
      this.pagedata = this.pagedata.filter((dt) => dt.id != id);
      this.$store
        .dispatch("delete", "/leffit/products/productfilters/" + id)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: this.item.name + " was removed successfully",
            type: "success",
          });
          // this.pagedata = data.data.data;
        });
    },
    create() {
      // this.item2.language=document.getElementById('filter_language2').value;
      // this.item2.parent_id=document.getElementById('filter_parent2').value;
      var formData = new FormData();
      formData.append("name", this.item2.name);
      formData.append("name_en", this.item2.name_en);
      formData.append("lang", this.item2.lang);
      formData.append("parent_id", this.item.id);
      formData.append("pickorder", this.item2.pickorder);
      formData.append("description", this.item2.description);
      formData.append("description_en", this.item2.description_en);
      formData.append("file", document.getElementById("filter_file2").files[0]);

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/productfilters",
          details: formData,
        })
        .then((data) => {
          console.log("result", data.data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM ADDED",
            text: this.item2.name + " was added successfully",
            type: "success",
          });
          this.pagedata.unshift(data.data.data);
          document.getElementById("closemodal").click();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
  },
  mounted() {
    document.getElementById("menuCollapse_3").classList.add("show");

    this.$store
      .dispatch("get", "/leffit/products/productfilters/" + this.id)
      .then((data) => {
        console.log("result", data.data.data);
        this.item = data.data.data;
        this.filters2 = [this.item];
        this.pagedata = this.item.children;
        this.loaded = true;
      });

    this.$store
      .dispatch(
        "get",
        "/lefficient/leffit/packages/settings/product_filter_settings"
      )
      .then((data) => {
        // console.log(data);
        if (data.data != null) {
          if (data.data.setting != null) {
            var settings = JSON.parse(data.data.setting);
            settings.tabs.forEach((element) => {
              this.settings[element.field] = element.show;
            });
            this.setting_ready = true;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="css" scoped>
.img-w {
  height: 130px;
  width: auto !important;
  margin: auto !important;
}
#imgHolder {
  display: block;
  position: relative;
  height: 100px;
  width: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #80808075;
  margin: auto;
}
.imgCard {
  background: #4e5467;
}
.imgCard h4 {
  color: white !important;
  margin-bottom: 20px;
}
.imgCard img {
  width: 100px;
  display: block;
  margin: auto;
  border-radius: 40%;
  background: lightgray;
}
.card {
  box-shadow: 0px 0px 24px lightgray;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.card h4 {
  color: #4e5467;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 30px;
}
.card b {
  color: #4e5467;
  font-size: 12px;
}
.card h6 {
  color: grey;
  font-weight: 300;
  font-size: 15px;
  padding-left: 5px;
  text-transform: capitalize;
  min-height: 20px;
}
.card .bsc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>