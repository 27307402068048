<template>
  <div class="sidebar" data-background-color="black">
    <div class="sidebar-wrapper">
      <div class="user" v-if="user">
        <div class="photo" v-if="user.hasOwnProperty('profile_image')">
          <img :src="user.profile_image" />
        </div>
        <div class="user-info">
          <a data-toggle="collapse" href="#collapseExample" class="username">
            <span>
              {{ user.firstname }}
              {{ user.lastname }}
              <b class="caret"></b>
            </span>
          </a>
          <div class="collapse" id="collapseExample">
            <ul class="nav d-none d-md-flex">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="'/admin/edit-user/' + user.id"
                >
                  <span class="sidebar-mini">
                    <i class="fa fa-pencil"></i>
                  </span>
                  <span class="sidebar-normal">
                    {{
                      $t("lefficient.system.edit_field", [
                        $t("lefficient.leffit.users"),
                      ])
                    }}
                  </span>
                </router-link>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link modal-toggle"
                  data-toggle="modal"
                  href="#updateImage"
                >
                  <span class="sidebar-mini">
                    <i class="fa fa-camera"></i>
                  </span>
                  <span class="sidebar-normal">
                    {{
                      $t("lefficient.system.edit_field", [
                        $t("lefficient.leffit.user.profile_image"),
                      ])
                    }}</span
                  >
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link modal-toggle"
                  data-toggle="modal"
                  href="#updateImage2"
                >
                  <span class="sidebar-mini"> <i class="fa fa-key"></i> </span>
                  <span class="sidebar-normal">
                    {{ $t("lefficient.login.password_change") }}</span
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="logout()" href="#">
                  <span class="sidebar-mini">
                    <i class="fa fa-sign-out"></i>
                  </span>
                  <span class="sidebar-normal">
                    {{ $t("lefficient.login.logout") }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul class="nav">
        <li class="nav-item" v-if="user_has('module', 'crm')">
          <router-link to="/admin/crm" class="nav-link">
            <p>{{ $t("leffit.crm.crm") }}</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'products')">
          <router-link to="/admin/products" class="nav-link">
            <p>{{ $t("leffit.products.products") }}</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'planning')">
          <router-link to="/admin/appointments/appointment" class="nav-link">
            <p>Appointments</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'stock')">
          <router-link to="/admin/stocks" class="nav-link">
            <p>{{ $t("leffit.products.stocks") }}</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'procurement')">
          <router-link to="/admin/procurement" class="nav-link">
            <p>{{ $t("leffit.orders.procurement") }}</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'sales')">
          <router-link to="/admin/sales" class="nav-link">
            <p>{{ $t("leffit.orders.sales") }}</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/admin/reports" class="nav-link">
            <p>{{ $t("leffit.reports.report") }}</p>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-if="user_has('module','shipping')">
                        <router-link to="/admin/shippings" class="nav-link">
                            <p>{{ $t('leffit.shipping.shippings') }}</p>
                        </router-link>
                    </li> -->

        <li
          class="nav-item"
          v-if="user_has('module', 'crm') || user_has('module', 'orders')"
        >
          <router-link to="/admin/email" class="nav-link">
            <p>Email</p>
          </router-link>
        </li>

        <li class="nav-item" v-if="user_has('module', 'pricat')">
          <router-link to="/admin/casa-jewelry" class="nav-link">
            <p>{{ $t("leffit.casa.casa_menu") }}</p>
          </router-link>
        </li>

        <li class="nav-item" v-if="user_has('module', 'newsletter')">
          <router-link to="/admin/newsletters" class="nav-link">
            <p>{{ $t("leffit.newletters.menu") }}</p>
          </router-link>
        </li>
        <!-- <li class="nav-item"  v-if="user_has('module','courses')">
                        <router-link to="/admin/courses" class="nav-link" >
                            <p>Courses</p>
                        </router-link>
                    </li> -->
        <li class="nav-item">
          <router-link
            to="/admin/website"
            class="nav-link"
            v-if="user_has('module', 'cms')"
          >
            <p>{{ $t("leffit.website.website") }}</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/admin/media-manager"
            class="nav-link"
            v-if="user_has('module', 'mediamanager')"
          >
            <p>{{ $t("lefficient.mediamanager") }}</p>
          </router-link>
        </li>
        <li class="nav-item" v-if="user_has('module', 'settings')">
          <router-link to="/admin/settings" class="nav-link">
            <p>{{ $t("lefficient.system.settings") }}</p>
          </router-link>
        </li>
      </ul>
    </div>

    <div id="updateImage" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4>
              {{
                $t("lefficient.system.edit_field", [
                  $t("lefficient.leffit.user.profile_image"),
                ])
              }}
            </h4>
          </div>
          <div class="modal-body">
            <div
              v-bind:style="{
                backgroundImage: 'url(' + user.profile_image + ')',
              }"
              class="imageContainer"
            >
              <input
                @change="readFile()"
                type="file"
                name=""
                id="fileImage"
                class="d-none"
              />
            </div>
            <div
              class="btnContainer"
              style="display: flex; justify-content: space-between"
            >
              <button @click="trigger()" class="btn btn-info">
                {{
                  $t("lefficient.system.edit_field", [
                    $t("lefficient.leffit.user.profile_image"),
                  ])
                }}
              </button>
              <button id="dismiss" data-dismiss="modal" class="btn btn-danger">
                {{ $t("lefficient.system.cancel") }}
              </button>
              <button
                v-if="file != null"
                @click="saveImage()"
                id="dismiss"
                data-dismiss="modal"
                class="btn btn-success"
              >
                {{ $t("lefficient.system.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="updateImage2" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4>{{ $t("lefficient.login.password_change") }}</h4>
          </div>
          <div class="modal-body">
            <div class="formContainer">
              <div class="form-group">
                <input
                  v-model="change_password.password"
                  id="password"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                />
              </div>
              <div class="form-group">
                <input
                  v-model="change_password.password2"
                  id="password2"
                  type="password"
                  class="form-control"
                  placeholder="Password Confirmation"
                />
              </div>
            </div>
            <div
              class="btnContainer"
              style="display: flex; justify-content: space-between"
            >
              <button @click="changePassword()" class="btn btn-info">
                {{ $t("lefficient.login.password_change") }}
              </button>
              <button id="dismiss2" data-dismiss="modal" class="btn btn-danger">
                {{ $t("lefficient.system.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "sidebar",
  data() {
    return {
      modules: process.env.VUE_APP_MODULES.split(","),
      change_password: {
        password: "",
        password2: "",
      },
      backup: null,
      file: null,
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      authenticated: "auth/authenticated",
      // user: 'auth/user',
    }),
  },
  mounted() {
    console.log(this.user);
  },
  created() {
    // console.log(this.$route);
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/signOut",
    }),

    async logout() {
      await this.logoutAction();

      this.$router.replace({ name: "login" });
    },

    isActive(path) {
      return this.$route.matched.find((d) => d.path == path) ? true : false;
    },
    trigger() {
      document.getElementById("fileImage").click();
    },
    readFile() {
      var file = document.getElementById("fileImage").files[0];

      let formats = ["image/png", "image/jpg", "image/jpeg", "image/PNG"];
      if (!formats.find((ft) => ft == file.type)) {
        alert("file format not supported");
        return false;
      }

      var fileReader = new FileReader();
      fileReader.onload = (result) => {
        this.backup = this.user.profile_image;
        this.user.profile_image = result.srcElement.result;
        this.file = file;
      };
      fileReader.readAsDataURL(file);
    },
    saveImage() {
      //send request to change dp
      //   let user=JSON.parse(localStorage.getItem('user'));
      let formdata = new FormData();
      formdata.append("file", this.file);

      this.$store
        .dispatch("post", {
          endpoint: "/lefficient/leffit/update-user-image/" + this.user.id,
          details: formdata,
          loader: { text: "Updating profile", show: true },
          notification: {
            title: "Profile Update",
            text: "Your profile was updated.",
            type: "success",
          },
        })
        .then((data) => {
          this.$store.commit("setUser", data.data);
          document.getElementById("dismiss").click();
          this.file = null;
        })
        .catch((e) => {
          this.user.profile_image = this.backup;
          this.file = null;
          this.$store.dispatch("makeNotification", {
            title: "File error",
            text: "Error occured while uploading file, Please make sure the file doesn't exceed upload size limit",
            type: "danger",
          });
        });
    },
    changePassword() {
      let password = this.change_password.password;
      let password2 = this.change_password.password2;

      if (password.length == 0 || password2.length == 0) {
        this.$store.dispatch("makeNotification", {
          title: "Password error",
          text: "Please enter your password and confirm it",
          type: "danger",
        });
        return false;
      }
      if (password != password2) {
        this.$store.dispatch("makeNotification", {
          title: "Password error",
          text: "Password doesn't match",
          type: "danger",
        });
        return false;
      }

      this.$store
        .dispatch("post", {
          endpoint: "/lefficient/leffit/change-password/" + this.user.id,
          details: { password, password2 },
          loader: { text: "Updating profile", show: true },
          notification: {
            title: "Profile Update",
            text: "Your profile was updated.",
            type: "success",
          },
        })
        .then((data) => {
          this.$store.commit("setUser", data.data);
          document.getElementById("dismiss2").click();
          // console.log(data);
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
  },
};
</script>
<style>
.modal-backdrop {
  display: none;
}
</style>
<style scoped>
.router-link-active {
  /* color:red !important; */
  background: #fb9c7e !important;
}
.imageContainer {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 50%;
}
#updateImage {
  background: #00000059;
}
.sidebar {
  background: white !important;
  width: 210px;
}
.sidebar-mini .sidebar {
  width: 80px;
}
.sidebar .sidebar-wrapper {
  width: 100%;
  overflow: hidden !important;
}
.sidebar .user .photo {
  margin: auto;
  box-shadow: none !important;
  background: var(--color-1-faded) !important;
}
.sidebar .user .user-info > a > span {
  display: flex;
  position: relative;
  opacity: 1;
  justify-content: center;
  color: var(--color-1) !important;
  font-weight: 500;
}
.sidebar .user .photo {
  width: 50px;
  height: 50px;
  float: unset;
}
.sidebar .user .user-info .caret {
  position: relative;
  top: 8px;
  left: 10px;
}
.sidebar .nav li.active > a {
  background: white;
  color: var(--color-2) !important;
}
.sidebar .nav li.active > a > i {
  color: var(--color-2) !important;
}
.sidebar .nav li > a {
  color: var(--color-1) !important;
  font-weight: 500;
  display: flex;
  margin: 0px 15px 0;
  padding: 0px 1rem;
}
.sidebar .nav li > a > i {
  color: var(--color-1) !important;
  float: unset !important;
  font-size: 16px;
  margin-right: 2px !important;
  transition: all 0.2s linear;
}
.sidebar .nav i {
  float: unset !important;
}
.sidebar-mini .sidebar .nav li > a > i {
  font-size: 20px;
  padding: 10px 0px;
}
.sidebar .nav p {
  font-size: 12px;
}

@media (max-width: 600px) {
  .sidebar-mini .sidebar {
    width: 250px !important;
  }
  .sidebar .nav li a {
    margin: 0px 15px 0;
  }
  .sidebar .nav i {
    float: unset;
  }
}
</style>
