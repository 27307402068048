export default `
<section class="six bg-white" >
<div class="">
    <h2 id="section11header" class="h2 pt-3">++{{section11header}}</h2>
    <div class="row mt-3">
        <div class="col-sm-4 col-lg-4" >
            <h3 id="section11header1">++{{section11header1}}</h3>
            <h6 id="section11Text1" class="p">++{{section11Text1}}</h6>
        </div>
        <div class="col-sm-4 col-lg-4">
            <h3 id="section11header2">++{{section11header2}}</h3>
            <h6 id="section11Text2" class="p">++{{section11Text2}} </h6>
        </div>
        <div class="col-sm-4 col-lg-4">
            <h3 id="section11header3">++{{section11header3}}</h3>
            <h6 id="section11Text3" class="p">++{{section11Text3}}</h6>
        </div>
    </div>
</div>
</section>
`