<template>
	<div>
		<div class="head row p-0 m-0">
        	<h4 v-show="isDisabled" ><router-link :to="this.$route.fullPath+'/edit'" class="btn btn-secondary"><i class="fa fa-edit"></i> EDIT</router-link></h4>
      	</div>

		<div v-show="isDisabled" class="row">
			<div class="col-sm-12 col-md-6">
				<div class="imgCard card">
					<h4>PROFILE IMAGE</h4>
					<div v-bind:style="{ backgroundImage: 'url(' + item.profile_image + ')' }"></div>
				</div>
				<div class="card basicDetailsCard">
					<h4>BASIC DETAILS</h4>
					<div id="">
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.display_name') }}:</b>
							<h6>{{item.display_name}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.intials') }}:</b>
							<h6>{{item.intials}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.firstname') }}:</b>
							<h6>{{item.firstname}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.lastname') }}:</b>
							<h6>{{item.lastname}}</h6>
						</div>  
					</div>
				</div>
				<div class="card basicDetailsCard">
					<h4>Other Details</h4>
					<div id="">
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.city') }}:</b>
							<h6>{{item.city}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.country') }}:</b>
							<h6>{{item.country}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.birthday') }}:</b>
							<h6>{{item.birthday}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.gender') }}:</b>
							<h6>{{item.gender}}</h6>
						</div>
						<div class="bsc bsc2">
							<b>{{ $t('lefficient.leffit.users.user.language') }}:</b>
							<h6>{{item.language}}</h6>
						</div> 
					</div>
				</div> 
			</div>
			<div class="col-sm-12 col-md-6">
				<div class="card basicDetailsCard">
					<h4>OTHER DETAILS</h4>
					<div id="">
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.phone') }}</b>
							<h6>{{item.phone}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.mobile') }}</b>
							<h6>{{item.mobile}}</h6>
						</div> 
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.email') }}</b>
							<h6>{{item.email}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.address') }}</b>
							<h6>{{item.address}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.address2') }}</b>
							<h6>{{item.address2}}</h6>
						</div>
						<div class="bsc">
							<b>{{ $t('lefficient.leffit.users.user.website') }}</b>
							<h6>{{item.website}}</h6>
						</div>  
						
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card basicDetailsCard">
					<h4>About Contact</h4>
					<div id="">
						<div class="bsc"> 
							<h6 style="text-transform:none">{{item.about_me}}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form v-show="!isDisabled" @submit="updateItem($event)"> 
			<div >
				<div style="background:white;" class="imgCard card">
					<h4 style="color:#4e5467 !important">UPLOAD IMAGE</h4>
					<div id="imgHolder" v-bind:style="{ backgroundImage: 'url(' + item.picture + ')' }"></div>

					<div class="d-flex text-center pt-2">
						<input type="file" name="" id="file" class="d-none" @change="readFile()">
						<button v-if="!this.profileImageSet" type="button" @click="trigger()" style="margin:auto;background:#fb9c7e;color:white;font-size: 12px;font-weight: 500;" class="btn ">Change Picture</button>
						<button v-if="this.profileImageSet" type="button" @click="cancelImage()" style="margin:auto;background:red;color:white;font-size: 12px;font-weight: 500;" class="btn ">Cancel Picture</button>
					</div>
				</div> 
				<div class="form-row">
					<div class="col-12">
						<label for="">Attach Roles</label> 
						<select2 v-model="role" @change="attachRoles()" :options="pagedata.map((d)=>{ return {id:d.id,text:d.name} })" class="form-control"></select2>
					</div>
					<div class="col-12 pt-2 " style="text-align:left">
						<div v-for="(v,index) in attached" :key="index" class="itemPermission mt-2">
							<b>{{v.name}}</b>
							<a @click="detach(v)" href="#"><i class="fa fa-remove"></i></a>
						</div>
					</div>

					<div class="col-12">
						<label for="">Attach Permission</label> 
						<select2 v-model="permission" @change="attachPermissions()" :options="permissions.map((d)=>{ return {id:d.id,text:d.name} })" class="form-control"></select2>
					</div>
					<div class="col-12 pt-2 " style="text-align:left">
						<div v-for="(v,index) in attached2" :key="index" class="itemPermission mt-2">
							<b>{{v.name}}</b>
							<a @click="detachPermission(v)" href="#"><i class="fa fa-remove"></i></a>
						</div>
					</div>


					<div class="form-group col-6">
						<label for="user_title">{{ $t('lefficient.leffit.users.user.display_name') }}</label>
						<input type="text" class="form-control" id="user_title" v-model="item.display_name">
					</div>
					<div class="form-group col-6">
						<label for="user_initials">{{ $t('lefficient.leffit.users.user.initials') }}</label>
						<input type="text" class="form-control" id="user_initials" v-model="item.initials">
					</div>
				
					<div class="form-group col-6">
						<label for="user_firstname">{{ $t('lefficient.leffit.users.user.firstname') }}</label>
						<input type="text" class="form-control" id="user_firstname" v-model="item.firstname">
					</div>
					<div class="form-group col-6">
						<label for="user_lastname">{{ $t('lefficient.leffit.users.user.lastname') }}</label>
						<input type="text" class="form-control" id="user_lastname" v-model="item.lastname">
					</div> 
					<div class="form-group col-6">
						<label for="user_address">{{ $t('lefficient.leffit.users.user.address') }}</label>
						<input type="text" class="form-control" id="user_address" v-model="item.address">
					</div> 
					<div class="form-group col-6">
						<label for="user_address2">{{ $t('lefficient.leffit.users.user.address2') }}</label>
						<input type="text" class="form-control" id="user_address2" v-model="item.address2">
					</div>
              </div>

              <div class="form-row">
                <div class="form-group col-6">
                  <label for="user_zipcode">{{ $t('lefficient.leffit.users.user.zipcode') }}</label>
                  <input type="text" class="form-control" id="user_zipcode" v-model="item.zipcode">
                </div>
              
                <div class="form-group col-6">
                  <label for="user_city">{{ $t('lefficient.leffit.users.user.city') }}</label>
                  <input type="text" class="form-control" id="user_city" v-model="item.city">
                </div>
              
                <div class="form-group col-6">
                  <label for="user_country">{{ $t('lefficient.leffit.users.user.country') }}</label>
                  <select2 :options="country.map((l)=>{ return {id:l.code,text:l.name}; })" v-model="item.country"  style="position:relative" class="form-control country" id="contact_country"></select2>
                </div>  
                <div class="form-group col-6">
                  <label for="user_phone">{{ $t('lefficient.leffit.users.user.phone') }}</label>
                  <input type="text" class="form-control" id="user_phone" v-model="item.phone">
                </div>
              
                <div class="form-group col-6">
                  <label for="user_mobile">{{ $t('lefficient.leffit.users.user.mobile') }}</label>
                  <input type="text" class="form-control" id="user_mobile" v-model="item.mobile">
                </div> 
                <div class="form-group col-6">
                  <label for="user_website">{{ $t('lefficient.leffit.users.user.website') }}</label>
                  <input type="text" class="form-control" id="user_website" v-model="item.website">
                </div>
              
                <div class="form-group col-6">
                  <label for="user_email">{{ $t('lefficient.leffit.users.user.email') }}</label>
                  <input type="email" class="form-control" id="user_email" v-model="item.email">
                </div> 
              
                <div class="form-group col-6">
                  <label for="user_birthday">{{ $t('lefficient.leffit.users.user.birthday') }}</label>
                  <input type="date" class="form-control" id="user_birthday" v-model="item.date_of_birth">
                </div>
              
                <div class="form-group col-6">
                  <label for="user_gender">{{ $t('lefficient.leffit.users.user.gender') }}</label>
                  <select v-model="item.gender" style="position:relative" class="form-control" id="user_gender">
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                  </select>
                </div>  
              </div> 
              <div class="form-row">
                <div class="form-group col-12">
                  <label for="user_about_me">{{ $t('lefficient.leffit.users.user.about_me') }}</label>
                  <editor
					v-model="item.about_me"
					:init="{
						height: 300,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar:
							'undo redo | formatselect | bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
					}"
				/>
                </div>
              </div>  
				</div> 
				
				<div class="row p-0 m-0">
					<button class="btn btn-success">UPDATE</button>
				</div>
		</form>
	</div>
	
	
</template>

<script>
import { mapState } from 'vuex';
import language from '../../../data/language';
import country from '../../../data/country';
export default {
	props:['id', 'edit'], 
  	name: 'item', 
  	data () {
	    return {
	    	item:{
				'profile_image':'',
				'initials' : 'initials',
				'display_name' : 'highdee',
				'firstname' : 'Idowu',
				'lastname' : 'Aladesiun',
				'address' : 'address1',
				'address2' : 'address2',
				'zipcode' : 'e4434e',
				'city' : 'lagos',
				'country' : 'Nigeria',
				'phone' : '4334343',
				'mobile' : '09093943',
				'website' : 'highdee.com', 
				'date_of_birth' : '', 
				'about_me' : '', 
				'email' : 'highdee.ai@gmail.com', 
				'password' : '123',
				'gender' : '1',
			},
			language:language,
			country:country,
			profileImageSet:false,
			pagedata : [],
			attached:[],
			permissions:[],
			attached2:[],
			role:0,
			permission:0
	    }
  	},
  	computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
  		isDisabled : function(){
  			return this.$route.meta.edit == null;
  		}
	},
	created(){
		
	},  
	methods:{ 
		attachRoles(){
            var value=this.role;
            var item=this.pagedata.find((it)=> it.id == value);
            if(item != null){
                this.attached.push(item);
            }
            this.role=0;
            this.$store.dispatch('get','/lefficient/leffit/attach-user-role/'+value+'/'+this.item.id);
		},
		attachPermissions(){
            var value=this.permission;
            var item=this.permissions.find((it)=> it.id == value);
            if(item != null){
                this.attached2.push(item);
            }
            this.permission=0;
            this.$store.dispatch('get','/lefficient/leffit/attach-user-permission/'+value+'/'+this.item.id);
        },
        detach(v){
			this.$store.dispatch('get','/lefficient/leffit/detach-user-role/'+v.id+'/'+this.item.id);
            this.attached=this.attached.filter((d)=> d.id != v.id);
		},
		detachPermission(v){
			this.$store.dispatch('get','/lefficient/leffit/detach-user-permission/'+v.id+'/'+this.item.id);
            this.attached2=this.attached2.filter((d)=> d.id != v.id);
        },
		updateItem(e){
			e.preventDefault();   

			this.item.country=document.getElementById('user_country').value;


			this.$store.dispatch('put',{endpoint:'/lefficient/leffit/users/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data.data); 
				this.$store.dispatch('makeNotification',{title:"ITEM UPDATED",text:this.item.lastname+' was updated successfully',type:'success'});
			}).catch((error)=>{
				console.log(error.request);
			});
		},
		trigger(){
			document.getElementById('file').click();
		},
		cancelImage(){
			document.getElementById('imgHolder').style.backgroundImage="url('"+this.item.picture+"')"; 
			this.profileImageSet=false;
		},
		readFile(){
          var file=document.getElementById('file').files[0];
          
          let formats=['image/png','image/jpg','image/jpeg','image/PNG'];

          if(!formats.find((ft=> ft == file.type))){ alert('file format not supported'); return false; }

          var fileReader=new FileReader();
          fileReader.onload=(result)=>{  
			document.getElementById('imgHolder').style.backgroundImage="url('"+result.srcElement.result+"')"; 
			this.profileImageSet=true;
          };
          fileReader.readAsDataURL(file); 
        } ,
	},
  	mounted(){
	    this.$store.dispatch('get','/lefficient/leffit/users/'+this.$route.params.id)
        .then(data=>{
        	console.log('result',data.data);
			this.item = data.data.data; 
			this.attached=this.item.roles;
			this.attached2=this.item.permissions;
  
		}); 
		this.$store.dispatch('get','/lefficient/leffit/Roles')
        .then(data=>{
			this.pagedata = data.data;
		});
		this.$store.dispatch('get','/lefficient/leffit/Permissions')
        .then(data=>{
			console.log('permission',data);
			this.permissions = data.data;
		});
	}
}
</script>

<style lang="css" scoped>
.itemPermission{
        background-color: #d3d3d370;
        padding: 5px 8px;
        display: inline-block;
        width: auto;
        border-radius: 20px;
        margin-right:10px;
    }
    .itemPermission b{
        padding: 0px 10px;
        font-weight: 300;
        text-transform: capitalize;
    }
    .itemPermission a{
        color: red;
    }
    .itemPermission a i{
        font-weight: lighter;
        font-size: 14px;
    }
	#imgHolder{
		display: block;
		position: relative;
		height: 100px;
		width: 120px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #80808075;
		margin: auto;
	}
	.imgCard{
		background: #4e5467;
	}
	.imgCard h4{
		color: white !important;
		margin-bottom: 20px;
	}
	.imgCard img{
		width: 100px;
		display: block;
		margin: auto;
		border-radius: 40%;
		background: lightgray;
	}
	.card{
		box-shadow: 0px 0px 24px lightgray;
		padding: 15px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
	}
	.card h4{
		color: #4e5467;
		font-weight: 400;
		font-size: 19px;
		margin-bottom: 30px;
	}
	.card b{
		color: #4e5467;
		font-size: 12px;
	}
	.card h6{
		color: grey;
		font-weight: 300;
		font-size: 15px;
		padding-left: 5px;
		text-transform: capitalize;
		min-height: 20px;
	}
	.card .bsc2{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
</style>