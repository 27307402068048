<template>
  <label class="w-100">
    <div>{{ label }}</div>
    <input 
    v-if="type == 10"
        type="file" 
        accept="image/*" 
        @change="onChange($event)"
    />

    <input 
        v-if="type == 1"
        type="text" 
        v-model="model"
        :class="cClass" 
    />

    <Select2 
        v-if="type == 4"
        v-model="model" 
        :options="options"
    />

    <editor
        v-if="type == 3"
        v-model="model"
        :init="{
            height: 300,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
        }"
    />
    <div v-if="url">
      <img :src="url" class="rounded float-left img-thumbnail previ" />
      <button v-if="file != null" @click="copyToLanguage" class="ml-1 btn btn-sm btn-primary">Copy to {{ lang == 'nl' ? 'English' : 'Deutch'}}</button>
    </div>
  </label>
  
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    type: {
      required: true,
    },
    options: {
      type: Array,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    lang: {
      type: String,
    },
    field: {
      type: Number,
      required: true,
    },
    section: {
      type: Number,
      required: true,
    },
    columnId: {
      type: Number,
      required: true,
    },
    cClass: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    url: null,
    emitValueEvent: false,
    file: null
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if(this.emitValueEvent) {
          this.$emit('input', value, this.section, this.field, this.lang, this.columnId);
        }
      },
    },
  },
  mounted() {
    this.emitValueEvent = true;

    if(this.type == 10) {
      this.url = this.value
    }
  },
  watch: {
    value(val) {
      if(this.type == 10) {
        this.url = val
      }
    }
  },
  methods: {
    onChange(e) {
        this.file = e.target.files[0];
        this.$emit('input', this.file, this.section, this.field, this.lang, this.columnId, null);
        this.url = URL.createObjectURL(this.file);
    },
    copyToLanguage() {
      let language = this.lang == 'nl' ? 'en' : 'nl';
      this.$emit('input', this.file, this.section, this.field, language, this.columnId, this.url);
    }
  }
}
</script>

<style scoped>
.previ {
  height: 100%;
  width: 60px !important;
}
</style>
