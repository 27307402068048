<template>
  <div>
    <div class="head row">
      <h3 class="pl-3">{{ $t("leffit.products.products") }}</h3>
    </div>
    <b-modal id="modalShowexpproductpf" hide-footer>
      <div class="mx-auto text-center">
        <b-alert show v-if="this.textmsg.error" variant="danger">{{
          this.textmsg.error
        }}</b-alert>
        <b-form-input
          v-model="tablefield"
          placeholder="Enter column name"
        ></b-form-input>
        <b-button
          type="submit"
          @click="exportproduct('productperfilter')"
          variant="primary"
          >Download</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowexpproductpf')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShowexpproductfilter" hide-footer>
      <div class="mx-auto text-center">
        <b-button
          type="submit"
          @click="exportproduct('productfilter')"
          variant="primary"
          class="center"
          >Download</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowexpproductfilter')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShowexpproduct" hide-footer>
      <div class="mx-auto text-center">
        <b-button
          type="submit"
          @click="exportproduct('product')"
          variant="primary"
          >Download</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowexpproduct')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShow" hide-footer>
      <div class="mx-auto text-center">
        <b-alert show v-if="this.textmsg.success" variant="success">{{
          this.textmsg.success
        }}</b-alert>
        <b-alert show v-if="this.textmsg.error" variant="danger">{{
          this.textmsg.error
        }}</b-alert>

        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        >
        </b-form-file>
        <b-button
          type="submit"
          @click="importProduct('product')"
          variant="primary"
          >Import</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShow')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShowImageImport" hide-footer>
      <div class="mx-auto text-center">
        <b-alert show v-if="this.textmsg.success" variant="success">{{
          this.textmsg.success
        }}</b-alert>
        <b-alert show v-if="this.textmsg.error" variant="danger">{{
          this.textmsg.error
        }}</b-alert>
        <div class="mb-3 text-left">
          <b>Upload Format = filename_modelID_language</b>
          <p>Example : image_32_nl.jpg</p>
        </div>
        <b-form-file
          v-model="files"
          :state="Boolean(files)"
          multiple
          placeholder="Choose multiple files or drop it here..."
          drop-placeholder="Drop file here..."
        >
        </b-form-file>
        <b-button
          type="submit"
          @click="importFiles('product')"
          variant="primary"
          >Import</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowImageImport')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShowimportFilterp" hide-footer>
      <div class="mx-auto text-center">
        <b-alert show v-if="this.textmsg.success" variant="success">{{
          this.textmsg.success
        }}</b-alert>
        <b-alert show v-if="this.textmsg.error" variant="danger">{{
          this.textmsg.error
        }}</b-alert>

        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        >
        </b-form-file>
        <b-button
          type="submit"
          @click="importProduct('productfilter')"
          variant="primary"
          >Import</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowimportFilterp')"
        >Cancel</b-button
      >
    </b-modal>

    <b-modal id="modalShowimportFilterpp" hide-footer>
      <div class="mx-auto text-center">
        <b-alert show v-if="this.textmsg.success" variant="success">{{
          this.textmsg.success
        }}</b-alert>
        <b-alert show v-if="this.textmsg.error" variant="danger">{{
          this.textmsg.error
        }}</b-alert>

        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        >
        </b-form-file>
        <b-button
          type="submit"
          @click="importProduct('productperfilter')"
          variant="primary"
          >Import</b-button
        >
      </div>
      <b-button
        style="margin-left:auto;width:100px"
        class="mt-3"
        block
        @click="$bvModal.hide('modalShowimportFilterpp')"
        >Cancel</b-button
      >
    </b-modal>

    <div class="row">
      <a
        style="padding: 10px 25px;height: 38px;margin-top: 9px;"
        href="/admin/products/create"
        class="btn btn-success btn-sm"
        >{{ $t("leffit.products.product.product_add") }}</a
      >
      <a
        href="/admin/products/ean_upload"
        style="height: 37px;margin-top: 10px;"
        class="btn btn-success btn-sm"
        >{{ $t("leffit.products.product.upload_image") }}</a
      >
      <!-- <b-button
          type="submit"
          @click="exportproduct('product')"
          class="btn btn-success btn-sm"
          >Export</b-button
        > -->
      <!-- <a @click="pricatExport()" href="#" style="height: 37px;margin-top: 10px;" class="btn btn-success btn-sm">{{pricatExportLabel}}</a> -->

      <!-- <a href="/admin/products/import" class="btn btn-success btn-sm">Emport product</a> -->
      <b-button
          type="submit"
          @click="exportproduct('products')"
          class="btn btn-success btn-sm mt-2" 
          >Download</b-button
        >
      <!-- <b-dropdown text="Export product">
				<b-dropdown-item  @click="$bvModal.show('modalShowexpproduct')">Products </b-dropdown-item>
				<b-dropdown-item @click="$bvModal.show('modalShowexpproductfilter')">Product filters</b-dropdown-item>
				<b-dropdown-item @click="$bvModal.show('modalShowexpproductpf')">Product per filter</b-dropdown-item>
			</b-dropdown> -->
			<!-- <b-dropdown text="Import product">
				<b-dropdown-item @click="$bvModal.show('modalShow')" >Products </b-dropdown-item>
				<b-dropdown-item @click="$bvModal.show('modalShowimportFilterp')">Product filters</b-dropdown-item>
				<b-dropdown-item @click="$bvModal.show('modalShowimportFilterpp')">Product per filter</b-dropdown-item>
			</b-dropdown>
			<b-dropdown text="Import Bulk Images">
				<b-dropdown-item @click="$bvModal.show('modalShowImageImport')" >Products </b-dropdown-item>
			</b-dropdown> -->
    </div>
    <div v-if="import_error.length > 0" class="col-12">
      <div class="alert alert-danger">
        <p v-html="import_error.substr(0, 500) + '...'"></p>
      </div>
    </div>
    <products-table @srcData="srch()" v-on:onSearchTable="getSearchParams"></products-table>
  </div>
</template>

<script>
import table from "./table";
import axios from "axios";

export default {
  name: "productFilters",
  components: { "products-table": table },
  data() {
    return {
      pagedata: [],
      exportProducts: [],
      pricatExportLabel: "Pricat Export",
      search: {},
      modalShow: false,
      modalShowImageImport: false,
      file: null,
      files: null,
      modalShowexpproduct: false,
      modalShowexpproductfilter: false,
      modalShowexpproductpf: false,
      modalShowimportFilterp: false,
      modalShowimportFilterpp: false,
      tablefield: null,
      textmsg: {
        success: null,
        error: null,
      },
      import_error: "",
      //baseurl : process.env.VUE_APP_LOGO_URL,
      child: {}
    };
  },
  methods: {
    getSearchParams(value){
      this.search = value
    },
    pricatExport() {
      this.pricatExportLabel = "Wait...";
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/get-product-export-data",
          details: { limit: 10 },
        })
        .then((data) => {
          // console.log(data);
          this.pricatExportLabel = "Pricat Export";
          this.exportProducts = data.data.data;
          this.csvExport(this.exportProducts);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    csvExport(arrData) {
      console.log(arrData);

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    srch(event){
      this.child = val;
    },
    exportproduct(tablename) {
      console.log(tablename)
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/export?model=" +
            tablename +
            "&colname=" +
            this.tablefield,
            this.search,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          // console.log(result)
          const type = result.headers["content-type"];
          const blob = new File([result.data], {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "file.xlsx";
          link.click();
          var token = JSON.parse(localStorage.getItem("token"));
          // console.log(
          //   process.env.VUE_APP_API_BASE_URL +
          //     "/lefficient/leffit/export/?model=" +
          //     tablename +
          //     "&colname=" +
          //     this.tablefield +
          //     "&token=" +
          //     token
          // );
        })
        .catch((error) => {
          console.log(error.request);
          if (error.request.status == 500) {
            this.$store.dispatch("makeNotification", {
              title: "Server error",
              text: "Unable to export server error occured!",
              type: "danger",
            });
          }
        });
    },

    importProduct(filterName) {
      //console.log(filterName);
      let formData = new FormData();
      formData.append("file", this.file);
      let header = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/lefficient/leffit/import/?model=" +
            filterName,
          formData,
          header
        )
        .then((result) => {
          console.log(result);
          this.textmsg.success = result.data.status;
          let self = this;
          setTimeout(function() {
            self.textmsg.success = null;
          }, 5000);
        })
        .catch((error) => {
          if (error.request.status == 422) {
            var response = JSON.parse(error.request.response);
            var errors = response.errors;
            var row = "";
            var error_text = "";
            for (var err in errors) {
              var title = err.split(".");
              if (title.length > 1) {
                row = "Row " + title[0];
              }
              error_text = errors[err][0].replace(title[0] + ".", "");
              this.import_error += row + " " + error_text + "<br />";
            }
            // console.log(row,error_text);
            this.$store.dispatch("makeNotification", {
              title: "Error",
              text: "Your importation was not successfull",
              type: "danger",
            });
          } else {
            this.textmsg.error = "Error :(";
            let self = this;
            setTimeout(function() {
              self.textmsg.error = null;
            }, 5000);
          }
        });
    },

    importFiles(model) {
      console.log(this.files);
      if (this.files == null) {
        this.textmsg.error = "Select some images.";
        return false;
      }
      var form = new FormData();
      this.files.forEach((file, index) => {
        form.append("file" + index, file);
      });
      let header = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/leffit/products/import-files?model=" +
            model,
          form,
          header
        )
        .then((result) => {
          console.log(result);
          this.textmsg.success = result.data.message;
          let self = this;
          setTimeout(function() {
            self.textmsg.success = null;
          }, 5000);
        })
        .catch((error) => {
          this.textmsg.error = "Error :(";
          let self = this;
          setTimeout(function() {
            self.textmsg.error = null;
          }, 5000);
        });
    },
  },
  mounted() {
    console.log(this.$t("leffit.products.product.product"));
  },
};
</script>

<style lang="css" scoped></style>
