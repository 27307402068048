export default [
        {code:'AF',name:'Afghanistan'},
        {code:'AX',name:'&Aring;land'},
        {code:'AL',name:'Albani&euml;'},
        {code:'DZ',name:'Algerije'},
        {code:'VI',name:'Amerikaanse Maagdeneilanden'},
        {code:'AS',name:'Amerikaans-Samoa'},
        {code:'AD',name:'Andorra'},
        {code:'AO',name:'Angola'},
        {code:'AI',name:'Anguilla'},
        {code:'AQ',name:'Antarctica'},
        {code:'AG',name:'Antigua en Barbuda'},
        {code:'AR',name:'Argentini&euml;'},
        {code:'AM',name:'Armeni&euml;'},
        {code:'AW',name:'Aruba'},
        {code:'AU',name:'Australi&euml;'},
        {code:'AZ',name:'Azerbeidzjan'},
        {code:'BS',name:'Bahama'},
        {code:'BH',name:'Bahrein'},
        {code:'BD',name:'Bangladesh'},
        {code:'BB',name:'Barbados'},
        {code:'BE',name:'Belgi&euml;'},
        {code:'BZ',name:'Belize'},
        {code:'BJ',name:'Benin'},
        {code:'BM',name:'Bermuda'},
        {code:'BT',name:'Bhutan'},
        {code:'BO',name:'Bolivia'},
        {code:'BQ',name:'Bonaire, Sint Eustatius en Saba'},
        {code:'BA',name:'Bosni&euml; en Herzegovina'},
        {code:'BW',name:'Botswana'},
        {code:'BV',name:'Bouveteiland'},
        {code:'BR',name:'Brazili&euml;'},
        {code:'VG',name:'Britse Maagdeneilanden'},
        {code:'IO',name:'Brits Indische Oceaanterritorium'},
        {code:'BN',name:'Brunei'},
        {code:'BG',name:'Bulgarije'},
        {code:'BF',name:'Burkina Faso'},
        {code:'BI',name:'Burundi'},
        {code:'KH',name:'Cambodja'},
        {code:'CA',name:'Canada'},
        {code:'CF',name:'Centraal-Afrikaanse Republiek'},
        {code:'CL',name:'Chili'},
        {code:'CN',name:'China'},
        {code:'CX',name:'Christmaseiland'},
        {code:'CC',name:'Cocoseilanden'},
        {code:'CO',name:'Colombia'},
        {code:'KM',name:'Comoren'},
        {code:'CG',name:'Congo-Brazzaville'},
        {code:'CD',name:'Congo-Kinshasa'},
        {code:'CK',name:'Cookeilanden'},
        {code:'CR',name:'Costa Rica'},
        {code:'CU',name:'Cuba'},
        {code:'CW',name:'Cura&ccedil;ao'},
        {code:'CY',name:'Cyprus'},
        {code:'DK',name:'Denemarken'},
        {code:'DJ',name:'Djibouti'},
        {code:'DM',name:'Dominica'},
        {code:'DO',name:'Dominicaanse Republiek'},
        {code:'DE',name:'Duitsland'},
        {code:'EC',name:'Ecuador'},
        {code:'EG',name:'Egypte'},
        {code:'SV',name:'El Salvador'},
        {code:'GQ',name:'Equatoriaal-Guinea'},
        {code:'ER',name:'Eritrea'},
        {code:'EE',name:'Estland'},
        {code:'ET',name:'Ethiopi&euml;'},
        {code:'FO',name:'Faer&ouml;er'},
        {code:'FK',name:'Falklandeilanden'},
        {code:'FJ',name:'Fiji'},
        {code:'PH',name:'Filipijnen'},
        {code:'FI',name:'Finland'},
        {code:'FR',name:'Frankrijk'},
        {code:'TF',name:'Franse Zuidelijke en Antarctische Gebieden'},
        {code:'GF',name:'Frans-Guyana'},
        {code:'PF',name:'Frans-Polynesi&euml;'},
        {code:'GA',name:'Gabon'},
        {code:'GM',name:'Gambia'},
        {code:'GE',name:'Georgi&euml;'},
        {code:'GH',name:'Ghana'},
        {code:'GI',name:'Gibraltar'},
        {code:'GD',name:'Grenada'},
        {code:'GR',name:'Griekenland'},
        {code:'GL',name:'Groenland'},
        {code:'GP',name:'Guadeloupe'},
        {code:'GU',name:'Guam'},
        {code:'GT',name:'Guatemala'},
        {code:'GG',name:'Guernsey'},
        {code:'GN',name:'Guinee'},
        {code:'GW',name:'Guinee-Bissau'},
        {code:'GY',name:'Guyana'},
        {code:'HT',name:'Ha&iuml;ti'},
        {code:'HM',name:'Heard en McDonaldeilanden'},
        {code:'HN',name:'Honduras'},
        {code:'HU',name:'Hongarije'},
        {code:'HK',name:'Hongkong'},
        {code:'IE',name:'Ierland'},
        {code:'IS',name:'IJsland'},
        {code:'IN',name:'India'},
        {code:'ID',name:'Indonesi&euml;'},
        {code:'IQ',name:'Irak'},
        {code:'IR',name:'Iran'},
        {code:'IL',name:'Isra&euml;l'},
        {code:'IT',name:'Itali&euml;'},
        {code:'CI',name:'Ivoorkust'},
        {code:'JM',name:'Jamaica'},
        {code:'JP',name:'Japan'},
        {code:'YE',name:'Jemen'},
        {code:'JE',name:'Jersey'},
        {code:'JO',name:'Jordani&euml;'},
        {code:'KY',name:'Kaaimaneilanden'},
        {code:'CV',name:'Kaapverdi&euml;'},
        {code:'CM',name:'Kameroen'},
        {code:'KZ',name:'Kazachstan'},
        {code:'KE',name:'Kenia'},
        {code:'KG',name:'Kirgizi&euml;'},
        {code:'KI',name:'Kiribati'},
        {code:'UM',name:'Kleine Pacifische eilanden van de V.S.'},
        {code:'KW',name:'Koeweit'},
        {code:'HR',name:'Kroati&euml;'},
        {code:'LA',name:'Laos'},
        {code:'LS',name:'Lesotho'},
        {code:'LV',name:'Letland'},
        {code:'LB',name:'Libanon'},
        {code:'LR',name:'Liberia'},
        {code:'LY',name:'Libi&euml;'},
        {code:'LI',name:'Liechtenstein'},
        {code:'LT',name:'Litouwen'},
        {code:'LU',name:'Luxemburg'},
        {code:'MO',name:'Macau'},
        {code:'MK',name:'Macedoni&euml;'},
        {code:'MG',name:'Madagaskar'},
        {code:'MW',name:'Malawi'},
        {code:'MV',name:'Maldiven'},
        {code:'MY',name:'Maleisi&euml;'},
        {code:'ML',name:'Mali'},
        {code:'MT',name:'Malta'},
        {code:'IM',name:'Man'},
        {code:'MA',name:'Marokko'},
        {code:'MH',name:'Marshalleilanden'},
        {code:'MQ',name:'Martinique'},
        {code:'MR',name:'Mauritani&euml;'},
        {code:'MU',name:'Mauritius'},
        {code:'YT',name:'Mayotte'},
        {code:'MX',name:'Mexico'},
        {code:'FM',name:'Micronesia'},
        {code:'MD',name:'Moldavi&euml;'},
        {code:'MC',name:'Monaco'},
        {code:'MN',name:'Mongoli&euml;'},
        {code:'ME',name:'Montenegro'},
        {code:'MS',name:'Montserrat'},
        {code:'MZ',name:'Mozambique'},
        {code:'MM',name:'Myanmar'},
        {code:'NA',name:'Namibi&euml;'},
        {code:'NR',name:'Nauru'},
        {code:'NL',name:'Nederland'},
        {code:'NP',name:'Nepal'},
        {code:'NI',name:'Nicaragua'},
        {code:'NC',name:'Nieuw-Caledoni&euml;'},
        {code:'NZ',name:'Nieuw-Zeeland'},
        {code:'NE',name:'Niger'},
        {code:'NG',name:'Nigeria'},
        {code:'NU',name:'Niue'},
        {code:'MP',name:'Noordelijke Marianen'},
        {code:'KP',name:'Noord-Korea'},
        {code:'NO',name:'Noorwegen'},
        {code:'NF',name:'Norfolk'},
        {code:'UG',name:'Oeganda'},
        {code:'UA',name:'Oekra&iuml;ne'},
        {code:'UZ',name:'Oezbekistan'},
        {code:'OM',name:'Oman'},
        {code:'AT',name:'Oostenrijk'},
        {code:'TL',name:'Oost-Timor'},
        {code:'PK',name:'Pakistan'},
        {code:'PW',name:'Palau'},
        {code:'PS',name:'Palestina'},
        {code:'PA',name:'Panama'},
        {code:'PG',name:'Papoea-Nieuw-Guinea'},
        {code:'PY',name:'Paraguay'},
        {code:'PE',name:'Peru'},
        {code:'PN',name:'Pitcairneilanden'},
        {code:'PL',name:'Polen'},
        {code:'PT',name:'Portugal'},
        {code:'PR',name:'Puerto Rico'},
        {code:'QA',name:'Qatar'},
        {code:'RE',name:'R&eacute;union'},
        {code:'RO',name:'Roemeni&euml;'},
        {code:'RU',name:'Rusland'},
        {code:'RW',name:'Rwanda'},
        {code:'BL',name:'Saint-Barth&eacute;lemy'},
        {code:'KN',name:'Saint Kitts en Nevis'},
        {code:'LC',name:'Saint Lucia'},
        {code:'PM',name:'Saint-Pierre en Miquelon'},
        {code:'VC',name:'Saint Vincent en de Grenadines'},
        {code:'SB',name:'Salomonseilanden'},
        {code:'WS',name:'Samoa'},
        {code:'SM',name:'San Marino'},
        {code:'SA',name:'Saoedi-Arabi&euml;'},
        {code:'ST',name:'Sao Tom&eacute; en Principe'},
        {code:'SN',name:'Senegal'},
        {code:'RS',name:'Servi&euml;'},
        {code:'SC',name:'Seychellen'},
        {code:'SL',name:'Sierra Leone'},
        {code:'SG',name:'Singapore'},
        {code:'SH',name:'Sint-Helena, Ascension en Tristan da Cunha'},
        {code:'MF',name:'Sint-Maarten'},
        {code:'SX',name:'Sint Maarten'},
        {code:'SI',name:'Sloveni&euml;'},
        {code:'SK',name:'Slowakije'},
        {code:'SD',name:'Soedan'},
        {code:'SO',name:'Somali&euml;'},
        {code:'ES',name:'Spanje'},
        {code:'SJ',name:'Spitsbergen en Jan Mayen'},
        {code:'LK',name:'Sri Lanka'},
        {code:'SR',name:'Suriname'},
        {code:'SZ',name:'Swaziland'},
        {code:'SY',name:'Syri&euml;'},
        {code:'TJ',name:'Tadzjikistan'},
        {code:'TW',name:'Taiwan'},
        {code:'TZ',name:'Tanzania'},
        {code:'TH',name:'Thailand'},
        {code:'TG',name:'Togo'},
        {code:'TK',name:'Tokelau'},
        {code:'TO',name:'Tonga'},
        {code:'TT',name:'Trinidad en Tobago'},
        {code:'TD',name:'Tsjaad'},
        {code:'CZ',name:'Tsjechi&euml;'},
        {code:'TN',name:'Tunesi&euml;'},
        {code:'TR',name:'Turkije'},
        {code:'TM',name:'Turkmenistan'},
        {code:'TC',name:'Turks- en Caicoseilanden'},
        {code:'TV',name:'Tuvalu'},
        {code:'UY',name:'Uruguay'},
        {code:'VU',name:'Vanuatu'},
        {code:'VA',name:'Vaticaanstad'},
        {code:'VE',name:'Venezuela'},
        {code:'AE',name:'Verenigde Arabische Emiraten'},
        {code:'US',name:'Verenigde Staten'},
        {code:'GB',name:'Verenigd Koninkrijk'},
        {code:'VN',name:'Vietnam'},
        {code:'WF',name:'Wallis en Futuna'},
        {code:'EH',name:'Westelijke Sahara'},
        {code:'BY',name:'Wit-Rusland'},
        {code:'ZM',name:'Zambia'},
        {code:'ZW',name:'Zimbabwe'},
        {code:'ZA',name:'Zuid-Afrika'},
        {code:'GS',name:'Zuid-Georgia en de Zuidelijke Sandwicheilanden'},
        {code:'KR',name:'Zuid-Korea'},
        {code:'SS',name:'Zuid-Soedan'},
        {code:'SE',name:'Zweden'},
        {code:'CH',name:'Zwitserland'},
]