<template>
    <div class="breadcrumbs" v-if="steps.length>0">
        <a :href="page.url" v-for="(page, index) in steps" :key="index" class="bd">{{ $t(page.name) }}</a>
        <a v-if="next.name.length>0" class="bd next" href="#" @click="nextStep($event)">{{ $t(next.name) }}</a>
    </div>
</template>
<script> 
export default {
    name:'breadcrumbs',
    props:{
        steps : Array,
        next: Object,
    },
    methods: {
        nextStep (e) {
            e.preventDefault();
            
            if(this.next.hasOwnProperty('endpoint')){
                this.$store.dispatch('get', this.next.endpoint)
                .then(resp=>{
                    var data = resp.data
                    console.log(resp);
                    this.$store.dispatch('makeNotification',{title:data.title,text:data.text,type:data.type});
                    if(data.hasOwnProperty('url')){
                        this.$router.push(data.url);
                    }
                }).catch((error)=>{
                    console.log(error.request);
                });
            }else{
                alert('ERROR');
            }
        }
    }
}
</script>
<style scoped>
    .breadcrumbs{
       display: flex;
    }
    .breadcrumbs .bd{ 
        background:lightcoral;
        padding-top: 10px;
        padding-bottom: 10px;
        width:auto;
        padding-right:25px; 
        padding-left: 30px;
        clip-path: polygon(0 0, 85% 0, 99% 50%, 84% 100%, 0 100%);
        margin-left: -25px; 
        color: white;
        font-size: 13px;
    }
    .breadcrumbs .bd:first-child{
        margin-left: 0;
        background: var(--color-1);
        z-index: 6;
        padding-left: 15px;
    }
    .breadcrumbs .bd:nth-child(2){
        background: #74626e;
        z-index: 5;
    }
     .breadcrumbs .bd:nth-child(3){
        background: #b57f77;
        z-index: 4;
    } 
     .breadcrumbs .bd:nth-child(4){
        background: #f29b7f;
        color: #000;
        z-index: 3;
    } 
     .breadcrumbs .bd:nth-child(5){
        background: #f3cdc0;
        color: #000;
        z-index: 2;
    } 
     .breadcrumbs .bd:nth-child(6){
        background: #fae9e1;
        color: #000;
        z-index: 1;
    } 

    .next{
         margin-left: auto !important; 
        color: #fff !important;
        clip-path: polygon(0 0, 0 0, 0 0, 0 0, 0 0);
        padding-left: 15px !important;
    }

</style>
