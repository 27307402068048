<template>
    <div id="login">

        <div id="login_module" class="col-10 col-sm-4 col-md-4 col-lg-3">
            <img :src="logo">
            <div id="form">
                <div class="form-side" v-if="!this.verified && !this.reset_task.error"> 
                    <div class="roller"></div>
                    <h6 class="mb-4 text-center text-white">{{ $t('login.password_reset_verify') }}...</h6> 
                </div>
                <p v-if="reset_task.error || error" class="msg text-center text-danger">{{reset_task.error_msg}}</p>
                <p v-if="reset_task.success" class="msg text-center text-success">{{reset_task.success_msg}}</p> 
                <form v-if="this.verified && !this.reset_task.error && !this.reset_task.final_success" action="" @submit.prevent="send()">
                    <div class="form-group">
                        <input type="password" class="form-control" v-model="password" :placeholder="$t('lefficient.leffit.user.fields.password')">
                    </div> 
                     <div class="form-group">
                        <input type="password" class="form-control" v-model="password_confirmation" :placeholder="$t('lefficient.leffit.user.fields.password_confirmation')">
                    </div> 
                    <div class="form-group">
                        <button :disabled="(reset_task.task_loader ? true:false)" class="btn btn-party btn-mijn btn-block p-2" type="submit">
                            <div id="task_loader" v-if="reset_task.task_loader"></div>
                            {{ $t('system.confirm')}}
                        </button>
                    </div>
                    <br>
                </form>
                <div class="row foot">
                    <div class="col-md-6">
                        <a href="/login">{{ $t('login.login') }}</a> 
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name:'resetpassword',
    computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint
        }),
    },
    data(){
        return {
            verified:false,
            password:'',
            password_confirmation:'',
            reset_task:{
                sent:false,
                error:false,
                error_msg:'',
                success:false,
                success_msg:'',
                final_success:false,
                btntext:this.$t('login.login'),
                task_loader:false
            },
            error:false,
            user_id:0,
            logo : process.env.VUE_APP_LOGO_URL,
        }
    }, 
    methods:{
        send(){
            this.reset_task.error=false;
            this.error=false;

            if(this.password.trim().length==0 || this.password_confirmation.trim().length==0){
                this.reset_task.error=true;
                this.reset_task.error_msg= this.$t('login.password_create');
                return false;
                
            }

            if(this.password != this.password_confirmation){
                this.error=true;
                this.reset_task.error_msg= this.$t('login.passwords_do_not_match'); // "Wachtwoorden komen niet overeen.";
                return false;
            }
            
            let password=this.password;
            if(password.length < 8){
                this.error=true;
                this.reset_task.error_msg= this.$t('login.passwords_min_8'); //"Wachtwoord moet minimaal 8 karakters hebben.";
                return false;
            }
            if(password.search(/[0-9]/) < 0){
                this.error=true;
                this.reset_task.error_msg=this.$t('login.passwords_min_int'); // "Wachtwoord moet minimaal 1 getal (0-9) bevatten.";
                return false;
            }
            if(password.search(/[A-Z]/) < 0){
                this.error=true;
                this.reset_task.error_msg= this.$t('login.passwords_min_capital'); // "Wachtwoord moet minimaal 1 hoofdletter (A-Z) bevatten.";
                return false;
            }
            

            this.reset_task.task_loader=true;
            this.reset_task.btntext= this.$t('system.loading')+"...";
            let email=this.email;
            console.log(email);
            this.$store.dispatch('post',{endpoint:"/login/reset-password/"+this.user_id, details : {password:this.password,password_confirmation:this.password_confirmation,token:this.$route.params.token}})
            .then(data=>{  
                console.log(data);
                this.$store.dispatch('makeNotification',{title:"Reset Password",text:this.$t('login.password_reset_succes'),type:'success'});
                this.$router.push('/login');
            })
            .catch(error=>{
                console.log(error);
                this.reset_task.task_loader=false;
                this.reset_task.btntext=this.$t('login.password_create');
            });
        }
    },
    created(){ 
        this.$store.dispatch('post',{endpoint:'/login/verify-forget-password', details:{token:this.$route.params.token}})
        .then(data=>{
            console.log(data);
            this.user_id=data.data;
            this.verified=true;
        });
    }
}
</script>

<style scoped>
    
    .form-control{
        background-color: #ffffffcc;
    }
    #login{
        height: 100%;
        background-image: linear-gradient(to right ,#00897e,#0090b5,#005197,#044580,#262261) !important ;
    }
    .msg{
        font-size: 12px;
        color: white !important;
        background: #dc3545;
        padding: 1px 12px;
        width: fit-content;
        margin: 0px auto;
        margin-bottom: 10px;
    }
    #task_loader{
        width: 20px;
        height: 20px;
        border: 2px solid gray;
        border-top: 2px solid white;
        border-radius: 50%;
        position: absolute;
        left: 15%;
        margin-top: 0.7%;
        animation: spin .6s linear infinite;
    }
     @keyframes spin{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    /*#login_module .foot{
        padding: 10px 16px;
        text-align: center;
        display: block;
        justify-content: space-between;
    }*/
    #login_module .foot a{
        font-size: 13px;
        font-weight: bold;
        color: white;
        display: block;
    }
    #login_module{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto; 
        display: table;
        border-radius: 5px; 
    }
    #login_module img{
        width: 265px;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px; 
        border-radius: 7px; 
        padding: 5px 7px;
    }
    #login_module h2{
        text-align: center;
        margin: 20px 0px;
        color: #014d90;
        font-weight: 700;
    }
    #login_module form{
        text-align: center;
        
    }
    #login_module form input:focus{
        border:1px solid #014d90;
        box-shadow: none;
    }
    #login_module form input{
       font-size: 1rem;
        border-radius: 15px;
        padding: 25px 15px;
    }
     #login_module .btn-mijn{
        margin: 0 auto;
        padding: .300rem .75rem;
        background: var(--color-2) !important;
        border-radius:10px;
     }
</style>

