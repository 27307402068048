<template>
  <div>
    <h1>routes:</h1>
    <table class="table">
        <thead>
          
        </thead>
        <tbody>
            <tr v-for="(route, index) in allRoutes" :key="index+'_route'">
              <td></td>
              <td></td>
              <td></td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'app',
  data(){
    return {
    }
  },
  methods:{
    getChildren(route){
      let routes = [];
      let thas = this;
      if(route.children){
        route.children.forEach(function(child){
          routes.push(child);
          if(route.children){
            routes.concat(thas.getChildren(route.children));
          }
        });
      }
      return routes;
    }
  },
  computed:{
    allRoutes : function(){
      var thas = this;
      var routes = [];
      this.$router.options.routes.forEach(function(route){
        if(route.children){
          routes.concat(thas.getChildren(route));
        }
        routes.push(route);
      });
      console.log(routes);
      return routes;
    }
  },
  components: {
    
  }
}
</script>

<style>
.card-body{
  text-align: left;
}
th input[type=checkbox], th input[type=radio]{
  width: auto;
  margin: auto;
}
.drag{
  cursor: move !important;
  position: relative;
}
</style>
