<template>
<div class='demo-app'>
    <div class='demo-app-main'>
      <FullCalendar
        class='demo-app-calendar'
        ref="fullCalendar"
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>

	<div 
	    class="modal fade"
	    :class="{show: isAddEvent}" 
	    id="createTableModal" 
	    tabindex="-1" 
	    role="dialog" 
	    aria-labelledby="createTableModal"
	    aria-hidden="true">
	    <div class="modal-dialog" role="document">
	        <div class="modal-content">
	          <div class="modal-header">
	              <h4 class="modal-title font-weight-bold">{{ action == 'add' ? 'Add' : 'Update' }} Schedule</h4>
	              <button 
	              	type="button" 
	              	style="color:#000" 
	              	class="close" 
	              	data-dismiss="modal" 
	              	aria-label="Close"
	              	@click="isAddEvent = false">
	                  <span aria-hidden="true">&times;</span>
	              </button>
	          </div>
	          <div class="modal-body">

				<div class="form-group">
	                <label for="type">{{ $t('leffit.products.product.product_type') }}</label>
	                <select v-model="appointment.plannable_id" style="position:relative" class="form-control select-plannable col-12" id="type">
	                    <option 
	                    	:key="index" 
	                    	v-for="(plannable,index) in plannables" 
	                    	:value="plannable.id">{{ plannable.model }}</option>
	                </select>
	            </div>
	          	
	          	<div class="form-group">
	          		<label for="title">Title</label>
	          		<input type="text" class="form-control" v-model="appointment.title">
	          	</div>

	          	<div class="form-group">
	          		<input type="submit" class="btn btn-success" :value="action == 'add' ? 'Add' : 'Update'" @click="action == 'add' ? createSchedule() : updateSchedule()">
	          		<button 
		              	type="button"
		              	class="btn btn-danger"
		              	@click="isAddEvent = false">Close</button>
	          	</div>
	            
	          </div>
	        </div>
	    </div>
	</div>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data: function() {
    return {
    	action: 'add',
    	isAddEvent: false,
    	appointment: {
    		title: '',
    		start: new Date(),
    		end: new Date(),
    		plannable_id: ''
    	},
    	appointments: [],
    	plannables: [],
    	calendarApi: {},
	    calendarOptions: {
	        plugins: [
	          dayGridPlugin,
	          timeGridPlugin,
	          interactionPlugin // needed for dateClick
	        ],
	        headerToolbar: {
	          left: 'prev,next today',
	          center: 'title',
	          right: 'timeGridDay,timeGridWeek,dayGridMonth'
	        },
	        initialView: 'timeGridDay',
	        slotDuration: '00:30',
	        events: [],
	        editable: false,
	        selectable: true,
	        selectMirror: true,
	        dayMaxEvents: true,
	        weekends: true,
	        select: this.handleDateSelect,
	        eventClick: this.handleEventClick,
	        eventsSet: this.handleEvents,
	        eventRender: this.handleEventRender,
	      },
	      currentEvents: []
	    }
  },
  methods: {
  	fetchAppointments: function(){
		this.$store.dispatch('get','/leffit/plannables/appointments')
        .then(data=>{
        	// console.log('appointments',data);
			this.appointments = data.data.data;
			this.calendarOptions.events = [];
			for(let appointment of this.appointments) {
				this.calendarOptions.events.push({
	                id: appointment.id,
	                title: appointment.description,
	                start: new Date(appointment.start),
	                end: new Date(appointment.end),
	                extendedProps: {
	                	plannable_id: appointment.plannable_id
	                },
	                className: 'appointment-item single-item'
	            });
			}
			window.$('.select-plannable').select2({
				width: '100%'
			});

		});

        this.$store.dispatch('get','/leffit/plannables/plannables')
        .then(data=>{
			this.plannables = data.data.data;
			var context=this;
			window.$('.select-plannable').select2().change(function(){
				context.appointment.plannable_id=window.$('.select-plannable').val();
				window.console.log(window.$('.select-plannable').val()); 
			});
		}); 
	},
  	createSchedule() {
  		console.log(this.appointment.title)
  		this.appointment.description = this.appointment.title

		let notification = {title:"Appointment", text:'Created successfully', type:'success'};
		this.$store.dispatch('post',{endpoint:'/leffit/plannables/appointments',details:this.appointment, notification: notification})
        .then(data=>{
        	this.fetchAppointments();
        	this.closeModal();
        	console.log('appointments',data);
		});
  	},
  	updateSchedule() {
  		let notification = {title:"Appointment", text:'Updated successfully', type:'success'};
  		this.appointment.description = this.appointment.title
  		console.log(this.appointment)
  		this.$store.dispatch('put',{endpoint:'/leffit/plannables/appointments/'+this.appointment.id,details:this.appointment, notification: notification})
  		.then(data=>{
  			this.fetchAppointments();
  			this.closeModal();
  		}).catch((error)=>{
  			console.log(error.request);
  		});
  	},
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
    	console.log(selectInfo);
    	this.action = 'add'
    	this.appointment = {
    		id: null,
    		title: '',
    		start: selectInfo.startStr,
    		end: selectInfo.endStr,
    		plannable_id: ''
    	}
      	this.calendarApi = selectInfo.view.calendar
      	this.calendarApi.unselect() // clear date selection

      	this.isAddEvent = true

      // if (title) {
      //   calendarApi.addEvent({
      //     id: 100,
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },
    handleEventClick(clickInfo) {
    	console.log(clickInfo.event)
    	this.action = 'update'
    	window.$('.select-plannable').val(clickInfo.event.extendedProps.plannable_id).trigger('change');
    	this.appointment.id = clickInfo.event.id
    	this.appointment.title = clickInfo.event.title
    	this.appointment.start = clickInfo.event.startStr
    	this.appointment.end = clickInfo.event.endStr
    	this.appointment.plannable_id = clickInfo.event.extendedProps.plannable_id

    	this.isAddEvent = true
      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove()
      // }
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    handleEventRender(event, eventElm) {
    	window.$(event.el).data('toggle', 'tooltip');
    	window.$(event.el).data('placement', 'top');
    	window.$(event.el).attr('title', event.event.title);
    	window.$(event.el).tooltip();
    },
    closeModal: function(){
    	this.isAddEvent = false
        window.$('.modal').modal('hide');
        window.$('.modal-backdrop').remove();
    },
  },
  mounted() {
  	this.fetchAppointments()
  }
}
</script>

<style lang='css' scoped>
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b { /* used for event dates/times */
  margin-right: 3px;
}
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.fade.show {
	display: block;
}

.appointment-item:hover {
	cursor: pointer;
}
</style>