<template>
    <div>
        <div class="head row">
        	<h3 class="pl-3" v-show="!isDisabled">{{ $t('leffit.shipping.region_edit') }}</h3>
             <h3 class="pl-3" v-show="isDisabled">{{ $t('leffit.shipping.region_view') }}</h3>
      	</div>
        <div class="">
           <div >
				<div class="card" style="border:none">

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.region_name') }}</label>
                        <input type="text" class="form-control" id="title" v-model="item.name" :disabled="(isDisabled ? true:false)">
                    </div>
                </div>

                 <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.region_country') }}</label>
                        <Select2 @change="toggleCountry()" v-model="selectedcountry"  class="col-12" :options="countries.map((d)=>{return {id:d.id,text:d.code}})"></Select2>
                        <div class="added">
                            <ul class="items">
                                <li v-for="(seltd,index) in selectedcountries" :key="index" class="item">
                                    <a class="itm" href="#"><span>{{seltd.code}}</span> <a @click="removeCountryItem(seltd.id)" class="rmv" href="#"><i class="fas fa-remove"></i></a> </a>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="title">{{ $t('leffit.shipping.region_cost') }}</label>
                        <Select2 class="col-12" v-model="selectedcost" @change="toggleCost()" :options="costs.map((d)=>{return {id:d.id,text:d.name}})"></Select2>
                        <div class="added">
                            <ul class="items">
                                <li v-for="(seltd,index) in selectedcosts" :key="index" class="item">
                                    <a class="itm" href="#"><span>{{seltd.name}}</span> <a @click="removeCostItem(seltd.id)" class="rmv" href="#"><i class="fas fa-remove"></i></a> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <button @click="create()" class="btn btn-success" :disabled="(isDisabled ? true:false)">{{ $t('lefficient.leffit.system.update') }}</button>

				</div>
			</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'create',
    data(){
        return {
            item : {
                name:'',
                country : '',
                cost: '',
            },
            countries: [],
            costs: [],
            selectedcountry:0,
            selectedcost:0,
            isDisabled: false,
            selectedcountries:[],
            selectedcosts:[],
        }
    },
    methods:{
        removeCostItem(id){
            this.selectedcost=id;
            this.toggleCost();
        },
        toggleCost(){
            var cost=this.costs.find((d)=> d.id == this.selectedcost);
            if(this.selectedcosts.find((d)=> d.id == cost.id)){
                this.selectedcosts=this.selectedcosts.filter((d)=> d.id != cost.id);
            }else{
                this.selectedcosts.push(cost);
            } 
        },
        removeCountryItem(id){
            this.selectedcountry=id;
            this.toggleCountry();
        },
        toggleCountry(){
            var country=this.countries.find((d)=> d.id == this.selectedcountry);
            if(this.selectedcountries.find((d)=> d.id == country.id)){
                this.selectedcountries=this.selectedcountries.filter((d)=> d.id != country.id);
            }else{
                this.selectedcountries.push(country);
            }
            console.log(country);
        },
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }

            this.item.countries=this.selectedcountries.map((d)=> d.id);
            this.item.costs=this.selectedcosts.map((d)=> d.id);
 
            console.log(this.$store.state);
            this.$store.dispatch('put',{endpoint:'/leffit/shipping/shippingregions/'+this.item.id,details:this.item})
			.then(data=>{
				console.log('result',data);
                this.$store.dispatch('makeNotification',{type:'success',title:"Status updated",text:'Status was updated automatically'});

			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){

         var isview=window.location.pathname.indexOf("view");
         if(isview > 1){
                this.isDisabled=true;  }

         this.$store.dispatch('get','/leffit/shipping/shippingregions/'+this.$route.params.id)
			.then(data=>{
                console.log('result',data);
                this.item=data.data.data;
                this.selectedcountries=data.data.data.countries.map((d)=>{
                    d.id=d.country.id;
                    d.code=d.country.code;
                    return d;
                });
                this.selectedcosts=data.data.data.costs.map((d)=>{
                    console.log(d);
                    d.id=d.cost.id;
                    d.name=d.cost.name;
                    if(d.cost.method != null){
                        console.log(d.name);
                        d.name=d.name +' ('+d.cost.method.name+')';
                    }
                    return d;
                });
			}).catch((error)=>{
				console.log(error);
			});

            this.$store.dispatch('get','/lefficient/leffit/countries')
			.then(data=>{
                // console.log('result : ',data);
                 this.countries=data.data.data;
			}).catch((error)=>{
				console.log("error :" ,error);
			});

            this.$store.dispatch('get','/leffit/shipping/shippingcosts')
			.then(data=>{
                // console.log('result : ',data);
                this.costs=data.data.data;
                this.costs=this.costs.map((d)=>{
                    if(d.method != null){
                        d.name=d.name +' ('+d.method.name+')';
                    }
                    return d;
                });
			}).catch((error)=>{
				console.log("error :" ,error);
			});

            this.$store.dispatch('get','/lefficient/leffit/getcountry/'+this.$route.params.id)
			.then(data=>{
                // console.log('result : ',data.data);
                if( data.data != 0 ){
                this.selectedcountry=data.data.id;
                }
			}).catch((error)=>{
				console.log("error here :" ,error);
			});

            this.$store.dispatch('get','/leffit/shipping/getcost/'+this.$route.params.id)
			.then(data=>{
                // console.log('result :',data.data);
                if(data.data != 0 ){
                    this.selectedcost=data.data.id;
                }
			}).catch((error)=>{
				console.log("error" ,error);
			});
    }
}
</script>
<style scoped>
    .items{
        list-style-type: none;
        margin: 10px 0px;
        padding: 0;
    }
    .items li{
        display: inline-block;
        margin: 5px 4px;
    }
    .item a.itm{
        background: #d3d3d345;
        padding: 4px 10px;
        border-radius: 15px;
        color: black;
        font-size: 14px;
        border: 1px solid lightgray; 
    }
    .item a.rmv{
        color: red;
        margin-left: 6px;
        font-size: 12px;
    }
</style>