<template>
	<div>
		<div class="head row">
        <h3 class="pl-3">{{ $t('leffit.products.stock.stocks') }}</h3>
    </div>
      <stock-table></stock-table>
	</div>
</template>

<script>
import table from './table';
export default {
  	name: 'productFilters',
	components:{'stock-table':table},
  	data () {
    	return {
    		pagedata : [],
        attributes : [
          'ean',
          'name',
          'producttype', 
          'stock', 
        ],
        search : {search:{}},
    	}
  	},
    methods: {
      translate (input) {
        if(input){
          if(input.constructor === String){
            if(input.indexOf('leffit.products')!==-1){
              return this.$t(input);
            }else{
              return input;
            }
          }
        } 
        return input;
	  },
	   searchTable(){  
        var temp=[];
          for(var s in this.search.search){
              temp.push({
                'attribute':s,
                'value':this.search.search[s]
              }); 
          }
          this.search.search=temp;
          console.log(this.search);
          this.$store.dispatch('post',{details:this.search,endpoint:'/leffit/products/stocklocations/search'})
          .then((data)=>{
            console.log(data);
            this.pagedata=data.data.data;
          })
          .catch((error)=>{
            console.log(error)
          })
        },
    },
  	mounted(){
	  //   this.$store.dispatch('post',{endpoint:'/leffit/products/search',details:{limit:10}})
    //     .then(data=>{
    //     	console.log(data);
		// 	this.pagedata = data.data.data;
		// });
	},
}
</script>

<style lang="css" scoped>
</style>