<template>
	<div>
		<div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.products.productstatuses') }}</h3>
			<div class="col-12 p-0 m-0">
				<button data-target="#addnew" data-toggle="modal" class="btn btn-success btn-sm">{{ $t('leffit.products.product_statuses.add_new') }}</button>
			</div>
      	</div>
      	<table class="table table-responsive table-hover">
      		<thead>
      			<tr>
      				<th></th>
      				<th :key="index" v-for="(attribute,index) in attributes">{{ $t('leffit.products.type.'+attribute) }}</th>
      			</tr>
      		</thead>
          <thead>
            <tr>
              <th><a class="btn btn-primary"><i class="fa fa-search"></i></a></th>
              <th :key="index" v-for="(attribute,index) in attributes"><input type="text" v-model="search[attribute]" class="form-control"></th>
            </tr>
          </thead>
      		<tbody>
      			<tr :key="index" v-for="(item, index) in pagedata">
      				<td>
      					<router-link :to="'/admin/products/settings/productstatus/'+item.id" class="btn btn-open">
      						<i class="fa fa-search"></i>
      					</router-link>
      					<router-link :to="'/admin/products/settings/productstatus/'+item.id+'/edit'" class="btn btn-warning">
      						<i class="fa fa-pencil"></i>
      					</router-link>
						<a :href="'#'" @click="deleteItem(item.id)" class="btn btn-danger">
      						<i class="fa fa-trash"></i>
      					</a>
      				</td>
      				<td :key="index" v-for="(attribute,index) in attributes" v-html="translate(item[attribute])"></td>
      			</tr>
      		</tbody>
      	</table>

		  <div class="modal" tabindex="-1" role="dialog" id="addnew">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ $t('leffit.products.product_statuses.add_new_status') }}</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					 <div class="row">
						 <div class="form-group col-sm-12 ">
							 <label for="">{{ $t('leffit.products.product_statuses.name') }}</label>
						 	<input v-model="item.name" type="text" class="form-control" />
						 </div>
					 </div>
				</div>
				<div class="modal-footer">
					<button id="closemodal" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">{{ $t('leffit.products.product_statuses.close') }}</button>
					<button @click="create()" type="button" class="btn btn-primary">{{ $t('leffit.products.product_statuses.save_changes') }}</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  	name: 'index',
  	data () {
    	return {
    		pagedata : [],
            attributes : [
            'name',
            ],
			search : {},
			item:{name:""}
    	}
	},
	methods:{
		create(){
			this.$store.dispatch('post',{endpoint:'/leffit/products/productstatuses',details:this.item})
			.then(data=>{
				console.log('result',data.data);
				this.$store.dispatch('makeNotification',{title:"ITEM ADDED",text:this.item.name+' was added successfully',type:'success'});
				this.pagedata.unshift(data.data.data);
				document.getElementById('closemodal').click();
				}).catch((error)=>{
					console.log(error.request);
				});
		},
		deleteItem(id){
			this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
			this.$store.dispatch('delete','/leffit/products/productstatuses/'+id)
			.then(data=>{
				console.log(data);
				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:this.item.name+' was removed successfully',type:'success'});
				// this.pagedata = data.data.data;
			});
		},
		translate (input) {
          if(input){
            if(input.constructor === String){
              if(input.indexOf('leffit.products')!==-1){
                return this.$t(input);
              }else{
                return input;
              }
            }
          }

          return input;
        }
	},
  	mounted(){
	    this.$store.dispatch('get','/leffit/products/productstatuses')
        .then(data=>{
        	console.log(data);
			this.pagedata = data.data.data;
		});
	},
}
</script>

<style lang="css" scoped>
</style>
