<template>
    <div class="mt-5">
        <div class="row">
            <div class="col-12">
                <button data-target="#createMessageModal" data-toggle="modal" class="ml-auto d-block btn btn-success">{{ $t('leffit.crm.contact.create') }}</button>
                <div id="createMessageModal" class="modal">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">Create New Message</h4>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="">
                                        <label for="">Select Status</label>
                                        <Select2 style="width:100%" v-model="message.message_status_id" :options="statuses" name="" id="" class="form-control">
                                        </Select2>
                                    </div>
                                    <div class="">
                                        <label for="">Select Organizations</label>
                                        <Select2 style="width:100%" v-model="message.organization_id" :options="organizations"  name="" id="" class="form-control"> </Select2>
                                    </div>
                                    <div class="">
                                        <label for="">Select User</label>
                                        <Select2 style="width:100%" v-model="message.lefficient_leffit_user_id" :options="users"  name="" id="" class="form-control"> </Select2>
                                    </div>
                                    <div class="mt-2">
                                        <label for="">Title</label>
                                        <input v-model="message.title" type="text" class="form-control">
                                    </div>
                                    <div class="">
                                        <label for="">Attachment</label>
                                        <input type="file" id="attachment" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <editor
                                            api-key="no-api-key"
                                            v-model="message.message"
                                            :init="{
                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                'advlist autolink lists link  print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | link code | paste | fullscreen | removeformat | help'
                                            }"
                                        />
                                    </div>

                                    <button type="button" @click="submit()" class="btn btn-success">SUBMIT</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div v-if="level == 0" class="col-12">
                <div class="form-group">
                    <label for="">{{ $t('leffit.crm.contact.filter_by_status') }}</label>
                    <select @change="filterStatus()" v-model="filter.status" style="width:100%" name="" id="" class="form-control">
                        <option value=""></option>
                        <option v-for="(status , index) in statuses" :key="index" :value="status.id">{{status.text}}</option>
                    </select>
                </div>
                <table class="table table-responsive table-hover">
                    <thead> 
                        <tr>
                            <th></th>
                            <th>{{ $t('leffit.crm.contact.title') }}</th>
                            <th>{{ $t('leffit.crm.contact.orgnization') }}</th>
                            <th>{{ $t('leffit.crm.contact.user') }}</th>
                            <th>{{ $t('leffit.crm.contact.status') }}</th>
                            <!-- <th v-for="(attribute,index) in attributes">{{ $t('leffit.crm.contact.'+attribute) }}</th> -->
                        </tr>
                    </thead> 
                    <tbody>
                        <tr v-for="(message,index) in results" :key="index">
                            <td>
                                <button :to="''" class="btn btn-open" @click="getMessage(message.id)">
                                    <i class="fa fa-search"></i>
                                </button>
                            </td>
                            <td>{{message.title}}</td>
                            <td>{{message.organization.name}}</td>
                            <td v-if="message.user != null">{{message.user.display_name}}</td>
                            <td v-else>user not found</td>
                            <td>
                                <span v-if="message.status != null">{{message.status.name}}</span>
                                <span v-else>No defined</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="level==1" class="col-12 pl-2 pr-2">
                <div style="border:1px solid lightgray">
                    <div class="" style="border-bottom: 1px solid lightgray;">
                        <button @click="returnToMessages()" class="btn"><i class="fas fa-arrow-left"></i> BACK</button>
                    </div>
                    <div v-if="selected_message != null" class="content pl-2 pr-2"> 
                        <div class="each-msg" v-for="(message,index) in selected_message.data" :key="index">
                            <h4>{{message.title}}</h4>
                            <p v-html="message.message"></p>
                            <div v-if="message.medias.length > 0">
                                <div class="attachment mt-2 mr-3" v-for="(link,index) in message.medias" :key="index">
                                    <a target="_blank" :href="link">Download Attachment</a>
                                </div>
                            </div>
                            <div class="d-flex stats">
                                <span >
                                    <span v-if="message.status != null">{{message.status.name}}</span>
                                    <span v-else>No defined</span>
                                </span>
                                <span>{{message.organization.name}}</span>
                                <span v-if="message.user != null">{{message.user.name}}</span>
                                <span>{{message.created_at}}</span>
                            </div>
                        </div>
                        <div v-if="selected_message.data.length == 0" class="text-center"> Message list is empty </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            statuses:[],organizations:[],users:[],messages:[],filter:{status:0},results:[],
            level:0,selected_message:null,
            message:{'lefficient_leffit_user_id':0,'parent_id' : 0,'organization_id' : 0,'contact_id' : this.$route.params.id,'message_status_id' : 0, 'title' : '','message' : '',}
        }
    },
    methods:{
        returnToMessages(){
            this.level=0;
            this.selected_message=null;
        },
        filterStatus(){
            this.results=this.messages.filter((d)=> d.status.id == this.filter.status);
        },
        submit(){ 
            var formData=new FormData();
            formData.append('lefficient_leffit_user_id',this.message.lefficient_leffit_user_id);
            formData.append('parent_id',0);
            formData.append('organization_id',this.message.organization_id);
            formData.append('contact_id',this.message.contact_id);
            formData.append('message_status_id',this.message.message_status_id);
            formData.append('title',this.message.title);
            formData.append('message',this.message.message);

            console.log(document.getElementById('attachment').files);
            if(document.getElementById('attachment').files.length > 0){
                console.log('has file');
                formData.append('file',document.getElementById('attachment').files[0]);
            }

            this.$store.dispatch('post',
            {
                details:formData,
                endpoint:'/leffit/crm/message'
            })
            .then((data)=>{
                console.log(data);
                window.$("#createMessageModal").modal('hide');
                this.$store.dispatch('makeNotification',{title:"Message Created",text:'New message was created successfully',type:'success'});
            })
            .catch((error)=>{
                console.log(error);
                this.$store.dispatch('makeNotification',{title:"Message Created",text:'New message was created successfully',type:'danger'});
            });
        },
        getMessageStatuses(){
            this.$store.dispatch('get','/leffit/crm/messagestatus')
            .then((data)=>{
                this.statuses=data.data.data.map((st)=>{
                    return {id:st.id,text: st.name};
                });
            })
            .catch((error)=>{
                console.log(error);
            });
        },
        getOrganizations(){
            this.$store.dispatch('get','/leffit/crm/organizations')
			.then(data=>{
				// console.log('result',data.data);
				this.organizations=data.data.data.map((org)=>{
                    return {id:org.id,text: org.name};
                }); 
			}).catch((error)=>{
				console.log(error.request);
			});
        },
        getUsers(){
            this.$store.dispatch('get','/lefficient/leffit/users')
			.then(data=>{
				// console.log('result',data.data);
				this.users=data.data.data.map((user)=>{
                    return {id:user.id,text: user.display_name};
                }); 
			}).catch((error)=>{
				console.log(error.request);
			});
        },
        getMessage(id){
            this.$store.dispatch('get','/leffit/crm/get-message/'+id)
            .then((data)=>{
                console.log(this.selected_message);
                var temp=this.selected_message;
                temp.data=[...data.data,this.selected_message];
                this.selected_message=null;
                this.selected_message=temp;
            })
            .catch((error)=>{
                console.log(error);
            })
            this.level = 1;
            this.selected_message=this.messages.find((d)=> d.id == id);
            this.selected_message.data=[];
        },
        getMessages(){
            this.$store.dispatch('get','/leffit/crm/message')
            .then((data)=>{
                console.log(data);
                this.messages=data.data.data;
                this.results=this.messages;
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },
    created(){
        this.getMessages();
        this.getMessageStatuses();
        this.getOrganizations();
        this.getUsers();
    }
}
</script>
<style >
    .stats span{
        font-size: 14px;
        color: grey;
        margin-right: 20px;
    }
    .each-msg{
        border:1px solid lightgray;
        box-shadow: 0px 0px 10px lightgray;
        margin-top: 20px;
        padding: 20px;
        border-radius: 2px;
        padding-bottom: 5px;
    }
    .content{
        max-height: 500px;
        overflow: scroll;
        padding: 20px 0px;
    }
    .select2-container{
        width:100% !important;
    }
</style>