<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded';
export default {
	name: 'index',
	components: {sidebarInnerHardcoded},
	data () {
		return {
			menu : [
				{
					title 	: this.$t('leffit.crm.contacts'),
					url 	: '/admin/crm/contacts',
					children : [],
				},
				{
					title 	: this.$t('leffit.crm.organizations'),
					url 	: '/admin/crm/organizations',
					children : [],
				},
				{
					title 	: this.$t('leffit.crm.addresses'),
					url 	: '/admin/crm/addresses',
					children : [],
				},
				{
					title 	: this.$t('leffit.crm.messages'),
					url 	: '/admin/crm/messages',
					children : [],
				},
			],
		}
	}
}
</script>

<style lang="css" scoped>
</style>
