<template>
    <div>
        <div class="mt-5" style="position:relative;height:100vh;width:100%">
			<sectionBuilder
				:get_url="'/leffit/products/productfilters/'"
				:media_url="'/leffit/products/add-product-filter-secton-media/'"
			></sectionBuilder> 
		</div>
    </div>
</template>
<script>
import sectionBuilder from "../../../../courses/sectionBuilder/create_template" 
export default {
    components:{
		sectionBuilder,  
	},
}
</script>