import bijenkorfIndex from '../../../../src/leffit/bijenkorf/index'
import bijenkorfSalesReportIndex from '../../../../src/leffit/bijenkorf/sale_reports/index'
import webshopExport from '../../../../src/leffit/bijenkorf/export/webshop'
import pricatExport from '../../../../src/leffit/bijenkorf/export/pricat'
import VirgoImport from '../../../../src/leffit/bijenkorf/virgo/import'
// import PricatIndex from '../../../../src/leffit/bijenkorf/pricate/index'

export default [
    {
        path:"virgo/import",
        component: VirgoImport,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:"sales-report/import",
        component: bijenkorfSalesReportIndex,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:"webshop/export",
        component: webshopExport,
        meta:{
            AuthRequired:true
        }
    },
    {
        path:"pricat/export",
        component: pricatExport,
        meta:{
            AuthRequired:true
        }
    },
    // {
    //     path:"pricat/index",
    //     component: PricatIndex,
    //     meta:{
    //         AuthRequired:true
    //     }
    // }
]