import axios from 'axios';
export default{
    closePageLoader(state){
        if(state.loader.show){
            state.loader.show=false
        }else{
            state.loader=false
        }
        // state.loader.text='';
    },
    restoreSession(state){ 
        let user=localStorage.getItem('user');
        let access_token=localStorage.getItem('access_token');

        if(!user || !access_token){ return false;}

        state.user=JSON.parse(user);
        state.access_token=JSON.parse(access_token);
    },
    setUser(state,user){
        for(var key in user){
            user[key]=user[key] == null ? '':user[key];
        }
        state.user=user;
        localStorage.setItem('user',JSON.stringify(user)); 
    },
    clearUserData () {
        localStorage.removeItem('user')
        location.reload()
    },
    resetSanctumToken(){
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        localStorage.setItem('lockscreenTime',JSON.stringify((new Date()).getTime() + (process.env.VUE_APP_lOCK_EXPIRE * 1)));
    },
    setSanctumToken(state,data){
        window.console.log("setToken: ", data.data.token);
        axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
        localStorage.setItem('token',JSON.stringify(data.data.token));
        localStorage.setItem('token_expiry',JSON.stringify((new Date()).getTime() + (data.data.token_expiry * 60 * 1000)));
        localStorage.setItem('lockscreenTime',JSON.stringify((new Date()).getTime() + (process.env.VUE_APP_lOCK_EXPIRE * 1)));
    },
    setTokens(state,data){
        window.console.log("setToken: ", data);
        var access_token = data.access_token;
        var refresh_token = data.refresh_token;
        var expires_in=data.expires_in;

        state.access_token= access_token;
        state.refresh_token= refresh_token; 
 
        localStorage.setItem('access_token',JSON.stringify(access_token));
        localStorage.setItem('refresh_token',JSON.stringify(refresh_token));
        localStorage.setItem('access_token_last_set',JSON.stringify(Date.now()));
        localStorage.setItem('token_expires_in',JSON.stringify(expires_in));
        localStorage.setItem('lockscreenTime',JSON.stringify((new Date()).getTime() + (process.env.VUE_APP_lOCK_EXPIRE * 1)));

        console.log('SET TOKEN :', access_token);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        // axios.defaults.headers.common['Accept'] = "application/json";
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

        window.console.log('set token complete'); 
    },
    resetHeaders(){ 
        axios.defaults.headers.common = {};
    },
    resetTokens(state){ 
        var access_token = JSON.parse(localStorage.getItem('access_token'));
        var refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
        localStorage.setItem('lockscreenTime',JSON.stringify((new Date()).getTime() + (process.env.VUE_APP_lOCK_EXPIRE * 1)));

        state.access_token= access_token;
        state.refresh_token= refresh_token;

        axios.defaults.headers.common = {'Authorization': 'Bearer '+access_token};

        // window.console.log('reset token complete');
    }, 
    logout(state,redirect='/login'){
        state.user={};
        state.token="";
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token_last_set');
        window.location.href= redirect;
    },
    price(state, input){
        // const formatter = new Intl.NumberFormat('nl-NL', {
        //   style: 'currency',
        //   currency: 'EUR',
        //   minimumFractionDigits: 2
        // });

        return input;
        // return formatter.format(input);
    }
}