<template>
	<div>
		<div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.shipping.shippingstatusses') }}</h3>
      	</div>
        <div class="row">
			<a href="/admin/shippings/shippingstatus/create" class="btn btn-success btn-sm">{{ $t('leffit.shipping.add_shippingstatus') }}</a>
		</div>
      <status-table></status-table>
	</div>
</template>
<script>
import table from './table';
export default {
    components:{
        'status-table':table
    }
}
</script>