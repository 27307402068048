<template>
  <div class="p-5">
    <!-- DATE RANGE -->
    <div class="row">
      <h2 class="col-12">Tijdsvak</h2>
      <div class="col-6 form-group">
        <label for="">Start Date *</label>
        <input type="date" class="form-control" v-model="form.date_start" />
      </div>
      <div class="col-6 form-group">
        <label for="">End Date *</label>
        <input type="date" class="form-control" v-model="form.date_end" />
      </div>
    </div>

    <!-- ELEMENT OPTIONS -->
    <div class="row">
      <h2 class="col-12">Groepeer op</h2>
      <div class="col-6 form-group">
        <label for="">Select Element</label>
        <select v-model="form.group_by" class="form-control" id="">
          <option v-for="(element, index) in elementOptions" :key="index" :value="index">
            {{ element }}
          </option>
        </select>
      </div>
    </div>

    <!--  FILTERS -->
    <div class="row">
      <h2 class="col-12">Gebruiker</h2>

      <!-- User -->
      <div class="col-6 form-group">
        <label for="">Medewerker</label>
        <v-select
          :reduce="(user) => user.id"
          v-model="form.user_id"
          label="display_name"
          :options="users"
          class="w-100"
        ></v-select>
      </div>
    </div>

    <!--  Organization -->
    <div class="row">
      <h2 class="col-12">Organisatie</h2>
      <div class="col-6 form-group">
        <label for="">Bovenliggende organisatie</label>
        <v-select
          :reduce="(org) => org.id"
          v-model="form.organization_parent_id"
          label="name"
          :options="organizations.filter((org) => org.parent_id == null || org.id == 0)"
          class="w-100"
        ></v-select>
      </div>
      <div class="col-6 form-group">
        <label for="">organisatie</label>
        <v-select
          :reduce="(org) => org.id"
          v-model="form.organization_id"
          label="name"
          :options="organizations.filter((org) => org.parent_id > 0 || org.id == 0)"
          class="w-100"
        ></v-select>
      </div>
    </div>

    <!--  Organization -->
    <div class="row">
      <h2 class="col-8">Producten</h2>
      <!-- Filter -->
      <div class="form-group col-6 mb-0">
        <label>Filter</label>
        <div class="d-flex align-items-center">
          <v-select
            class="w-100"
            :reduce="(dt) => dt.id"
            v-model="form.filter_id"
            label="name"
            :options="filters"
          ></v-select>
        </div>
      </div>

      <!-- Products -->
      <div class="form-group col-6 mb-0">
        <!-- v-if="products.length > 0" -->
        <label>Products</label>
        <div class="d-flex align-items-center">
          <v-select
            class="w-100"
            :reduce="(product) => product.id"
            v-model="form.product_id"
            label="name"
            :options="products"
          >
            <template slot="option" slot-scope="option">
              {{ option.casa_productcode }} - {{ option.name }}
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.casa_productcode }} - {{ option.name }}
            </template>
          </v-select>
        </div>
      </div>

      <!-- Seizoenscode -->
      <div class="form-group col-6 mb-0">
        <label>Seizoenscode</label>
        <div class="d-flex align-items-center">
          <v-select
            class="w-100"
            :reduce="(product) => product.casa_seizoenscode"
            v-model="form.casa_seizoenscode"
            label="casa_seizoenscode"
            :options="products.filter((product) => product.casa_seizoenscode != null)"
          ></v-select>
        </div>
      </div>

      <div class="col-12">
        <button class="btn btn-primary" @click="downloadPdf()">
          <span v-if="!exportLoader">Download</span>
          <span v-else>DOWNLOADING...</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

const elements = [
  "Alles",
  "Product",
  "Bovenliggende organisatie",
  "Organisatie",
  "Medewerker",
];

export default {
  data() {
    return {
      exportLoader: false,
      elementOptions: elements,
      users: [{ id: 0, display_name: "-- alles --" }],
      organizations: [{ id: 0, name: "-- alles --" }],
      filters: [],
      products: [],
      form: {
        date_start: "",
        date_end: "",
        group_by: 0,
        user_id: 0,
        organization_parent_id: 0,
        organization_id: 0,
        casa_seizoenscode: "",
        product_id: "",
        filter_id: "",
        product_ids: [],
      },
    };
  },
  methods: {
    async getUsers() {
      var response = await this.$store.dispatch("get", "/lefficient/leffit/users");
      if (response.status == 200) this.users = [...this.users, ...response.data];
    },
    async getOrganizations() {
      var response = await this.$store.dispatch(
        "get",
        "/leffit/crm/get-turnover-rate-organizations"
      );
      if (response.status == 200)
        this.organizations = [...this.organizations, ...response.data];
    },
    async getProducts() {
      var response = await this.$store.dispatch(
        "get",
        "/leffit/products/get-all-products"
      );
      if (response.status == 200) {
        this.products = [...this.products, ...response.data];
        this.products = this.products.sort(
          (a, b) => a.casa_seizoenscode > b.casa_seizoenscode || -1
        );
      }
    },
    async getFilters() {
      var response = await this.$store.dispatch(
        "get",
        "/leffit/products/get-sorted-productfilters"
      );
      if (response.status == 200) this.filters = [...this.filters, ...response.data];
    },
    async downloadPdf() {
      this.exportLoader = true;
      var response = await axios.post(
        process.env.VUE_APP_API_BASE_URL + "/reports/turnover-rate/download",
        this.form,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status == 200) {
        // console.log(response);
        this.exportLoader = false;
        const type = response.headers["content-type"];
        const blob = new File([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var current = new Date();
        link.download =
          "Turnover-report" +
          "_" +
          current.getFullYear() +
          "_" +
          (current.getMonth() + 1) +
          "_" +
          current.getDate() +
          ".csv";
        link.click();
      }
      this.exportLoader = false;
    },
  },
  created() {
    this.getUsers();
    this.getOrganizations();
    this.getProducts();
    this.getFilters();
  },
};
</script>
<style scoped></style>
