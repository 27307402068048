<template>
  <div>
    <div class="row m-0">
      <div class="form-group col-12">
        <div class="row">
          <div class="col-md-6">
            <label for="filter_parent">{{
              $t("leffit.products.product_filter.children")
            }}</label>
            <select2
              v-model="item.children"
              :options="
                filters.map((d) => {
                  return { id: d.id, text: d.name };
                })
              "
              style="position: relative; width: 100%"
              class="form-controlx children"
              id="filter_children"
            >
            </select2>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-success"
              style="margin-top: 32px"
              @click="addFilter()"
            >
              {{ $t("leffit.products.product_filter.children_filter.add") }}
            </button>
          </div>
        </div>

        <div class="row m-0 mt-2">
          <!-- <ul class="m-0 p-0">
                        <li class="filter_li" v-for="(filter,index) in children" :key="index">
                            <a href="#" class="anc">{{getFilter(filter).name}} 
                                <a @click="removeFilter(filter)" href="#"><i class="fas fa-remove"></i></a>
                            </a>
                        </li>
                    </ul> -->

          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("leffit.products.filter.name") }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- 
                                MAYBE MAKE THIS ALSO DEPENDENT ON THE TYPE
                            -->
              <tr v-for="(ft, index) in children" :key="index">
                <td>
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      @click="reOrder('up', index)"
                      v-if="index > 0"
                      class="btn btn-sm pl-2 pr-2 btn-warning"
                    >
                      <i class="fa fa-chevron-up"></i>
                    </button>
                    <button
                      @click="reOrder('down', index)"
                      v-if="index < children.length - 1"
                      class="btn btn-sm pl-2 pr-2 btn-success"
                    >
                      <i class="fa fa-chevron-down"></i>
                    </button>
                    <button
                      @click="removeFilter(ft)"
                      class="btn btn-sm pl-2 pr-2 btn-danger"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                </td>
                <td>
                  <a :href="'../' + ft + '/edit'">{{ getFilter(ft).name }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <!-- <button @click="updateItem()" class="btn btn-success">UPDATE</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      children: [],
      filters: [],
    };
  },
  methods: {
    updateItem() {
      this.$parent.children = this.children;
      this.$parent.updateItem();
    },
    addFilter() {
      this.children.push(this.item.children);
      this.updateItem();
    },
    removeFilter(id) {
      if (!confirm("This item will be removed")) {
        return false;
      }

      this.children = this.children.filter((d) => d != id);
      this.updateItem();
    },
    getFilter(id) {
      return this.filters.find((d) => d.id == id);
    },
    reOrder(direction, index) {
      var data = this.children;
      if (direction == "up") {
        var temp = data[index];
        data[index] = data[index - 1];
        data[index - 1] = temp;
      } else if (direction == "down") {
        temp = data[index];
        data[index] = data[index + 1];
        data[index + 1] = temp;
      }
      this.children = [];
      this.children = data;
      this.updateItem();
    },
  },
  created() {
    this.$store
      .dispatch("get", "/leffit/products/get-productfilters/" + this.item.id)
      .then((data) => {
        this.filters = data.data.data;
        this.children = this.item.children.map((d) => {
          return d.id;
        });
        // console.log(this.children)
        this.$parent.children = this.children;
        // console.log(this.filters)
      });
  },
};
</script>

<style>
.select2-container {
  padding: 0;
  background: #fff;
}
</style>

<style scoped>
.select2-container {
  padding: 0;
}
.filter_li {
  list-style-type: none;
  margin: 5px 5px;
  display: inline-block;
}
.filter_li a.anc {
  background: #d3d3d363;
  padding: 1px 12px;
  border-radius: 17px;
  border: 1px solid #d3d3d369;
  color: grey;
  text-decoration: none;
}
.filter_li a.anc a {
  color: red;
  margin-left: 5px;
  font-size: 13px;
  text-decoration: none;
}
</style>