<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			<router-view />
		</div>
	</div>
</template>

<script>
import sidebarInnerHardcoded from './../../layout/components/sidebar_inner_hardcoded'; 
export default {
  	name: 'index',
  	components: {sidebarInnerHardcoded},

  	data () {
    	return {
    		menu : [
    			{
    				title 	: this.$t('leffit.products.products'),
    				url 	: '/admin/products/products',
    				children : [],
				},
				{
    				title 	: this.$t('leffit.products.vouchers'),
    				url 	: '/admin/products/vouchers',
    				children : [],
				},
				
				{
    				title 	: this.$t('leffit.products.stikkers'),
    				url 	: '/admin/products/stockstickers',
    				children : [],
    			},
				{
					title 	: this.$t('leffit.bijenkorf.virgocode_importeren'),
					url 	: '/admin/products/virgo/import',
					children : [],
				},
				{
					title 	: this.$t('leffit.bijenkorf.pricate_export'),
					url 	: '/admin/products/pricat/export',
					children : [],
				},
                {
					title 	: this.$t('leffit.bijenkorf.webshop_export'),
					url 	: '/admin/products/webshop/export',
					children : [],
				},
    			{
    				title 		: this.$t('leffit.products.settings'),
    				url 		: '/admin/products/settings',
    				children 	: [
    					{
		    				title 	: this.$t('leffit.products.productfilters'),
		    				url 	: '/admin/products/settings/productfilters'
		    			},
		    			{
		    				title 	: this.$t('leffit.products.producttypes'),
		    				url 	: '/admin/products/settings/producttypes'
		    			},
                        {
                            title   : this.$t('leffit.products.productstatuses'),
                            url     : '/admin/products/settings/productstatuses'
                        }
                        // {
                        //     title   : this.$t('leffit.products.productmeta_labels'),
                        //     url     : '/admin/products/settings/productmeta_labels'
                        // },
    				]
				},
    		],
    	}
  	}
}
</script>

<style lang="css" scoped>
.mainPanel{
    width:100% !important;
}
</style>