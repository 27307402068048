<template>
    <div style="background:white">
        <div style="border:1px solid lightgray">
            <div class="" style="border-bottom: 1px solid lightgray;">
                <router-link :to="'/admin/crm/messages'" class="btn"><i class="fas fa-arrow-left"></i> BACK</router-link>
            </div>
            
            <div class="content pl-2 pr-2"> 
                <div class="each-msg" v-for="(message,index) in messages" :key="index">
                    <h4>{{message.title}}</h4>
                    <p v-html="message.message"></p>
                    <div v-if="message.medias.length > 0">
                        <div class="attachment mt-2 mr-3" v-for="(link,index) in message.medias" :key="index">
                            <a target="_blank" :href="link">Download Attachment</a>
                        </div>
                    </div>
                    <div class="d-flex stats">
                        <span >
                            <span v-if="message.status != null">{{message.status.name}}</span>
                            <span v-else>No defined</span>
                        </span>
                        <span>{{message.organization.name}}</span>
                        <span v-if="message.user != null">{{message.user.name}}</span>
                        <span>{{message.created_at}}</span>
                    </div>
                </div>
                <div v-if="messages.length == 0" class="text-center"> Message list is empty </div>
            </div>   
            <div class="row mb-3 mt-3">
                <div class="col-12 pl-4 pr-4">
                    <textarea v-model="reaction.message" class="form-control" style="width:100%" id="" rows="5" placeholder="Type your message..."></textarea>
                </div>
                <div class="col-12 pl-4 pr-4">
                    <div class="form-group">
                        <select v-model="reaction.status" id="" class="form-control">
                            <option value="0">Select message status</option>
                            <option v-for="(status,index) in statuses" :key="index" :value="status.id">{{status.name}}</option>
                        </select>
                    </div>
                    <button @click="send()" class="btn btn-success btn-sm">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            messages:[],
            statuses:[],
            reaction:{message:'',status:0,title:"Response"}
        }
    },
    methods:{
        send(){
            this.$store.dispatch('post',{
                endpoint:'/leffit/crm/send-message-to-contact/'+this.$route.params.id,
                details:this.reaction
            })
            .then((data)=>{
                console.log(data); 
            })
            .catch((error)=>{
                console.log(error);
            }) 
        }, 
        readMessage(){
            this.$store.dispatch('get','/leffit/crm/read-message/'+this.$route.params.id); 
        },
        getMessage(){
            this.$store.dispatch('get','/leffit/crm/get-message/'+this.$route.params.id)
            .then((data)=>{
                console.log(data);
                this.messages=data.data;
                if(this.messages.length > 0){
                    if(this.messages[0].message_status_id == 1){
                        this.readMessage();
                    }
                }
            })
            .catch((error)=>{
                console.log(error);
            }) 
        },
        getStatuses(){
            this.$store.dispatch('get','/leffit/crm/messagestatus')
            .then((data)=>{
                console.log(data);
                this.statuses=data.data.data; 
            })
            .catch((error)=>{
                console.log(error);
            })
        },
    },
    created(){
        this.getMessage();
        this.getStatuses();
    }
}
</script>