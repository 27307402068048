var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tools",staticStyle:{"min-width":"225px !important","max-width":"225px !important"}},[_c('div',{staticClass:"collaps",staticStyle:{"overflow":"auto"}},_vm._l((_vm.menu),function(page,index){return _c('div',{key:index},[(page.children.length == 0)?_c('router-link',{key:index,staticClass:"menu",class:{ active: _vm.isActive([], page.url) },attrs:{"to":page.url}},[_c('div',{staticClass:"ina"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(page.title))])])]):(page.children.length > 0)?_c('a',{staticClass:"menu",class:{
          active:
            _vm.isActive(page.children, '') ||
            _vm.$route.name == 'product_filter_item_page',
        },staticStyle:{"padding-bottom":"0px"},attrs:{"data-toggle":"collapse","href":'#menuCollapse_' + index}},[_c('div',{staticClass:"ina"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(page.title))]),(page.children.length > 0)?_c('i',{staticClass:"fa fa-chevron-down colp-icon"}):_vm._e()])]):_vm._e(),_c('div',{class:page.children.find(function (d) { return d.url == _vm.$route.path; }) ||
          _vm.$route.name == 'product_filter_item_page'
            ? 'collapse show'
            : 'collapse',attrs:{"id":'menuCollapse_' + index}},[_c('div',_vm._l((page.children),function(childpage,cindex){return _c('router-link',{key:cindex,class:{
              submenu: true,
              active: _vm.isActive([], childpage.url),
            },attrs:{"to":childpage.url}},[_c('div',{staticClass:"ina"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(childpage.title))])])])}),1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }