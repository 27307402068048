<template>
    <div class="row">
        <div class="col-12"> 
                <div class="row">
                    <div class="form-group col-12">
                        <label for="">Code</label>
                        <input disabled="true" :value="voucher.code" type="text" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="">Starts At</label>
                        <input v-model="voucher.starts_at" type="datetime-local" class="form-control">
                    </div>
                    <div class="form-group col-6">
                        <label for="">Expires At</label>
                        <input @change="validateEndDate()" v-model="voucher.expires_at" type="datetime-local" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="">Value Type</label>
                        <select v-model="voucher.value_type" id="" class="form-control">
                            <option value="price">Price</option>
                            <option value="percentage">Percentage</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Value</label>
                        <input v-model="voucher.value" type="number" class="form-control" placeholder="Ex. 120">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <select v-model="voucher.validity" class="form-control">
                            <option value="once">Once</option>
                            <option value="unlimited">Unlimited</option>
                            <option value="products">Products</option>
                            <option value="users">Users</option>
                        </select>
                    </div>
                    <div v-if="voucher.validity == 'products'" class="col-12">
                        <multiselect  track-by="id" :name="'prods'" label="name" v-model="selected_products" :options="products.map((d)=>{ return {name:d.name,id:d.id};})" :multiple="true" >
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{option.name}}</strong></template>
                        </multiselect>
                    </div> 
                    <div v-if="voucher.validity == 'users'" class="col-12">
                        <multiselect  track-by="id" :name="'prods'" label="name" v-model="selected_contacts" :options="contacts.map((d)=>{ return {name:d.firstname+' '+d.lastname,id:d.id};})" :multiple="true" >
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{option.name}}</strong></template>
                        </multiselect>
                    </div> 
                    <div class="col-12">
                        <button @click="update()" type="button" class="btn btn-success">UPDATE</button>
                    </div>
                </div> 
        </div>
        <div class="col-12 mt-4">
            <div class="card">
                <div class="card-header">
                    <h5>Send Voucher</h5>
                </div>
                <div class="card-body">
                    <multiselect  track-by="id" :name="'prods'" label="name"  v-model="contact" :options="contacts.map((d)=>{ return {name:d.firstname+' '+d.lastname,id:d.id};})" :multiple="false" >
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{option.name}}</strong></template>
                    </multiselect>
                    <button @click="shareVoucher()" class="btn btn-sm btn-success">
                        <span v-if="!sending_voucher">Share</span>
                        <span v-else>Sending</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data(){
        return {
            voucher:{
                code:'',starts_at:'',expires_at:'',validity:'',value_type:'price'
            },
            selected_products:[],
            products:[],
            contacts:[],
            selected_contacts:[],
            contact:'',
            sending_voucher:false
        }
    },
    computed : {
        ...mapState({
            endpoint:state=>state.leffit.endpoint,
            user:state=>state.leffit.user,
            token:state=>state.leffit.token, 
        }),
    },
    methods:{ 
        validateEndDate(){
            var start=this.voucher.starts_at;
            var expires_at=this.voucher.expires_at;
            
            start=new Date(start).getTime();
            expires_at=new Date(expires_at).getTime();
            var start_date_diff=expires_at - start;  
            if(start_date_diff <= 0){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Time Error",text:"Please select a date beyond start date"});
                this.voucher.expires_at=this.voucher.starts_at;
                return false;
            }
            
            return true;
        },
        shareVoucher(){
            this.sending_voucher=true;
            this.$store.dispatch('post',{endpoint:'/leffit/payments/share-voucher/'+this.contact.id+'/'+this.voucher.id+'?token='+this.token,details:this.voucher})
			.then(data=>{
				// console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Voucher sent",text:'Voucher has been sent successfully'});
                this.contact='';
                this.sending_voucher=false;
			}).catch((error)=>{
                console.log(error);
                this.sending_voucher=false;
			});
        },
        update(){
            if(this.voucher.code.length == 0 || this.voucher.starts_at.length == 0 || this.voucher.expires_at.length == 0 || this.voucher.validity.length == 0 ){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            if(this.voucher.validity == 'products'){
                this.voucher.data=this.selected_products.map((d)=> d.id);
            }
            else if(this.voucher.validity == 'users'){
                this.voucher.data=this.selected_contacts.map((d)=> d.id);
            }

            if(!this.validateEndDate()){
                return false;
            }
            if(this.voucher.metadata != null){
                var metadata=JSON.parse(this.voucher.metadata);
                metadata.data=this.voucher.data;
                this.voucher.metadata=JSON.stringify(metadata);
            }

            this.$store.dispatch('post',{endpoint:'/leffit/payments/update-voucher/'+this.voucher.id+'?token='+this.token,details:this.voucher})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Voucher created",text:'Voucher was created successfully'});
                
			}).catch((error)=>{
				console.log(error);
			});
        },
        getVoucher(){
             this.$store.dispatch('get','/leffit/payments/vouchers/'+this.$route.params.id)
            .then(data=>{
                console.log(data);
                this.voucher=data.data.data;
                this.voucher.starts_at=this.voucher.starts_at.replace(' ','T');
                this.voucher.expires_at=this.voucher.expires_at.replace(' ','T');
                
                var meta=JSON.parse(this.voucher.metadata);
                this.voucher.validity=meta.validity;
                this.voucher.value_type=meta.value_type;
                this.voucher.value=meta.value;
                if(this.voucher.validity == 'products'){
                    this.selected_products=this.products.filter((d)=> meta.data.find((e)=> e == d.id));
                }
                if(this.voucher.validity == 'users'){
                    this.selected_contacts=this.contacts.filter((d)=> meta.data.find((e)=> e == d.id)).map((contact)=> {return {id:contact.id,name:contact.firstname+' '+contact.lastname}});
                }
                console.log(this.selected_contacts);
            }).catch((error)=>{
                window.console.log(error);
            });
        }
    },
    created(){
        this.$store.dispatch('get','/leffit/products/products/cache')
        .then(data=>{
            console.log(data);
            this.products = data.data.data;
        }).catch((error)=>{
            window.console.log(error);
        });

        this.$store.dispatch('get','/leffit/crm/contacts')
        .then(data=>{
            // console.log(data);
            this.contacts = data.data.data;
            this.getVoucher();
        }).catch((error)=>{
            window.console.log(error);
        });

       
    }

}
</script>