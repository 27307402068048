<template>
	<div class="d-flex flex-column flex-lg-row" id="mainBody">
		<sidebarInnerHardcoded :menu="menu" />
		<div style="width: 100% !important" class="mainPanel">
			 <div class="textEditor form-group">

          <label for="">{{$t('leffit.emails.template_name')}}</label>
          <input autocomplete="false" v-model="templatefield.name" type="text" class="form-control"   />
        </div>

        <div class="textEditor form-group">
          <label for="">{{$t('leffit.emails.subject')}}</label>
          <input autocomplete="false" v-model="templatefield.subject" type="text" class="form-control"   />
        </div>
        <div class="textEditor form-group">

          <label for="">{{$t('leffit.emails.identifier')}}</label>
          <input autocomplete="false" v-model="templatefield.identifier" type="text" class="form-control"   />
        </div>

    <div  class="textEditor">
      <label for="">{{$t('leffit.emails.description')}}</label>
      <tinymce-editor  v-model="templatefield.content" class="form-control"   :init="{height: 400, force_br_newlines:true}" plugins="lists" toolbar="undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist">
      </tinymce-editor>
      </div>
 <button @click="saveToServer()"  class="btn btn-success"><i class="fa fa-save"></i> {{$t('leffit.emails.submit')}}</button>
		</div>
	</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import sidebarInnerHardcoded from './../../../layout/components/sidebar_inner_hardcoded';
import { mapState } from "vuex";
export default {
  	name: 'index',
  	components: {
      sidebarInnerHardcoded,
      'tinymce-editor': Editor
    },
computed:{
            ...mapState({
               mailtemplate:state=>state.leffit.mailtemplate


            })
        },
  	data () {
    	return {
        menu : [
          {
            title   : this.$t('leffit.email.templates'),
            url   : '/admin/email/',
            children : [],
          },

          {
            title   : this.$t('leffit.email.add'),
            url   : '/admin/email/template',
            children : [],
          },
        ],
       templatefield:{name:'',subject:'',content:'',identifier:''},


    	}
  	},
    methods:{
      saveToServer(){

                let template=this.templatefield;
                // let sections=JSON.stringify(this.templatefield);
                // template.content=sections;

                this.$store.state.leffit.loader=true;
                console.log(template.name);
                let content= template.content;
                // return false;
                this.$store.dispatch('post',{
                  endpoint:'/lefficient/leffit/mailtemplates/',
                  details:{'name':template.name,'identifier':template.identifier,'subject':template.subject,'content':content},
                notification:{title:'Mail Template',text:'Mail template created.',type:'success'}})
                .then(data=>{
                    console.log(data);
                    this.success=true;
                    this.$store.state.leffit.loader=false;
                    this.$store.state.leffit.changesMade=false;

                })
                .catch(error=>{
                    this.$store.state.leffit.formErrors.push('Server error!!');
                    console.log(error.request);
                    this.$store.state.leffit.loader=false;
                });
            },
    }

}
</script>

<style lang="css" scoped>
</style>
