<template>
	<div v-if="loaded">
		<div class="head row" v-if="ordertype.name">
            <h3 class="pl-3">{{ $t(ordertype.name) }}</h3>
            <div class="col-12">
                <router-link :to="create_url+'/create'" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> {{ $t(ordertype.name) }} </router-link>
                <!-- <a @click="exportproduct('orders')" href="javascript:void(0);" class="btn btn-primary btn-sm"><i class="fa fa-export"></i> Export </a> -->
            </div>
        </div>
        <order-table :pagedata="pagedata" :ordertype_id="ordertype_id" :order_url="create_url"></order-table>
        <pagination
            :limit="2"
            :data="pagedata"
            @pagination-change-page="getOrdertype"
        ></pagination>
	</div>
</template>

<script>
import table from '../../orders/order/table.vue';
import axios from 'axios';
export default {
  	name: 'productFilters',
    props : {
        ordertype_id : Number,
        create_url : String,
    },
    components:{'order-table':table},
  	data () {
    	return { 
            pagedata : [],
            ordertype : {   
                settings : {
                    relationdetails:false,
                    shippingdetails:false,
                    orderdetails:{},
                    buttons:[],
                    orderrow:[],
                    orderrow_extra:[],
                }
            },
            ordertype_name : '',
            order_url : '',
            loaded:false
    	}
    },
    methods:{
        deleteItem(id){
            this.pagedata=this.pagedata.filter((dt)=> dt.id != id);
      			this.$store.dispatch('delete','/leffit/orders/orders/'+id)
      			.then(data=>{
      				console.log(data);
      				this.$store.dispatch('makeNotification',{title:"ITEM DELETED",text:'Order was removed successfully',type:'success'});
      			});
        },
        exportproduct(tablename) {
            axios.get(process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+"&order_type="+this.ordertype_id,
                {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
                }
            )
            .then(result => {
                const type = result.headers['content-type']
                const blob = new File([result.data],{type: 'application/octet-stream'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'orders.xlsx'
                link.click(); 
                    var token = JSON.parse(localStorage.getItem('token'));
                    // window.location.href = process.env.VUE_APP_API_BASE_URL+'/lefficient/leffit/export/?model='+tablename+'&colname='+this.tablefield+'&token='+token;
                                        
                    })
                .catch(error => {
                    console.log(error.request);
                        if(error.request.status == 500){
                        this.$store.dispatch('makeNotification',{title:'Server error',text:'Unable to export server error occured!',type:'danger'});
                        }
            });
        },
        getOrdertype(page = 1){
            this.$store.dispatch('get','/leffit/orders/orders/ordertype_id/'+this.ordertype_id+'?page='+page)
            .then(data=>{
                this.loaded = true
                this.pagedata = data.data;
                console.log(data);
            }).catch(error=>{
                console.log(error.request);
            });
        }
    }, 
    mounted(){   
        this.$store.dispatch('get','/leffit/orders/order-types/'+this.ordertype_id)
        .then(data=>{
            this.ordertype = data.data.data;
            window.console.log(data);
            this.ordertype.settings = JSON.parse(this.ordertype.settings);
        }).catch(error=>{
            console.log(error);
        });
        this.getOrdertype()
    }

}
</script>

<style lang="css" scoped>
</style>