<template>
  <div class="py-3">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Pricat Type</label>
              <select
                class="form-control"
                v-model="pricat_option"
                @change="onChangePricatOption"
              >
                <option value="add">Toevoegen</option>
                <option value="update">Update</option>
                <option value="delete">Verwijderen</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 45px">
      <div class="col-md-2" v-if="selectedProducts.length != 0">
        <button @click="pricatExport()" class="btn btn-success">
          {{ pricatExportBtnLabel }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table" v-if="pricat_option == 'update'">
          <tr>
            <th>Kortingpercentage %</th>
            <td colspan="2">
              <input
                v-model="promotion_discount"
                type="number"
                class="form-control"
              />
            </td>
          </tr>
          <tr>
            <th>Begindatum</th>
            <td colspan="2">
              <input v-model="start_date" type="date" class="form-control" />
            </td>
          </tr>
          <tr>
            <th>Einddatum</th>
            <td colspan="2">
              <input v-model="end_date" type="date" class="form-control" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="container">
      <div class="row card">
        <div class="col-md-12" v-if="!orgLoading && !filterLoading">
          <div class="form-group mb-0">
            <label>Leverancier</label>
            <div class="d-flex align-items-center">
              <v-select
                :reduce="(org) => org.id"
                v-model="organisation"
                label="name"
                :options="organisations"
                class="w-100"
              ></v-select>
              <div>
                <button
                  class="btn btn-success px-4"
                  @click="getOrganizationProduct()"
                >
                  <span v-if="!addLoading">{{
                    $t("leffit.products.filter.add")
                  }}</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <label>Filter</label>
            <div class="d-flex align-items-center">
              <v-select
                :reduce="(dt) => dt.id"
                v-model="filter"
                label="name"
                :options="data"
                class="w-100"
              ></v-select>
              <div>
                <button
                  class="btn btn-success px-4"
                  @click="getFilterProducts()"
                >
                  <span v-if="!filterProductsLoading">{{ $t("leffit.products.filter.add") }}</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <label>Producten</label>
            <div class="d-flex align-items-center">
              <v-select
                :reduce="(product) => product.product_id"
                v-model="fetchProduct"
                label="name"
                :options="fetchProducts"
                class="w-100"
              >
                <template slot="option" slot-scope="option">
                  {{ option.casa_productcode }} - {{ option.name }}
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.casa_productcode }} - {{ option.name }}
                </template>
              </v-select>
              <div>
                <button class="btn btn-success px-4" @click="addFromProducts()">
                  <span>{{ $t("leffit.products.filter.add") }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <label>Seizoenscode</label>
            <div class="d-flex align-items-center">
              <v-select
                class="w-100"
                :reduce="(org) => org.casa_seizoenscode"
                v-model="casa_sd"
                label="casa_seizoenscode"
                :options="prods"
              ></v-select>
              <div>
                <button
                  class="btn btn-success px-4"
                  @click="seizoenscodeFilter()"
                >
                  <span>{{ $t("leffit.products.filter.add") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="py-5" style="text-align: center">LOADING...</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table">
          <tr>
            <th>Naam</th>
            <!-- name -->
            <th>Prijs</th>
            <!-- price -->
            <th>Prijs na korting</th>
            <!-- price after discount -->
            <th>Begindatum</th>
            <!-- date start -->
            <th>Einddatum</th>
            <!-- date end -->
            <th>
              <button class="btn btn-primary" @click="pricatExport()">
                <span v-if="!exportLoader">Download</span>
                <span v-else>DOWNLOADING...</span>
              </button>
            </th>
          </tr>
          <tr>
            <td colspan="6">
              <v-select
                style="100%"
                :reduce="(dt) => dt.id"
                v-model="sort"
                label="name"
                :options="filters"
                @input="sortSelectedProducts()"
              ></v-select>
            </td>
          </tr>
          <tr v-for="(sp, index) in products" v-bind:key="sp.id">
            <td>{{ sp.name }}</td>
            <td>
              <span v-if="sp.price">{{ parseFloat(sp.price).toFixed(2) }}</span>
            </td>
            <td>
              <span v-if="sp.dis_price">{{
                parseFloat(sp.dis_price).toFixed(2)
              }}</span>
            </td>
            <td>{{ sp.start_date }}</td>
            <td>{{ sp.end_date }}</td>
            <td>
              <button @click="removeProductTable(index)" class="btn btn-danger">
                <i class="fa fa-trash-o"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Table from "../../crm/addresses/table.vue";
export default {
  data() {
    return {
      sort: "",
      casa_sd: "",
      organisations: [],
      organisation: "",
      products: [],
      addLoading: false,
      orgLoading: false,
      filterProductsLoading:false,
      exportLoader: false,

      fetchProducts: [],
      fetchProduct: "",

      data: [],
      filters: [],
      filter: "",
      filterLoading: false,

      selectedProducts: [],
      formatProducts: [],
      product_id: "",

      pricatExportBtnLabel: "Export",
      pricatAddBtnLabel: "Add",

      exportProducts: [],
      pricat_option: "add",
      start_date: "",
      end_date: "",
      alreay_exist: false,

      promotion_discount: 0,
      selectedFilter: "",
      prods: [],
      allProducts: [],
    };
  },
  methods: {
    sortSelectedProducts() {
      var fp = this.filters.filter((filter) => filter.id == this.sort);
      this.products = fp[0].products.filter((prod) =>
        this.products.filter((prod2) => prod2.product_id == prod.product_id)
      );
    },
    seizoenscodeFilter() {
      this.products = this.allProducts.filter((item) => {
        return this.casa_sd == item.casa_seizoenscode;
      });
    },
    getAllProducts() {
      this.$store
        .dispatch("get", "/leffit/products/select2-products")
        .then((resp) => {
          resp.data.data.forEach((item) => {
            item.product_id = item.id;
          });

          var produs = [...resp.data.data];
          this.allProducts = [...resp.data.data];

          this.fetchProducts = resp.data.data;
          // console.log(this.fetchProducts)
          this.prods = produs.filter(
            (item, index) =>
              produs.findIndex(
                (obj) => obj.casa_seizoenscode === item.casa_seizoenscode
              ) === index
          );
          this.prods = this.prods.sort(
            (a, b) => (a.casa_seizoenscode > b.casa_seizoenscode && 1) || -1
          );
        });
    },
    addFromProducts() {
      if (this.fetchProduct.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product field is required",
          type: "danger",
        });
        return false;
      }
      let prods = [];
      if (this.products.find((prd) => prd.id == this.fetchProduct)) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Product has been added already",
          type: "danger",
        });
        return false;
      }
      var selected_filter = this.fetchProducts.find(
        (value) => value.id == this.fetchProduct
      );
      if (this.pricat_option == "update") {
        selected_filter.dis_price =
          selected_filter.price -
          (this.promotion_discount / 100) * selected_filter.price;
        selected_filter.start_date = this.start_date;
        selected_filter.end_date = this.end_date;
      }

      this.products.push(selected_filter);
      // console.log(this.products)
    },

    getOrganizationProduct() {
      if (this.organisation.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Supplier field is required",
          type: "danger",
        });
        return false;
      }
      this.addLoading = true;
      this.$store
        .dispatch(
          "get",
          "/leffit/crm/get-organization-product/" + this.organisation
        )
        .then((resp) => {
          this.addLoading = false;
          resp.data.forEach((item) => {
            item.product_id = item.id;
            if (this.pricat_option == "update") {
              item.dis_price =
                item.price - (this.promotion_discount / 100) * item.price;
              item.start_date = this.start_date;
              item.end_date = this.end_date;
            }
          });

          var temp = [...this.products];
          resp.data.forEach((product) => {
            if (!this.products.find((p) => p.id == product.id)) {
              temp.push(product);
            }
          });
          this.products = [];
          this.products = temp;
          // this.products.push(...resp.data)
          // console.log(this.products)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOrganisations() {
      this.orgLoading = true;
      this.$store
        .dispatch("get", "/leffit/crm/get-organizations-product")
        .then((resp) => {
          this.orgLoading = false;
          this.organisations = resp.data.data;
          // console.log(this.organisations)
        })
        .catch((error) => {
          this.orgLoading = false;
          console.log(error);
        });
    },

    getFilters() {
      this.filterLoading = true;
      this.$store
        .dispatch("get", "/leffit/products/productfilters/all")
        .then((data) => {
          this.filterLoading = false;
          this.filters = data.data.data;
          this.filters.forEach((filter) => {
            if (filter.parent_id != 0) {
              var hold = {};
              hold.name = filter.name;
              hold.id = filter.id;
              hold.second = "";
              hold.third = "";
              var parent = this.filters.find(
                (parent) => parent.id == filter.parent_id
              );
              // hold.second = parent.name
              if (parent) {
                hold.name = parent.name + " - " + hold.name;
                if (parent.parent_id != 0) {
                  var parent_p = this.filters.find(
                    (parent_p) => parent_p.id == parent.parent_id
                  );
                  // hold.third = parent_p.name
                  hold.name = parent_p.name + " - " + hold.name;
                }
              }

              this.data.push(hold);
            }
          });
          // console.log(this.filters)
          // console.log(this.data)
        });
    },

    getFilterProducts() {
      this.filterProductsLoading = true;
      this.$store
        .dispatch(
          "post",
          {
            "details":{'filter_id':this.filter},
            "endpoint":"/leffit/products/get-product-by-filter"
          }
        )
        .then((resp) => {
          this.addLoading = false;
          resp.data.forEach((item) => {
            item.product_id = item.id;
            if (this.pricat_option == "update") {
              item.dis_price = item.price - (this.promotion_discount / 100) * item.price;
              item.start_date = this.start_date;
              item.end_date = this.end_date;
            }
          });

          var temp = [];
          resp.data.forEach((product) => {
            if (!this.products.find((p) => p.id == product.id)) {
              temp.push(product);
            }
          });
          this.products = [];
          this.products = temp; 
          this.filterProductsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.filterProductsLoading = false;
        }); 
        
    },

    addProductTable() {
      if (this.selectedFilter == "") {
        this.$store.dispatch("makeNotification", {
          title: "Server error",
          text: "Please select product filter!",
          type: "danger",
        });
        return false;
      }

      this.pricatAddBtnLabel = "Adding...";
      this.selectedProducts = [];
      this.$store
        .dispatch("post", {
          endpoint: "/leffit/products/get-product-by-filter",
          details: {
            pricat_option: this.pricat_option,
            filter_id: this.selectedFilter,
            promotion_discount: this.promotion_discount,
            start_date: this.start_date,
            end_date: this.end_date,
          },
        })
        .then((data) => {
          this.selectedProducts = data.data;
          this.pricatAddBtnLabel = "Add";
          // formate product
          this.formatProducts = [];
          for (let i = 0; i < this.selectedProducts.length; i++) {
            this.formatProducts[
              this.selectedProducts[i]["id"]
            ] = this.selectedProducts[i];
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    removeProductTable(pindex) {
      this.products.splice(pindex, 1);
    },

    async pricatExport() {
      if (this.pricat_option.length < 1) {
        this.$store.dispatch("makeNotification", {
          title: "Error",
          text: "Pricate type field is required",
          type: "danger",
        });
        return false;
      }
      let payload = {};
      payload.type = this.pricat_option;

      payload.products = this.products.map((product) => {
        return {
          id: product.product_id,
          price: product.price,
          discount: product.dis_price,
          start_date: product.start_date,
          end_date: product.end_date,
        };
      });

      this.exportLoader = true;
      // console.log(payload);
      var response = await axios.post(
        process.env.VUE_APP_API_BASE_URL +
          "/lefficient/leffit/export?model=pricat-export",
        payload,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status == 200) {
        // console.log(response);
        this.exportLoader = false;
        const type = response.headers["content-type"];
        const blob = new File([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var current = new Date();
        var payloadName = {
          add: "Toevoeg",
          update: "Update",
          delete: "Verwijder",
        };
        link.download =
          payloadName[payload.type] +
          "_" +
          "Pricat" +
          "_" +
          current.getFullYear() +
          "_" +
          (current.getMonth() + 1) +
          "_" +
          current.getDate() +
          ".csv";
        link.click();

        this.$store.dispatch("displayNotification", {
          type: "success",
          id: Math.round(Math.random() * 100),
          message: "Exported successfully.",
          interval: 5000,
          autoDismiss: true,
        });
      } else {
        if (response.status == 500) {
          this.$store.dispatch("makeNotification", {
            title: "Server error",
            text: "Unable to export server error occured!",
            type: "danger",
          });
        }
      }
    },

    // pricatExport(){
    //     if(this.selectedProducts.length==0){
    //        this.$store.dispatch('makeNotification',{title:'Server error',text:'Please select product!',type:'danger'});
    //        return false;
    //     }

    //     this.pricatExportBtnLabel = 'Exporting...';
    //        this.$store.dispatch('post',{endpoint:'/lefficient/leffit/pricat-export',
    //        details:{
    //           pricat_option: this.pricat_option,
    //           products: this.selectedProducts,
    //        }})
    //     .then(data=>{
    //        console.log(data);
    //        this.pricatExportBtnLabel = 'Export';
    //        this.exportProducts = data.data.data;
    //        this.csvExport(this.exportProducts);
    //     }).catch((error)=>{
    //        window.console.log(error);
    //     })
    // },

    // csvExport(arrData){
    //     const date = new Date();
    //     let month = parseInt(date.getMonth()) + 1;  // month starts from zero
    //     let file_name = date.getFullYear()+''+month+''+date.getDate()+'_'+this.pricat_option;

    //       let csvContent = "data:text/csv;charset=utf-8,";
    // 	   csvContent += [
    // 		    Object.keys(arrData[0]).join(";"),
    // 		    ...arrData.map(item => Object.values(item).join(";"))
    // 	   ]
    // 		.join("\n")
    // 		.replace(/(^\[)|(\]$)/gm, "");

    // 	   const data = encodeURI(csvContent);
    // 	   const link = document.createElement("a");
    // 	   link.setAttribute("href", data);
    // 	   link.setAttribute("download", file_name+".csv");
    // 	   link.click();

    // },

    onChangePricatOption() {
      this.start_date = "";
      this.end_date = "";
      this.promotion_discount = "";
    },
    updateProducts() {
      this.products = this.products.map((p) => {
        let coef =
          this.promotion_discount == "" ? 0 : this.promotion_discount / 100;
        p.dis_price = p.price - coef * p.price;
        p.start_date = this.start_date;
        p.end_date = this.end_date;
        return p;
      });
    },
  },
  created() {
    this.getOrganisations();
    this.getFilters();
    this.getAllProducts();
  },
  mounted() {
    var self = this;
    $(".product-select").select2();

    $(".product-select").on("select2:select", function(e) {
      var data = e.params.data;
      self.selectedFilter = data.id;
    });

    // // ======== get all filters ================
    //  axios.get(process.env.VUE_APP_API_BASE_URL+'/leffit/products/productfilters/all',{
    //         headers: {'Content-Type': 'application/json'}
    //  }).then(function (response) {
    //     // handle success
    //     self.filters = response.data.data;
    //  })
  },
  watch: {
    promotion_discount(value) {
      this.updateProducts();
    },
    start_date(value) {
      this.updateProducts();
    },
    end_date(value) {
      this.updateProducts();
    },
  },
};
</script>
<style scoped>
.upload-container {
  height: 400px;
}
</style>
