<template>
    <div v-if="this.templateModal" id="templateModal">
        <div class="head">
            <h3>Choose Template</h3>

            <div class="search"><input type="text" class="form-control" placeholder="Search template by name"></div>
            <div class="remove"><button @click="removeModal()" class="btn"><i class="fa fa-remove"></i></button></div>
        </div>
        <hr>
        <div class="body">
            <div class="temps">
                <div @click="selectTemp(temp.id)" v-for="(temp,index) in this.templateViews" :key="index" class="temp">
                    <div class="img" >
                        <div class="preview" >
                            <div class="previewContainer"
                             v-html="temp.content.split('++').map((tct)=>{ 
                                for(let c=0;c<temp.fields.length;c++){
                                    tct=tct.replace('{{'+temp.fields[c].element+'}}',temp.fields[c].content)
                                }
                                return tct;
                             }).join('')"
                             ></div>
                        </div>
                    </div>
                    <h5>{{temp.title}}</h5>
                    <p class="m-0">
                        {{temp.text}}
                    </p>
                </div>  
            </div>
        </div>
    </div>
</template>

<script> 
import { mapState} from "vuex";
export default {
    name:"mailTemplates",
    components:{
        
    },
    computed:{
        ...mapState({
            templateModal:state=>state.leffit.templateModal,
            templateViews:state=>state.leffit.templateViewData
        })
    },
    methods:{
        removeModal(){
            this.$store.state.leffit.templateModal=false;
        },
        selectTemp(id){
            let selected=this.templateViews.find(tp=>tp.id == id);
            selected=JSON.stringify(selected);

            selected=JSON.parse(selected);
            selected.unique_id=Math.round(Math.random() * 9999);
            this.$store.state.leffit.activeSection=selected;
            this.$store.state.leffit.sections.push(selected);
            this.$store.state.leffit.templateModal=false;
 
        }
    },
    created() {
        let button=0;
        
        document.onkeyup=(e)=>{
            button=e.which || e.button; 
            // console.dir(e.target);
            if(button == 27 && this.templateModal && e.target.nodeName != 'INPUT'){
                
                this.$store.state.leffit.templateModal=false;
            }
        }
    },
}
</script>

<style scoped>
   
    #templateModal{
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
        background: white;
        box-shadow: 0px 0px 10px #808080ba;
        width: 95%;
        height: 95%;
        padding: 20px;
        border-radius: 5px;
        z-index: 9999;
    }

    #templateModal .head{
        display: flex;
        justify-content: space-between;
    }
    #templateModal .head h3{
        color: gray;
        font-size: 20px;
    }
    #templateModal .head .search{
        width: 400px;
    }

    #templateModal .temps{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        overflow-y: scroll;
        padding: 10px;
        height:650px;
    }
    #templateModal .temps .temp{
        height: 350px;
        box-shadow: 1px 3px 5px #b8b8b8;
        padding: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        transition: all .2s linear;
        position: relative;
        
    }
    #templateModal .temps .temp:hover{
        box-shadow: 2px 7px 10px lightgray;
        cursor: pointer;
    }
     #templateModal .temps .temp:hover .img{
        transform: scale(0.98);
        border-radius: 2px; 
    }
    #templateModal .temps .temp .img{
        height: 60%;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        transition: all .2s linear;
        text-align: center;
        display: flex;
        justify-content: center;
        background: #333;
        overflow: hidden;
        border: 2px solid #333333;
    }   
    #templateModal .temps .temp .img .preview{
        transform: scale(0.4) !important;
    }
    .previewContainer{
        background: white;
        padding: 25px 0px;
    }
    #templateModal .temps .temp h5{
        color: black;
        margin-top: 10px;
        font-size: 15px;
    }
    #templateModal .temps .temp p{
        color: gray;
        font-size: 13px;
        text-align: justify;
    }
</style>
<style  scoped>
    @media (max-width:1000px){
        #templateModal .temps{ 
            grid-template-columns: 1fr 1fr 1fr; 
        }
    }

    @media (max-width:760px){
        #templateModal .temps{ 
            grid-template-columns: 1fr 1fr; 
        }
    }
    @media (max-width:400px){
        #templateModal .temps{ 
            grid-template-columns: 1fr; 
        }
    }
</style>


<style type="text/css" scoped>
   
    .text-align{
        text-align: center;
    }
    #app{ 
        text-align: center;
        font-family: Helvetica;
    }
    #app .holder{
        padding: 0;
    }

    .first{
        border: 1px solid;
        height: 10em;
    }
    #app .first .href{
        height: 100%;
        padding-top: 8em;
    }
    #app .first .href a{
        text-decoration: underline;	
        color: #111;
        font-size: 12px;
    }
    #app .third{ 
        border-bottom: 1px solid black;
        color: #404040;
    }
    #app  .h2{
        color: #E95D70;
        font-weight: bold;
        font-size: 25px;
    }
    #app .p{
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #404040;
    }
    #app .four{
        text-align: left;
        border-bottom: 1px solid #111;
    }

    #app .four .h2{
        color: #E95D70;
        font-weight: bold;
        font-size: 25px;
    } 
    #app .four .p{
        font-size: 15px;
        line-height: 18px !important;
        letter-spacing: 0.3px !important;
    }
    #app .five{
        border-bottom: 1px solid #111;
    }

    #app .five .btn{
        border-radius: 0;
        background-color:  #E95D70;
        color: #fff;
    }
    #app .six{
        border-bottom: 1px solid #111
    }
    #app .six img{
        height:auto !important; 
        width:100% !important;
    }
    #app .six .p{
        margin-top: 15px;
    }
    #app .seven a{
        color: #E95D70;
        text-decoration: underline;
    }
</style>