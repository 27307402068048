<template>
	<div>
		<div class="head row">
        	<h3 class="pl-3">{{ $t('leffit.website.fields') }}</h3>
      	</div>
        <div class="row">
			<a href="#addPageType" data-toggle="modal" class="btn btn-success btn-sm">{{ $t('leffit.website.add_fields') }}</a>
		</div>
      <page-type-table></page-type-table>

      <div class="modal" id="addPageType">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">{{ $t('leffit.website.add_fields') }}</div>
                  <div class="modal-body">
                      <div class="form-group">
                          <label class="col-12 p-0" for="">Field Type</label>
                           <select style="width:100%" class="form-control col-12 select">
                                <option value="">Select field type</option>
                                <option v-for="(fd,index) in fieldtypes" :key="index" :value="fd.id">{{fd.name}}</option>
                            </select> 
                      </div>

                      <div class="form-group">
                          <label for="">Name</label>
                          <input v-model="item.name" type="text" class="form-control">
                      </div>

                      <button @click="create()" class="btn btn-success">{{ $t('leffit.website.create') }}</button>
                  </div>
              </div>
          </div>
      </div>
	</div>
</template>
<script>
import table from './table';
export default {
    components:{
        'page-type-table':table,
        
    },
    data(){
        return {
            item:{
                name:'',fieldtype_id:''
            },
            fieldtypes:[]
        }
    },
    methods:{
        create(){
            if(this.item.name == ''){
                this.$store.dispatch('makeNotification',{type:'danger',title:"Validtion Error",text:'Please fill in the required field'});
                return false;
            }
            
            this.$store.dispatch('post',{endpoint:'/leffit/cms/fields',details:this.item})
			.then(data=>{
				console.log('result',data); 
                this.$store.dispatch('makeNotification',{type:'success',title:"Pagetype created",text:'Pagetype was created successfully'});
                this.item={};
                window.location.href='/admin/website/fields/'+data.data.data.id+'/edit';
			}).catch((error)=>{
				console.log(error);
			});
        }
    },
    mounted(){
        this.$store.dispatch('get','/leffit/cms/fieldtypes')
        .then(data=>{
            console.log(data);
            this.fieldtypes = data.data.data;
            
            var context=this;
            window.$('.select').select2().change(function(){
                context.item.fieldtype_id=window.$(this).val();
            });
        });
    },
}
</script>