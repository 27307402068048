<template>
  <div @scroll="resetLockScreen()"  @click="resetLockScreen()" id="app">
    <router-view />
    <notifications />
    <lockscreenmodal></lockscreenmodal>
  </div>
</template>
<script>
// import sidebar from './layout/components/sidebar';
// import navbar from './layout/components/navbar';
import lockscreenModal from './leffit/lockscreen';
import 'vue-select/dist/vue-select.css';
export default {
  name:'App',
  components:{lockscreenmodal:lockscreenModal},
  data(){
    return {
        lockscreenTime: 100 * 3600,
        error:{
            show:false
        },
        password:''
    }
  },
  created(){

    // this.$store.dispatch('getTranslations');

    if(!this.$store.state.leffit.user.hasOwnProperty('firstname')){
      let user=localStorage.getItem('user');
      let token=localStorage.getItem('token');
      
      if(user != null){
        console.log(user)
        this.$store.state.leffit.user = JSON.parse(user);
        // this.$store.state.leffit.token = JSON.parse(token);
        // this.$store.commit('resetSanctumToken');
        //     this.$store.commit('resetTokens');
      }
    }

    // if(this.$route.meta.AuthRequired){
      // let token=localStorage.getItem('token');
      // if(token != null){
          // token=JSON.parse(token);

          // console.log(accesstoken);
          // this.$store.commit('setTokens',{access_token:accesstoken,refresh_token:refreshtoken});
        // this.$store.commit('resetSanctumToken');
        //     this.$store.commit('resetTokens');
      // }
    // }

    // let accesstoken=localStorage.getItem('access_token');
    // let refreshtoken=localStorage.getItem('refresh_token');
    // if(accesstoken != null && refreshtoken != null){
    //     accesstoken=JSON.parse(accesstoken);
    //     refreshtoken=JSON.parse(refreshtoken);
    //     this.$store.commit('setTokens',{access_token:accesstoken,refresh_token:refreshtoken});

    //     var lastset=localStorage.getItem('access_token_last_set');
    //     var diff=parseInt(Date.now()) - parseInt(lastset);
    //     if(diff >= this.lockscreenTime){
    //         this.$store.state.leffit.lockscreen.show=true;
    //     }

          // var lastset=localStorage.getItem('access_token_last_set');
          // var diff=parseInt(Date.now()) - parseInt(lastset);
          // if(diff >= this.lockscreenTime){
          //     this.$store.state.leffit.lockscreen.show=true;
          // }

          // setInterval(()=>{
          //     if(!this.$store.state.leffit.lockscreen.show){
          //         // this.$store.dispatch('refreshToken');
          //     }
          // },this.lockscreenTime);

          // this.resetLockScreen();
      // }
    // }
  },
  mounted(){
    this.resetLockScreen();
    // Call this when reload any route
    this.checkTokenExpiration();
  },
  methods:{
    resetLockScreen(){

      let reload;

        if(!this.$route.meta.AuthRequired){
            this.$store.state.leffit.lockscreen.show = false;
            return false;
        }

        if(localStorage.getItem('lockscreen') == 'true') {
          this.$store.state.leffit.lockscreen.show=true;
          clearTimeout(reload);
          return true;
        }

        // reload = setTimeout(()=>{

        //     // var lockscreenTime=localStorage.getItem('lockscreenTime');
        //     // var diff=parseInt(Date.now()) - parseInt(lockscreenTime);

        //     // let current = new Date();

        //     console.log(localStorage.getItem('lockscreenTime'));
        //     console.log((new Date()).getTime());


        //     if((new Date()).getTime() >= localStorage.getItem('lockscreenTime')) {
        //       localStorage.setItem('lockscreen', 'true');
        //       this.$store.state.leffit.lockscreen.show=true;
        //       clearTimeout(reload);
        //     }

        //     // if(diff >= this.lockscreenTime){
        //     //     this.$store.state.leffit.lockscreen.show=true;
        //     // }

        //     if(!this.$store.state.leffit.lockscreen.show){
        //       this.resetLockScreen();
        //     }

        // }, 1000);
    },
    checkTokenExpiration() {

      if(this.$route.meta.AuthRequired) {

        setTimeout(() => {

            if((new Date()).getTime() >= localStorage.getItem('token_expiry')) {
              this.$store.dispatch('revokeToken', { token: localStorage.getItem('token') });
            }else {
              this.checkTokenExpiration();
            }

        }, 1000);
      }
    }
  }
}
</script>
<style>
 
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style scoped>


</style>

