<template>
  <div v-if="loaded">
    <div class="col-12 pl-0">
      <a
        href="javascript:void(0);"
        @click="exportData()"
        class="btn btn-primary btn-sm"
        ><i data-v-a062e5ae="" class="fa fa-export"></i> Export
      </a>
    </div>
    <table class="table table-responsive table-hover">
      <thead>
        <tr>
          <th></th>
          <th>Producten</th>
          <th>Aantal</th>
          <th>Magazijnen</th>
          <th>{{ $t("leffit.products.stock.voorraadtype") }}</th>
          <th>Verwachte binnenkomst</th>
        </tr>
        <tr>
          <th>
            <a @click="searchTable()" class="btn btn-primary"
              ><i class="fa fa-search"></i
            ></a>
          </th>
          <th>
            <input type="text" class="form-control" v-model="search.product" />
          </th>
          <th>
            <input type="text" class="form-control" v-model="search.amount" />
          </th>
          <th>
            <!-- <input type="text" class="form-control" v-model="search.warehouse" /> -->
            <Select2
              v-model="search.warehouse"
              :options="
                warehouses.map((d) => {
                  return { id: d.id, text: $t(d.name) };
                })
              "
            ></Select2>
          </th>
          <th>
            <Select2
              v-model="search.stocktype"
              :options="
                stocktypes.map((d) => {
                  return { id: d.id, text: $t(d.name) };
                })
              "
            ></Select2>
          </th>
          <th></th>
          <!-- <th>{{$t('leffit.products.stock.voorraadtype')}}</th>
                <th>Verwachte binnenkomst</th>  -->
        </tr>
      </thead>
      <tbody>
        <tr :key="index" v-for="(item, index) in pagedata.data">
          <td></td>
          <td>
            <span v-if="item.product_name">{{ item.product_name }}</span>
          </td>
          <td>{{ item.amount }}</td>
          <td>
            <span v-if="item.warehouse_name">{{ item.warehouse_name }}</span>
          </td>
          <td>
            <span v-if="item.stocktype_name">{{
              $t(item.stocktype_name)
            }}</span>
          </td>
          <td>
            <span v-if="item.datetime">{{ item.datetime }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :limit="2"
      :data="pagedata"
      @pagination-change-page="searchTable"
    ></pagination>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pagedata: [],
      attributes: ["ean", "name", "producttype", "stock"],
      search: {
        product: "",
        stocktype: "",
        warehouse: "",
        amount: "",
      },
      stocktypes: [],
      warehouses: [],
      loaded: false,
    };
  },
  methods: {
    convertFloat(input) {
      if (input) {
        return parseFloat(input);
      } else {
        return 0;
      }
    },
    translate(input) {
      if (input) {
        if (input.constructor === String) {
          if (input.indexOf("leffit.products") !== -1) {
            return this.$t(input);
          } else {
            return input;
          }
        }
      }
      return input;
    },
    searchTable(page = 1) {
      this.$store
        .dispatch("post", {
          details: this.search,
          endpoint: "/leffit/products/stocks/search?page=" + page,
        })
        .then((data) => {
          // console.log(data);
          this.loaded = true;
          this.pagedata = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportData() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/lefficient/leffit/export?model=stocks&amount=
            ${this.search.amount}&product=${this.search.product}&
            stocktype=${this.search.stocktype}&warehouse=${this.search.warehouse}`,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          const type = result.headers["content-type"];
          const blob = new File([result.data], {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "stocks.xlsx";
          link.click();
          var token = JSON.parse(localStorage.getItem("token"));
        })
        .catch((error) => {
          console.log(error.request);
          if (error.request.status == 500) {
            this.$store.dispatch("makeNotification", {
              title: "Server error",
              text: "Unable to export, server error occured!",
              type: "danger",
            });
          }
        });
    },
    getWarehouses() {
      this.$store
        .dispatch("get", "/leffit/products/warehouses")
        .then((data) => {
          this.warehouses = data.data.data;
          // console.log(data)
        });
    },
  },
  mounted() {
    this.searchTable();
    this.getWarehouses();
    this.$store.dispatch("get", "/leffit/products/stocktypes").then((data) => {
      this.stocktypes = data.data;
    });
  },
};
</script>

<style lang="css" scoped></style>
