<template>
  <div>
    <div class="head row">
      <h3 class="pl-3">{{ $t("leffit.crm.addresses") }}</h3>
      <!-- <div class="col-12 p-0 m-0">
            <button data-target="#addnew" data-toggle="modal" class="btn btn-success btn-sm">Add New Address</button>
          </div> -->
    </div>
    <address-table></address-table>
    <div class="modal" tabindex="-1" role="dialog" id="addnew">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Contact</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit="updateItem($event)">
              <div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_name">{{
                      $t("leffit.crm.organization.name")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_name"
                      v-model="item.name"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_address">{{
                      $t("leffit.crm.organization.address")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_address"
                      v-model="item.address"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_address2">{{
                      $t("leffit.crm.organization.address2")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_address2"
                      v-model="item.address2"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-4">
                    <label for="organization_zipcode">{{
                      $t("leffit.crm.organization.zipcode")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_zipcode"
                      v-model="item.zipcode"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_city">{{
                      $t("leffit.crm.organization.city")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_city"
                      v-model="item.city"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="contact_country">{{
                      $t("leffit.crm.contact.country")
                    }}</label>
                    <select
                      style="position:relative;width:100%"
                      class="form-control country"
                      id="contact_country"
                    >
                      <option
                        selected
                        :key="index"
                        v-for="(ct, index) in country"
                        :value="ct.code"
                        >{{ ct.name }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_phone">{{
                      $t("leffit.crm.organization.phone")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_phone"
                      v-model="item.phone"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_mobile">{{
                      $t("leffit.crm.organization.mobile")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_mobile"
                      v-model="item.mobile"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_fax">{{
                      $t("leffit.crm.organization.fax")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_fax"
                      v-model="item.fax"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_email">{{
                      $t("leffit.crm.organization.email")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_email"
                      v-model="item.email"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="organization_coc">{{
                      $t("leffit.crm.organization.coc")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_coc"
                      v-model="item.coc"
                    />
                  </div>

                  <div class="form-group col-6">
                    <label for="organization_vat_number">{{
                      $t("leffit.crm.organization.vat_number")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_vat_number"
                      v-model="item.vat_number"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-4">
                    <label for="organization_iban_name">{{
                      $t("leffit.crm.organization.iban_name")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_iban_name"
                      v-model="item.iban_name"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_iban_number">{{
                      $t("leffit.crm.organization.iban_number")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_iban_number"
                      v-model="item.iban_number"
                    />
                  </div>

                  <div class="form-group col-4">
                    <label for="organization_swift_code">{{
                      $t("leffit.crm.organization.swift_code")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_swift_code"
                      v-model="item.swift_code"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="contact_language">{{
                      $t("leffit.crm.contact.language")
                    }}</label>
                    <select
                      style="position:relative;width:100%"
                      class="form-control language"
                      id="contact_language"
                    >
                      <option
                        selected
                        :key="index"
                        v-for="(lg, index) in language"
                        :value="lg.code"
                        >{{ lg.name }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group col-12">
                    <label for="contact_parent_id">{{
                      $t("leffit.crm.contact.parent_id")
                    }}</label>
                    <select
                      style="position:relative;width:100%"
                      class="form-control parent"
                      id="contact_parent_id"
                    >
                      <option
                        selected
                        :key="index"
                        v-for="(lg, index) in pagedata"
                        :value="lg.id"
                        >{{ lg.name }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_remarks">{{
                      $t("leffit.crm.organization.remarks")
                    }}</label>
                    <textarea
                      class="form-control WYSIWYG"
                      id="organization_remarks"
                      v-model="item.remarks"
                    ></textarea>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="organization_status_id">{{
                      $t("leffit.crm.organization.status_id")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="organization_status_id"
                      v-model="item.status_id"
                    />
                  </div>
                </div>

                <div class="row p-0 m-0">
                  <button class="btn btn-success">UPDATE</button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              id="closemodal"
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Close
            </button>
            <button @click="create()" type="button" class="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import language from "../../../data/language";
import country from "../../../data/country";
import table from "./table";
export default {
  name: "index",
  components: { "address-table": table },
  data() {
    return {
      pagedata: [],
      item: {
        name: "orgname",
        address: "address1",
        address2: "address2",
        zipcode: "433f",
        city: "ado",
        country: "",
        phone: "434343",
        mobile: "4343",
        fax: "4343",
        email: "highdee.ai@gmail.com",
        picture: "",
        coc: "",
        vat_number: "",
        iban_name: "",
        iban_number: "",
        swift_code: "",
        language: "",
        remarks: "",
        status_id: "",
        parent_id: "",
        organizationtype_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      language: language,
      country: country,
    };
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.leffit.endpoint,
    }),
  },
  methods: {
    create() {
      this.item.language = document.getElementById("contact_language").value;
      this.item.country = document.getElementById("contact_country").value;
      this.item.parent_id = document.getElementById("contact_parent_id").value;

      this.$store
        .dispatch("post", {
          endpoint: "/leffit/crm/organizations",
          details: this.item,
        })
        .then((data) => {
          this.$store.dispatch("makeNotification", {
            title: "ITEM ADDED",
            text: this.item.name + " was added successfully",
            type: "success",
          });
          this.pagedata.unshift(data.data.data);
          document.getElementById("closemodal").click();
        })
        .catch((error) => {
          console.log(error.request);
        });
    },
    deleteItem(id) {
      this.pagedata = this.pagedata.filter((dt) => dt.id != id);
      console.log(this.pagedata);
      this.$store
        .dispatch("delete", "/leffit/crm/addresses/" + id)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("makeNotification", {
            title: "ITEM DELETED",
            text: this.item.name + " was removed successfully",
            type: "success",
          });
        });
    },
  },
  mounted() {
    window.$(".language").select2();
    window.$(".parent").select2();
    window.$(".country").select2();

    // this.$store.dispatch("get", "/leffit/crm/addresses").then((data) => {
    //   console.log("Addresses: ", data.data);
    //   this.pagedata = data.data.data;
    // });
  },
};
</script>

<style lang="css" scoped></style>
