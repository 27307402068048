<template>
    <div>
        <table class="table table-hover">
                <thead>
                    <tr>
                        <td colspan="3"></td>
                        <td colspan="3">File</td>
                        <td colspan="3">label</td>
                        <td colspan="3">dependency</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td colspan="3"></td>
                        <td colspan="3">
                            <div class="form-group">
                                <input type="file" @change="fileselected()" class="form-control" id="file">
                            </div>
                        </td>
                        <td colspan="3">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="product.label">
                            </div>
                        </td>
                        <td colspan="3">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="product.dependency">
                            </div>
                        </td>
                        <td colspan="3"><div class="form-group col-lg-1"><button @click="addFile()" class="btn btn-sm btn-success" style="margin-top:-5px"><i class="fa fa-plus"></i></button></div></td>
                    </tr>
                    <tr v-if="uploading" class="text-center">
                        <td colspan="15">{{progress}}%</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file,index) in files" :key="index">
                        <td colspan="3">
                            <div class="">
                                <button v-if="index > 0" @click="reorder(index,'up')" class="btn btn-sm btn-success">
                                    <i class="fas fa-chevron-up"></i>
                                </button>
                                <button v-if="index < files.length -1" @click="reorder(index,'down')" class="btn btn-sm btn-warning">
                                    <i class="fas fa-chevron-down"></i>
                                </button>
                            </div>
                        </td>
                        <td colspan="3">
                            <a target="_blank" :href="api_url+'/storage/'+file.id+'/'+file.file_name">view file</a>
                        </td>
                        <td colspan="3">
                            {{file.custom_properties.label}}
                        </td>
                        <td colspan="3">
                            {{file.custom_properties.dependency}}
                        </td>
                        <td colspan="3">
                            <div class="">
                                <!-- <button @click="edit=index" v-if="index != edit" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i></button>
                                <button @click="updateEmail()" v-else class="btn btn-success btn-sm"><i class="fas fa-check"></i></button> -->
                                <button @click="removeFile(file.id)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
           </table>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return {
            product:{label:'',dependency:''},
            files:[], 
            chunks:[], 
            uploaded:0,
            file:null,
            uploading:false,
            size:0,
            format:''
        }
    },
    computed:{
        progress(){
            return Math.round((this.uploaded / this.size) * 100);
        },
        api_url(){
            return process.env.VUE_APP_API_BASE_URL;
        }
    },
    watch:{
        chunks(newv,oldv){
            if(newv.length > 0){
                this.uploadFile();
            }
        }
    },
    methods:{
        fileselected(){
            var file=document.getElementById('file');
            if(file.files.length > 0){
                this.file=file.files[0];
                var splitd=this.file.name.split('.');
                this.format=splitd[splitd.length-1];
            }
        },
        reorder(index,direction){
            var data=this.files;
            if(direction == 'up'){
                var temp=data[index];
                data[index]=data[index-1];
                data[index-1]=temp;
            }else if(direction == 'down'){
                temp=data[index];
                data[index]=data[index+1];
                data[index+1]=temp;
            }
            this.files=[];
            this.files=data;
            var payload={};
            this.files.forEach((d,index)=>{
                payload[d.id]=index;
            });

            this.$store.dispatch('post',{endpoint:'/leffit/products/order-digital-product/'+this.item.id,details:{orders:payload}})
			.then(data=>{ 
                var temp=this.sort(data.data.digital_products);
                this.files=[];
                setTimeout(() => {
                    this.files=temp;
                }, 100);
                
			}).catch((error)=>{
				console.log(error);
			});
        },
        sort(data){
            var orders=data.map((f)=>{ return f.custom_properties.pickorder});
            orders.sort();
            var temp=[];
            orders.forEach((d)=>{
                temp.push(data.find((f)=> f.custom_properties.pickorder == d));
            });
            return temp;
        },
        createChunk(){ 
            let size = parseInt(process.env.VUE_APP_CHUNK_SIZE) * 1000000  , chunks = Math.ceil(this.file.size / size); 
            for (let i = 0; i < chunks; i++) {
                this.chunks.push(this.file.slice(
                    i * size, Math.min(i * size + size, this.file.size), this.file.type
                ));
            } 
            this.size=JSON.parse(JSON.stringify(this.chunks.length));
        },
        addFile(){
            var file=document.getElementById('file');
            if(file.files.length == 0 || this.product.label == '' || this.product.description == ''){
                this.$store.dispatch('makeNotification',{title:"Validation error",text:'All fields is required',type:'danger'});
                return false
            }

            this.createChunk(); 
        },
        uploadFile(){
            var payload=new FormData(); 
            payload.append('file',this.chunks[0]);
            this.uploading=true;

            //last chunk is sending
            if(this.chunks.length == 1){
                payload.append('last',1);
                payload.append('label',this.product.label);
                payload.append('dependency',this.product.dependency);
                payload.append('format',this.format);
                payload.append('name',this.file.name);

                console.log('last one');
            }

            this.$store.dispatch('post',{endpoint:'/leffit/products/attach-digital-product/'+this.item.id,details:payload})
			.then(data=>{
                console.log('result',data.data);
                this.uploaded+=1;
                this.chunks.shift();
                if(this.chunks.length == 0){
                    this.item.digital_products=this.sort(data.data.digital_products);
                    this.files==[];
                    this.files=this.sort(data.data.digital_products);
                    this.$store.dispatch('makeNotification',{title:"UPDATE",text:'File was saved',type:'success'});
                    this.uploading=false;
                    this.uploaded=0;
                }
			}).catch((error)=>{
                console.log(error.request);
                this.uploading=false;
			});
        },
        removeFile(id){
            if(!confirm("Are you sure you want to remove this item ?")){
                return false;
            }
            this.files=this.files.filter((d)=> d.id != id); 
            
            this.$store.dispatch('get','/leffit/products/delete-digital-product/'+this.$route.params.id+'/'+id)
			.then(data=>{
                console.log('result',data.data);
			}).catch((error)=>{
				console.log(error.request);
			});
        }
    },
    created(){
        console.log(this.item);
        this.files=this.sort(this.item.digital_products);
    }
}
</script>